import React, { useEffect, useState } from 'react';
import { Feed, CommentModal } from 'web-lib';
import { BSON } from 'realm-web';

import { SelectedAgentSocial, SocialItem } from '../../../../../utils/constants';
import { SocialCard } from '../../../../../components';

interface AgentFeedProps {
    agentId: any;
    allFeedItems: any;
    fetchFeedItems: Function;
    selectedAgent?: SelectedAgentSocial;
    setSelectedAgent?: Function;
    updateFeedItem: Function;
    feedStyling: string;
}

const AgentFeed = ({
    agentId,
    allFeedItems,
    fetchFeedItems,
    selectedAgent,
    setSelectedAgent = () => {},
    updateFeedItem,
    feedStyling,
}: AgentFeedProps) => {
    const [feedItemsToRender, setFeedItemsToRender] = useState<SocialItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<SocialItem>(); // used to conditionally render the CommentPost popup modal
    const [scrollToTop, setScrollToTop] = useState<boolean>(false); // used to confitionally scroll to the top of the feed
    const [containerHeight, setContainerHeight] = useState<number>(50);

    useEffect(() => {
        if (!allFeedItems[selectedAgent?.agentId]) {
            fetchFeedItems(false, selectedAgent?.agentId, true);
        } else {
            setFeedItemsToRender(allFeedItems[selectedAgent?.agentId]);
        }
    }, []);

    // find the feed items inside the allFeedItems array
    // if they do not exist, fetch em
    useEffect(() => {
        setFeedItemsToRender(allFeedItems[selectedAgent?.agentId]);

        if (selectedItem) {
            allFeedItems[selectedAgent?.agentId]?.map((item: SocialItem) => {
                if (item._id.toString() === selectedItem._id.toString()) {
                    setSelectedItem(item);
                }
            });
        }
    }, [allFeedItems]);

    useEffect(() => {
        setScrollToTop(true); // <- start at the top of the feed when looking at a new feed
        setFeedItemsToRender([]); // <- render blank feed items for smoother transition
        fetchFeedItems(true, selectedAgent?.agentId, true);
    }, [selectedAgent]);

    // calculate the size of the comment section on the modal
    useEffect(() => {
        const card = document.getElementById('card');
        const cardHeight = card?.clientHeight;
        const usableHeightInContainer = 570;
        const newContainerHeight = usableHeightInContainer - (cardHeight || 0);
        setContainerHeight(newContainerHeight);
    }, [selectedItem]);

    // if scrollToTop is true, scroll to the top of the feed then set it to false so we don't keep scrolling up
    useEffect(() => {
        if (scrollToTop) {
            setScrollToTop(false);
        }
    }, [scrollToTop]);

    const handleLeaveComment = (comment: Comment, item: SocialItem) => {
        window.scrollTo(0, 0);
        updateFeedItem({
            agentId,
            eventId: item._id,
            type: 'comment',
            comment,
        });
    };

    return (
        <>
            {selectedItem && (
                <CommentModal
                    containerHeight={containerHeight}
                    card={
                        <div id={'card'} style={{ width: '100%' }}>
                            <SocialCard
                                agentId={agentId}
                                item={selectedItem}
                                setSelectedItem={setSelectedItem}
                                updateFeedItem={updateFeedItem}
                            />
                        </div>
                    }
                    item={selectedItem}
                    onExit={() => setSelectedItem(undefined)}
                    onLeaveComment={(comment: Comment, item: SocialItem) =>
                        handleLeaveComment(comment, item)
                    }
                />
            )}

            <Feed
                containerStyling={feedStyling}
                customCards={
                    feedItemsToRender?.length
                        ? feedItemsToRender.map((item: SocialItem) => (
                              <SocialCard
                                  agentId={new BSON.ObjectId(selectedAgent?.agentId)}
                                  item={item}
                                  setSelectedItem={setSelectedItem}
                                  setSelectedAgent={setSelectedAgent}
                                  updateFeedItem={updateFeedItem}
                              />
                          ))
                        : []
                }
                scrollToTop={scrollToTop}
                noFeedMessage={`No feed found for ${selectedAgent?.firstName}`}
            />
        </>
    );
};

export default AgentFeed;
