const height = window.innerHeight;

export default (theme: any) => {
    const contentWidth = 632;
    return {
        root: {
            height: height - 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            userSelect: 'none',
            backgroundColor: theme.palette.background,
        },
        contentBox: {
            height: 630,
            width: contentWidth,
            backgroundColor: theme.palette.white,
            borderRadius: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        topBox: {
            // ...theme.paddingHorizontal(48),
            padding: 40,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
        },
        bottomBox: {
            padding: 48,
            paddingBottom: 20,
            height: 228,
            display: 'flex',
            flexDirection: 'column',
            width: contentWidth - 96,
        },
        middleBox: {
            padding: 48,
            paddingTop: 60,
            paddingBottom: 20,
            height: 218,
            display: 'flex',
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            flexDirection: 'column',
            width: contentWidth - 96,
        },
        otherBottomBox: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 48,
        },
    };
};
