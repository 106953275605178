import React, { memo, ReactNode } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';
import UnselectedRadio from '../../../../../images/blueRadioUnselected.svg';
import SelectedRadio from '../../../../../images/blueRadioSelected.svg';
import DisabledRadio from '../../../../../images/disabledRadio.svg';
import Typography from '../../../../../components/Typography';
import indexTheme from '../../../../../theme';

interface Props {
    data: any;
    selectedValue: any;
    onChangeValue: Function;
    style?: any;
}
const RadioButtons = ({ data, selectedValue, onChangeValue, style }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div style={style}>
            {data?.map(
                (item: {
                    label: string;
                    value: string;
                    description?: string;
                    disabled?: boolean;
                    extraLabelContent?: ReactNode;
                }) => {
                    return (
                        <div className={styleSheet.radioButtonContainer}>
                            <ClickableWithFeedback
                                onClick={() => onChangeValue(item.value)}
                                noop={item.disabled}
                            >
                                {item.disabled ? (
                                    <img src={DisabledRadio} />
                                ) : item.value === selectedValue ? (
                                    <img src={SelectedRadio} />
                                ) : (
                                    <img src={UnselectedRadio} />
                                )}
                            </ClickableWithFeedback>
                            <div className={styleSheet.radioButtonText} style={{ marginTop: -3 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ClickableWithFeedback
                                        onClick={() => onChangeValue(item.value)}
                                    >
                                        <Typography
                                            textStyle={'b1'}
                                            color={
                                                item.disabled
                                                    ? indexTheme.palette.mediumGrey
                                                    : indexTheme.palette.black
                                            }
                                        >
                                            {item.label}
                                        </Typography>
                                    </ClickableWithFeedback>
                                    {item.extraLabelContent ?? null}
                                </div>
                                {item.description ? (
                                    <Typography
                                        textStyle={'b1'}
                                        color={indexTheme.palette.mediumGrey}
                                    >
                                        {item.description}
                                    </Typography>
                                ) : null}
                            </div>
                        </div>
                    );
                },
            )}
        </div>
    );
};
export default memo(RadioButtons);
