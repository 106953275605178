import React, { useRef, useEffect, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { getDateDifference } from '../utils';

interface NotificationCardProps {
    notification: any;
    onClickCard: Function;
}

const NotificationCard = (props: NotificationCardProps) => {
    const { notification, onClickCard } = props;
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const getText = () => {
        var title = notification.content.text;
        // capitalize first letter of each word
        title = title.replace(/\w\S*/g, function (e: any) {
            return e.charAt(0).toUpperCase() + e.substr(1).toLowerCase();
        });

        // remove period at the end
        var final = title.substring(0, title.length - 1);
        if (final === 'New Showing Request' && notification?.status === 'requested') {
            final = 'New Client Showing Request';
        }
        else if (final === 'New Showing Request' && notification?.status === 'pending_internal'){
            final = 'New Showing Request';
        }
        else if (final === 'Showing Confirmed' && notification.status === 'confirmed'){
            final = 'Showing Confirmed';
        }
        return final;
    };
    getDateDifference(notification.activityDate);

    const feedBackButton = <div className={styleSheet.viewText}>Tap to leave feedback.</div>;
    const viewButton =  <div className={styleSheet.viewText}>Tap to view appointment details</div>
    const approveDenyButton = (
        <div className={styleSheet.viewText}>
            {notification.status === 'pending_internal'
                ? 'Tap to approve or deny.'
                : 'Tap to confirm or decline.'}
        </div>
    );
    return (
        <div className={styleSheet.notificationCardContainer}>
            <div className={styleSheet.details}>
                <div className={styleSheet.topRow}>
                    <div className={styleSheet.titleStyle}>{getText()}</div>
                    <div className={styleSheet.timeStyle}>
                        {(
                            getDateDifference(notification.activityDate).duration +
                            getDateDifference(notification.activityDate).type
                        ).includes('0min') ? (
                            <>{`Now`}</>
                        ) : (
                            <>
                                {`${getDateDifference(notification.activityDate).duration}${
                                    getDateDifference(notification.activityDate).type
                                }`}
                            </>
                        )}
                    </div>
                </div>
                <div className={styleSheet.textStyle}>{notification.content.title}</div>
                <div>{notification?.content?.status === 'completed' &&
                    !notification?.content?.hasLeftFeedback &&
                    feedBackButton}</div>
                <div>{(notification?.content?.status === 'requested' ||
                    notification?.content?.status === 'pending_internal') &&
                    approveDenyButton}</div>
                <div>{(notification?.content?.status === 'confirmed') &&
                    viewButton}</div>
            </div>
        </div>
        
    );
};

export default NotificationCard;
