import React, { useEffect, useState, useLayoutEffect } from 'react';
import styles from './styles';
import { getDateDifference } from '../utils';
import { useSelector, useDispatch } from 'react-redux';
import _, { noop } from 'lodash';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router';
import {
    fetchClientsRequested,
    deleteClientsRequested,
    resetResponseDataRequested,
    addClientsRequested,
} from '../actions';
import { getSearchedClients, getDeletedClients, getAddedClients, getCRMErrors } from '../selectors';
import Header from '../Header';
import CRMDeleteModal from '../CRMDeleteModal';
import CRMAddClientsModal from '../CRMAddClientsModal';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../../../utils/common';
import { filterClients } from '../utils';
import TableWithFilterBar from '../TableWithFilterBar';
import { NewClient } from '../CRMAddClientsModal/constants';
import { updateShowingsFilter } from '../../Showings/actions';
import { ClickableWithFeedback } from '../../../../components';
import { getSubscriptionTier } from '../../Profile/selectors';
import { updateUpgradeModalVisible } from '../../Profile/actions';

const CRMScreen = (props: {}) => {
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );
    const currentPlanTier = useSelector(getSubscriptionTier);
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const clients = useSelector((state) => getSearchedClients(state).fetchedClients);
    const loading = useSelector(
        (state) =>
            getSearchedClients(state).loading ||
            getDeletedClients(state).loading ||
            getAddedClients(state).loading,
    );
    const deleteMessage = useSelector((state) => getDeletedClients(state).message);
    const clientsAddedResponse = useSelector(
        (state) => getAddedClients(state).clientsAddedResponse,
    );
    const errors = useSelector(getCRMErrors);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchClientsRequested());
    }, []);

    const [canResize, setCanResize] = useState(false);

    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);

    // determine root height
    const [windowHeight, setWindowHeight] = useState<number>(0);
    useLayoutEffect(() => {
        const resize = () => {
            setTimeout(() => {
                setWindowHeight(window.innerHeight);
                setCanResize(false);
            }, 500);
        };
        window.addEventListener('resize', resize);
        setWindowHeight(window.innerHeight);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const [selectedRows, setChecked] = useState<Map<any, boolean>>(new Map());
    const [searchText, setSearchText] = useState<string>('');
    const [disableDelete, setDisableDelete] = useState<boolean>(true);
    const [transformedData, setTransformedData] = useState<Array<any>>([]);
    const [crmData, setCrmData] = useState<Array<any>>([]);
    const [selectedClients, setSelectedClients] = useState<Array<any>>([]);

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [addModal, setAddModal] = useState<boolean>(false);

    // calculate clients per page based on screen size and other components
    const CLIENTS_PER_PAGE = Math.floor((windowHeight - 130) / 65);

    /**
     * As soon as clients are fetched, transform the data to add new fields for display
     * and sorting purposes.  Set this new array to transformedData
     */
    useEffect(() => {
        if (clients && clients?.length) {
            const transformClients = clients?.map((client: any) => {
                const activityLength = client?.connectionActivity?.length;
                return {
                    ...client,
                    displayEmail: formatEmailForDisplay(client.email) || '',
                    displayPhone: '+1 ' + formatPhoneNumberForDisplay(client.phoneNumber),
                    lastContacted: activityLength
                        ? client.connectionActivity[activityLength - 1].date
                        : client.connectionDate
                        ? client.connectionDate
                        : null,
                    selected: false,
                };
            });
            setTransformedData(transformClients);
        }
    }, [clients]);

    useEffect(() => {
        setCrmData(filterClients(searchText, transformedData));
    }, [transformedData, searchText]);

    /**
     * If data changes (filtered, sorted differently, checkboxes selected, etc... )
     * update pagination if necessary,  update array of clients selected for deletion,
     * and check whether the delete button should be disabled or not.
     */
    useEffect(() => {
        const clientsToDelete = Array.from(selectedRows, ([_id, checked]) => ({
            _id,
            checked,
        })).filter((d) => d.checked);
        setSelectedClients(crmData.filter((d) => clientsToDelete.find((e) => e._id === d._id)));
        setDisableDelete(clientsToDelete.length ? false : true);
    }, [selectedRows]);
    const history = useHistory();

    return (
        <div className={styleSheet.root} style={{ height: `${windowHeight - 60}px` }}>
            <Header
                setSearchText={setSearchText}
                disableDelete={disableDelete}
                onClickDelete={() => setDeleteModal(true)}
                onClickAdd={() =>
                    currentPlanTier > 0 || agentHasFreeTrial
                        ? setAddModal(true)
                        : dispatch(updateUpgradeModalVisible(true))
                }
            />
            <TableWithFilterBar
                labels={[
                    'First Name',
                    'Last Name',
                    'Phone',
                    'Email',
                    'Last Active',
                    'Last Contacted',
                    '',
                ]}
                allChecked={false} // Not needed. Added for compatibility.
                setAllChecked={noop} // Not needed. Added for compatibility.
                itemsPerPage={CLIENTS_PER_PAGE}
                rows={crmData.map((d: any) => {
                    const contactDate = d.lastContacted || null;
                    const lastSessionDate = d.lastSession || null;
                    return {
                        _id: d._id,
                        'First Name': d.firstName,
                        'Last Name': d.lastName,
                        Phone: d.displayPhone,
                        Email: d.displayEmail ? d.displayEmail : 'Not Available',
                        lastSessionDate: lastSessionDate
                            ? (
                                  getDateDifference(lastSessionDate).duration +
                                  getDateDifference(lastSessionDate).type
                              ).includes('0min')
                                ? `Now`
                                : `${getDateDifference(lastSessionDate).duration} ${
                                      getDateDifference(lastSessionDate).type
                                  }`
                            : 'No Session',
                        'Last Contacted': contactDate
                            ? (
                                  getDateDifference(contactDate).duration +
                                  getDateDifference(contactDate).type
                              ).includes('0min')
                                ? `Now`
                                : `${getDateDifference(contactDate).duration} ${
                                      getDateDifference(contactDate).type
                                  }`
                            : 'Not Contacted',
                        x: {
                            data: 1,
                            render: (
                                <ClickableWithFeedback
                                    onClick={() => {
                                        dispatch(
                                            updateShowingsFilter({
                                                type: 'consumerId',
                                                id: d.stitchUserId,
                                                displayText: `${d.firstName} ${d.lastName}`,
                                            }),
                                        );
                                        history.push('/myShowings');
                                    }}
                                    className={styleSheet.viewMore}
                                >
                                    View Showings
                                </ClickableWithFeedback>
                            ),
                        },
                    };
                })}
                showBlueTick={(row: any) => row.lastSessionDate !== 'No Session'}
                rowStyle={{
                    marginTop: 10,
                    height: 40,
                    fontSize: 14,
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                }}
                setChecked={setChecked}
                selectedRows={selectedRows}
            />
            <CRMDeleteModal
                visible={deleteModal}
                onClickClose={() => {
                    setDeleteModal(false);
                    dispatch(resetResponseDataRequested());
                }}
                onClickSubmit={() => {
                    dispatch(deleteClientsRequested(selectedClients));
                    setChecked(new Map());
                }}
                numClients={selectedClients.length}
                loading={loading}
                message={deleteMessage}
                errors={errors}
            />
            <CRMAddClientsModal
                visible={addModal}
                onClickClose={() => {
                    setAddModal(false);
                    dispatch(resetResponseDataRequested());
                }}
                onClickSubmit={(clients: NewClient[]) => dispatch(addClientsRequested(clients))}
                loading={loading}
                message={clientsAddedResponse}
                errors={errors}
            />
        </div>
    );
};

export default CRMScreen;
