import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import PropTypes from 'prop-types';

import rootReducer from './reducer';
import sagas from './saga';

const sagaMiddleware = createSagaMiddleware();

var middleware = [sagaMiddleware];
// if in dev environment, use a redux logger and redux dev tools
let devTools;
if (process.env.REACT_APP_STITCH_APP_ID !== 'showingly-production-ckmll') {
    middleware.push(logger);
    devTools = composeWithDevTools(applyMiddleware(...middleware));
} else {
    devTools = applyMiddleware(...middleware);
}

const store = createStore(rootReducer, devTools);

sagaMiddleware.run(sagas);

const Store = ({ children }) => {
    return <Provider store={store}>{children}</Provider>;
};

Store.propTypes = {
    children: PropTypes.node.isRequired,
};

export { store };

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}

export default Store;
