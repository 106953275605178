import { createSelector } from 'reselect';

export const getQueuedShowingsState = (state: any) => state.queuedShowings || {};

export const getShowingQueue = createSelector(
    getQueuedShowingsState,
    (queuedShowingsState) => queuedShowingsState.showingQueue,
);

export const getGroupDelegationPrice = createSelector(
    getQueuedShowingsState,
    (queuedShowingsState) => queuedShowingsState?.groupDelegationPrice,
);

export const getWaypointsRO = createSelector(
    getQueuedShowingsState,
    (queuedShowingsState) => queuedShowingsState.waypoints,
);

export const getSsoQueueDataSaved = createSelector(
    getQueuedShowingsState,
    (queuedShowingsState) => queuedShowingsState.ssoQueueDataSaved,
);

export const getQueueLoading = createSelector(
    getQueuedShowingsState,
    (queuedShowingsState) =>
        queuedShowingsState.loading || queuedShowingsState.fetchingListings || false,
);
