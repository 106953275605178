import { Brokerage } from 'asset-lib/src/assets/svg';
import { createSelector } from 'reselect';
import { getProfileState } from '../Profile/selectors';

export const getBrokerageHubState = (state: any) => state.brokerageHub;

export const getBrokerageData = createSelector(getProfileState, (profile) => profile.brokerageData);

export const getBrokerageFeed = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.feed,
);

export const getBrokerageErrors = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.errors,
);

export const isCreatingContent = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.createLoading,
);

export const hasFetchedFeed = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.hasFetchedFeed,
);

export const isFeedLoading = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.loadingFeed,
);

export const isNewContentCreated = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.newContentCreated,
);

export const isBrokerageLoading = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.loading,
);

export const getAgentUserProfileData = createSelector(
    getProfileState,
    (profileState) => profileState.profileData,
);

export const getBrokerId = createSelector(
    getProfileState,
    (profileState) => profileState.brokerageData.brokerageId,
);

export const getBrokerageId = createSelector(
    getProfileState,
    (profileState) => profileState.brokerageData._id,
);

export const getBrokerageListings = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.brokerageListings,
);

export const getLoadingListings = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.loadingBrokerageListings,
);

export const getToAppendBrokerageListings = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.toAppendListings,
);

export const getBrokerageAgents = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.brokerageAgents,
);

export const getLoadingAgents = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.loadingBrokerageAgents,
);

export const getToAppendBrokerageAgents = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.toAppendAgents,
);

export const getBrokerageObjectId = createSelector(
    getProfileState,
    (profileState) => profileState.profileData.brokerage,
);

export const getSelectedBrokerage = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.selectedBrokerage,
);

export const getViewingRosterProfile = createSelector(
    getBrokerageHubState,
    (brokerageHubState) => brokerageHubState.viewingBrokerageRosterProfile,
);
