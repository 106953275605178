import { BSON } from 'realm-web';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { LISTINGS_COLLECTION, SHOWING_REQUESTS } from '../../store/api/constants';
import {
    anonLogin,
    callStitchFunction,
    findRecord,
    findShowingRequest,
    updateShowingRequest,
    updateShowingRequestUnverified,
} from '../../store/api/sagas';
import { saveListingInRedux } from '../main/ListingDetailsScreen/actions';
import * as Actions from './actions';
import { getUnverifiedUserData } from './selectors';

export function* fetchListingByKey({
    key,
}: ReturnType<typeof Actions.fetchListingByKeyRequested>): any {
    try {
        yield anonLogin();
        const listing = yield call(callStitchFunction, 'fetchListingByUnverifiedLink', key);
        if (listing !== -1) {
            // yield put(saveListingInRedux(listing, 'unverified'));
            yield put(Actions.fetchListingByKeySucceeded(listing));
        } else {
            yield put(Actions.fetchListingByKeyFailed(null));
        }
    } catch (err: any) {
        yield put(Actions.fetchListingByKeyFailed(err));
    }
}

export function* scheduleUnverifiedShowing({
    listingId,
    startTime,
    endTime,
}: ReturnType<typeof Actions.scheduleUnverifiedShowingRequested>): Generator<any, any, any> {
    try {
        const unverifiedUser = yield select(getUnverifiedUserData);
        const utcOffset = Math.floor(new Date().getTimezoneOffset() / 60);
        const unverifiedShowing = yield call(
            callStitchFunction,
            'createUnverifiedShowing',
            listingId,
            startTime,
            endTime,
            utcOffset,
            unverifiedUser,
        );
        if (unverifiedShowing?._id) {
            yield put(Actions.scheduleUnverifiedShowingSucceeded(unverifiedShowing));
        }
    } catch (err: any) {
        yield put(Actions.scheduleUnverifiedShowingFailed(err));
    } finally {
        yield put(Actions.setUnverifiedUserData(null));
    }
}

export function* fetchUnverifiedShowing({
    key,
}: ReturnType<typeof Actions.fetchUnverifiedShowingRequested>): Generator<any, any, any> {
    try {
        yield anonLogin();

        const showingObjId = atob(key);
        const showing = yield call(findRecord, SHOWING_REQUESTS, {
            _id: new BSON.ObjectId(showingObjId),
        });
        if (showing?._id) {
            const listing = yield call(findRecord, LISTINGS_COLLECTION, {
                _id: showing.listingId,
            });
            yield put(Actions.fetchUnverifiedShowingSucceeded(showing, listing));
        } else {
            yield put(Actions.fetchUnverifiedShowingFailed(null));
        }
    } catch (err: any) {
        yield put(Actions.fetchUnverifiedShowingFailed(err));
    }
}

export function* updateUnverifiedShowingStatus({
    showingObjId,
    status,
}: ReturnType<typeof Actions.updateUnverifiedShowingStatusRequested>): Generator<any, any, any> {
    try {
        //
        const res = yield call(updateShowingRequest, {
            id: showingObjId,
            status,
        });
        if (res?.modifiedCount || res?.matchedCount) {
            const showing = yield call(findRecord, SHOWING_REQUESTS, {
                _id: showingObjId,
            });
            const listing = yield call(findRecord, LISTINGS_COLLECTION, {
                _id: showing.listingId,
            });
            yield put(Actions.fetchUnverifiedShowingSucceeded(showing, listing));
            yield put(Actions.updateUnverifiedShowingStatusSucceeded());
        }
    } catch (err: any) {
        yield put(Actions.updateUnverifiedShowingStatusFailed(err));
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.FETCH_LISTING_BY_KEY_REQUESTED, fetchListingByKey),
        takeLatest(Actions.SCHEDULE_UNVERIFIED_SHOWING_REQUESTED, scheduleUnverifiedShowing),
        takeLatest(Actions.FETCH_UNVERIFIED_SHOWING_REQUESTED, fetchUnverifiedShowing),
        takeLatest(
            Actions.UPDATE_UNVERIFIED_SHOWING_STATUS_REQUESTED,
            updateUnverifiedShowingStatus,
        ),
    ]);
}
