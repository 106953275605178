import SelectedListingScreenStyles from '../SelectedListingScreen/styles';
import PastMessagesStyles from './PastMessages/styles';
export default (theme: any) => {
    return {
        ...SelectedListingScreenStyles(theme),
        ...PastMessagesStyles(theme),
        circularProgressContainer: { position: 'absolute', bottom: '83px', right: '15px' },
        clickableIcon: { display: 'flex' },
        loader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 50,
        },
        modalFooter: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            display: 'flex',
            padding: 16,
            borderTop: '1px solid #e9e9e9',
        },
        pastMessagesStyle: { paddingLeft: '48px', paddingRight: '48px', paddingTop: '17px' },
        picker: { padding: 16 },
        pickerText: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '16px',
        },
        textArea: {
            resize: 'none',
            padding: 15,
            width: '95%',
            height: '293px',
            border: 'none',
            outline: 'none',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 18,
        },
        textAreaContainer: { borderTop: '1px solid #e9e9e9' },
        tagBox: {
            display: 'flex',
            gap: '1rem',
        },
    };
};
