import { createSelector } from 'reselect';

export const getShowingDetailsState = (state: any) => state.showingDetails;

export const getCurrentShowing = createSelector(
    getShowingDetailsState,
    (showingDetailsState) => showingDetailsState.showing,
);

export const getLoading = createSelector(
    getShowingDetailsState,
    (showingDetailsState) => showingDetailsState.loading,
);

export const getInitiallyViewingListingDetails = createSelector(
    getShowingDetailsState,
    (showingDetailsState) => showingDetailsState.initiallyViewingListingDetails,
);

export const getLoadingShowingStatus = createSelector(
    getShowingDetailsState,
    (showingDetailsState) => showingDetailsState.loadingShowingStatus,
);
