import { createSelector } from 'reselect';

export const getNotificationState = (state: any) => state.notifications || {};
export const getMyNotifications = createSelector(getNotificationState, (notificationState) => {
    const { notifications = [] } = notificationState;
    return notifications;
});

export const getPendingShowings = createSelector(getNotificationState, (notificationState) => {
    const { pendingShowings = [] } = notificationState;
    return pendingShowings;
});
export const getLoadingNotifications = createSelector(getNotificationState, (notificationState) => {
    const { loadingNotifications } = notificationState;
    return loadingNotifications;
});
export const getLoadingShowings = createSelector(getNotificationState, (notificationState) => {
    const { loadingPendingShowings } = notificationState;
    return loadingPendingShowings;
});
