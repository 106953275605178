import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import Typography from '../../../components/Typography';
import DropdownIcon from '../../../images/dropDownIcon.svg';
import ButtonComponent from '../../../components/ButtonComponent';
import DropdownSelect from '../../../components/DropdownSelect';
import { getMarketRequested, setAuthPracticeInfo } from '../actions';
import { getMarkets } from '../selectors';
import { findIndex, propEq } from 'ramda';
import { useHistory } from 'react-router-dom';

interface AgentStartProps {}

const AgentStart = (props: AgentStartProps) => {
    const markets = useSelector(getMarkets);
    const dispatch = useDispatch();
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();

    useEffect(() => {
        dispatch(getMarketRequested());
    }, []);

    const states =
        markets?.map((mar: any) => {
            return {
                label: mar.name,
                value: mar.name,
            };
        }) || [];

    const [selectedState, setSelectedState] = useState<string | null>(null);

    const authMarketIndex = findIndex(propEq('name', selectedState), markets);
    const authMarket = markets[authMarketIndex];

    const filteredMarkets = markets.filter((mar: any) => {
        return mar.name === selectedState;
    });

    const selectedMarket = filteredMarkets.length ? filteredMarkets[0] : null;

    const mlsList =
        selectedMarket?.displayMlsNames?.map((names: any) => {
            return {
                label: names?.displayMlsName,
                value: names?.mlsName,
            };
        }) || [];

    const [selectedMLS, setSelectedMLS] = useState<string | null>(null);

    const authMlsIndex = findIndex(propEq('label', selectedMLS), mlsList);
    const authMls = mlsList[authMlsIndex];

    const continueDisabled = !markets?.length || authMarketIndex < 0 || authMlsIndex < 0;

    const handleContinue = () => {
        dispatch(setAuthPracticeInfo(authMarket, authMls));
        history.push('/auth/search');
    };

    const [selecting, setSelecting] = useState<string | null>(null);

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.contentBox}>
                <div className={styleSheet.topBox}>
                    <Typography textStyle="h2">Where do you practice real estate?</Typography>
                </div>
                <div className={styleSheet.midBox}>
                    <Typography
                        color={theme.palette.mediumGrey}
                        textStyle="b1"
                        style={{ fontSize: 18 }}
                    >
                        State
                    </Typography>
                    <DropdownSelect
                        list={states}
                        text={'Select Your State'}
                        width={516}
                        selectOption={(option: any) => setSelectedState(option)}
                        selectedOption={selectedState}
                        selecting={selecting === 'state'}
                        setSelecting={() => setSelecting('state')}
                        unsetSelecting={() => setSelecting(null)}
                    />
                    <Typography
                        color={theme.palette.mediumGrey}
                        textStyle="b1"
                        style={{ fontSize: 18 }}
                    >
                        MLS
                    </Typography>
                    <DropdownSelect
                        list={mlsList}
                        text={'Select Your MLS'}
                        width={516}
                        selectOption={(option: any) => setSelectedMLS(option)}
                        selectedOption={selectedMLS}
                        selecting={selecting === 'mls'}
                        setSelecting={() => setSelecting('mls')}
                        unsetSelecting={() => setSelecting(null)}
                    />
                </div>
                <div className={styleSheet.bottomBox}>
                    <ButtonComponent
                        onClick={() => handleContinue()}
                        disabled={continueDisabled}
                        fill
                        width={257}
                    >
                        Continue
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
export default AgentStart;
