import { STATUS } from '../../../store/api/constants';
import { BSON } from 'realm-web';

export enum ShowingDetailsActions {
    fetch = '[Showing Details] Fetch',
    saveShowing = '[Showing Details] Save',
    accept = '[Showing Details] Accept',
    decline = '[Showing Details] Decline',
}

export const saveShowingInRedux = (
    showing: any,
    initiallyViewingListingDetails: boolean = false,
) => {
    return {
        type: ShowingDetailsActions.saveShowing,
        showing,
        initiallyViewingListingDetails,
    };
};

export const fetchShowingDetailsRequested = (showingId: BSON.ObjectId) => {
    return {
        type: ShowingDetailsActions.fetch,
        status: STATUS.Requested,
        showingId,
    };
};

export const fetchShowingDetailsSucceeded = (showing: any = []) => {
    return {
        type: ShowingDetailsActions.fetch,
        status: STATUS.Succeeded,
        showing,
    };
};

export const fetchShowingDetailsFailed = (errors: string[] = []) => {
    return {
        type: ShowingDetailsActions.fetch,
        status: STATUS.Failed,
        errors,
    };
};

/*
 * Accept and Decline
 * Showing Requests
 */

export const acceptShowingRequestRequested = (showingId: BSON.ObjectId) => {
    return {
        type: ShowingDetailsActions.accept,
        status: STATUS.Requested,
        showingId,
    };
};

export const acceptShowingRequestSucceded = () => {
    return {
        type: ShowingDetailsActions.accept,
        status: STATUS.Succeeded,
    };
};

export const acceptShowingRequestFailed = (errors: string[] = []) => {
    return {
        type: ShowingDetailsActions.accept,
        status: STATUS.Failed,
        errors,
    };
};

export const declineShowingRequestRequested = (showingId: BSON.ObjectId) => {
    return {
        type: ShowingDetailsActions.decline,
        status: STATUS.Requested,
        showingId,
    };
};

export const declineShowingRequestSucceded = () => {
    return {
        type: ShowingDetailsActions.decline,
        status: STATUS.Succeeded,
    };
};

export const declineShowingRequestFailed = (errors: string[] = []) => {
    return {
        type: ShowingDetailsActions.decline,
        status: STATUS.Failed,
        errors,
    };
};
