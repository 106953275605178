const NEWS_ACTION_PREFIX = '[News Action]';

export const FETCH_NEWS_REQUESTED = NEWS_ACTION_PREFIX + 'FETCH_NEWS_REQUESTED';
export const FETCH_NEWS_SUCCEEDED = NEWS_ACTION_PREFIX + 'FETCH_NEWS_SUCCEEDED';
export const FETCH_NEWS_FAILED = NEWS_ACTION_PREFIX + 'FETCH_NEWS_FAILED';

export const fetchNewsRequested = () => {
    return {
        type: FETCH_NEWS_REQUESTED,
    };
};
export const fetchNewsSucceeded = (news: any) => {
    return {
        type: FETCH_NEWS_SUCCEEDED,
        news,
    };
};
export const fetchNewsFailed = (errors: any) => {
    return {
        type: FETCH_NEWS_FAILED,
        errors,
    };
};
