import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import ButtonComponent from '../../../../components/ButtonComponent';
import { UpgradeSnackBar } from '../../../../components';
import { useSelector } from 'react-redux';
import { dispatch } from 'd3';
import { updateUpgradeModalVisible } from '../../Profile/actions';

interface HeaderProps {
    setSearchText: Function;
    disableDelete: boolean;
    onClickDelete: Function;
    onClickAdd: Function;
}
const Header = ({ setSearchText, disableDelete, onClickDelete, onClickAdd }: HeaderProps) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });


    const searchBarComponent = (
        <div className={styleSheet.searchBarContainer}>
            <input
                placeholder={'Search for Client'}
                onChange={(e: any) => {
                    setSearchText(e.target.value);
                }}
                className={styleSheet.searchBar}
            />
        </div>
    );

    return (
        <div>
        <UpgradeSnackBar/>

        <div className={styleSheet.root}>
            <div className={styleSheet.leftSide}>Your Clients</div>
            {searchBarComponent}
            <div className={styleSheet.rightSide}>
                <ButtonComponent
                    className={styleSheet.button}
                    disabled={disableDelete}
                    onClick={onClickDelete}
                    >
                    Delete Selected
                </ButtonComponent>
                <ButtonComponent className={styleSheet.button} onClick={onClickAdd} fill>
                    Add Clients
                </ButtonComponent>
            </div>
        </div>
                    </div>
    );
};

export default Header;
