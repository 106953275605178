import { BSON } from 'realm-web';
import { STATUS } from '../../../store/api/constants';

export enum DASHBOARD_ACTIONS {
    Fetch = '[Dashboard] Fetch Agent Stats',
    FetchMetrics = '[Dashboard] Fetch Agent Metrics',
    FetchMarketAverages = '[Dashboard] Fetch Market Averages',
}

export type chartsData = {
    totalShowingsPerMonth: {};
    totalConsumers: {};
    totalListingsPerMonth: {};
    avgPrice: {};
    agentCount: Number;
};

//#region getAgentStats //////////////////////////////////
export const getAgentStatsRequested = (agentObjectId: BSON.ObjectId) => {
    return {
        type: DASHBOARD_ACTIONS.Fetch,
        status: STATUS.Requested,
        agentObjectId,
    };
};

export const getAgentStatsSucceeded = (agentStats: any, agentMarkets: string[]) => {
    return {
        type: DASHBOARD_ACTIONS.Fetch,
        status: STATUS.Succeeded,
        agentStats,
        agentMarkets,
    };
};

export const getAgentStatsFailed = (error: any) => {
    return {
        type: DASHBOARD_ACTIONS.Fetch,
        status: STATUS.Failed,
        error,
    };
};
//#end-region /////////////////////////////////////////

export type DashboardActions =
    | ReturnType<typeof getAgentStatsRequested>
    | ReturnType<typeof getAgentStatsSucceeded>
    | ReturnType<typeof getAgentStatsFailed>;
