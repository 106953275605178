export default (theme: any) => {
    const baseProps = {
        backgroundColor: theme.palette.white,
        ...theme.cardShadow,
        height: 317,
        maxWidth: 812,
        minWidth: 344,
        width: '93vw',
    };
    return {
        root: {
            background: theme.palette.background,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        showingContentContainer: {
            ...baseProps,
        },
        loadingContainer: {
            ...baseProps,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        header: {
            height: 72,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        bodyA: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: 30,
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 72,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderTopWidth: 1,
            borderStyle: 'solid',
            alignSelf: 'flex-end',
            marginTop: 22,
        },
    };
};
