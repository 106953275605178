export default (theme: any) => {
    return {
        tag: {
            ...theme.paddingHorizontal(5),
            ...theme.paddingVertical(3),
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            fontSize: 14,
            minWidth: 62,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
};
