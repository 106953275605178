import {
    FETCH_ACTIVE_LISTINGS_REQUESTED,
    FETCH_ACTIVE_LISTINGS_SUCCEEDED,
    FETCH_ACTIVE_LISTINGS_FAILED,
    FETCH_LISTING_SUCCEEDED,
    FETCH_LISTING_FAILED,
    CLIENT_SEARCH_ACTION,
    CLEAR_LISTING_ERRORS,
    SET_SELECTED_LISTING,
    SET_UPDATING_QUEUE,
    CLEAR_SHOWING_AVAILABILITY,
    CLEAR_SHOWING_SUCCESS_STATUS,
    CLEAR_SELECTED_LISTING,
    LOAD_SEARCH_HISTORY,
    CLEAR_SCHEDULE_SHOWING_ERROR,
    FETCH_SHOWINGS_FOR_LISTING_REQUESTED,
    FETCH_SHOWINGS_FOR_LISTING_SUCCEEDED,
    FETCH_SHOWINGS_FOR_LISTING_FAILED,
    FETCH_LISTING_REQUESTED,
    FETCH_SSO_LISTING_REQUESTED,
    FETCH_SSO_LISTING_FAILED,
    FETCH_SSO_LISTING_SUCCEEDED,
    FETCH_SSO_UNCONNECTED_LISTING_REQUESTED,
    FETCH_SSO_UNCONNECTED_LISTING_FAILED,
    FETCH_SSO_UNCONNECTED_LISTING_SUCCEEDED,
} from './actions';
import { CLEAR_SELECTED_LISTING_AND_SHOWING } from '../../../store/api/constants';
import { STATUS } from '../../../utils/constants';
// import { SHOWING_ACTION } from '../../../utils/constants';
import {
    CONNECT_LISTING_FAILED,
    CONNECT_LISTING_REQUESTED,
    CONNECT_LISTING_SUCCEEDED,
    RESET_CONNECT_LISTINGS_SUCCESS_STATUS,
} from './constants';
import { SHOWING_ACTION } from './actions';
import { nice } from 'd3';

// const savedListings = localStorage.getItem("searchHistory")

export const initialState = {
    // request state
    loading: false,

    // Search Query passed to last search
    searchedText: null,

    // active listings array for `ActiveListingScreen`
    activeListings: [],

    errors: [],

    // single selected listing from user
    selectedListing: null,
    fetchListingLoading: false,
    selectedListingMarketData: null,
    showingSuccessfullyCreated: false,
    searchedClients: [], // array of clients searched for by the user
    showings: [],
    newShowing: null,

    // dates a given listing is NOT allowed to schedule for
    unavailableDates: [],

    //Loader for showings
    loadingShowings: false,

    showingsOnListing: [],
    showingsOnListingLoading: false,
    ssoListing: null,
    ssoListingLoading: undefined,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case LOAD_SEARCH_HISTORY: {
            const { activeListings } = action;
            return {
                ...state,
                activeListings: activeListings,
            };
        }
        case FETCH_ACTIVE_LISTINGS_REQUESTED: {
            const { searchedText } = action;
            return {
                ...state,
                loading: true,
                searchedText,
            };
        }
        case FETCH_ACTIVE_LISTINGS_SUCCEEDED: {
            const { activeListings } = action;
            return {
                ...state,
                activeListings,
                loading: false,
            };
        }
        case FETCH_ACTIVE_LISTINGS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                loading: false,
                errors,
            };
        }
        case FETCH_LISTING_REQUESTED: {
            return {
                ...state,
                fetchListingLoading: true,
            };
        }
        case FETCH_LISTING_SUCCEEDED: {
            const { listing, marketData } = action;
            return {
                ...state,
                selectedListing: listing,
                selectedListingMarketData: marketData,
                fetchListingLoading: false,
            };
        }
        case FETCH_LISTING_FAILED: {
            return {
                ...state,
                errors: action.errors,
                fetchListingLoading: false,
            };
        }
        case CLEAR_LISTING_ERRORS: {
            return {
                ...state,
                errors: [],
                connectListingErrors: [],
            };
        }
        case SET_SELECTED_LISTING: {
            const { selectedListing } = action;
            return {
                ...state,
                selectedListing,
            };
        }
        case SET_UPDATING_QUEUE: {
            const { status } = action;
            return {
                ...state,
                updatingShowingInQueue: status,
            };
        }
        case CLIENT_SEARCH_ACTION.Succeeded: {
            return {
                ...state,
                searchedClients: action.clients,
            };
        }
        case CLEAR_SELECTED_LISTING_AND_SHOWING: {
            return {
                ...state,
                selectedListing: null,
            };
        }
        case CONNECT_LISTING_REQUESTED: {
            const { errors } = action;
            return {
                ...state,
                loading: true,
                errors: errors,
            };
        }
        case CONNECT_LISTING_FAILED: {
            const { errors } = action;
            return {
                ...state,
                loading: false,
                connectListingErrors: [errors],
            };
        }
        case CONNECT_LISTING_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                connectSucceeded: true,
            };
        }
        case RESET_CONNECT_LISTINGS_SUCCESS_STATUS: {
            return {
                ...state,
                connectSucceeded: false,
            };
        }

        case SHOWING_ACTION.Create:
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        showingSuccessfullyCreated: true,
                    };
                }

                case STATUS.Failed: {
                    let tempErrors: string[] = state.errors;
                    tempErrors.push(action.error);
                    return {
                        ...state,
                        loading: false,
                        errors: tempErrors,
                    };
                }
            }

        case SHOWING_ACTION.ShowingAvailability: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingShowings: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        unavailableDates: action.unavailableDates,
                        loadingShowings: false,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: [action.error],
                        loadingShowings: false,
                    };
                }

                default:
                    return state;
            }
        }
        case SHOWING_ACTION.FetchSchedule: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.error,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        showings: action.showings,
                    };
                }
            }
        }
        case CLEAR_SHOWING_AVAILABILITY: {
            return {
                ...state,
                unavailableDates: [],
            };
        }

        case CLEAR_SHOWING_SUCCESS_STATUS: {
            return {
                ...state,
                showingSuccessfullyCreated: false,
            };
        }

        case SHOWING_ACTION.AddNewShowing: {
            const { showing } = action;
            return {
                ...state,
                newShowing: showing,
            };
        }

        case SHOWING_ACTION.RemoveNewShowing: {
            return {
                ...state,
                newShowing: null,
            };
        }

        case CLEAR_SELECTED_LISTING: {
            return {
                ...state,
                selectedListing: null,
            };
        }

        case CLEAR_SCHEDULE_SHOWING_ERROR: {
            return {
                ...state,
                errors: [],
            };
        }

        case FETCH_SHOWINGS_FOR_LISTING_REQUESTED: {
            return {
                ...state,
                showingsOnListingLoading: true,
            };
        }
        case FETCH_SHOWINGS_FOR_LISTING_SUCCEEDED: {
            return {
                ...state,
                showingsOnListingLoading: false,
                showingsOnListing: action.showings,
            };
        }
        case FETCH_SHOWINGS_FOR_LISTING_FAILED: {
            return {
                ...state,
                showingsOnListingLoading: false,
                errors: action.errors,
            };
        }
        case FETCH_SSO_LISTING_REQUESTED: {
            return {
                ...state,
                ssoListingLoading: true,
            };
        }
        case FETCH_SSO_LISTING_SUCCEEDED: {
            return {
                ...state,
                ssoListingLoading: false,
                ssoListing: action.listing,
            };
        }
        case FETCH_SSO_LISTING_FAILED: {
            return {
                ...state,
                ssoListingLoading: false,
                errors: action.errors,
            };
        }
        case FETCH_SSO_UNCONNECTED_LISTING_REQUESTED: {
            return {
                ...state,
                ssoUnconnectedListingLoading: true,
            };
        }
        case FETCH_SSO_UNCONNECTED_LISTING_SUCCEEDED: {
            return {
                ...state,
                ssoUnconnectedListingLoading: false,
                isSsoUnconnectedListing: action.isListing,
                ssoUnconnectedListingLink: action.listingLink,
            };
        }
        case FETCH_SSO_UNCONNECTED_LISTING_FAILED: {
            return {
                ...state,
                ssoUnconnectedListingLoading: false,
                errors: action.errors,
            };
        }
        default:
            return state;
    }
}
