import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Store from './store';
import './index.css';
import SSO from './sso';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Poppins-Bold.ttf';
import { routerRef } from './utils';
import { ThemeProvider } from 'react-jss';
import theme from './theme';

// Testing automatic PRs - You can safely delete this comment
// Testing automatic PRs - Automatic updates
// Adding more ...

ReactDOM.render(
    <React.StrictMode>
        <Store>
            <BrowserRouter
                ref={(e) => {
                    routerRef.current = e;
                }}
            >
                <ThemeProvider theme={theme}>
                    <SSO>
                        <App />
                    </SSO>
                </ThemeProvider>
            </BrowserRouter>
        </Store>
    </React.StrictMode>,
    document.getElementById('root'),
);

// Testing automatic PRs - Autoupdate 2 - Delete this comment

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
