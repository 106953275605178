import { createSelector } from 'reselect';

export const getMyListingsState = (state: any) => state.myListings;

export const getShowingsGroupedByListing = createSelector(
    getMyListingsState,
    (myListingsState) => myListingsState.showingsGroupedByListing,
);

export const getShowingHistory = createSelector(
    getMyListingsState,
    (myListingsState) => myListingsState.history,
);

// determine how many pending showing requests actually exist to determine whether or not to display no pending showings on "Pending Showings" tab
export const getNumberRequestedShowings = createSelector(getMyListingsState, (myListingsState) => {
    let totalNumPendingShowings: number = 0;
    myListingsState.showingsGroupedByListing.map(
        (listing: any) =>
        (totalNumPendingShowings +=
            listing.showingRequests.length > 0 ? listing.showingRequests.length : 0),
    );
    return totalNumPendingShowings;
});

export const getListingConnectionPrice = createSelector(
    getMyListingsState,
    (myListingState) => myListingState.connectionPrice,
);

// Not sure why it was set up like this, but all of my listings (even the unconnected ones) are under the connectedListings on state. :(
export const getMyListings = createSelector(
    getMyListingsState,
    (myListingsState) => myListingsState.connectedListings,
);




