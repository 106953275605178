import { values } from 'lodash';
import React, { useState } from 'react';

export function useLocalStorage(key: string, initialValue: any) {
    window.localStorage.removeItem(key);

    const [storeValue, setStoreValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: any) => {
        try {
            setStoreValue(value);
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    };

    return [storeValue, setValue];
}
