import React, { ReactNode, useEffect, useState } from 'react';

import queryString from 'query-string';
import { requestAccessTokenSSO } from '../domains/auth/actions';
import { useDispatch } from 'react-redux';
import { getAuthEndPoint } from '../utils/common/index';

interface Props {
    children: ReactNode;
}

export enum EntryPointType {
    Entry = 'entry',
    Config = 'listingConfig',
}

export enum Mls {
    Sef = 'sef',
    Colorado = 'recolorado',
    Sdar = 'sdar',
    Neren = 'neren',
    Odessa = 'odessa',
}

enum SSOSearchParams {
    AgentMlsId = 'agentmlsid',
    MlsName = 'mlsname',
    ListingId = 'listingid',
}

// Strictly for SEF Only
enum SEFParams {
    Code = 'code',
    State = 'state',
    Key = 'KEY',
    Dtm = 'dtm',
}

// Includes possibly every SSO Integration unless a special case like SEF Arises
enum SSOParams {
    MlsUniqueIdentifier = 'mlsuniqueidentifier',
    Code = 'code',
    Key = 'KEY',
    dtm = 'dtm',
}

enum AppMode {
    Production = 'production',
    Staging = 'staging',
}

const BASE_URL_REDIRECT = 'https://agent.showingly.com/entry/search';
const STAGING_BASE_URL_REDIRECT = 'https://beta.agent.showingly.com/entry/search';

function SSO(props: Props) {
    const [searchPath, setSearchPath] = useState<string>(window.location.search);
    const [pathName, setpathName] = useState<string>(window.location.pathname);
    const [parsedSearch, setParsedSearch] = useState<queryString.ParsedQuery<string>>(
        queryString.parse(searchPath),
    );
    const [main, setMain] = useState<string[] | string>(pathName.split('/'));
    const dispatch = useDispatch();

    const entrySetup = () => {
        sessionStorage.setItem('isOIDCRefresh', 'false');
        localStorage.removeItem('showingQueue');
        sessionStorage.setItem('appRefresh', 'true');
    };
    const getRedirectURI = (provider: string) => {
        const appMode = process.env.REACT_APP_MODE;
        switch (provider) {
            // Intentional Fallthrough
            case Mls.Colorado:
            case Mls.Sef: {
                return BASE_URL_REDIRECT;
            }
            case Mls.Sdar:
            case Mls.Neren: {
                if (appMode === AppMode.Production) {
                    return `${BASE_URL_REDIRECT}?mlsuniqueidentifier=${provider}`;
                } else {
                    return `${STAGING_BASE_URL_REDIRECT}?mlsuniqueidentifier=${provider}`;
                }
            }
            default: {
                return BASE_URL_REDIRECT;
            }
        }
    };
    const setOIDCStatus = () => sessionStorage.setItem('isComingViaOIDC', 'true');
    const setEntryPointData = (data: string) => sessionStorage.setItem('entryEndpointData', data);
    const setQueueData = (data: string) => sessionStorage.setItem('queueData', data);

    const handleDeepLink = (provider: string) => {
        switch (provider) {
            // Intentional Fallthrough
            case Mls.Colorado:
            case Mls.Sef: {
                const paramsToEncode = {
                    mlsname: provider,
                };
                const paramsStringified = JSON.stringify(paramsToEncode);
                const paramsEncoded = Buffer.from(paramsStringified).toString('base64');
                const redirectUri = `${getRedirectURI(provider)}&state=${paramsEncoded}`;
                const authEndPoint = getAuthEndPoint(provider, redirectUri);
                window.open(authEndPoint, '_self');
                break;
            }
            case Mls.Neren:
            case Mls.Sdar: {
                const redirectURI = getRedirectURI(provider);

                const authEndPoint = getAuthEndPoint(provider, redirectURI);
                window.open(authEndPoint, '_self');
                break;
            }
            case Mls.Odessa: {
                // Bypass OIDC
                const mlsName = parsedSearch[SSOParams.MlsUniqueIdentifier];
                const agentMlsId = parsedSearch[SSOSearchParams.AgentMlsId];
                const key = parsedSearch[SEFParams.Key];
                const dtm = parsedSearch[SEFParams.Dtm];

                // Call with withoutOIDC flag as true
                dispatch(requestAccessTokenSSO(null, mlsName, null, agentMlsId, key, dtm, true));
            }
            default: {
                return BASE_URL_REDIRECT;
            }
        }
    };

    useEffect(() => {
        if (main.includes(EntryPointType.Entry)) {
            entrySetup();
            const isRememberMe = localStorage.getItem('remember');
            if (isRememberMe) {
                localStorage.clear();
            }

            setOIDCStatus();
            setEntryPointData(JSON.stringify(parsedSearch));

            // build a queue for each entry point case associated data
            const queueData = {
                ...(parsedSearch[SSOSearchParams.MlsName]
                    ? { mlsname: (parsedSearch[SSOSearchParams.MlsName] as string).toLowerCase() }
                    : null),
                ...(parsedSearch[SSOSearchParams.ListingId]
                    ? { listingid: parsedSearch[SSOSearchParams.ListingId] }
                    : null),
                ...(main.includes(EntryPointType.Config) ? { unconnected: 'true' } : null),
            };

            if (Object.keys(queueData).length) setQueueData(JSON.stringify(queueData));

            if (parsedSearch[SSOSearchParams.AgentMlsId] && parsedSearch[SSOSearchParams.MlsName]) {
                const providerName = parsedSearch[SSOSearchParams.MlsName];
                const agentMlsId = parsedSearch[SSOSearchParams.AgentMlsId];
                const key = parsedSearch[SEFParams.Key];
                const dtm = parsedSearch[SEFParams.Dtm];
                let withoutOIDC = false;

                if (providerName === Mls.Odessa) {
                    // For the specific case of Odessa we skip the OIDC
                    withoutOIDC = true;
                }

                dispatch(
                    requestAccessTokenSSO(
                        null,
                        providerName,
                        null,
                        agentMlsId,
                        key,
                        dtm,
                        withoutOIDC,
                    ),
                );
            } else if (parsedSearch[SEFParams.Code] && parsedSearch[SEFParams.State]) {
                const encodedParams = (parsedSearch[SEFParams.State] as unknown) as string;
                const paramsDecoded = atob(encodedParams);
                setEntryPointData(paramsDecoded);
                setOIDCStatus();
                const readReadyParams = JSON.parse(paramsDecoded);
                // Prepare other necessary resources
                const providerName = readReadyParams[SSOSearchParams.MlsName];
                const agentMlsId = readReadyParams[SSOSearchParams.AgentMlsId];
                const code = parsedSearch[SEFParams.Code];
                const redirectURI = getRedirectURI(providerName?.toLowerCase());
                dispatch(
                    requestAccessTokenSSO(code, providerName, redirectURI, agentMlsId, null, null),
                );
            } else if (parsedSearch[SSOParams.Code]) {
                // We have received the code, and possibly the mlsName from state or url
                setOIDCStatus();
                const encodedParams = (parsedSearch[SEFParams.State] as unknown) as string;
                const mlsName = (parsedSearch[SSOParams.MlsUniqueIdentifier] as unknown) as string;
                const code = parsedSearch[SSOParams.Code];
                let readReadyParams;
                if (encodedParams) {
                    const paramsDecoded = atob(encodedParams);
                    if (paramsDecoded) {
                        readReadyParams = JSON.parse(paramsDecoded);
                    }
                }
                let providerName;
                if (mlsName) {
                    providerName = mlsName.toUpperCase();
                } else {
                    providerName = readReadyParams?.mlsname;
                }
                const redirectURI = getRedirectURI(mlsName?.toLowerCase());
                // Request access token for the user from the provider
                dispatch(requestAccessTokenSSO(code, providerName, redirectURI, null, null, null));
            } else {
                const mlsuniqueidentifier = (parsedSearch[
                    SSOParams.MlsUniqueIdentifier
                ] as unknown) as string;
                const mlsName = (parsedSearch[SSOSearchParams.MlsName] as unknown) as string;

                if (mlsuniqueidentifier) {
                    handleDeepLink(mlsuniqueidentifier?.toLowerCase());
                } else if (mlsName) {
                    handleDeepLink(mlsName?.toLowerCase());
                } else {
                    handleDeepLink('sef');
                }
            }
        }
        return () => {
            sessionStorage.setItem('appRefresh', 'false');
        };
    });
    return <>{props.children}</>;
}

export default (SSO as unknown) as any;
