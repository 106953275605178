import { createSelector } from 'reselect';

export const getShowingsState = (state: any) => state.showingsState;

export const getShowings = createSelector(
    getShowingsState,
    (showingsState) => showingsState.showings,
);

export const getShowingsLoading = createSelector(
    getShowingsState,
    (showingsState) => showingsState.loading,
);

export const getUpdatingShowing = createSelector(
    getShowingsState,
    (showingsState) => showingsState.updatingShowing,
);

export const getShowingsFilter = createSelector(
    getShowingsState,
    (showingsState) => showingsState.passedFilter,
);
export const getFeedbackError = createSelector(
    getShowingsState,
    (showingsState) => showingsState.feedbackError,
);
export const getFeedbackSubmitting = createSelector(
    getShowingsState,
    (showingsState) => showingsState.submittingFeedback,
);

export const getUpdateLog = createSelector(
    getShowingsState,
    (showingsState) => showingsState.updateLog,
);