import {
    CONFIRM_MY_LISTING_REQUEST_FAILED,
    CONFIRM_MY_LISTING_REQUEST_SUCCEEDED,
    CONFIRM_MY_LISTING_REQUEST_CLEAR,
    FETCH_CONNECTION_PRICING,
} from './actions';
import {
    MY_LISTINGS_ACTION,
    SHOWING_HISTORY_ACTION,
    SHOWING_REQUESTS_ACTION,
    TEXT_SEARCH_LISTINGS_ACTION,
    UNCONNECTED_LISTINGS_ACTION,
    SHOWING_REVIEW_ACTION,
    CLIENT_FETCH_ACTION,
    CLIENT_ADD_ACTION,
    AGENT_TEXT_SEARCH_ACTION,
    CONNECT_LISTING_ACTION,
} from './types';
import { STATUS } from '../../../utils/constants';

export const initialState = {
    loading: false,
    listingReviews: [],
    connectedListings: [],
    unconnectedListings: [],
    listingReviewError: [],
    loadingListingReviews: false,
    showingsGroupedByListing: [],
    errors: [],
    loadingShowingRequests: false,
    history: [],
    loadingMyListings: false,
    confirmShowingErrors: [],
    showingRequestUpdated: false,

    fetchingClients: false,
    clients: [],

    addedClientData: {},
    addingClient: false,
    addClientError: [],

    searchedAgents: [],
    searchingAgents: false,
    searchAgentsErrors: [],

    connectingListing: false,
    connectListingErrors: [],
    connectListingSuccess: false,

};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case MY_LISTINGS_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingMyListings: true,
                    };
                case STATUS.Succeeded:
                    const { connectedListings } = action;

                    return {
                        ...state,
                        connectedListings: connectedListings,
                        loadingMyListings: false,
                    };
                case STATUS.Failed:
                    let tempErrors: string[] = state.errors;
                    tempErrors.push(action.error);
                    return {
                        ...state,
                        errors: tempErrors,
                        loadingMyListings: false,
                    };
            }
        case SHOWING_REVIEW_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingListingReviews: true,
                    };
                case STATUS.Succeeded:
                    const { listingReviews } = action;
                    return {
                        ...state,
                        listingReviews: listingReviews,
                        loadingListingReviews: false,
                    };
                case STATUS.Failed:
                    const { listingReviewError } = action;
                    return {
                        ...state,
                        listingReviewError: listingReviewError,
                        loadingListingReviews: false,
                    };
            }

        case SHOWING_REQUESTS_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingShowingRequests: true,
                    };
                case STATUS.Succeeded:
                    const { showingsGroupedByListing } = action;
                    return {
                        ...state,
                        loadingShowingRequests: false,
                        showingsGroupedByListing,
                    };
                case STATUS.Failed:
                    const { error } = action;
                    return {
                        ...state,
                        loadingShowingRequests: false,
                        error,
                    };
            }
        case TEXT_SEARCH_LISTINGS_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { searchResults } = action;
                    return {
                        ...state,
                        loading: false,
                        textSearchedListings: searchResults,
                    };
                case STATUS.Failed:
                    const { error } = action;
                    return {
                        ...state,
                        loading: false,
                        error,
                    };
            }
        case SHOWING_HISTORY_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { history } = action;
                    return {
                        ...state,
                        loading: false,
                        history,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        loading: false,
                        errors: errors,
                    };
            }

        case SHOWING_HISTORY_ACTION.Clear: {
            return {
                ...state,
                history: [],
            };
        }
        case CONFIRM_MY_LISTING_REQUEST_FAILED:
            return {
                ...state,
                confirmShowingErrors: action.errors,
            };

        case CONFIRM_MY_LISTING_REQUEST_SUCCEEDED:
            return {
                ...state,
                showingRequestUpdated: true,
            };

        case CONFIRM_MY_LISTING_REQUEST_CLEAR:
            return {
                ...state,
                showingRequestUpdated: false,
            };

        case CLIENT_FETCH_ACTION:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        fetchingClients: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        fetchingClients: false,
                        clients: action.clients,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        fetchingClients: false,
                        errors: action.errors,
                    };
            }

        case CLIENT_ADD_ACTION:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        addingClient: true,
                        addClientError: [],
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        addingClient: false,
                        addedClientData: action.response,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        addingClient: false,
                        addClientError: action.errors,
                    };
                case STATUS.Clear:
                    return {
                        ...state,
                        addedClientData: {},
                    };
            }

        case AGENT_TEXT_SEARCH_ACTION:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        searchingAgents: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        searchingAgents: false,
                        searchedAgents: action.response,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        searchingAgents: false,
                        searchAgentsErrors: action.errors,
                    };
            }

        case CONNECT_LISTING_ACTION:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        connectingListing: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        connectingListing: false,
                        connectListingSuccess: true,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        connectingListing: false,
                        connectListingErrors: action.errors,
                    };
                case STATUS.Clear:
                    return {
                        ...state,
                        connectingListing: false,
                        connectListingSuccess: false,
                        connectListingErrors: [],
                    };
            }

        case FETCH_CONNECTION_PRICING:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        connectionPrice: action.listingConnectionPrice,
                        loading: false,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        errors: action.errors,
                        loading: false,
                    };
            }

        default: {
            return state;
        }
    }
}
