export type NewClient = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
};

export const DummyContact: any = {
    firstName: 'Jonathan',
    lastName: 'Doe',
    phoneNumber: '5555555555',
};
