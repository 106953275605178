import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

interface LoaderProps {
    size?: number;
}

export default (props: LoaderProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const HEIGHT_WIDTH_RATIO = 1.75;
    const DEFAULT_SIZE = 50;
    const height = props.size || DEFAULT_SIZE;
    const width = props.size ? props.size * HEIGHT_WIDTH_RATIO : DEFAULT_SIZE * HEIGHT_WIDTH_RATIO;

    const loadingIcon =
        'https://showingly-image-assets.s3.amazonaws.com/showinglyGeneralLoader.gif';
    return (
        <div className={styleSheet.loadingContainer} style={{ height, width }}>
            <img src={loadingIcon} style={{ height, width }} />
        </div>
    );
};
