import React, { useEffect, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, Loader, Typography } from '../../../../components';
import indexTheme from '../../../../theme';
import close from '../../../../images/close.svg';
import Tag from '../../../../components/Tag';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import { ShowingRequest } from '../constants';
import { DateTime } from 'luxon';
import {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
    getStatusInfo,
} from '../../../../utils/common';
import GrayPerson from '../../../../images/darkGreyPerson.svg';
import ButtonComponent from '../../../../components/ButtonComponent';
import RadioButtons from '../../Listings/ConfigureListing/common/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Stars from '../../Listings/Stars';
import { submitFeedback } from '../saga';
import { submitFeedbackRequested } from '../../Showings/actions';
import { getFeedbackError, getFeedbackSubmitting } from '../selectors';

interface Props {
    set: Function;
    modalVisible: boolean;
    ref: any;
    showing: ShowingRequest;
    closeModal: Function;
    changeStatus: Function;
    loading: boolean;
}
const feedbackStarStyle = { marginRight: 4, marginLeft: 0 };

const LeaveFeedbackModal = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const dispatch = useDispatch();
    const submitting = useSelector(getFeedbackSubmitting);
    const error = useSelector(getFeedbackError);
    const [message, setMessage] = useState<string>('');
    const [location, setLocation] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [interest, setInterest] = useState<number>(0);

    const { showing } = props;
    function onStarClick(e: any, i: number, setter: Function) {
        var dim = e.currentTarget.getBoundingClientRect();
        setter(e.pageX - dim.left <= 12 ? i - 0.5 : i);
    }
    useEffect(() => {
        if (!submitting && !error) {
            props.closeModal();
            props.set(false);
        }
    }, [submitting]);
    return (
        <AnimatedModal style={{ top: null, margin: 'auto', width: 812 }} {...props}>
            <div className={styleSheet.header}>
                <Typography
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                    style={{ marginLeft: 15 }}
                >
                    Leave Feedback
                </Typography>
                <img
                    className={styleSheet.closeButton}
                    src={close}
                    onClick={() => {
                        props.closeModal();
                        props.set(false);
                    }}
                />
            </div>
            <div className={styleSheet.feedbackDate}>
                <Typography textStyle={'p1'}>{showing.listing.address.full} </Typography>
                <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                    {showing.listing.address.city}, {showing.listing.address.state}{' '}
                    {showing.listing.address.postalCode}
                </Typography>
            </div>
            <div className={styleSheet.showingTimeDetails} style={{ marginTop: 0 }}>
                <Typography
                    style={{ marginLeft: 15 }}
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                >
                    Please rate the property on the following categories.
                </Typography>
            </div>
            <div className={styleSheet.feedbackStarForm}>
                <div className={styleSheet.feedbackStarFormRow}>
                    <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                        Price Accuracy
                    </Typography>
                    <span className={styleSheet.feedbackSeparator}></span>

                    <span>
                        <Stars
                            value={price}
                            starStyle={feedbackStarStyle}
                            onStarClick={(e: any, i: number) => onStarClick(e, i, setPrice)}
                        />
                    </span>
                </div>
                <div className={styleSheet.feedbackStarFormRow}>
                    <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                        Location
                    </Typography>
                    <span className={styleSheet.feedbackSeparator}></span>

                    <span>
                        <Stars
                            value={location}
                            starStyle={feedbackStarStyle}
                            onStarClick={(e: any, i: number) => onStarClick(e, i, setLocation)}
                        />
                    </span>
                </div>
                <div className={styleSheet.feedbackStarFormRow}>
                    <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                        Interest Level
                    </Typography>
                    <span className={styleSheet.feedbackSeparator}></span>

                    <span>
                        <Stars
                            value={interest}
                            starStyle={feedbackStarStyle}
                            onStarClick={(e: any, i: number) => onStarClick(e, i, setInterest)}
                        />
                    </span>
                </div>
            </div>
            <div className={styleSheet.showingTimeDetails} style={{ marginTop: 0 }}>
                <Typography
                    style={{ marginLeft: 15 }}
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                >
                    Additional Feedback
                </Typography>
            </div>
            <div className={styleSheet.textAreaContainer}>
                <textarea
                    className={styleSheet.textArea}
                    placeholder="What additional feedback would you like to give?"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    maxLength={250}
                />
                <div className={styleSheet.circularProgressContainer}>
                    <CircularProgress
                        variant="determinate"
                        size={36}
                        thickness={6}
                        style={{ color: '#d3d3d3' }}
                        value={100}
                    />
                    <CircularProgress
                        variant="determinate"
                        size={36}
                        thickness={6}
                        style={{ color: '#48A4FF', position: 'absolute', left: 0 }}
                        value={(message.length * 100) / 250}
                    />
                </div>
            </div>

            <div className={styleSheet.bottomButtonContainer}>
                {props.loading ? (
                    <Loader />
                ) : (
                    <ButtonComponent
                        disabled={submitting || message.length < 10}
                        fill
                        width={200}
                        onClick={() => {
                            dispatch(
                                submitFeedbackRequested({
                                    priceAccuracyRating: price,
                                    locationRating: location,
                                    interestLevel: interest,
                                    reviewText: message,
                                    showingId: showing._id,
                                }),
                            );
                        }}
                    >
                        Send
                    </ButtonComponent>
                )}
            </div>
        </AnimatedModal>
    );
};
export default LeaveFeedbackModal;
