import React, { useState, useEffect } from 'react';
import styles from '../styles';
import { createUseStyles, useTheme } from 'react-jss';
import CheckMarkComponent from '../../../../../../components/CheckmarkComponent';
import { getDateDifference } from '../../../utils';
import blueCheckMark from '../../../../../../images/blueCheck.svg';
import { all } from 'ramda';
interface CRMRowProps {
    row: any;
    showBlueTick?: boolean;
    customStyle?: Object;
    checked: boolean;
    setChecked: Function;
}
const CRMRow = (props: CRMRowProps) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const { row, showBlueTick, customStyle, checked, setChecked } = props;

    return (
        <div className={styleSheet.row} style={customStyle}>
            <div className={styleSheet.checkBox}>
                <CheckMarkComponent disabled={false} checked={checked} setChecked={setChecked} />
            </div>
            <div className={styleSheet.blueCheck}>
                {showBlueTick && <img src={blueCheckMark} />}
            </div>

            <div className={styleSheet.rowData}>
                {Object.keys(row).map(
                    (d, i) =>
                        d !== '_id' &&
                        d !== 'selected' && (
                            <div
                                className={
                                    d === 'Email'
                                        ? styleSheet.infoEmail
                                        : d === 'Phone'
                                        ? styleSheet.infoPhone
                                        : styleSheet.info
                                }
                                key={i}
                            >
                                {typeof row[d] === 'object' ? row[d].render : row[d]}
                            </div>
                        ),
                )}
            </div>
        </div>
    );
};

export default CRMRow;
