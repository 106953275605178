import React, { useEffect, useLayoutEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { DateTime } from 'luxon';
import {
    fetchListingByKeyRequested,
    fetchUnverifiedShowingRequested,
    updateUnverifiedShowingStatusRequested,
} from '../actions';
import { useLocation } from 'react-router-dom';
import {
    getFetchedUnverifiedConfirmationShowing,
    getFetchedUnverifiedListing,
    getUpdateUnverifiedShowingLoading,
} from '../selectors';
import { ClickableWithFeedback, HeaderBar, Loader, Typography } from '../../../components';
import ButtonComponent from '../../../components/ButtonComponent';
import { getApprovalsText } from '../NonMlsShowingRequests/utils';

const UnverifiedShowingConfirmationScreen = () => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const dispatch = useDispatch();
    const { showing = {}, loading = true, listing = {} } =
        useSelector(getFetchedUnverifiedConfirmationShowing) || {};
    const updateLoading = useSelector(getUpdateUnverifiedShowingLoading);

    const loc = useLocation();
    const strings = loc.pathname.split('/');
    const key = strings[strings.length - 1];

    useEffect(() => {
        dispatch(fetchUnverifiedShowingRequested(key));
    }, []);

    /**
     * Make sure that the schedule calendar resizes when the screen
     * is resized.
     */
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useLayoutEffect(() => {
        const resize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resize);
        setScreenWidth(window.innerWidth);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const rightContent = (
        <ClickableWithFeedback
            onClick={() => window.open('https://open.agent.showingly.com/downloads')}
        >
            <Typography textStyle={screenWidth > 385 ? 'h4' : 'b3'} color={theme.palette.blue}>
                Download the App
            </Typography>
        </ClickableWithFeedback>
    );

    const approvalText = getApprovalsText(
        listing?.agentListing?.approvalSettings?.approvalType,
        showing?.approvals,
        showing?.status,
    );

    const showingContent = (
        <div className={styleSheet.showingContentContainer}>
            <div className={styleSheet.header}>
                <Typography textStyle={'h4'} color={theme.palette.mediumGrey}>
                    New Showing Request
                </Typography>
            </div>
            <div className={styleSheet.bodyA}>
                <Typography textStyle={'h4'}>{listing?.address?.full}</Typography>
                <Typography textStyle={'h4'} style={{ ...theme.typography.medium }}>
                    {`${listing?.address?.city}, ${listing?.address?.state} ${listing?.address?.postalCode}`}
                </Typography>
                <Typography textStyle={'b1'} style={{ marginTop: 16 }}>
                    {DateTime.fromJSDate(showing?.start).toFormat('DDDD')}
                </Typography>
                <Typography textStyle={'b1'}>{`${DateTime.fromJSDate(showing?.start).toFormat(
                    't',
                )} - ${DateTime.fromJSDate(showing?.end).toFormat('t')}`}</Typography>
            </div>
            <div className={styleSheet.footer}>
                {updateLoading ? (
                    <Loader />
                ) : !approvalText?.length ? (
                    <>
                        <ButtonComponent
                            width={121}
                            onClick={() =>
                                dispatch(
                                    updateUnverifiedShowingStatusRequested(showing._id, 'denied'),
                                )
                            }
                        >
                            Deny
                        </ButtonComponent>
                        <div style={{ width: 16 }} />
                        <ButtonComponent
                            width={121}
                            fill={true}
                            onClick={() =>
                                dispatch(
                                    updateUnverifiedShowingStatusRequested(
                                        showing._id,
                                        'confirmed',
                                    ),
                                )
                            }
                        >
                            Approve
                        </ButtonComponent>
                    </>
                ) : (
                    <Typography textStyle={'h4'} style={{ ...theme.typography.medium }}>
                        {approvalText}
                    </Typography>
                )}
            </div>
        </div>
    );

    return (
        <div className={styleSheet.root}>
            <HeaderBar rightContent={rightContent} />
            {loading && (!showing?._id || !listing?._id) ? (
                <div className={styleSheet.loadingContainer}>
                    <Loader size={90} />
                </div>
            ) : (
                showingContent
            )}
        </div>
    );
};
export default UnverifiedShowingConfirmationScreen;
