import { all, call, put, takeLatest } from 'redux-saga/effects';
import { callStitchFunction } from '../../../store/api/sagas';
import {
    ListingDetailsActions,
    fetchRecipientsSucceeded,
    fetchRecipientsFailed,
    sendBlastMessageRequested,
    sendBlastMessageSucceeded,
    sendBlastMessageFailed,
} from './actions';
import { STATUS } from '../../../utils/constants';

export function* fetchRecipientsList({ listingId }: any): Generator<any, any, any> {
    if (!listingId) {
        return;
    } else {
        try {
            const response = yield call(callStitchFunction, 'getMessageBlastRecipients', listingId);
            yield put(fetchRecipientsSucceeded(response));
        } catch (error: any) {
            yield put(fetchRecipientsFailed(error));
        }
    }
}
export function* sendMessage({
    listingId,
    message,
    privateRecipients,
    notifications,
    when,
    callback,
}: ReturnType<typeof sendBlastMessageRequested>): Generator<any, any, any> {
    if (!listingId) {
        return;
    } else {
        try {
            const response = yield call(
                callStitchFunction,
                'createBlastMessage',
                listingId,
                message,
                privateRecipients,
                notifications,
                when,
            );
            yield put(sendBlastMessageSucceeded(listingId, message, privateRecipients));
            callback();
        } catch (error: any) {
            yield put(sendBlastMessageFailed(error));
        }
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === ListingDetailsActions.fetch && action.status === STATUS.Requested,
            fetchRecipientsList,
        ),
        takeLatest(
            (action: any) =>
                action.type === ListingDetailsActions.send && action.status === STATUS.Requested,
            sendMessage,
        ),
    ]);
}
