import { createAction } from '@reduxjs/toolkit';
import { NewClient } from './CRMAddClientsModal/constants';

export const ADD_CLIENT_DRAWER_PREFIX = 'clients/';

// #region SEARCH CLIENTS
// ===========================================================================================

export const FETCH_CLIENTS_REQUESTED = ADD_CLIENT_DRAWER_PREFIX + 'FETCH_CLIENTS_REQUESTED';
export const FETCH_CLIENTS_SUCCEEDED = ADD_CLIENT_DRAWER_PREFIX + 'FETCH_CLIENTS_SUCCEEDED';
export const FETCH_CLIENTS_FAILED = ADD_CLIENT_DRAWER_PREFIX + 'FETCH_CLIENTS_FAILED';

export const fetchClientsRequested = () => {
    return {
        type: FETCH_CLIENTS_REQUESTED,
    } as const;
};
export const fetchClientsSucceeded = (fetchedClients: any) => {
    return {
        type: FETCH_CLIENTS_SUCCEEDED,
        fetchedClients,
    } as const;
};
export const fetchClientsFailed = (errors: any) => {
    return {
        type: FETCH_CLIENTS_FAILED,
        errors,
    } as const;
};

// #endregion SEARCH CLIENTS

// #region SET SELECTED CLIENTS
// ===========================================================================================

export const SET_SELECTED_CLIENTS_REQUESTED =
    ADD_CLIENT_DRAWER_PREFIX + 'SET_SELECTED_CLIENTS_REQUESTED';

export const setSelectedClientsRequested = (selectedClients: any) => {
    return {
        type: SET_SELECTED_CLIENTS_REQUESTED,
        selectedClients,
    } as const;
};

// #endregion SET SELECTED CLIENTS

// #region DELETE CLIENTS
// ===========================================================================================
export const DELETE_CLIENTS_REQUESTED = ADD_CLIENT_DRAWER_PREFIX + 'DELETE_CLIENTS_REQUESTED';
export const DELETE_CLIENTS_SUCCEEDED = ADD_CLIENT_DRAWER_PREFIX + 'DELETE_CLIENTS_SUCCEEDED';
export const DELETE_CLIENTS_FAILED = ADD_CLIENT_DRAWER_PREFIX + 'DELETE_CLIENTS_FAILED';

export const deleteClientsRequested = (clientsToDelete: Array<any>) => {
    return {
        type: DELETE_CLIENTS_REQUESTED,
        clientsToDelete,
    } as const;
};
export const deleteClientsSucceeded = (deleteMessage: string) => {
    return {
        type: DELETE_CLIENTS_SUCCEEDED,
        deleteMessage,
    } as const;
};
export const deleteClientsFailed = (errors: any) => {
    return {
        type: DELETE_CLIENTS_FAILED,
        errors,
    } as const;
};

// #endregion DELETE CLIENTS

// #region RESET ERROR
// ===========================================================================================

export const RESET_RESPONSE_DATA_REQUESTED =
    ADD_CLIENT_DRAWER_PREFIX + 'RESET_RESPONSE_DATA_REQUESTED';

export const resetResponseDataRequested = () => {
    return {
        type: RESET_RESPONSE_DATA_REQUESTED,
    } as const;
};

// #endregion SET SELECTED CLIENTS

// #region ADD CLIENTS
// ===========================================================================================

export const ADD_CLIENTS_REQUESTED = ADD_CLIENT_DRAWER_PREFIX + 'ADD_CLIENTS_REQUESTED';
export const ADD_CLIENTS_SUCCEEDED = ADD_CLIENT_DRAWER_PREFIX + 'ADD_CLIENTS_SUCCEEDED';
export const ADD_CLIENTS_FAILED = ADD_CLIENT_DRAWER_PREFIX + 'ADD_CLIENTS_FAILED';

export const addClientsRequested = createAction<Array<NewClient>>(ADD_CLIENTS_REQUESTED);
export const addClientsSucceeded = createAction<{ clientsAdded: number; clientsFailed: number }>(
    ADD_CLIENTS_SUCCEEDED,
);
export const addClientsFailed = createAction<any>(ADD_CLIENTS_FAILED);

// #endregion ADD CLIENTS
