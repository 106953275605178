export default (theme: any) => {
    return {
        headerContainer: {
            height: 86,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        innerHeaderContainer: {
            ...theme.marginHorizontal(48),
        },
        closeIconContainer: {
            position: 'absolute',
            right: 26.91,
            top: 26.23,
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
        },
        drawerHeaderText: {
            fontFamily: 'Poppins-medium',
            fontSize: 18,
            color: theme.palette.mediumGrey,
            paddingTop: 27,
        },

        searchBarContainer: {
            margin: {
                left: 48,
                right: 48,
                top: 16,
            },
            padding: { bottom: 16 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        searchBar: {
            ...theme.typography.medium,
            backgroundColor: theme.palette.background,
            height: 30,
            width: '100vw',
            color: theme.palette.darkGrey,
            fontSize: 16,
            borderRadius: 5,
            borderWidth: 0,
            paddingLeft: 10,
        },

        clientsContainer: {
            margin: {
                left: 48,
                right: 48,
            },
            overflowY: 'scroll',
        },
        clientOption: {
            display: 'flex',
            height: 43,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            ...theme.typography.medium,
            color: theme.palette.black,
            fontSize: 16,
            paddingLeft: 10,
            alignItems: 'center',
        },

        addClientButton: {
            display: 'flex',
            ...theme.typography.semiBold,
            fontSize: 14,
            color: theme.palette.blue,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 16,
        },

        loaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
};
