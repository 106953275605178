import * as Actions from './actions';
import { PAGINATION_LIMIT } from './constants';

export const initialState = {
    loading: false,
    showings: {
        buySide: {
            All: null,
            'Needs Action': null,
            Upcoming: null,
        },
        listSide: {
            All: null,
            'Needs Action': null,
            Upcoming: null,
        },
    },
    // The showings filter is on state so we can manipulate it from other screens like the CRM.
    passedFilter: {},
    submittingFeedback: false,
    feedbackError: null,
    updateLog:{
        //Screen to Update after a new showing requested
        //False = need to update, True = Don´t need update
        showing: false,
        calendar: false,
        //Actions to be done after a new showing requested
        detailMsg: false,
        updateMSg: false,
    }
};

export default function (state = initialState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case Actions.FETCH_SHOWINGS_REQUESTED: {
            const { option, filterOption, page, filterId } = payload;
            const camelOption = option?.[0].toLowerCase() + option?.replace(' ', '').substring(1);
            return {
                ...state,
                loading: {
                    option: camelOption,
                    filterOption: filterId ? filterId : filterOption,
                    page: page,
                },
            };
        }
        case Actions.FETCH_SHOWINGS_SUCCEEDED: {
            const { payload } = action;
            const oldShowings = state.showings;
            const newShowings = payload;
            const updatedShowings = {
                buySide: { ...oldShowings.buySide, ...newShowings.buySide },
                listSide: { ...oldShowings.listSide, ...newShowings.listSide },
            };
            return {
                ...state,
                loading: false,
                showings: updatedShowings,
            };
        }
        case Actions.CHANGE_SHOWING_STATUS_REQUESTED: {
            return {
                ...state,
                updatingShowing: payload.id.toString(),
            };
        }
        case Actions.CHANGE_SHOWING_STATUS_SUCCEEDED: {
            return {
                ...state,
                updatingShowing: '',
            };
        }
        case Actions.CHANGE_SHOWING_STATUS_FAILED: {
            return {
                ...state,
                updatingShowing: '',
            };
        }
        case Actions.UPDATE_SHOWINGS_FILTER: {
            return {
                ...state,
                passedFilter: payload,
            };
        }
        case Actions.SUBMIT_FEEDBACK_REQUESTED: {
            return {
                ...state,
                submittingFeedback: true,
                feedbackError: null,
            };
        }
        case Actions.SUBMIT_FEEDBACK_SUCCEEDED: {
            return {
                ...state,
                submittingFeedback: false,
            };
        }
        case Actions.SUBMIT_FEEDBACK_FAILED: {
            const { error } = payload;
            return {
                ...state,
                submittingFeedback: false,
                feedbackError: error,
            };
        }
        case Actions.NEW_SHOWING_REQUESTED: {
            return {
                ...state,
                updateLog:{
                    //Screen to Update after a new showing requested
                    //False = need to update, True = Don´t need update
                    showing: false,
                    calendar: false,
                    //Actions to be done after a new showing requested
                    //true = show msg, false = don´t show msg
                    detailMsg: true,
                    updateMSg: false,
                }
            }
        }
        case Actions.UPDATE_SHOWING_REQUESTED: {
            return {
                ...state,
                updateLog:{
                    //Screen to Update after a new showing requested
                    //False = need to update, True = Don´t need update
                    showing: false,
                    calendar: false,
                    //Actions to be done after a new showing requested
                    //true = show msg, false = don´t show msg
                    detailMsg: true,
                    updateMSg: true,
                }
            }
        }
        case Actions.DETAIL_MESSEGE_CLOSED: {
            const logValues = state.updateLog
            return {
                ...state,
                updateLog:{
                    showing: logValues.showing,
                    calendar: logValues.calendar,
                    detailMsg: false,
                    updateMSg: false,
                }
            };
        }
        case Actions.SHOWING_REFRESH_SUCCEEDED: {
            const logValues = state.updateLog
            return {
                ...state,
                updateLog:{
                    showing: true,
                    calendar: logValues.calendar,
                    detailMsg: logValues.detailMsg,
                    updateMSg: logValues.updateMSg,
                }
            };
        }
        case Actions.CALENDAR_REFRESH_SUCCEEDED: {
            const logValues = state.updateLog
            return {
                ...state,
                updateLog:{
                    showing: logValues.showing,
                    calendar: true,
                    detailMsg: logValues.detailMsg,
                    updateMSg: logValues.updateMSg,
                }
            };
        }
        default:
            return state;
    }
}
