import { view } from 'ramda';
import { createSelector } from 'reselect';
import { transformUser } from './ConfigureListing/utils';

export const getListingsState = (state: any) => state.listings;

export const getListings = createSelector(getListingsState, (listings) => listings.listings);

export const getSelectedListing = createSelector(
    getListingsState,
    (listings) => listings.selectedListing,
);

export const getLoading = createSelector(getListingsState, (listings) => listings.loading);

export const getConfigurationDetails = createSelector(
    getListingsState,
    (listings) => listings.configurationDetails,
);

export const getSearchedAgents = createSelector(getListingsState, (listings) => listings.agents);

export const getSearchedConsumers = createSelector(
    getListingsState,
    (listings) => listings.consumers,
);

export const getConnectionErrors = createSelector(
    getListingsState,
    (listings) => listings.connectionErrors,
);

export const getConnectionSuccess = createSelector(
    getListingsState,
    (listings) => listings.connectListingSucceeded,
);

export const getConnectLoading = createSelector(
    getListingsState,
    (listings) => listings.connectLoading,
);

export const getAddClientLoading = createSelector(
    getListingsState,
    (listings) => listings.addClientLoading,
);

export const getNewClient = createSelector(getListingsState, (listings) => listings.newClient);

export const getLockboxImage = createSelector(
    getListingsState,
    (listings) => listings.lockboxImage,
);

export const getSelectedView = createSelector(
    getListingsState,
    (listings) => listings.selectedView,
);