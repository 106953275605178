import { STATUS } from '../../../store/api/constants';
import { BSON } from 'realm-web';
import { PaymentMethod } from '../../../utils/common/index';
import { FormattedMarkets } from './ProfileScreenOld';

export const PROFILE_ACTIONS_PREFIX = 'profile/';

export const GET_PROFILE_DATA_REQUESTED = `${PROFILE_ACTIONS_PREFIX}GET_PROFILE_DATA_REQUESTED`;
export const GET_PROFILE_DATA_SUCCEEDED = `${PROFILE_ACTIONS_PREFIX}GET_PROFILE_DATA_SUCCEEDED`;
export const GET_PROFILE_DATA_FAILED = `${PROFILE_ACTIONS_PREFIX}GET_PROFILE_DATA_FAILED`;

export const UPDATE_PROFILE_DATA_REQUESTED = `${PROFILE_ACTIONS_PREFIX}UPDATE_PROFILE_DATA_REQUESTED`;
export const UPDATE_PROFILE_DATA_SUCCEEDED = `${PROFILE_ACTIONS_PREFIX}UPDATE_PROFILE_DATA_SUCCEEDED`;
export const UPDATE_PROFILE_DATA_FAILED = `${PROFILE_ACTIONS_PREFIX}UPDATE_PROFILE_DATA_FAILED`;

export const UPDATE_UPGRADE_MODAL_VISIBILITY =
    PROFILE_ACTIONS_PREFIX + 'UPDATE_UPGRADE_MODAL_VISIBILITY';

export enum PROFILE_ACTIONS {
    AddBrokerageToAgent = '[Profile] Add Brokerage To Agent',
    BrokerageSearch = '[Profile] Brokerage Search',
    ErrorHandling = '[Profile] Error Handling',
    ShowinglyPlus = '[Profile] Showingly Plus',
    ShowinglyPlusBrokerage = '[Profile] Showingly Plus Brokerage',
    ProfilePhotoUpdate = '[Profile] Profile Photo Update',
    UpdatePhoneNumber = '[Profile] Update Phone Number',
    AddCard = '[Profile] Add Card',
    AddCardAndSubscribe = '[Profile] Add Card and Subscribe',
    Subscribe = '[Profile] Subscribe',
    SetStripeActionStatus = '[Profile] Set Stripe Action Status',
    setModalType = '[Profile] Set Modal Type',
    PaymentModal = '[PaymentModal] Set Modal Type',
    SetPrice = '[Profile] Set Price',
    FetchPricing = '[Profile] Fetch Pricing',
    SetSubscribedPrice = '[Profile] Set Subscribed Price',
    RemovePayment = '[Profile] Remove Payment Method',
    ResetRemovePayment = '[Agent Profile] Reset Removed Payment',
    FetchPaymentMethods = '[Profile Fetch Payment Methods',
    SetProfileData = '[Profile] Set Profile Data',
    CancelSubscription = '[Profile] Cancel Subscription',
    Verify = '[Profile] Verify Phone Number',
    ClearVerification = '[Profile] Clear Phone Verification State',
    VerifyLicenseCode = '[Profile] Verify License',
    LinkAgentAccounts = '[Profile] Link Agent Accounts',
    FetchMyBrokerages = '[Profile] Fetch My Brokerages',
    FetchSubscriptionPaymentMethod = '[Profile] Fetch Subscription Payment Method',
    UpdateSubscriptionPaymentMethod = '[Profile] Update Subscription Payment Method',
    FetchBankAccounts = '[Profile] Fetch Bank Accounts',
    RequestStripeLink = '[Profile] Request Stripe Link',
    SetStripeLink = '[Profile] Set Stripe Link',
    SetStripeOAuthLink = '[Profile] Set Stripe OAuth Link',
    ChangePayment = '[Profile] Change Payment',
    AddCardAndChangePayment = '[Profile] Add Card and Change Payment',
    UpdateNotifications = '[Profile] Update Notifications',
    SetIsDelinquent = '[Profile] Set Is Delinquent',
    PayStripeInvoice = '[Profile] Pay Stripe Invoice',
    Downgrade = '[Profile] Downgrade',
}

export { STATUS } from '../../../store/api/constants';

export const getProfileDataRequested = (requestFromCustomAuth = false) => {
    return <const>{
        type: GET_PROFILE_DATA_REQUESTED,
        status: STATUS.Requested,
        requestFromCustomAuth,
    };
};

export const getProfileDataSucceeded = (
    profileData: object,
    requestFromCustomAuth = false,
    paymentMethods: object,
    invoiceHistory: object,
    formattedMarkets: FormattedMarkets,
) => {
    return <const>{
        type: GET_PROFILE_DATA_SUCCEEDED,
        status: STATUS.Succeeded,
        profileData,
        requestFromCustomAuth,
        paymentMethods,
        invoiceHistory,
        formattedMarkets,
    };
};

export const getProfileDataFailed = (errors: string[] = []) => {
    return <const>{
        type: GET_PROFILE_DATA_FAILED,
        status: STATUS.Failed,
        errors,
    };
};

export const updateProfileDataRequested = (about: string) => {
    return <const>{
        type: UPDATE_PROFILE_DATA_REQUESTED,
        status: STATUS.Requested,
        about,
    };
};

export const updateProfileDataSucceeded = (profileData: any) => {
    return <const>{
        type: UPDATE_PROFILE_DATA_SUCCEEDED,
        status: STATUS.Succeeded,
        profileData,
    };
};

export const updateProfileDataFailed = (errors = []) => {
    return <const>{
        type: UPDATE_PROFILE_DATA_FAILED,
        status: STATUS.Failed,
        errors,
    };
};

export const enrollAgentInShowinglyPlusRequested = (token: any) => {
    return <const>{
        type: PROFILE_ACTIONS.ShowinglyPlus,
        status: STATUS.Requested,
        token,
    };
};

export const enrollAgentInShowinglyPlusSucceeded = () => {
    return <const>{
        type: PROFILE_ACTIONS.ShowinglyPlus,
        status: STATUS.Succeeded,
    };
};

export const enrollAgentInShowinglyPlusFailed = (errors: string[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.ShowinglyPlus,
        status: STATUS.Failed,
        errors,
    };
};
export const setShowinglyPlusStatus = (newStatus: boolean = false) => {
    return <const>{
        type: PROFILE_ACTIONS.ShowinglyPlus,
        status: STATUS.Succeeded,
        newStatus,
    };
};

export const setBrokerage = (brokerageData: any = {}) => {
    return <const>{
        type: PROFILE_ACTIONS.ShowinglyPlusBrokerage,
        status: STATUS.Succeeded,
        brokerageData,
    };
};

export const searchBrokerageRequested = (marketName: string, searchText: string) => {
    return <const>{
        type: PROFILE_ACTIONS.BrokerageSearch,
        status: STATUS.Requested,
        marketName,
        searchText,
    };
};

export const searchBrokerageSucceeded = (searchedBrokerages: any[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.BrokerageSearch,
        status: STATUS.Succeeded,
        searchedBrokerages,
    };
};

export const searchBrokerageFailed = (errors: string[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.BrokerageSearch,
        status: STATUS.Failed,
        errors,
    };
};

type AgentInfo = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

export const addBrokerageToAgentRequested = (brokerageId: BSON.ObjectId, agentInfo: AgentInfo) => {
    return <const>{
        type: PROFILE_ACTIONS.AddBrokerageToAgent,
        status: STATUS.Requested,
        brokerageId,
        agentInfo,
    };
};

export const addBrokerageToAgentSucceeded = () => {
    return <const>{
        type: PROFILE_ACTIONS.AddBrokerageToAgent,
        status: STATUS.Succeeded,
    };
};

export const addBrokerageToAgentFailed = (errors: string[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.AddBrokerageToAgent,
        status: STATUS.Failed,
        errors,
    };
};

export const clearErrors = () => {
    return <const>{
        type: PROFILE_ACTIONS.ErrorHandling,
        status: STATUS.Requested,
    };
};

export const updateProfilePhotoSucceeded = (uri: any) => {
    return <const>{
        type: PROFILE_ACTIONS.ProfilePhotoUpdate,
        status: STATUS.Succeeded,
        uri,
    };
};

export const updateProfilePhotoRequested = (photo: any) => {
    return <const>{
        type: PROFILE_ACTIONS.ProfilePhotoUpdate,
        status: STATUS.Requested,
        photo,
    };
};
export const updateProfilePhotoFailed = (errors: string[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.ProfilePhotoUpdate,
        status: STATUS.Failed,
        errors,
    };
};

export const updateProfilePhoneNumberRequested = (newPhoneNumber: string) => {
    return <const>{
        type: PROFILE_ACTIONS.UpdatePhoneNumber,
        status: STATUS.Requested,
        newPhoneNumber,
    };
};

export const updateProfilePhoneNumberSucceeded = (newPhoneNumber: string) => {
    return <const>{
        type: PROFILE_ACTIONS.UpdatePhoneNumber,
        status: STATUS.Succeeded,
        newPhoneNumber,
    };
};

export const updateProfilePhoneNumberFailed = (errors: string[] = []) => {
    return <const>{
        type: PROFILE_ACTIONS.UpdatePhoneNumber,
        status: STATUS.Failed,
        errors,
    };
};

export function addCardRequested(paymentMethodId: string, name: any, zip: any) {
    return {
        type: PROFILE_ACTIONS.AddCard,
        status: STATUS.Requested,
        paymentMethodId,
        name,
        zip,
    };
}

export function addCardSucceeded() {
    return {
        type: PROFILE_ACTIONS.AddCard,
        status: STATUS.Succeeded,
    };
}

export function addCardFailed(error: string) {
    return {
        type: PROFILE_ACTIONS.AddCard,
        status: STATUS.Failed,
        paymentError: error,
    };
}

export function addCardAndSubscribeRequested(
    paymentMethodId: string,
    priceId: string,
    name: any,
    zip: any,
) {
    return {
        type: PROFILE_ACTIONS.AddCardAndSubscribe,
        status: STATUS.Requested,
        paymentMethodId,
        priceId,
        name,
        zip,
    };
}

export function addCardAndSubscribeSucceeded() {
    return {
        type: PROFILE_ACTIONS.AddCardAndSubscribe,
        status: STATUS.Succeeded,
    };
}

export function addCardAndSubscribeFailed(error: any) {
    return {
        type: PROFILE_ACTIONS.AddCardAndSubscribe,
        status: STATUS.Failed,
        paymentError: error,
    };
}

export function subscribeRequested(paymentMethodId: string, priceId: string) {
    return {
        type: PROFILE_ACTIONS.Subscribe,
        status: STATUS.Requested,
        paymentMethodId,
        priceId,
    };
}

export function subscribeSucceeded(subscription: Object) {
    return {
        type: PROFILE_ACTIONS.Subscribe,
        status: STATUS.Succeeded,
        subscription,
    };
}

export function subscribeFailed(errors: any) {
    return {
        type: PROFILE_ACTIONS.Subscribe,
        status: STATUS.Failed,
        errors,
    };
}

export function downgradeRequested(subscriptionId: any, newPriceId: any) {
    return {
        type: PROFILE_ACTIONS.Downgrade,
        status: STATUS.Requested,
        subscriptionId,
        newPriceId,
    };
}

export function downgradeSucceeded(subscription: any) {
    return {
        type: PROFILE_ACTIONS.Downgrade,
        status: STATUS.Succeeded,
        subscription,
    };
}

export function downgradeFailed(errors: any) {
    return {
        type: PROFILE_ACTIONS.Downgrade,
        status: STATUS.Failed,
        errors,
    };
}

export const setStripeActionStatus = (stripeActionStatus: any) => {
    return {
        type: PROFILE_ACTIONS.SetStripeActionStatus,
        stripeActionStatus,
    };
};

export const setModalType = (modalType: any) => {
    return {
        type: PROFILE_ACTIONS.setModalType,
        modalType,
    };
};
export const setPaymentModalType = (modalType: string) => {
    return {
        type: PROFILE_ACTIONS.PaymentModal,
        modalType,
    };
};
export function setSubscriptionPrice(priceId: string) {
    return {
        type: PROFILE_ACTIONS.SetPrice,
        priceId,
    };
}
export const setSubscribedPrice = (priceId: any) => {
    return {
        type: PROFILE_ACTIONS.SetSubscribedPrice,
        status: STATUS.Succeeded,
        priceId,
    };
};
export const fetchPricingRequested = (marketName: string) =>
    <const>{
        type: PROFILE_ACTIONS.FetchPricing,
        status: STATUS.Requested,
        marketName,
    };
export const fetchPricingSucceeded = (proPrice: string, execPrice: string) =>
    <const>{
        type: PROFILE_ACTIONS.FetchPricing,
        status: STATUS.Succeeded,
        proPrice,
        execPrice,
    };
export const fetchPricingFailed = (error: string) =>
    <const>{
        type: PROFILE_ACTIONS.FetchPricing,
        status: STATUS.Failed,
        error,
    };

export const removePaymentMethodsRequested = (paymentMethod: PaymentMethod) =>
    <const>{
        type: PROFILE_ACTIONS.RemovePayment,
        status: STATUS.Requested,
        paymentMethod,
    };

export const removePaymentMethodsSucceeded = (updatedPaymentMethods: PaymentMethod[]) =>
    <const>{
        type: PROFILE_ACTIONS.RemovePayment,
        status: STATUS.Succeeded,
        updatedPaymentMethods,
    };

export const removePaymentMethodsFailed = (error: string[] = []) =>
    <const>{
        type: PROFILE_ACTIONS.RemovePayment,
        status: STATUS.Requested,
        error,
    };

export const resetRemovePaymentMethod = () =>
    <const>{
        type: PROFILE_ACTIONS.ResetRemovePayment,
        status: STATUS.Requested,
    };

export type ProfileActions =
    | ReturnType<typeof removePaymentMethodsRequested>
    | ReturnType<typeof removePaymentMethodsSucceeded>
    | ReturnType<typeof removePaymentMethodsFailed>;

export const getPaymentMethodsRequested = () =>
    <const>{
        type: PROFILE_ACTIONS.FetchPaymentMethods,
        status: STATUS.Requested,
    };

export const getPaymentMethodsSucceeded = (paymentMethods: Object) =>
    <const>{
        type: PROFILE_ACTIONS.FetchPaymentMethods,
        status: STATUS.Succeeded,
        paymentMethods,
    };
export const getPaymentMethodsFailed = (errors: string) =>
    <const>{
        type: PROFILE_ACTIONS.FetchPaymentMethods,
        status: STATUS.Failed,
        errors,
    };

export const setProfileData = (profileData: any) =>
    <const>{
        type: PROFILE_ACTIONS.SetProfileData,
        status: STATUS.Failed,
        profileData,
    };

export const cancelSubscriptionRequested = (subscriptionId: string) => {
    return {
        type: PROFILE_ACTIONS.CancelSubscription,
        status: STATUS.Requested,
        subscriptionId,
    };
};

export const cancelSubscriptionSucceeded = (endDate: string) => {
    return {
        type: PROFILE_ACTIONS.CancelSubscription,
        status: STATUS.Succeeded,
        endDate,
    };
};

export const cancelSubscriptionFailed = (errors: string[] = []) => {
    return {
        type: PROFILE_ACTIONS.CancelSubscription,
        status: STATUS.Failed,
        errors,
    };
};

export const verifyPhoneNumberRequested = (code: any, phoneNumber: string) => {
    return {
        type: PROFILE_ACTIONS.Verify,
        status: STATUS.Requested,
        code,
        phoneNumber,
    };
};

export const verifyPhoneNumberSucceeded = () => {
    return {
        type: PROFILE_ACTIONS.Verify,
        status: STATUS.Succeeded,
    };
};

export const verifyPhoneNumberFailed = (errors: any[] = []) => {
    return {
        type: PROFILE_ACTIONS.Verify,
        status: STATUS.Failed,
        errors,
    };
};

export const clearPhoneVerificationState = () => {
    return {
        type: PROFILE_ACTIONS.ClearVerification,
    };
};

export const verifyLicenseCodeFailed = (errors: string[] = []) => ({
    type: PROFILE_ACTIONS.VerifyLicenseCode,
    status: STATUS.Failed,
    errors,
});

export const linkAgentRequested = (linkingAgentObjId: BSON.ObjectId) => {
    return {
        type: PROFILE_ACTIONS.LinkAgentAccounts,
        status: STATUS.Requested,
        linkingAgentObjId,
    };
};

export const linkAgentSucceeded = () => {
    return {
        type: PROFILE_ACTIONS.LinkAgentAccounts,
        status: STATUS.Succeeded,
    };
};

export const linkAgentFailed = (errors: string[] = []) => {
    return {
        type: PROFILE_ACTIONS.LinkAgentAccounts,
        status: STATUS.Failed,
        errors,
    };
};

// Linked license verification and flow actions

export enum LINKED_LICENSE_ACTIONS {
    sendCode = '[Linked License Actions] Send Verification Code',
    verifyCode = '[Linked License Actions] Verify Code',
    searchMarkets = '[Linked License Actions] Searching Markets',
    searchAgents = '[Linked License Actions] Searching Agents',
    reset = '[Linked License Actions] Reset Flow State',
    updateLicenses = '[Linked License Actions] Update Profile Linked Licenses',
}

export const sendLinkedLicenseVerificationCodeRequested = (
    method: 'email' | 'phone',
    receiver: string,
    isResend: boolean,
) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.sendCode,
        status: STATUS.Requested,
        method,
        receiver,
        isResend,
    };

export const sendLinkedLicenseVerificationCodeSucceeded = () =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.sendCode,
        status: STATUS.Succeeded,
    };

export const sendLinkedLicenseVerificationCodeFailed = (sendCodeError: string) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.sendCode,
        status: STATUS.Failed,
        sendCodeError,
    };

export const verifyLinkedLicenseCodeRequested = (
    method: 'email' | 'phone',
    code: any,
    emailOrPhone?: string,
) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.verifyCode,
        status: STATUS.Requested,
        method,
        code,
        emailOrPhone,
    };

export const verifyLinkedLicenseCodeSucceeded = () =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.verifyCode,
        status: STATUS.Succeeded,
    };

export const verifyLinkedLicenseCodeFailed = (verifyCodeError: string) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.verifyCode,
        status: STATUS.Failed,
        verifyCodeError,
    };

export const getMarketRequested = () =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchMarkets,
        status: STATUS.Requested,
    };

export const getMarketSucceeded = (markets: any) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchMarkets,
        status: STATUS.Succeeded,
        markets,
    };

export const getMarketFailed = (marketSearchError: string) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchMarkets,
        status: STATUS.Failed,
        marketSearchError,
    };

export const fetchAgentDocumentsRequested = (searchText: string, marketName: string) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchAgents,
        status: STATUS.Requested,
        searchText,
        marketName,
    };

export const fetchAgentDocumentsSucceeded = (entryAgents: any) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchAgents,
        status: STATUS.Succeeded,
        entryAgents,
    };

export const fetchAgentDocumentsFailed = (agentSearchError: string) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.searchAgents,
        status: STATUS.Failed,
        agentSearchError,
    };

export const resetLinkedLicenseState = () =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.reset,
    };

export const updateLinkLicenses = (updatedMarkets: any) =>
    <const>{
        type: LINKED_LICENSE_ACTIONS.updateLicenses,
        updatedMarkets,
    };

export const fetchMyBrokeragesRequested = (passedAgentId: string | null) =>
    <const>{
        type: PROFILE_ACTIONS.FetchMyBrokerages,
        status: STATUS.Requested,
        passedAgentId,
    };

export const fetchMyBrokeragesSucceeded = (myBrokerages: any[]) =>
    <const>{
        type: PROFILE_ACTIONS.FetchMyBrokerages,
        status: STATUS.Succeeded,
        myBrokerages,
    };

export const fetchMyBrokeragesFailed = (errors: string) =>
    <const>{
        type: PROFILE_ACTIONS.FetchMyBrokerages,
        status: STATUS.Failed,
        errors,
    };

export const fetchSubscriptionPaymentMethodRequested = () => {
    return {
        type: PROFILE_ACTIONS.FetchSubscriptionPaymentMethod,
        status: STATUS.Requested,
    };
};

export const fetchSubscriptionPaymentMethodSucceeded = (subscriptionPaymentMethod: any) => {
    return {
        type: PROFILE_ACTIONS.FetchSubscriptionPaymentMethod,
        status: STATUS.Succeeded,
        subscriptionPaymentMethod,
    };
};

export const fetchSubscriptionPaymentMethodFailed = (errors: any = []) => {
    return {
        type: PROFILE_ACTIONS.FetchSubscriptionPaymentMethod,
        status: STATUS.Failed,
        errors,
    };
};

export const fetchBankAccountsRequested = () => {
    return {
        type: PROFILE_ACTIONS.FetchBankAccounts,
        status: STATUS.Requested,
    };
};

export const fetchBankAccountsSucceeded = (bankAccounts: any) => {
    return {
        type: PROFILE_ACTIONS.FetchBankAccounts,
        status: STATUS.Succeeded,
        bankAccounts,
    };
};

export const fetchBankAccountsFailed = (errors: any = []) => {
    return {
        type: PROFILE_ACTIONS.FetchBankAccounts,
        status: STATUS.Failed,
        errors,
    };
};

export const requestStripeLink = () => {
    return {
        type: PROFILE_ACTIONS.RequestStripeLink,
    };
};

export const setStripeDashboardLink = (stripeLink: any) => {
    return {
        type: PROFILE_ACTIONS.SetStripeLink,
        stripeLink,
    };
};

export const setStripeOAuthLink = (stripeOAuthLink: any) => {
    return {
        type: PROFILE_ACTIONS.SetStripeOAuthLink,
        stripeOAuthLink,
    };
};

export function addCardAndChangePaymentRequested(paymentMethodId: string, name: any, zip: any) {
    return {
        type: PROFILE_ACTIONS.AddCardAndChangePayment,
        status: STATUS.Requested,
        paymentMethodId,
        name,
        zip,
    };
}

export function addCardAndChangePaymentSucceeded() {
    return {
        type: PROFILE_ACTIONS.AddCardAndChangePayment,
        status: STATUS.Succeeded,
    };
}

export function addCardAndChangePaymentFailed(error: any) {
    return {
        type: PROFILE_ACTIONS.AddCardAndChangePayment,
        status: STATUS.Failed,
        paymentError: error,
    };
}

export function changePaymentRequested(paymentMethodId: string) {
    return {
        type: PROFILE_ACTIONS.ChangePayment,
        status: STATUS.Requested,
        paymentMethodId,
    };
}

export function changePaymentSucceeded(subscription: Object) {
    return {
        type: PROFILE_ACTIONS.ChangePayment,
        status: STATUS.Succeeded,
        subscription,
    };
}

export function changePaymentFailed(errors: any) {
    return {
        type: PROFILE_ACTIONS.ChangePayment,
        status: STATUS.Failed,
        errors,
    };
}

export const updateNotificationsRequested = (
    notiType: any,
    pathName: string,
    settingType: any,
    toValue: any,
) => {
    return {
        type: PROFILE_ACTIONS.UpdateNotifications,
        status: STATUS.Requested,
        notiType,
        pathName,
        settingType,
        toValue,
    };
};

export const updateNotificationsSucceeded = (newNotifications: any) => {
    return {
        type: PROFILE_ACTIONS.UpdateNotifications,
        status: STATUS.Succeeded,
        newNotifications,
    };
};

export const updateNotificationsFailed = (errors: any) => {
    return {
        type: PROFILE_ACTIONS.UpdateNotifications,
        status: STATUS.Failed,
        errors,
    };
};

export const setIsDelinquent = (isDelinquent: any) => {
    return {
        type: PROFILE_ACTIONS.SetIsDelinquent,
        isDelinquent,
    };
};

export const payStripeInvoiceRequested = () => {
    return {
        type: PROFILE_ACTIONS.PayStripeInvoice,
        status: STATUS.Requested,
    };
};

export const payStripeInvoiceSucceeded = () => {
    return {
        type: PROFILE_ACTIONS.PayStripeInvoice,
        status: STATUS.Succeeded,
    };
};

export const payStripeInvoiceFailed = () => {
    return {
        type: PROFILE_ACTIONS.PayStripeInvoice,
        status: STATUS.Failed,
    };
};

export const updateUpgradeModalVisible = (value: boolean) => {
    return {
        type: UPDATE_UPGRADE_MODAL_VISIBILITY,
        value,
    };
};
