import React, { useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { ShowingRequest } from '../constants';
import Tag from '../../../../components/Tag';
import indexTheme from '../../../../theme';
import { ClickableWithFeedback, Loader, Typography } from '../../../../components';
import useWindowDimensions, {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
} from '../../../../utils/common';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import GrayPerson from '../../../../images/grayPerson.svg';
import GrayMessage from '../../../../images/grayMessage.svg';
import GrayInfo from '../../../../images/grayInfo.svg';
import GrayMail from '../../../../images/grayMail.svg';
import ForwardArrow from '../../../../images/forwardArrow.svg';
import { DateTime } from 'luxon';
import ButtonComponent from '../../../../components/ButtonComponent';
import { useHistory } from 'react-router-dom';
import AnimatedModal from '../../../../components/AnimatedModal';
import DenialModal from '../Modals/DenialReason';
import ListSideDetails from '../Modals/ListSideDetails';
import DenyModal from '../Modals/DenyModal';
import { useDispatch } from 'react-redux';
import { changeShowingStatusRequested as changeShowingStatus } from '../actions';
import LeaveFeedbackModal from '../Modals/LeaveFeedbackModal';

interface Props {
    showing: ShowingRequest;
    selectedType?: 'buySide' | 'listSide';
    updatingShowing: string;
    onViewFeedback?: Function;
    refresh?: Function;
}
const ShowingCard = ({
    showing,
    selectedType,
    updatingShowing,
    onViewFeedback = () => {},
    refresh = () => {},
}: Props) => {
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...{ width, height }, theme });

    const [sendLockBoxDetails, setSendLockBoxDetails] = useState<boolean>(false);

    const ref = useRef<any>(null);
    const [modal, setModal] = useState<any>(false);
    const history = useHistory();
    let userData =
        selectedType === 'buySide'
            ? showing?.listing?.agent
            : showing?.unverifiedUser
            ? showing.unverifiedUser
            : showing?.agent;
    const phoneNumber =
        selectedType === 'buySide'
            ? showing?.listing?.agentListing
                ? showing?.listing?.agentListing?.agents &&
                  showing?.listing?.agentListing?.agents[0].phoneNumber
                : showing?.listing?.agent?.contact?.cell
                ? showing?.listing?.agent?.contact?.cell
                : showing?.listing?.agent?.contact?.office
            : showing.unverifiedUser
            ? showing.unverifiedUser.phoneNumber
            : showing?.agent?.phoneNumber;
    const email =
        selectedType === 'buySide'
            ? showing?.listing?.agent?.contact?.email
            : showing.unverifiedUser
            ? showing.unverifiedUser.email
            : showing?.agent?.email;
    const office =
        selectedType === 'buySide'
            ? showing?.listing?.office?.name
            : showing.unverifiedUser
            ? showing.unverifiedUser.company
            : showing?.agent?.brokerages?.name;

    const showFeedBackButton =
        selectedType === 'buySide' && showing.status === 'completed' && !showing.feedback;

    const showViewFeedbackButton =
        selectedType === 'listSide' && showing.status === 'completed' && showing.feedback;

    const showDenialButton =
        selectedType === 'buySide' && showing.status === 'denied' && showing.denialReason?.length;

    const isUpdating = updatingShowing === showing?._id?.toString();

    const getStatusInfo = () => {
        switch (showing.status) {
            case 'logged':
                return {
                    text: 'Logged',
                    color: 'green',
                };
            case 'completed':
                return { text: 'Completed', color: 'green' };
            case 'pending_internal':
                return { text: 'Pending', color: 'mediumGrey' };
            case 'pending_external':
                return { text: 'Pending', color: 'mediumGrey' };
            case 'requested':
                return { text: 'Client Request', color: 'mediumGrey' };
            case 'confirmed':
                return { text: 'Confirmed', color: 'blue' };
            case 'denied':
                return { text: 'Denied', color: 'red' };
            case 'cancelled':
                return { text: 'Cancelled', color: 'red' };
            default:
                return { text: 'Confirmed', color: 'green' };
        }
    };
    const topSection = (
        <div className={styleSheet.topSection}>
            <Tag
                style={{ marginRight: 16, marginBottom: 12, fontSize: width > 1310 ? 14 : 12 }}
                text={getStatusInfo().text}
                color={getStatusInfo().color}
            />
            <Typography textStyle={'p1'}>{showing.listing.address.full} </Typography>
            <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                {showing.listing.address.city}, {showing.listing.address.state}{' '}
                {showing.listing.address.postalCode}
            </Typography>
        </div>
    );

    const IconAndText = ({
        topIcon,
        topText,
        bottomIcon,
        bottomText,
        customColor,
    }: {
        topIcon: any;
        topText: string | null;
        bottomIcon: any;
        bottomText: string | null;
        customColor?: string;
    }) => {
        return (
            <div>
                {topText ? (
                    <div style={{ alignItems: 'center', display: 'flex', marginBottom: 12 }}>
                        <img src={topIcon} height={20} width={20} />
                        <Typography
                            color={customColor ?? indexTheme.palette.mediumGrey}
                            textStyle={
                                width <= 1024
                                    ? 'b4'
                                    : width <= 1484
                                    ? 'b3'
                                    : width <= 1553
                                    ? 'b2'
                                    : 'b1'
                            }
                            style={{ marginLeft: 8 }}
                        >
                            {topText}
                        </Typography>
                    </div>
                ) : null}
                {bottomText ? (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <img src={bottomIcon} height={20} width={20} />
                        <Typography
                            color={customColor ?? indexTheme.palette.mediumGrey}
                            textStyle={
                                width <= 1024
                                    ? 'b4'
                                    : width <= 1484
                                    ? 'b3'
                                    : width <= 1553
                                    ? 'b2'
                                    : 'b1'
                            }
                            style={{ marginLeft: 8 }}
                        >
                            {bottomText}
                        </Typography>
                    </div>
                ) : null}
            </div>
        );
    };

    const midSection = (
        <div className={styleSheet.midSection}>
            <IconAndText
                topIcon={GrayCal}
                topText={DateTime.fromJSDate(showing.start).toFormat('DD')}
                bottomIcon={GrayClock}
                bottomText={`${DateTime.fromJSDate(showing.start).toFormat(
                    't',
                )} - ${DateTime.fromJSDate(showing.end).toFormat('t')}`}
                customColor={indexTheme.palette.black}
            />
            <IconAndText
                topIcon={GrayPerson}
                topText={userData ? userData?.firstName + ' ' + userData?.lastName : null}
                bottomIcon={GrayMessage}
                bottomText={phoneNumber ? `+1 ${formatPhoneNumberForDisplay(phoneNumber)}` : null}
            />
            <IconAndText
                topIcon={GrayInfo}
                topText={office}
                bottomIcon={GrayMail}
                bottomText={formatEmailForDisplay(email)}
            />
        </div>
    );

    const bottomSection = (
        <div className={styleSheet.bottomSection}>
            {showFeedBackButton && (
                <ButtonComponent
                    className={styleSheet.button}
                    fill
                    width={width * 0.14}
                    onClick={() => setModal('Leave Feedback')}
                    screenWidth={width}
                >
                    Leave Feedback
                </ButtonComponent>
            )}
            {showViewFeedbackButton && (
                <ButtonComponent
                    className={styleSheet.button}
                    fill
                    width={width * 0.14}
                    onClick={() => onViewFeedback(showing)}
                    screenWidth={width}
                >
                    View Feedback
                </ButtonComponent>
            )}
            {showDenialButton && (
                <ButtonComponent
                    className={styleSheet.button}
                    width={width * 0.14}
                    onClick={() => setModal('Denial Reason')}
                >
                    View Reason
                </ButtonComponent>
            )}
            {selectedType === 'listSide' && showing.status === 'pending_internal' ? (
                showing.unverifiedUser ? (
                    <ButtonComponent
                        className={styleSheet.button}
                        width={width * 0.14}
                        onClick={() => {
                            setModal('List Side Details');
                        }}
                        fill
                        disabled={isUpdating}
                    >
                        Respond
                    </ButtonComponent>
                ) : showing?.approvals?.includes('agent') ? (
                    <Typography color={indexTheme.palette.mediumGrey} textStyle="p2">
                        Pending approval from seller.
                    </Typography>
                ) : (
                    <div>
                        <ButtonComponent
                            className={styleSheet.button}
                            width={width * 0.14}
                            onClick={() => {
                                setModal('Deny Showing');
                            }}
                            disabled={isUpdating}
                        >
                            Deny
                        </ButtonComponent>
                        <ButtonComponent
                            className={styleSheet.button}
                            width={width * 0.14}
                            onClick={() =>
                                dispatch(
                                    changeShowingStatus({
                                        id: showing?._id,
                                        status: 'confirmed',
                                        callback: refresh,
                                    }),
                                )
                            }
                            fill
                            disabled={isUpdating}
                        >
                            Approve
                        </ButtonComponent>
                    </div>
                )
            ) : null}
            {!(showing.status === 'pending_internal' && showing.unverifiedUser) && (
                <ClickableWithFeedback
                    onClick={() => {
                        if (selectedType === 'buySide') {
                            history.push(`/showingDetails/${showing?._id?.toString()}`);
                        } else {
                            setModal('List Side Details');
                        }
                    }}
                    className={styleSheet.seeDetails}
                >
                    <Typography color={indexTheme.palette.blue} textStyle={'p1'}>
                        See Details
                    </Typography>
                    <img
                        className={styleSheet.forwardArrow}
                        height={20}
                        width={20}
                        src={ForwardArrow}
                    />
                </ClickableWithFeedback>
            )}

            {showing?.unverifiedUser && (
                <Tag
                    text="This is a Non MLS showing request."
                    color="red"
                    style={{ paddingHorizontal: 16, paddingVertical: 3 }}
                />
            )}
        </div>
    );

    return (
        <>
            <div className={styleSheet.root}>
                {topSection} {midSection} {bottomSection}
            </div>
            <DenialModal
                showing={showing}
                ref={ref}
                set={setModal}
                modalVisible={modal === 'Denial Reason'}
                closeModal={() => ref?.current?.closeModal()}
            />
            <ListSideDetails
                showing={showing}
                ref={ref}
                set={setModal}
                modalVisible={modal === 'List Side Details'}
                closeModal={() => ref?.current?.closeModal()}
                changeStatus={(props: any) => {
                    dispatch(changeShowingStatus(props));
                }}
                callback={refresh}
                loading={isUpdating}
                sendLockBoxDetails={sendLockBoxDetails}
                setSendLockBoxDetails={setSendLockBoxDetails}
            />
            <DenyModal
                showing={showing}
                ref={ref}
                set={setModal}
                modalVisible={modal === 'Deny Showing'}
                closeModal={() =>
                    showing.unverifiedUser
                        ? setModal('List Side Details')
                        : ref?.current?.closeModal()
                }
                onSend={(denialReason: string) => {
                    dispatch(
                        changeShowingStatus({
                            id: showing?._id,
                            status: 'denied',
                            denialReason: denialReason,
                            callback: refresh,
                        }),
                    );
                }}
                loading={isUpdating}
            />
            <LeaveFeedbackModal
                showing={showing}
                ref={ref}
                set={setModal}
                modalVisible={modal === 'Leave Feedback'}
                closeModal={() => ref?.current?.closeModal()}
                loading={isUpdating}
                changeStatus={() => {}}
            />
        </>
    );
};
export default ShowingCard;
