import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';
import CheckMarkEmpty from '../../images/checkboxBlack.svg';
import CheckMarkFilled from '../../images/checkboxFilledBlack.svg';
import { createUseStyles, useTheme } from 'react-jss';

// most of the listed props are used in the styles file, but they are necessary for this button to work.

interface CheckmarkComponentProps {
    disabled: boolean;
    checked: boolean;
    setChecked: Function;
}
function CheckmarkComponent(props: CheckmarkComponentProps) {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const {
        disabled,
        // What the button text is
        checked,
        setChecked,
        // Small, Medium, Large
    } = props;

    return (
        <div style={{ cursor: 'pointer', display: 'flex' }} onClick={() => setChecked(!checked)}>
            {checked ? (
                <img src={CheckMarkFilled} className={styleSheet.icon} />
            ) : (
                <img src={CheckMarkEmpty} className={styleSheet.icon} />
            )}
            {disabled && <div className={styleSheet.disabledOverlay} />}
        </div>
    );
}
export default CheckmarkComponent;
