// Constant agent type that is used as a parameter in API calls.
export const USER_TYPE_AGENT = 'agent';

// The name of the view for showing requests
export const SHOWING_REQUESTS_VIEW = 'showingRequestsView';

// The name of the showing requests collection
export const SHOWING_REQUESTS = 'showingRequests';

// The name of the agents collection
export const AGENTS = 'agents';

// The name of the agentsSocialConnections collection
export const AGENTS_SOCIAL_CONNECTIONS = 'agentsSocialConnections';

// The name of the feedback collection
export const FEEDBACK = 'feedback';

// The name of the consumers collection
export const CONSUMERS = 'consumers';

// The name of the favorites collection
export const FAVORITES = 'favorites';

// The name of the markets collection
export const MARKETS_COLLECTION = 'markets';

// The name of the pending connections collection
export const PENDING_CONNECTIONS = 'pendingConnections';

// The name of the listings collection
export const LISTINGS_COLLECTION = 'listings';

// The name of the stripe accounts collection
export const STRIPE_ACCOUNTS_COLLECTION_NAME = 'stripeAccounts';

// The name of the markets collection
export const MARKETS_COLLECTION_NAME = 'markets';

// Remove current listing and showing from Redux
export const CLEAR_SELECTED_LISTING_AND_SHOWING = 'CLEAR_SELECTED_LISTING_AND_SHOWING';

export const SHOWING_DAY_LIMIT = 6;

// Re-fetch current showing and listing via Redux
export const REFETCH_CURRENT_LISTING_AND_SHOWING = 'REFETCH_CURRENT_LISTING_AND_SHOWING';

export const BROKERAGES = 'brokerages';

// The name of the subscription settings collection
export const SUBSCRIPTION_SETTINGS = 'subscriptionSettings';

// The name of the agent listing statistics collection
export const AGENT_LISTING_STATS = 'agentsListingStats';

// The name of the brokerage listing statistics collection
export const BROKERAGE_LISTING_STATS = 'brokeragesListingStats';

// The name of the market listing statistics collection
export const MARKET_LISTING_STATS = 'marketsListingStats';
export const SOCIAL_FEED = 'socialFeed';

//The name of the message blast collection
export const MESSAGE_BLAST = 'messageBlasts';

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
    Delete,
    Refresh,
}
