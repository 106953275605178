import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, Switch, Route } from 'react-router-dom';
import { BSON } from 'realm-web';

import {
    fetchBrokerageListingsRequested,
    appendBrokerageListingsSucceeded,
    fetchBrokerageAgentsRequested,
    appendBrokerageAgentsSucceeded,
    clearBrokerageData,
    updateSelectedBrokerage,
    setViewingRosterProfile,
} from '../actions';
import {
    getBrokerId,
    getBrokerageId,
    getBrokerageListings,
    getBrokerageAgents,
    getLoadingListings,
    getLoadingAgents,
    getToAppendBrokerageListings,
    getToAppendBrokerageAgents,
    getSelectedBrokerage,
} from '../selectors';
import { BrokerageHubList } from '../types';

import RosterIcon from '../../../../images/rosterIcon.svg';
import RosterIconSelected from '../../../../images/rosterIconSelected.svg';
import ListingsIcon from '../../../../images/myListingsGrey.svg';
import ListingsIconSelected from '../../../../images/myListingsSelected.svg';
import FeedIcon from '../../../../images/feedIcon.svg';
import FeedIconSelected from '../../../../images/feedIconSelected.svg';
// import RankingsIcon from '../../../../images/rankingsIcon.svg';
// import RankingsIconSelected from '../../../../images/rankingsIconSelected.svg';

import BrokerageFeed from './BrokerageFeed';
import BrokerageListings from './BrokerageListings';
import BrokerageRoster from './BrokerageRoster';

import styles from './index.module.css';
import { getMyBrokerages } from '../../Profile/selectors';
import { BrokerageData, NestedBrokerageMarket } from '../../../../utils/constants';
import { setSelectedAgent } from '../../Social/actions';

type BrokerageHubTypes = {};

const BrokerageHub = ({}: BrokerageHubTypes) => {
    let brokerageId: string | BSON.ObjectID = useSelector(getBrokerageId);
    const brokerId: string = useSelector(getBrokerId);
    const brokerageListings: any = useSelector(getBrokerageListings);
    const brokerageAgents: any = useSelector(getBrokerageAgents);
    const brokerages: BrokerageData[] = useSelector(getMyBrokerages);
    const loadingListings: boolean = useSelector(getLoadingListings);
    const loadingAgents: boolean = useSelector(getLoadingAgents);
    const toAppendListings: any = useSelector(getToAppendBrokerageListings);
    const toAppendAgents: any = useSelector(getToAppendBrokerageAgents);
    const selectedBrokerage: BrokerageData = useSelector(getSelectedBrokerage);

    const dispatch = useDispatch();

    const fetchBrokerageListings: Function = (
        markets: { brokerageId: string; mlsName: string }[],
        skip: number,
    ) => dispatch(fetchBrokerageListingsRequested(markets, skip));
    const addNewListings: Function = (totalListings: any) =>
        dispatch(appendBrokerageListingsSucceeded(totalListings));
    const fetchBrokerageAgents: Function = (
        brokerageId: BSON.ObjectId,
        skip: number,
        filter: string,
    ) => dispatch(fetchBrokerageAgentsRequested(brokerageId, skip, filter));
    const addNewAgents: Function = (totalAgents: any) =>
        dispatch(appendBrokerageAgentsSucceeded(totalAgents));
    const clearBrokerageData: Function = () => dispatch(clearBrokerageData());
    const updateSelectedBrokerage: Function = (brokerage: any) =>
        dispatch(updateSelectedBrokerage(brokerage));
    const setSelectedAgent: Function = (selectedAgent: any) =>
        dispatch(setSelectedAgent(selectedAgent));
    const setViewingRosterProfile: Function = () => dispatch(setViewingRosterProfile(true));

    const [currentlySelected, setCurrentlySelected] = useState<string>('Feed');
    const [currentListingPagination, setCurrentListingPagination] = useState<number>(0);
    const [currentAgentPagination, setCurrentAgentPagination] = useState<number>(0);
    const [selectedLocalBrokerage, setSelectedBrokerage] = useState<BrokerageData>(
        selectedBrokerage,
    );
    const history = useHistory();
    brokerageId = selectedLocalBrokerage?._id;

    //Initially route the feed page upon clicking brokerage
    useEffect(() => {
        if (!window.location.pathname.includes('Roster')) {
            history.push('brokerageHub/brokerageFeed');
        } else {
            // ensures the roster loads and the text is orange on back page from an agent profile page
            setCurrentlySelected('Roster');
            history.push('brokerageRoster');
        }
    }, []);

    useEffect(() => {
        // brokerageListings.length <= currentListingPagination stops us from re-fetching listings
        // over and over again while still allowing for refetches if there's 0, 50, 100, etc. listings
        if (selectedLocalBrokerage && selectedBrokerage) {
            const markets = selectedLocalBrokerage?.markets?.map(
                (market: NestedBrokerageMarket) => {
                    return {
                        brokerageId: market.brokerageId,
                        mlsName: market.mlsName,
                    };
                },
            );
            if (markets?.length > 0 && brokerageListings.length <= currentListingPagination) {
                fetchBrokerageListings(markets, currentListingPagination);
                updateSelectedBrokerage(selectedLocalBrokerage);
            }
        }
    }, [currentListingPagination, selectedLocalBrokerage]);

    //Append the newly fetched listings to the original listings already in state
    useEffect(() => {
        if (toAppendListings.length > 0) {
            addNewListings(brokerageListings.concat(toAppendListings));
        } else if (toAppendAgents.length > 0) {
            addNewAgents(brokerageAgents.concat(toAppendAgents));
        }
    }, [toAppendListings, toAppendAgents]);

    useEffect(() => {
        // brokerageAgents.length <= currentAgentPagination stops us from re-fetching agents
        // over and over again while still allowing for refetches if there's 0, 50, 100, etc. agents
        if (brokerageId && brokerageAgents.length <= currentAgentPagination) {
            fetchBrokerageAgents(brokerageId, currentAgentPagination, 'last');
        }
    }, [brokerageId, currentAgentPagination]);

    const brokerageHubList: BrokerageHubList[] = [
        {
            listTitle: 'Feed',
            listIcon: FeedIcon,
            listIconSelected: FeedIconSelected,
            listLink: '/brokerageHub/brokerageFeed',
        },
        // At the moment there are no wireframes or tasks for creating the rankings page, so let's not map that yet
        // Eventually we will need to build this feature, so don't delete this
        // {
        //     listTitle: 'Rankings',
        //     listIcon: RankingsIcon,
        //     listIconSelected: RankingsIconSelected,
        //     listLink: '/brokerageHub/brokerageListings',
        // },
        {
            listTitle: 'Listings',
            listIcon: ListingsIcon,
            listIconSelected: ListingsIconSelected,
            listLink: '/brokerageHub/brokerageListings',
        },
        {
            listTitle: 'Roster',
            listIcon: RosterIcon,
            listIconSelected: RosterIconSelected,
            listLink: '/brokerageHub/brokerageRoster',
        },
    ];

    const formatHubItem = (
        listTitle: string,
        listIcon: string,
        listIconSelected: string,
        listLink: string,
        key: number,
    ) => {
        return (
            <div
                key={key}
                className={styles.listItemContainer}
                onClick={() => {
                    setCurrentlySelected(listTitle);
                    history.push(listLink);
                }}
            >
                <img
                    className={styles.iconImage}
                    alt={listTitle}
                    src={currentlySelected === listTitle ? listIconSelected : listIcon}
                />
                <p
                    style={
                        currentlySelected === listTitle
                            ? {
                                  color: '#3A3A3A',
                              }
                            : {
                                  color: '#9A9A9A',
                              }
                    }
                >
                    {listTitle}
                </p>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div className={styles.innerRoot}>
                <div className={styles.hubNavbar}>
                    {/* <h1>Brokerage Hub</h1> */}
                    {brokerages?.map((item: BrokerageData) => {
                        const selected =
                            JSON.stringify(selectedLocalBrokerage?._id) ===
                            JSON.stringify(item._id);

                        return (
                            <div
                                className={styles.brokerageContainer}
                                onClick={() => {
                                    if (!selected) {
                                        setCurrentListingPagination(0); // When we select a new brokerage we should reset all pagination to ensure that results will actually return for smaller brokerages
                                        setSelectedBrokerage(item);
                                        updateSelectedBrokerage(item);
                                        clearBrokerageData();
                                    }
                                }}
                            >
                                <img
                                    src={
                                        item.image !== ''
                                            ? item?.image
                                            : 'https://brokerage-logo-images.s3.amazonaws.com/Generic+Photo.png'
                                    }
                                    className={styles.brokerageImage}
                                    alt={'img'}
                                />
                                <span
                                    className={
                                        selected
                                            ? styles.brokerageTextSelected
                                            : styles.brokerageText
                                    }
                                >
                                    {item.name}
                                </span>
                            </div>
                        );
                    })}
                    <div className={styles.pagesContainer}>
                        {brokerageHubList.map((hubItem: BrokerageHubList, index: number) =>
                            formatHubItem(
                                hubItem.listTitle,
                                hubItem.listIcon,
                                hubItem.listIconSelected,
                                hubItem.listLink,
                                index,
                            ),
                        )}
                    </div>
                </div>

                {/* Let's route all the other pages that belong to the Brokerage feature */}

                <div className={styles.hubPage}>
                    <Switch>
                        <Route exact path="/brokerageHub/brokerageListings">
                            <BrokerageListings
                                brokerageListings={brokerageListings}
                                loadingListings={loadingListings}
                                currentPagination={currentListingPagination}
                                setCurrentPagination={setCurrentListingPagination}
                            />
                        </Route>
                        <Route exact path="/brokerageHub/brokerageFeed">
                            <BrokerageFeed />
                        </Route>
                        <Route exact path="/brokerageHub/brokerageRoster">
                            <BrokerageRoster
                                brokerageAgents={brokerageAgents}
                                loadingAgents={loadingAgents}
                                currentPagination={currentAgentPagination}
                                setCurrentPagination={setCurrentAgentPagination}
                                setSelectedAgent={setSelectedAgent}
                                setViewingRosterProfile={setViewingRosterProfile}
                            />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default BrokerageHub;
