export default (theme: any) => {
    return {
        dateTime: {
            color: theme.palette.darkGrey,
            fontSize: '15sp',
            fontWeight: 700,
            lineHeight: '27px',
        },
        message: {
            color: theme.palette.darkGrey,
            fontSize: '15px',
            lineHeight: '22px',
            fontWeight: 500,
        },
        pastMessageRoot: {
            padding: '12px 16px',
            backgroundColor: theme.palette.white,
            marginBottom: 10,
            borderRadius: 5,
            flexDirection: 'column',
        },
        recipientDisplayWrap: {
            display: 'flex',
            lineHeight: '26px',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '-10px',
            marginBottom: '15px',
            marginLeft: '15px',
            flexWrap: 'wrap',
        },
        recipientDisplay: {
            backgroundColor: theme.palette.separatorGrey,
            paddingLeft: '8px',
            paddingRight: '8px',
            borderColor: theme.palette.separatorGrey,
            borderRadius: '18px',
            borderWidth: '1px',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'max-content',
        },
        recipientDisplayText: {
            color: theme.palette.mediumGrey,
            fontFamily: 'Poppins-SemiBold',
            fontSize: '15px',
            display: 'inline',
            minWidth: 'max-content',
        },
    };
};
