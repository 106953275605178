import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { callStitchFunction } from '../../../store/api/sagas';
import * as Actions from './actions';
import { getProfileData } from '../Profile/selectors';

export function* fetchAllShowings({
    startOfWeek = null,
    endOfWeek = null,
}: ReturnType<typeof Actions.fetchAllShowingsRequested>): Generator<any, any, any> {
    try {
        const agentRecord = yield select(getProfileData);
        const agentObjectId = agentRecord?._id;
        const showings = yield call(callStitchFunction, 'fetchAllShowings', startOfWeek, endOfWeek);
        yield put(Actions.fetchAllShowingsSucceeded(showings, startOfWeek, endOfWeek));
    } catch (error) {
        yield put(Actions.fetchAllShowingsFailed());
    }
}

export default function* (): Generator<any, any, any> {
    yield all([takeLatest(Actions.FETCH_ALL_SHOWINGS_REQUESTED, fetchAllShowings)]);
}
