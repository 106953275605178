import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import {
    callStitchFunction,
    findRecord,
    runQuery,
    upsertAgentRecord,
} from '../../../store/api/sagas';
import {
    queueShowingInteractionRequested,
    queueShowingInteractionSucceeded,
    queueShowingInteractionFailed,
    fetchGroupDelegationPriceRequested,
    fetchGroupDelegationPriceSucceeded,
    fetchGroupDelegationPriceFailed,
    fetchAllListingsForQueuedShowingsRequested,
    fetchAllListingsForQueuedShowingsFailed,
    fetchAllListingsForQueuedShowingsSucceeded,
    QUEUED_SHOWING_ACTION,
    fetchClientRequested,
    fetchClientFailed,
    fetchClientSucceeded,
} from './actions';
import { getClient } from '../../auth/selectors';
import { ShowingType } from '../../../utils/constants';
import { getProfileData } from '../Profile/selectors';
import { getShowingQueue } from './selectors';
import { AGENTS, LISTINGS_COLLECTION, STATUS } from '../../../store/api/constants';
import { BSON } from 'realm-web';
import { getStitchUser } from '../../../utils';

/**
 *
 * @param {interactionType} has values of 'add' to add a showing to the queue,
 * 'remove' to remove from the queue, 'update' to update from the queue, 'schedule'
 * to schedule all queued showings, and 'clear' to reset the queue
 * @param {showing} the information for the showing to be added to the queue or updated
 * @param {updateIndex} the index of the queued showings to either have updated or removed
 */
function* queueShowingInteraction({
    interactionType,
    showing,
    updateIndex,
    callback,
    customScheduleQueue,
}: ReturnType<typeof queueShowingInteractionRequested>): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const showingQueue = yield select(getShowingQueue);
        var newQueue = [...showingQueue];
        var success = false;
        switch (interactionType) {
            case 'add':
                newQueue.push(showing);
                success = true;
                break;
            case 'remove':
                newQueue[updateIndex] = {
                    ...newQueue[updateIndex],
                    remove: true,
                };
                success = true;
                break;
            case 'update':
                var updatedShowing = { ...showing };
                if (typeof showing.listingId === 'string') {
                    updatedShowing.listingId = new BSON.ObjectID(showing.listingId);
                }
                newQueue.splice(updateIndex, 1, updatedShowing);
                success = true;
                break;
            case 'clear':
                newQueue = [];
                success = true;
                const emptyDetails: any = {
                    queuedShowings: newQueue,
                };
                yield call(upsertAgentRecord, user, emptyDetails);
                break;
            case 'schedule':
                var showingCount = 0;
                var updateQueue = customScheduleQueue?.length
                    ? [...customScheduleQueue]
                    : [...showingQueue];
                for (
                    var i = updateIndex === -1 ? 0 : updateIndex;
                    i < (updateIndex === -1 ? showingQueue.length : updateIndex + 1);
                    i++
                ) {
                    var queuedShowing = showingQueue[i];
                    // delegations should only be 30 minutes
                    const minutes = 30;
                    var delegationEndTime = new Date(
                        queuedShowing?.startDate?.getTime() + minutes * 60000,
                    );
                    const endTime =
                        queuedShowing.showingType === 'delegate'
                            ? delegationEndTime
                            : queuedShowing.endDate;
                    const showing: any = yield call(
                        callStitchFunction,
                        'createShowing',
                        queuedShowing.showingType === 'delegate'
                            ? ShowingType.Delegated
                            : ShowingType.Agent,
                        queuedShowing.listingId,
                        queuedShowing.startDate,
                        endTime,
                        queuedShowing.clientId?._id, // bad values. clientId._id is really a stitchUserId
                        queuedShowing.utcOffset,
                        queuedShowing.delegationPrice || null,
                    ) as any;
                    /**
                     * on success, remove the listing from the queue.
                     * Update local storage and redux state to reflect the current queue
                     * If fail at any point, leave the failed request attempts in the queue
                     */
                    if (showing) {
                        showingCount++;
                        if (updateQueue.length > 1) {
                            var tempIndex = updateQueue.indexOf(showingQueue[i]);
                            updateQueue.splice(tempIndex, 1);
                            yield put(queueShowingInteractionRequested('remove', null, tempIndex));
                        } else {
                            updateQueue = [];
                        }
                        newQueue = updateQueue;
                        const emptyShowingQueue: string[] = [];
                        const details = {
                            queuedShowings: emptyShowingQueue,
                        };
                        yield call(upsertAgentRecord, user, details);
                    }
                }
                if (
                    (updateIndex === -1 && showingCount === showingQueue.length) ||
                    (updateIndex !== -1 && showingCount === 1)
                ) {
                    success = true;
                    newQueue = updateQueue;
                    /**
                     * clear the queued listing from local storage and change
                     * the path to search for agents that come from SSO
                     */
                    const entryEndpointData: any = sessionStorage.getItem('entryEndpointData');
                    const entryEndpointDataParsed: any = JSON.parse(entryEndpointData);
                    if (entryEndpointDataParsed) {
                        var removeListings = entryEndpointData?.replace(
                            `&listingid=${entryEndpointDataParsed.listingid}`,
                            '',
                        );
                        sessionStorage.setItem('entryEndpointData', JSON.stringify(removeListings));
                    }
                }
                break;
            default:
                break;
        }

        if (success === true) {
            yield call(saveShowingQueue, { showingQueue: newQueue });
            yield put(queueShowingInteractionSucceeded(newQueue, interactionType));
            if (callback) {
                yield call(callback);
            }
        } else {
            yield put(
                queueShowingInteractionFailed(
                    'There was an issue in scheduling these showings. Code: 0091-1',
                ),
            );
        }
    } catch (error) {
        yield put(
            queueShowingInteractionFailed(
                'There was an issue with your queued showings. Code: 0091-3',
            ),
        );
    }
}

function* fetchGroupDelegationPrice({
    showings,
}: ReturnType<typeof fetchGroupDelegationPriceRequested>): Generator<any, any, any> {
    try {
        const agent = yield select(getProfileData);
        const marketName = agent?.markets[0]?.marketName || null; // Will handle later, for now fetch pricing of preferred market
        const market = yield call(findRecord, 'markets', {
            name: marketName,
        });
        const { delegationBaseCost: defaultBaseCost, areas } = market;
        let delegationBaseCost = defaultBaseCost;
        let newQueue = [...showings];
        var totalPrice = 0;
        if (showings && showings.length) {
            /**
             * for each queued showing, if the type is delegated, fetch
             * the delegation price and total it up. Then attach the delegation
             * price to the queuedShowing for later scheduling
             */
            for (let i = 0; i < showings.length; i++) {
                const showing = showings[i];
                if (showing.showingType === 'delegate') {
                    const zipCode = showing?.listing?.NoTransform?.postalCode;
                    const showingId = showing?.listingId;
                    const start = showing?.startTime;
                    if (areas) {
                        const areaNames = Object.keys(areas);
                        const codeToCompare =
                            typeof zipCode === 'number' ? zipCode : parseInt(zipCode);
                        areaNames.map((name) => {
                            const zipcodes = areas[name].zipcodes;
                            zipcodes.map((code: number) => {
                                if (code === codeToCompare) {
                                    delegationBaseCost = areas[name].delegationBaseCost;
                                }
                            });
                        });
                    }
                    const delegationPrice = yield call(
                        callStitchFunction,
                        'fetchDelegationCost',
                        showingId,
                        delegationBaseCost,
                        start,
                    );
                    if (delegationPrice) {
                        totalPrice += delegationPrice;
                        newQueue[i] = {
                            ...newQueue[i],
                            delegationPrice,
                        };
                    }
                }
            }
            yield put(fetchGroupDelegationPriceSucceeded(totalPrice));
            yield put(queueShowingInteractionSucceeded(newQueue, 'update'));
        }
        yield put(fetchGroupDelegationPriceFailed(''));
    } catch (error) {
        yield put(fetchGroupDelegationPriceFailed(error.message));
        yield call(alert, {
            type: 'error',
            message: 'Unable to fetch the delegation cost. Please try again later. Code: 0090',
        });
    }
}

function* fetchAllListings({
    listingIds,
}: ReturnType<typeof fetchAllListingsForQueuedShowingsRequested>): Generator<any, any, any> {
    try {
        let _listingIds: string[] = [];
        let queuedShowings: any = [];
        if (listingIds?.length) {
            _listingIds = [...listingIds];
        } else {
            const user = yield select(getStitchUser);
            const agent = yield call(findRecord, AGENTS, {
                $or: [{ stitchUserId: user.id }, { tempUserId: user.id }],
            });
            if (agent?.queuedShowings?.length) {
                queuedShowings = [...agent?.queuedShowings];
            }
        }
        let listings: any = [];
        if (queuedShowings?.length) {
            listings = yield all(
                queuedShowings?.map((showing: any) => {
                    return call(findRecord, LISTINGS_COLLECTION, {
                        $or: [
                            { listingId: showing?._id },
                            { _id: new BSON.ObjectID(showing?._id) },
                        ],
                    });
                }),
            );
            queuedShowings?.map((showing: any, index: number) => {
                if (showing.startDate !== '' && showing.startDate >= new Date()) {
                    listings[index].startDate = showing.startDate;
                    listings[index].endDate = showing.endDate;
                }
                if (showing.clientId) {
                    listings[index].clientId = showing.clientId;
                }
            });
        } else if (_listingIds?.length) {
            listings = yield all(
                _listingIds?.map((listingId: any) => {
                    return call(findRecord, LISTINGS_COLLECTION, {
                        $or: [{ listingId: listingId }, { _id: new BSON.ObjectID(listingId) }],
                    });
                }),
            );
        }

        const minutesUtcOffset = new Date().getTimezoneOffset();
        const utcOffset = Math.floor(minutesUtcOffset / 60);

        listings = listings.map((listing: any) => {
            return {
                listing: listing.listing || listing, // in the case of queuedShowings
                showingType: 'agent',
                listingId: listing.listing ? listing.listing._id : listing._id,
                startDate: listing.startDate || '',
                endDate: listing.endDate || '',
                clientId: listing.clientId || null,
                utcOffset: utcOffset,
            };
        });
        yield put(fetchAllListingsForQueuedShowingsSucceeded(listings));
    } catch (err) {
        yield put(
            fetchAllListingsForQueuedShowingsFailed([
                'There was an error fetching your queued showings. Code: 0099',
            ]),
        );
    }
}

function* fetchClient({ stitchIds }: any): Generator<any, any, any> {
    try {
        const fetchedClients = yield runQuery('consumers', {
            stitchUserId: {
                $in: stitchIds,
            },
        });
        if (fetchedClients.length > 0) {
            yield put(fetchClientSucceeded(fetchedClients));
        } else if (fetchedClients.length === 0) {
            yield put(fetchClientFailed(['Client could not be found']));
        }
    } catch (err) {
        yield put(fetchClientFailed([err]));
    }
}

function* saveShowingQueue({ showingQueue }: any): Generator<any, any, any> {
    try {
        var clearQueueDataSSO = false;
        if (showingQueue?.length) {
            var parsedShowingQueue = [...showingQueue];
            const user = yield select(getStitchUser);
            const agent = yield call(findRecord, AGENTS, {
                $or: [{ stitchUserId: user.id }, { tempUserId: user.id }],
            });
            let setQueuedShowings: any = [];
            if (agent?.queuedShowings) {
                setQueuedShowings = [...agent?.queuedShowings];
            }

            /**
             * if the user brought in a listing from SSO, format it so it can
             * shape all of the other queued showings
             */
            if (
                parsedShowingQueue[0]?.queueDataMlsName &&
                parsedShowingQueue[0]?.queueDataListingId
            ) {
                const listing = yield call(findRecord, LISTINGS_COLLECTION, {
                    mlsList: {
                        $elemMatch: {
                            listingId: parsedShowingQueue[0]?.queueDataListingId,
                            mlsName: parsedShowingQueue[0]?.queueDataMlsName,
                        },
                    },
                });
                if (listing?._id) {
                    const newQueueItem = {
                        _id: listing._id.toString(),
                        startDate: '',
                        endDate: '',
                    };
                    parsedShowingQueue = [newQueueItem];
                } else {
                    // clear incoming queue if listing isn't found
                    parsedShowingQueue = [];
                }
                // sessionStorage.removeItem('queueData');
                clearQueueDataSSO = true;
            } else if (
                parsedShowingQueue[0]?.queueDataMlsName ||
                parsedShowingQueue[0]?.queueDataListingId
            ) {
                // if the sso data for queued showings is invalid, we dont want them
                parsedShowingQueue = [];
                // sessionStorage.removeItem('queueData');
                clearQueueDataSSO = true;
            }

            /**
             * parsedShowingQueue is the local client side array of queued showings. setQueuedShowings
             * is whatever is currently stored on the agent's queuedShowings array. For each of the client side
             * queued showings, check if it already exists on the agent. If it already, exists,
             * update/remove the data on the showing. If it doesn't already exist, add it
             */
            parsedShowingQueue.forEach((queuedShowing: any, index: number) => {
                if (queuedShowing?.listingId) {
                    var matchingIndex = 0;
                    if (
                        // the showing already exists if this returns true
                        setQueuedShowings?.find((agentDocumentShowing: any, index2: number) => {
                            matchingIndex = index2;
                            return (
                                agentDocumentShowing._id === queuedShowing?.listingId?.toString()
                            );
                        })
                    ) {
                        /**
                         * if the client removes a queued showing, saveShowingQueue will be called from
                         * queueShowingInteraction up above. In the case that interactionType is 'remove', a
                         * boolean called 'remove' is attached to the queued showing that is passed in from
                         * the showing queue array. Remove all showings from the agent with this field that
                         * have not already been removed.
                         *
                         * Update the fields of all other already existant showings. Doesn't hurt performance
                         * to update a field from null => null in some cases where this may happen
                         */
                        if (queuedShowing.remove) {
                            setQueuedShowings.splice(matchingIndex, 1);
                        } else {
                            setQueuedShowings[matchingIndex] = {
                                _id: queuedShowing?.listing?._id?.toString(),
                                startDate: queuedShowing?.startDate,
                                endDate: queuedShowing?.endDate,
                                clientId: queuedShowing?.clientId, // bad schema. this is really a client object
                            };
                        }
                    } else {
                        // showing doesn't already exist, so add it
                        setQueuedShowings.push({
                            _id: queuedShowing?.listing?._id?.toString(),
                            startDate: queuedShowing?.startDate,
                            endDate: queuedShowing?.endDate,
                        });
                    }
                } else {
                    if (
                        !setQueuedShowings?.find(
                            (savedListingId: any) => savedListingId === queuedShowing,
                        )
                    ) {
                        setQueuedShowings.push(queuedShowing);
                    }
                }
            });

            const details = {
                queuedShowings: setQueuedShowings,
            };
            yield call(upsertAgentRecord, user, details);
            // once agent updated, clear all sso data from current storage
            if (clearQueueDataSSO) {
                sessionStorage.removeItem('queueData');
            }

            yield put(fetchAllListingsForQueuedShowingsRequested());
        }
    } catch (err) {
        // Err
        console.error('saveShowingQueue err: ', err);
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeEvery(
            (action: any) =>
                action.type === QUEUED_SHOWING_ACTION.QueueShowingInteraction &&
                action.status === STATUS.Requested,
            queueShowingInteraction,
        ),
        takeLatest(
            (action: any) =>
                action.type === QUEUED_SHOWING_ACTION.FetchGroupDelegationPrice &&
                action.status === STATUS.Requested,
            fetchGroupDelegationPrice,
        ),
        takeLatest(
            (action: any) =>
                action.type === QUEUED_SHOWING_ACTION.FetchListings &&
                action.status === STATUS.Requested,
            fetchAllListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === QUEUED_SHOWING_ACTION.FetchClient &&
                action.status === STATUS.Requested,
            fetchClient,
        ),
        takeLatest(
            (action: any) =>
                action.type === QUEUED_SHOWING_ACTION.SaveShowingQueue &&
                action.status === STATUS.Requested,
            saveShowingQueue,
        ),
    ]);
}
