import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, ClickableWithFeedback, Loader, Typography } from '../../../../components';
import ButtonComponent from '../../../../components/ButtonComponent';
import CloseIcon from '../../../../images/xBlack.svg';

interface CRMDeleteModalProps {
    visible: boolean;
    onClickClose: Function;
    onClickSubmit: Function;
    numClients: number;
    loading: boolean;
    message: string;
    errors: any;
}
const CRMDeleteModal = ({
    visible,
    onClickClose,
    onClickSubmit,
    numClients,
    loading,
    message,
    errors,
}: CRMDeleteModalProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const headerBar = (
        <div className={styleSheet.headerBar}>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                Delete Client
            </Typography>
            <ClickableWithFeedback onClick={onClickClose}>
                <img src={CloseIcon} className={styleSheet.closeIcon} />
            </ClickableWithFeedback>
        </div>
    );
    const messageDiv = (
        <div className={styleSheet.messageContainer}>
            {loading && <Loader />}
            {!loading && !errors && !message && (
                <>
                    <div>
                        <Typography textStyle="h2" color={theme.palette.black}>
                            Are you sure you want to
                        </Typography>
                        <Typography textStyle="h2" color={theme.palette.red}>
                            {' '}
                            remove{' '}
                        </Typography>
                        <Typography textStyle="h2" color={theme.palette.black}>
                            {numClients > 1 ? 'these clients' : 'this client'}?
                        </Typography>
                    </div>
                    <Typography
                        textStyle="h4"
                        color={theme.palette.mediumGrey}
                        style={{ marginTop: 2 }}
                    >
                        This action will remove the selected {numClients > 1 ? 'clients' : 'client'}{' '}
                        & disconnect you from their {numClients > 1 ? 'apps' : 'app'}.
                    </Typography>
                </>
            )}
            {!loading && (errors || message) && (
                <>
                    <Typography textStyle="h2" color={theme.palette.black}>
                        {message || errors}
                    </Typography>
                </>
            )}
        </div>
    );
    const buttonBar = (
        <div className={styleSheet.buttonBar}>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                <ButtonComponent className={styleSheet.button} onClick={onClickClose}>
                    No
                </ButtonComponent>
                <ButtonComponent className={styleSheet.button} onClick={onClickSubmit} fill>
                    Yes
                </ButtonComponent>
            </Typography>
        </div>
    );

    return (
        <AnimatedModal
            set={onClickClose}
            modalVisible={visible}
            style={{ minHeight: 600, width: 800, margin: 'auto', top: null }}
        >
            {headerBar}
            {messageDiv}
            {!loading && !errors && !message && <>{buttonBar}</>}
            {!loading && errors && message}
        </AnimatedModal>
    );
};

export default CRMDeleteModal;
