import { DASHBOARD_ACTIONS } from './actions';
import { STATUS } from '../../../store/api/constants';

export const initialState = {
    loading: false,
    errors: [],
    agentMarkets: [],
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case DASHBOARD_ACTIONS.Fetch: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingStats: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { agentStats, agentMarkets } = action;
                    return {
                        ...state,
                        loadingStats: false,
                        agentStats,
                        agentMarkets,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loadingStats: false,
                        errors: errors,
                    };
                }
                default: {
                    return state;
                }
            }
        }
        default:
            return state;
    }
}
