import { all, call, put, takeLatest, select, race } from 'redux-saga/effects';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
    callStitchFunction,
    findListing,
    findShowingData,
    findRecord,
    runQuery,
} from '../../../store/api/sagas';
import {
    FETCH_ACTIVE_LISTINGS_REQUESTED,
    FETCH_LISTING_REQUESTED,
    SHOWING_ACTION,
    CLIENT_SEARCH_ACTION,
    fetchActiveListingsSucceeded,
    fetchActiveListings as fetchActiveListingsRequested,
    fetchActiveListingsFailed,
    storeReservedShowings,
    fetchListingsSucceeded,
    fetchListingsFailed,
    createShowingRequested,
    createShowingSucceeded,
    createShowingFailed,
    setSelectedClient,
    textSearchClientRequestedSucceeded,
    newlyCreatedShowingRequest,
} from './actions';
import * as Actions from './actions';
import { fetchMyShowingsRequested, findAllShowingDataSuccess } from '../MyShowings/actions';
import { getAgentRecord, getAgentObject } from '../../auth/selectors';
import * as StoreApi from '../../../store/api/sagas';
import { generateSignature, parseStitchServiceError } from '../../../utils/common';
import { BSON } from 'realm-web';
import { STATUS, Client, ShowingType } from '../../../utils/constants';
import { MY_SHOWINGS_FILTER } from '../MyShowings/types';
import { getProfileData } from '../Profile/selectors';
import { getStitchUser } from '../../../utils';
// import { getShowingQueue } from './selectors';
import { fetchMyListingsRequested } from '../MyListings/actions';
import { setConfigDetails } from '../Listings/actions';
import {
    LISTINGS_COLLECTION,
    SHOWING_REQUESTS,
    USER_TYPE_AGENT,
} from '../../../store/api/constants';
import { CONNECT_LISTING_REQUESTED } from './constants';
import { getUnavailableDates } from '../../../utils/common/schedulingHelpers';
import { saveListingInRedux } from '../ListingDetailsScreen/actions';
import { listingClassRets } from '../../../utils/constants';
import { fetchListingsRequested } from '../Listings/actions';

export function* fetchSSOListing({ listingId, mlsName }: any): Generator<any, any, any> {
    try {
        const listing = yield call(findRecord, LISTINGS_COLLECTION, {
            mlsList: {
                $elemMatch: {
                    listingId: listingId,
                    mlsName: mlsName,
                },
            },
        });
        if (listing) {
            yield put(Actions.fetchSSOListingSucceeded(listing));
        } else {
            yield put(Actions.fetchSSOListingFailed(''));
        }
    } catch (err: any) {
        console.log('errrr', err);
        yield put(Actions.fetchSSOListingFailed(err));
    }
}

export function* fetchSSOUnconnectedListing({ mlsName, listingId }: any): Generator<any, any, any> {
    try {
        // check if the listing is already in our system
        let listingLink = '';
        let isListing = !!(yield call(
            findRecord,
            'listings',
            {
                'mlsList.listingId': listingId.toString(),
            },
            { _id: 1 },
        ));

        // if the listing is not yet in Showingly fetch the MLS
        if (!isListing) {
            const result: (undefined | Record<string, string>)[] = yield all(
                Object.values(listingClassRets).map((listingClass) =>
                    call(
                        callStitchFunction,
                        'fetchListingFromMls',
                        mlsName,
                        listingId,
                        listingClass,
                    ),
                ),
            );

            // if only one promise resolved means we find the listing
            isListing = result.some((r) => r && '_id' in r);
        }

        if (isListing) {
            // We must refresh the store with the new listing before taking the user back to the listings page
            const agentObject = yield select(getAgentObject);
            yield put(fetchListingsRequested(agentObject._id.toString()));
        }

        yield put(Actions.fetchSSOUnconnectedListingSucceeded(isListing, listingLink));
    } catch (err: any) {
        console.log('err', err);
        yield put(Actions.fetchSSOUnconnectedListingFailed(err));
    }
}

export function* fetchActiveListings({
    searchedText = '',
    isMlsIdSearch = false,
}: ReturnType<typeof fetchActiveListingsRequested>): Generator<any, any, any> {
    try {
        let listings = [];
        const listingsLimit = 25; // <- limit to how many listings can be returned
        const agentRecord = yield select(getAgentRecord);
        const markets = agentRecord?.markets;
        let marketObjectIds: any[] = [];
        if (markets && markets.length > 0) {
            markets.map((market: any) => {
                if (!marketObjectIds.includes(market.marketObjectId)) {
                    marketObjectIds.push(market.marketObjectId);
                }
            });
        }

        if (searchedText) {
            const listingMlsId = searchedText;
            listings = yield call(
                callStitchFunction,
                'textSearchListings',
                listingMlsId,
                true, // isExtraData
                null, // brokerageId
                listingsLimit,
                marketObjectIds,
                isMlsIdSearch,
            );
        }
        yield put(fetchActiveListingsSucceeded(listings));
    } catch (error: any) {
        yield put(fetchActiveListingsFailed(error));
    }
}

export function* fetchRequestedListing({
    listingId,
    listingDetails = false,
    mlsName = null,
}: any): Generator<any, any, any> {
    try {
        if (listingDetails) {
            const listing = yield call(findListing, {
                'mlsList.listingId': listingId,
                'mlsList.mlsName': mlsName,
            });
            if (listing) {
                yield put(saveListingInRedux(listing, 'buyer'));
            }
        } else {
            const listing = yield call(findListing, {
                _id: new BSON.ObjectId(listingId.toString()),
            });
            if (listing) {
                const {
                    marketName = '',
                    leadsEnabled = false,
                    delegationEnabled = false,
                } = listing;
                const marketData = {
                    name: marketName,
                    leadsEnabled,
                    delegationEnabled,
                };
                const { agentListing: { allowOverlap = null } = {} } = listing;
                if (allowOverlap === false) {
                    const reservedShowings = yield call(findShowingData, {
                        listingId: new BSON.ObjectID(listingId),
                        status: {
                            $in: ['pending_internal', 'pending_external', 'confirmed', 'requested'],
                        },
                    });
                    yield put(storeReservedShowings(reservedShowings));
                }
                yield put(fetchListingsSucceeded(listing, marketData));
            } else {
                const message =
                    'Listing was not found. Please try again later. If the problem persists, please call 833-217-7578 for assistance';
                yield put(fetchListingsFailed([message]));
            }
        }
    } catch (error) {
        //const message = parseStitchServiceError(error);
        // yield put(fetchListingsFailed(message));
        // yield call(alert, {
        //     type: 'error',
        //     message: message,
        // });
    }
}

export function* createShowing({
    showingType,
    listingId,
    startTime,
    endTime,
    clientIds,
    utcOffset,
}: ReturnType<typeof createShowingRequested>): any {
    try {
        const clientToAttach = clientIds?.length > 0 ? clientIds[0] : undefined;

        const showing = yield call(
            callStitchFunction,
            'createShowing',
            showingType,
            listingId,
            startTime,
            endTime,
            clientToAttach,
            utcOffset,
        );

        if (showing) {
            yield put(newlyCreatedShowingRequest(showing));
            yield put(fetchMyShowingsRequested(MY_SHOWINGS_FILTER.None, true));
            yield put(findAllShowingDataSuccess(showing));
        }

        yield put(createShowingSucceeded(showing, listingId));
        yield put(setSelectedClient(null));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(createShowingFailed(listingId, message));
    }
}

export function* textSearchClient({ searchText }: any): Generator<any, any, any> {
    try {
        const agentRecord = yield select(getProfileData);
        const { _id: agentId } = agentRecord;
        let searchedClients: Client[] = [];

        //This stitch function 'textClientSearch' seems to be prone to returning weird search results when non-sense strings are inputted
        //Something to look into in the future...
        if (searchText)
            // <- don't run call if there's no text
            searchedClients = yield call(
                callStitchFunction,
                'textClientSearch',
                searchText,
                agentId,
            );

        if (searchedClients) {
            //Filter the results again just to enure that only clients which makes sense are included in the final array
            const filteredResults = searchedClients?.filter((client: any, key: number) =>
                (client.firstName + client.lastName)
                    .toLowerCase()
                    .includes(searchText.toLowerCase()),
            );
            yield put(textSearchClientRequestedSucceeded(filteredResults));
        }
    } catch (err) {}
}

export function* connectListing({
    listingId,
    timeWindows,
    connectionData,
}: any): Generator<any, any, any> {
    try {
        const { views = null } = yield call(findRecord, LISTINGS_COLLECTION, {
            _id: listingId,
        });
        const agentData = yield select(getProfileData);
        const { unformattedPhoneNumber = '', _id: agentObjectId } = agentData;
        const emptyTimes = { startTime: '', endTime: '' };
        const { id: stitchUserId } = yield select(getStitchUser);

        const availability = {
            0: !timeWindows?.monday.noShowings
                ? {
                      startTime: new Date(new Date().setHours(timeWindows.monday.startTime, 0, 0)),
                      endTime: new Date(new Date().setHours(timeWindows.monday.endTime, 0, 0)),
                  }
                : emptyTimes,
            1: !timeWindows?.tuesday.noShowings
                ? {
                      startTime: new Date(new Date().setHours(timeWindows.tuesday.startTime, 0, 0)),
                      endTime: new Date(new Date().setHours(timeWindows.tuesday.endTime, 0, 0)),
                  }
                : emptyTimes,
            2: !timeWindows?.wednesday.noShowings
                ? {
                      startTime: new Date(
                          new Date().setHours(timeWindows.wednesday.startTime, 0, 0),
                      ),
                      endTime: new Date(new Date().setHours(timeWindows.wednesday.endTime, 0, 0)),
                  }
                : emptyTimes,
            3: !timeWindows?.thursday.noShowings
                ? {
                      startTime: new Date(
                          new Date().setHours(timeWindows.thursday.startTime, 0, 0),
                      ),
                      endTime: new Date(new Date().setHours(timeWindows.thursday.endTime, 0, 0)),
                  }
                : emptyTimes,
            4: !timeWindows?.friday.noShowings
                ? {
                      startTime: new Date(new Date().setHours(timeWindows.friday.startTime, 0, 0)),
                      endTime: new Date(new Date().setHours(timeWindows.friday.endTime, 0, 0)),
                  }
                : emptyTimes,
            5: !timeWindows?.saturday.noShowings
                ? {
                      startTime: new Date(
                          new Date().setHours(timeWindows.saturday.startTime, 0, 0),
                      ),
                      endTime: new Date(new Date().setHours(timeWindows.saturday.endTime, 0, 0)),
                  }
                : emptyTimes,
            6: !timeWindows?.sunday.noShowings
                ? {
                      startTime: new Date(new Date().setHours(timeWindows.sunday.startTime, 0, 0)),
                      endTime: new Date(new Date().setHours(timeWindows.sunday.endTime, 0, 0)),
                  }
                : emptyTimes,
        };

        const data = {
            agentUserId: agentObjectId,
            firstName: agentData.firstName,
            lastName: agentData.lastName,
            profilePhoto: {
                path: agentData.profilePhotoUpload.path,
                as: agentData.profilePhotoUpload.as,
                uri: agentData.profilePhotoUpload.uri,
            },
            availability: {
                recurring: availability,
            },
            listingId,
            phoneNumber: unformattedPhoneNumber,
            allowOverlap: connectionData?.allowOverlap,
            type: connectionData?.showingType || '',
            showingInstructions: connectionData?.showingInstructions || '',
            noticeRequired: connectionData?.noticeRequired,
            showingTexts: connectionData?.showingTextsNumber || '',
            lockCombo: connectionData?.lockBoxCode,
            consumerUserId: connectionData?.client?.stitchUserId || '',
            lockboxType: connectionData.lockBoxType || 'Other',
            information: connectionData.additionalInfo || '',
            lockboxLocation: connectionData.lockBoxLocation || '',
            connectionDate: new Date(),
            lockComboExpiration: new Date(),
            maxDuration: connectionData.maxDuration || 0,
        };

        if (data.type === 'accompanied') {
            data.lockboxLocation = '';
            data.lockCombo = '';
        }

        // if (connectionData?.lockCode && !_.isEmpty(connectionData?.lockCode)) {
        //     data.lockCombo = connectionData?.lockCode || '';
        // } else {
        //     data.lockCombo = connectionData?.tempCode || '';
        // }

        // const selectedClient = yield select(getSelectedConnectListingClient);
        // if (selectedClient) {
        //     data.consumerUserId = selectedClient?.id;
        //     yield put(setSelectedConnectListingClient(null));
        // }

        // if (connectionData?.lockbox && !_.isEmpty(connectionData?.lockbox)) {
        //     data.lockboxType = connectionData?.lockBoxType;
        // } else {
        //     data.lockboxType = 'Other';
        // }
        // if (connectionData?.additionalInfo && !_.isEmpty(connectionData?.additionalInfo)) {
        //     data.information = connectionData?.additionalInfo;
        // }
        // if (
        //     connectionData?.lockLocation &&
        //     !_.isEmpty(connectionData?.lockLocation)
        // ) {
        //     data.lockboxLocation = connectionData?.lockLocation;
        // }
        if (connectionData?.daysUntil && !_.isEmpty(connectionData?.daysUntil)) {
            const daysToExpiry = parseInt(connectionData?.daysUntil);
            const time = DateTime.local();
            const expirationDate = time.plus({ days: daysToExpiry });
            const lockExpiration = expirationDate.toJSDate();
            lockExpiration.setHours(0);
            lockExpiration.setMinutes(0);
            lockExpiration.setSeconds(0);
            lockExpiration.setMilliseconds(0);
            data.lockComboExpiration = lockExpiration;
        }
        if (connectionData?.firstConnection === true) {
            data.connectionDate = new Date();
        }

        // { listingId, agentListingData, views, agentData }

        let myParams = {
            listingId,
            agentListing: data,
        };

        const successfulCreation = yield call(callStitchFunction, 'agentConnectListing', myParams);

        if (successfulCreation) {
            const listingData = yield call(findListing, { _id: listingId });
            const date = new Date();
            const { _id: listingMlsId = null } = listingData;

            if (listingMlsId) {
                const listingForAgent = {
                    activityDate: date,
                    content: {
                        image: listingData.photos[0],
                        title: listingData.address.full,
                        text: `${agentData.firstName} has a new listing.`,
                        full: listingData.address.full,
                        city: listingData.address.city,
                        zip: listingData.address.postalCode,
                        state: listingData.address.state,
                        listingObjectId: listingData._id,
                        listingId: listingData.listingId,
                        price: listingData.listPrice,
                        bedrooms: listingData.property.bedrooms,
                        bathrooms: listingData.property.bathrooms,
                        area: listingData.property.area,
                        brokerageId: agentData.brokerage?._id,
                        is_displayed: listingData.is_displayed || true,
                        visible: listingData.visible || true,
                    },
                };

                const { followers } = agentData;
                // update the notifications array of all of your followers
                if (followers && followers.length) {
                    const followerIds: any[] = [];
                    yield all(
                        followers.map(async (follower: any) => {
                            const { agentId } = follower;
                            followerIds.push(agentId);
                        }),
                    );
                    yield put(
                        Actions.updateAgentNotificationRequested(
                            followerIds,
                            listingForAgent.content,
                            'listing',
                        ),
                    );
                }
            }
            yield put(Actions.connectListingSucceeded());
            yield put(fetchMyListingsRequested(agentObjectId));
            yield put(Actions.fetchMyListings());
            yield put(Actions.fetchMyListing(listingId, false));
        } else {
            yield put(Actions.connectListingFailed('Nothing was added'));
            // yield call(alert, {
            //     type: 'error',
            //     message: 'Could not connect your listing, please try again.',
            // });
        }
    } catch (error) {
        yield put(Actions.connectListingFailed(error));
    }
}

/**
 * This function will check if the listing allows for overlapping showings.
 * If it does not, check the agentListings available time slots along with
 * current times available to get a view of what times are blocked off
 * for the calendar view on the finalize schedule showing screen.
 * @param {listingId} the listingId associated with the listing
 * that an agent is trying to schedule on
 */
export function* fetchShowingAvailability({
    listingId,
}: ReturnType<typeof Actions.fetchShowingAvailabilityRequested>): any {
    try {
        const listing = yield call(findRecord, LISTINGS_COLLECTION, {
            _id: listingId,
        });
        if (listing) {
            const firstDate: any = new Date();
            var lastDate: any = new Date();
            lastDate.setHours(0, 0, 0, 0);
            lastDate.setDate(lastDate.getDate() + 7);
            const showings = yield call(runQuery, SHOWING_REQUESTS, {
                listingId,
                start: {
                    $gte: firstDate,
                },
                end: {
                    $lt: lastDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            });

            const unavailableDates = getUnavailableDates(listing, showings);

            if (unavailableDates) {
                yield put(Actions.fetchShowingAvailabilitySucceeded(unavailableDates));
            }
        }
    } catch (error) {
        const mes = parseStitchServiceError(error);
        yield put(Actions.fetchShowingAvailabilityFailed(mes));
    }
}

function* fetchShowings({ startTime, endTime }: any): Generator<any, any, any> {
    try {
        const agent = yield select(getProfileData);
        const agentIdToSearch = agent._id || 'Placeholder non-existant object Id';
        const minutesUtcOffset = new Date().getTimezoneOffset();
        const utcOffset = Math.floor(minutesUtcOffset / 60);
        const formattedUtcOffset = `-0${utcOffset}`;
        try {
            const showings = yield call(
                callStitchFunction,
                'getMyShowingHistory',
                USER_TYPE_AGENT,
                {
                    $match: {
                        agentId: agentIdToSearch,
                        start: { $gte: startTime },
                        end: { $lt: endTime },
                    },
                },
                100, // <- limit to how many we can fetch
                formattedUtcOffset,
            );
            yield put(Actions.fetchScheduleSucceeded(showings));
        } catch (err) {
            const error = parseStitchServiceError(err);
            yield put(Actions.fetchScheduleFailed([error]));
        }
    } catch (error) {
        yield put(Actions.fetchScheduleFailed(error));
    }
}

export function* fetchShowingsForListing({
    listingId,
}: ReturnType<typeof Actions.fetchShowingsForListingRequested>): Generator<any, any, any> {
    try {
        const today = DateTime.fromMillis(Date.now()).startOf('day').toJSDate();
        const endOfWeek = DateTime.fromMillis(Date.now()).plus({ week: 1 }).toJSDate();
        const query = {
            listingId: typeof listingId === 'string' ? new BSON.ObjectId(listingId) : listingId,
            start: {
                $gt: today,
                $lt: endOfWeek,
            },
        };
        const showings = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query,
            signature: generateSignature(),
        });

        yield put(Actions.fetchShowingsForListingSucceeded(showings));
    } catch (err) {
        yield put(Actions.fetchShowingsForListingFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(FETCH_ACTIVE_LISTINGS_REQUESTED, fetchActiveListings),
        takeLatest(FETCH_LISTING_REQUESTED, fetchRequestedListing),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_ACTION.Create && action.status === STATUS.Requested,
            createShowing,
        ),
        takeLatest(CLIENT_SEARCH_ACTION.Request, textSearchClient),
        takeLatest(Actions.FETCH_SHOWINGS_FOR_LISTING_REQUESTED, fetchShowingsForListing),
        takeLatest(CONNECT_LISTING_REQUESTED, connectListing),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_ACTION.ShowingAvailability &&
                action.status === STATUS.Requested,
            fetchShowingAvailability,
        ),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_ACTION.FetchSchedule && action.status === STATUS.Requested,
            fetchShowings,
        ),
        takeLatest(Actions.FETCH_SSO_LISTING_REQUESTED, fetchSSOListing),
        takeLatest(Actions.FETCH_SSO_UNCONNECTED_LISTING_REQUESTED, fetchSSOUnconnectedListing),
        // takeLatest(
        //     (action: any) =>
        //     action.type === SHOWING_ACTION.Create &&
        //     action.status === STATUS.Succeeded
        // ),
        // takeLatest(
        //     (action: any) =>
        //         action.type === SHOWING_ACTION.Create &&
        //         action.status === STATUS.Failed,
        //     logShowingError
        // ),
    ]);
}
