import * as Actions from './actions';

export const initialState = {
    fetchListing: {},
    fetchListingLoading: false,
    unverifiedUser: null,
    scheduleUnverifiedLoading: false,
    unverifiedShowing: null,

    unverifiedConfirmationShowing: null,
    unverifiedConfirmationShowingListing: null,
    fetchUnverifiedShowingConfirmationLoading: false,
    updateUnverifiedShowingLoading: false,
};

export default (state: any = initialState, action: any) => {
    switch (action.type) {
        // FETCH_LISTING_BY_KEY
        case Actions.FETCH_LISTING_BY_KEY_REQUESTED: {
            return {
                ...state,
                fetchListingLoading: true,
            };
        }
        case Actions.FETCH_LISTING_BY_KEY_SUCCEEDED: {
            return {
                ...state,
                fetchListingLoading: false,
                fetchListing: action.listing,
            };
        }
        case Actions.FETCH_LISTING_BY_KEY_FAILED: {
            return {
                ...state,
                fetchListingLoading: false,
                errors: action.errors,
            };
        }
        // SCHEDULE_UNVERIFIED_SHOWING
        case Actions.SCHEDULE_UNVERIFIED_SHOWING_REQUESTED: {
            return {
                ...state,
                scheduleUnverifiedLoading: true,
                unverifiedShowing: null,
            };
        }
        case Actions.SCHEDULE_UNVERIFIED_SHOWING_SUCCEEDED: {
            return {
                ...state,
                scheduleUnverifiedLoading: false,
                unverifiedShowing: action.showing,
            };
        }
        case Actions.SCHEDULE_UNVERIFIED_SHOWING_FAILED: {
            return {
                ...state,
                scheduleUnverifiedLoading: false,
                errors: action.errors,
            };
        }
        // SET_UNVERIFIED_USER_DATA
        case Actions.SET_UNVERIFIED_USER_DATA_REQUESTED: {
            return {
                ...state,
                unverifiedUser: action.data,
            };
        }
        // FETCH_UNVERIFIED_SHOWING
        case Actions.FETCH_UNVERIFIED_SHOWING_REQUESTED: {
            return {
                ...state,
                fetchUnverifiedShowingConfirmationLoading: true,
            };
        }
        case Actions.FETCH_UNVERIFIED_SHOWING_SUCCEEDED: {
            return {
                ...state,
                fetchUnverifiedShowingConfirmationLoading: false,
                unverifiedConfirmationShowing: action.showing,
                unverifiedConfirmationShowingListing: action.listing,
            };
        }
        case Actions.FETCH_UNVERIFIED_SHOWING_FAILED: {
            return {
                ...state,
                fetchUnverifiedShowingConfirmationLoading: false,
                errors: action.errors,
            };
        }
        // UPDATE_UNVERIFIED_SHOWING_STATUS
        case Actions.UPDATE_UNVERIFIED_SHOWING_STATUS_REQUESTED: {
            return {
                ...state,
                updateUnverifiedShowingLoading: true,
            };
        }
        case Actions.UPDATE_UNVERIFIED_SHOWING_STATUS_SUCCEEDED: {
            return {
                ...state,
                updateUnverifiedShowingLoading: false,
            };
        }
        case Actions.UPDATE_UNVERIFIED_SHOWING_STATUS_FAILED: {
            return {
                ...state,
                updateUnverifiedShowingLoading: false,
                errors: action.errors,
            };
        }
        default: {
            return state;
        }
    }
};
