export default (theme) => {
    const textStyles = {
        fontFamily: 'Poppins-medium',
        fontSize: 16,
        color: theme.palette.black,
    };
    const headerStyles = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.white,
        ...theme.paddingVertical(28),
        ...theme.paddingHorizontal(48),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey,
        alignItems: 'center',
    };
    return {
        root: {
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        listingInfoBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(24),
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            backgroundColor: theme.palette.white,
            borderBottomColor: theme.palette.grey,
        },
        cardsContainer: {
            // STYLE_NOTE: Headers taken into calculation of main content size
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'center',
            position: 'relative',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 10,
            ...theme.paddingHorizontal(10),
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        listingDetailsContainer: {
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        header: {
            ...headerStyles,
            justifyContent: 'space-between',
            fontFamily: 'Poppins-semiBold',
            color: theme.palette.mediumGrey,
            fontSize: 18,
        },
        selectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        leftHeader: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        price: {
            ...textStyles,
            fontFamily: 'Poppins-semiBold',
            fontSize: 26,
        },
        address1: {
            ...textStyles,
            fontSize: 18,
            fontFamily: 'Poppins-semiBold',
            marginRight: 6,
        },
        address2: {
            ...textStyles,
            fontSize: 18,
            marginRight: 26,
        },
        tag: {
            backgroundColor: theme.palette.lightGreen,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.green,
            fontSize: 14,
        },
        redTag: {
            backgroundColor: theme.palette.lightRed,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.red,
            fontSize: 14,
        },
        greyTag: {
            backgroundColor: theme.palette.background,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.mediumGrey,
            fontSize: 14,
            marginLeft: 26,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            ...theme.marginHorizontal(16),
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginVertical(16),
        },
        imageSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            // Take away the scrollbar per the wireframes
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            // add height here so it does not bounce when loading in.
            height: 265,
        },
        image: {
            height: 265,
            width: 473,
            marginRight: 16,
        },
        listingInfoSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        queue: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        icon: {
            width: 26,
            height: 26,
            marginRight: 10,
        },
        listingBedBathContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        iconAndText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ...textStyles,
        },
        detailContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ...textStyles,
        },
        detailSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        publicRemarksSectionContainer: {
            marginBottom: 16,
        },
        greyTitle: {
            ...textStyles,
            color: theme.palette.mediumGrey,
            marginBottom: 5,
        },
        textStyle: {
            ...textStyles,
        },
        metaDataSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background,
            height: 160,
            ...theme.marginHorizontal(-48),
            ...theme.paddingVertical(16),
            ...theme.paddingHorizontal(48),
        },
        closeButton: {
            marginRight: 15,
            cursor: 'pointer',
        },
        card: {
            backgroundColor: theme.palette.white,
            height: 298,
            margin: 8,
            borderRadius: 5,
            overflow: 'hidden',
            ...theme.slightShadow,
            display: 'flex',
        },
        dataRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 16,
        },
        key: {
            ...textStyles,
            fontFamily: 'Poppins-SemiBold',
            fontSize: 14,
            marginRight: 5,
        },
        value: {
            ...textStyles,
            fontSize: 14,
        },
        mapContainer: {
            height: 153,
            width: 390,
            padding: 4,
            borderRadius: 5,
            backgroundColor: theme.palette.white,
            ...theme.marginHorizontal(8),
        },
        listedBySectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            alignItems: 'center',
            ...textStyles,
            color: theme.palette.mediumGrey,
        },
        listingAgent: {
            ...textStyles,
            fontFamily: 'Poppins-semiBold',
            marginLeft: 16,
        },
        mlsNumber: {
            ...textStyles,
            fontFamily: 'Poppins-semiBold',
            color: theme.palette.mediumGrey,
            fontSize: 14,
        },
        disclaimer: {
            ...textStyles,
            fontSize: 14,
            color: theme.palette.mediumGrey,
        },
        snackbarAlert: {
            backgroundColor: theme.palette.blue,
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            '& div': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        cardHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 24,
            marginBottom: 5,
        },
        reminderHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            marginBottom: 10,
        },
        reminderText: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            paddingRight: 50,
            marginTop: 20,
        },
        smallCardHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,

            marginBottom: 8,
        },
        codeAndDescription: {
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            marginTop: 10,
        },
        codeContainer: {
            height: 120,
            width: 120,
        },
        cardDescription: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            paddingRight: 50,
        },
        timeAndDate: {
            flexDirection: 'column',
            marginTop: 15,
            display: 'flex',
        },
        iconAndText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            height: 20,
            width: 20,
            marginRight: 10,
        },
        dateTimeText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
        },
        userPhoto: {
            height: 50,
            width: 50,
            borderRadius: 60,
            marginBottom: 8,
        },
        cardContent: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(35),
            justifyContent: 'center',
        },
        reminderCardContent: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            paddingLeft: 48,
            paddingTop: 50,
            justifyContent: 'flex-start',
        },
        cardFirstContent: { flexDirection: 'column', display: 'flex' },
        cardArrowButton: {
            height: 40,
            width: 40,
            position: 'absolute',
            top: 20,
            right: 20,
            '&:active': {
                opacity: 0.5,
            },
        },
        cardMessageBlast: {
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            display: 'flex',
        },
        cardRowHeader: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            display: 'flex',
        },
        smallPhoto: {
            height: 40,
            width: 40,
            borderRadius: 40,
        },
        listingAgentName: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            paddingLeft: 10,
        },
        messageSentText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            paddingLeft: 5,
            marginBottom: -1,
        },
        viewMessages: {
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            fontSize: 17,
            color: theme.palette.blue,
            fontFamily: 'Poppins-Semibold',
            '&:active': {
                opacity: 0.5,
            },
            cursor: 'pointer',
        },
        viewLocationLink: {
            fontSize: 16,
            color: theme.palette.blue,
            ...theme.typography.semiBold,
        },
        viewMessagesDescription: {
            fontFamily: 'Poppins-Medium',
            color: '#9a9a9a',
            fontSize: 16,
            paddingTop: 10,
            paddingRight: 50,
        },
        messagesArrow: {
            height: 25,
            width: 25,
            paddingLeft: 10,
        },
        lockboxPhotoContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.paddingVertical(54),
        },
        lockboxPhoto: {
            borderRadius: 7.5,
        },
        infoHeader: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            color: '#9a9a9a',
            marginBottom: -3,
            paddingTop: 10,
        },
        infoText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            color: theme.palette.black,
        },
        loaderContainer: {
            flex: 1,
            alignItems: 'center',
            paddingTop: 100,
            display: 'flex',
            flexDirection: 'column',
        },
    };
};
