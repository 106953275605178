import React, { useEffect, useState } from 'react';
import styles from './styles';
import stylesCSS from '../../../utils/commonStyles.module.css';

import { createUseStyles, useTheme } from 'react-jss';
import agentWordmark from '../../../agentWordmark.svg';
import HelpIcon from '../../../images/helpCenterIcon.svg';
import { fetchListingByKeyRequested, setUnverifiedUserData } from '../actions';
import { getCreatedUnverifiedShowing, getFetchedUnverifiedListing } from '../selectors';
import ListingDetailsComponent from '../../main/ListingDetailsScreen/ListingDetailsComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import { formatCurrency } from '../../../utils/common/transforms';
import { ConnectedScheduleScreen } from '../../main/ShowingRequests/ScheduleScreen';
import UnverifiedUserDrawer from './UnverifiedUserDrawer';
import { RemoveScroll } from 'react-remove-scroll';
import { ClickableWithFeedback, Loader } from '../../../components';
import CloseIcon from '../../../images/blackXIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../../../utils/common';

interface Props {
    listingKey: any;
}

const ListingDetailsScreenUnverified = (props: Props) => {
    const listing = useSelector((state) => getFetchedUnverifiedListing(state).listing);
    const loading = useSelector((state) => getFetchedUnverifiedListing(state).loading);
    const unverifiedShowing = useSelector(getCreatedUnverifiedShowing);
    const dispatch = useDispatch();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);
    const [showUnverifiedUserDrawer, setShowUnverifiedUserDrawer] = useState<boolean>(false);
    const [showScheduledOverlay, setShowScheduledOverlay] = useState<boolean>(false);

    // if showing was created, close both drawers
    useEffect(() => {
        if (unverifiedShowing?._id) {
            setShowScheduleDrawer(false);
            setShowUnverifiedUserDrawer(false);
            dispatch(setUnverifiedUserData(null));
            setShowScheduledOverlay(true);
        }
    }, [unverifiedShowing]);

    useEffect(() => {
        dispatch(fetchListingByKeyRequested(props.listingKey));
        return () => {};
    }, []);

    const HeaderBar = () => {
        return (
            <div className={stylesCSS.header}>
                <img src={agentWordmark} className={stylesCSS.wordmark} />
                <div className={stylesCSS.profileIcons}>
                    <img
                        src={HelpIcon}
                        className={stylesCSS.headerIcon}
                        onClick={() => window.open('https://www.support.showingly.com/knowledge')}
                    />
                </div>
            </div>
        );
    };

    const connected = !!listing.agentListing?.listingId;
    const header = isMobile() ?
        (
            <>
                <div className={styleSheet.table}>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.subHeader}`}>
                            {'$' + formatCurrency(listing.listPrice)}
                        </div>
                        <div className={styleSheet.tableCol}>
                            {listing.mlsList?.length && listing.mlsList[0]?.standardStatus === 'Active' ? (
                                <div className={styleSheet.textRight}>
                                    <span className={styleSheet.tag}>
                                        For Sale
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={styleSheet.tableRow}>
                        <div className={styleSheet.tableCol}>
                            <span className={styleSheet.address1}>
                                {listing.address?.full}
                            </span>
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.address2}>
                                {listing.address?.city}, {listing.address?.state} {listing.address?.postal}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styleSheet.textCenter}>
                    <ButtonComponent
                        fill
                        width={257}
                        onClick={() => {
                            setShowUnverifiedUserDrawer(true);
                        }}
                    >
                        {connected ? 'Schedule' : 'Log'}
                    </ButtonComponent>
                </div>
            </>
        ) :
        (
            <div className={styleSheet.header}>
                <div className={styleSheet.leftHeader}>
                    <span className={styleSheet.price}>{'$' + formatCurrency(listing.listPrice)}</span>
                    <div className={styleSheet.verticalSeparator} />
                    <span className={styleSheet.address1}>{listing.address?.full}</span>
                    <span className={styleSheet.address2}>
                        {listing.address?.city}, {listing.address?.state} {listing.address?.postal}
                    </span>
                    {listing.mlsList?.length && listing.mlsList[0]?.standardStatus === 'Active' ? (
                        <span className={styleSheet.tag}>For Sale</span>
                    ) : null}
                </div>
                <div className={styleSheet.buttonContainer}>
                    <ButtonComponent
                        className={styleSheet.button}
                        fill
                        width={257}
                        onClick={() => {
                            setShowUnverifiedUserDrawer(true);
                        }}
                    >
                        {connected ? 'Schedule' : 'Log'}
                    </ButtonComponent>
                </div>
            </div>
    );

    if (loading || !listing?._id) {
        return (
            <div className={styleSheet.loaderContainer}>
                <Loader size={150} />
            </div>
        );
    }

    const successMessage = (
        <RemoveScroll>
            <div className={styleSheet.overlayBackground}>
                <div className={styleSheet.whiteContainer}>
                    <div className={styleSheet.scheduledHeader}>Showing Requested.</div>
                    <div className={styleSheet.scheduledBody}>
                        All status updates and access information will be delivered to the
                        email{'\n'}provided.
                    </div>
                    <ClickableWithFeedback
                        className={styleSheet.closeIconContainer}
                        onClick={() => setShowScheduledOverlay(false)}
                    >
                        <img src={CloseIcon} className={styleSheet.closeIcon} />
                    </ClickableWithFeedback>
                </div>
            </div>
        </RemoveScroll>
    );

    const successMessageMobile = (
        <div className={styleSheet.overlayBackground}>
            <div className={styleSheet.whiteContainerMobile}>
                <div className={styleSheet.scheduledHeaderMobile}>HURRAY!</div>
                <div className={styleSheet.scheduledBodyTitleMobile}>Showing Requested</div>
                <div className={styleSheet.scheduledBodyMobile}>
                    All status updates and access information will be delivered to the
                    email{'\n'}provided.
                </div>
                <div className={styleSheet.scheduledButtonMobile}>
                    <ButtonComponent
                        fill
                        width={257}
                        onClick={() => setShowScheduledOverlay(false)}
                    >
                        Finish
                    </ButtonComponent>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <div style={{ marginTop: 50 }}>
                <ListingDetailsComponent
                    header={
                        <>
                            {header}
                            <div className={styleSheet.separator} />
                        </>
                    }
                    listing={listing}
                />
            </div>
            <UnverifiedUserDrawer
                onClose={() => setShowUnverifiedUserDrawer(false)}
                visible={showUnverifiedUserDrawer}
                setShowScheduleDrawer={setShowScheduleDrawer}
            />
            <ConnectedScheduleScreen
                listing={listing}
                opType={'UnverifiedSchedule'}
                onClose={() => setShowScheduleDrawer(false)}
                visible={showScheduleDrawer}
                clientDrawerInteract={() => {}}
                backgroundOpacity={0}
            />
            {showScheduledOverlay ? (
                <>{isMobile() ? successMessageMobile : successMessage}</>
            ) : null}
        </div>
    );
};
export default ListingDetailsScreenUnverified;
