const action = {
    goldDigger: 'Gold Digger', // agent
    stalker: 'Stalker', // agent
    siebarthSalesAward: 'Siebarth Sales Award', // agent
    teachersPet: `Teacher's Pet`, // agent
    iSeeYou: 'I See You', // agent
    betterLuckNextTime: 'Better Luck Next Time', // agent
    resourceful: 'Resourceful', // agent
    swipeRight: 'Swipe Right', // consumer
    nightOwl: 'Night Owl', // stitch
    earlyBird: 'Early Bird', // stitch
    informant: 'Informant', // agent
    preach: 'Preach', // consumer
    testify: 'Testify', // consumer
    lookinGood: `Lookin' Good`, // agent
    welcomeHome: 'Welcome Home', // agent
    koolAid: 'Kool-Aid', // agent
    iAccept: 'I Accept', // agent
    innovator: 'Innovator', // agent
    busyBee: 'Busy Bee', // agent
    lifeOfLuxary: 'Life of Luxary', // agent
};
const manual = {
    connectorGold: 'Connector (Gold)',
    connectorSilver: 'Connector (Silver)',
    connectorBronze: 'Connector (Bronze)',
    influencerGold: 'Influencer (Gold)',
    influencerSilver: 'Influencer (Silver)',
    influencerBronze: 'Influencer (Bronze)',
    showoffGold: 'Showoff (Gold)',
    showoffSilver: 'Showoff (Silver)',
    showoffBronze: 'Showoff (Bronze)',
    aListerGold: 'A-Lister (Gold)',
    aListerSilver: 'A-Lister (Silver)',
    aListerBronze: 'A-Lister (Bronze)',
    executiveGold: 'Executive (Gold)',
    executiveSilver: 'Executive (Silver)',
    executiveBronze: 'Executive (Bronze)',
    hustlerGold: 'Huster (Gold)',
    hustlerSilver: 'Huster (Silver)',
    hustlerBronze: 'Huster (Bronze)',
    goat: 'GOAT',
    closeButNoCigar: 'Close But No Cigar',
    hallOfFame: 'Hall of Fame',
    champChamp: 'Champ Champ',
    coolCoolAwesome: 'Cool, Cool, Awesome',
    inviteOnly: 'Invite Only',
};
export const BadgeTypes = {
    action,
    manual,
};
