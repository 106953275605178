export default (theme: any) => {
    return {
        dropDownComponent: (props: any) => ({
            height: props?.height ?? 40,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            marginTop: props?.topMargin ?? 20,
            marginBottom: props?.bottomMargin ?? 30,
            width: props?.width ?? 400,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            borderRadius: 5,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        droppedDropDownComponent: (props: any) => ({
            height: props?.height ?? 40,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            marginTop: props?.topMargin ?? 20,
            marginBottom: props?.bottomMargin ?? 30,
            width: props?.width ?? 400,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            borderRadius: 5,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            '&:active': {
                opacity: 0.9,
            },
            cursor: 'pointer',
        }),
        dropdownIcon: {
            height: 24,
            width: 24,
            position: 'absolute',
            right: '10px',
        },
        menu: (props: any) => ({
            backgroundColor: theme.palette.white,
            width: props?.width + 20 ?? 400,
            position: 'absolute',
            top: (props?.height ?? 40) + (props?.topMargin ?? 20),
            left: 0,
            borderRadius: 5,
            zIndex: 999,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: `1px solid ${theme.palette.grey}`,
            maxHeight: 160,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                background: '#e9e9e9',
                width: 16,
            },
            '&::-webkit-scrollbar-track': {
                background: '#e9e9e9',
                border: '2px solid #e9e9e9',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#d3d3d3',
                borderRadius: 4,
                border: '2px solid #e9e9e9',
            },
            scrollbarColor: '#d3d3d3 #e9e9e9',
            ...theme.cardShadow,
        }),
        menuItem: (props: any) => ({
            alignItems: 'center',
            height: 30,
            paddingTop: 5,
            paddingLeft: 5,
            marginRight: 9,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
    };
};
