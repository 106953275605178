import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { PieChart, PercentileCard, DataCard, Loader, Button, SearchableDropdown } from 'web-lib';
import { useHistory } from 'react-router';
import { abbreviateNumber } from '../../../../../utils/common';

import styles from './index.module.css';
import { AgentStats as AgentStatsType, metricMember } from '../../../../../utils/constants';
import { SelectedAgentSocial } from '../../../../../utils/constants';

interface AgentStatsProps {
    isCurrentUser?: boolean;
    isSubscribed?: boolean;
    selectedAgentsStats: any;
    selectedAgentsMarkets: Array<string>;
    selectedAgent?: SelectedAgentSocial;
    loadingProfile: boolean;
    loadingStats: boolean;
}

type filterTypes = 'Last 30d' | '6m' | '12m' | '24m' | 'MTD' | 'YTD';

type auxDataCard = {
    count: number;
    vol: number;
    avg: number;
};

const AgentStats = ({
    isCurrentUser,
    isSubscribed,
    selectedAgentsStats,
    selectedAgentsMarkets,
    selectedAgent,
    loadingProfile,
    loadingStats,
}: AgentStatsProps) => {
    const history = useHistory();
    const defaultData = { count: 0, vol: 0, avg: 0 };
    const [filter, setFilter] = useState<filterTypes>('Last 30d');
    const [buySideData, setBuySideData] = useState<auxDataCard>(defaultData);
    const [sellSideData, setSellSideData] = useState<auxDataCard>(defaultData);
    const [selectedOption, setSelectedOption] = useState<'Volume' | 'Units'>('Volume');
    const [agentStats, setAgentStats] = useState<any>();

    const [selectedMarket, setSelectedMarket] = useState<string>();

    useEffect(() => {
        if (selectedAgentsMarkets.length) {
            setSelectedMarket(selectedAgentsMarkets[0]);
        }
    }, [selectedAgentsMarkets]);

    useEffect(() => {
        if (selectedMarket) {
            setAgentStats(selectedAgentsStats[selectedMarket]?.statDoc);
            setBuySideData(selectedAgentsStats[selectedMarket]?.buySide[0][metricMember[filter]]);
            setSellSideData(selectedAgentsStats[selectedMarket]?.sellSide[0][metricMember[filter]]);
        }
    }, [selectedAgentsStats, selectedMarket, filter]);

    const SubscribeCard = () => (
        <div>
            <span className={styles.subscribeCardTitle}>
                Upgrade to Showingly+ to see full statistics.
            </span>
            <Button
                className={styles.subscribeCardButton}
                onClick={() => {
                    history.push('/profile');
                }}
                buttonText={isCurrentUser ? 'See Your Stats' : 'See Their Stats'}
            />
        </div>
    );

    if (!selectedAgent || loadingProfile || loadingStats) {
        return (
            <div className={styles.listingsDataLoaderContainer}>
                <Loader icon={'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'} />
            </div>
        );
    } else if (agentStats) {
        return (
            <>
                {!isSubscribed && !isCurrentUser && (
                    <div className={styles.subscribeCardContainer}>
                        <SubscribeCard />
                    </div>
                )}

                <SearchableDropdown
                    className={styles.marketsDropdown}
                    dropdownStyle={styles.dropDownStyle}
                    inputStyle={styles.dropDownInput}
                    optionStyle={styles.optionStyle}
                    defaultValue={selectedAgentsMarkets[0]}
                    id={'marketSelect'}
                    noMatchMessage={'No match found'}
                    options={selectedAgentsMarkets}
                    setCurrentValue={(market: string) => setSelectedMarket(market)}
                />

                <div className={styles.filterButtonContainer}>
                    <Button
                        className={
                            filter === 'Last 30d'
                                ? styles.selectedFilterButton
                                : styles.filterButton
                        }
                        buttonText={'Last 30d'}
                        onClick={() => isSubscribed && setFilter('Last 30d')}
                    />
                    {['6m', '12m', '24m', 'MTD', 'YTD'].map((buttonText: string) => (
                        <div key={buttonText}>
                            <Button
                                className={
                                    isSubscribed
                                        ? filter === buttonText
                                            ? styles.selectedFilterButton
                                            : styles.filterButton
                                        : styles.freeVersionButton
                                }
                                buttonText={buttonText}
                                onClick={() => isSubscribed && setFilter(buttonText as filterTypes)}
                            />
                        </div>
                    ))}
                </div>

                <div className={styles.chartsAndDataContainer}>
                    <DataCard
                        className={
                            isSubscribed || isCurrentUser
                                ? styles.listSideDataCard
                                : styles.dataCardFreeVersion
                        }
                        title={'List Side'}
                        titleStyle={
                            isSubscribed || isCurrentUser
                                ? styles.listSideDataCardTitleStyle
                                : styles.dataCardTitleFreeVersion
                        }
                        labelStyle={
                            !(isSubscribed || isCurrentUser)
                                ? styles.labelStyleFreeVersion
                                : styles.labelStyle
                        }
                        dataContainerStyle={
                            isSubscribed || isCurrentUser
                                ? styles.listSideDataContainerStyle
                                : styles.dataContainerFreeVersion
                        }
                        data={[
                            {
                                label: 'Units Sold',
                                value: isSubscribed || isCurrentUser ? sellSideData.count : '. . .',
                            },
                            {
                                label: 'Volume',
                                value:
                                    isSubscribed || isCurrentUser
                                        ? '$' + abbreviateNumber(sellSideData.vol)
                                        : '. . .',
                            },
                            {
                                label: 'Average Sold Price',
                                value:
                                    isSubscribed || isCurrentUser
                                        ? '$' + abbreviateNumber(sellSideData.avg)
                                        : '. . .',
                            },
                        ]}
                    />
                    <div className={styles.pieChartContainer}>
                        <div className={styles.pieChartOptionContainer}>
                            <span
                                onClick={() => setSelectedOption('Volume')}
                                className={
                                    selectedOption === 'Volume'
                                        ? styles.pieChartOptionSelected
                                        : styles.pieChartOption
                                }
                            >
                                Volume
                            </span>
                            <span
                                onClick={() => setSelectedOption('Units')}
                                className={
                                    selectedOption === 'Units'
                                        ? styles.pieChartOptionSelected
                                        : styles.pieChartOption
                                }
                            >
                                Units
                            </span>
                        </div>
                        <div className={styles.circleStats}>
                            <div
                                className={
                                    isSubscribed || isCurrentUser
                                        ? styles.circle
                                        : classnames(styles.circle, styles.circleFreeVersion)
                                }
                            >
                                <p style={{ marginTop: '30px', marginBottom: '5px' }}>
                                    {selectedOption === 'Volume' ? 'Total Volume' : 'Total Units'}
                                </p>
                                <div className={styles.stats}>
                                    {isSubscribed || isCurrentUser ? (
                                        <span>
                                            {selectedOption === 'Volume' ? (
                                                <>
                                                    <span className={styles.dollarSign}>$</span>
                                                    {abbreviateNumber(
                                                        buySideData.vol + sellSideData.vol,
                                                    )}
                                                </>
                                            ) : (
                                                abbreviateNumber(
                                                    buySideData.count + sellSideData.count,
                                                )
                                            )}
                                        </span>
                                    ) : (
                                        '. . .'
                                    )}
                                </div>
                                <p>Average Sold Price</p>
                                <div className={styles.stats}>
                                    $
                                    <span>
                                        {isSubscribed || isCurrentUser
                                            ? abbreviateNumber(sellSideData.avg)
                                            : '. . .'}
                                    </span>
                                </div>
                            </div>
                            <PieChart
                                innerRadius={85}
                                height={185}
                                width={185}
                                data={[
                                    {
                                        value:
                                            isSubscribed || isCurrentUser
                                                ? buySideData.vol + sellSideData.vol
                                                : 1,
                                        color:
                                            isSubscribed || isCurrentUser ? '#48A4FF' : '#d3d3d3',
                                    },
                                    {
                                        value: isSubscribed || isCurrentUser ? sellSideData.avg : 0,
                                        color:
                                            isSubscribed || isCurrentUser ? '#2FD2A8' : '#d3d3d3',
                                    },
                                ]}
                                duration={isSubscribed || isCurrentUser ? 1000 : 0}
                                padAngle={0.04}
                                cornerRadius={10}
                            />
                        </div>
                    </div>
                    <DataCard
                        className={
                            isSubscribed || isCurrentUser
                                ? styles.buySideDataCard
                                : styles.dataCardFreeVersion
                        }
                        title={'Buy Side'}
                        titleStyle={
                            isSubscribed || isCurrentUser
                                ? styles.buySideDataCardTitleStyle
                                : styles.dataCardTitleFreeVersion
                        }
                        labelStyle={
                            !(isSubscribed || isCurrentUser)
                                ? styles.labelStyleFreeVersion
                                : styles.labelStyle
                        }
                        dataContainerStyle={
                            isSubscribed || isCurrentUser
                                ? styles.buySideDataContainerStyle
                                : styles.dataContainerFreeVersion
                        }
                        data={[
                            {
                                label: 'Units Sold',
                                value: isSubscribed || isCurrentUser ? buySideData.count : '. . .',
                            },
                            {
                                label: 'Volume',
                                value:
                                    isSubscribed || isCurrentUser
                                        ? '$' + abbreviateNumber(buySideData.vol)
                                        : '. . .',
                            },
                            {
                                label: 'Average Sold Price',
                                value:
                                    isSubscribed || isCurrentUser
                                        ? '$' + abbreviateNumber(buySideData.avg)
                                        : '. . .',
                            },
                        ]}
                    />
                </div>

                <div className={styles.subscribeCardContainer}>
                    {!isSubscribed && isCurrentUser ? <SubscribeCard /> : null}
                </div>

                <div className={styles.dividerLine} />
                <span className={styles.percentilesTitle}>Percentiles</span>

                <div className={styles.percentileRows}>
                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Total Active Listings
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed || isCurrentUser
                                    ? abbreviateNumber(agentStats.activeListingCount)
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.activeListingPerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={110}
                        />
                    </div>

                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed
                                    ? styles.statsTitles
                                    : classnames(styles.statsTitles, styles.freeVersionText)
                            }
                        >
                            Volume
                        </span>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Year To Date
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed
                                    ? abbreviateNumber(agentStats.ytdBuyVol + agentStats.ytdSellVol)
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.ytdVolPerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed
                                    ? styles.statsTitles
                                    : classnames(styles.statsTitles, styles.freeVersionText)
                            }
                        >
                            Units
                        </span>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Year To Date
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed
                                    ? abbreviateNumber(
                                          agentStats.ytdSellUnits + agentStats.ytdBuyUnits,
                                      )
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.ytdUnitsPrec : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                    <div className={styles.percentileCardContainer}>
                        <p
                            className={
                                isSubscribed
                                    ? styles.statsTitles
                                    : classnames(styles.statsTitles, styles.freeVersionText)
                            }
                        >
                            Avg. Sold Price
                        </p>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Year To Date
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={isSubscribed ? abbreviateNumber(agentStats.ytdAvgPrice) : ''}
                            percentile={isSubscribed ? agentStats.ytdAvgPricePerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                </div>
                <div className={styles.percentileRows}>
                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Number of Consumers
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed || isCurrentUser
                                    ? abbreviateNumber(
                                          agentStats.activeClientCount +
                                              agentStats.loggedClientCount,
                                      )
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.consumerPerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={110}
                        />
                    </div>

                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Last Year
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed
                                    ? abbreviateNumber(
                                          agentStats.prevBuyVol + agentStats.prevSellVol,
                                      )
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.prevYearVolPerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Last Year
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={
                                isSubscribed
                                    ? abbreviateNumber(
                                          agentStats.prevSellUnits + agentStats.prevBuyUnits,
                                      )
                                    : ''
                            }
                            percentile={isSubscribed ? agentStats.prevYearUnitsPerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                    <div className={styles.percentileCardContainer}>
                        <span
                            className={
                                isSubscribed ? styles.percentileDescription : styles.freeVersionText
                            }
                        >
                            Last Year
                        </span>
                        <PercentileCard
                            className={styles.percentileCard}
                            title={isSubscribed ? abbreviateNumber(agentStats.prevAvgPrice) : ''}
                            percentile={isSubscribed ? agentStats.prevYearAvgPricePerc : undefined}
                            percentileStyle={isSubscribed ? '' : styles.freeVersionText}
                            width={(document.getElementById('column')?.offsetWidth || 300) / 3}
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className={styles.statsLoaderContainer}>
                <Loader />
            </div>
        );
    }
};

export default AgentStats;
