import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { Button } from 'web-lib';
import { ConnectedProps } from 'react-redux';
import classnames from 'classnames';

import {
    getLoginInProgress,
    getLoginRejected,
    getPassReset,
    getIsSendingPasswordResetEmail,
} from '../selectors';
import { userLoginRequested, passwordResetRequested } from '../actions';

import styles from './index.module.css';
import agentLogo from '../../../images/agentFullWordmarkGray.svg';
import waves from '../../../images/waves.svg';
import people from '../../../images/people.svg';
import check from '../../../images/checkmark.svg';

const LoginPage = (props: {}) => {
    const isLoginInProgress = useSelector(getLoginInProgress);
    const isLoginRejected = useSelector(getLoginRejected);
    const isSendingPasswordResetEmail = useSelector(getIsSendingPasswordResetEmail);
    const emailSent = useSelector(getPassReset);
    const pendingUserEmail = useSelector((state: any) => state.auth.pendingUser.email);
    const dispatch = useDispatch();
    const onPressLogin = (email: any, password: any, history: any, path: string | undefined) =>
        dispatch(userLoginRequested(email, password, history, path));
    const onResetPass = (email: any) => dispatch(passwordResetRequested(email));

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const [rememberMe, setRememberMe] = useState(true);
    const [hasPressedLogin, setHasPressedLogin] = useState(false);
    const myQueueData = localStorage.getItem('queuedShowings');

    const onSubmit = ({ password }: { email: string; password: string }) => {
        setHasPressedLogin(true);

        if (myQueueData) {
            onPressLogin(pendingUserEmail, password, history, '/myQueue');
        } else {
            onPressLogin(pendingUserEmail, password, history, undefined);
        }
    };
    const resetPassword = () => {
        if (pendingUserEmail) {
            onResetPass(pendingUserEmail);
        } else {
            console.log('No email address detected.');
        }
    };

    // defaulting "Remember Me" to always be true
    localStorage.setItem('remember', rememberMe.toString());

    useEffect(() => {
        if (!pendingUserEmail) {
            history.push('/auth');
        }
    }, [pendingUserEmail]);

    // display the user's email without the trailing '+12345' display, but use '+12345' to login
    const getDisplayEmail = (email: any) => {
        if (email?.indexOf('+')) {
            const front = email.substr(0, email.indexOf('+'));
            const end = email.substr(email.indexOf('@'), email.length);
            if (front?.length && end?.length) {
                return front + end;
            }
        }
        return email;
    };

    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <img className={styles.logoImage} src={agentLogo} />
            </div>
            <Row>
                <Col>
                    <div className={styles.peopleStyle}>
                        <img src={people} />
                    </div>
                </Col>
                <Col>
                    <div
                        className={
                            !myQueueData ? styles.card : classnames(styles.card, styles.longCard)
                        }
                    >
                        <div className={styles.welcomeStyle}>Welcome</div>

                        {myQueueData && (
                            <p className={styles.queuedShowingsMessage}>
                                Please login and you will be re-directed to your queued showings
                            </p>
                        )}

                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={styles.inputGroup}>
                                <input
                                    id={'email'}
                                    className={styles.formControl}
                                    value={getDisplayEmail(pendingUserEmail)}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <input
                                    id={'password'}
                                    ref={register({
                                        required: 'Password field is required',
                                    })}
                                    type={'password'}
                                    name={'password'}
                                    className={styles.formControl}
                                    placeholder="Password"
                                />
                                {/* <button
                                    type="button"
                                    className={
                                        isSendingPasswordResetEmail
                                            ? styles.forgotPasswordTextDisabled
                                            : styles.forgotPasswordTextEnabled
                                    }
                                    onClick={() => {
                                        resetPassword();
                                    }}
                                >
                                    Forget Password?
                                </button>
                                {emailSent && !hasPressedLogin && (
                                    <span className={styles.emailSentText}>
                                        {'Password reset email sent at '}
                                        {getDisplayEmail(pendingUserEmail)}
                                    </span>
                                )} */}
                                {(errors.password && hasPressedLogin && (
                                    <span className={styles.errorText}>{'Invalid Password'}</span>
                                )) ||
                                    (isLoginRejected && hasPressedLogin ? (
                                        <span className={styles.errorText}>
                                            {'Incorrect Username or Password'}
                                        </span>
                                    ) : null)}
                            </div>
                            {/* <div className={styles.checkdiv}>
                                <div
                                    onClick={() => {
                                        setRememberMe(!rememberMe);
                                        localStorage.setItem('remember', (!rememberMe).toString());
                                    }}
                                    className={styles.checkbox}
                                >
                                    {rememberMe && <img className={styles.check} src={check} />}
                                </div>
                                <div className={styles.remember}>Remember me on this device</div>
                            </div> */}
                            <div className={styles.flexDiv}>
                                <Button
                                    buttonText="Login"
                                    className={
                                        hasPressedLogin
                                            ? styles.buttonPressedLogin
                                            : styles.buttonLogin
                                    }
                                    disabled={isLoginInProgress || isSendingPasswordResetEmail}
                                />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>

            <Row>
                <div className={styles.waveStyle}>
                    <img src={waves} />
                </div>
            </Row>
        </div>
    );
};

export default LoginPage;
