import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnauthenticatedApp from './unauthenticated-app';
import AuthenticatedApp from './authenticated-app';
import {
    isAuthLoading,
    getIsUserAuthenticated,
    getHasAuthLinkExpiredOrIsInvalid,
    getIsUnathenticated,
    getAuthErrors,
} from './domains/auth/selectors';
import fullWatermark from './images/agentGreyFullWordmark.png';
import { isMobile } from './utils/common';
import { clearAuthErrors } from './domains/auth/actions';
import styles from './App.module.css';
import { Button } from 'web-lib';
import { getIsDelinquent, isProfileLoading } from './domains/main/Profile/selectors';
import DelinquentModal from './components/DelinquentModal';
import { ButtonV4 } from 'web-lib';
import { useHistory } from 'react-router';
import UnconnectedListingsModal from './domains/main/MyListings/UnconnectedListingsModal';
import { Loader } from './components';
import { getSSOUnconnectedListingLoading } from './domains/main/SearchListings/selectors';

function App(props) {
    const authLoading = useSelector(isAuthLoading);
    const profileLoading = useSelector(isProfileLoading);
    const SSOUnconnectedListingLoading = useSelector(getSSOUnconnectedListingLoading);
    const loading = authLoading || profileLoading || SSOUnconnectedListingLoading;

    const isAuthenticated = useSelector(getIsUserAuthenticated);
    const isDelinquent = useSelector(getIsDelinquent);
    const userProfileData = useSelector((state) => state.profile.profileData);
    const userPhotoUri = useSelector(
        (state) => state?.profile?.profileData?.profilePhotoUpload?.uri || '',
    );
    const authLinkExpiredOrInvalid = useSelector(getHasAuthLinkExpiredOrIsInvalid);
    const isUnauthenticated = useSelector(getIsUnathenticated);
    const authErrors = useSelector(getAuthErrors);
    const [isAppAuthenticated, setisAppAuthenticated] = useState(isAuthenticated);
    const dispatch = useDispatch();
    const clearErrors = useCallback(() => dispatch(clearAuthErrors()), []);
    const [renderDelinquentModal, setRenderDelinquentModal] = useState(false);
    const [updatingDelinquentCard, setUpdatingDelinquentCard] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (updatingDelinquentCard) {
            history.push('/profile/addCard');
        }
    }, [updatingDelinquentCard]);

    useEffect(() => {
        if (isDelinquent) {
            setRenderDelinquentModal(true);
        }
    }, [isDelinquent]);

    const closeDelinquentModal = (updateCard) => {
        if (updateCard) {
            setUpdatingDelinquentCard(true);
        }
        setRenderDelinquentModal(false);
    };

    // Mount
    useEffect(() => {
        setisAppAuthenticated(isAuthenticated);
    }, [isAuthenticated]);

    // Check auth errors on startup
    useEffect(() => {
        if (authErrors.length) {
            if (authErrors[0]?.message === 'Illegal invocation') {
                clearErrors();
            }
        }
    }, [authErrors]);

    const location = window.location;
    const path = location.pathname;
    const main = path.split('/');

    if (
        !isMobile() &&
        isAppAuthenticated &&
        !isUnauthenticated &&
        !(main[1].localeCompare('showingRequests') === 0)
    ) {
        if (!userProfileData.markets || loading) {
            return (
                <div className={styles.loader}>
                    <Loader size={100} />
                    <p className={styles.friendlyMsg}>Fetching your data...</p>
                </div>
            );
        }
        return (
            <>
                <AuthenticatedApp
                    userProfileData={userProfileData}
                    userPhoto={userPhotoUri}
                    isDelinquent={isDelinquent}
                />
                {renderDelinquentModal ? (
                    <DelinquentModal closeModal={closeDelinquentModal} />
                ) : null}

                {/* If there is unconnected listings, and they haven't had a session in a while, this modal will render */}
                <UnconnectedListingsModal />
            </>
        );
    }

    return (
        <>
            {isMobile() &&
            !(main[1].localeCompare('showingRequests') === 0) &&
            !(main[1].localeCompare('schedule') === 0) ? (
                <div className={styles.mobileErrorMessage}>
                    <p>Oops! It looks like you're using a mobile device!</p>
                    <p>Please proceed on our apps from your appropriate app store.</p>
                    <img src={fullWatermark} className={styles.image} />
                    <a href="https://open.agent.showingly.com/downloads">
                        <Button buttonText={'Download App'} className={styles.downloadButton} />
                    </a>
                </div>
            ) : !isMobile() && (loading || sessionStorage.getItem('isComingViaOIDC') === 'true') ? (
                <img
                    src="https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif"
                    className={styles.AppLogo}
                    alt="logo"
                />
            ) : !isMobile() && (authLinkExpiredOrInvalid || isUnauthenticated) ? (
                <p className={styles.linkError}>
                    The link has Expired or is Invalid, please try again or go to{' '}
                    <a href="https://agent.showingly.com/auth">agent.showingly.com</a>
                </p>
            ) : !isMobile() && isAppAuthenticated && !isUnauthenticated ? (
                <>
                    <AuthenticatedApp userPhoto={userPhotoUri} />
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            height: '100px',
                            width: '100px',
                            backgroundColor: 'blue',
                            zIndex: 9999999,
                        }}
                    ></div>
                </>
            ) : (
                <UnauthenticatedApp />
            )}
        </>
    );
}

export default App;
