import React, { useEffect, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import ConfigureListingModule from './common/ConfigureListingModule';
import indexTheme from '../../../../theme';
import Typography from '../../../../components/Typography';
import RadioButtons from './common/RadioButtons';
import ButtonComponent from '../../../../components/ButtonComponent';
import DropDown from '../../../../images/greyDropDownIcon.svg';
import { ClickableWithFeedback } from '../../../../components';
import { DatePicker } from '../../../../components/DatePicker';
import { DateTime } from 'luxon';
import AnimatedModal from '../../../../components/AnimatedModal';
import { useTransition } from 'react-spring';
import AddRestriction from './AddRestriction';
import RestrictionCard from './AddRestriction/RestrictionCard';

interface Props {
    agentListing: any;
    setAgentListing: any;
}
const PageFour = ({ agentListing, setAgentListing }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    const [selectedValue, setSelectedValue] = useState(
        agentListing?.showingsStartDate < dayAfterTomorrow
            ? agentListing?.showingsStartDate < tomorrow
                ? 'now'
                : 'tomorrow'
            : 'on',
    );
    const [isViewing, setIsViewing] = useState(false);

    const now = DateTime.fromJSDate(dayAfterTomorrow);
    const [selectedMoment, setSelectedMoment] = useState<any>(now);
    const [addRestrictionModal, setAddRestrictionModal] = useState(false);
    const [editingInfo, setEditingInfo] = useState<any>({});
    const [passedRestriction, setPassedRestriction] = useState<any>(null);

    useEffect(() => {
        if (selectedValue === 'on') {
            setAgentListing({ ...agentListing, showingsStartDate: new Date(selectedMoment.ts) });
        }
    }, [selectedMoment]);

    // handle month is incremented or decremented via the DatePicker
    const changeMonth = (incDec: 1 | -1) => {
        if (incDec === 1) {
            let newNow = selectedMoment.plus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        } else if (incDec === -1) {
            let newNow = selectedMoment.minus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        }
    };

    const showingsStartDateData = [
        {
            label: 'Now',
            value: 'now',
        },
        {
            label: 'Tomorrow',
            value: 'tomorrow',
        },
        {
            label: 'On',
            value: 'on',
            extraLabelContent: (
                <div style={{ marginLeft: 20 }}>
                    <div
                        onClick={() => {
                            if (selectedValue !== 'on') {
                                setSelectedValue('on');
                            }
                            setIsViewing(!isViewing);
                        }}
                        className={styleSheet.selectDateBox}
                    >
                        <Typography
                            textStyle="b1"
                            color={
                                selectedValue !== 'on'
                                    ? indexTheme.palette.lightGrey
                                    : indexTheme.palette.black
                            }
                        >
                            {selectedValue !== 'on' || !selectedMoment
                                ? 'Select a Date'
                                : selectedMoment.toFormat('DD')}
                        </Typography>
                        <ClickableWithFeedback
                            onClick={() => {
                                if (selectedValue !== 'on') {
                                    setSelectedValue('on');
                                }
                                setIsViewing(!isViewing);
                            }}
                        >
                            <img src={DropDown} />
                        </ClickableWithFeedback>
                    </div>
                    {isViewing ? (
                        <div className={styleSheet.datePickerContainer}>
                            <DatePicker
                                setSelectedDay={(date: any) => {
                                    setSelectedMoment(date);
                                    setIsViewing(false);
                                }}
                                selectedDay={selectedMoment}
                                changeMonth={(value: 1 | -1) => changeMonth(value)}
                                removePadding
                                alwaysSixRows
                            />
                        </div>
                    ) : null}
                </div>
            ),
        },
    ];

    return (
        <div>
            <ConfigureListingModule title={'Showings Start Date'}>
                <RadioButtons
                    data={showingsStartDateData}
                    selectedValue={selectedValue}
                    onChangeValue={(value: any) => {
                        setIsViewing(false);
                        setSelectedValue(value);
                        switch (value) {
                            case 'now':
                                return setAgentListing({
                                    ...agentListing,
                                    showingsStartDate: new Date(),
                                });
                            case 'tomorrow':
                                return setAgentListing({
                                    ...agentListing,
                                    showingsStartDate: tomorrow,
                                });
                            case 'on':
                                return setAgentListing({
                                    ...agentListing,
                                    showingsStartDate: dayAfterTomorrow,
                                });
                        }
                    }}
                />
            </ConfigureListingModule>
            <ConfigureListingModule title={'Allow double bookings?'}>
                <div style={{ marginTop: -4 }}>
                    <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                        Multiple agents will be able to schedule showings within overlapping time
                        slots.
                    </Typography>
                    <RadioButtons
                        data={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                        ]}
                        selectedValue={agentListing.allowOverlap}
                        onChangeValue={(value: boolean) => {
                            setAgentListing({ ...agentListing, allowOverlap: value });
                        }}
                    />
                </div>
            </ConfigureListingModule>
            <ConfigureListingModule title={'Listing Syndication'}>
                <div style={{ marginTop: -4 }}>
                    <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                        Display listing to unrepresented consumers.
                    </Typography>
                    <RadioButtons
                        data={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                        ]}
                        selectedValue={agentListing.showToUnrepresentedConsumers}
                        onChangeValue={(value: boolean) => {
                            setAgentListing({
                                ...agentListing,
                                showToUnrepresentedConsumers: value,
                            });
                        }}
                    />
                </div>
            </ConfigureListingModule>
            <div
                style={{
                    ...indexTheme.paddingVertical(30),
                    borderBottomColor: indexTheme.palette.grey,
                    borderBottomWidth: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                }}
            >
                <Typography textStyle={'p1'}>
                    Daily showing availability is from 7:00am to 9:00pm. Add additional restrictions
                    below.
                </Typography>
            </div>
            <ConfigureListingModule title={'Availability Restrictions'} optional>
                {agentListing.blockedTimes.map((restriction: any, index: number) => {
                    return (
                        <RestrictionCard
                            onClick={() => {
                                setPassedRestriction(restriction);
                                setEditingInfo({
                                    editing: true,
                                    index,
                                });
                                setAddRestrictionModal(true);
                            }}
                            restriction={restriction}
                        />
                    );
                })}
                <ButtonComponent
                    onClick={() => setAddRestrictionModal(true)}
                    color={indexTheme.palette.blue}
                    width={257}
                >
                    Add Restriction
                </ButtonComponent>
            </ConfigureListingModule>
            {addRestrictionModal && (
                <AddRestriction
                    set={setAddRestrictionModal}
                    modalVisible={addRestrictionModal}
                    onPressAdd={(restriction: any) => {
                        const blockedTimes = agentListing.blockedTimes;
                        if (editingInfo.editing) {
                            blockedTimes.splice(editingInfo.index, 1, restriction);
                        } else {
                            blockedTimes.push(restriction);
                        }
                        setEditingInfo({});
                        setPassedRestriction(null);
                        setAgentListing({
                            ...agentListing,
                            blockedTimes,
                        });
                    }}
                    passedRestriction={passedRestriction}
                />
            )}
        </div>
    );
};
export default PageFour;
