export default (theme: any) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 65,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.grey,
            borderStyle: 'solid',
            backgroundColor: theme.palette.white,
        },
        checkBox: {
            marginLeft: 15,
            paddingRight: 30,
        },
        labels: {
            display: 'flex',
            flex: 1,
            color: theme.palette.mediumGrey,
            fontSize: 15,
            fontFamily: 'Poppins',
            fontWeight: 600,
        },
        titles: {
            display: 'flex',
            flex: 1,
        },
        emailTitle: {
            display: 'flex',
            flex: 1.7,
        },
        phoneTitle: {
            display: 'flex',
            flex: 1.1,
        },
        ascending: {
            width: 14,
            transform: 'rotate(180deg)',
            marginLeft: 10,
            cursor: 'pointer',
        },
        descending: {
            marginLeft: 10,
            width: 14,
            cursor: 'pointer',
        },
    };
};
