import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import ConfigureListingModule from './common/ConfigureListingModule';
import RadioButtons from './common/RadioButtons';
import { noticeRequired, maxDuration } from './utils';
import { formatMinutes } from '../../../../utils/common/transforms';
interface Props {
    agentListing: any;
    setAgentListing: any;
}
const PageOne = ({ agentListing, setAgentListing }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const radioButtonData = [
        {
            label: 'Automatic Approval',
            description:
                'Showings will automatically be approved, used for properties that don’t require approval.',
            value: 'go_and_show',
        },
        {
            label: 'Appointment Required',
            description:
                'Showings will require approval from listing agent or seller before they are confirmed.',
            value: 'appt_required',
        },
        {
            label: 'Accompanied Showing',
            description:
                'Showings will require the listing agent to be present at the time of the showing.',
            value: 'accompanied',
        },
    ];
    const noticeOptions = noticeRequired.map((notice: number) => {
        return {
            label: formatMinutes(notice),
            value: notice,
        };
    });
    const maxOptions = maxDuration.map((duration: number) => {
        return {
            label: formatMinutes(duration),
            value: duration,
        };
    });

    return (
        <div className={styleSheet.root}>
            <ConfigureListingModule title={'Showing Type'}>
                <RadioButtons
                    data={radioButtonData}
                    selectedValue={agentListing.type}
                    onChangeValue={(value: string) =>
                        setAgentListing({ ...agentListing, type: value })
                    }
                />
            </ConfigureListingModule>
            <ConfigureListingModule title={'Notice Required'}>
                <select
                    onChange={(e) =>
                        setAgentListing({ ...agentListing, noticeRequired: Number(e.target.value) })
                    }
                    value={agentListing.noticeRequired}
                    className={styleSheet.selectBox}
                >
                    {noticeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </ConfigureListingModule>
            <ConfigureListingModule title={'Max Showing Duration'}>
                <select
                    onChange={(e) =>
                        setAgentListing({ ...agentListing, maxDuration: Number(e.target.value) })
                    }
                    value={agentListing.maxDuration}
                    className={styleSheet.selectBox}
                >
                    {maxOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </ConfigureListingModule>
        </div>
    );
};
export default PageOne;
