import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ButtonV4 } from 'web-lib';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import BackArrow from '../BackArrow';

import {
    getSubscribedPrice,
    getPrices,
    getProfileData,
    getSubscriptionEndDate,
} from '../../../../../domains/main/Profile/selectors';

import { setSubscriptionPrice, setPaymentModalType } from '../../actions';

import People from '../../../../../images/blueGroup.svg';
import CheckMark from '../../../../../images/checkmarkAwesome.svg';
import RedX from '../../../../../images/notIncluded.svg';

import styles from '../index.module.css';

import { noSubscriptions, subscriptions } from '../constants';

interface UpgradePageProps {}

const UpgradePage = ({}: UpgradePageProps) => {
    const [buttonSelected, setButtonSelected] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<'monthlyPrice' | 'annualPrice'>(
        'monthlyPrice',
    );
    const prices: any = useSelector(getPrices);

    const history = useHistory();
    const dispatch = useDispatch();

    const priceBanner = (subscription: string, color: string) => {
        return (
            <div
                className={classnames(styles.bannerCommon, styles.upgradeBanner)}
                style={{ backgroundColor: color }}
            >
                <p className={styles.pCommon}>{subscription}</p>
            </div>
        );
    };

    const iconAndText = (featureIncluded: boolean, text: string) => {
        return (
            <div className={styles.rowContainer}>
                <img className={styles.icon} src={featureIncluded ? CheckMark : RedX} />
                <p className={styles.pCommon} style={{ color: '#3a3a3a' }}>
                    {text}
                </p>
            </div>
        );
    };

    const priceFormatter = () => {
        const unitPrice = prices[selectedPlan]?.unit_amount;
        const displayPrice =
            selectedPlan === 'monthlyPrice' ? unitPrice / 12 / 100 : unitPrice / 100;
        return displayPrice;
    };

    const priceAbrev = () => {
        return selectedPlan === 'monthlyPrice' ? '/mo' : '/yr';
    };

    return (
        <div className={styles.root}>
            <div className={styles.topRow}>
                <BackArrow
                    onClick={() => {
                        history.push('/profile');
                        setButtonSelected(true);
                    }}
                    placeHolder={'Agent+'}
                />
                <ButtonV4
                    text={'Upgrade to Agent+'}
                    onClick={() => {
                        dispatch(setPaymentModalType('pay'));
                        dispatch(setSubscriptionPrice(prices[selectedPlan]?.id));
                        history.push('/profile/addCard');
                    }}
                    width={'250px'}
                    height={'30px'}
                    fontSize={'16px'}
                    color={'#2fd2a8'}
                    selected={buttonSelected}
                />
            </div>
            <div className={styles.features}>
                <div className={styles.columnLeft}>
                    <div className={styles.headerCommon}>
                        The ultimate tool for an agent’s daily business.
                    </div>
                    <h2>
                        Unlike any other product on the market. Connected directly to our consumer
                        brokerage products.
                    </h2>
                    <div className={styles.bubbleUpgrade}>
                        <img className={styles.bubbleUpgradeImage} src={People} />
                    </div>
                </div>
                <div className={styles.columnRight}>
                    <div className={styles.plansHeader}>
                        <p className={classnames(styles.pCommon, styles.plansText)}>Plans</p>
                        <div className={styles.monthlyAndAnnualContainer}>
                            <div
                                className={
                                    selectedPlan === 'monthlyPrice'
                                        ? styles.selectedPlan
                                        : styles.plan
                                }
                            >
                                <p
                                    className={styles.pCommon}
                                    style={{ margin: '0' }}
                                    onClick={() => setSelectedPlan('monthlyPrice')}
                                >
                                    Monthly
                                </p>
                            </div>
                            <div
                                className={
                                    selectedPlan === 'annualPrice'
                                        ? styles.selectedPlan
                                        : styles.plan
                                }
                            >
                                <p
                                    className={styles.pCommon}
                                    style={{
                                        margin: '0',
                                    }}
                                    onClick={() => setSelectedPlan('annualPrice')}
                                >
                                    Annually
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.featuresListContainer}>
                        <div className={styles.priceContainer}>
                            <span className={classnames(styles.pCommon, styles.outerPriceElement)}>
                                $<span className={styles.priceElement}>0</span>
                                {priceAbrev()}
                            </span>
                            {priceBanner('Basic', '#9a9a9a')}
                        </div>
                        {noSubscriptions.map(
                            (noSubscription: { included: boolean; text: string }) =>
                                iconAndText(noSubscription.included, noSubscription.text),
                        )}
                    </div>
                    <div className={styles.featuresListContainer}>
                        <div className={styles.priceContainer}>
                            <span className={classnames(styles.pCommon, styles.outerPriceElement)}>
                                $<span className={styles.priceElement}>{priceFormatter()}</span>
                                {priceAbrev()}
                            </span>
                            {priceBanner('Agent+', '#48A4FF')}
                        </div>
                        {subscriptions.map((subscription: { included: boolean; text: string }) =>
                            iconAndText(subscription.included, subscription.text),
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradePage;
