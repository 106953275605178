import { combineReducers } from 'redux';
import auth from '../domains/auth/reducer';
import calendar from '../domains/main/Calendar/reducer';
import dash from '../domains/main/Dashboard/reducer';
import myListings from '../domains/main/MyListings/reducer';
import myShowings from '../domains/main/MyShowings/reducer';
import listings from '../domains/main/Listings/reducer';
import profile from '../domains/main/Profile/reducer';
import searchListings from '../domains/main/SearchListings/reducer';
import queuedShowings from '../domains/main/QueuedShowings/reducer';
import social from '../domains/main/Social/reducer';
import brokerageHub from '../domains/main/Brokerage/reducer';
import map from '../domains/main/Map/reducer';
import listingDetails from '../domains/main/ListingDetailsScreen/reducer';
import showingDetails from '../domains/main/ShowingDetailsScreen/reducer';
import news from '../components/NewsSection/reducer';
import { notifications } from '../domains/main/Notifications/reducer';
import { showingRequests } from '../domains/main/ShowingRequests/reducer';
import crmState from '../domains/main/CRM/reducer';
import unverifiedState from '../domains/unverifiedMain/reducer';
import showingsState from '../domains/main/Showings/reducer';

// DEVELOPER NOTE: Please keep the reducers in alphabetical order.
export default combineReducers({
    auth,
    brokerageHub,
    calendar,
    crmState,
    dash,
    listingDetails,
    showingDetails,
    map,
    myListings,
    listings,
    myShowings,
    news,
    notifications,
    profile,
    queuedShowings,
    searchListings,
    social,
    showingRequests,
    showingsState,
    unverifiedState,
});
