import Table, { TableProps } from './Table';
import FilterBar from './FilterBar';
import { NewPaginator } from '../../../../components';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';
import React, { useState, useEffect } from 'react';
import { sortRows } from '../utils';
import { noop } from 'lodash';

interface TableWithFilterBarProps extends TableProps {
    labels: Array<string>;
    itemsPerPage: number;
    allChecked: boolean;
    setChecked: Function;
    setAllChecked: Function;
    filterBarStyle?: any;
}

const TableWithFilterBar = ({
    labels,
    rows,
    itemsPerPage,
    rowStyle,
    selectedRows,
    setChecked,
    showBlueTick,
    filterBarStyle,
    tableBackgroundColor,
}: TableWithFilterBarProps) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const [selectedCount, setSelectedCount] = useState(
        Array.from(selectedRows, ([x, y]) => ({ y })).filter((d) => d.y).length,
    );

    // Pagination
    const [paginatedData, setPaginatedData] = useState<Array<any>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    // Filtering
    const [isAscending, setIsAscending] = useState<boolean>(true);
    const [filter, setFilter] = useState<number>(0);
    const [filteredRows, setFilteredRows] = useState<Array<any>>([]);

    useEffect(() => {
        setFilteredRows(sortRows(filter, isAscending, rows));
    }, [filter, rows, isAscending, itemsPerPage]);
    useEffect(() => {
        if (filteredRows && filteredRows.length <= itemsPerPage) setPaginatedData(filteredRows);
    }, [filteredRows]);

    // useEffect(()=> {setCount? setCount(selectedCount): null},[selectedCount])

    return (
        <>
            <FilterBar
                allChecked={selectedCount === rows.length}
                setAllChecked={(x: any) => {
                    rows.map((d) => setChecked(new Map(selectedRows.set(d._id, x))));
                    setSelectedCount(x ? rows.length : 0);
                }}
                currentFilter={filter}
                setCurrentFilter={setFilter}
                ascending={isAscending}
                setAscending={setIsAscending}
                labels={labels}
                customStyle={filterBarStyle}
            />
            <Table
                rows={paginatedData}
                showBlueTick={showBlueTick}
                setChecked={(x: any, y: any) => {
                    setChecked(new Map(selectedRows.set(x, y)));
                    setSelectedCount(selectedCount + (y ? 1 : -1));
                }}
                rowStyle={rowStyle}
                selectedRows={selectedRows}
                tableBackgroundColor={tableBackgroundColor}
            />
            {rows.length > itemsPerPage && (
                <div className={styleSheet.paginator}>
                    <NewPaginator
                        data={filteredRows}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPaginatedData={setPaginatedData}
                    />
                </div>
            )}
        </>
    );
};

export default TableWithFilterBar;
