import { DateTime, Interval } from 'luxon';

/**
 * Separates to notifications to only inlcude those that
 * need an action to be taken (still in chronological order)
 *
 * @param {notifications} notifications array handed to the
 * notifications screen
 */

export function needsActionSort(notifications: any) {
    if (!notifications?.length) return [];

    var final = notifications.filter((e: any) => {
        if (e.type === 'showing' &&
        e.content?.status === 'pending_internal' || e.content?.status === 'requested'
       ) {
            // type showing request
            return e;
        } else if (
            e.type === 'showing' &&
            e.content?.status === 'completed' &&
            !e.content?.hasLeftFeedback
        ) {
            return e;
        }
    });
    return final?.length ? final : notifications;
}

/**
 * Sort by activity date. If the object is a showing, convert
 * the documents objectId (showingId) to a timestamp
 *
 * @param {notifications} notifications array from screen
 * @returns param array in different order
 */
export function notificationDateSort(notifications: any) {
    //
    if (!notifications?.length) return notifications;

    var final = [...notifications];

    final.sort((a: any, b: any) => {
        const dateA: Date = a.activityDate ? a.activityDate : a.showingId?.getTimestamp();
        const dateB: Date = b.activityDate ? b.activityDate : b.showingId?.getTimestamp();

        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
    });

    return final;
}
const formatDateType = (minutes: any) => {
    const hours = minutes / 60;

    if (hours >= 8760) {
        return 'years';
    } else if (hours >= 730) {
        return 'months';
    } else if (hours >= 24) {
        return 'days';
    } else if (hours >= 1) {
        return 'hours';
    } else {
        return 'minutes';
    }
};
export const getDateDifference = (connectionDate: any) => {
    const date = DateTime.fromJSDate(connectionDate);
    const curr = DateTime.local();
    const interval = Interval.fromDateTimes(date, curr);
    const minutes = interval.length('minutes');
    const formatType = formatDateType(minutes);

    return {
        duration: Math.floor(interval.length(formatType)),
        type: formatType === 'minutes' ? 'min' : formatType === 'months' ? 'mo' : formatType[0],
    };
};
