import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ButtonV4, Loader } from 'web-lib';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import BackArrow from '../BackArrow';

import { cancelSubscriptionRequested } from '../../actions';

import { getSubscription, getSubscriptionEndDate, getSettingsLoading } from '../../selectors';

import People from '../../../../../images/blueGroup.svg';

import styles from '../index.module.css';

import { bannersText } from '../constants';

interface DowngradePageProps {}

const DowngradePage = ({}: DowngradePageProps) => {
    const [buttonSelected, setButtonSelected] = useState<boolean>(false);
    const [confirmationCancel, setConfirmationCancel] = useState<boolean>(false);
    const subscription: any = useSelector(getSubscription);
    const endDate: any = useSelector(getSubscriptionEndDate);
    const loading: boolean = useSelector(getSettingsLoading);

    const history = useHistory();
    const dispatch = useDispatch();

    const bannerBasic = (displayText: string, descriptionTexts: string[]) => {
        return (
            <div className={styles.bannerContainer}>
                <div className={classnames(styles.bannerCommon, styles.bannerDowngrade)}>
                    <p className={styles.pCommon} style={{ color: '#ffffff' }}>
                        {displayText}
                    </p>
                </div>
                {descriptionTexts.map((descriptionText: string) => (
                    <p className={styles.pCommon}>{descriptionText}</p>
                ))}
            </div>
        );
    };

    const currentYear = () => {
        const currDate = new Date();
        return currDate.getFullYear();
    };

    return (
        <div className={styles.root}>
            {!confirmationCancel ? (
                <>
                    <div className={styles.topRow}>
                        <BackArrow
                            onClick={() => {
                                history.push('/profile');
                                setButtonSelected(true);
                            }}
                            placeHolder={'Downgrade'}
                        />
                        <ButtonV4
                            text={'Downgrade to Basic'}
                            onClick={() => {
                                setConfirmationCancel(true);
                                dispatch(cancelSubscriptionRequested(subscription?.id));
                            }}
                            width={'250px'}
                            height={'30px'}
                            fontSize={'16px'}
                            color={'#ff4343'}
                            selected={buttonSelected}
                        />
                    </div>
                    <div className={classnames(styles.headerCommon, styles.subHeader)}>
                        You’re about to lose access to the only tool you need for your business.
                    </div>
                    <div className={styles.features}>
                        <div className={styles.featuresList}>
                            <h2>Here’s everything you’ll lose:</h2>
                            <div className={styles.bannersContainer}>
                                {bannersText.map(
                                    (bannerText: { title: string; description: string[] }) =>
                                        bannerBasic(bannerText.title, bannerText.description),
                                )}
                            </div>
                        </div>
                        <div className={styles.bubble}>
                            <img className={styles.bubbleImage} src={People} />
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles.downgradeMessageContainer}>
                    <div className={styles.topRow}>
                        <BackArrow
                            onClick={() => history.push('/profile')}
                            placeHolder={'Downgraded'}
                        />
                    </div>
                    <span className={classnames(styles.pCommon, styles.endDateText)}>
                        Your plan will expire on:
                        {!loading ? (
                            <span className={styles.date}> {endDate + ', ' + currentYear()}</span>
                        ) : (
                            <div className={styles.loadingContainer}>
                                <Loader />
                            </div>
                        )}
                        We’re sorry to see you go.
                    </span>
                </div>
            )}
        </div>
    );
};

export default DowngradePage;
