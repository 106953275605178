import React, { useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, Loader, Typography } from '../../../../components';
import indexTheme from '../../../../theme';
import close from '../../../../images/close.svg';
import Tag from '../../../../components/Tag';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import { ShowingRequest } from '../constants';
import { DateTime } from 'luxon';
import {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
    getStatusInfo,
} from '../../../../utils/common';
import GrayPerson from '../../../../images/darkGreyPerson.svg';
import ButtonComponent from '../../../../components/ButtonComponent';
import RadioButtons from '../../Listings/ConfigureListing/common/RadioButtons';
import { useDispatch } from 'react-redux';

interface Props {
    set: Function;
    modalVisible: boolean;
    ref: any;
    showing: ShowingRequest;
    closeModal: Function;
    changeStatus: Function;
    callback: Function;
    loading: boolean;
    sendLockBoxDetails: boolean;
    setSendLockBoxDetails: Function;
}
const ListSideDetails = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const dispatch = useDispatch();

    const { showing, sendLockBoxDetails, setSendLockBoxDetails } = props;

    const showCancelButton = showing?.status === 'confirmed';

    const showApproveDeny =
        showing?.status === 'pending_internal' || showing?.status === 'pending_external';
    const radioButtonData = [
        {
            label: 'Send Lockbox Details Upon Approval',

            value: true,
        },
        {
            label: 'Do Not Send Lockbox Details',

            value: false,
        },
    ];

    return (
        <AnimatedModal style={{ top: null, margin: 'auto', width: 812 }} {...props}>
            <div className={styleSheet.header}>
                <Typography
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                    style={{ marginLeft: 15 }}
                >
                    {showing?.unverifiedUser ? 'Non-MLS Showing Request' : 'Showing Details'}
                </Typography>
                <img
                    className={styleSheet.closeButton}
                    src={close}
                    onClick={() => {
                        props.closeModal();
                        props.set(false);
                    }}
                />
            </div>
            <div className={styleSheet.childrenContainer}>
                <div className={styleSheet.addressHeader}>
                    <Typography textStyle={'h2'}>{showing?.listing?.address?.full}</Typography>
                    <Typography style={{ fontFamily: 'Poppins-medium' }} textStyle={'h2'}>
                        {showing?.listing?.address?.city}, {showing?.listing?.address?.state}{' '}
                        {showing?.listing?.address?.postalCode}
                    </Typography>
                    <Tag
                        style={{ marginTop: 10 }}
                        text={getStatusInfo(showing?.status).text}
                        color={getStatusInfo(showing?.status).color}
                    />
                </div>
                <div className={styleSheet.showingTimeDetails}>
                    <div className={styleSheet.iconAndText}>
                        <img style={{ marginRight: 5 }} height={20} width={20} src={GrayCal} />
                        <Typography textStyle={'h4'}>
                            {DateTime.fromJSDate(showing?.start).toFormat('DDDD')}
                        </Typography>
                    </div>
                    <div className={styleSheet.iconAndText}>
                        <img style={{ marginRight: 5 }} height={20} width={20} src={GrayClock} />
                        <Typography textStyle={'h4'}>
                            {DateTime.fromJSDate(showing?.start).toFormat('t')} -{' '}
                            {DateTime.fromJSDate(showing?.end).toFormat('t')}
                        </Typography>
                    </div>
                </div>
                <div style={{ justifyContent: 'center' }} className={styleSheet.detailsContainer}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 5,
                        }}
                    >
                        <img src={GrayPerson} style={{ marginRight: 10 }} />
                        <Typography textStyle={'h4'}>
                            {showing?.unverifiedUser
                                ? showing?.unverifiedUser?.firstName +
                                  ' ' +
                                  showing?.unverifiedUser?.lastName
                                : showing?.agent?.firstName + ' ' + showing?.agent?.lastName}
                        </Typography>
                    </div>
                    {showing?.unverifiedUser?.company || showing?.agent?.brokerages ? (
                        <Typography style={{ marginBottom: 5 }} textStyle={'b1'}>
                            {showing?.unverifiedUser
                                ? showing.unverifiedUser.company
                                : showing?.agent?.brokerages}
                        </Typography>
                    ) : null}
                    <Typography
                        style={{ marginBottom: 5 }}
                        color={indexTheme.palette.mediumGrey}
                        textStyle={'b1'}
                    >
                        {formatPhoneNumberForDisplay(
                            showing?.unverifiedUser
                                ? showing?.unverifiedUser?.phoneNumber
                                : showing?.agent?.phoneNumber,
                            true,
                        )}
                    </Typography>
                    <Typography
                        style={{ marginBottom: 5 }}
                        color={indexTheme.palette.mediumGrey}
                        textStyle={'b1'}
                    >
                        {formatEmailForDisplay(
                            showing?.unverifiedUser
                                ? showing?.unverifiedUser?.email
                                : showing?.agent?.email,
                        )}
                    </Typography>
                </div>
                {showing?.unverifiedUser && showing?.status === 'pending_internal' && (
                    <div className={styleSheet.radioButtonContainer}>
                        <RadioButtons
                            data={radioButtonData}
                            selectedValue={sendLockBoxDetails}
                            onChangeValue={(value: boolean) => {
                                setSendLockBoxDetails(value);
                            }}
                            style={{ display: 'flex', flex: 1, justifyContent: 'space-evenly' }}
                        />
                    </div>
                )}
                <div className={styleSheet.bottomButtonContainer}>
                    {props.loading ? (
                        <Loader />
                    ) : showCancelButton ? (
                        <ButtonComponent
                            onClick={() => {
                                props.changeStatus({
                                    id: showing?._id,
                                    status: 'cancelled',
                                    callback: () => {
                                        props.callback();
                                        props.closeModal();
                                        props.set(false);
                                    },
                                });
                            }}
                        >
                            Cancel Showing
                        </ButtonComponent>
                    ) : showApproveDeny ? (
                        <>
                            <ButtonComponent
                                className={styleSheet.approveDenyButton}
                                onClick={() => {
                                    props.set('Deny Showing');
                                }}
                            >
                                Deny
                            </ButtonComponent>
                            <ButtonComponent
                                fill
                                className={styleSheet.approveDenyButton}
                                onClick={() => {
                                    props.changeStatus({
                                        id: showing?._id,
                                        status: 'confirmed',
                                        isUnverified: !!showing?.unverifiedUser,
                                        ...(showing.unverifiedUser
                                            ? { sendLockInfo: sendLockBoxDetails }
                                            : null),
                                        callback: () => {
                                            props.callback();
                                            props.closeModal();
                                            props.set(false);
                                        },
                                    });
                                }}
                            >
                                Approve
                            </ButtonComponent>
                        </>
                    ) : null}
                </div>
            </div>
        </AnimatedModal>
    );
};
export default ListSideDetails;
