import * as Actions from './actions';

export const initialState: any = {
    fetchLoading: false,
    fetchedClients: [],
    errors: null,
    selectedClients: [],
    deletionLoading: false,
    deleteMessage: null,
    addClientsLoading: false,
    clientsAddedResponse: null,
};

export type CrmState = typeof initialState;

export default function (state: any = initialState, action: any) {
    switch (action.type) {
        case Actions.FETCH_CLIENTS_REQUESTED: {
            return {
                ...state,
                fetchLoading: true,
            };
        }
        case Actions.FETCH_CLIENTS_SUCCEEDED: {
            return {
                ...state,
                fetchedClients: action.fetchedClients,
                fetchLoading: false,
            };
        }
        case Actions.FETCH_CLIENTS_FAILED: {
            return {
                ...state,
                errors: action.errors,
                fetchLoading: false,
            };
        }
        case Actions.SET_SELECTED_CLIENTS_REQUESTED: {
            return {
                ...state,
                selectedClients: action.selectedClients,
            };
        }
        case Actions.DELETE_CLIENTS_REQUESTED: {
            return {
                ...state,
                deletionLoading: true,
            };
        }
        case Actions.DELETE_CLIENTS_SUCCEEDED: {
            return {
                ...state,
                deleteMessage: action.deleteMessage,
                deletionLoading: false,
            };
        }
        case Actions.DELETE_CLIENTS_FAILED: {
            return {
                ...state,
                deletionLoading: false,
                errors: action.errors,
            };
        }
        case Actions.RESET_RESPONSE_DATA_REQUESTED: {
            return { ...state, deleteMessage: null, errors: null, clientsAddedResponse: null };
        }

        case Actions.ADD_CLIENTS_REQUESTED: {
            return {
                ...state,
                addClientsLoading: true,
            };
        }
        case Actions.ADD_CLIENTS_SUCCEEDED: {
            return {
                ...state,
                addClientsLoading: false,
                clientsAddedResponse: action.payload,
            };
        }
        case Actions.ADD_CLIENTS_FAILED: {
            return {
                ...state,
                addClientsLoading: false,
                errors: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
