import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'web-lib';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { TERMS_URL, PRIVACY_URL } from '../../domains/auth/constants';
import stylesCSS from './index.module.css';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

interface SSOModalComponentProps {
    containerStyles?: string;
    sendAppDownloadLink: Function;
    acceptTermsAndService: Function;
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SSOModal(props: SSOModalComponentProps) {
    const { acceptTermsAndService } = props;

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [termsCheck, setTermsCheck] = useState(false);
    const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);

    const onClickHyperLink = (type: string) => {
        if (type === 'terms') {
            window.open(TERMS_URL, '_blank');
        } else {
            window.open(PRIVACY_URL, '_blank');
        }
    };

    const getIsButtonDisabled = () => {
        if (termsCheck === false || privacyPolicyCheck == false) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.blur}>
                <div className={styleSheet.contentContainer}>
                    <h3 className={stylesCSS.overlayTitle}>
                        We noticed it's your first time signing in.
                    </h3>
                    <p className={stylesCSS.overlayText}>
                        Please verify that you agree to our Terms of Service & Privacy Policy and
                        we’ll have you on your way to an improved real estate experience.
                    </p>
                    {/* Terms CheckBox */}
                    <div className={stylesCSS.checkBoxContainer}>
                        <div className={stylesCSS.container}>
                            <input
                                checked={termsCheck}
                                onChange={() => {
                                    setTermsCheck(!termsCheck);
                                }}
                                type="checkbox"
                                className={stylesCSS.check}
                            />
                            <span className={stylesCSS.checkmark}></span>
                        </div>

                        <span className={stylesCSS.checkBoxText}>
                            I agree to the{' '}
                            <span
                                onClick={() => onClickHyperLink('terms')}
                                className={stylesCSS.hyperLink}
                            >
                                Terms of Service
                            </span>{' '}
                        </span>
                    </div>
                    {/* Privacy CheckBox */}
                    <div className={stylesCSS.checkBoxContainer}>
                        <div className={stylesCSS.container}>
                            <input
                                checked={privacyPolicyCheck}
                                onChange={() => {
                                    setPrivacyPolicyCheck(!privacyPolicyCheck);
                                }}
                                type="checkbox"
                                className={stylesCSS.check}
                            />
                            <span className={stylesCSS.checkmark}></span>
                        </div>
                        <span className={stylesCSS.checkBoxText}>
                            I agree to the{' '}
                            <span
                                onClick={() => onClickHyperLink('privacy')}
                                className={stylesCSS.hyperLink}
                            >
                                Privacy Policy
                            </span>{' '}
                        </span>
                    </div>
                    {/* Continue Button */}
                    <div className={stylesCSS.btnWrapper}>
                        <Button
                            disabled={getIsButtonDisabled()}
                            className={
                                getIsButtonDisabled()
                                    ? stylesCSS.btnDisabled
                                    : stylesCSS.btnContinue
                            }
                            buttonText={'Continue'}
                            onClick={() => {
                                acceptTermsAndService([TERMS_URL, PRIVACY_URL]);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

SSOModal.defaultProps = {
    containerStyles: null,
    sendAppDownloadLink: () => {},
    acceptTermsAndService: () => {},
};

export default SSOModal;
