import React, { useEffect, useLayoutEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { ButtonV4 as Button } from 'web-lib';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    sendLinkedLicenseVerificationCodeRequested,
    verifyLinkedLicenseCodeRequested,
    linkAgentRequested,
    getMarketRequested,
    fetchAgentDocumentsRequested,
    resetLinkedLicenseState,
} from '../actions';
import {
    getLinkedAgentSuccess,
    codeVerificationSuccessful,
    getVerifyingCode,
    getMarkets,
    loadingMarkets,
    getAgents,
    loadingAgents,
    failedToVerifyCode,
    failedToSendCode,
} from '../selectors';
import { getAgentObject } from '../../../auth/selectors';
import { VerificationInputBar } from '../../../../components';

import styles from './index.module.css';
import BackArrow from '../../../../images/leftArrowBlack.svg';
import { getProfileData } from '../selectors';
import { SearchableDropdown } from 'web-lib';
import { BSON } from 'realm-web';

// determines if the modal should render SMS verification, user selection, etc.
enum ModalDisplayType {
    contactSupport = 'ContactSuppport',
    failedScreen = 'Failed Screen',
    selectVerification = 'SelectVerification',
    successScreen = 'SuccessScreen',
    verifyCode = 'VerifyCode',
    selectMarket = 'SelectMarket',
}

enum VerificationType {
    email = 'email',
    phone = 'phone',
}

type ToLinkUser = {
    market: string;
    agent: any;
    agentObjectId: BSON.ObjectId | null;
};

type LinkLicenseProps = {};

const LinkLicense = ({}: LinkLicenseProps) => {
    const linkedAgentSuccess: boolean | null = useSelector(getLinkedAgentSuccess);
    const verifyingCode: boolean = useSelector(getVerifyingCode);
    const verifiedCode: boolean = useSelector(codeVerificationSuccessful);
    const agentUser: any = useSelector(getAgentObject);
    const markets: any = useSelector(getMarkets);
    const marketsLoading: boolean = useSelector(loadingMarkets);
    const agents: any = useSelector(getAgents);
    const agentsLoading: boolean = useSelector(loadingAgents);
    const verifyFailure: string = useSelector(failedToVerifyCode);
    const sendFailure: string = useSelector(failedToSendCode);

    const dispatch = useDispatch();
    const sendVerificationCode: Function = (
        method: 'email' | 'phone',
        receiver: string,
        isResend: boolean,
    ) => dispatch(sendLinkedLicenseVerificationCodeRequested(method, receiver, isResend));
    const verifyCode: Function = (method: 'email' | 'phone', code: any, emailOrPhone?: string) =>
        dispatch(verifyLinkedLicenseCodeRequested(method, code, emailOrPhone));
    const linkAgent: Function = (linkingAgentObjId: BSON.ObjectId) =>
        dispatch(linkAgentRequested(linkingAgentObjId));
    const fetchAgents: Function = (searchText: string, marketName: string) =>
        dispatch(fetchAgentDocumentsRequested(searchText, marketName));
    const fetchMarkets: Function = () => dispatch(getMarketRequested());
    const resetLicenseFlow: Function = () => dispatch(resetLinkedLicenseState());

    const history = useHistory();
    const [modalDisplayType, setModalDisplayType] = useState<ModalDisplayType>(
        ModalDisplayType.selectMarket,
    );
    const [selectedVerification, setSelectedVerification] = useState<VerificationType>();
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [toLinkUser, setToLinkUser] = useState<ToLinkUser>({
        market: '',
        agent: null,
        agentObjectId: null,
    });
    const [didError, setDidError] = useState<string>('');
    const [receiver, setReceiver] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    // ensures that the background is gray on mount and changes back to white onUnmount
    useEffect(() => {
        document.body.style.backgroundColor = '#F2F2F2';
    }, []);

    useLayoutEffect(() => {
        return () => {
            document.body.style.backgroundColor = '#ffffff';
        };
    }, []);

    useEffect(() => {
        if (verifiedCode) {
            linkAgent(toLinkUser.agentObjectId);
            resetLicenseFlow();
        }
        if (linkedAgentSuccess !== null) {
            if (linkedAgentSuccess) {
                setModalDisplayType(ModalDisplayType.successScreen);
            } else {
                setModalDisplayType(ModalDisplayType.failedScreen);
            }
        }
    }, [linkedAgentSuccess, verifiedCode]);

    useEffect(() => {
        if (sendFailure) {
            setDidError(sendFailure);
            setButtonDisabled(false);
        } else if (verifyFailure) {
            setDidError(verifyFailure);
            setButtonDisabled(false);
        }
    }, [sendFailure, verifyFailure]);

    const selectedAgentArr = agents?.filter(
        (agent: any) =>
            agent.firstName +
                ' ' +
                agent.lastName +
                ' - ' +
                agent.markets[0].marketName +
                ' ' +
                agent.markets[0].agentMlsId ===
            toLinkUser.agent,
    );

    const selectedAgent = selectedAgentArr?.length > 0 ? selectedAgentArr[0] : null;

    const noVerificationMethods = !selectedAgent?.email && !selectedAgent?.phoneNumber;

    // triggers the SMS or email verification flow
    const onVerificationSelection = (type: VerificationType) => {
        // filter out the selected agent object before sending the verification code
        if (selectedAgentArr.length > 0) {
            const agentObjectId = new BSON.ObjectId(selectedAgent._id);
            setToLinkUser({
                ...toLinkUser,
                agentObjectId: agentObjectId,
            });
        } else if (selectedAgent.length === 0) {
            setDidError('The searched user does not exist, so cannot be linked.');
            resetLicenseFlow();
        }
        // Depending on the type fo verification selected, send either a phoneNumber or an email to the verification code function
        const receiver =
            type === VerificationType.phone ? selectedAgent.phoneNumber : selectedAgent.email;

        setReceiver(receiver);

        sendVerificationCode(type, receiver, false);
        setSelectedVerification(type);
        setModalDisplayType(ModalDisplayType.verifyCode);
    };

    /**
     * Determine if an item should be mapped in searchable dropdown
     * @param agent - the agent to be mapped in searchable dropdown
     * If the agent is the same as the currently logged in agent, then don't map them
     * If the agent is already linked with the currently logged in agent, then don't map them
     */
    const shouldMap = (agent: any) => {
        const agentName = agent.firstName + ' ' + agent?.lastName;
        const agentMarket = agent.markets[0].marketName + ' ' + agent.markets[0].agentMlsId;
        const nameAndMarket = agentName + ' - ' + agentMarket;
        // First let's get rid of the current signed in user, so they can't link with themselves
        if (agent._id.toString() !== agentUser._id.toString()) {
            let currentMarkets = agentUser.markets;
            // Second let's make sure that can't link again with some account they're already linked with
            const alreadyLinkedMarket = currentMarkets.filter(
                (market: any) => market.marketName + ' ' + market.agentMlsId === agentMarket,
            );
            if (alreadyLinkedMarket.length === 0) {
                return nameAndMarket;
            }
            return null;
        }
        return null;
    };

    // get data that goes inside of body, trying this to hopefully keep bloat out of the main return statement
    const getModalBody = (bodyType: ModalDisplayType) => {
        if (bodyType === ModalDisplayType.selectVerification) {
            return (
                <>
                    {noVerificationMethods ? (
                        <div className={styles.supportButton}>
                            <span className={styles.modalText} style={{ marginBottom: 30 }}>
                                Looks like you don't have any verification methods. Please contact
                                support to manually verify.
                            </span>
                            <Button
                                onClick={() => setModalDisplayType(ModalDisplayType.contactSupport)}
                                text={'Support'}
                            />
                        </div>
                    ) : (
                        <>
                            <span className={styles.modalHeader}>
                                Select your verification method
                            </span>

                            <div className={styles.buttonContainer}>
                                <Button
                                    onClick={() => onVerificationSelection(VerificationType.phone)}
                                    text={'SMS'}
                                    disabled={!selectedAgent?.phoneNumber}
                                />
                                <Button
                                    onClick={() => onVerificationSelection(VerificationType.email)}
                                    text={'Email'}
                                    disabled={!selectedAgent?.email}
                                />
                            </div>

                            <span className={styles.modalText}>
                                Verification information provided by the MLS.
                            </span>
                            <span className={styles.modalText}>
                                If your information is incomplete or has changed, please{' '}
                                <span
                                    className={styles.greenText}
                                    onClick={() =>
                                        setModalDisplayType(ModalDisplayType.contactSupport)
                                    }
                                >
                                    contact support
                                </span>{' '}
                                for manual verification.
                            </span>
                            <span className={styles.modalText}>
                                If you are using SMS verification, ensure that the number provided
                                to the MLS is a number that can receive texts.
                            </span>
                        </>
                    )}
                </>
            );
        } else if (bodyType === ModalDisplayType.verifyCode) {
            return (
                <div className={styles.codeInputContainer}>
                    <span className={styles.inputHeader}>Enter the verification code:</span>
                    <VerificationInputBar
                        loading={verifyingCode}
                        onComplete={(code: string) => setVerificationCode(code)}
                        style={styles.verificationBarStyling}
                    />
                    <span className={styles.noCodeText}>Didn't recieve a code?</span>
                    <span className={styles.bodyText}>Check your spam or junk folders.</span>
                    <span className={styles.bodyText}>
                        Make sure your cell phone or email is up to date in the MLS.
                    </span>
                    <Button
                        onClick={() => {
                            // If there is an error from entering the code incorrectly for example, then clear those errors once the code is verified again
                            if (didError) {
                                setDidError('');
                            }
                            verifyCode(selectedVerification, verificationCode, receiver);
                            setReceiver('');
                            setButtonDisabled(true);
                        }}
                        text={buttonDisabled ? 'Linking...' : 'Link'}
                        disabled={buttonDisabled}
                    />
                </div>
            );
        } else if (bodyType === ModalDisplayType.contactSupport) {
            return (
                <>
                    <span className={styles.inputHeader}>Support:</span>
                    <span>Contact support at (833) 217-7578</span>
                </>
            );
        } else if (bodyType === ModalDisplayType.successScreen) {
            return <span>License linked successfully.</span>;
        } else if (bodyType === ModalDisplayType.failedScreen) {
            return (
                <>
                    <span>Link unsuccessful</span>
                    <span className={styles.modalText}>
                        If this problem persists, please{' '}
                        <span
                            className={styles.greenText}
                            onClick={() => setModalDisplayType(ModalDisplayType.contactSupport)}
                        >
                            contact support
                        </span>{' '}
                        for manual verification.
                    </span>
                </>
            );
        } else if (bodyType === ModalDisplayType.selectMarket) {
            return (
                <div className={styles.selectMarketContainer}>
                    <div style={{ height: '120px' }} className={styles.elemContainer}>
                        <SearchableDropdown
                            id={'market'}
                            name={'market'}
                            type={'text'}
                            className={styles.searchDD}
                            inputStyle={styles.searchDDInput}
                            dropdownStyle={styles.dropdown}
                            optionStyle={styles.dropdownOptions}
                            placeholder={'Select Your Market'}
                            onChange={(event: any) => fetchMarkets()}
                            options={markets
                                ?.map((market: any) => market.name)
                                .sort((a: any, b: any) => (a > b ? 1 : -1))}
                            loading={marketsLoading}
                            setCurrentValue={(market: string) =>
                                setToLinkUser({
                                    ...toLinkUser,
                                    market: market,
                                })
                            }
                        />
                        <SearchableDropdown
                            id={'agent'}
                            name={'agent'}
                            type={'text'}
                            className={styles.searchDD}
                            inputStyle={styles.searchDDInput}
                            dropdownStyle={styles.dropdown}
                            optionStyle={styles.dropdownOptions}
                            placeholder={'Select Who You Are'}
                            onChange={(event: any) => {
                                const searchText = event?.target?.value ? event?.target?.value : '';
                                fetchAgents(searchText, toLinkUser.market);
                            }}
                            options={agents?.map((agent: any) => {
                                const shouldReturn = shouldMap(agent);
                                if (shouldReturn !== null) {
                                    return shouldReturn;
                                }
                            })}
                            loading={agentsLoading}
                            setCurrentValue={(agent: any) => {
                                setToLinkUser({
                                    ...toLinkUser,
                                    agent: agent,
                                });
                            }}
                            disableInput={isDisabledAgentInput()}
                        />
                    </div>
                    <div style={{ height: '90px' }} className={styles.elemContainer}>
                        <button
                            className={
                                isDisabledNext()
                                    ? classnames(styles.nextButton, styles.disabled)
                                    : classnames(styles.nextButton, styles.enabled)
                            }
                            onClick={() => setModalDisplayType(ModalDisplayType.selectVerification)}
                            disabled={isDisabledNext()}
                        >
                            Next
                        </button>
                        <p onClick={() => window.open('mailto:support@showingly.com')}>
                            Need help?
                        </p>
                    </div>
                </div>
            );
        }
    };

    // Check if the agent that's selected actually exists, before allow the user to select next
    const isDisabledNext = () => {
        if (toLinkUser.agent && agents && agents.length > 0) {
            for (let i: number = 0; i < agents.length; i++) {
                const agentName = agents[i].firstName + ' ' + agents[i].lastName;
                const agentMarket =
                    agents[i].markets[0].marketName + ' ' + agents[i].markets[0].agentMlsId;
                if (agentName + ' - ' + agentMarket === toLinkUser.agent) {
                    return false;
                }
            }
            return true;
        }
        return true;
    };

    // Check if the market that's selected actually exists, before allowing the user to search agents
    const isDisabledAgentInput = () => {
        if (markets && markets.length > 0 && toLinkUser.market !== '') {
            for (let i: number = 0; i < markets.length; i++) {
                if (markets[i].name === toLinkUser.market) {
                    return false;
                }
            }
            return true;
        }
        return true;
    };

    return (
        <div className={styles.root}>
            <div
                className={styles.back}
                onClick={() => {
                    resetLicenseFlow();
                    document.body.style.backgroundColor = 'white';
                    history.push('/profile');
                }}
            >
                <img className={styles.backArrow} src={BackArrow} />
                <span>Linked Licenses/Accounts</span>
            </div>

            <div className={styles.modal}>
                <div className={styles.modalBody}>{getModalBody(modalDisplayType)}</div>
                {didError !== '' && <span className={styles.errorMessage}>{didError}</span>}
            </div>
        </div>
    );
};

export default LinkLicense;
