import { Showing, ShowingStatus, ShowingType, STATUS } from '../../../utils/constants';

export enum SHOWING_ACTION {
    Create = '[ShowingRequest] Create',
    Reschedule = '[ShowingRequest] Reschedule',
    UpdateStatus = '[ShowingRequest] Update Status',
    SetShowingClient = '[ShowingRequest] Set Current Client',
    FetchShowingAvailability = '[ShowingRequest] Fetch Showing Availability',
    ClearShowingIds = '[ShowingRequest] Clear Showing Ids',
    FetchAgentAvailability = '[ShowingRequest] Fetch Agent Availability',
}

// #region Create
// The effects of all showing management actions are handled the same way by the reducer, using the
// showingId to reference the showing that is being affected.
//
// When the creation of a new showing is requested, a showingId does not exist. This creates a
// problem for our reducer, which needs an Id to set the loading flag.  If the operation fails, it
// is also tricky to know which showing the operation failed for.
//
// We resolve this by assigning the showingId 0 to newly created showings.  This value will never be
// used by a legitimately created showing.

export const createShowingRequested = (
    showingType: ShowingType,
    listingId: string,
    startTime: Date,
    endTime: Date,
    clientId: string | null = null,
    delegationPayment: any = null,
) => {
    return <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Requested,
        showingType,
        listingId,
        startTime,
        endTime,
        clientId,
        delegationPayment,
    };
};

export const createShowingSucceeded = (showing: Showing, listingId: string, showingType: string) =>
    <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Succeeded,
        listingId,
        showing,
        showingType,
    };

export const createShowingFailed = (listingId: string, error: string) =>
    <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Failed,
        listingId,
        error,
    };

type CreateShowing =
    | ReturnType<typeof createShowingRequested>
    | ReturnType<typeof createShowingSucceeded>
    | ReturnType<typeof createShowingFailed>;

// #endregion Create
// ----------------------------------------------------------------------------

// #region Reschedule
export const rescheduleShowingRequested = (
    showingType: ShowingType,
    showingId: string,
    startTime: Date,
    endTime: Date,
    clientId: string | null = null,
    previousShowingType: ShowingType | null,
    delegationPrice: number | null = null,
) => {
    return <const>{
        type: SHOWING_ACTION.Reschedule,
        status: STATUS.Requested,
        showingType,
        showingId,
        startTime,
        endTime,
        clientId,
        previousShowingType,
        delegationPrice,
    };
};

export const rescheduleShowingSucceeded = (
    showing: Showing,
    showingId: string,
    listingId: string,
    showingType: ShowingType | null,
) =>
    <const>{
        type: SHOWING_ACTION.Reschedule,
        status: STATUS.Succeeded,
        showingId,
        showing,
        listingId,
        showingType,
    };

export const rescheduleShowingFailed = (showingId: string, error: string) =>
    <const>{
        type: SHOWING_ACTION.Reschedule,
        status: STATUS.Failed,
        showingId,
        error,
    };

type RescheduleShowing =
    | ReturnType<typeof rescheduleShowingRequested>
    | ReturnType<typeof rescheduleShowingSucceeded>
    | ReturnType<typeof rescheduleShowingFailed>;

// #endregion Reschedule
// ----------------------------------------------------------------------------

// #region Update
export const updateShowingStatusRequested = (
    showingId: string,
    newShowingStatus: ShowingStatus,
    callback?: any,
) =>
    <const>{
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Requested,
        showingId,
        newShowingStatus,
        callback,
    };

export const updateShowingStatusSucceeded = (showing: Showing) =>
    <const>{
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Succeeded,
        showing,
    };

export const updateShowingStatusFailed = (showingId: string, error: string) =>
    <const>{
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Failed,
        showingId,
        error,
    };

type UpdateShowingStatus =
    | ReturnType<typeof updateShowingStatusRequested>
    | ReturnType<typeof updateShowingStatusSucceeded>
    | ReturnType<typeof updateShowingStatusFailed>;

// #endregion Update
// ----------------------------------------------------------------------------

// #region setSelectedClient
export const setSelectedClient = (client: any) =>
    <const>{
        type: SHOWING_ACTION.SetShowingClient,
        client,
    };

export type SetSelectedClient = ReturnType<typeof setSelectedClient>;
// #endregion setSelectedClient
// ----------------------------------------------------------------------------

// #region fetchShowingAvailability
export const fetchShowingAvailabilityRequested = (listingId: any, showingId?: any) =>
    <const>{
        type: SHOWING_ACTION.FetchShowingAvailability,
        status: STATUS.Requested,
        listingId,
        showingId,
    };

export const fetchShowingAvailabilitySucceeded = (unavailableDates: any) =>
    <const>{
        type: SHOWING_ACTION.FetchShowingAvailability,
        status: STATUS.Succeeded,
        unavailableDates,
    };

export const fetchShowingAvailabilityFailed = (error: string) =>
    <const>{
        type: SHOWING_ACTION.FetchShowingAvailability,
        status: STATUS.Failed,
        error,
    };

export type FetchShowingAvailability =
    | ReturnType<typeof fetchShowingAvailabilityRequested>
    | ReturnType<typeof fetchShowingAvailabilitySucceeded>
    | ReturnType<typeof fetchShowingAvailabilityFailed>;
// #endregion fetchShowingAvailability
// ----------------------------------------------------------------------------

// #region clearShowingIds
export const clearShowingIds = () => {
    return <const>{
        type: SHOWING_ACTION.ClearShowingIds,
    };
};

export type ClearTransactions = ReturnType<typeof clearShowingIds>;
// #endregion clearShowingIds
// ----------------------------------------------------------------------------

// #region fetchAgentAvailability
export const fetchAgentAvailabilityRequested = () =>
    <const>{
        type: SHOWING_ACTION.FetchAgentAvailability,
        status: STATUS.Requested,
    };

export const fetchAgentAvailabilitySucceeded = (agentSchedule: any) =>
    <const>{
        type: SHOWING_ACTION.FetchAgentAvailability,
        status: STATUS.Succeeded,
        agentSchedule,
    };

export const fetchAgentAvailabilityFailed = (error: string) =>
    <const>{
        type: SHOWING_ACTION.FetchAgentAvailability,
        status: STATUS.Failed,
        error,
    };

export type FetchAgentAvailability =
    | ReturnType<typeof fetchAgentAvailabilityRequested>
    | ReturnType<typeof fetchAgentAvailabilitySucceeded>
    | ReturnType<typeof fetchAgentAvailabilityFailed>;
// #endregion fetchAgentAvailability
// ----------------------------------------------------------------------------

export type ShowingAction =
    | CreateShowing
    | RescheduleShowing
    | UpdateShowingStatus
    | SetSelectedClient
    | FetchShowingAvailability
    | ClearTransactions
    | FetchAgentAvailability;
