export default (theme: any) => {
    const HEADER_PADDING = 48;
    const borderStyles = {
        borderBottom: { color: theme.palette.grey, style: 'solid', width: 1 },
    };
    
    return {
        flexColumn:{
            display: 'flex',
            flexDirection: 'column',
        },
        mainContainerTitle: {
            height: "fit-content",
            flexDirection: 'column',
            display: 'flex',
            backgroundColor: 'white',
            padding: '35px',
            paddingBottom: '0',
        },
        featuresContainerAlt:{
            paddingLeft: '20px',
        },
        featuresContainer:{
            marginTop: '10vh!important',
            paddingLeft: '20px',
        },
        mainContainer: {
            backgroundColor: theme.palette.white,
            height: '66vh',
            display: 'flex',
            padding: '20px',
            paddingBottom: '10px',
            overflowY: 'hidden',
            borderRadius: '5px',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },

        outterContainer: {
            backgroundColor: theme.palette.background,
            height: '80vh',
            padding: '25px',
            paddingBottom: '0',
            alignItems: 'center',
        },
       
         
        headerBar: {
            height: 140,
            // ...theme.commonBottomBorder,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: { left: 50, right: 23 },
            backgroundColor: theme.palette.blue,

        },
        pageHeader: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(23),
            ...theme.paddingHorizontal(HEADER_PADDING),
            ...borderStyles,
            display: 'flex',
            justifyContent: 'space-between',
        },
        closeIcon: {
            scale:1,
            height: 20,
            width: 20,
            position: 'absolute',
            top: "-100px",
            right: '-340px',
        },
        cardContainer: {
            backgroundColor: theme.palette.white,
            width: '30%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
            justifyContent: 'space-between',
        },
        cardContainerAlt: {
            overflowX: 'hidden',
            backgroundColor: theme.palette.blue,
            width: '70%',
            height: '100%',
            ...theme.cardShadow,
            border: {
                width: 0,
                radius: 5,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: 'hidden',
        },
        cardHeaderContainer: {
            height: 80,
            display: 'flex',
            flexDirection: 'column',
            margin: {
                top: 15,
                left: 20,
                right: 20,
                bottom: '2vh',
            },
            // ...theme.commonBottomBorder,
        },
        cardHeaderContainerAlt: {
            justifyContent: 'space-between',
            height: 80,
            display: 'flex',
            flexDirection: 'row',
            margin: {
                top: 15,
                left: 20,
                right: 20,
                bottom: '2vh',
            },
            // ...theme.commonBottomBorder,
        },
        itemsContainer: {
            overflowY: 'hide',
            height: '100%',
            // ...theme.commonBottomBorder,
        },
        itemRow: {
            margin: {
                top: 10,
                left: 20,
                right: 20,
            },
            display: 'flex',
            alignItems: 'center',
        },
        itemsContainerAlt: {
            
            height: '100%',
                display: 'grid',
                flexWrap: 'wrap',
                justifyItems: 'flex-start',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(2, 1fr)'
        },
        itemRowAlt: {
            margin: {
                top: 10,
                left: 20,
                right: 20,
            },
            width:"400px",
            display: 'flex',
            alignItems: 'center',
        },
        checkIcon: {
            height: 18,
            width: 18,
            marginRight: 10,
        },
        cardFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: "fit-content",  
            marginBottom:20,
        },
        noBorder: {
            borderWidth: 0,
            borderColor: theme.palette.white,
        },
    };
};
