export default (theme: any) => {
    return {
        modalBackground: {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalContainer: {
            width: '42%',
            height: '46%',
            borderRadius: '5px',
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            display: 'flex',
            flexDirection: 'column',
            padding: '25px',
            left: '554px',
            top: '290px'
        },
        icon: {
            left: '4.54%',
            right: '4.55%',
            top: '4.54%',
            bottom: '4.54%',
        },
        title: {
            display: 'inline-block',
            textAlign: 'center',
            marginTop: '10px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '26px',
            lineHeight: '120%',
        },
        subTitle: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '120%',
        },
        titleCloseBtn: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        body: {
            flexDirection: 'column',
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.7rem',
            textAlign: 'center'
        },
        footer: {
            flex: '20%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            width: '152px',
            height: '40px',
            margin: '10px',
            border: 'none',
            backgroundColor: '#48A4FF',
            color: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '120%',
        },
        divider: {
            width: '216px',
            backgroudColor: '#D3D3D3',
            borderRadius: '1px',
        },
        paragraph: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '150%',
            flexDirection: 'column',
        },
    };
};