import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import {
    getConfigurationDetails,
    getConnectionErrors,
    getConnectionSuccess,
    getConnectLoading,
    getListings,
    getLockboxImage,
} from '../selectors';
import { useHistory } from 'react-router-dom';
import {
    connectListingRequested,
    fetchSelectedListingRequested,
    resetValues,
    storeLockboxPhotoRequested,
    changeSelectedView,
} from '../actions';
import { getProfileData, getSubscriptionPlan } from '../../Profile/selectors';
import PageOne from './PageOne';
import Header from '../../../../components/Header';
import ButtonComponent from '../../../../components/ButtonComponent';
import PageTwo from './PageTwo';
import ConfigureListingModule from './common/ConfigureListingModule';
import { BSON } from 'realm-web';
import PageThree from './PageThree';
import Typography from '../../../../components/Typography';
import indexTheme from '../../../../theme';
import NextArrow from '../../../../images/nextArrow.svg';
import { animated, useSpring, useTransition } from 'react-spring';
import PageFour from './PageFour';
import PageFive from './PageFive';
import { AgentListingObject } from '../constants';
import { updateUpgradeModalVisible } from '../../Profile/actions';
import { useLocalStorage } from '../useLocalStorage';
import Loader from '../../../../components/Loader';
interface Props {}

const ConfigureListing = ({}: Props) => {
    const configurationDetails = useSelector(getConfigurationDetails);
    const listingAgent = useSelector(getProfileData);
    const connectionErrors = useSelector(getConnectionErrors);
    const connectListingSuccess = useSelector(getConnectionSuccess);
    const loading = useSelector(getConnectLoading);
    const lockboxImage = useSelector(getLockboxImage);
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);

    const { pageNumber, listing } = configurationDetails;

    const isEditing = !!listing?.agentListing;

    const history = useHistory();

    const objId = window.location.pathname.split('configure/')[1];

    const subPlan = useSelector(getSubscriptionPlan);
    const subTier = subPlan?.tier || 0;
    const listings = useSelector(getListings);
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );

    const checkConfigureDisabled = () => {
        let configuredListings: number = 0;
        let configuredListingsIDs: Array<Object> = [];
        listings?.map((listing: any) => {
            if (listing?.agentListing) {
                configuredListings++;
                configuredListingsIDs.push(listing?._id);
            }
        });
         // if the agent has a free trial, they can configure as many listings as they want
         if(agentHasFreeTrial) return false
        // if the User tier is above zero, they can configure as many listings as they want
        if (subTier > 0) return false;
        // Any user with no configured listings can configure one
        if (configuredListings == 0) return false;
        // If the user subTier is zero, and already have one configured listing, we make sure they are opening the right one.
        if (configuredListings == 1 && configuredListingsIDs[0] == listing?._id) return false;
        let alreadyConnected = false;
        listings?.every((e: any) => {
            if (JSON.stringify(e.agentListing?.listingId) === JSON.stringify(listing._id)) {
                alreadyConnected = true;
                return false;
            }
            return true;
        });
        if (alreadyConnected) return false;
        if (!subTier) return true;
    };

    useEffect(() => {
        if (checkConfigureDisabled()) {
            history.replace('/listings');
        }

        if (!listing?._id) {
            dispatch(fetchSelectedListingRequested(objId, true));
        }
    }, []);

    useEffect(() => {
        if (connectListingSuccess && isEditing) {
            dispatch(fetchSelectedListingRequested(objId));
            history.push(`/listings/${objId}`);
            window.localStorage.removeItem('configNumberPage');
            window.localStorage.removeItem('configDefaultAgentListing');
            dispatch(resetValues());
            window.scrollTo(0, 0);
        }
    }, [connectListingSuccess]);

    const defaultAgentListing = {
        agents: [
            {
                _id: listingAgent._id,
                canApprove: true,
                notificationSettings: ['text'],
                firstName: listingAgent.firstName,
                lastName: listingAgent.lastName,
                phoneNumber: listingAgent.phoneNumber,
                email: listingAgent.email || '',
                profilePhotoUpload: listingAgent.profilePhotoUpload,
                type: 'Listing Agent',
            },
        ],
        listingId: listing?._id,
        approvalSettings: {
            unverified: {
                // This will always be true
                allowed: true,
                hiddenId: Math.floor(Math.random() * 10000000000000000000).toString(),
            },
            approvalType: 'none',
        },
        noticeRequired: 0,
        maxDuration: 15,
        allowOverlap: false,
        type: 'go_and_show',
        lockboxLocation: '',
        lockboxType: 'Other',
        lockCombo: '',
        lockboxPhotoUpload: {
            as: '',
            uri: '',
            path: '',
        },
        consumers: [],
        showingsStartDate: new Date(),
        blockedTimes: [],
        acceptingShowings: true,
        showingInstructions: '',
        showToUnrepresentedConsumers: true,
    };

    const [page, setPage] = useLocalStorage('configNumberPage', pageNumber);
    const [agentListing, setAgentListing] = useLocalStorage(
        'configDefaultAgentListing',
        defaultAgentListing,
    );

    // Ensure the listing id is being put on the agentListing
    useEffect(() => {
        if (listing.agentListing) {
            setAgentListing({ ...listing.agentListing });
        } else {
            setAgentListing({
                ...agentListing,
                listingId: listing?._id,
            });
        }
    }, [listing?._id]);

    useEffect(() => {
        if (lockboxImage) {
            setAgentListing({
                ...agentListing,
                lockboxPhotoUpload: {
                    ...lockboxImage.image,
                },
            });
        }
    }, [lockboxImage]);

    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const getDisabled = () => {
        if (page === 2) {
            if (agentListing.lockboxType !== 'None') {
                if (agentListing.lockboxType === 'Other' && !agentListing.lockCombo) {
                    return true;
                }
                if (!agentListing.lockboxLocation) {
                    return true;
                }
            }
        }
        return false;
    };

    const transitions = useTransition(page, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
    });

    const BreadCrumbs = () => {
        return (
            <div className={styleSheet.breadCrumbs}>
                {['General Info', 'Lockbox', 'Users & Approval', 'Availability', 'Finish'].map(
                    (title, index) => {
                        return (
                            <div className={styleSheet.individualContainer}>
                                <Typography
                                    style={{ alignItems: 'center' }}
                                    textStyle={'h4'}
                                    color={
                                        page - 1 === index
                                            ? indexTheme.palette.blue
                                            : indexTheme.palette.mediumGrey
                                    }
                                >
                                    {title}
                                </Typography>
                                {index !== 4 && (
                                    <img
                                        style={{ ...indexTheme.marginHorizontal(26) }}
                                        src={NextArrow}
                                    />
                                )}
                            </div>
                        );
                    },
                )}
            </div>
        );
    };

    return transitions((style) => (
        <div className={styleSheet.root}>
            {load && (
                <div className={styleSheet.modalBackground}>
                    <Loader />
                </div>
            )}
            <Header
                title={'Configure Your Listing'}
                extraTitleContent={
                    <>
                        <Typography color={indexTheme.palette.mediumGrey} textStyle={'h4'}>
                            {listing?.address?.full}
                        </Typography>
                        <Typography
                            style={{ ...indexTheme.typography.medium, marginLeft: 5 }}
                            color={indexTheme.palette.mediumGrey}
                            textStyle={'h4'}
                        >
                            {listing?.address?.city}, {listing?.address?.state}{' '}
                            {listing?.address?.postalCode}
                        </Typography>
                    </>
                }
            />
            <BreadCrumbs />
            <div className={styleSheet.pageRoot}>
                <animated.div style={style} className={styleSheet.pageContainer}>
                    {page === 1 ? (
                        <PageOne agentListing={agentListing} setAgentListing={setAgentListing} />
                    ) : page === 2 ? (
                        <PageTwo
                            storeLockboxPhotoRequested={(file: any) =>
                                dispatch(storeLockboxPhotoRequested(file))
                            }
                            agentListing={agentListing}
                            setAgentListing={setAgentListing}
                        />
                    ) : page === 3 ? (
                        <PageThree agentListing={agentListing} setAgentListing={setAgentListing} />
                    ) : page === 4 ? (
                        <PageFour agentListing={agentListing} setAgentListing={setAgentListing} />
                    ) : page === 5 ? (
                        <PageFive
                            loading={loading}
                            errors={connectionErrors}
                            success={connectListingSuccess}
                        />
                    ) : null}
                </animated.div>

                {!isEditing ? (
                    <div
                        style={{
                            justifyContent: page > 1 && page < 5 ? 'space-between' : 'flex-end',
                        }}
                        className={styleSheet.buttonContainer}
                    >
                        {page > 1 && page < 5 ? (
                            <ButtonComponent
                                width={152}
                                onClick={() => {
                                    setPage(page - 1);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Back
                            </ButtonComponent>
                        ) : null}
                        <ButtonComponent
                            className={styleSheet.button}
                            fill
                            onClick={() => {
                                if (page < 5) {
                                    if (page === 4) {
                                        dispatch(connectListingRequested(agentListing));
                                    }
                                    setPage(page + 1);
                                    window.scrollTo(0, 0);
                                } else {
                                    dispatch(fetchSelectedListingRequested(objId));
                                    window.localStorage.removeItem('configNumberPage');
                                    window.localStorage.removeItem('configDefaultAgentListing');
                                    dispatch(resetValues());
                                    history.push(`/listings/${objId}`);
                                }
                            }}
                            width={152}
                            disabled={getDisabled()}
                        >
                            {page === 4 ? 'Finish' : page === 5 ? 'View Listing' : 'Next'}
                        </ButtonComponent>
                    </div>
                ) : (
                    <div
                        style={{ justifyContent: 'space-between' }}
                        className={styleSheet.buttonContainer}
                    >
                        <ButtonComponent
                            width={152}
                            onClick={() => {
                                history.push(`/listings/${objId}`);
                                window.scrollTo(0, 0);
                                dispatch(changeSelectedView('Configuration'));
                            }}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            className={styleSheet.button}
                            fill
                            onClick={() => {
                                setLoad(true);
                                dispatch(connectListingRequested(agentListing));
                            }}
                            width={152}
                            disabled={getDisabled()}
                        >
                            Save
                        </ButtonComponent>
                    </div>
                )}
            </div>
        </div>
    ));
};
export default ConfigureListing;
