import React, { useEffect, useState } from 'react';
import { Button } from 'web-lib';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import { BSON } from 'realm-web';

import styles from './index.module.css';
import HeartIcon from '../../../../../../images/feed/heartEmpty.svg';
import HeartFull from '../../../../../../images/feed/heartFull.svg';
import CommentIcon from '../../../../../../images/feed/comment.svg';
import defaultImage from '../../../../../../images/defaultUser.svg';
import { getOrdinalNum, minutesToTimeDisplay } from '../../../../../../utils/common/dateTimeUtils';

export type AgentSimple = {
    firstName: string;
    lastName: string;
    profilePhoto: string;
};

export type Upvote = {
    firstName: string;
    lastName: string;
    profilePhoto: string;
};

type FeedItemToDisplay = {
    creator?: {
        name: string;
        image: string;
        type?: string;
    };
    image?: string;
    link?: string;
    description?: string;
    title?: string;
    created?: Date;
    startTime?: Date;
    endTime?: Date;

    upvotesCount: number;
    upvotes: any;
    comments?: any[];
    key?: number;
};

const dateString = (startTime: Date) => {
    const month = DateTime.fromMillis(startTime?.getTime()).toFormat('MMM');
    const day = parseInt(DateTime.fromMillis(startTime?.getTime()).toFormat('d'));
    const year = DateTime.fromMillis(startTime?.getTime()).toFormat('y');
    const minutes = startTime.getMinutes();
    const time = DateTime.fromMillis(startTime?.getTime())
        .toFormat(minutes === 0 ? 'ha' : 'h:mma')
        .toLowerCase()
        .replace(/ /g, '');
    return `${month} ${getOrdinalNum(day)}, ${year} @ ${time}`;
};

export const CustomFeedCard = (feedItem: any) => {
    const {
        image,
        link,
        description,
        title,
        creator,
        created,
        upvotesCount,
        startTime,
        endTime,
        onUpvote = () => {},
        likedByUser,
        item,
        setSelectedItem,
    } = feedItem;

    return (
        <div className={styles.cardRoot}>
            {creator && (
                <div className={styles.authorContainer}>
                    <img
                        src={creator.image ? creator.image : defaultImage}
                        onError={(e: any) => (e.target.src = defaultImage)}
                        alt={'profilePic'}
                        className={styles.authorImage || defaultImage}
                    />
                    <div className={styles.authorInfoContainer}>
                        <div className={styles.row}>
                            <span className={styles.authorName}>{creator.name}</span>
                            <span className={styles.postedAt}>{minutesToTimeDisplay(created)}</span>
                        </div>
                        <span className={styles.authorTitle}>{creator.type}</span>
                    </div>
                </div>
            )}

            <div className={styles.feedBody}>
                <span className={styles.feedTitle}>{title}</span>

                {image && <img src={image} alt={'image'} className={styles.feedImage} />}

                {startTime?.getTime() !== endTime?.getTime() && (
                    <span className={styles.dateDisplay}>{dateString(startTime)}</span>
                )}

                {description && <span className={styles.descriptionText}>{description}</span>}

                {link && (
                    <Button
                        buttonText={'View Link'}
                        className={styles.linkButton}
                        onClick={() => window.open(link)}
                    />
                )}
                <div className={styles.row2}>
                    <div className={styles.icons}>
                        <div className={styles.iconContainer}>
                            <img
                                src={likedByUser ? HeartFull : HeartIcon}
                                alt={'heartIcon'}
                                className={styles.icon}
                                onClick={() => onUpvote()}
                            />
                            <span className={styles.iconText}>
                                {upvotesCount ? upvotesCount : 0}
                            </span>
                        </div>
                        <div
                            className={styles.iconContainer}
                            onClick={() => {
                                setSelectedItem(item);
                                window.scrollTo(0, 0);
                            }}
                        >
                            <img src={CommentIcon} alt={'commentIcon'} className={styles.icon} />
                            <span className={styles.iconText}>{item?.comments?.length || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
