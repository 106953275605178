import React from 'react';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import { Switch } from '..';
import styles from './styles';

const RadioOptionRow = ({
    title,
    checked,
    setChecked,
}: {
    title: string;
    checked: boolean;
    setChecked: Function;
}) => {
    const useStyles = createUseStyles(styles);
    const theme: DefaultTheme = useTheme();
    const styleSheet = useStyles({ theme });

    return (
        <div className={styleSheet.radioOptionRowContainer}>
            {/* Title */}
            <span>{title}</span>
            {/* Dots */}
            <div className={styleSheet.dots} />
            {/* Toggle */}
            <Switch checked={checked} onToggle={() => setChecked(title)} />
        </div>
    );
};

export default RadioOptionRow;
