import React, { useRef, useState } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedDrawer, AnimatedDrawerHeader, Typography } from '../../../../components';
import theme from '../../../../theme';
import RadioButtons from '../../../main/Listings/ConfigureListing/common/RadioButtons';
import { checkValidEmail, checkValidPhone, radioButtonData } from '../utils';
import ButtonComponent from '../../../../components/ButtonComponent';
import { animated, useSpring } from 'react-spring';
import { isEmpty } from 'ramda';
import { setUnverifiedUserData } from '../../actions';
import { useDispatch } from 'react-redux';
import { isMobile } from '../../../../utils/common';

interface Props {
    onClose: Function;
    visible: boolean;
    setShowScheduleDrawer: Function;
}

const UnverifiedUserDrawer = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const themeStyle = useTheme();
    const styleSheet = useStyles({ theme: themeStyle });
    const dispatch = useDispatch();
    const closeDrawerRef = useRef<any>(null);

    const [otherText, setOtherText] = useState<string>('');
    const [radioOption, setRadioOption] = useState<string>('Licensed Agent');
    const [userData, setUserData] = useState<any>({});

    const [invalidFields, setInvalidFields] = useState<any>({});

    // check that the user has filled in all necessary fields
    const disabledConditions = () => {
        // check the necessary fields exist
        var invalid: any = {};
        if (radioOption === 'Other' && !otherText?.length) {
            invalid['other'] = true;
        }
        // skip certain fields based on user type
        var skip: string[] = ['other'];
        if (['Unrepresented Homebuyer', 'Other'].includes(radioOption)) {
            skip.push('company', 'license');
        }
        Object.keys(inputOptions).map((e: any) => {
            if (!skip.includes(e) && !userData[e]?.length) {
                invalid[e] = true;
            }
        });
        // if (disabled) return false;

        // check if valid email
        if (!checkValidEmail(userData['email'])) {
            invalid['email'] = true;
        }
        if (!checkValidPhone(userData['phone'])) {
            invalid['phone'] = true;
        }

        setInvalidFields(invalid);
        if (isEmpty(invalid)) {
            dispatch(
                setUnverifiedUserData({
                    ...userData,
                    phoneNumber: '+1' + userData.phone,
                    occupationType: radioOption,
                    otherOccupation: otherText,
                }),
            );
            props.setShowScheduleDrawer(true);
        }
    };

    const inputOptions: any = {
        other: {
            placeholder: 'If other, please specify',
            onChange: (e: any) => setOtherText(e),
            onFocus: () => setRadioOption('Other'),
            style: { width: 223 },
        },
        firstName: {
            placeholder: 'First Name',
            onChange: (e: string) => setUserData({ ...userData, firstName: e }),
        },
        lastName: {
            placeholder: 'Last Name',
            onChange: (e: string) => setUserData({ ...userData, lastName: e }),
        },
        phone: {
            placeholder: 'Phone Number',
            onChange: (e: string) => setUserData({ ...userData, phone: e }),
        },
        email: {
            placeholder: 'Email',
            onChange: (e: string) => setUserData({ ...userData, email: e }),
        },
        company: {
            placeholder: 'Brokerage/Company Name',
            onChange: (e: string) => setUserData({ ...userData, company: e }),
        },
        license: {
            placeholder: 'License Number',
            onChange: (e: string) => setUserData({ ...userData, license: e }),
        },
    };

    const animatedStyleSheet: any = {
        bottomOptionVisible: useSpring({
            opacity: ['Unrepresented Homebuyer', 'Other'].includes(radioOption) ? 0 : 1,
        }),
    };

    const getInputOption = (type: string) => {
        var optStyle = inputOptions[type].style || {};
        if (invalidFields[type]) {
            optStyle = {
                ...optStyle,
                border: '1px solid red',
            };
        }
        return (
            <input
                className={styleSheet.searchBar}
                placeholder={inputOptions[type].placeholder}
                onChange={(e: any) => inputOptions[type].onChange(e.target.value)}
                onFocus={inputOptions[type].onFocus || null}
                style={optStyle}
            />
        );
    };

    const isMob = isMobile();

    const userTypeComponent = (
        <>
            <Typography textStyle="p1" color={theme.palette.mediumGrey} style={{ height: 20 }}>
                Who Are You?
            </Typography>
            <RadioButtons
                data={radioButtonData.map((e: any) =>
                    e.label ? e : { ...e, extraLabelContent: getInputOption('other') },
                )}
                selectedValue={radioOption}
                onChangeValue={(e: string) => setRadioOption(e)}
            />
            <div className={styleSheet.separatorBar} />
        </>
    );

    const AnimatedTypography = animated(Typography);

    const userMetaData = (
        <div className={styleSheet.container}>
            {/* Name */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Name
            </Typography>
            <div className={styleSheet.metaRow}>
                {getInputOption('firstName')}
                {getInputOption('lastName')}
            </div>
            {/* Contact */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Contact
            </Typography>
            <div className={styleSheet.metaRow}>
                {getInputOption('phone')}
                {getInputOption('email')}
            </div>
            {/* Company & License */}
            <AnimatedTypography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {'Company & License'}
            </AnimatedTypography>
            <animated.div
                className={styleSheet.metaRow}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {getInputOption('company')}
                {getInputOption('license')}
            </animated.div>
        </div>
    );

    const userMetaDataMobile = (
        <div className={styleSheet.container}>
            {/* Name */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Name
            </Typography>
            <div className={styleSheet.mb5}>
                {getInputOption('firstName')}
            </div>
            <div>
                {getInputOption('lastName')}
            </div>
            {/* Contact */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Contact
            </Typography>
            <div className={styleSheet.mb5}>
                {getInputOption('phone')}
            </div>
            <div>
                {getInputOption('email')}
            </div>
            {/* Company & License */}
            <AnimatedTypography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {'Company & License'}
            </AnimatedTypography>
            <animated.div
                style={animatedStyleSheet.bottomOptionVisible}
            >
                <div className={styleSheet.mb5}>
                    {getInputOption('company')}
                </div>
                <div>
                    {getInputOption('license')}
                </div>
            </animated.div>
        </div>
    );

    const buttonClassName = isMob ? styleSheet.scheduleButtonsContainerMobile : styleSheet.scheduleButtonsContainer;

    const bottomButton = (
        <div className={buttonClassName}>
            <ButtonComponent
                fill={true}
                onClick={() => {
                    disabledConditions();
                }}
            >
                <div>Next</div>
            </ButtonComponent>
        </div>
    );

    if (!props.visible) {
        return <></>;
    }

    const userMetaDataResponsive = isMob ? userMetaDataMobile : userMetaData;
    const userTypeClass = isMob ? styleSheet.userTypeMobile : styleSheet.userType;

    return (
        <AnimatedDrawer onClose={props.onClose} ref={closeDrawerRef}>
            <AnimatedDrawerHeader
                title={'Schedule a Showing'}
                onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
            />
            <div className={userTypeClass}>
                {userTypeComponent}
                {userMetaDataResponsive}
            </div>
            {bottomButton}
        </AnimatedDrawer>
    );
};
export default UnverifiedUserDrawer;
