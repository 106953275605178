import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import {
    AnimatedModal,
    ClickableWithFeedback,
    Loader,
    Typography,
} from '../../../../../components';
import CloseIcon from '../../../../../images/xBlack.svg';
import { NewClient } from '../constants';
import { formatPhoneNumberForDisplay } from '../../../../../utils/common';

interface AddClientRowProps {
    client: NewClient;
    index: number;
    removeRow: Function;
    updateClientData: Function;
}
const AddClientRow = ({ client, index, removeRow, updateClientData }: AddClientRowProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [newClientData, setNewClientData] = useState<NewClient>(client);

    useEffect(() => {
        updateClientData(newClientData, index);
    }, [newClientData]);

    return (
        <div className={styleSheet.rowRoot}>
            <div className={styleSheet.number}>{index + 1}</div>
            <input
                value={client.firstName}
                onChange={(e) => setNewClientData({ ...client, firstName: e.target.value })}
                placeholder="First Name"
                className={styleSheet.inputContainer}
            />
            <input
                value={client.lastName}
                onChange={(e) => setNewClientData({ ...client, lastName: e.target.value })}
                placeholder="Last Name"
                className={styleSheet.inputContainer}
            />
            <input
                value={client.phoneNumber}
                onChange={(e) => {
                    if (e.target.value.length <= 14) {
                        setNewClientData({
                            ...client,
                            phoneNumber: formatPhoneNumberForDisplay(e.target.value),
                        });
                    }
                }}
                placeholder="Phone Number"
                className={styleSheet.inputContainer}
                id="clientPhone"
            />
            <ClickableWithFeedback onClick={() => removeRow(index)}>
                <img src={CloseIcon} className={styleSheet.xIcon} />
            </ClickableWithFeedback>
        </div>
    );
};

export default AddClientRow;
