import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styles from './index.module.css';
import backArrow from '../../../../images/leftArrowBlack.svg';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

type InvoiceHistoryProps = {
    invoiceHistory: any;
};

const InvoiceHistory = ({ invoiceHistory }: InvoiceHistoryProps) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    // returns the th, nd, st, rd for a date
    // thanks stack overflow
    const getOrdinalNum = (n: number) =>
        n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');

    const Invoice = ({ invoice, index, length }: any) => {
        const date = invoice.created * 1000;
        const invoicePdf = invoice.invoice_pdf;
        const viewablePdf = invoice.hosted_invoice_url;
        return (
            <div
                className={styles.invoiceItem}
                style={
                    length !== index || length === 1 ? { borderBottom: '#c4c4c4 4px solid' } : {}
                }
            >
                <p className={styles.date}>
                    {DateTime.fromMillis(date).toFormat('MMMM')}{' '}
                    {getOrdinalNum(parseInt(DateTime.fromMillis(date).toFormat('d')))},{' '}
                    {DateTime.fromMillis(date).toFormat('y')}
                </p>
                <p
                    className={styles.view}
                    onClick={() => {
                        window.open(viewablePdf);
                    }}
                >
                    View
                </p>
                <a className={styles.download} href={invoicePdf}>
                    Download
                </a>
            </div>
        );
    };
    return (
        <>
            <div className={styles.root}>
                <div className={styles.back}>
                    <img
                        className={styles.backArrow}
                        src={backArrow}
                        onClick={() => {
                            history.push('/profile');
                        }}
                    />
                    <p>Invoice History</p>
                </div>
                <div className={styles.container}>
                    {invoiceHistory?.length ? (
                        invoiceHistory?.map((invoice: any, index: any) => (
                            <Invoice
                                invoice={invoice}
                                length={invoiceHistory.length}
                                index={index}
                            />
                        ))
                    ) : (
                        <p className={styles.noInvoice}>No Invoice History.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default InvoiceHistory;
