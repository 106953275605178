export default (theme: any) => {
    return {
        root: {
            backgroundColor: theme.palette.background,
            position: 'relative',
        },
        paginator: {
            bottom: 20,
            position: 'absolute',
            margin: 'auto',
            width: '100%',
        },
        viewMore: {
            color: theme.palette.blue,
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 600,
            cursor: 'pointer',
            textAlign: 'center'
        },
    };
};
