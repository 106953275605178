import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BSON } from 'realm-web';
import { Loader, PictureCarousel, Button } from 'web-lib';
import { Photo } from '../../../../components';

import * as SocialSelectors from '../selectors';
import {
    getIsSubscribed,
    loadingProfile as loadingProfileSelector,
    getUploadingPhoto,
    getProfilePhoto,
    getAgentId,
} from '../../Profile/selectors';
import {
    clearSelectedAgent,
    fetchAgentStatsRequested,
    fetchAgentSocialDocumentRequested,
    followAgentRequested,
    UpdateFeedData,
    updateFeedItemRequested,
    setSelectedAgent,
    fetchAgentFullRequested,
} from '../actions';
import {
    SelectedAgentSocial,
    Badge,
    SocialDocument,
    SocialItem,
    SocialUserSimple,
    AgentSimple,
} from '../../../../utils/constants';
import { updateProfilePhotoRequested } from '../../Profile/actions';
import { fetchFeedRequested } from '../actions';
import AgentStatsComponent from './AgentStats';
import AgentFeed from './AgentFeed';

import styles from './index.module.css';
import BlueLocationIcon from '../../../../images/feed/blueLocationIcon.svg';
import DefaultUser from '../../../../images/defaultUser.svg';
import GrayCamera from '../../../../images/feed/grayCamera.svg';
import LeftArrow from '../../../../images/leftArrowBlack.svg';
import PersonIcon from '../../../../images/feed/personIcon.svg';
import PrivateProfileLockIcon from '../../../../images/privateProfileLockIcon.svg';
import { setViewingRosterProfile } from '../../Brokerage/actions';
import { getViewingRosterProfile } from '../../Brokerage/selectors';
import { truncateString } from '../../../../utils/common';
import FollowerOrFollowingPanel from './FollowerOrFollowingPanel';

interface AgentProfileStats {}

const AgentProfile = ({}: AgentProfileStats) => {
    const agentId: BSON.ObjectId = useSelector(getAgentId);
    const allFeedItems: SocialItem[] = useSelector(SocialSelectors.getAllFeedItems);
    const selectedAgentsStats: any = useSelector(SocialSelectors.getSelectedAgentStats);
    const selectedAgentsMarkets: Array<string> = useSelector(
        SocialSelectors.getSelectedAgentMarkets,
    );
    const errors: Array<any> = useSelector(SocialSelectors.getSocialErrors);
    const fetchingSocialDocument: boolean = useSelector(SocialSelectors.getFetchingSocialDocument);
    const isSubscribed: boolean = useSelector(getIsSubscribed);
    const loadingProfile: boolean = useSelector(loadingProfileSelector);
    const loadingStats: boolean = useSelector(SocialSelectors.loadingSocialStats);
    const profilePhoto: string = useSelector(getProfilePhoto);
    const selectedAgent: SelectedAgentSocial = useSelector(SocialSelectors.getSelectedAgent);
    const socialDocuments: SocialDocument[] = useSelector(SocialSelectors.getSocialDocuments);
    const uploadingPhoto: boolean = useSelector(getUploadingPhoto);
    const agentFullDocument: any = useSelector(SocialSelectors.getFetchedAgentFullDocument);
    const isFetchingFullDocumentLoading: boolean = useSelector(
        SocialSelectors.getIsFetchingAgentFullDocument,
    );
    const isAgentPending: boolean = useSelector(SocialSelectors.getIsAgentPending);
    const isMyAgentPending: boolean = useSelector(SocialSelectors.getIsMyAgentPending);
    const isViewingRosterProfile: boolean = useSelector(getViewingRosterProfile);
    const isFollowAgentLoading: boolean = useSelector(SocialSelectors.getIsFollowAgentLoading);

    const dispatch = useDispatch();

    const resetViewingRosterProfile: Function = () => dispatch(setViewingRosterProfile(false));
    const getAgentSocialDocument: Function = (agentId: BSON.ObjectId) =>
        dispatch(fetchAgentSocialDocumentRequested(agentId));
    const fetchAgentStats: Function = (agentObjectId: BSON.ObjectId) =>
        dispatch(fetchAgentStatsRequested(agentObjectId));
    const fetchAgentFeedItems: Function = (
        isRefresh: boolean,
        agentId?: BSON.ObjectId,
        isOnlyAgent?: boolean,
    ) => dispatch(fetchFeedRequested(isRefresh, agentId, isOnlyAgent));
    const followAgent: Function = (
        interactionType: 'follow' | 'unfollow' | 'removeRequest' | 'confirmRequest' | 'denyRequest',
        agent: AgentSimple,
        isPrivateAccount = false,
    ) => dispatch(followAgentRequested(interactionType, agent, isPrivateAccount));
    const onImageUpload: Function = (image: any) => dispatch(updateProfilePhotoRequested(image));
    const setSelectedAgent: Function = (agent: SelectedAgentSocial) =>
        dispatch(setSelectedAgent(agent));
    const updateFeedItem: Function = (feedData: UpdateFeedData) =>
        dispatch(updateFeedItemRequested(feedData));
    const fetchAgentFull: Function = (
        agentId: BSON.ObjectId,
        viewingAgentId: BSON.ObjectId | string,
    ) => dispatch(fetchAgentFullRequested(agentId, viewingAgentId));

    const history = useHistory();
    const [profilePicToUpload, setProfilePicToUpload] = useState<{ display: boolean; image: any }>({
        display: false,
        image: '',
    });
    const [currentAgentDocument, setCurrentAgentDocument] = useState<SocialDocument>(); // social document for the agent that is currently stored inside redux social.selectedAgent
    const [isFollowing, setIsFollowing] = useState<boolean>(false); // indicates if the selectedAgent is being followed by the current user
    const [isCurrentUser, setIsCurrentUser] = useState<boolean>(
        agentId.toString() === selectedAgent?.agentId?.toString(),
    );

    const [showFollowers, setShowFollowers] = useState<boolean>(false);
    const [showFollowing, setShowFollowing] = useState<boolean>(false);

    const CHAR_LIMIT_AGENT_NAME = 20;
    const CHAR_LIMIT_BROKERAGE_NAME = 15;

    useEffect(() => {
        setAgentBrokerageName(
            truncateString(
                currentAgentDocument?.brokerageAndMarketData?.brokerage?.name || '',
                CHAR_LIMIT_BROKERAGE_NAME,
            ),
        );
    }, [currentAgentDocument]);

    //The name of the current agent
    const [currentAgentName, setCurrentAgentName] = useState<string>(
        truncateString(selectedAgent?.name || '', CHAR_LIMIT_AGENT_NAME),
    );
    const [agentBrokerageName, setAgentBrokerageName] = useState<string>(
        truncateString(
            currentAgentDocument?.brokerageAndMarketData?.brokerage?.name || '',
            CHAR_LIMIT_BROKERAGE_NAME,
        ),
    );

    const getAgentDocument = () =>
        socialDocuments.find(
            (doc: SocialDocument) =>
                doc?.agentObjectId?.toString() === selectedAgent?.agentId?.toString(),
        );

    // load user's social document and stats on page render
    useEffect(() => {
        // on load, find the current agent's socialDocument to display their data
        // if we don't have it yet, fetch their document

        const agentDocument = getAgentDocument();
        if (agentDocument) {
            setCurrentAgentDocument(agentDocument);
        } else {
            getAgentSocialDocument(selectedAgent?.agentId);
        }
        // Fetch the selected agent's full document for private module

        fetchAgentFull(selectedAgent?.agentId, agentId);
    }, [selectedAgent]);

    // fetch the agent document when the user clicks on a new agent
    useEffect(() => {
        setIsCurrentUser(agentId.toString() === selectedAgent?.agentId?.toString());

        setCurrentAgentName(selectedAgent?.name || ''); //Necessary because name doesnt change immediately.

        const agentDocument = getAgentDocument();
        if (agentDocument) {
            setCurrentAgentDocument(agentDocument);
        } else {
            getAgentSocialDocument(selectedAgent?.agentId);
        }
    }, [selectedAgent]);

    // if we hit the else case in the 2 useEffects above and had to fetch the users document, this is were we double check for that agent social document
    useEffect(() => {
        const agentDocument = getAgentDocument();
        if (agentDocument) {
            setCurrentAgentDocument(agentDocument);
        }
    }, [socialDocuments]);

    // when the social document change, reload the document to figure out if the current user is following the selectedAgent
    useEffect(() => {
        // returns true if the user is following the selectedAgent,
        // returns false if not
        const following = currentAgentDocument?.followers.find(
            (follower: SocialUserSimple) =>
                follower.agentObjectId.toString() === agentId.toString(),
        );
        setIsFollowing(!!following);

        // fetch the agent stats and metrics on load
        if (currentAgentDocument) {
            fetchAgentStats(currentAgentDocument.agentObjectId);
            // fetchAgentMetrics(
            //     selectedAgent?.agentId,
            //     currentAgentDocument.brokerageAndMarketData?.market?.mlsName,
            // );
        }
    }, [currentAgentDocument]);

    useEffect(() => {}, []);

    // function useClearImage(ref: any) {
    //     useEffect(() => {
    //         if (!profilePicToUpload && ref.current) {
    //             ref.current.value = '';
    //         }
    //     }, [ref, profilePicToUpload]);
    // }
    // const imageRef = useRef(null);
    // useClearImage(imageRef);

    // render the photo the user is attempting to upload, or just show their current photo, or the default
    const getPhotoToRender = () => {
        if (profilePicToUpload.image) {
            return URL.createObjectURL(profilePicToUpload.image);
        } else {
            return selectedAgent?.photoUri ? selectedAgent?.photoUri : DefaultUser;
        }
    };

    // takes in a number like 1400 into 1.4k
    const getKNumberDisplay = (num: number) => {
        if (num < 1000) {
            return num;
        }

        const n = num?.toString();
        return `${n.charAt(0)}.${n.charAt(1)}k`;
    };

    return (
        <div className={styles.root}>
            {!isCurrentUser &&
                !isFollowing &&
                agentFullDocument?.settings?.social?.profileSettings?.privateAccount && (
                    <div className={styles.privateAccountOverlay}>
                        <div className={styles.privateContentContainer}>
                            <div className={styles.innerContainer}>
                                <img src={PrivateProfileLockIcon} />
                                <h3 className={styles.overlayTitle}>This account is private.</h3>
                                <p className={styles.overlayText}>
                                    Follow to see their activity, badges and stats.
                                </p>
                            </div>
                        </div>
                    </div>
                )}

            <div className={styles.profileDetailsContainer}>
                <img
                    src={LeftArrow}
                    alt={'goBack'}
                    className={styles.backButton}
                    onClick={() => {
                        dispatch(clearSelectedAgent());
                        if (isViewingRosterProfile) {
                            resetViewingRosterProfile();
                            history.push('/brokerageHub/brokerageRoster');
                        } else {
                            history.push('/social');
                        }
                    }}
                />

                {/* Agent profile picture */}
                <Photo photoURI={getPhotoToRender()} containerStyles={styles.agentImage} />
                {/* Agent name */}
                <span
                    onMouseOver={(e: any) => {
                        setCurrentAgentName(selectedAgent?.name || '');
                    }}
                    onMouseLeave={(e: any) => {
                        setCurrentAgentName(
                            truncateString(selectedAgent?.name || '', CHAR_LIMIT_AGENT_NAME),
                        );
                    }}
                    className={styles.agentNameText}
                >
                    {currentAgentName}
                </span>
                {/* Agent's brokerage name */}
                <span
                    className={styles.brokerageNameText}
                    onMouseOver={(e: any) => {
                        setAgentBrokerageName(
                            currentAgentDocument?.brokerageAndMarketData?.brokerage?.name || '',
                        );
                    }}
                    onMouseLeave={(e: any) => {
                        setAgentBrokerageName(
                            truncateString(
                                currentAgentDocument?.brokerageAndMarketData?.brokerage?.name || '',
                                CHAR_LIMIT_BROKERAGE_NAME,
                            ),
                        );
                    }}
                >
                    {agentBrokerageName}
                </span>
                {/* Agent count for agent's brokerage */}
                <div className={styles.brokerageAgentCountContainer}>
                    {currentAgentDocument?.brokerageAndMarketData?.brokerage &&
                        currentAgentDocument?.brokerageAndMarketData?.brokerage?.agentCount && (
                            <>
                                <span className={styles.brokerageCountText}>
                                    {currentAgentDocument?.brokerageAndMarketData?.brokerage
                                        ?.agentCount || ''}
                                </span>
                                <img
                                    src={PersonIcon}
                                    alt={'person'}
                                    className={styles.personIcon}
                                />
                            </>
                        )}
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.columnContainer}>
                    {/* Container to show that the agent in view has requested to follow the current agent */}
                    {/* This is placed here temporarily until the new profile designs are implemented */}
                    {isMyAgentPending && (
                        <div className={styles.isMyAgentPendingContainer}>
                            <span
                                className={styles.isMyAgentPendingText}
                            >{`${selectedAgent?.firstName} ${selectedAgent?.lastName} has requested to follow you.`}</span>
                            <div className={styles.pendingRequestCardConfirmDenyContainer}>
                                <Button
                                    className={styles.buttonConfirm}
                                    buttonText="Confirm"
                                    onClick={() => {
                                        setTimeout(
                                            !isFollowAgentLoading &&
                                                followAgent('confirmRequest', {
                                                    agentId: selectedAgent?.agentId,
                                                }),
                                            2000,
                                        );
                                    }}
                                />

                                <Button
                                    className={styles.buttonDeny}
                                    buttonText="Deny"
                                    onClick={() => {
                                        followAgent('denyRequest', {
                                            agentId: selectedAgent?.agentId,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <span className={styles.earnedBadgesHeader}>Activity</span>
                    <AgentFeed
                        agentId={agentId}
                        allFeedItems={allFeedItems}
                        fetchFeedItems={fetchAgentFeedItems}
                        selectedAgent={selectedAgent}
                        setSelectedAgent={setSelectedAgent}
                        updateFeedItem={updateFeedItem}
                        feedStyling={styles.agentFeed}
                    />
                </div>

                <div className={styles.badgesAndStatsColumn}>
                    <div className={styles.columnContainer}>
                        <span className={styles.earnedBadgesHeader}>Badges</span>
                        <div className={styles.dividerLine} />
                        <PictureCarousel
                            cards={currentAgentDocument?.badges?.map(
                                (badge: Badge, index: number) => (
                                    <img
                                        key={index}
                                        className={styles.badgeSize}
                                        src={badge.image}
                                    />
                                ),
                            )}
                            displaySize={4}
                            overlayImage={true}
                            percentageWidth={100}
                        />
                    </div>
                    <div className={styles.columnContainer}>
                        <span className={styles.earnedBadgesHeader}>Stats</span>
                        <div className={styles.dividerLine} />
                        <AgentStatsComponent
                            isSubscribed={isSubscribed}
                            selectedAgentsStats={selectedAgentsStats}
                            selectedAgentsMarkets={selectedAgentsMarkets || []}
                            selectedAgent={selectedAgent}
                            loadingProfile={loadingProfile}
                            loadingStats={loadingStats}
                            isCurrentUser={isCurrentUser}
                        />
                    </div>
                </div>
            </div>

            {showFollowers && (
                <FollowerOrFollowingPanel
                    followers={currentAgentDocument?.followers || []}
                    onExit={() => setShowFollowers(false)}
                />
            )}
            {showFollowing && (
                <FollowerOrFollowingPanel
                    following={currentAgentDocument?.following || []}
                    onExit={() => setShowFollowing(false)}
                />
            )}

            {/* <div className={styles.agentInfo}> */}
            {/* {isCurrentUser && (
                    <div style={{ position: 'relative' }}>
                        <label className={styles.imageLabel} htmlFor="fileInput"></label>
                        <img src={GrayCamera} alt={'camra'} className={styles.cameraIcon} />
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                e.target.files?.length &&
                                    setProfilePicToUpload({
                                        display: true,
                                        image: e?.target?.files[0],
                                    });
                            }}
                            ref={imageRef}
                        />
                    </div>
                )} */}

            {/* {profilePicToUpload.display && (
                    <div className={styles.uploadContainer}>
                        {!uploadingPhoto ? (
                            <div className={styles.uploadImageText}>
                                <span
                                    className={styles.saveText}
                                    onClick={() => {
                                        profilePicToUpload &&
                                            onImageUpload(profilePicToUpload.image);
                                    }}
                                >
                                    Save
                                </span>
                                <span
                                    className={styles.cancelText}
                                    onClick={() =>
                                        setProfilePicToUpload({ display: false, image: '' })
                                    }
                                >
                                    Cancel
                                </span>
                            </div>
                        ) : (
                            <div className={styles.uploadPhotoContainer}>
                                <Loader />
                            </div>
                        )}
                    </div>
                )} */}

            {fetchingSocialDocument || isFetchingFullDocumentLoading ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div>
                        {/* Bren says bios are not architected yet */}
                        {/* {selectedAgent?.bio && (
                        <div className={classnames(styles.col, styles.bioContainer)}>
                            <span className={styles.bioText}>Bio</span>
                            <span className={styles.bio}>{selectedAgent?.bio}</span>
                            {selectedAgent?.isCurrentUser && (
                                <span className={styles.editText}>Edit</span>
                            )}
                        </div>
                    )} */}
                    </div>
                </div>
            )}
            {/* </div> */}
        </div>
    );
};

export default AgentProfile;
