import { createSelector } from 'reselect';
import { CrmState as stateType } from './reducer';

const CrmState = (state: stateType) => state.crmState || {};

export const getSelectedClients = createSelector(CrmState, (state: any) => state.selectedClients);

export const getSearchedClients = createSelector(CrmState, (state: any) => ({
    fetchedClients: state.fetchedClients,
    loading: state.fetchLoading,
}));
export const getDeletedClients = createSelector(CrmState, (state: any) => ({
    message: state.deleteMessage,
    errors: state.errors,
    loading: state.deletionLoading,
}));

export const getCRMErrors = createSelector(CrmState, (state: any) => state.errors);
export const getAddedClients = createSelector(CrmState, (state: any) => ({
    clientsAddedResponse: state.clientsAddedResponse,
    loading: state.addClientsLoading,
}));
