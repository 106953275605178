export default (theme: any) => {
    const labelText = {
        fontSize: 15,
        width: '100%',
        whiteSpace: 'nowrap',
        fontWeight: 600,
    };
    return {
        radioOptionRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 6,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            '& span': {
                ...labelText,
                width: 'auto',
            },
        },
        dots: {
            height: '1%',
            width: '100%',
            ...theme.marginHorizontal(10),
            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 3,
                style: 'dotted',
            },
        },
    };
};
