import { createSelector } from 'reselect';

const getUnverifiedState = (state: any) => state.unverifiedState || {};

export const getFetchedUnverifiedListing = createSelector(getUnverifiedState, (state: any) => ({
    listing: state.fetchListing,
    loading: state.fetchListingLoading,
}));

export const getUnverifiedUserData = createSelector(
    getUnverifiedState,
    (state: any) => state.unverifiedUser,
);

export const getUnverifiedShowingLoading = createSelector(
    getUnverifiedState,
    (state: any) => state.scheduleUnverifiedLoading,
);

export const getCreatedUnverifiedShowing = createSelector(
    getUnverifiedState,
    (state: any) => state.unverifiedShowing,
);

export const getFetchedUnverifiedConfirmationShowing = createSelector(
    getUnverifiedState,
    (state: any) => ({
        loading: state.fetchUnverifiedShowingConfirmationLoading,
        showing: state.unverifiedConfirmationShowing,
        listing: state.unverifiedConfirmationShowingListing,
    }),
);

export const getUpdateUnverifiedShowingLoading = createSelector(
    getUnverifiedState,
    (state: any) => state.updateUnverifiedShowingLoading,
);
