import React, { useEffect, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import indexTheme from '../../theme';
import { DateTime } from 'luxon';
import Typography from '../Typography';
import ClickableWithFeedback from '../ClickableWithFeedback';
import { DatePicker } from '../DatePicker';
import DropDown from '../../images/greyDropDownIcon.svg';

interface Props {
    disabled?: boolean;
    changeDate: Function;
    selectedDate: Date;
    sixRows?: boolean;
    onInitialPress?: Function;
}
const DropDownCal = ({
    disabled = false,
    changeDate,
    selectedDate,
    sixRows = false,
    onInitialPress = () => {},
}: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const [isOpen, setOpen] = useState(false);

    const now = DateTime.fromJSDate(selectedDate);
    const [selectedMoment, setSelectedMoment] = useState<any>(now);

    const changeMonth = (incDec: 1 | -1) => {
        if (incDec === 1) {
            let newNow = selectedMoment.plus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        } else if (incDec === -1) {
            let newNow = selectedMoment.minus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        }
    };

    return (
        <div style={{ marginLeft: 20 }}>
            <div
                onClick={() => {
                    onInitialPress();
                    setOpen(!isOpen);
                }}
                className={styleSheet.selectDateBox}
            >
                <Typography
                    textStyle="b1"
                    color={disabled ? indexTheme.palette.lightGrey : indexTheme.palette.black}
                >
                    {disabled || !selectedMoment ? 'Select a Date' : selectedMoment.toFormat('DD')}
                </Typography>
                <ClickableWithFeedback
                    onClick={() => {
                        onInitialPress();
                        setOpen(!isOpen);
                    }}
                >
                    <img src={DropDown} style={{ zIndex: -1 }} />
                </ClickableWithFeedback>
            </div>
            {isOpen ? (
                <div className={styleSheet.datePickerContainer}>
                    <DatePicker
                        setSelectedDay={(date: any) => {
                            setSelectedMoment(date);
                            setOpen(false);
                            changeDate(date);
                        }}
                        selectedDay={selectedMoment}
                        changeMonth={(value: 1 | -1) => changeMonth(value)}
                        removePadding
                        alwaysSixRows={sixRows}
                    />
                </div>
            ) : null}
        </div>
    );
};
export default DropDownCal;
