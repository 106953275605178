export default (theme: any) => {
    return {
        topRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        searchBar: (props: any) => ({
            height: 35,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            width: props?.width ?? 238,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
            borderRadius: 5,
            borderBottomRightRadius: props?.searching ? 0 : 5,
            borderBottomLeftRadius: props?.searching ? 0 : 5,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        dropdownIcon: {
            height: 24,
            width: 24,
        },
        menu: (props: any) => ({
            backgroundColor: theme.palette.white,
            width: props?.width ?? 536,
            position: 'absolute',
            left: 0,
            borderRadius: 5,
            zIndex: 999,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            // borderTop: `1px solid ${theme.palette.grey}`,
            maxHeight: 160,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                background: '#e9e9e9',
                width: 16,
            },
            '&::-webkit-scrollbar-track': {
                background: '#e9e9e9',
                border: '2px solid #e9e9e9',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#d3d3d3',
                borderRadius: 4,
                border: '2px solid #e9e9e9',
            },
            scrollbarColor: '#d3d3d3 #e9e9e9',
            ...theme.cardShadow,
        }),
        menuItem: (props: any) => ({
            alignItems: 'center',
            height: 30,
            paddingTop: 5,
            paddingLeft: 5,
            marginRight: 9,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        inputText: {
            fontSize: 18,
            ...theme.typography.medium,
            color: theme.palette.black,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            padding: 0,
        },
    };
};
