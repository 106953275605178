import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import styles from './styles';
import indexTheme from '../../../theme';
import { useSelector, useDispatch } from 'react-redux';
import _, { noop } from 'lodash';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router';
import Typography from './Typography';
import ClickableWithFeedback from '../../../components/ClickableWithFeedback';
import WhiteCloseIcon from '../../../images/xWhite.svg';
import CheckIcon from '../../../images/blueCheck.svg';
import greenCheckIcon from '../../../images/greenCheck.svg';
import { plansExtended as plans } from '../../../components/UpgradeSubscriptionModal/utils';
import {
    getPrices,
    getSubscription,
    getSubscriptionTier,
} from '../../../domains/main/Profile/selectors';
import Tag from '../../../components/Tag';
import ButtonComponent from '../../../components/ButtonComponent';
import { findIndex, propEq } from 'ramda';
import PaymentModal from '../../../components/PaymentModal';

const ReadMore = (props: {}) => {
    const prices: any = useSelector(getPrices) || {};
    const currentPlanTier = useSelector(getSubscriptionTier);
    const subscription = useSelector(getSubscription);
    const theme: any = useTheme();
    const useStyles = createUseStyles(styles);
    const styleSheet = useStyles({ theme });

    const formattedPlans = [plans[0]];
    const planType = currentPlanTier;
    const [downgradeVisible, setDowngradeVisible] = useState<any>(undefined);
    const [paymentVisible, setPaymentVisible] = useState<any>(null);
    const currentSubId = subscription?.id;

    let planPrices = Object.keys(prices);
    planPrices.shift();
    planPrices.map((planPrice: any) => {
        if (prices[planPrice]) {
            const price = prices[planPrice];
            const {
                id,
                unit_amount,
                nickname,
                recurring: { interval },
            } = price;
            const planIndex = findIndex(propEq('title', nickname.split(' ').reverse()[0]), plans);
            const plan: any = plans[planIndex];
            plan.id = id;
            formattedPlans.push(plan);
        }
    });
    const headerBar = (
        <div className={styleSheet.pageHeader}>
            <Typography textStyle={'h4'} color={indexTheme.palette.mediumGrey}>
                Plans & Pricings
            </Typography>
        </div>
    );

    const mainContainerTitle = (
        <div className={styleSheet.mainContainerTitle}>
            <Typography textStyle={'h2'} color={indexTheme.palette.blue}>
                Simple, transparent pricing
            </Typography>
            <Typography textStyle={'b3'} color={indexTheme.palette.lightGrey}>
                Get new features with a simple way of payment
            </Typography>
        </div>
    );
    const mainContainerBody = (
        <div className={styleSheet.mainContainer}>
            {formattedPlans.map((e: any, index: number) => (
                <div
                    className={
                        e.title == 'Free' ? styleSheet.cardContainer : styleSheet.cardContainerAlt
                    }
                >
                    <div
                        className={
                            e.title == 'Free'
                                ? styleSheet.cardHeaderContainer
                                : styleSheet.cardHeaderContainerAlt
                        }
                    >
                        <div className={styleSheet.flexColumn}>
                            <Typography
                                textStyle="h2"
                                color={e.title == 'Free' ? theme.palette.blue : theme.palette.white}
                            >
                                {e.planName}
                            </Typography>
                            <Typography
                                style={{ marginBottom: '10px' }}
                                textStyle="b2"
                                color={
                                    e.title == 'Free'
                                        ? theme.palette.lightGrey
                                        : theme.palette.white
                                }
                            >
                                {e.description}
                            </Typography>
                        </div>
                        <div className={styleSheet.flexColumn}>
                            <Typography
                                textStyle="h1"
                                color={
                                    e.title == 'Free' ? theme.palette.black : theme.palette.white
                                }
                            >
                                {e.title == 'Free' ? e.title : e.price}
                            </Typography>
                            <Typography
                                textStyle="b1"
                                color={
                                    e.title == 'Free'
                                        ? theme.palette.lightGrey
                                        : theme.palette.white
                                }
                                style={{ ...theme.typography.medium }}
                            >
                                {e.title == 'Free' ? e.price : '/monthly'}
                            </Typography>
                        </div>
                    </div>
                    <Typography
                        className={
                            e.title == 'Free'
                                ? styleSheet.featuresContainer
                                : styleSheet.featuresContainerAlt
                        }
                        textStyle="h2"
                        color={e.title == 'Free' ? theme.palette.black : theme.palette.white}
                    >
                        Features
                    </Typography>
                    <div
                        className={
                            e.title == 'Free'
                                ? styleSheet.itemsContainer
                                : styleSheet.itemsContainerAlt
                        }
                    >
                        {e.plan.map((f: any) => {
                            return (
                                <div
                                    className={
                                        e.title == 'Free'
                                            ? styleSheet.itemRow
                                            : styleSheet.itemRowAlt
                                    }
                                >
                                    <img
                                        src={e.title == 'Free' ? CheckIcon : greenCheckIcon}
                                        className={styleSheet.checkIcon}
                                    />
                                    <Typography
                                        textStyle="b1"
                                        color={
                                            e.title == 'Free'
                                                ? theme.palette.mediumGrey
                                                : theme.palette.white
                                        }
                                    >
                                        {f}
                                    </Typography>
                                </div>
                            );
                        })}
                        {e.title != 'Free' ? (
                            <div style={{ marginLeft: 48 }} className={styleSheet.itemRow}></div>
                        ) : (
                            ''
                        )}
                    </div>
                    {subscription?.cancelDate && planType === index && (
                        <Tag
                            text={'Cancels on ' + subscription.cancelDateString}
                            color={'red'}
                            style={{
                                borderRadius: 'unset',
                                borderColor: '#E9E9E9',
                                borderStyle: 'solid',
                                borderWidth: 0,
                                borderBottomWidth: 1,
                            }}
                        />
                    )}
                    <div className={styleSheet.cardFooterContainer}>
                        {!subscription?.permanent &&
                            (planType === index ? (
                                <ButtonComponent
                                    fill
                                    width={257}
                                    editStyle={{
                                        color: theme.palette.lightGrey,
                                        backgroundColor: theme.palette.white,
                                        height: '50px',
                                        fontFamily: 'Poppins',
                                        borderColor: theme.palette.lightGrey,
                                        borderWidth: 2,
                                        disabled: true,
                                        noop: true,
                                    }}
                                >
                                    Current Plan
                                </ButtonComponent>
                            ) : planType > index ? (
                                <ButtonComponent
                                    fill={true}
                                    width={257}
                                    onClick={() => setDowngradeVisible(e.id || null)}
                                    editStyle={{
                                        borderWidth: 0,
                                    }}
                                    disabled={!!subscription?.cancelDate}
                                >
                                    Downgrade
                                </ButtonComponent>
                            ) : (
                                <ButtonComponent
                                    fill
                                    width={257}
                                    onClick={() => setPaymentVisible(e.id)}
                                    editStyle={{
                                        backgroundColor: theme.palette.green,
                                        height: '50px',
                                        fontFamily: 'Poppins',
                                    }}
                                >
                                    Upgrade
                                </ButtonComponent>
                            ))}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div>
            {paymentVisible ? (
                <PaymentModal goBack={() => setPaymentVisible(null)} planId={paymentVisible} />
            ) : null}
            {headerBar}
            <div className={styleSheet.outterContainer}>
                {mainContainerTitle}
                {mainContainerBody}
            </div>
        </div>
    );
};
export default ReadMore;
