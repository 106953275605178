import { createSelector } from 'reselect';

export const getSocialState = (state: any) => state.social || {};

export const getSocialFeedLoading = createSelector(getSocialState, (socialState) => {
    const { feedLoading = true } = socialState;
    return feedLoading;
});

export const getSocialFeed = createSelector(getSocialState, (socialState) => {
    const { feedItems = [] } = socialState;
    return feedItems;
});

export const getSocialFeedPageNumber = createSelector(getSocialState, (socialState) => {
    const { socialFeedPageNumber = 0 } = socialState;
    return socialFeedPageNumber;
});

export const getSelectedAgent = createSelector(
    getSocialState,
    (socialState) => socialState.selectedAgent,
);

export const getSelectedAgentMetrics = createSelector(
    getSocialState,
    (socialState) => socialState.selectedAgentMetrics,
);

export const getSelectedAgentStats = createSelector(
    getSocialState,
    (socialState) => socialState.selectedAgentStats,
);

export const getSelectedAgentMarkets = createSelector(
    getSocialState,
    (socialState) => socialState.selectedAgentMarkets,
);

export const getSocialErrors = createSelector(getSocialState, (socialState) => socialState.errors);

export const loadingSocialStats = createSelector(
    getSocialState,
    (socialState) => socialState.loadingStats,
);

export const loadingSocialMetrics = createSelector(
    getSocialState,
    (socialState) => socialState.loadingMetrics,
);

export const getSocialDocuments = createSelector(
    getSocialState,
    (socialState) => socialState.socialDocuments,
);

export const getAllFeedItems = createSelector(
    getSocialState,
    (socialState) => socialState.feedItemsByAgentId,
);

export const getFetchingSocialDocument = createSelector(
    getSocialState,
    (socialState) => socialState.fetchingSocialDocument,
);

export const getFetchedAgentFullDocument = createSelector(
    getSocialState,
    (socialState) => socialState.agentFullDocument,
);

export const getIsFetchingAgentFullDocument = createSelector(
    getSocialState,
    (socialState) => socialState.loading,
);

export const getIsAgentPending = createSelector(
    getSocialState,
    (socialState) => socialState.isAgentPending,
);

export const getIsMyAgentPending = createSelector(
    getSocialState,
    (socialState) => socialState.isMyAgentPending,
);

export const getSearchedAgents = createSelector(
    getSocialState,
    (socialState) => socialState.searchedAgents,
);

export const getCurrentAgentFullSocialDocument = createSelector(
    getSocialState,
    (socialState) => socialState.myAgentFullDocument,
);

export const getIsFollowAgentLoading = createSelector(
    getSocialState,
    (socialState) => socialState.followingAgentLoading,
);
