import { BSON } from 'realm-web';

export const PAGINATION_LIMIT = 10;

export type FetchShowingsParams = {
    option?: string;
    filterOption?: string;
    page?: number;
    filterId?: string;
};

export type SubmitFeedbackParams = {
    priceAccuracyRating: number;
    locationRating: number;
    interestLevel: number;
    reviewText: string;
    showingId: any;
};

export type ShowingsType = {
    buySide: SideType;
    listSide: SideType;
};

export type SideType = {
    All: ShowingGroupByDate[];
    'Needs Action': ShowingGroupByDate[];
    Upcoming: ShowingGroupByDate[];
};

export type ShowingGroupByDate = {
    date: Date;
    showingRequests: ShowingRequest[];
    _id: Date;
};

export type ShowingRequest = {
    agent: any;
    agentId: BSON.ObjectId;
    approvals?: string[];
    consumer: any;
    created: Date;
    creatorType: string;
    creatorUserId: string;
    dateAdjusted?: Date;
    denialReason?: any;
    end: Date;
    feedback?: any;
    listing: any;
    listingId: BSON.ObjectId;
    pricing: any;
    start: Date;
    status: string;
    type: string;
    updated?: Date;
    utcOffset: number;
    unverifiedUser?: any;
    _id: BSON.ObjectId;
};

export type ChangeShowingRequestParams = {
    id: BSON.ObjectId;
    status: 'confirmed' | 'denied' | 'cancelled';
    denialReason?: string;
    sendLockInfo?: boolean;
    isUnverified?: boolean;
    callback?: Function;
};

export type ShowingsPage = {
    page: number;
    showings: ShowingRequest[];
};
