import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { AnyIfEmpty, connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { SlideOutDrawer } from 'web-lib';
import ClickableWithFeedback from '../ClickableWithFeedback';
import {
    getCancelling,
    getPaymentMethods,
    getStripeActionStatus,
    getSubscription,
    getSubscriptionEndDate,
    isPaymentLoading,
} from '../../domains/main/Profile/selectors';
import { AnimatedModal, Typography } from '..';
import XButton from '../../images/greyBoxXButton.svg';
import ButtonComponent from '../ButtonComponent';
import PaymentDropDownSelect from '../PaymentDropDownSelect';
import {
    addCardAndSubscribeRequested,
    cancelSubscriptionRequested,
    downgradeRequested,
    subscribeRequested,
} from '../../domains/main/Profile/actions';
import { Loader } from 'web-lib';
import FailureIcon from '../../images/lightFailure.svg';
import SuccessIcon from '../../images/lightSuccess.svg';

interface Props {
    goBack: Function;
    planId: any;
    currentSubId: any;
}

const DowngradeModal = ({ goBack, planId, currentSubId }: Props) => {
    const cancelling = useSelector(getCancelling);
    const endDate = useSelector(getSubscriptionEndDate);
    const dispatch = useDispatch();
    const downgradeCompletely = () => dispatch(cancelSubscriptionRequested(currentSubId));
    const downgrade = () => dispatch(downgradeRequested(currentSubId, planId));
    const upgrade = (paymentMethodId: string, priceId: string) =>
        dispatch(subscribeRequested(paymentMethodId, priceId));

    useEffect(() => {
        if (endDate) goBack();
    }, [endDate]);

    // cannot be in styles file bc passed as style, not className
    const animatedModalStyle = {
        maxWidth: 812,
        maxHeight: 600,
        minHeight: 450,
        minWidth: 609,
        height: '80vh',
        width: '80vw',
        top: null,
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
    };

    const ref: any = useRef(null);
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const [downgradeClicked, setDowngradeClicked] = useState<boolean>(false);
    const loadingIconSource =
        'https://showingly-image-assets.s3.amazonaws.com/showinglyGeneralLoader.gif';
    return (
        <AnimatedModal
            set={() => {}}
            modalVisible={true}
            backgroundEventDisabled={true}
            style={animatedModalStyle}
            ref={ref}
        >
            <div className={styleSheet.header}>
                <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                    Downgrade Plan
                </Typography>
                <img src={XButton} className={styleSheet.xButton} onClick={() => goBack()} />
            </div>
            {
                <>
                    <div
                        style={{
                            justifyContent: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            height: 460,
                            paddingLeft: 90,
                            paddingRight: 90,
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'flex-start',
                                height: 170,
                            }}
                        >
                            {cancelling ? (
                                <img src={loadingIconSource} style={{ height: 50 }} />
                            ) : (
                                <>
                                    <Typography textStyle="h3" color={'black'}>
                                        Are you sure you want to downgrade your plan?
                                    </Typography>
                                    <Typography textStyle="b1" color={'black'}>
                                        We would love it if you stayed, but if you must go we
                                        understand.
                                    </Typography>
                                    <Typography textStyle="b1" color={'black'}>
                                        You will still have access to the benefits of your plan
                                        until the end of this billing cycle.
                                    </Typography>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styleSheet.footer}>
                        <ButtonComponent
                            fill
                            color={theme.palette.blue}
                            className={styleSheet.button}
                            onClick={() => {
                                goBack();
                            }}
                            height={48}
                        >
                            <Typography
                                textStyle="h4"
                                color={theme.palette.white}
                                style={{ fontSize: 18 }}
                            >
                                {'Stay Subscribed'}
                            </Typography>
                        </ButtonComponent>
                        <ButtonComponent
                            fill
                            color={theme.palette.blue}
                            className={styleSheet.button}
                            onClick={() => {
                                setDowngradeClicked(true);
                                if (planId !== null) {
                                    downgrade();
                                } else {
                                    downgradeCompletely();
                                }
                            }}
                            disabled={downgradeClicked}
                            height={48}
                        >
                            <Typography
                                textStyle="h4"
                                color={theme.palette.white}
                                style={{ fontSize: 18 }}
                            >
                                {'Downgrade'}
                            </Typography>
                        </ButtonComponent>
                    </div>
                </>
            }
        </AnimatedModal>
    );
};

export default DowngradeModal;
