import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
// Redux Imports
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateLog } from '../Showings/selectors';
import { getProfileData } from '../Profile/selectors';
import { getAllShowings, getLoading } from './selectors';
import { fetchAllShowingsRequested } from './actions';
import { calendarRefreshSucceeded } from '../Showings/actions';
import { saveShowingInRedux } from '../ShowingDetailsScreen/actions';
// Componets Import
import { Loader, UpgradeSnackBar, NewPaginator } from '../../../components';
import { DatePicker } from '../../../components/DatePicker';
//Image Imports
import SendIt from '../../../images/sendIt.svg';
import GrayGuy from '../../../images/grayGuy.svg';
import GrayCal from '../../../images/grayCal.svg';
import GrayClock from '../../../images/grayClock.svg';
import XButtonCircle from '../../../images/xButton.svg';
import backArrow from '../../../images/leftArrowBlack.svg';
import frontArrow from '../../../images/rightArrowBlack.svg';
// Other Imports
import { noop } from 'lodash';
import styles from './styles';
import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import { createUseStyles, useTheme } from 'react-jss';
import { capitalizeFirstLetter, numberWithCommas } from '../../../utils/common';
//Unusing Imports
import { DH_CHECK_P_NOT_PRIME } from 'constants';

interface CalendarScreenProps {}

const CalendarScreen = (props: CalendarScreenProps) => {
    // ----------------------------- States Values and Logic. ------------------------------
    // Redux value
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const allShowings = useSelector(getAllShowings);
    const agentRecord = useSelector(getProfileData);
    const updatelog = useSelector(getUpdateLog);
    // State value and logic for Calendar Resize.
    const [cardWidth, setCardWidth] = useState(0);
    const [gridWidth, setGridWidth] = useState<any>(0);
    const [screenInnerWidth, setScreenInnerWidth] = useState(0);
    // State values and logic for displaying the modal that appears when a showing is clicked.
    const [modalTop, setModalTop] = useState<any>(0);
    const [modalLeft, setModalLeft] = useState<any>(0);
    const [modalVisible, setModalVisible] = useState<any>(null);
    // State values and logic for displaying the modal that appears when a multiple card showing is clicked.
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [multipleModals, setMultipleModals] = useState<any>(null);
    const [paginatedData, setPaginatedData] = useState<Array<any>>([]);
    const [isMultipleSelected, setIsMultipleSelected] = useState(false);
    // Style Values
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const now = DateTime.local();
    // Other Values
    const [selectedMoment, setSelectedMoment] = React.useState(now);
    const agentRecordId = agentRecord?._id;
    // Format days of this week for display
    const startOfWeek = selectedMoment.plus({ days: 1 }).startOf('week').minus({ days: 1 });
    const startOfWeekLabel = startOfWeek.toFormat('LLLL d');
    const endOfWeek = selectedMoment.plus({ days: 1 }).endOf('week').minus({ days: 1 });
    const endOfWeekLabel = endOfWeek.toFormat('LLLL d');
    const startOfWeekNumber = startOfWeek.day;
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // Get the showings for this week
    const showingsThisWeek = allShowings
        ?.find(
            (d: any) =>
                d.startOfWeek === startOfWeek.toISODate() && d.endOfWeek === endOfWeek.toISODate(),
        )
        ?.showings?.filter((showing: any) => {
            const startWeek = selectedMoment.plus({ days: 1 }).startOf('week').minus({ days: 1 });
            const endWeek = selectedMoment.plus({ days: 1 }).endOf('week').minus({ days: 1 });
            if (
                DateTime.fromJSDate(showing.start) < endWeek &&
                DateTime.fromJSDate(showing.start) > startWeek
            ) {
                return true;
            } else return false;
        });
    // Create a Map that groups all showings by date
    const showingsByDate = showingsThisWeek?.length
        ? showingsThisWeek?.reduce((showings: any, showing: any) => {
              const date = DateTime.fromJSDate(showing.start).toISODate();
              if (showings.has(date)) {
                  // We've already seen at least one showing on this date, add it to the array
                  showings.get(date).push(showing);
              } else {
                  // This is the first showing on this date, track it.
                  showings.set(date, [showing]);
              }
              return showings;
          }, new Map())
        : null;
    // -------------------------- End of States Values and Logic. --------------------------

    useEffect(() => {
        onResize();
    }, []);

    useEffect(() => {
        if (!showingsThisWeek || !updatelog.calendar) {
            fetchAllShowings(startOfWeek.toISODate(), endOfWeek.toISODate());
            dispatch(calendarRefreshSucceeded());
        }
    }, [selectedMoment]);

    useEffect(() => {
        if (modalVisible) {
            const selectedShowingCard = document.getElementById(
                isMultipleSelected ? `${multipleModals[0]._id}1` : `${modalVisible._id}1`,
            );
            const leftPos = (selectedShowingCard?.getBoundingClientRect()?.left || 0) - 377;
            const topPos =
                (selectedShowingCard?.getBoundingClientRect()?.top || 0) -
                (368 - modalVisible.duration) / 2;
            setModalLeft(leftPos);
            setModalTop(topPos);
        }
    }, [modalVisible]);

    const fetchAllShowings = (startOfWeek: string, endOfWeek: string) =>
        dispatch(fetchAllShowingsRequested(startOfWeek, endOfWeek));

    const saveShowingToRedux = (showing: any, initiallyViewingListingDetails: boolean = false) =>
        dispatch(saveShowingInRedux(showing, initiallyViewingListingDetails));

    //Change the Showing display when the pagination change
    const changeShowing = (page: any) => {
        if (isMultipleSelected) {
            setModalVisible(multipleModals[page - 1]);
            setCurrentPage(page);
        }
    };

    //Set the initial values of Overlaped Showings
    const initialOverlapedShowings = (showings: any) => {
        setCurrentPage(1);
        setMultipleModals(showings);
        setModalVisible(showings[0]);
        setIsMultipleSelected(true);
    };

    const onResize = () => {
        setScreenInnerWidth(window.innerWidth);
        const calendarHeaderWidth = window.innerWidth - 501;
        const gridHeaderWidth: any = calendarHeaderWidth - 148;
        setGridWidth(gridHeaderWidth - 2);
        setCardWidth((gridHeaderWidth - 2) / 7 - 2);
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', () => onResize());
        return () => {
            window.removeEventListener('resize', () => onResize());
        };
    }, []);

    // increase or decrease for month change
    type incDecOption = -1 | 1;

    const history = useHistory();

    // handle month is incremented or decremented via the DatePicker
    const changeMonth = (incDec: incDecOption) => {
        if (incDec === 1) {
            let newNow = selectedMoment.plus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        } else if (incDec === -1) {
            let newNow = selectedMoment.minus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        }
    };

    // Handle when week is incremented or decremented via the selction row
    const changeWeek = (incDec: incDecOption) => {
        if (incDec === 1) {
            const newNow = selectedMoment.plus({ days: 7 });
            setSelectedMoment(newNow);
        } else if (incDec === -1) {
            const newNow = selectedMoment.minus({ days: 7 });
            setSelectedMoment(newNow);
        }
    };

    const isoDays = days.map((d, i) => {
        return startOfWeek.plus({ days: i }).toISODate();
    });
    const daysAndNumbers = days.map((d, i) => {
        let n = startOfWeekNumber + i;
        const daysInTheMonth = startOfWeek.daysInMonth;

        if (n > daysInTheMonth) {
            n = n - daysInTheMonth;
        }
        return {
            day: d,
            number: n,
        };
    });

    // The mini calendar component and status keys (to the left of the main calendar)
    const TheCalendarWithin = (
        <div className={styleSheet.leftCalendarColumn}>
            <DatePicker
                changeMonth={(incDec: incDecOption) => changeMonth(incDec)}
                selectedDay={selectedMoment}
                setSelectedDay={(sel: any) => setSelectedMoment(sel)}
            />
            <div className={styleSheet.horizontalSeparator} />
            <span className={styleSheet.calendarKeyText}>Calendar Key</span>
            <div className={styleSheet.circleTextRow}>
                <div className={classNames(styleSheet.circle, styleSheet.green)} />
                <span className={styleSheet.circleText}>Completed & Logged</span>
            </div>
            <div className={styleSheet.circleTextRow}>
                <div className={classNames(styleSheet.circle, styleSheet.blue)} />
                <span className={styleSheet.circleText}>Confirmed</span>
            </div>
            <div className={styleSheet.circleTextRow}>
                <div className={classNames(styleSheet.circle, styleSheet.gray)} />
                <span className={styleSheet.circleText}>Pending & Requested</span>
            </div>
            <div className={styleSheet.circleTextRow}>
                <div className={classNames(styleSheet.circle, styleSheet.red)} />
                <span className={styleSheet.circleText}>Cancelled & Denied</span>
            </div>
            <div className={styleSheet.circleTextRow}>
                <div className={classNames(styleSheet.circle, styleSheet.purple)} />
                <span className={styleSheet.circleText}>Brokerage</span>
            </div>
        </div>
    );

    const displayHours = [
        '7am',
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
        '8pm',
        '9pm',
        '10pm',
        '11pm',
    ];

    // Configure labels and colors for displaying status within showing modal
    const statusColors: any = {
        great: {
            background: 'rgba(47, 210, 173, 0.15)',
            main: '#2fd2a8',
        },
        good: {
            background: 'rgba(72, 164, 255, 0.15)',
            main: '#48a4ff',
        },
        bad: {
            background: 'rgba(255, 67, 67, 0.15)',
            main: '#ff4343',
        },
        neutral: {
            background: 'rgba(154, 154, 154, 0.15)',
            main: '#9a9a9a',
        },
    };
    const greatStatuses = ['completed', 'logged'];
    const goodStatuses = ['confirmed'];
    const badStatuses = ['cancelled', 'denied'];

    const statusStatus = greatStatuses.includes(modalVisible?.status)
        ? 'great'
        : goodStatuses.includes(modalVisible?.status)
        ? 'good'
        : badStatuses.includes(modalVisible?.status)
        ? 'bad'
        : 'neutral';
    const getStatusLabel = (status: any) => {
        if (status === 'pending_internal' || status === 'pending_external') {
            return 'Pending';
        } else {
            return capitalizeFirstLetter(status);
        }
    };

    return (
        <div className={styleSheet.root}>
            <UpgradeSnackBar />
            <div className={styleSheet.selectionRow}>
                Calendar & Schedule
                <div className={styleSheet.weekSelector}>
                    <div onClick={() => changeWeek(-1)} className={styleSheet.arrowContainer}>
                        <img src={backArrow} className={styleSheet.arrow} />
                    </div>
                    <span className={styleSheet.selectorText}>
                        {startOfWeekLabel} - {endOfWeekLabel}
                    </span>
                    <div onClick={() => changeWeek(1)} className={styleSheet.arrowContainer}>
                        <img src={frontArrow} className={styleSheet.arrow} />
                    </div>
                </div>
                <div className={styleSheet.buttonBox} onClick={() => history.push('/search')}>
                    <span className={styleSheet.buttonText}>Schedule a Showing</span>
                </div>
            </div>
            <div className={styleSheet.mainContent}>
                {TheCalendarWithin}
                <div className={styleSheet.headerAndScroller}>
                    <div className={styleSheet.header}>
                        <div className={styleSheet.mstContainer}>MST</div>
                        <div className={styleSheet.daysAndBars} style={{ width: gridWidth + 2 }}>
                            <div className={styleSheet.daysContainer}>
                                {daysAndNumbers.map((dan) => {
                                    const { day, number } = dan;
                                    return (
                                        <div className={styleSheet.day}>
                                            <span className={styleSheet.dayText}>{day}</span>
                                            <span className={styleSheet.dayNumberText}>
                                                {number}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styleSheet.bars}>
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                                <div className={styleSheet.bar} />
                            </div>
                        </div>
                    </div>
                    <div
                        className={modalVisible ? styleSheet.scrollerNoFlow : styleSheet.scroller}
                        style={{ width: screenInnerWidth - 564 }}
                    >
                        {loading && (
                            <div
                                style={{
                                    position: 'fixed',
                                    left: 564,
                                    top: 'inherit',
                                    display: 'flex',
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 'inherit',
                                    height: 'inherit',
                                    background: '#ffffff60',
                                }}
                            >
                                <Loader />
                            </div>
                        )}
                        <div className={styleSheet.timeLinesContainer}>
                            {displayHours.map((dh) => {
                                return (
                                    <div className={styleSheet.timeLines}>
                                        <div className={styleSheet.topRow}>
                                            <span className={styleSheet.topRowText}>{dh}</span>
                                            <div className={styleSheet.timeLine} />
                                        </div>
                                        <div className={styleSheet.otherLines}>
                                            <div className={styleSheet.noLine} />
                                            <div className={styleSheet.tinyLine} />
                                            <div className={styleSheet.smallLine} />
                                            <div className={styleSheet.tinyLine} />
                                            <div className={styleSheet.bottomNoLine} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styleSheet.theGridAndLines}>
                            <div className={styleSheet.lines}>
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                                <div className={styleSheet.line} />
                            </div>
                            <div className={styleSheet.theGrid} style={{ width: gridWidth }}>
                                {isoDays.map((isoDay: string, isoDayIndex: number) => {
                                    const showingsForThisDay = showingsByDate?.get(isoDay) || [];
                                    showingsForThisDay.forEach((showing: any, index: any) => {
                                        showingsForThisDay[index].overlapping = 0;
                                    });

                                    /*
                                     * Create an array for final card output, and if there are any showings
                                     * add the first one to it
                                     */

                                    // if (showingsForThisDate && showingsForThisDate.length) {
                                    //     mergedShowings.push(showingsForThisDate[0]);
                                    // }

                                    if (showingsForThisDay.length > 1) {
                                        // Sort all showings for this date by start time
                                        let swap;
                                        do {
                                            swap = false;
                                            for (
                                                let i = 0;
                                                i < showingsForThisDay.length - 1;
                                                i++
                                            ) {
                                                if (
                                                    showingsForThisDay[i].startTime >
                                                    showingsForThisDay[i + 1].startTime
                                                ) {
                                                    let temp = showingsForThisDay[i];
                                                    showingsForThisDay[i] =
                                                        showingsForThisDay[i + 1];
                                                    showingsForThisDay[i + 1] = temp;
                                                    swap = true;
                                                }
                                            }
                                        } while (swap);

                                        // Merge all showings that overlap for a given time period
                                        for (let i = 1; i < showingsForThisDay.length; i++) {
                                            const curr = showingsForThisDay[i];
                                            const currStartTime = DateTime.fromJSDate(curr.start);
                                            const currEndTime = DateTime.fromJSDate(curr.end);
                                            const prev = showingsForThisDay[i - 1];
                                            let currInterval: any = 0;
                                            let prevInterval: any = 0;

                                            // Interval of current element being looked at
                                            currInterval = Interval.fromDateTimes(
                                                currStartTime,
                                                currEndTime,
                                            );

                                            /**
                                             * If the previous index is not an array (currently has no overlapping showings),
                                             * compare the interval of the previous element. Otherwise compare the first index
                                             * of the previous element
                                             */
                                            if (!Array.isArray(prev)) {
                                                const prevStartTime = DateTime.fromJSDate(
                                                    prev.start,
                                                );
                                                const prevEndTime = DateTime.fromJSDate(prev.end);
                                                prevInterval = Interval.fromDateTimes(
                                                    prevStartTime,
                                                    prevEndTime,
                                                );
                                            } else {
                                                const currPrev = prev[prev.length - 1];
                                                const currPrevStartTime = DateTime.fromJSDate(
                                                    currPrev.start,
                                                );
                                                const currPrevEndTime = DateTime.fromJSDate(
                                                    currPrev.end,
                                                );
                                                prevInterval = Interval.fromDateTimes(
                                                    currPrevStartTime,
                                                    currPrevEndTime,
                                                );
                                            }

                                            /**
                                             * If the current showing overlaps the previous showing, merge the current
                                             * and overlapping showings. If the previous showing is already a list of
                                             * elements, move the current showing into that list of already overlapping showings.
                                             */
                                            if (currInterval.overlaps(prevInterval)) {
                                                if (!Array.isArray(prev)) {
                                                    showingsForThisDay[i - 1] = [
                                                        { ...prev },
                                                        { ...curr },
                                                    ];
                                                } else {
                                                    showingsForThisDay[i - 1].push(curr);
                                                }
                                                showingsForThisDay.splice(i, 1);
                                                i--;
                                            }
                                        }
                                    }

                                    return (
                                        <div className={styleSheet.gridColumn}>
                                            {displayHours.map(() => {
                                                return <div className={styleSheet.gridBox} />;
                                            })}
                                            {showingsForThisDay.map((showingForThisDay: any) => {
                                                const overlapping =
                                                    Array.isArray(showingForThisDay);

                                                if (overlapping) {
                                                    const showingLabel = `${showingForThisDay.length} Showings`;
                                                    const firstStartTime = DateTime.fromJSDate(
                                                        showingForThisDay[0].start,
                                                    );
                                                    let maxDuration = 0;
                                                    let duration = 0;
                                                    showingForThisDay.forEach((element: any) => {
                                                        // Convert length of showing into usable height value for the card
                                                        maxDuration =
                                                            Interval.fromDateTimes(
                                                                firstStartTime,
                                                                element.end,
                                                            ).length('minutes') *
                                                            (4 / 3);
                                                        if (duration < maxDuration) {
                                                            duration = maxDuration;
                                                        }
                                                    });
                                                    const startTimeMinutes =
                                                        firstStartTime.hour * 80 +
                                                        firstStartTime.minute * (4 / 3) -
                                                        538;
                                                    const formattedShowingTimes =
                                                        showingForThisDay.map((sfd: any) => {
                                                            const startTime = DateTime.fromJSDate(
                                                                sfd.start,
                                                            );
                                                            const endTime = DateTime.fromJSDate(
                                                                sfd.end,
                                                            );
                                                            return `${startTime.toFormat(
                                                                'h:mm',
                                                            )}${startTime
                                                                .toFormat('a')
                                                                .toLowerCase()} - ${endTime.toFormat(
                                                                'h:mm',
                                                            )}${endTime
                                                                .toFormat('a')
                                                                .toLowerCase()}`;
                                                        });
                                                    return (
                                                        <div
                                                            id={`${showingForThisDay[0]._id}1`}
                                                            style={{
                                                                height: duration,
                                                                width: cardWidth,
                                                                flexDirection: 'row',
                                                                alignItems:
                                                                    duration > 20
                                                                        ? 'flex-start'
                                                                        : 'center',
                                                                backgroundColor: '#fff',
                                                                position: 'absolute',
                                                                top: startTimeMinutes,
                                                                display: 'flex',
                                                                overflow: 'hidden',
                                                                borderRadius: 1,
                                                                boxShadow:
                                                                    '0px 4px 8px 0px rgba(0, 0, 0, .05)',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setPaginatedData(showingForThisDay);
                                                                const overllapedShowings = {
                                                                    ...showingForThisDay,
                                                                    duration,
                                                                };
                                                                initialOverlapedShowings(
                                                                    overllapedShowings,
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    styleSheet.showingStatusBar,
                                                                    styleSheet.black,
                                                                )}
                                                            />
                                                            {duration > 20 ? (
                                                                <div
                                                                    className={
                                                                        styleSheet.labelAndTime
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            styleSheet.showingLabelText
                                                                        }
                                                                    >
                                                                        {showingLabel}
                                                                    </span>
                                                                    {formattedShowingTimes.map(
                                                                        (fst: any) => (
                                                                            <div
                                                                                className={
                                                                                    styleSheet.clockAndTime
                                                                                }
                                                                            >
                                                                                {screenInnerWidth >=
                                                                                    1650 && (
                                                                                    <img
                                                                                        className={
                                                                                            styleSheet.clockIcon
                                                                                        }
                                                                                        src={
                                                                                            GrayClock
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                <span
                                                                                    className={
                                                                                        styleSheet.timeText
                                                                                    }
                                                                                >
                                                                                    {fst}
                                                                                </span>
                                                                            </div>
                                                                        ),
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <span
                                                                    className={
                                                                        styleSheet.showingLabelText
                                                                    }
                                                                >
                                                                    {showingLabel}
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                } else {
                                                    const isBuyerAgent =
                                                        showingForThisDay?.agentId?.toString() ===
                                                        agentRecordId.toString();
                                                    const showingLabel =
                                                        isBuyerAgent &&
                                                        showingForThisDay?.consumer?.firstName
                                                            ? `Showing with ${
                                                                  showingForThisDay.consumer
                                                                      .firstName
                                                              } ${showingForThisDay.consumer.lastName?.substring(
                                                                  0,
                                                                  1,
                                                              )}.`
                                                            : `Showing`;
                                                    const startTime = DateTime.fromJSDate(
                                                        showingForThisDay.start,
                                                    );
                                                    const endTime = DateTime.fromJSDate(
                                                        showingForThisDay.end,
                                                    );

                                                    // Length of the showing time 1.3333 to display as height of card
                                                    const duration =
                                                        Interval.fromDateTimes(
                                                            startTime,
                                                            endTime,
                                                        ).length('minutes') *
                                                        (4 / 3);

                                                    // This is the distance from the top of the scroller that each
                                                    // showing card should render, must account for the length in minutes times 1.3333
                                                    // (because each card is 80 pixels tall but represents 60 minutes) as well as the
                                                    // hours not displayed and the padding above the grid
                                                    const startTimeMinutes =
                                                        startTime.hour * 80 +
                                                        startTime.minute * (4 / 3) -
                                                        538;
                                                    const formattedTimes = `${startTime.toFormat(
                                                        'h:mm',
                                                    )}${startTime
                                                        .toFormat('a')
                                                        .toLowerCase()} - ${endTime.toFormat(
                                                        'h:mm',
                                                    )}${endTime.toFormat('a').toLowerCase()}`;
                                                    const getStatusClass = (status: string) => {
                                                        switch (status) {
                                                            case 'pending-external':
                                                            case 'requested':
                                                            case 'pending-internal':
                                                                return 'gray';
                                                            case 'confirmed':
                                                                return 'blue';
                                                            case 'denied':
                                                            case 'cancelled':
                                                                return 'red';
                                                            case 'completed':
                                                            case 'logged':
                                                                return 'green';
                                                            default:
                                                                return 'black';
                                                        }
                                                    };

                                                    return (
                                                        <div
                                                            id={`${showingForThisDay._id}1`}
                                                            style={{
                                                                height: duration,
                                                                width: cardWidth,
                                                                flexDirection: 'row',
                                                                alignItems:
                                                                    duration > 20
                                                                        ? 'flex-start'
                                                                        : 'center',
                                                                backgroundColor: '#fff',
                                                                position: 'absolute',
                                                                top: startTimeMinutes,
                                                                display: 'flex',
                                                                overflow: 'hidden',
                                                                borderRadius: 1,
                                                                boxShadow:
                                                                    '0px 4px 8px 0px rgba(0, 0, 0, .05)',
                                                                cursor: 'pointer',
                                                                zIndex:
                                                                    modalVisible?._id?.toString() ===
                                                                    showingForThisDay?._id?.toString()
                                                                        ? 1000000
                                                                        : 100,
                                                            }}
                                                            onClick={() => {
                                                                setIsMultipleSelected(false);
                                                                setModalVisible({
                                                                    ...showingForThisDay,
                                                                    duration,
                                                                    isBuyerAgent,
                                                                });
                                                            }}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    styleSheet.showingStatusBar,
                                                                    styleSheet[
                                                                        getStatusClass(
                                                                            showingForThisDay.status,
                                                                        )
                                                                    ],
                                                                )}
                                                            />
                                                            {duration > 20 ? (
                                                                <div
                                                                    className={
                                                                        styleSheet.labelAndTime
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            styleSheet.showingLabelText
                                                                        }
                                                                    >
                                                                        {showingLabel}
                                                                    </span>
                                                                    <div
                                                                        className={
                                                                            styleSheet.clockAndTime
                                                                        }
                                                                    >
                                                                        {screenInnerWidth >=
                                                                            1650 && (
                                                                            <img
                                                                                className={
                                                                                    styleSheet.clockIcon
                                                                                }
                                                                                src={GrayClock}
                                                                            />
                                                                        )}
                                                                        <span
                                                                            className={
                                                                                styleSheet.timeText
                                                                            }
                                                                        >
                                                                            {formattedTimes}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <span
                                                                    className={
                                                                        styleSheet.showingLabelText
                                                                    }
                                                                >
                                                                    {showingLabel}
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {modalVisible && (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                top: 60,
                                left: 200,
                                right: 0,
                                bottom: 0,
                                zIndex: 10000,
                                backgroundColor: 'rgba(0,0,0,0.3)',
                            }}
                        />
                        <div
                            onClick={() => setModalVisible(null)}
                            style={{
                                position: 'absolute',
                                top: 60,
                                left: 200,
                                right: 0,
                                bottom: 0,
                                zIndex: 1009999999090,
                            }}
                        />
                        <div
                            onClick={() => {}}
                            style={{
                                height: 368,
                                width: 360,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                                zIndex: 9999999999999999,
                                position: 'absolute',
                                top: modalTop,
                                left: modalLeft,
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                className={styleSheet.statusContainer}
                                style={{
                                    backgroundColor: statusColors[statusStatus].background,
                                    color: statusColors[statusStatus].main,
                                }}
                            >
                                <span className={styleSheet.statusText}>
                                    {getStatusLabel(modalVisible?.status)}
                                </span>
                            </div>
                            <img
                                onClick={() => setModalVisible(null)}
                                src={XButtonCircle}
                                className={styleSheet.xButton}
                            />
                            <span className={styleSheet.priceText}>
                                {'$'}
                                {numberWithCommas(modalVisible.listing.listPrice)}
                            </span>
                            <span className={styleSheet.addressText}>
                                {modalVisible.listing.address.full}
                            </span>
                            <span className={styleSheet.secondAddressText}>
                                {modalVisible.listing.address.city},{' '}
                                {modalVisible.listing.address.state}{' '}
                                {modalVisible.listing.address.postalCode}
                            </span>
                            <span
                                onClick={() => {
                                    saveShowingToRedux(modalVisible, true);
                                    history.push(
                                        `/showingDetails/${modalVisible?._id?.toString()}`,
                                    );
                                }}
                                className={styleSheet.viewText}
                            >
                                View Listing
                            </span>
                            <div className={styleSheet.iconAndText}>
                                <img className={styleSheet.biggerIcon} src={GrayCal} />
                                <span className={styleSheet.grayText}>
                                    {DateTime.fromJSDate(modalVisible.start).toFormat('LLLL d, y')}
                                </span>
                            </div>
                            <div className={styleSheet.iconAndText}>
                                <img className={styleSheet.biggerIcon} src={GrayClock} />
                                <span className={styleSheet.grayText}>
                                    {DateTime.fromJSDate(modalVisible.start).toFormat('h:mm')}
                                    {DateTime.fromJSDate(modalVisible.start)
                                        .toFormat('a')
                                        .toLowerCase()}{' '}
                                    - {DateTime.fromJSDate(modalVisible.end).toFormat('h:mm')}
                                    {DateTime.fromJSDate(modalVisible.end)
                                        .toFormat('a')
                                        .toLowerCase()}
                                </span>
                            </div>
                            <div className={styleSheet.iconAndText}>
                                <img className={styleSheet.biggerIcon} src={GrayGuy} />
                                <span className={styleSheet.grayText}>
                                    {modalVisible.isBuyerAgent
                                        ? `${modalVisible.listing.agent.firstName} ${modalVisible.listing.agent.lastName}`
                                        : `${modalVisible.agent.firstName} ${modalVisible.agent.lastName}`}
                                </span>
                            </div>
                            <div
                                className={styleSheet.viewShowingContainer}
                                style={{ flexDirection: 'column', alignItems: 'flex-end' }}
                            >
                                <div style={{ flexDirection: 'row' }}>
                                    <span
                                        onClick={() => {
                                            saveShowingToRedux(modalVisible);
                                            history.push(
                                                `/showingDetails/${modalVisible?._id?.toString()}`,
                                            );
                                        }}
                                        className={styleSheet.viewShowingText}
                                    >
                                        View Showing Details
                                    </span>
                                    <img src={SendIt} className={styleSheet.sendIcon} />
                                </div>
                                <div>
                                    {isMultipleSelected && (
                                        <NewPaginator
                                            dots={true}
                                            data={paginatedData}
                                            itemsPerPage={1}
                                            currentPage={currentPage}
                                            setCurrentPage={changeShowing}
                                            setPaginatedData={noop} // We don't really need to modify the data from within the paginator
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CalendarScreen;
