import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { getLoading, getListings } from '../selectors';
import { useParams } from 'react-router-dom';
import Tag from '../../../../components/Tag';
import ButtonComponent from '../../../../components/ButtonComponent';
import { formatCurrency } from '../../../../utils/common/transforms';
import PastMessages from './PastMessages';
import { CircularProgress } from '@material-ui/core';
import {
    sendBlastMessageRequested,
    fetchRecipientsRequested,
} from '../../ListingDetailsScreen/actions';
import {
    getMessageBlastRecipients,
    getBlastMessageSending,
    isRecipientLoading,
} from '../../ListingDetailsScreen/selectors';
import { DateTime } from 'luxon';

import {
    AnimatedModal,
    ClickableWithFeedback,
    Loader,
    PickerComponent,
    Typography,
} from '../../../../components';
import CloseIcon from '../../../../images/circleCloseBlack.svg';
import DeleteIcon from '../../../../images/circleCloseGray.svg';
import PlusIcon from '../../../../images/plusIconBlue.svg';
import Stars from '../Stars';
import TableWithFilterBar from '../../CRM/TableWithFilterBar';

const modalStyle = {
    width: '100%',
    minHeight: 'unset',
};

const starStyle = {
    width: 15,
    height: 15,
    marginRight: 8,
    marginLeft: 0,
};

function MessageBlastScreen() {
    const listingsLoading = useSelector(getLoading);
    const loading = useSelector(isRecipientLoading);
    const listings = useSelector(getListings);
    const dataList = useSelector(getMessageBlastRecipients);
    const messageSending = useSelector(getBlastMessageSending);
    const { id }: any = useParams();
    const dispatch = useDispatch();

    const [listing, setListing] = useState<any>(null);
    const [reverseBlast, setReverseBlasts] = useState<any>([]);
    const [messageModal, setMessageModal] = useState(false);
    const [recipientModal, setRecipientModal] = useState(false);
    const [message, setMessage] = useState('');
    const animatedModalRef = useRef<any>(null);

    const filterOptions = ['Send to Everyone', 'Select Custom Recipients'];
    const [selectOption, setSelectOption] = useState<any>(filterOptions[0]);

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [selectedRows, setChecked] = useState<Map<any, boolean>>(new Map<any, boolean>());
    const [selectedRowsNames, setSelectedRowNames] = useState<any[]>([]);
    const [recipientList, setRecipientList] = useState<any[]>([]);

    useEffect(() => {
        setListing(listings.find((i: any) => i?._id.toString() === id));
    }, [listings]);

    useEffect(() => {
        setRecipientList(
            dataList.map((recipient: any, index: any) => ({
                _id: recipient?._id,
                firstName: recipient.agent
                    ? recipient.agent?.firstName
                    : recipient.unverifiedUser?.firstName,
                lastName: recipient.agent
                    ? recipient.agent?.lastName
                    : recipient.unverifiedUser?.lastName,
                showingDate: {
                    data: new Date(recipient?.start),
                    render: DateTime.fromJSDate(new Date(recipient?.start)).toLocaleString(
                        DateTime.DATE_MED,
                    ),
                },
                status: recipient?.status,
                Price: {
                    data:
                        recipient.feedback?.ratings?.priceAccuracy === undefined
                            ? 0
                            : recipient.feedback?.ratings?.priceAccuracy === undefined,
                    render: (
                        <Stars
                            value={recipient.feedback?.ratings?.priceAccuracy}
                            starStyle={starStyle}
                            variant="black"
                        />
                    ),
                },
                Location: {
                    data:
                        recipient.feedback?.ratings?.location === undefined
                            ? 0
                            : recipient.feedback?.ratings?.location === undefined,
                    render: (
                        <Stars
                            value={recipient.feedback?.ratings?.location}
                            starStyle={starStyle}
                            variant="black"
                        />
                    ),
                },
                Interest: {
                    data:
                        recipient.feedback?.ratings?.interestLevel === undefined
                            ? 0
                            : recipient.feedback?.ratings?.interestLevel === undefined,
                    render: (
                        <Stars
                            value={recipient.feedback?.ratings?.interestLevel}
                            starStyle={starStyle}
                            variant="black"
                        />
                    ),
                },
            })),
        );
    }, [dataList]);

    useEffect(() => {
        const selectedIdArray = Array.from(selectedRows, ([_id, checked]) => ({
            _id,
            checked,
        })).filter((d) => d.checked);
        setSelectedRowNames(
            dataList.filter((d: any) => selectedIdArray.find((e) => e._id === d._id)),
        );
    }, [selectedRows]);

    useEffect(() => {
        if (listing) {
            dispatch(fetchRecipientsRequested(listing?._id));
            setReverseBlasts([...listing?.blast].reverse());
            setMessageModal(!listing?.blast?.length);
        }
    }, [listing]);

    const header = () => {
        return (
            <div className={styleSheet.priceHeader}>
                <div className={styleSheet.leftContent}>
                    <span className={styleSheet.price}>
                        {'$'}
                        {formatCurrency(listing?.listPrice)}
                    </span>
                    <div className={styleSheet.verticalSeparator} />
                    <span className={styleSheet.address}>{listing?.address?.full}</span>
                    <span className={styleSheet.city}>
                        {listing?.address?.city}, {listing?.address?.state}{' '}
                        {listing?.address?.postal}
                    </span>
                    <div className={styleSheet.verticalSeparator} />

                    <div className={styleSheet.tagBox}>
                        <Tag
                            text={
                                listing?.agentListing?.acceptingShowings
                                    ? 'Showings Enabled'
                                    : 'Showings Disabled'
                            }
                            color={listing?.agentListing?.acceptingShowings ? 'green' : 'red'}
                        />
                        <Tag
                            text={
                                listing?.agentListing?.showToUnrepresentedConsumers !== false
                                    ? 'Syndication Enabled'
                                    : 'Syndication Disabled'
                            }
                            color={
                                listing?.agentListing?.showToUnrepresentedConsumers !== false
                                    ? 'green'
                                    : 'red'
                            }
                        />
                    </div>
                </div>
                <ButtonComponent fill onClick={() => setMessageModal(true)} width={257}>
                    New Message Blast
                </ButtonComponent>
            </div>
        );
    };

    const pastMessages = (
        <div className={styleSheet.pastMessagesStyle}>
            {reverseBlast?.map((data: any, index: any) => {
                return (
                    <PastMessages
                        agentData={listing.blastRecipientAgentData}
                        data={data}
                        recipients={dataList}
                    />
                );
            })}
        </div>
    );

    const createMessage = (
        <AnimatedModal
            set={setMessageModal}
            ref={animatedModalRef}
            modalVisible={messageModal}
            style={{ ...modalStyle, maxWidth: recipientModal ? '1297px' : '812px' }}
        >
            <div>
                <div className={styleSheet.headerBar} style={{ borderColor: theme.palette.grey }}>
                    <Typography textStyle="p1" color={'#9A9A9A'}>
                        {recipientModal ? 'Select Recipients' : 'New Message Blast'}
                    </Typography>

                    <ClickableWithFeedback
                        onClick={() =>
                            recipientModal ? setRecipientModal(false) : setMessageModal(false)
                        }
                    >
                        <img src={CloseIcon} className={styleSheet.closeIcon} />
                    </ClickableWithFeedback>
                </div>
                {recipientModal ? (
                    <>
                        {loading ? (
                            <div className={styleSheet.loader}>
                                <Loader size={100} />
                            </div>
                        ) : (
                            <TableWithFilterBar
                                allChecked={allChecked}
                                setAllChecked={setAllChecked}
                                labels={[
                                    'First Name',
                                    'Last Name',
                                    'Showing Date',
                                    'Status',
                                    'Price',
                                    'Location',
                                    'Interest',
                                ]}
                                selectedRows={selectedRows}
                                setChecked={setChecked}
                                rows={recipientList}
                                showBlueTick={(row: any) => false}
                                rowStyle={{
                                    marginTop: 0,
                                    height: 'unset',
                                    minHeight: 48,
                                    fontSize: 16,
                                    borderBottom: '1px solid #e9e9e980',
                                }}
                                filterBarStyle={{ height: 47, borderColor: '#e9e9e9' }}
                                itemsPerPage={10}
                                tableBackgroundColor="white"
                            />
                        )}
                    </>
                ) : (
                    <>
                        <div className={styleSheet.picker}>
                            <PickerComponent
                                data={filterOptions}
                                selectedOption={selectOption}
                                onPressButton={setSelectOption}
                                height={36}
                                optionWidth={366}
                            />
                        </div>

                        {!(selectOption !== 'Send to Everyone' && !!selectedRowsNames.length) && (
                            <text
                                className={styleSheet.pickerText}
                                style={{
                                    color:
                                        selectOption === 'Send to Everyone' ? '#9A9A9A' : '#48A4FF',
                                }}
                            >
                                {selectOption === 'Send to Everyone' ? (
                                    ' This message will send to all buyer agents who have requested a showing on this lisiting.'
                                ) : (
                                    <ClickableWithFeedback onClick={() => setRecipientModal(true)}>
                                        Select Recipients
                                    </ClickableWithFeedback>
                                )}
                            </text>
                        )}
                        {selectOption !== 'Send to Everyone' && !!selectedRowsNames.length && (
                            <div className={styleSheet.recipientDisplayWrap}>
                                {selectedRowsNames.map((d) => (
                                    <span
                                        className={styleSheet.recipientDisplay}
                                        style={{
                                            paddingRight: 5,
                                            flexDirection: 'row',
                                            marginTop: 10,
                                        }}
                                    >
                                        <span
                                            className={styleSheet.recipientDisplayText}
                                            style={{ marginRight: 8 }}
                                        >
                                            {d.agent
                                                ? d?.agent?.firstName + ' ' + d?.agent?.lastName
                                                : d?.unverifiedUser?.firstName +
                                                  ' ' +
                                                  d?.unverifiedUser?.lastName}
                                        </span>
                                        <ClickableWithFeedback
                                            onClick={() =>
                                                setChecked(new Map(selectedRows.set(d._id, false)))
                                            }
                                            className={styleSheet.clickableIcon}
                                        >
                                            <img src={DeleteIcon} height={16} width={16} />
                                        </ClickableWithFeedback>
                                    </span>
                                ))}
                                <ClickableWithFeedback
                                    onClick={() => setRecipientModal(true)}
                                    className={styleSheet.clickableIcon}
                                >
                                    <img src={PlusIcon} height={24} width={24} />
                                </ClickableWithFeedback>
                            </div>
                        )}
                        <div className={styleSheet.textAreaContainer}>
                            <textarea
                                className={styleSheet.textArea}
                                placeholder="What would you like to say?"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                maxLength={250}
                            />
                            <div className={styleSheet.circularProgressContainer}>
                                <CircularProgress
                                    variant="determinate"
                                    size={36}
                                    thickness={6}
                                    style={{ color: '#d3d3d3' }}
                                    value={100}
                                />
                                <CircularProgress
                                    variant="determinate"
                                    size={36}
                                    thickness={6}
                                    style={{ color: '#48A4FF', position: 'absolute', left: 0 }}
                                    value={(message.length * 100) / 250}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className={styleSheet.modalFooter}>
                    <ButtonComponent
                        fill
                        disabled={!recipientModal && messageSending}
                        onClick={() => {
                            if (recipientModal) {
                                setRecipientModal(false);
                            } else {
                                let recipientFinal: any = null;
                                const notification = {
                                    email: true,
                                    showInApp: true,
                                };
                                if (selectOption === filterOptions[1]) {
                                    //Custom
                                    recipientFinal = selectedRowsNames.map((d) =>
                                        d.agent ? d?.agent?._id : d.unverifiedUser,
                                    );
                                }
                                dispatch(
                                    sendBlastMessageRequested(
                                        listing?._id,
                                        message,
                                        recipientFinal,
                                        notification,
                                        null,
                                        () => {
                                            listing.blast?.push({
                                                listingObjectId: listing._id,
                                                message,
                                                privateRecipients: recipientFinal,
                                                date: new Date(),
                                            });
                                            setMessage('');
                                            setReverseBlasts([...listing?.blast].reverse());
                                            setSelectOption(filterOptions[0]);
                                            setChecked(new Map());
                                            setMessageModal(false);
                                        },
                                    ),
                                );
                            }
                        }}
                        width={257}
                    >
                        {recipientModal ? 'Add Recipients' : 'Send'}
                    </ButtonComponent>
                </div>
            </div>
        </AnimatedModal>
    );
    return listingsLoading ? (
        <div className={styleSheet.loader}>
            <Loader size={100} />
        </div>
    ) : (
        <div className={styleSheet.root}>
            {header()}
            {pastMessages}
            {messageModal && createMessage}
        </div>
    );
}

export default MessageBlastScreen;
