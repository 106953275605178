import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedDrawer, AnimatedDrawerHeader, Loader } from '..';
import ClickableWithFeedback from '../ClickableWithFeedback';
import {
    addClientsRequested,
    fetchClientsRequested,
    resetResponseDataRequested,
    setSelectedClientsRequested,
} from '../../domains/main/CRM/actions';
import {
    getAddedClients,
    getCRMErrors,
    getDeletedClients,
    getSearchedClients,
} from '../../domains/main/CRM/selectors';
import CRMAddClientsModal from '../../domains/main/CRM/CRMAddClientsModal';
import { NewClient } from '../../domains/main/CRM/CRMAddClientsModal/constants';

interface Props {
    visible: boolean;
    onClose: Function;
    backgroundOpacity?: number;
}

const AddClientDrawer = (props: Props) => {
    const clients = useSelector((state) => getSearchedClients(state).fetchedClients);
    const loading = useSelector((state) => getSearchedClients(state).loading);
    const addModalLoading = useSelector(
        (state) =>
            getSearchedClients(state).loading ||
            getDeletedClients(state).loading ||
            getAddedClients(state).loading,
    );
    const clientsAddedResponse = useSelector(
        (state) => getAddedClients(state).clientsAddedResponse,
    );
    const errors = useSelector(getCRMErrors);
    const [addModal, setAddModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const closeDrawerRef = useRef<any>(null);

    useEffect(() => {
        dispatch(fetchClientsRequested());
    }, []);

    const [searchText, setSearchText] = useState<string>('');

    // string match clients with search text
    const filteredClients = (text: string) => {
        if (!text?.length || !clients?.length) return clients;
        const filter: string = text.toLocaleLowerCase();
        const sorted: any = clients.filter((a: any) => {
            var name = `${a.firstName} ${a.lastName}`;
            name = name.toLocaleLowerCase();
            return name.includes(filter);
        });

        return sorted;
    };

    /**
     * Make sure that the client scroll view resizes when the screen
     * is resized.
     */
    const [screenHeight, setScreenHeight] = useState<number>(0);
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenHeight(window.innerHeight));
        setScreenHeight(window.innerHeight);
        return () =>
            window.removeEventListener('resize', () => setScreenHeight(window.innerHeight));
    }, []);

    if (!props.visible) {
        return <></>;
    }

    const searchBarComponent = (
        <div className={styleSheet.searchBarContainer}>
            <input
                placeholder={'Search for Existing Client'}
                onChange={(e: any) => {
                    setSearchText(e.target.value);
                }}
                className={styleSheet.searchBar}
            />
        </div>
    );

    const clientsComponent = (
        <>
            <div
                className={styleSheet.clientsContainer}
                style={clients?.length ? { height: screenHeight - 250 } : {}}
            >
                {clients?.length ? (
                    filteredClients(searchText).map((e: any) => {
                        return (
                            <ClickableWithFeedback
                                onClick={() => {
                                    dispatch(setSelectedClientsRequested(e));
                                    closeDrawerRef.current.closeDrawer();
                                }}
                            >
                                <div className={styleSheet.clientOption}>
                                    {`${e.firstName} ${e.lastName}`}
                                </div>
                            </ClickableWithFeedback>
                        );
                    })
                ) : (
                    <div className={styleSheet.clientOption}>No clients</div>
                )}
            </div>
            <div className={styleSheet.addClientButton}>
                <ClickableWithFeedback onClick={() => setAddModal(true)}>
                    Create New Client
                </ClickableWithFeedback>
            </div>
        </>
    );

    return (
        <>
            <AnimatedDrawer
                onClose={props.onClose}
                ref={closeDrawerRef}
                backgroundOpacity={props.backgroundOpacity}
            >
                <CRMAddClientsModal
                    visible={addModal}
                    onClickClose={() => {
                        setAddModal(false);
                        dispatch(resetResponseDataRequested());
                    }}
                    onClickSubmit={(clients: NewClient[]) => dispatch(addClientsRequested(clients))}
                    loading={addModalLoading}
                    message={clientsAddedResponse}
                    errors={errors}
                />
                <AnimatedDrawerHeader
                    title={'Add a Client'}
                    onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
                />
                {!loading ? (
                    <>
                        {searchBarComponent}
                        {clientsComponent}
                    </>
                ) : (
                    <div className={styleSheet.loaderContainer}>
                        <Loader size={100} />
                    </div>
                )}
            </AnimatedDrawer>
        </>
    );
};
export default AddClientDrawer;
