import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { getSearchedClients } from '../CRM/selectors';
import { getListings } from '../Listings/selectors';
import { ClickableWithFeedback, Typography } from '../../../components';

interface Props {
    type: 'listSide' | 'buySide';
    onChange: Function;
}

const ShowingsFilter = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const clients = useSelector(getSearchedClients);

    const listings = useSelector(getListings);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {props.type === 'buySide'
                ? clients?.fetchedClients?.map((client: any) => {
                      const displayText = client.firstName + ' ' + client.lastName;
                      return (
                          <ClickableWithFeedback
                              onClick={() =>
                                  props.onChange({
                                      type: 'consumerId',
                                      id: client?.stitchUserId,
                                      displayText,
                                  })
                              }
                              className={styleSheet.filterCard}
                          >
                              <Typography textStyle={'p1'}>{displayText}</Typography>
                          </ClickableWithFeedback>
                      );
                  })
                : listings?.map((listing: any) => {
                      const { address } = listing;
                      const displayText = `${address?.full} ${address?.city}, ${address?.state} ${address?.postalCode}`;
                      return (
                          <ClickableWithFeedback
                              onClick={() =>
                                  props.onChange({
                                      type: 'listingId',
                                      id: listing?._id?.toString(),
                                      displayText,
                                  })
                              }
                              className={styleSheet.filterCard}
                          >
                              <Typography textStyle={'p1'}>{displayText}</Typography>
                          </ClickableWithFeedback>
                      );
                  })}
        </div>
    );
};
export default ShowingsFilter;
