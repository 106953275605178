// Organization is by color group first (ex. blue, lightBlue will go together)
// After that please organize alphabetically.

export default {
    palette: {
        white: '#ffffff',
        black: '#000000',
        background: '#F6F6F6',

        // greys ========================
        lightGrey: '#BCBCBC',
        separatorGrey: '#E9E9E9',
        grey: '#D3D3D3',
        mediumGrey: '#9A9A9A',
        darkGrey: '#3A3A3A',
        dividerGrey: '#CCCCCC',
        // ==============================

        blue: '#48A4FF',
        lightBlue: 'rgba(72, 164, 255, 0.15)',

        green: '#2FD2A8',
        lightGreen: 'rgba(47, 210, 168, 0.15)',

        orange: '#FF8238',
        lightOrange: 'rgba(255, 130, 56, 0.15)',

        purple: '#986DF4',
        lightPurple: 'rgba(152, 109, 244, 0.15)',

        red: '#FF4343',
        lightRed: 'rgba(255, 67, 67, 0.15)',

        transparentBlack: 'rgba(0, 0, 0, 0.85)',
    },
    typography: {
        '@font-face': [
            {
                fontFamily: 'Poppins-Medium',
                src: 'url(./fonts/Poppins-Medium.ttf)',
            },
        ],
        light: {
            fontFamily: 'Poppins'
        },
        medium: {
            fontFamily: 'Poppins-medium',
        },
        semiBold: {
            fontFamily: 'Poppins-semiBold',
        },
        bold: {
            fontFamily: 'Poppins-bold',
        },
    },
    paddingHorizontal: (value: number) => {
        return {
            paddingLeft: value,
            paddingRight: value,
        };
    },
    paddingVertical: (value: number) => {
        return {
            paddingTop: value,
            paddingBottom: value,
        };
    },
    marginHorizontal: (value: number) => {
        return {
            marginLeft: value,
            marginRight: value,
        };
    },
    marginVertical: (value: number) => {
        return {
            marginTop: value,
            marginBottom: value,
        };
    },
    commonShadow: {
        filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1))',
    },
    cardShadow: {
        filter: 'drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.05))',
    },
    rowStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    slightShadow: {
        filter: 'drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.04))',
    },
    commonBottomBorder: {
        borderWidth: 0,
        borderColor: '#E9E9E9',
        borderBottomWidth: 1,
        borderStyle: 'solid',
    },
};
