import { all, call } from 'redux-saga/effects';
import initSaga from '../init/saga';
import authSaga from '../domains/auth/sagas';
import calendarSaga from '../domains/main/Calendar/sagas';
import dash from '../domains/main/Dashboard/sagas';
import myListings from '../domains/main/MyListings/saga';
import listings from '../domains/main/Listings/saga';
import listingDetailsScreen from '../domains/main/ListingDetailsScreen/saga';
import showingDetailsScreen from '../domains/main/ShowingDetailsScreen/saga';
import myShowings from '../domains/main/MyShowings/saga';
import profileSaga from '../domains/main/Profile/saga';
import searchSaga from '../domains/main/SearchListings/saga';
import queuedShowingsSaga from '../domains/main/QueuedShowings/saga';
import Social from '../domains/main/Social/saga';
import BrokerageHub from '../domains/main/Brokerage/saga';
import MapSaga from '../domains/main/Map/saga';
import NewsSaga from '../components/NewsSection/sagas';
import NotificationsSaga from '../domains/main/Notifications/sagas';
import showingSaga from '../domains/main/ShowingRequests/saga';
import CrmSaga from '../domains/main/CRM/saga';
import UnverifiedSaga from '../domains/unverifiedMain/saga';
import ShowingsSaga from '../domains/main/Showings/saga';

export default function* rootSaga() {
    yield all([
        call(initSaga),
        call(authSaga),
        call(calendarSaga),
        call(dash),
        call(myListings),
        call(listings),
        call(listingDetailsScreen),
        call(showingDetailsScreen),
        call(myShowings),
        call(profileSaga),
        call(searchSaga),
        call(queuedShowingsSaga),
        call(Social),
        call(BrokerageHub),
        call(MapSaga),
        call(NewsSaga),
        call(NotificationsSaga),
        call(showingSaga),
        call(CrmSaga),
        call(UnverifiedSaga),
        call(ShowingsSaga),
    ]);
}
