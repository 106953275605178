import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import './swiper.override.css';

import defaultHome from '../../../images/defaultHome.svg';

SwiperCore.use([Navigation]);

export type PropertyPicSwiperProps = {
    images: Array<string>;
};

export const PropertyPicSwiper = (props: PropertyPicSwiperProps) => {
    const [isBroken, setIsBroken] = useState(false);
    const [photos, setPhotos] = useState<any[]>(props.images);
    useEffect(() => {
        if (isBroken) {
            setPhotos([photos[0]]);
        }
    }, [isBroken]);
    return (
        <div className={styles.root}>
            {photos.length !== 1 && !isBroken ? (
                <Swiper navigation loop={true}>
                    {photos?.map((image: any, key: any) => (
                        <SwiperSlide className={styles.swiperSlide} key={key}>
                            <img
                                src={
                                    !isBroken
                                        ? image
                                        : 'https://showingly-image-assets.s3.amazonaws.com/noHomeFound.png'
                                }
                                key={key}
                                className={styles.image}
                                alt={'house-pic'}
                                onError={() => setIsBroken(true)}
                            />
                            <div className={styles.imageCounter}>
                                <p className={styles.imageText}>
                                    {key + 1 + '/' + props.images.length}
                                </p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <img
                    src={'https://showingly-image-assets.s3.amazonaws.com/noHomeFound.png'}
                    className={styles.image}
                    alt={'house-pic'}
                />
            )}
        </div>
    );
};
