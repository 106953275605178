export default (theme: any) => {
    return {
        root: ({ width }: any) => ({
            flex: 1,
            backgroundColor: theme.palette.white,
            paddingBottom: 23,
            marginBottom: 16,
        }),
        button: {
            marginRight: 23,
        },
        seeDetails: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 10,
            marginRight: 26,
        },
        forwardArrow: {
            marginLeft: 5,
        },
        topSection: {
            display: 'flex',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.grey,
            borderBottomStyle: 'solid',
            marginTop: 26,
            justifyContent: 'space-between',
            ...theme.paddingHorizontal(26),
            paddingBottom: 10,
            alignItems: 'center',
            "@media (max-width: 1310px)": {
                flexDirection : 'column'
            }
        },
        midSectionRow: {
            ...theme.marginHorizontal(26),
            ...theme.paddingVertical(16),
            borderBottom: '1px solid ' + theme.palette.separatorGrey,
            display: 'flex',
            alignItems: 'center',
            "@media (max-width: 1310px)": {
                flexDirection : 'column',
                alignItems: 'flex-start'
            }
        },
        midSectionColumn: { flexDirection: 'column', display: 'flex', flex: 2,  "@media (max-width: 1310px)": {
            flexDirection : 'column'
        }},
        iconRow: { display: 'flex', alignItems: 'center' },
        bottomSection: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 23,
            alignItems: 'center',
            justifyContent: 'flex-end',
            "@media (max-width: 1310px)": {
                justifyContent : 'center'
            }
        },
    };
};
