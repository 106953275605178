import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { getUpdateLog } from '../../domains/main/Showings/selectors';

interface modalProps {
    data: any;
    icon: string;
    isOpen: Function;
    icon2?: string;
}

const Modal = (props: modalProps) => {
    const updatelog = useSelector(getUpdateLog);
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const { icon, icon2, isOpen, data } = props;
    const startTime = DateTime.fromJSDate(data.start);
    const endTime = DateTime.fromJSDate(data.end);
    const date = startTime.toFormat('LLLL d, y');
    const time = `${startTime.toFormat('h:mm')}${startTime
        .toFormat('a')
        .toLowerCase()} - ${endTime.toFormat('h:mm')}${endTime.toFormat('a').toLowerCase()}`;
    const address = data.listing.address?.full;
    const ubication = data.listing.address?.postal
        ? '' +
          data.listing.address?.city +
          ',' +
          data.listing.address?.state +
          ' ' +
          data.listing.address?.postal
        : '' + data.listing.address?.city + ',' + data.listing.address?.state;
    let title;
    let subTitle;
    if (!updatelog.updateMSg) {
        switch (data.status) {
            case 'pending_internal':
                title = 'Client Request';
                subTitle = 'Pending Approval';
                break;
            case 'confirmed':
                title = 'Confirmed';
                break;
            case 'logged':
                title = 'Client Request';
                subTitle = 'Logged';
                break;
            case 'Reschedule':
                title = 'Showing Updated';
                break;
            default:
                break;
        }
    } else {
        title = 'Showing Updated';
    }
    return (
        <div className={styleSheet.modalBackground}>
            <div className={styleSheet.modalContainer}>
                <div className={styleSheet.titleCloseBtn}>
                    <button onClick={() => isOpen()}> X </button>
                </div>
                <div className={styleSheet.body}>
                    {updatelog.updateMSg ? (
                        <img className={styleSheet.icon} src={icon2} />
                    ) : (
                        <img className={styleSheet.icon} src={icon} />
                    )}
                    <a className={styleSheet.title}>{title}</a>
                    {subTitle && <a className={styleSheet.subTitle}>{subTitle}</a>}
                    <span className={styleSheet.paragraph}>
                        <hr className={styleSheet.divider} />
                        <a>{time}</a>
                        <br />
                        <a>{date}</a>
                        <br />
                        <hr className={styleSheet.divider} />
                        <a>{address}</a>
                        <br />
                        <a>{ubication}</a>
                    </span>
                </div>
                <div className={styleSheet.footer}>
                    <button onClick={() => isOpen()} className={styleSheet.button}>
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
