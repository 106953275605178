const height = window.innerHeight;

export default (theme) => {
    return {
        root: {
            height: height - 60,
            overflow: 'hidden',
            userSelect: 'none',
        },
        selectionRow: {
            height: 86,
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            paddingLeft: 48,
            paddingRight: 48,
            borderBottom: '1px solid #d3d3d3',
            fontFamily: 'Poppins-SemiBold',
            color: '#9a9a9a',
            fontSize: 16,
            zIndex: 10,
        },
        weekSelector: {
            justifyContent: 'space-between',
            height: 30,
            width: 400,
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
        },
        arrowContainer: {
            width: 30,
            height: 30,
            backgroundColor: '#f6f6f6',
            borderRadius: 5,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            '&:active': {
                opacity: 0.5,
            },
            cursor: 'pointer',
        },
        buttonBox: {
            backgroundColor: '#48a4ff',
            paddingLeft: 35,
            paddingRight: 35,
            paddingTop: 6,
            paddingBottom: 7,
            borderRadius: 5,
        },
        buttonText: {
            color: 'white',
        },
        arrow: {
            height: 17,
            width: 17,
        },
        selectorText: {
            color: 'black',
            // paddingLeft: 100,
            // paddingRight: 100,
        },
        mainContent: {
            display: 'flex',
            flexDirection: 'row',
        },
        leftCalendarColumn: {
            width: 300,
            height,
            borderRight: '1px solid #d3d3d3',
        },
        horizontalSeparator: {
            width: 232,
            height: 1,
            backgroundColor: '#e9e9e9',
            marginLeft: 34,
            marginRight: 34,
            marginTop: 20,
            marginBottom: 20,
        },
        keyHeader: {
            color: '#9a9a9a',
        },
        headerAndScroller: {
            flexDirection: 'column',
            flex: 1,
            display: 'flex',
        },
        header: {
            maxHeight: 105,
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 15,
            paddingRight: 48,
            borderBottom: '1px solid #d3d3d3',
            backgroundColor: '#fff',
            fontFamily: 'Poppins-SemiBold',
            color: '#d3d3d3',
            fontSize: 14,
            zIndex: 101,
            flex: 1,
        },
        mstContainer: {
            width: 85,
            height: 105,
            paddingBottom: 30,
            alignItems: 'flex-end',
            display: 'flex',
        },
        bars: {
            position: 'relative',
            top: -52.5,
            right: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        bar: {
            borderRadius: 1,
            height: 48,
            width: 2,
            backgroundColor: '#e9e9e9',
        },
        daysAndBars: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 105,
            marginTop: 45,
        },
        daysContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            height: 105,
            alignItems: 'center',
        },
        day: {
            display: 'flex',
            flex: 1,
            maxHeight: 60,
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dayText: {
            color: '#9a9a9a',
            fontSize: 18,
        },
        dayNumberText: {
            color: '#9a9a9a',
            fontSize: 26,
        },
        scroller: {
            // flex: 1,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            backgroundColor: '#f6f6f6',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 15,
            paddingRight: 48,
            paddingTop: 12,
            fontFamily: 'Poppins-SemiBold',
            color: '#d3d3d3',
            fontSize: 14,
            position: 'absolute',
            width: window.innerWidth - 564,
            height: height - 263,
            top: 251,
        },
        scrollerNoFlow: {
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            backgroundColor: '#f6f6f6',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 15,
            paddingRight: 48,
            paddingTop: 12,
            fontFamily: 'Poppins-SemiBold',
            color: '#d3d3d3',
            fontSize: 14,
            position: 'absolute',
            width: window.innerWidth - 564,
            height: height - 263,
            top: 251,
        },
        timeLinesContainer: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            display: 'flex',
        },
        timeLines: {
            width: 85,
            height: 80,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            display: 'flex',
        },
        otherLines: {
            position: 'absolute',
            marginTop: 11,
            height: 80,
            width: 85,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        topRow: {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        topRowText: {},
        timeLine: {
            width: 37,
            height: 2,
            borderRadius: 1,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#e9e9e9',
        },
        tinyLine: {
            width: 5,
            height: 2,
            borderRadius: 2,
            backgroundColor: '#d3d3d3',
            // marginTop: 10,
        },
        noLine: {
            width: 5,
            height: 0,
            borderRadius: 2,
            backgroundColor: '#f6f6f6',
        },
        smallLine: {
            width: 10,
            height: 2,
            borderRadius: 2,
            backgroundColor: '#d3d3d3',
            // marginTop: 18,
        },
        bottomNoLine: {
            width: 5,
            height: 1,
            borderRadius: 2,
            // marginTop: 18,
        },
        theGridAndLines: {
            flex: 1,
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: 1375,
        },
        lines: {
            height: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        line: {
            height: 5,
            width: 2,
            borderRadius: 1,
            backgroundColor: '#e9e9e9',
        },
        theGrid: {
            height: 1360,
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            borderLeft: '2px solid #e9e9e9',
            borderTop: '2px solid #e9e9e9',
        },
        gridColumn: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        gridBox: {
            flex: 1,
            borderBottom: '2px solid #e9e9e9',
            borderRight: '2px solid #e9e9e9',
        },
        calendarKeyText: {
            paddingLeft: 35,
            color: '#9a9a9a',
            fontSize: 14,
            fontFamily: 'Poppins-Medium',
        },
        circleTextRow: {
            flexDirection: 'row',
            paddingLeft: 35,
            alignItems: 'center',
            display: 'flex',
            paddingTop: 15,
        },
        green: {
            backgroundColor: '#2fd2a8',
        },
        blue: {
            backgroundColor: '#48a4ff',
        },
        gray: {
            backgroundColor: '#9a9a9a',
        },
        red: { backgroundColor: '#ff4343' },
        purple: {
            backgroundColor: '#986df4',
        },
        black: {
            backgroundColor: '#000',
        },
        circle: {
            height: 16,
            width: 16,
            borderRadius: 16,
            marginRight: 20,
        },
        circleText: {
            color: 'black',
            fontSize: 12,
            fontFamily: 'Poppins-Medium',
        },
        showingLabelText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 12,
            color: 'black',
            paddingLeft: 5,
        },
        showingStatusBar: {
            height: '100%',
            width: 4,
            borderTopLeftRadius: 1,
            borderBottomLeftRadius: 1,
        },
        labelAndTime: {
            flexDirection: 'column',
            display: 'flex',
        },
        clockAndTime: {
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
        },
        timeText: {
            color: '#9a9a9a',
            fontFamily: 'Poppins-Regular',
            fontSize: 12,
            paddingLeft: 4,
        },
        clockIcon: {
            marginLeft: 5,
            height: 12,
            width: 12,
        },
        statusContainer: {
            backgroundColor: 'rgba(72, 164, 255, 0.2)',
            borderRadius: 5,
            height: 24,
            paddingLeft: 10,
            paddingRight: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 15,
            marginTop: 15,
            fontFamily: 'Poppins-SemiBold',
            color: '#d3d3d3',
            fontSize: 14,
            paddingTop: 2,
        },
        statusText: {
            fontFamily: 'Poppins-SemiBold',
            position: 'relative',
            fontSize: 14,
        },
        priceText: {
            fontFamily: 'Poppins-SemiBold',
            fontSize: 24,
            paddingTop: 10,
            marginLeft: 15,
        },
        addressText: {
            fontFamily: 'Poppins-SemiBold',
            fontSize: 16,
            marginLeft: 15,
        },
        secondAddressText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            marginLeft: 15,
        },
        xButton: {
            position: 'absolute',
            cursor: 'pointer',
            top: 15,
            right: 15,
            height: 20,
            width: 20,
            '&:active': {
                opacity: 0.5,
            },
        },
        viewText: {
            color: '#48a4ff',
            fontFamily: 'Poppins-SemiBold',
            fontSize: 14,
            paddingTop: 5,
            marginLeft: 15,
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
            paddingBottom: 15,
        },
        biggerIcon: {
            marginLeft: 15,
            height: 20,
            width: 20,
            marginRight: 11,
        },
        iconAndText: {
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            paddingTop: 10,
            paddingBottom: 5,
        },
        grayText: {
            color: '#9a9a9a',
            fontFamily: 'Poppins-Medium',
            fontWeight: 500,
            fontSize: 16,
        },
        viewShowingContainer: {
            position: 'absolute',
            cursor: 'pointer',
            bottom: 15,
            left: 15,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            '&:active': {
                opacity: 0.5,
            },
        },
        viewShowingText: {
            color: '#48a4ff',
            fontFamily: 'Poppins-SemiBold',
            fontSize: 14,
        },
        sendIcon: {
            paddingLeft: 7,
            height: 20,
            width: 20,
        },
    };
};
