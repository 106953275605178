import { createSelector } from 'reselect';

export const getAuthState = (state: any) => state.auth || {};
export const getProfileState = (state: any) => state.profile || {};

/**
 * Get user object returned from Stitch
 * @param state
 * @returns {{}}
 */
export const getStitchUser = createSelector(getAuthState, (auth) => auth.stitchUser || {});

/**
 * Get user object returned from Stitch
 * @param state
 * @returns {{}}
 */
export const getStitchUserId = createSelector(
    getStitchUser,
    (stitchUser) => stitchUser?.id || null,
);

/**
 * Get general Auth Loading status
 * @param state
 * @returns {*}
 */
export const isAuthLoading = createSelector(getAuthState, (auth) => auth.loading);

export const getClient = createSelector(getAuthState, (auth) => auth.client || null);

export const getLoggedInStatus = createSelector(getClient, (client) => {
    if (client) {
        return client?.auth?.user?.isLoggedIn || false;
    }
    return false;
});

export const getAppInitialization = createSelector(getAuthState, (auth) => auth.initializing);

export const getInitUserLocation = createSelector(getAuthState, (auth) => auth.userLocation);

export const getLoginInProgress = createSelector(getAuthState, (auth) => auth.loginInProgress);

export const getIsUserAuthenticated = createSelector(getAuthState, (auth) => auth.isAuthenticated);

export const getAgentRecord = createSelector(getAuthState, (auth) => auth.agent);

export const getLoginRejected = createSelector(getAuthState, (auth) => auth.loginRejected);

export const getMarkets = createSelector(getAuthState, (auth) => auth.markets);

export const getEntryAgents = createSelector(getAuthState, (auth) => auth.entryAgents);

export const getPendingUser = createSelector(getAuthState, (auth) => auth.pendingUser || {});

export const getPendingPhoneNumber = createSelector(
    getAuthState,
    (auth) => auth.pendingUser.phoneNumber || null,
);

export const getAuthErrors = createSelector(getAuthState, (auth) => auth.errors || []);

export const getHasAuthLinkExpiredOrIsInvalid = createSelector(
    getAuthState,
    (auth) => auth.hasAuthLinkExpiredOrIsInvalid,
);

export const getIsUnathenticated = createSelector(getAuthState, (auth) => auth.isUnauthenticated);

export const getTextSent = createSelector(getAuthState, (auth) => auth.appLinkSent);

export const getVerifyingCode = createSelector(getAuthState, (auth) => auth.verifyingCode);

export const getAgentObject = createSelector(getAuthState, (auth) => auth.agent);

export const getAuthMarket = createSelector(getAuthState, (auth) => auth.authMarket);

export const getAuthAgent = createSelector(getAuthState, (auth) => auth.authAgent);

export const getPassReset = createSelector(getAuthState, (auth) => auth.passResetEmailSent);

export const getIsSendingPasswordResetEmail = createSelector(
    getAuthState,
    (auth) => auth.isSendingPasswordResetEmail,
);

export const getVerified = createSelector(getAuthState, (auth) => auth.newUserVerified);

export const getPasswordResetActive = createSelector(
    getAuthState,
    (auth) => auth.passwordResetActive,
);
