//
export const freePlanDescription: any = [
    'Home Search',
    'Schedule Showings',
    // 'Batch Schedule Showings',
    // 'Route Builder',
    'Mobile/Web Applications',
    // 'Client Mobile Application',
    // 'View Client Favorites',
    'Showing/Listing Notes',
    '1 active listing',
    'Lockbox integrations',
];

export const professionalPlanDescription: any = [
    'All Features in Free Plan',
    'Unlimited Active Listings',
    'Call Center for Buyer Agents',
    'Lockbox Integrations',
    'Automatic Feedback',
    'Seller Approval (App/Text/Email)',
    'Offer Management (Coming Soon)',
    // 'Non-Member Showing & Offer Link',
    'Non-Member Showing Link',
    'Non-Member Offer Link (Coming Soon)',
    // 'Itinerary Sharing',
    'Approve/Deny by Text and Email',
    'Message Blast Buyer Agents',
];

export const executivePlanDescription: any = [
    [
        'All Features in Free Plan',
        'Client mobile app',
        'Connect w/ client',
        'See client favorited homes',
        'See client left feedback on listings',
        'Batch scheduling',
    ],
    //Extended version for the ReadMore page
    [
        'Home search',
        'Schedule showings',
        'Mobile/web applications',
        'Showing/listing feedback',
        'Unlimited active listings',
        'Lockbox integrations',
        'Client mobile app',
        'Connect w/ client',
        'See client favorited homes',
        'See client left feedback on listings',
        'Batch scheduling',
        'Seller approval (App/Text/Email)',
        'Showing link for all listings',
        'Approve/Deny showings by text and email',
        'Unlimited active listings',
        'Add Co-Listing agents',
        'Non-MLS Showing link',
        'Route optimization (coming soon)',
        'Brokerage integrated property scheduling (coming soon)',
        'Brokerage in house showing help (coming soon)',
    ],
];

export const plans = [
    {
        title: 'Free',
        planName: 'Starter Plan',
        price: 'For a Lifetime',
        plan: freePlanDescription,
        tier: 0,
        description: 'Perfect plan for beginners',
    },
    {
        title: 'Professional',
        planName: 'Pro Plan',
        price: '$20/mo',
        plan: professionalPlanDescription,
        tier: 1,
        description: '',
    },
    {
        title: 'Executive',
        planName: 'Premium Plan',
        price: '$40.00',
        plan: executivePlanDescription[0],
        tier: 2,
        description: 'Perfect plan for producers',
    },
];
export const plansExtended = [
    {
        title: 'Free',
        planName: 'Starter Plan',
        price: 'For a Lifetime',
        plan: freePlanDescription,
        tier: 0,
        description: 'Perfect plan for beginners',
    },
    {
        title: 'Professional',
        planName: 'Pro Plan',
        price: '$20/mo',
        plan: professionalPlanDescription,
        tier: 1,
        description: '',
    },
    {
        title: 'Executive',
        planName: 'Premium Plan',
        price: '$40.00',
        plan: executivePlanDescription[1],
        tier: 2,
        description: 'Perfect plan for producers',
    },
    {
        title: 'Showingly+',
        planName: 'Me Premium Plan',
        price: '$40.00',
        plan: executivePlanDescription[1],
        tier: 2,
        description: 'Perfect plan for producers',
    },
];
