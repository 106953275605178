// Constants

export enum MY_LISTINGS_ACTION {
    Fetch = '[MyListings] Fetch',
    Search = '[MyListings] Search ',
}

export enum UNCONNECTED_LISTINGS_ACTION {
    Fetch = '[UnconnectedListings] Fetch',
}

export enum SHOWING_REQUESTS_ACTION {
    Fetch = '[ShowingRequests] Fetch',
}

export enum TEXT_SEARCH_LISTINGS_ACTION {
    Fetch = '[TextSearchListings] Fetch',
}

export enum SHOWING_HISTORY_ACTION {
    Fetch = '[ShowingHistory] Fetch',
    Refresh = '[ShowingHistory] Refresh',
    Clear = '[ShowingHistory] Clear',
}

export enum MESSAGE_BLAST_ACTION {
    Fetch = '[MessageBlast] Fetch',
}
export enum LISTING_RECIPIENTS_ACTION {
    Fetch = '[RecipientsList] Fetch'
}
export enum BLAST_MESSAGE_ACTION {
    Send = '[BlastMessage] Send',
}

export enum MESSAGE_BLAST_ACTION {
    Send = '[MessageBlast] Send',
    ResetSuccess = '[MessageBlast] Reset Success',
}

export enum SHOWING_REVIEW_ACTION {
    Fetch = '[Showing Reviews] Fetch Reviews',
}

export const AGENT_SEARCH_ACTION = 'AGENT_SEARCH_ACTION';
export const AGENT_TEXT_SEARCH_ACTION = 'AGENT_TEXT_SEARCH_ACTION';

export const CLIENT_FETCH_ACTION = 'CLIENT_FETCH_ACTION';

export const CLIENT_ADD_ACTION = 'CLIENT_ADD_ACTION';
export type ClientData = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

export const MESSAGE_BLAST_CHARACTER_LIMIT = 250;

export const CONNECT_LISTING_ACTION = 'CONNECT_LISTING_ACTION';
