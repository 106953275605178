import { STATUS } from '../../../utils/constants';

export enum NOTIFICATION_ACTION {
    FetchNotifications = '[Notifications] Fetch',
    FetchPendingShowings = '[Notifications] Fetch Pending Showings',
}

export const fetchNotificationsRequested = () =>
    <const>{
        type: NOTIFICATION_ACTION.FetchNotifications,
        status: STATUS.Requested,
    };

export const fetchNotificationsSucceeded = (notifications: Array<any>) =>
    <const>{
        type: NOTIFICATION_ACTION.FetchNotifications,
        status: STATUS.Succeeded,
        notifications,
    };

export const fetchNotificationsFailed = (error: string) =>
    <const>{
        type: NOTIFICATION_ACTION.FetchNotifications,
        status: STATUS.Failed,
        error,
    };

type FetchNotifications =
    | ReturnType<typeof fetchNotificationsRequested>
    | ReturnType<typeof fetchNotificationsSucceeded>
    | ReturnType<typeof fetchNotificationsFailed>;
// --------------------------------------------------------

export const fetchPendingShowingsRequested = () =>
    <const>{
        type: NOTIFICATION_ACTION.FetchPendingShowings,
        status: STATUS.Requested,
    };

export const fetchPendingShowingsSucceeded = (pendingShowings: Array<any>) =>
    <const>{
        type: NOTIFICATION_ACTION.FetchPendingShowings,
        status: STATUS.Succeeded,
        pendingShowings,
    };

export const fetchPendingShowingsFailed = (error: string) =>
    <const>{
        type: NOTIFICATION_ACTION.FetchPendingShowings,
        status: STATUS.Failed,
        error,
    };
type FetchPendingShowings =
    | ReturnType<typeof fetchPendingShowingsRequested>
    | ReturnType<typeof fetchPendingShowingsSucceeded>
    | ReturnType<typeof fetchPendingShowingsFailed>;
export type NotificationAction = FetchNotifications | FetchPendingShowings;
