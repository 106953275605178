export default (theme: any) => {
    const MAIN_PADDING = 16;
    const borderStyles = {
        borderBottom: { color: theme.palette.grey, style: 'solid', width: 1 },
    };
    return {
        root: {
            backgroundColor: theme.palette.background,
            justifyContent: 'center',
            minHeight: '100%',
            paddingBottom: 16,
        },
        listingCard: {
            ...theme.marginHorizontal(48),
            ...theme.marginVertical(16),
            backgroundColor: theme.palette.white,
            height: 231,
            borderRadius: 5,
        },
        pageHeader: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(23),
            ...theme.paddingHorizontal(48),
            ...borderStyles,
        },
        cardHeaderContainer: {
            ...borderStyles,
        },
        cardHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            ...theme.paddingHorizontal(MAIN_PADDING),
            ...theme.paddingVertical(10),
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            width: window.innerWidth * 0.1,
            ...theme.marginVertical(10),
        },
        centerVertical: {
            display: 'flex',
            alignItems: 'center',
        },
        tagBox: {
            display: 'flex',
            gap: '1rem',
        },
    };
};
