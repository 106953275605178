export default (theme: any) => {
    return {
        root: {},
        notificationCardContainer: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            ...theme.marginHorizontal(10),
        },
        details: {
            marginLeft: 10,
            paddingTop: 5,
            paddingBottom: 7,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        topRow: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
        },
        titleStyle: {
            ...theme.typography.semiBold,
            color: theme.palette.black,
            fontSize: 16,
        },
        textStyle: {
            flex: 1,
            ...theme.typography.mediumBold,
            color: theme.palette.mediumGrey,
            fontSize: 14,
        },
        timeStyle: {
            color: theme.palette.lightGrey,
            ...theme.typography.mediumBold,
            fontSize: 14,
        },
        viewText: {
            ...theme.typography.mediumBold,
            fontSize: 14,
            color: theme.palette.blue,
            flex: 1,
        },
    };
};
