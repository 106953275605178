export default (theme: any) => {
    return {
        root: {},
        innerContentRoot: {
            backgroundColor: theme.palette.white,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'space-between',
            padding: { left: 48, right: 48 },
        },
        selectAllContainer: {
            width: '100%',
            margin: {
                top: 25,
                bottom: 16,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        allCheck: { height: 34, width: 34, marginBottom: -3, marginRight: 10 },
        cardContainer: {
            backgroundColor: theme.palette.background,
            overflowY: 'scroll',
            width: '100%',
            // margin: { left: 48, right: 48 },
            // paddingLeft: 48,
            display: 'flex',
            flexDirection: 'column',
            ...theme.commonBottomBorder,
            alignItems: 'center',
            paddingBottom: 10,
        },
        scheduleButtonContainer: {
            position: 'fixed',
            bottom: 0,
            width: 637,
            height: 68, // wireframe 88
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        queueCard: {
            backgroundColor: theme.palette.white,
            ...theme.cardShadow,
            width: 637 - 96 - 32 - 10, // width - outer padding(48) - inner margin(16) - padding offset(10)
            marginTop: 16,
            height: 100 - 20, // height - padding offset(10)
            border: {
                width: 0,
                radius: 5,
            },
            display: 'flex',
            flexDirection: 'row',
            padding: { left: 0, right: 10, top: 10, bottom: 10 },
            alignItems: 'center',
        },
        queueCardLeft: {
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 5,
        },
        queueCardRight: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
    };
};
