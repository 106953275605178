import React, { useEffect, useState } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import { getLoading, getSelectedListing, getSelectedView } from '../selectors';
import { useHistory } from 'react-router-dom';
import { fetchSelectedListingRequested, setConfigDetails, changeSelectedView } from '../actions';
import indexTheme from '../../../../theme';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import { formatCurrency } from '../../../../utils/common/transforms';
import Tag from '../../../../components/Tag';
import OverviewSection from '../OverviewSection';
import ListingDetailsComponent from '../../ListingDetailsScreen/ListingDetailsComponent';
import ListingConfigSection from '../ListingConfigSection';
import { Loader } from '../../../../components';
import ButtonComponent from '../../../../components/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { updateShowingsFilter } from '../../Showings/actions';

interface Props {}

const SelectedListingScreen = (props: Props) => {
    const listing = useSelector(getSelectedListing);
    const loading = useSelector(getLoading);
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = history.location;
    const splitHistory = pathname.split('/');
    const listingObjId = splitHistory[splitHistory?.length - 1];

    useEffect(() => {
        if (!listing?._id) {
            dispatch(fetchSelectedListingRequested(listingObjId));
        }
    }, []);
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const selectedView = useSelector(getSelectedView);
    //const [selectedView, setSelectedView] = useState<string>('Overview');
    const selections = ['Overview', 'Listing Details', 'Configuration'];

    const header = (
        <>
            <div className={styleSheet.header}>
                Your Listing
                <div className={styleSheet.selectionContainer}>
                    {selections.map((selection, index) => {
                        return (
                            <>
                                <ClickableWithFeedback
                                    style={
                                        selection === selectedView
                                            ? {
                                                  color: indexTheme.palette.blue,
                                              }
                                            : {}
                                    }
                                    onClick={() => {
                                        dispatch(changeSelectedView(selection));
                                    }}
                                >
                                    {selection}
                                </ClickableWithFeedback>
                                {index !== 2 && <div className={styleSheet.verticalSeparator} />}
                            </>
                        );
                    })}
                </div>
            </div>

            {!loading && (
                <div className={styleSheet.priceHeader}>
                    <div className={styleSheet.leftContent}>
                        <span className={styleSheet.price}>
                            {'$'}
                            {formatCurrency(listing?.listPrice)}
                        </span>
                        <div className={styleSheet.verticalSeparator} />
                        <span className={styleSheet.address}>{listing?.address?.full}</span>
                        <span className={styleSheet.city}>
                            {listing?.address?.city}, {listing?.address?.state}{' '}
                            {listing?.address?.postal}
                        </span>
                        <div className={styleSheet.verticalSeparator} />
                        <div className={styleSheet.tagBox}>
                            <Tag
                                text={
                                    listing?.agentListing?.acceptingShowings
                                        ? 'Showings Enabled'
                                        : 'Showings Disabled'
                                }
                                color={listing?.agentListing?.acceptingShowings ? 'green' : 'red'}
                            />
                            <Tag
                                text={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'Syndication Enabled'
                                        : 'Syndication Disabled'
                                }
                                color={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'green'
                                        : 'red'
                                }
                            />
                        </div>
                    </div>
                    <ButtonComponent
                        fill
                        onClick={() => {
                            const { address } = listing;
                            const displayText = `${address?.full} ${address?.city}, ${address?.state} ${address?.postalCode}`;

                            dispatch(
                                updateShowingsFilter({
                                    type: 'listingId',
                                    id: listing?._id?.toString(),
                                    displayText,
                                }),
                            );
                            history.push('/myShowings/listSide');
                        }}
                        width={257}
                    >
                        Showings & Feedback
                    </ButtonComponent>
                </div>
            )}
        </>
    );
    return (
        <div className={styleSheet.root}>
            {header}
            {loading ? (
                <div className={styleSheet.loadingContainer}>
                    <Loader />
                </div>
            ) : selectedView === 'Overview' ? (
                <OverviewSection listing={listing} />
            ) : selectedView === 'Listing Details' ? (
                <ListingDetailsComponent header={null} listing={listing} />
            ) : (
                <ListingConfigSection
                    onPressEdit={(page: number) => {
                        dispatch(setConfigDetails(listing, page));
                        history.push(`/listings/configure/${listingObjId}`);
                    }}
                    agentListing={listing?.agentListing}
                />
            )}
        </div>
    );
};
export default SelectedListingScreen;
