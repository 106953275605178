import React, { useState, useEffect } from 'react';

import styles from './index.module.css';
import Camera from '../../images/blueCamera.svg';
import indexTheme from '../../theme';
import Typography from '../Typography';
import ClickableWithFeedback from '../ClickableWithFeedback';
import { imageToBase64 } from '../../utils/common';

interface ImageUploadProps {
    handleOnImageUpload: Function;
    buttonText?: string;
    includeCameraIcon?: boolean;
    clearUploadText?: boolean;
    value?: any;
    onRemove?: Function;
}

const ImageUploader = ({
    handleOnImageUpload,
    buttonText = 'Attach an Image (Optional)',
    includeCameraIcon = true,
    clearUploadText = false,
    value,
    onRemove,
}: ImageUploadProps) => {
    const [uploadText, setUploadText] = useState<string | null>(buttonText);

    // Calculate width of input tag so that it always lays perfectly on top of the label.
    const [width, setWidth] = useState<any>(0);
    useEffect(() => {
        if (value?.as) {
            setUploadText(value?.as);
        } else {
            setUploadText(buttonText);
        }
        setWidth(document.getElementById('labelContainer')?.clientWidth);
    }, [document.getElementById('labelContainer')?.clientWidth, value]);

    return (
        <div className={styles.root}>
            <div className={styles.div2}>
                <div className={styles.buttonContainer}>
                    <div id="labelContainer" className={styles.labelContainer}>
                        {includeCameraIcon && (
                            <img
                                alt={'camera'}
                                height={22}
                                width={22}
                                className={styles.cameraIcon}
                                src={Camera}
                            />
                        )}

                        <label htmlFor="file-upload">
                            <Typography textStyle={'p2'} color={indexTheme.palette.blue}>
                                {uploadText}
                            </Typography>
                        </label>
                    </div>
                    <input
                        onChange={(e) => {
                            e.target.files && setUploadText(e.target.files[0].name);
                            e.target.files && handleOnImageUpload(e.target.files[0]);
                        }}
                        type="file"
                        id="file-upload"
                        accept="image/*"
                        className={styles.input}
                        style={{ width: `${width - 10}px` }}
                        disabled={value?.as?.length}
                    />
                </div>
            </div>
            {clearUploadText && uploadText !== buttonText ? (
                <ClickableWithFeedback
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        setUploadText(buttonText);
                        handleOnImageUpload(null);
                        onRemove && onRemove();
                    }}
                >
                    <Typography color={indexTheme.palette.red} textStyle={'p3'}>
                        Remove
                    </Typography>
                </ClickableWithFeedback>
            ) : null}
        </div>
    );
};

export default ImageUploader;
