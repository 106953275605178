import { all, call, put, takeLatest } from 'redux-saga/effects';
import { callStitchFunction } from '../../../store/api/sagas';
import { STATUS } from '../../../utils/constants';
import * as Actions from './actions';
import { LISTINGS_FIND_PROJECTION_FOR_SEARCH } from './constants';
import { PickerOptionsToNumbers } from './FilterDrawer/Utils';

function* fetchMapListings({
    region,
    activeListings,
    filter,
}: ReturnType<typeof Actions.fetchMapListingsRequested>): Generator<any, any, any> {
    var query: any = {
        $and: [{ listPrice: { $gte: filter.minPrice } }, { listPrice: { $lte: filter.maxPrice } }],
    };

    // Sub Types
    if (filter.types.length > 0) {
        // Take the user facing label and transform it to internal subtypes
        const internalSubTypes = filter.types
            .map((type) => {
                switch (type) {
                    case 'House':
                        return ['SingleFamilyResidence', 'Single Family Residence', 'Residential'];
                    case 'Condo':
                        return ['Condominium'];
                    case 'Townhome':
                        return ['Townhouse'];
                    case 'Apartment':
                        return ['Apartment'];
                    case 'Multifamily':
                        return ['MultiFamily', 'Duplex'];
                }
            })
            .flat();

        query.$and.push({ 'property.subTypeText': { $in: [...internalSubTypes] } });
        // If the user has selected "Other" search for all other types as well
        if (filter.types.includes('Other')) {
            query.$and.push({
                'property.subTypeText': {
                    $nin: [
                        'SingleFamilyResidence',
                        'Single Family Residence',
                        'Residential',
                        'Condominium',
                        'Townhouse',
                        'Apartment',
                        'MultiFamily',
                        'Duplex',
                    ],
                },
            });
        }
    }

    // Status
    if (filter.statuses.length > 0) {
        query.$and.push({ 'mlsList.standardStatus': { $in: filter.statuses } });
    }

    // Beds, Baths, Parking
    if (filter.beds !== 'Any') {
        query.$and.push({
            'property.bedrooms': { $gte: PickerOptionsToNumbers[filter.beds as any] },
        });
    }
    if (filter.baths !== 'Any') {
        query.$and.push({
            'property.bathrooms': { $gte: PickerOptionsToNumbers[filter.baths as any] },
        });
    }
    if (filter.parking !== 'Any') {
        query.$and.push({
            'property.parking.spaces': { $gte: PickerOptionsToNumbers[filter.parking as any] },
        });
    }

    // Only listings configured with showingly
    if (filter.onlyConfigured) {
        query['$and'].push({ agentListing: { $exists: true } });
    }

    const statuses: Array<string> = [];

    try {
        const minCoords = [region?.minCoords?.lng, region?.minCoords?.lat];
        const maxCoords = [region?.maxCoords?.lng, region?.maxCoords?.lat];

        const listings = yield call(
            callStitchFunction,
            'getMapDataByGeo',
            query,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.projection,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.sort,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.limit,
            'listings',
            minCoords,
            maxCoords,
        );
        if (listings && listings.length) {
            let listingsWithStringIds = listings.map((d: any) => {
                return {
                    ...d,
                    _id: d._id.toString(),
                };
            });
            let totalListings = [...listingsWithStringIds, ...activeListings];
            let listingSet = totalListings.filter(
                (item, index) =>
                    totalListings.indexOf(totalListings.find((d) => d._id == item._id)) === index,
            );
            yield put(Actions.fetchMapListingsSucceeded(listingSet));
        }
    } catch (error) {
        yield put(Actions.fetchMapListingsFailed([error]));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.MAP_ACTIONS.FetchMapListings &&
                action.status === STATUS.Requested,
            fetchMapListings,
        ),
    ]);
}
