import {
    MY_LISTINGS_ACTION,
    SHOWING_HISTORY_ACTION,
    SHOWING_REQUESTS_ACTION,
    TEXT_SEARCH_LISTINGS_ACTION,
    UNCONNECTED_LISTINGS_ACTION,
    MESSAGE_BLAST_ACTION,
    SHOWING_REVIEW_ACTION,
    AGENT_SEARCH_ACTION,
    CLIENT_FETCH_ACTION,
    CLIENT_ADD_ACTION,
    ClientData,
    AGENT_TEXT_SEARCH_ACTION,
    CONNECT_LISTING_ACTION,
    LISTING_RECIPIENTS_ACTION,
    BLAST_MESSAGE_ACTION,
} from './types';
import { AgentListing, STATUS } from '../../../utils/constants';
import { BSON } from 'realm-web';

export const MY_LISTING_ACTIONS_PREFIX = 'myListings/';

export const CONFIRM_MY_LISTING_REQUEST_REQUESTED =
    MY_LISTING_ACTIONS_PREFIX + 'CONFIRM_MY_LISTING_REQUEST_REQUESTED';
export const CONFIRM_MY_LISTING_REQUEST_SUCCEEDED =
    MY_LISTING_ACTIONS_PREFIX + 'CONFIRM_MY_LISTING_REQUEST_SUCEEDED';
export const CONFIRM_MY_LISTING_REQUEST_FAILED =
    MY_LISTING_ACTIONS_PREFIX + 'CONFIRM_MY_LISTING_REQUEST_FAILED';
export const CONFIRM_MY_LISTING_REQUEST_CLEAR =
    MY_LISTING_ACTIONS_PREFIX + 'CONFIRM_MY_LISTING_REQUEST_CLEAR';
export const FETCH_CONNECTION_PRICING = MY_LISTING_ACTIONS_PREFIX + 'FETCH_CONNECTION_PRICING';
//#region Fetch My Showings

export const fetchShowingReviewsRequested = (listingIds: BSON.ObjectId[]) => {
    return {
        type: SHOWING_REVIEW_ACTION.Fetch,
        status: STATUS.Requested,
        listingIds,
    };
};

export const fetchShowingReviewsSucceeded = (listingReviews: any[] = []) => {
    return {
        type: SHOWING_REVIEW_ACTION.Fetch,
        status: STATUS.Succeeded,
        listingReviews,
    };
};

export const fetchShowingReviewsFailed = (listingReviewError: any[] = []) => {
    return {
        type: SHOWING_REVIEW_ACTION.Fetch,
        status: STATUS.Failed,
        listingReviewError,
    };
};

export type FetchShowingReviews =
    | ReturnType<typeof fetchShowingReviewsRequested>
    | ReturnType<typeof fetchShowingReviewsSucceeded>
    | ReturnType<typeof fetchShowingReviewsFailed>;

export const fetchMyListingsRequested = (agentObjectId: string) => {
    return {
        type: MY_LISTINGS_ACTION.Fetch,
        status: STATUS.Requested,
        agentObjectId: agentObjectId,
    };
};

export const fetchMyListingsSucceeded = (connectedListings: any[] = []) => {
    return {
        type: MY_LISTINGS_ACTION.Fetch,
        status: STATUS.Succeeded,
        connectedListings,
    };
};

export const fetchMyListingsFailed = (error: any) => {
    return {
        type: MY_LISTINGS_ACTION.Fetch,
        status: STATUS.Failed,
        error: error,
    };
};

export type MyListingsActions =
    | ReturnType<typeof fetchMyListingsRequested>
    | ReturnType<typeof fetchMyListingsSucceeded>
    | ReturnType<typeof fetchMyListingsFailed>;

//#endregion

//#region Get Showing Requests

export const fetchShowingRequestsRequested = (agentUserId: BSON.ObjectId) => {
    return {
        type: SHOWING_REQUESTS_ACTION.Fetch,
        status: STATUS.Requested,
        agentUserId,
    };
};

export const fetchShowingRequestsSucceeded = (showingsGroupedByListing: any[] = []) => {
    return {
        type: SHOWING_REQUESTS_ACTION.Fetch,
        status: STATUS.Succeeded,
        showingsGroupedByListing,
    };
};

export const fetchShowingRequestsFailed = (errors: any) => {
    return {
        type: SHOWING_REQUESTS_ACTION.Fetch,
        status: STATUS.Failed,
        errors,
    };
};

export type ShowingRequestActions =
    | ReturnType<typeof fetchShowingRequestsRequested>
    | ReturnType<typeof fetchShowingRequestsSucceeded>
    | ReturnType<typeof fetchShowingRequestsFailed>;

//#endregion

export const confirmMyListingRequestRequested = (
    newStatus: string,
    showingId: any,
    sendLockInfo = false,
    isUnverified = false,
) => {
    return {
        type: CONFIRM_MY_LISTING_REQUEST_REQUESTED,
        newStatus,
        showingId,
        sendLockInfo,
        isUnverified,
    };
};

export const confirmMyListingRequestSucceeded = () => {
    return { type: CONFIRM_MY_LISTING_REQUEST_SUCCEEDED };
};

export const clearMyListingRequest = () => {
    return { type: CONFIRM_MY_LISTING_REQUEST_CLEAR };
};

export const confirmMyListingRequestFailed = (errors: string[] = []) => {
    return { type: CONFIRM_MY_LISTING_REQUEST_FAILED, errors };
};

//#region Text Search Connect Listings

export const textSearchListingsRequested = (searchText: string, markets: any[]) => {
    return {
        type: TEXT_SEARCH_LISTINGS_ACTION.Fetch,
        status: STATUS.Requested,
        searchText,
        markets,
    };
};

export const textSearchListingsSucceeded = (searchResults: any) => {
    return {
        type: TEXT_SEARCH_LISTINGS_ACTION.Fetch,
        status: STATUS.Succeeded,
        searchResults,
    };
};

export const textSearchListingsFailed = (errors = []) => {
    return {
        type: TEXT_SEARCH_LISTINGS_ACTION.Fetch,
        status: STATUS.Failed,
        errors,
    };
};

export type textSearchListingsAction =
    | ReturnType<typeof textSearchListingsRequested>
    | ReturnType<typeof textSearchListingsSucceeded>
    | ReturnType<typeof textSearchListingsFailed>;

//#endregion

//#region Showing History

export const fetchShowingHistoryRequested = (listingId: BSON.ObjectId) => {
    return {
        type: SHOWING_HISTORY_ACTION.Fetch,
        status: STATUS.Requested,
        listingId,
    };
};

export const fetchShowingHistorySucceeded = (history: any = []) => {
    return {
        type: SHOWING_HISTORY_ACTION.Fetch,
        status: STATUS.Succeeded,
        history,
    };
};

export const fetchShowingHistoryFailed = (errors: string[] = []) => {
    return {
        type: SHOWING_HISTORY_ACTION.Fetch,
        status: STATUS.Failed,
        errors,
    };
};

export const clearShowingHistory = () => {
    return {
        type: SHOWING_HISTORY_ACTION.Clear,
    };
};

export const setValForPullToRefreshLoaderHistory = (isRefreshing: boolean) => {
    return {
        type: SHOWING_HISTORY_ACTION.Refresh,
        isRefreshing,
    };
};

export type showingHistoryAction =
    | ReturnType<typeof fetchShowingHistoryRequested>
    | ReturnType<typeof fetchShowingHistorySucceeded>
    | ReturnType<typeof fetchShowingHistoryFailed>
    | ReturnType<typeof setValForPullToRefreshLoaderHistory>;
//#region Message Blast

export const resetMessageBlastSuccess = () => {
    return {
        type: MESSAGE_BLAST_ACTION.ResetSuccess,
    };
};

//#endregion

export const fetchAgentRequested = () =>
    <const>{
        type: AGENT_SEARCH_ACTION,
        status: STATUS.Requested,
    };

export const fetchAgentSucceeded = (agentData: any) =>
    <const>{
        type: AGENT_SEARCH_ACTION,
        status: STATUS.Succeeded,
        agentData,
    };

export const fetchAgentFailed = (errors: string[] = []) =>
    <const>{
        type: AGENT_SEARCH_ACTION,
        status: STATUS.Failed,
        errors,
    };

export const fetchAllAgentsClientsRequested = () =>
    <const>{
        type: CLIENT_FETCH_ACTION,
        status: STATUS.Requested,
    };

export const fetchAllAgentsClientsSucceeded = (clients: any[]) =>
    <const>{
        type: CLIENT_FETCH_ACTION,
        status: STATUS.Succeeded,
        clients,
    };

export const fetchAllAgentsClientsFailed = (errors: string[] = []) =>
    <const>{
        type: CLIENT_FETCH_ACTION,
        status: STATUS.Failed,
        errors,
    };

export const addClientRequested = (clientData: ClientData) =>
    <const>{
        type: CLIENT_ADD_ACTION,
        status: STATUS.Requested,
        clientData,
    };

export const addClientSucceeded = (response: any) =>
    <const>{
        type: CLIENT_ADD_ACTION,
        status: STATUS.Succeeded,
        response,
    };

export const addClientFailed = (errors: string[] = []) =>
    <const>{
        type: CLIENT_ADD_ACTION,
        status: STATUS.Failed,
        errors,
    };

export const addClientClear = () =>
    <const>{
        type: CLIENT_ADD_ACTION,
        status: STATUS.Clear,
    };

export type addClientAction =
    | ReturnType<typeof addClientRequested>
    | ReturnType<typeof addClientSucceeded>
    | ReturnType<typeof addClientFailed>;

export const searchAgentRequested = (agentTextSearch: string) =>
    <const>{
        type: AGENT_TEXT_SEARCH_ACTION,
        status: STATUS.Requested,
        agentTextSearch,
    };

export const searchAgentSucceeded = (response: any) =>
    <const>{
        type: AGENT_TEXT_SEARCH_ACTION,
        status: STATUS.Succeeded,
        response,
    };

export const searchAgentFailed = (errors: string[] = []) =>
    <const>{
        type: AGENT_TEXT_SEARCH_ACTION,
        status: STATUS.Failed,
        errors,
    };

export type searchAgentAction =
    | ReturnType<typeof searchAgentRequested>
    | ReturnType<typeof searchAgentSucceeded>
    | ReturnType<typeof searchAgentFailed>;

export const connectListingRequested = (
    agentListing: AgentListing,
    connectionPricing: any,
    paymentMethod: any,
    isUpdating: boolean,
    mlsList: any,
    isNewCard: boolean,
) =>
    <const>{
        type: CONNECT_LISTING_ACTION,
        status: STATUS.Requested,
        agentListing,
        connectionPricing,
        paymentMethod,
        isUpdating,
        mlsList,
        isNewCard,
    };

export const connectListingSucceeded = () =>
    <const>{
        type: CONNECT_LISTING_ACTION,
        status: STATUS.Succeeded,
    };

export const connectListingFailed = (errors: string[] = []) =>
    <const>{
        type: CONNECT_LISTING_ACTION,
        status: STATUS.Failed,
        errors,
    };

export const connectListingClear = () =>
    <const>{
        type: CONNECT_LISTING_ACTION,
        status: STATUS.Clear,
    };

export const fetchConnectionPricingRequested = (listingId: string) =>
    <const>{
        type: FETCH_CONNECTION_PRICING,
        status: STATUS.Requested,
        listingId,
    };

export const fetchConnectionPricingSucceeded = (listingConnectionPrice: any) =>
    <const>{
        type: FETCH_CONNECTION_PRICING,
        status: STATUS.Succeeded,
        listingConnectionPrice,
    };

export const fetchConnectionPricingFailed = (errors: any) =>
    <const>{
        type: FETCH_CONNECTION_PRICING,
        status: STATUS.Failed,
        errors,
    };

export type connectListingAction =
    | ReturnType<typeof connectListingRequested>
    | ReturnType<typeof connectListingSucceeded>
    | ReturnType<typeof connectListingClear>
    | ReturnType<typeof connectListingFailed>;
