import { DateTime, Interval } from 'luxon';

const formatDateType = (minutes: any) => {
    const hours = minutes / 60;

    if (hours >= 8760) {
        return 'years';
    } else if (hours >= 730) {
        return 'months';
    } else if (hours >= 24) {
        return 'days';
    } else if (hours >= 1) {
        return 'hours';
    } else {
        return 'minutes';
    }
};
export const getDateDifference = (connectionDate: any) => {
    const date = DateTime.fromJSDate(connectionDate);
    const curr = DateTime.local();
    const interval = Interval.fromDateTimes(date, curr);
    const minutes = interval.length('minutes');
    const formatType = formatDateType(minutes);

    const duration = Math.floor(interval.length(formatType));
    const type =
        formatType === 'minutes' ? 'min' : duration === 1 ? formatType.slice(0, -1) : formatType;

    return {
        duration,
        type,
    };
};

const sortDates = (clients: Array<any>, filter: number, returnVal: number) => {
    var sortedClients = [...clients];

    sortedClients.sort((a: any, b: any) => {
        const clientA: any = new Date(a[Object.keys(a)[filter]] || 0);
        const clientB: any = new Date(b[Object.keys(b)[filter]] || 0);

        if (clientA > clientB) return returnVal;
        if (clientA < clientB) return -returnVal;
        return 0;
    });

    return sortedClients;
};

const sortStrings = (clients: Array<any>, filter: number, returnVal: number) => {
    var sortedClients = [...clients];
    sortedClients.sort((a: any, b: any) => {
        let clientA: any =
            typeof a[Object.keys(a)[filter]] === 'object'
                ? a[Object.keys(a)[filter]].data
                : a[Object.keys(a)[filter]];
        let clientB: any =
            typeof b[Object.keys(b)[filter]] === 'object'
                ? b[Object.keys(b)[filter]].data
                : b[Object.keys(b)[filter]];

        clientA = typeof clientA === 'string' ? clientA?.toUpperCase() : clientA;
        clientB = typeof clientB === 'string' ? clientB?.toUpperCase() : clientB;

        if (clientA > clientB) return returnVal;
        if (clientA <= clientB) return -returnVal;
        return 0;
    });
    return sortedClients;
};

export const sortRows = (filter: number, isAscending: boolean, clients: Array<any>) => {
    const returnVal = isAscending ? 1 : -1;

    // if (filter === 5 || filter === 6) {
    //     return sortStrings(clients, filter + 1, returnVal);
    // } else {
    return sortStrings(clients, filter + 1, returnVal);
    // }
};

export const filterClients = (text: string, clients: Array<any>) => {
    if (!text?.length || !clients?.length) return clients;
    const filter: string = text.toLocaleLowerCase();
    const sorted: any = clients.filter((a: any) => {
        var name = `${a.firstName} ${a.lastName}`;
        name = name.toLocaleLowerCase();
        return name.includes(filter);
    });

    return sorted;
};
