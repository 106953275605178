import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import indexTheme from '../../theme';

interface Props {
    title: string;
    extraTitleContent?: any;
}
const Header = ({ title, extraTitleContent }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div className={styleSheet.pageHeader}>
            <Typography textStyle={'h4'} color={indexTheme.palette.mediumGrey}>
                {title}
            </Typography>
            {extraTitleContent ? (
                <>
                    <div className={styleSheet.verticalSeparator} />
                    {extraTitleContent}
                </>
            ) : null}
        </div>
    );
};
export default Header;
