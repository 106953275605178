import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { SlideOutDrawer } from 'web-lib';
import ClickableWithFeedback from '../ClickableWithFeedback';
import {
    getPaymentMethods,
    getStripeActionStatus,
    getSubscription,
    isPaymentLoading,
} from '../../domains/main/Profile/selectors';
import { AnimatedModal, Typography } from '..';
import XButton from '../../images/greyBoxXButton.svg';
import ButtonComponent from '../ButtonComponent';
import PaymentDropDownSelect from '../PaymentDropDownSelect';
import {
    addCardAndSubscribeRequested,
    subscribeRequested,
} from '../../domains/main/Profile/actions';
import { Loader } from 'web-lib';
import FailureIcon from '../../images/lightFailure.svg';
import SuccessIcon from '../../images/lightSuccess.svg';

interface Props {
    goBack: Function;
    planId: any;
}

const PaymentModal = ({ goBack, planId }: Props) => {
    const paymentMethods = useSelector(getPaymentMethods);
    const purchasing = useSelector(isPaymentLoading);
    const stripeActionStatus = useSelector(getStripeActionStatus);
    const subscription = useSelector(getSubscription);
    const dispatch = useDispatch();
    const addCardAndUpgrade = (paymentMethodId: string, priceId: string, name: any, zip: any) =>
        dispatch(addCardAndSubscribeRequested(paymentMethodId, priceId, name, zip));
    const upgrade = (paymentMethodId: string, priceId: string) =>
        dispatch(subscribeRequested(paymentMethodId, priceId));

    // cannot be in styles file bc passed as style, not className
    const animatedModalStyle = {
        maxWidth: 812,
        maxHeight: 600,
        minHeight: 450,
        minWidth: 609,
        height: '80vh',
        width: '80vw',
        top: null,
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
    };

    const ref: any = useRef(null);
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);

    const [newPaymentMethodInfo, setNewPaymentMethodInfo] = useState<any>(null);
    const [purchaseClicked, setPurchaseClicked] = useState<boolean>(false);

    return (
        <AnimatedModal
            set={() => {}}
            modalVisible={true}
            backgroundEventDisabled={true}
            style={animatedModalStyle}
            ref={ref}
        >
            <div className={styleSheet.header}>
                <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                    Payment
                </Typography>
                <img src={XButton} className={styleSheet.xButton} onClick={() => goBack()} />
            </div>
            {purchasing ? (
                <div className={styleSheet.loadingContainer}>
                    <div style={{ height: 70, marginBottom: 10 }}>
                        <Loader
                            icon={
                                'https://showingly-image-assets.s3.amazonaws.com/showinglyGeneralLoader.gif'
                            }
                        />
                    </div>
                    <Typography textStyle="h3" color={theme.palette.black}>
                        Processing Payment
                    </Typography>
                </div>
            ) : stripeActionStatus === 'failed' ? (
                <div className={styleSheet.statusContainer}>
                    <img src={FailureIcon} style={{ width: 90, height: 90, marginBottom: 10 }} />
                    <Typography
                        textStyle="h3"
                        color={theme.palette.black}
                        style={{ marginLeft: 60, marginRight: 60, paddingTop: 10 }}
                    >
                        There was a problem with your payment, please contact support.
                    </Typography>
                </div>
            ) : subscription?.status === 'active' && subscription?.priceId === planId ? (
                <div className={styleSheet.statusContainer}>
                    <img src={SuccessIcon} style={{ width: 90, height: 90, marginBottom: 10 }} />
                    {subscription?.plan ? (
                        <Typography
                            textStyle="h3"
                            color={theme.palette.black}
                            style={{ marginLeft: 60, marginRight: 60, paddingTop: 10 }}
                        >
                            Successfully upgraded to the {subscription.plan} Plan. You can view Plan
                            details in your Account Settings.
                        </Typography>
                    ) : (
                        <Typography
                            textStyle="h3"
                            color={theme.palette.black}
                            style={{ marginLeft: 60, marginRight: 60, paddingTop: 10 }}
                        >
                            Your Plan was upgraded successfully. You can view Plan details in your
                            Account Settings.
                        </Typography>
                    )}
                </div>
            ) : (
                <>
                    <div className={styleSheet.selectContainer}>
                        <PaymentDropDownSelect
                            paymentMethods={paymentMethods}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                            setNewPaymentMethodInfo={setNewPaymentMethodInfo}
                            enterNewSelected={selectedPaymentMethod?.value === 'add'}
                        />
                    </div>
                    <div className={styleSheet.footer}>
                        <ButtonComponent
                            fill
                            color={theme.palette.blue}
                            className={styleSheet.button}
                            onClick={() => {
                                setPurchaseClicked(true);
                                if (selectedPaymentMethod?.value === 'add') {
                                    addCardAndUpgrade(
                                        newPaymentMethodInfo.pmId,
                                        planId,
                                        newPaymentMethodInfo.fullName,
                                        newPaymentMethodInfo.zipCode,
                                    );
                                } else {
                                    upgrade(selectedPaymentMethod?.value, planId);
                                }
                            }}
                            disabled={
                                (!newPaymentMethodInfo &&
                                    (selectedPaymentMethod?.value === 'add' ||
                                        !selectedPaymentMethod)) ||
                                purchaseClicked
                            }
                            height={48}
                        >
                            <Typography
                                textStyle="h4"
                                color={theme.palette.white}
                                style={{ fontSize: 18 }}
                            >
                                {purchaseClicked ? 'Processing...' : 'Purchase'}
                            </Typography>
                        </ButtonComponent>
                    </div>
                </>
            )}
        </AnimatedModal>
    );
};

export default PaymentModal;
