import React, { useState } from 'react';
import { YourPlan } from 'web-lib';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    getSubscribedPrice,
    getPrices,
    getProfileData,
    getSubscriptionEndDate,
} from '../../domains/main/Profile/selectors';
import {
    setSubscriptionPrice,
    setPaymentModalType,
    payStripeInvoiceRequested,
} from '../../domains/main/Profile/actions';
import styles from './index.module.css';
import { getIsSubscribed } from '../../domains/main/Profile/selectors';
import {
    YourPlanButtonStyles,
    YourPlanStyling,
    featureListFree,
    featureListPlan,
} from '../../domains/main/Profile/ProfileScreenOld/common';
import { ButtonV4 } from 'web-lib';
import { useHistory } from 'react-router';

type DelinquentModalProps = {
    closeModal: Function;
};
type ProfileData = {
    name?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    brokerage: any;
    isSuperAdmin: boolean;
};
const DelinquentModal = ({ closeModal }: DelinquentModalProps) => {
    const subscribedPrice: string = useSelector(getSubscribedPrice);
    const profileData: ProfileData = useSelector(getProfileData);
    const prices: any = useSelector(getPrices);
    const isSubscribed: boolean = useSelector(getIsSubscribed);
    const subscriptionEndDate: string = useSelector(getSubscriptionEndDate);
    const subscriptionCancelled: boolean = useSelector(
        (state: any) => state.profile.subscriptionCancelled,
    );
    const subscriptionId: string = useSelector((state: any) => state.profile?.subscription?.id);

    const dispatch = useDispatch();
    const setModalTypeChange: Function = () => dispatch(setPaymentModalType('change'));
    const payCurrentBall: Function = () => dispatch(payStripeInvoiceRequested());

    const [planType, setPlanType] = useState('monthly');
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    return visible ? (
        <div className={styles.modalRoot}>
            <div className={styles.textContainer}>
                <span className={styles.text}>
                    There was a problem with the most recent payment for your Agent+ plan. Please
                    update your payment method.
                </span>
            </div>
            <ButtonV4
                onClick={() => {
                    // payCurrentBall();
                    closeModal(true);
                    setModalTypeChange();
                }}
                width={'280px'}
                text="Update Payment Method"
                color="#2FD2A8"
            />
            <span onClick={() => closeModal(false)} className={styles.blueText}>
                Continue without Agent+
            </span>
        </div>
    ) : null;
};

export default DelinquentModal;
