import { retry } from 'redux-saga/effects';
import { STATUS } from '../../../store/api/constants';
import {
    GET_PROFILE_DATA_REQUESTED,
    GET_PROFILE_DATA_SUCCEEDED,
    GET_PROFILE_DATA_FAILED,
    UPDATE_PROFILE_DATA_REQUESTED,
    UPDATE_PROFILE_DATA_SUCCEEDED,
    UPDATE_PROFILE_DATA_FAILED,
    PROFILE_ACTIONS,
    LINKED_LICENSE_ACTIONS,
    UPDATE_UPGRADE_MODAL_VISIBILITY,
} from './actions';

export const initialState = {
    addingBrokerageToAgent: false,
    loading: false,
    searchedBrokerages: [],
    profileData: {
        profilePhotoUpload: {
            path: '',
            as: '',
            uri: '',
        },
    },
    aboutUpdateLoading: false,
    brokerageData: {},
    error: [],
    errors: [],
    Plus: true, // setting to true for all users until this can be implemented properly
    subscribingToPlus: false, // loading for signing up for Agent+
    stripeActionStatus: null,
    paymentLoading: false,
    modalType: 'add',
    // if we update profileData directly, the entire app has to essentially refresh and the user is brought to /search
    // we cannot change this logic as it affects SSO and there's too much logic there
    // this object is used to avoid that process all-together
    updatedProfileData: {
        phoneNumber: null,
        about: null,
        paymentMethod: null,
        linkedLicensesAndAccounts: null,
        profilePhotoUpload: {
            path: '',
            as: '',
            uri: '',
        },
    },
    paymentModalType: false,
    paymentError: null,
    aboutUpdateSuccess: true,
    uploadingPhotoSuccessful: true,
    subscriptionPriceId: null,
    currentlySubscribedPriceId: null,
    proPrice: null,
    execPrice: null,
    paymentMethods: {},
    invoiceHistory: {},

    uploadingPhoto: false,
    //Remove payment state
    updatedPaymentMethods: {}, // <-- This exists such that every time anything card related happens, the listed card array changes immediately
    loadingRemovePayment: false,
    removedPayment: false,
    errorRemovingPayment: [],
    subscriptionEndDate: null,
    cancellingSubscription: false,
    subscriptionCancelled: false,
    subscription: null,
    verification: {
        verifyingCode: false,
        newPhoneNumberVerified: false,
        updatePhoneNumberSuccess: false,
        updatingPhoneNumber: false,
        errors: [],
    },

    upgradeModalVisible: false,

    // indication on if the agent successfully linked. Null at first, true if success, false if not
    linkAgentSuccess: null,

    // Linked License reducer state
    sendCode: {
        loading: false,
        succeeded: false,
        error: '',
    },

    verifyCode: {
        loading: false,
        succeeded: false,
        error: '',
    },

    markets: {
        loading: false,
        data: [],
        error: '',
    },

    agents: {
        loading: false,
        data: [],
        error: '',
    },
    subscriptionPaymentMethod: null,
    stripeLink: null,
    stripeOAuthLink: null,
    settingsLoading: false,
    showingsSettings: null,
    pickUpSettings: null,
    socialSettings: null,
    isPickupTutorialComplete: false,
    isDelinquent: false,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case GET_PROFILE_DATA_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_PROFILE_DATA_SUCCEEDED: {
            const { profileData, paymentMethods, invoiceHistory, formattedMarkets } = action;
            return {
                ...state,
                loading: false,
                profileData,
                paymentMethods,
                invoiceHistory,
                formattedMarkets,
                updatedPaymentMethods: paymentMethods,
                subscription: profileData?.subscription,
                subscriptionEndDate: profileData?.subscription?.cancelDateString || null,
                showingsSettings: profileData?.settings?.showings?.showingUpdates || {
                    push: false,
                    email: false,
                },
                pickUpSettings: profileData?.settings?.pickup?.pickupNotifications || {
                    push: false,
                    email: false,
                },
                socialSettings: profileData?.settings?.social || {
                    profileSettings: {
                        privateAccount: false,
                    },
                },
                isPickupTutorialComplete:
                    profileData?.tutorialsCompleted?.includes('pickup') || false,
            };
        }
        case GET_PROFILE_DATA_FAILED: {
            const { errors } = action;
            return {
                ...state,
                errors,
                loading: false,
            };
        }
        case UPDATE_PROFILE_DATA_REQUESTED: {
            return {
                ...state,
                aboutUpdateLoading: true,
                aboutUpdateSuccess: false,
            };
        }
        case UPDATE_PROFILE_DATA_SUCCEEDED: {
            return {
                ...state,
                profileData: action.profileData,
                aboutUpdateLoading: false,
                aboutUpdateSuccess: true,
            };
        }
        case UPDATE_PROFILE_DATA_FAILED: {
            const { errors } = action;
            return {
                ...state,
                errors,
                aboutUpdateLoading: false,
            };
        }

        case PROFILE_ACTIONS.ShowinglyPlus: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        subscribingToPlus: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        Plus: true,
                        subscribingToPlus: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        error: errors,
                        subscribingToPlus: false,
                    };
                }

                default:
                    return state;
                case STATUS.Succeeded: {
                    const { newStatus } = action;
                    return {
                        ...state,
                        Plus: newStatus,
                    };
                }
            }
        }

        case PROFILE_ACTIONS.ShowinglyPlusBrokerage: {
            switch (status) {
                case STATUS.Succeeded: {
                    const { brokerageData } = action;
                    return {
                        ...state,
                        brokerageData,
                    };
                }
            }
        }

        case PROFILE_ACTIONS.BrokerageSearch: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }

                case STATUS.Succeeded: {
                    const { searchedBrokerages } = action;
                    return {
                        ...state,
                        searchedBrokerages,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.UpdateNotifications: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }

                case STATUS.Succeeded: {
                    const { newNotifications } = action;
                    return {
                        ...state,
                        showingsSettings: newNotifications?.showings?.showingUpdates,
                        pickUpSettings: newNotifications?.pickup?.pickupNotifications,
                        socialSettings: newNotifications?.social,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.AddBrokerageToAgent: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        addingBrokerageToAgent: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        addingBrokerageToAgent: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        addingBrokerageToAgent: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.ProfilePhotoUpdate: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        uploadingPhoto: true,
                        uploadingPhotoSuccessful: false,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        uploadingPhoto: false,
                        errors,
                    };
                }
                case STATUS.Succeeded: {
                    const { uri } = action;
                    return {
                        ...state,
                        uploadingPhoto: false,
                        uploadingPhotoSuccessful: true,
                        updatedProfileData: {
                            ...state.updatedProfileData,
                            profilePhotoUpload: {
                                uri,
                            },
                        },
                    };
                }
            }
        }

        case PROFILE_ACTIONS.ErrorHandling: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        errors: [],
                        error: [],
                    };
                }
            }
        }

        case PROFILE_ACTIONS.UpdatePhoneNumber: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        verification: {
                            errors: action.errors,
                            updatingPhoneNumber: true,
                        },
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        updatedProfileData: {
                            ...state.updatedProfileData,
                            phoneNumber: action.newPhoneNumber,
                        },
                        verification: {
                            updatePhoneNumberSuccess: true,
                            updatingPhoneNumber: false,
                        },
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        verification: {
                            errors: action.errors,
                            updatingPhoneNumber: false,
                        },
                    };
                }
            }
        }
        case PROFILE_ACTIONS.SetStripeActionStatus: {
            // status is 0 if updating is initiating
            // status is 1 if updating is completed
            const { stripeActionStatus } = action;

            if (stripeActionStatus === null) {
                return {
                    ...state,
                    stripeActionStatus,
                    paymentError: null,
                };
            }

            return {
                ...state,
                stripeActionStatus,
            };
        }
        case PROFILE_ACTIONS.Subscribe: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        paymentLoading: false,
                        subscriptionEndDate: null,
                        subscription: action.subscription,
                        subscriptionCancelled: false,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        paymentLoading: false,
                        stripeActionStatus: 'failed',
                    };
                }
                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.AddCard: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        paymentLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { paymentError } = action;
                    return {
                        ...state,
                        stripeActionStatus: 'failed',
                        paymentLoading: false,
                        paymentError,
                    };
                }
                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.AddCardAndSubscribe: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        paymentLoading: false,
                        subscriptionEndDate: null,
                        subscription: action.subscription,
                        subscriptionCancelled: false,
                    };
                }
                case STATUS.Failed: {
                    const { paymentError } = action;
                    return {
                        ...state,
                        paymentLoading: false,
                        stripeActionStatus: 'failed',
                        paymentError,
                    };
                }
                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.AddCardAndChangePayment: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        paymentLoading: false,
                        subscriptionEndDate: null,
                        subscriptionCancelled: false,
                        stripeActionStatus: 'success',
                    };
                }
                case STATUS.Failed: {
                    const { paymentError } = action;
                    return {
                        ...state,
                        paymentLoading: false,
                        stripeActionStatus: 'failed',
                        paymentError,
                    };
                }
                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.PaymentModal: {
            const { modalType } = action;
            return {
                ...state,
                paymentModalType: modalType,
            };
        }
        case PROFILE_ACTIONS.SetPrice: {
            const { priceId } = action;
            return {
                ...state,
                subscriptionPriceId: priceId,
            };
        }
        case PROFILE_ACTIONS.SetSubscribedPrice: {
            // status is 0 if updating is initiating
            // status is 1 if updating is completed
            const { priceId } = action;
            return {
                ...state,
                currentlySubscribedPriceId: priceId,
            };
        }
        case PROFILE_ACTIONS.FetchPricing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { proPrice, execPrice } = action;
                    return {
                        ...state,
                        loading: false,
                        proPrice,
                        execPrice,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loading: false,
                        errors,
                    };
                }
                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.setModalType: {
            const { modalType } = action;
            return {
                ...state,
                modalType: modalType,
            };
        }

        case PROFILE_ACTIONS.RemovePayment: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingRemovePayment: true,
                        removedPayment: false,
                    };
                }

                case STATUS.Succeeded: {
                    const { updatedPaymentMethods } = action;
                    return {
                        ...state,
                        paymentMethods: updatedPaymentMethods,
                        loadingRemovePayment: false,
                        removedPayment: true,
                    };
                }

                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingRemovePayment: false,
                        removedPayment: false,
                        errorRemovingPayment: error,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.ResetRemovePayment: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        removedPayment: false,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.FetchPaymentMethods: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        // paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { paymentMethods } = action;
                    return {
                        ...state,
                        // paymentLoading: false,
                        paymentMethods: paymentMethods,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        // paymentLoading: false,
                        errors,
                    };
                }
                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.SetProfileData: {
            const { profileData } = action;
            return {
                ...state,
                profileData: profileData,
            };
        }

        case PROFILE_ACTIONS.CancelSubscription: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        cancellingSubscription: true,
                        settingsLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        cancellingSubscription: false,
                        settingsLoading: false,
                        subscriptionEndDate: action.endDate,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        cancellingSubscriptionErrors: errors,
                        cancellingSubscription: false,
                        settingsLoading: false,
                    };
                }
                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.Verify: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        verification: {
                            ...state.verification,
                            verifyingCode: true,
                        },
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        verification: {
                            ...state.verification,
                            verifyingCode: false,
                            newPhoneNumberVerified: true,
                        },
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        verification: {
                            errors: action.errors,
                            verifyingCode: false,
                        },
                    };
                }

                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.ClearVerification: {
            return {
                ...state,
                verification: {
                    verifyingCode: false,
                    newPhoneNumberVerified: false,
                    updatePhoneNumberSuccess: false,
                    errors: [],
                },
            };
        }

        case PROFILE_ACTIONS.LinkAgentAccounts: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        linkAgentSuccess: true,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        linkAgentSuccess: false,
                    };
                }
            }
        }

        // Linked License reducer state

        case LINKED_LICENSE_ACTIONS.sendCode: {
            const { status } = action;
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        sendCode: {
                            loading: true,
                        },
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        sendCode: {
                            succeeded: true,
                            loading: false,
                        },
                    };
                }

                case STATUS.Failed: {
                    const { sendCodeError } = action;
                    return {
                        ...state,
                        sendCode: {
                            succeeded: false,
                            loading: false,
                            error: sendCodeError,
                        },
                    };
                }
            }
        }

        case LINKED_LICENSE_ACTIONS.verifyCode: {
            const { status } = action;
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        verifyCode: {
                            loading: true,
                        },
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        verifyCode: {
                            succeeded: true,
                            loading: false,
                        },
                    };
                }

                case STATUS.Failed: {
                    const { verifyCodeError } = action;
                    return {
                        ...state,
                        verifyCode: {
                            succeeded: false,
                            loading: false,
                            error: verifyCodeError,
                        },
                    };
                }
            }
        }

        case LINKED_LICENSE_ACTIONS.searchMarkets: {
            const { status } = action;
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        markets: {
                            loading: true,
                        },
                    };
                }

                case STATUS.Succeeded: {
                    const { markets } = action;
                    return {
                        ...state,
                        markets: {
                            loading: false,
                            data: markets,
                        },
                    };
                }

                case STATUS.Failed: {
                    const { marketSearchError } = action;
                    return {
                        ...state,
                        markets: {
                            loading: false,
                            error: marketSearchError,
                        },
                    };
                }

                default:
                    return state;
            }
        }

        case LINKED_LICENSE_ACTIONS.searchAgents: {
            const { status } = action;
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        agents: {
                            loading: true,
                        },
                    };
                }

                case STATUS.Succeeded: {
                    const { entryAgents } = action;
                    return {
                        ...state,
                        agents: {
                            loading: false,
                            data: entryAgents,
                        },
                    };
                }

                case STATUS.Failed: {
                    const { agentSearchError } = action;
                    return {
                        ...state,
                        agents: {
                            loading: false,
                            error: agentSearchError,
                        },
                    };
                }

                default:
                    return state;
            }
        }

        // Let's reset state just to avoid certain potential bugs
        case LINKED_LICENSE_ACTIONS.reset: {
            return {
                ...state,
                sendCode: {
                    loading: false,
                    succeeded: false,
                    error: '',
                },
                verifyCode: {
                    loading: false,
                    succeeded: false,
                    error: '',
                },
                markets: {
                    loading: false,
                    data: [],
                    error: '',
                },
                agents: {
                    loading: false,
                    data: [],
                    error: '',
                },
                linkAgentSuccess: null,
            };
        }

        case LINKED_LICENSE_ACTIONS.updateLicenses: {
            const { updatedMarkets } = action;
            return {
                ...state,
                formattedMarkets: updatedMarkets,
            };
        }

        case PROFILE_ACTIONS.FetchMyBrokerages: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { myBrokerages } = action;
                    return {
                        ...state,
                        myBrokerages,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        loading: false,
                    };
                }
            }
        }

        case PROFILE_ACTIONS.FetchSubscriptionPaymentMethod: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        settingsLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { subscriptionPaymentMethod } = action;
                    return {
                        ...state,
                        subscriptionPaymentMethod,
                        settingsLoading: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        settingsLoading: false,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.ChangePayment: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { subscription = null } = action;
                    return {
                        ...state,
                        subscription,
                        paymentLoading: false,
                        stripeActionStatus: 'success',
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        paymentLoading: false,
                        stripeActionStatus: 'failed',
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.FetchBankAccounts: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        settingsLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { bankAccounts } = action;
                    return {
                        ...state,
                        bankAccounts,
                        settingsLoading: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        settingsLoading: false,
                    };
                }

                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.PayStripeInvoice: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paymentLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        paymentLoading: false,
                        subscriptionEndDate: null,
                        subscriptionCancelled: false,
                        stripeActionStatus: 'success',
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        paymentLoading: false,
                        stripeActionStatus: 'failed',
                    };
                }
                default:
                    return state;
            }
        }

        case PROFILE_ACTIONS.SetStripeLink: {
            const { stripeLink } = action;
            return {
                ...state,
                stripeLink,
            };
        }

        case PROFILE_ACTIONS.SetStripeOAuthLink: {
            const { stripeOAuthLink } = action;
            return {
                ...state,
                stripeOAuthLink,
            };
        }

        case PROFILE_ACTIONS.SetIsDelinquent: {
            const { isDelinquent } = action;
            return {
                ...state,
                isDelinquent,
            };
        }

        case UPDATE_UPGRADE_MODAL_VISIBILITY: {
            return {
                ...state,
                upgradeModalVisible: action.value,
            };
        }

        default:
            return state;
    }
}
