export default (theme: any) => {
    const border = {
        style: 'solid',
        color: theme.palette.separatorGrey,
        width: 1,
    };
    return {
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: border,
            ...theme.paddingVertical(15),
        },
        closeButton: {
            marginRight: 15,
            cursor: 'pointer',
        },
        addressHeader: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 70,
            flexDirection: 'column',
        },
        childrenContainer: {
            ...theme.paddingHorizontal(17),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        showingTimeDetails: {
            borderTop: border,
            borderBottom: border,
            ...theme.paddingVertical(10),
            marginTop: 15,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
        iconAndText: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 5,
        },
        denialReasonContainer: {
            marginTop: 58,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        detailsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 16,
            paddingBottom: 30,
        },
        radioButtonContainer: {
            width: '100%',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.separatorGrey,
            borderTopWidth: 1,
            ...theme.paddingVertical(16),
            display: 'flex',
            justifyContent: 'center',
        },
        bottomButtonContainer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.separatorGrey,
            borderTopWidth: 1,
            ...theme.paddingVertical(16),
            display: 'flex',
            justifyContent: 'center',
        },
        inputBox: {
            backgroundColor: theme.palette.background,
            borderRadius: 5,
            border: 'none',
            ...theme.typography.semiBold,
            width: '100%',
            height: 59,
            alignSelf: 'center',
            justifyContent: 'center',
            resize: 'none',
            ...theme.paddingVertical(11),
            marginTop: 15,
            ...theme.marginHorizontal(15),
            ...theme.paddingHorizontal(15),
        },
        approveDenyButton: {
            ...theme.marginHorizontal(8),
        },
        circularProgressContainer: { position: 'absolute', bottom: '83px', right: '15px' },
        textArea: {
            resize: 'none',
            padding: 15,
            width: '95%',
            height: '293px',
            border: 'none',
            outline: 'none',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 18,
        },
        textAreaContainer: { borderTop: 'none' },
        feedbackDate: {
            width: '100%',
            paddingBottom: 15,
            paddingTop: 15,
            display: 'flex',
            justifyContent: 'center',
        },
        feedbackStarForm: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 16,
        },
        feedbackStarFormRow: { width: '60%', display: 'flex', alignItems: 'center' },
        feedbackSeparator: {
            height: 1,
            ...theme.marginHorizontal(7),
            borderBottom: '2px dotted ' + theme.palette.grey,
            flex: 1,
        },
    };
};
