import {
    FETCH_ALL_SHOWINGS_REQUESTED,
    FETCH_ALL_SHOWINGS_SUCCEEDED,
    FETCH_ALL_SHOWINGS_FAILED,
} from './actions';

export const initialState = {
    // request state
    loading: false,
    allShowings: [],
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case FETCH_ALL_SHOWINGS_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case FETCH_ALL_SHOWINGS_SUCCEEDED: {
            const { allShowings, startOfWeek, endOfWeek } = action;
            return {
                ...state,
                allShowings: [
                    { showings: allShowings, startOfWeek, endOfWeek },
                ],
                loading: false,
            };
        }
        case FETCH_ALL_SHOWINGS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                loading: false,
                errors,
            };
        }
        default:
            return state;
    }
}
