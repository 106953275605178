import React from 'react';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import { Switch } from '../../../../../components';
import iIcon from '../../../../../images/iIcon.svg';
import styles from './styles';

/**
 * The only configured option row
 * @param checked - Whether or not the toggle is selected
 * @param setChecked - Set state function for the selected state value.
 * This component handles flipping the value.
 * Only pass the set state function associated with the checked variable
 */
export const OnlyConfiguredOption = ({
    checked,
    setChecked,
}: {
    checked: boolean;
    setChecked: Function;
}) => {
    const useStyles = createUseStyles(styles);
    const theme: DefaultTheme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div className={styleSheet.configuredRowContainer}>
            <div>
                <span>Only Display Configured Listings</span>
                <img height={13} width={13} src={iIcon} />
            </div>
            <Switch checked={checked} onToggle={() => setChecked(!checked)} />
        </div>
    );
};

/**
 * A light grey line to be used as a break
 */
export const Break = () => {
    const useStyles = createUseStyles(styles);
    const theme: DefaultTheme = useTheme();
    const styleSheet = useStyles({ theme });
    return <div className={styleSheet.break} />;
};

export const RangeRow = ({
    min,
    setMin,
    max,
    setMax,
    options,
    label,
}: {
    min: number;
    setMin: Function;
    max: number;
    setMax: Function;
    options: Array<{ value: number; label: string }>;
    label: string;
}) => {
    const useStyles = createUseStyles(styles);
    const theme: DefaultTheme = useTheme();
    const styleSheet = useStyles({ theme });

    return (
        <div className={styleSheet.priceRangeContainer}>
            <div className={styleSheet.smallGreyText}>{label}</div>
            <select
                className={styleSheet.dropdown}
                value={min}
                onChange={(event) => setMin(parseFloat(event.target.value))}
            >
                <option value={-1} label={'No Min'} />
                {options.map((option) => {
                    return <option value={option.value}>{option.label}</option>;
                })}
            </select>
            <div className={styleSheet.smallGreyText}>{`to`}</div>
            <select
                className={styleSheet.dropdown}
                value={max}
                onChange={(event) => setMax(parseFloat(event.target.value))}
            >
                {options.map((option) => {
                    return <option value={option.value}>{option.label}</option>;
                })}
                <option value={Number.MAX_VALUE} label={'No Max'} />
            </select>
        </div>
    );
};

export const MAP_FILTER_PICKER_OPTIONS = ['Any', '1+', '2+', '3+', '4+'];

export enum PickerOptionsToNumbers {
    '1+' = 1,
    '2+' = 2,
    '3+' = 3,
    '4+' = 4,
}

export const PRICE_OPTIONS = [
    { value: 70000, label: '$70K' },
    { value: 80000, label: '$80K' },
    { value: 90000, label: '$90K' },
    { value: 100000, label: '$100K' },
    { value: 110000, label: '$110K' },
    { value: 120000, label: '$120K' },
    { value: 130000, label: '$130K' },
    { value: 140000, label: '$140K' },
    { value: 150000, label: '$150K' },
    { value: 160000, label: '$160K' },
    { value: 170000, label: '$170K' },
    { value: 180000, label: '$180K' },
    { value: 190000, label: '$190K' },
    { value: 200000, label: '$200K' },
    { value: 210000, label: '$210K' },
    { value: 220000, label: '$220K' },
    { value: 230000, label: '$230K' },
    { value: 240000, label: '$240K' },
    { value: 250000, label: '$250K' },
    { value: 260000, label: '$260K' },
    { value: 270000, label: '$270K' },
    { value: 280000, label: '$280K' },
    { value: 290000, label: '$290K' },
    { value: 300000, label: '$300K' },
    { value: 325000, label: '$325K' },
    { value: 350000, label: '$350K' },
    { value: 375000, label: '$375K' },
    { value: 400000, label: '$400K' },
    { value: 425000, label: '$425K' },
    { value: 450000, label: '$450K' },
    { value: 475000, label: '$475K' },
    { value: 500000, label: '$500K' },
    { value: 525000, label: '$525K' },
    { value: 550000, label: '$550K' },
    { value: 575000, label: '$575K' },
    { value: 600000, label: '$600K' },
    { value: 625000, label: '$625K' },
    { value: 650000, label: '$650K' },
    { value: 675000, label: '$675K' },
    { value: 700000, label: '$700K' },
    { value: 725000, label: '$725K' },
    { value: 750000, label: '$750K' },
    { value: 775000, label: '$775K' },
    { value: 800000, label: '$800K' },
    { value: 825000, label: '$825K' },
    { value: 850000, label: '$850K' },
    { value: 875000, label: '$875K' },
    { value: 900000, label: '$900K' },
    { value: 925000, label: '$925K' },
    { value: 950000, label: '$950K' },
    { value: 975000, label: '$975K' },
    { value: 1000000, label: '$1M' },
    { value: 1100000, label: '$1.1M' },
    { value: 1200000, label: '$1.2M' },
    { value: 1300000, label: '$1.3M' },
    { value: 1400000, label: '$1.4M' },
    { value: 1500000, label: '$1.5M' },
    { value: 1600000, label: '$1.6M' },
    { value: 1700000, label: '$1.7M' },
    { value: 1800000, label: '$1.8M' },
    { value: 1900000, label: '$1.9M' },
    { value: 2000000, label: '$2M' },
    { value: 2500000, label: '$2.5M' },
    { value: 3000000, label: '$3M' },
    { value: 3500000, label: '$3.5M' },
    { value: 4000000, label: '$4M' },
    { value: 4500000, label: '$4.5M' },
    { value: 5000000, label: '$5M' },
    { value: 5500000, label: '$5.5M' },
    { value: 6000000, label: '$6M' },
    { value: 6500000, label: '$6.5M' },
    { value: 7000000, label: '$7M' },
    { value: 7500000, label: '$7.5M' },
    { value: 8000000, label: '$8M' },
    { value: 8500000, label: '$8.5M' },
    { value: 9000000, label: '$9M' },
    { value: 9500000, label: '$9.5M' },
    { value: 10000000, label: '$10M' },
];

export const SQUARE_FEET_OPTIONS = [
    { value: 900, label: '900' },
    { value: 1000, label: '1000' },
    { value: 1100, label: '1100' },
    { value: 1200, label: '1200' },
    { value: 1300, label: '1300' },
    { value: 1400, label: '1400' },
    { value: 1500, label: '1500' },
    { value: 1600, label: '1600' },
    { value: 1700, label: '1700' },
    { value: 1800, label: '1800' },
    { value: 1900, label: '1900' },
    { value: 2000, label: '2000' },
    { value: 2100, label: '2100' },
    { value: 2200, label: '2200' },
    { value: 2300, label: '2300' },
    { value: 2400, label: '2400' },
    { value: 2500, label: '2500' },
    { value: 2600, label: '2600' },
    { value: 2700, label: '2700' },
    { value: 2800, label: '2800' },
    { value: 2900, label: '2900' },
    { value: 3000, label: '3000' },
    { value: 3250, label: '3250' },
    { value: 3500, label: '3500' },
    { value: 3750, label: '3750' },
    { value: 4000, label: '4000' },
    { value: 4250, label: '4250' },
    { value: 4500, label: '4500' },
    { value: 4750, label: '4750' },
    { value: 5000, label: '5000' },
    { value: 6000, label: '6000' },
    { value: 7000, label: '7000' },
    { value: 8000, label: '8000' },
    { value: 9000, label: '9000' },
    { value: 10000, label: '10k' },
];

export type FilterOptionType = {
    onlyConfigured: boolean;
    statuses: Statuses;
    beds: string;
    baths: string;
    parking: string;
    minPrice: number;
    maxPrice: number;
    minSqft: number;
    maxSqft: number;
    types: SubTypes;
};

export type SubTypes = Array<
    'House' | 'Condo' | 'Townhome' | 'Apartment' | 'Multifamily' | 'Other'
>;
export type Statuses = Array<'Active' | 'Pending' | 'Coming Soon'>;
