import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import {
    AnimatedDrawer,
    AnimatedDrawerHeader,
    ClickableWithFeedback,
    Loader,
} from '../../../../components';
import PickerComponent from '../../../../components/PickerComponent';
import QRCode from '../../../../images/appQRCode.svg';
import { getAgentObject } from '../../../auth/selectors';
import { fetchNotificationsRequested, fetchPendingShowingsRequested } from '../actions';
import {
    getMyNotifications,
    getPendingShowings,
    getLoadingNotifications,
    getLoadingShowings,
} from '../selectors';
import { needsActionSort, notificationDateSort } from '../utils';
import NotificationCard from '../NotificationCard';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListSideDetails from '../../Showings/Modals/ListSideDetails';
import { changeShowingStatusRequested as changeShowingStatus } from '../../Showings/actions'
import { getPaymentModalType } from '../../Profile/selectors';
import { getUpdatingShowing } from '../../Showings/selectors';
import { ShowingRequest } from '../../Showings/constants';


interface NotificationsProps {
    onClose: Function;
    visible: boolean;
}

const NotificationScreen = (props: NotificationsProps) => {
    const agent = useSelector(getAgentObject);
    const notifications = useSelector(getMyNotifications);
    const pendingShowings = useSelector(getPendingShowings);
    const loading = useSelector(
        (state) => getLoadingNotifications(state) || getLoadingShowings(state),
    );
    const dispatch = useDispatch();
    const updatingShowing = useSelector(getUpdatingShowing);

    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const closeDrawerRef = useRef<any>();

    const history = useHistory();

    const filterOptions = ['Needs Action', 'Recent'];
    const [selectedFilter, setSelectedFilter] = useState<string>(filterOptions[0]);
    const [notificationsToDisplay, setNotificationsToDisplay] = useState<any>([]);
    const [modal, setModal] = useState<any>(false);
    const ref = useRef<any>(null);
    const [sendLockBoxDetails, setSendLockBoxDetails] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchNotificationsRequested());
        dispatch(fetchPendingShowingsRequested());
    }, []);

    useEffect(() => {
        if (notifications && pendingShowings) {
            const combinedNotifications = [...pendingShowings, ...notifications];
            // const combinedNotifications: any = [];
            selectedFilter === 'Recent'
                ? setNotificationsToDisplay(notificationDateSort(combinedNotifications))
                : setNotificationsToDisplay(needsActionSort(combinedNotifications));
        }
    }, [selectedFilter, pendingShowings, notifications]);

    const doesNotHaveAppComponent = (
        <div id="doesNotHaveApp" className={styleSheet.doesNotHaveApp}>
            <div className={styleSheet.row}>
                <img src={QRCode} className={styleSheet.qrCode} />
                <div className={styleSheet.appText}>
                    <div>Download the mobile app to enjoy</div>
                    <div>Showingly on the go</div>
                </div>
            </div>
        </div>
    );

    const [canResize, setCanResize] = useState(false);
    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);

    /**
     * determine height of notifications component based
     * on other fixed components, and allow for resize
     */
    const [componentHeight, setComponentHeight] = useState<number>(0);
    useLayoutEffect(() => {
        const headerHeight = document.getElementById('header')?.clientHeight;
        const footerHeight = document.getElementById('doesNotHaveApp')?.clientHeight;
        if (headerHeight) {
            const totalHeight = footerHeight ? headerHeight + footerHeight : headerHeight + 10;
            const resize = () => {
                setTimeout(() => {
                    setComponentHeight(window.innerHeight - totalHeight);
                    setCanResize(false);
                }, 500);
            };
            window.addEventListener('resize', resize);
            setComponentHeight(window.innerHeight - totalHeight);
            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, [
        document.getElementById('header')?.clientHeight,
        document.getElementById('doesNotHaveApp')?.clientHeight,
        window.innerHeight,
    ]);

    // determine positioning of halfway down component based on container height
    const [halfWayMargin, setHalfWayMargin] = useState<number>(0);
    useEffect(() => {
        if (componentHeight) {
            setHalfWayMargin((componentHeight - 50) / 2);
        }
    }, [componentHeight]);

   

    const notificationsComponent = (
        <div
            className={styleSheet.notificationsContainer}
            style={{ height: `${componentHeight}px` }}
        >
            {loading && !notificationsToDisplay?.length ? (
                <div className={styleSheet.loader}>
                    <Loader />
                </div>
            ) : (
                <>
                    {notificationsToDisplay?.map((item: any, index:number) => {

                    const listing = item?.content?.listing;
                    const showingWithoutListing = item?.content?.showing;

                    const showing = {listing,...showingWithoutListing} as ShowingRequest
                       
                       const isUpdating =  updatingShowing === item?.content?.showingRequestId;
                       const refresh = () => {}
                        
                        if (!item.content && !item.clicked) {
                            return (
                                <>
                                    <NotificationCard
                                        notification={{
                                            type: 'showing',
                                            content: {
                                                title: item?.listing?.address?.full,
                                                text: item?.status === 'confirmed' ? 'New Showing Confirmed.' : 'New Showing Request.',
                                            },
                                            activityDate: item?._id
                                                ? item._id.getTimestamp()
                                                : null,
                                            status: item.status,
                                        }}
                                        onClickCard={() => {
                                            
                                            
                                        }}
                                    />
                                </>
                            );
                        }
                        return (
                            <>
                            <ClickableWithFeedback
                                onClick={() => {
                                     if (item?.content?.status === 'completed') {
                                        history.push(
                                            `/showingDetails/${item?.content?.showingRequestId?.toString()}`) 
                                            closeDrawerRef.current.closeDrawer()
                                     }  
                                     else 
                                    setModal(index)
                                    
                                }}
                            >
                                <NotificationCard notification={item} onClickCard={() => {
                                    
                                }} />
                               
                                
                                       
                            </ClickableWithFeedback>
                            {
                                item.content.listing
                                &&
                                   <ListSideDetails
                                            showing={showing}
                                            ref={ref}
                                            set={setModal}
                                            modalVisible={modal === index }
                                            closeModal={() => ref?.current?.closeModal()}
                                            changeStatus={(props: any) => {
                                                dispatch(changeShowingStatus(props));
                                            }}
                                            callback={refresh}
                                            loading={isUpdating}
                                            sendLockBoxDetails={sendLockBoxDetails}
                                            setSendLockBoxDetails={setSendLockBoxDetails}
                                        />
                            }
                           
                            
                                    
                            
                            
                        </>
                        );
                    })}
                    {!notificationsToDisplay?.length && (
                        <div
                            className={styleSheet.noNotifications}
                            style={{ marginTop: `${halfWayMargin}px` }}
                        >
                            No Notifications
                        </div>
                    )}
                </>
            )}
        </div>
    );

    if (!props.visible) {
        return <></>;
    }

    return (
        <AnimatedDrawer onClose={props.onClose} ref={closeDrawerRef}>
            <div id="header">
                <AnimatedDrawerHeader
                    title={'Notifications'}
                    onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
                />
                <div className={styleSheet.buttonRow}>
                    <PickerComponent
                        data={filterOptions}
                        onPressButton={setSelectedFilter}
                        selectedOption={selectedFilter}
                        optionWidth={200}
                    />
                </div>
            </div>
            {notificationsComponent}
            {!agent?.deviceType && doesNotHaveAppComponent}
        </AnimatedDrawer>
    );
};

export default NotificationScreen;
