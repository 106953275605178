import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'web-lib';

import { ListingStat } from '../../types';

import NotConnectIcon from '../../../../../images/unconnected.svg';
import BedIcon from '../../../../../images/bedBold6A.svg';
import BathIcon from '../../../../../images/bathroomBold6A.svg';
import DollarIcon from '../../../../../images/pickup6A.svg';
import SQFTIcon from '../../../../../images/squareFootageBoldGreysvg.svg';
import DefaultHouseImage from '../../../../../images/defaultHome.svg';

import styles from './index.module.css';

type BrokerageListingsProps = {
    brokerageListings: any;
    loadingListings: boolean;
    currentPagination: number;
    setCurrentPagination: Function;
};

const BrokerageListings = ({
    brokerageListings,
    loadingListings,
    currentPagination,
    setCurrentPagination,
}: BrokerageListingsProps) => {
    const [toMapListings, setToMapListings] = useState<any[]>([]);
    const [didAppend, setDidAppend] = useState<boolean>(false);
    const [maxScrollEnd, setMaxScrollEnd] = useState<number>(1000000); // Set scroll end to arbitrarily large value until we calculate the approximate height for re-fetch
    const scrollContainer = useRef(null);

    useEffect(() => {
        setToMapListings(brokerageListings);
        if (brokerageListings.length > 0 && brokerageListings) {
            //Lets set the scroll end to be height the is a function of the total number of listings, minus a few, times the container element size
            //This way once most of the elements have been scrolled through the page will make another fetch
            setMaxScrollEnd(129 * (brokerageListings.length - 10));

            if (didAppend) setDidAppend(false);
        }
    }, [brokerageListings]);

    const handleScrollBottom = (ref: any) => {
        const currentScrollLocation = ref.current.scrollTop;

        if (currentScrollLocation >= maxScrollEnd && !didAppend) {
            setCurrentPagination(currentPagination + 50);
            setDidAppend(true);
        }
    };

    //BEFORE YOU ASK ME WHY!
    //This is the best way to add commas to the listing display data
    //Using a loop or switch is too slow and causes the app to crash, as well as limits the rendering ability of this component
    //In essence this is the a loop unrolled helper function that entirely eliminates the need for loops
    const addCommas = (dataItem: number) => {
        if (dataItem) {
            const dataItemArray = Array.from(dataItem.toString()).reverse();
            const dataItemArrayLength = dataItemArray.length;

            if (dataItemArrayLength > 3 && dataItemArrayLength <= 6) {
                const endDigits = dataItemArray.slice(0, 3).reverse().join('');
                const startDigits = dataItemArray.slice(3, dataItemArrayLength).reverse().join('');

                return startDigits + ',' + endDigits;
            } else if (dataItemArrayLength > 6 && dataItemArrayLength <= 9) {
                const endDigits = dataItemArray.slice(0, 3).reverse().join('');
                const middleDigits = dataItemArray.slice(3, 6).reverse().join('');
                const startDigits = dataItemArray.slice(6, dataItemArrayLength).reverse().join('');

                return startDigits + ',' + middleDigits + ',' + endDigits;
            } else {
                return dataItem;
            }
        } else {
            return '--';
        }
    };

    const IconAndDataHandler = (data: any, icon: string) => {
        return (
            <>
                <img className={styles.infoIcon} alt={'Info icon'} src={icon} />
                <p>{data}</p>
            </>
        );
    };

    type ListingCardProps = {
        listingInfo: any;
    };

    const ListingCard = ({ listingInfo }: ListingCardProps) => {
        const [ppDidBreak, setPPDidBreak] = useState<boolean>(false);

        const { listPrice, property, address } = listingInfo;

        const { bathrooms, bedrooms, area } = property;

        const { full, city, state, postalCode } = address;

        const listingStats: ListingStat[] = [
            {
                listStat: '$' + addCommas(listPrice),
                listIcon: DollarIcon,
            },
            {
                listStat: bedrooms ?? '--',
                listIcon: BedIcon,
            },
            {
                listStat: bathrooms ? parseInt(bathrooms) : '--',
                listIcon: BathIcon,
            },
            {
                listStat: area ? addCommas(area) + ' sqft.' : '-- sqft.',
                listIcon: SQFTIcon,
            },
        ];

        return (
            <div className={styles.listingCardContainer} id={'forATestDontLetMeForgetToDelete'}>
                <div className={styles.listingCardRow}>
                    {listingInfo.photos && (
                        <div>
                            <img
                                src={listingInfo?.photos[0] ?? DefaultHouseImage}
                                alt={'homeImage'}
                                onError={(e: any) => {
                                    e.target.src = DefaultHouseImage;
                                }}
                                className={styles.listingPhoto}
                                referrerPolicy={'no-referrer'}
                            />
                        </div>
                    )}
                    <div className={styles.listingInfoContainer}>
                        <div className={styles.listingAddressContainer}>
                            <span className={styles.listingFull}>
                                {full}
                                {!listingInfo.agentListing && (
                                    <img
                                        src={NotConnectIcon}
                                        alt={'Unconnected Icon'}
                                        className={styles.unconnectedIcon}
                                    />
                                )}
                            </span>
                            <span className={styles.listingCityStateZip}>
                                {city + ', ' + state + ' ' + postalCode}
                            </span>
                        </div>
                        <div className={styles.listingInfoBar}>
                            {listingStats.map((listingStat: ListingStat, key: number) => (
                                <div className={styles.listingInfoIconAndStat} key={key}>
                                    {IconAndDataHandler(listingStat.listStat, listingStat.listIcon)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div
                className={styles.listingsContainer}
                onScroll={() => handleScrollBottom(scrollContainer)}
                ref={scrollContainer}
                id={'ListingContainer'}
            >
                {toMapListings && toMapListings.length > 0 ? (
                    toMapListings.map((listing: any, key: number) => (
                        <>
                            <ListingCard listingInfo={listing} />
                            <div className={styles.cardDivider} />
                        </>
                    ))
                ) : (
                    <>
                        {!loadingListings && (
                            <p className={styles.noListingsFound}>
                                No listings found for your brokerage.
                            </p>
                        )}
                    </>
                )}
                {loadingListings && (
                    <div className={styles.pageLoaderContainer}>
                        <div className={styles.pageLoader}>
                            <Loader
                                icon={
                                    'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrokerageListings;
