import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import backArrow from '../../../../images/leftArrowBlack.svg';
import { useHistory } from 'react-router';
import { Button } from 'web-lib';
import ReactCodeInput from 'react-verification-code-input';
import { sendVerificationMessageRequested } from '../../../auth/actions';

import {
    clearPhoneVerificationState,
    updateProfilePhoneNumberRequested,
    verifyPhoneNumberRequested,
} from '../actions';
import { formatPhoneNumberToIntl, scrubAndFormatPhoneNumber } from '../../../../utils/common';
import {
    getIsPhoneNumberVerified,
    getIsVerifyingCode,
    getIsUpdatePhoneNumberSuccessful,
    getVerificationErrors,
    getIsUpdatingPhoneNumber,
} from '../selectors';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

const ChangePhoneNumber = (props: {}) => {
    const verifyingCode = useSelector(getIsVerifyingCode);
    const updatePhoneNumberSuccess = useSelector(getIsUpdatePhoneNumberSuccessful);
    const errors = useSelector(getVerificationErrors);
    const updatingPhoneNumber = useSelector(getIsUpdatingPhoneNumber);

    const dispatch = useDispatch();
    const sendVerificationMessage = (
        method: 'email' | 'phone',
        reciever: string,
        isResend: boolean,
    ) => dispatch(sendVerificationMessageRequested(method, reciever, isResend));
    const verifyCode = (code: any, phone: string) =>
        dispatch(verifyPhoneNumberRequested(code, phone));
    const clearVerification = () => dispatch(clearPhoneVerificationState());

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [code, setCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>('');
    const history = useHistory();

    const isSendCodeDisabled = formatPhoneNumberToIntl(phoneNumber).includes('valid');

    const isResend = phoneNumber === currentPhoneNumber && currentPhoneNumber !== '';
    const isLoading = updatingPhoneNumber || verifyingCode;

    useEffect(() => {
        return () => {
            clearVerification();
        };
    }, []);

    useEffect(() => {
        if (updatePhoneNumberSuccess) {
            history.push('/profile');
        }
    }, [updatePhoneNumberSuccess]);

    const verificationCard = (
        <div className={styles.verificationContainer}>
            <>
                <p
                    className={
                        codeSent ? styles.verify : classNames(styles.verifyDisabled, styles.verify)
                    }
                >
                    Enter the verification code:{' '}
                </p>
                <div className={styles.topLine}></div>
                <div className={styles.bottomLine}></div>
                <div
                    className={
                        codeSent
                            ? styles.inputStyling
                            : classNames(styles.inputStylingDisabled, styles.inputStyling)
                    }
                >
                    <ReactCodeInput
                        autoFocus={true}
                        className={codeSent ? styles.codeInputBar : styles.codeInputBarDisabled}
                        fields={6}
                        fieldWidth={55}
                        fieldHeight={25}
                        loading={isLoading}
                        onComplete={(e: any) => {
                            setCode(e);
                        }}
                        disabled={!codeSent}
                    />
                </div>
                <div className={styles.errorsContainer}>
                    {errors?.length ? (
                        <>
                            {errors.map((error: string) => (
                                <p key={error} className={styles.errorText}>
                                    {error}
                                </p>
                            ))}
                            <p className={styles.supportText}>
                                *If this seems like a mistake or you keep getting the same errors,
                                reach out to our support team for assistance!
                            </p>
                        </>
                    ) : null}
                </div>
                <Button
                    buttonText={'Verify'}
                    onClick={() => {
                        verifyCode(code, scrubAndFormatPhoneNumber(currentPhoneNumber));
                    }}
                    disabled={!codeSent}
                    className={
                        codeSent
                            ? classNames(styles.button, styles.verifyButton)
                            : classNames(styles.button, styles.verifyButton, styles.disabledButton)
                    }
                />
            </>
        </div>
    );

    return (
        <div className={styles.root}>
            <div
                className={styles.back}
                onClick={() => {
                    history.push('/profile');
                }}
            >
                <img className={styles.backArrow} src={backArrow} />
                <p>Change Phone Number</p>
            </div>
            <div className={styles.container}>
                <input
                    className={styles.phoneNumberInput}
                    name={'password'}
                    placeholder="Enter New Phone Number"
                    onChange={(event: any) => {
                        setPhoneNumber(event.target.value);
                    }}
                />
                <Button
                    buttonText={isResend ? 'Resend Code' : 'Send Code'}
                    className={
                        isSendCodeDisabled
                            ? classNames(styles.button, styles.disabledButton)
                            : styles.button
                    }
                    disabled={isSendCodeDisabled}
                    onClick={() => {
                        sendVerificationMessage(
                            'phone',
                            scrubAndFormatPhoneNumber(phoneNumber),
                            isResend,
                        );
                        setCodeSent(true);
                        setCurrentPhoneNumber(phoneNumber);
                    }}
                />
                {verificationCard}
            </div>
        </div>
    );
};

export default ChangePhoneNumber;
