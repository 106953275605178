import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import Typography from '../../../components/Typography';
import DropdownIcon from '../../../images/dropDownIcon.svg';
import ButtonComponent from '../../../components/ButtonComponent';
import DropdownSelect from '../../../components/DropdownSelect';
import {
    getMarketRequested,
    setAuthAgentInfo,
    setAuthPracticeInfo,
    textSearchAgentsRequested,
    sendVerificationMessageRequested,
    verifyCodeRequested,
} from '../actions';
import {
    getAuthAgent,
    getAuthMarket,
    getEntryAgents,
    getMarkets,
    getPasswordResetActive,
    getVerified,
} from '../selectors';
import { findIndex, propEq } from 'ramda';
import SearchDropdownSelect from '../../../components/SearchDropdownSelect';
import { useHistory } from 'react-router';
import VerifySelect from '../../../components/VerifySelect';
import VerifyInput from '../../../components/VerifyInput';
import { BSON } from 'realm-web';

interface AgentVerifyProps {}

const AgentVerify = (props: AgentVerifyProps) => {
    const authAgent = useSelector(getAuthAgent);
    const verified = useSelector(getVerified);
    const passwordResetActive = useSelector(getPasswordResetActive);
    const dispatch = useDispatch();
    const sendCode = (method: 'email' | 'phone', receiver: string, isResend: boolean) =>
        dispatch(sendVerificationMessageRequested(method, receiver, isResend));
    const verifyCode = (
        method: 'email' | 'phone',
        code: any,
        emailOrPhone?: string,
        linkingAgentObjId?: BSON.ObjectId,
    ) => dispatch(verifyCodeRequested(method, code, emailOrPhone, linkingAgentObjId));

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();

    useEffect(() => {
        if (verified) {
            if (passwordResetActive) history.push('/auth/reset');
            else history.push('/auth/signup');
        }
    }, [verified]);

    const [selectedVerifyOption, selectVerifyOption] = useState<string>('SMS');
    const [codeSent, setCodeSent] = useState<string | null>(null);

    const receiver =
        selectedVerifyOption === 'SMS'
            ? authAgent?.phoneNumber || 'Placeholder non-number phone number that no one will have'
            : authAgent?.email || 'Placeholder non-email that no one will have';
    const verificationMethods: any = {
        SMS: 'phone',
        Email: 'email',
    };

    const [enteredCode, setEnteredCode] = useState<string>('');

    // Keeps track of the information the last verification code was requested with
    const [payload, setPayload] = useState<any>(null);

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.contentBox}>
                <div className={styleSheet.topBox}>
                    <Typography textStyle="h2">
                        {passwordResetActive
                            ? `First, we need to verify your account.`
                            : `Let's verify your account using your MLS information.`}
                    </Typography>
                </div>
                {!codeSent ? (
                    <div className={styleSheet.bottomBox}>
                        <Typography
                            color={theme.palette.mediumGrey}
                            textStyle="b1"
                            style={{ fontSize: 18, paddingBottom: 20 }}
                        >
                            Select your preferred verification method.
                        </Typography>
                        <VerifySelect
                            codeSend={(codeType: string) => {
                                const selectedMethod = verificationMethods[codeType];
                                setPayload({
                                    selectedMethod,
                                    receiver,
                                });
                                sendCode(selectedMethod, receiver, false);
                                setCodeSent(codeType);
                            }}
                            selectedVerifyOption={selectedVerifyOption}
                            selectVerifyOption={(fo: any) => selectVerifyOption(fo)}
                            verifyOptions={['SMS', 'Email']}
                            buttonText={'Send Code'}
                        />
                        <Typography
                            color={theme.palette.mediumGrey}
                            textStyle="b1"
                            style={{ fontSize: 16, marginTop: 29 }}
                        >
                            We use the information associated with your MLS membership to keep your
                            account safe.
                        </Typography>
                        <Typography
                            color={theme.palette.mediumGrey}
                            textStyle="b1"
                            style={{ fontSize: 16, marginTop: 25 }}
                        >
                            If you are using SMS verification, ensure that the number provided to
                            the MLS is a number that can receive texts.
                        </Typography>
                    </div>
                ) : (
                    <>
                        <div className={styleSheet.middleBox}>
                            <VerifyInput
                                disabled={false}
                                enteredCode={enteredCode}
                                setEnteredCode={setEnteredCode}
                                style={{}}
                                onCodeFilled={() => {
                                    verifyCode(payload?.selectedMethod, enteredCode);
                                }}
                            />
                            <Typography
                                color={theme.palette.black}
                                textStyle="b1"
                                style={{ fontSize: 18, marginTop: 48 }}
                            >
                                Didn't receive a code?
                            </Typography>
                            <Typography
                                color={theme.palette.mediumGrey}
                                textStyle="b2"
                                style={{ fontSize: 16, marginTop: 16 }}
                            >
                                Check your spam or junk folders and make sure your cell phone or
                                email is up to date in the MLS.
                            </Typography>
                        </div>
                        <div className={styleSheet.otherBottomBox}>
                            <ButtonComponent
                                onClick={() =>
                                    sendCode(payload?.selectedMethod, payload?.receiver, true)
                                }
                                disabled={false}
                                fill
                                width={257}
                            >
                                Resend Code
                            </ButtonComponent>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default AgentVerify;
