import React from 'react';
import classnames from 'classnames';
import ReactCodeInput from 'react-verification-code-input';

import styles from './index.module.css';

interface VerificationInputBarProps {
    autoFocus?: boolean;
    height?: number;
    loading?: boolean;
    fields?: number;
    onComplete?: Function;
    style?: string;
    width?: number;
}

const VerificationInputBar = ({
    autoFocus = true,
    height = 25,
    loading = false,
    fields = 6,
    onComplete = () => {},
    style = '',
    width = 55,
}: VerificationInputBarProps) => {
    return (
        <div className={styles.inputStyling}>
            <ReactCodeInput
                autoFocus={autoFocus}
                className={classnames(styles.codeInputBar, style)}
                fields={fields}
                fieldHeight={height}
                fieldWidth={width}
                loading={loading}
                onComplete={(e: any) => onComplete(e)}
            />
        </div>
    );
};

export default VerificationInputBar;
