export const CONNECT_LISTING_ACTIONS_PREFIX = 'connectListing/';

export const CONNECT_LISTING_REQUESTED =
    CONNECT_LISTING_ACTIONS_PREFIX + 'CONNECT_LISTING_REQUESTED';
export const CONNECT_LISTING_SUCCEEDED =
    CONNECT_LISTING_ACTIONS_PREFIX + 'CONNECT_LISTING_SUCCEEDED';
export const CONNECT_LISTING_FAILED = CONNECT_LISTING_ACTIONS_PREFIX + 'CONNECT_LISTING_FAILED';

export const CLAIM_LISTING_REQUESTED = CONNECT_LISTING_ACTIONS_PREFIX + 'CLAIM_LISTING_REQUESTED';
export const CLAIM_LISTING_SUCCEEDED = CONNECT_LISTING_ACTIONS_PREFIX + 'CLAIM_LISTING_SUCCEEDED';
export const CLAIM_LISTING_FAILED = CONNECT_LISTING_ACTIONS_PREFIX + 'CLAIM_LISTING_FAILED';

export const SET_SELECTED_CLIENT =
    CONNECT_LISTING_ACTIONS_PREFIX + 'SET_SELECTED_CONNECT_LISTING_CLIENT';

export const RESET_CONNECT_LISTINGS_SUCCESS_STATUS =
    CONNECT_LISTING_ACTIONS_PREFIX + 'RESET_CONNECT_LISTING_SUCCESS_STATUS';

export const MY_LISTING_ACTIONS_PREFIX = 'myListings/';

export const FETCH_MY_LISTINGS_REQUESTED =
    MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTINGS_REQUESTED';
export const FETCH_MY_LISTINGS_SUCCEEDED =
    MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTINGS_SUCCEEDED';
export const FETCH_MY_LISTINGS_FAILED = MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTINGS_FAILED';

export const FETCH_MY_LISTING_REQUESTED = MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTING_REQUESTED';
export const FETCH_MY_LISTING_SUCCEEDED = MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTING_SUCCEEDED';
export const FETCH_MY_LISTING_FAILED = MY_LISTING_ACTIONS_PREFIX + 'FETCH_MY_LISTING_FAILED';
