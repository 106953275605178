import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import {
    AnimatedModal,
    ClickableWithFeedback,
    Loader,
    Typography,
} from '../../../../../components';
import DropdownSelect from '../../../../../components/DropdownSelect';
import CloseIcon from '../../../../../images/xBlack.svg';
import { NewClient, DummyContact } from '../constants';
import CSVReader from 'react-csv-reader';
import { formatPhoneNumberForDisplay } from '../../../../../utils/common';

interface AddByCSVProps {
    firstClient: any;
    setNewClients: Function;
    setSubmitDisabled: Function;
}
const AddByCSV = ({ firstClient, setNewClients, setSubmitDisabled }: AddByCSVProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [headers, setHeaders] = useState<Array<{ label: string }>>([]);
    const [fileName, setFileName] = useState<string | null>(null);

    const [fieldKeys, setFieldKeys] = useState<any>({
        firstName: null,
        lastName: null,
        phoneNumber: null,
    });
    const [selecting, setSelecting] = useState<string | null>(null);

    const [CSVData, setCSVData] = useState<Array<any>>([]);

    const handleCSVUpload = (data: Array<string>, fileType: string, fileName: string) => {
        // ideally, this will never be hit as the user should only be able to upload CSVs with the given input package used
        if (fileType !== 'text/csv') {
            return;
        }
        setFileName(fileName);
        // grab all header names from the uploaded CSV
        const headerArr = Object.keys(data[0]);
        setHeaders(
            headerArr.map((h) => {
                return { label: h };
            }),
        );
        // remove empty rows from uploaded CSV
        setCSVData(data.filter((d: any) => d[headerArr[0]].length));
    };
    const commonDropDownProps = {
        width: 190,
        topMargin: 0,
        bottomMargin: 10,
        height: 35,
        list: headers,
        unsetSelecting: () => setSelecting(null),
        typographyAlignment: { margin: 'auto' },
    };

    useEffect(() => {
        const formattedClients = CSVData?.map((client: any) => {
            return {
                firstName: client[fieldKeys.firstName],
                lastName: client[fieldKeys.lastName],
                phoneNumber: client[fieldKeys.phoneNumber],
            };
        });
        setNewClients(formattedClients);
        if (fieldKeys.firstName && fieldKeys.lastName && fieldKeys.phoneNumber) {
            setSubmitDisabled(false);
        }
    }, [fieldKeys]);

    const step1 = (
        <div className={styleSheet.step1}>
            <Typography
                textStyle="h4"
                color={theme.palette.black}
                className={styleSheet.typography}
                style={{ marginBottom: 2 }}
            >
                Step 1. Choose a file
            </Typography>
            <CSVReader
                inputId="csv"
                onFileLoaded={(data, fileInfo) => {
                    handleCSVUpload(data, fileInfo.type, fileInfo.name);
                }}
                label={fileName || 'Upload .CSV'}
                parserOptions={{
                    skipEmptyLines: true,
                    header: true,
                }}
                cssLabelClass={styleSheet.csvLabel}
                inputStyle={{ display: 'none' }}
            />
        </div>
    );
    const step2 = (
        <div className={styleSheet.step2}>
            <Typography
                textStyle="h4"
                color={theme.palette.black}
                className={styleSheet.typography}
            >
                Step 2. Map Fields
            </Typography>
            <div className={styleSheet.categoryRow}>
                <div>
                    <Typography
                        textStyle="p1"
                        color={theme.palette.mediumGrey}
                        className={styleSheet.typography}
                    >
                        First Name
                    </Typography>
                    <DropdownSelect
                        {...commonDropDownProps}
                        text={'Field 1'}
                        selectOption={(option: any) =>
                            setFieldKeys({ ...fieldKeys, firstName: option })
                        }
                        selectedOption={fieldKeys.firstName}
                        selecting={selecting === 'firstName'}
                        setSelecting={() => setSelecting('firstName')}
                    />
                </div>
                <div>
                    <Typography
                        textStyle="p1"
                        color={theme.palette.mediumGrey}
                        className={styleSheet.typography}
                    >
                        Last Name
                    </Typography>

                    <DropdownSelect
                        {...commonDropDownProps}
                        text={'Field 2'}
                        selectOption={(option: any) =>
                            setFieldKeys({ ...fieldKeys, lastName: option })
                        }
                        selectedOption={fieldKeys.lastName}
                        selecting={selecting === 'lastName'}
                        setSelecting={() => setSelecting('lastName')}
                    />
                </div>
                <div>
                    <Typography
                        textStyle="p1"
                        color={theme.palette.mediumGrey}
                        className={styleSheet.typography}
                    >
                        Phone Number
                    </Typography>
                    <DropdownSelect
                        {...commonDropDownProps}
                        text={'Field 3'}
                        selectOption={(option: any) =>
                            setFieldKeys({ ...fieldKeys, phoneNumber: option })
                        }
                        selectedOption={fieldKeys.phoneNumber}
                        selecting={selecting === 'phoneNumber'}
                        setSelecting={() => setSelecting('phoneNumber')}
                    />
                </div>
            </div>
        </div>
    );

    const step3 = (
        <div className={styleSheet.step3}>
            <Typography
                textStyle="h4"
                color={theme.palette.black}
                className={styleSheet.typography}
            >
                Step 3. Confirm Field Mapping
            </Typography>
            <br />
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Example
            </Typography>

            <div className={styleSheet.categoryRow}>
                {Object.entries(fieldKeys).map(([fieldName, value]: any) => {
                    const data: any =
                        value && firstClient ? firstClient[fieldName] : DummyContact[fieldName];

                    return (
                        <div
                            className={styleSheet.exampleContact}
                            style={{
                                color: value ? theme.palette.black : theme.palette.lightGrey,
                            }}
                        >
                            {fieldName === 'phoneNumber' ? formatPhoneNumberForDisplay(data) : data}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div className={styleSheet.addByCSVRoot}>
            <div className={styleSheet.row} style={{ borderBottomWidth: 1 }}>
                {step1}
            </div>
            <div className={styleSheet.row} style={{ borderBottomWidth: 1 }}>
                {step2}
            </div>
            <div className={styleSheet.row}>{step3}</div>
        </div>
    );
};

export default AddByCSV;
