export default (theme: any) => {
    return {
        featuresContainer:{
            paddingLeft: '20px',
            paddingTop: '40px',
        },
        mainContainer: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.white,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '100%',
            borderRadius: 5,
        },
         
        headerBar: {
            height: 140,
            // ...theme.commonBottomBorder,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: { left: 50, right: 23 },
            backgroundColor: theme.palette.blue,

        },
        // closeIcon: {
        //     scale:1,
        //     position: 'absolute',
        //     top: "-100px",
        //     right: '-340px',
        // },
        cardContainer: {
            backgroundColor: theme.palette.white,
            width: '45%',
            height: '93%',
            border: "2px solid "+theme.palette.separatorGrey,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        cardContainerAlt: {
            backgroundColor: theme.palette.blue,
            width: '45%',
            height: '93%',
            ...theme.cardShadow,
            border: {
                width: 0,
                radius: 5,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        cardHeaderContainer: {
            height: 80,
            display: 'flex',
            flexDirection: 'column',
            margin: {
                top: 15,
                left: 20,
                right: 20,
                bottom: 13,
            },
            // ...theme.commonBottomBorder,
        },
        cardHeaderContainerAlt: {
            height: 80,
            display: 'flex',
            flexDirection: 'column',
            margin: {
                top: 15,
                left: 20,
                right: 20,
                bottom: 13,
            },
            // ...theme.commonBottomBorder,
        },
        itemsContainer: {
            overflowY: 'hide',
            height: '100%',
            // ...theme.commonBottomBorder,
        },
        itemRow: {
            margin: {
                top: 10,
                left: 20,
                right: 20,
            },
            display: 'flex',
            alignItems: 'center',
        },
        checkIcon: {
            height: 18,
            width: 18,
            marginRight: 10,
        },
        cardFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 120,
            marginBottom:20,
        },
        noBorder: {
            borderWidth: 0,
            borderColor: theme.palette.white,
        },
    };
};
