export default (theme: any) => {
    return {
        root: ({ width }: any) => ({
            minHeight: 156,
            // Do not change this, it keeps the card from spilling over
            // width: width <= 1024 ? width - width *0.34 -200  :  width - width * 0.34 - 200 - 96,
            // minWidth: 595,
            backgroundColor: theme.palette.white,
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(12),
            marginBottom: 8,
        }),
        button: {
            marginRight: 16,
        },
        seeDetails: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 10,
            marginRight: 26,
        },
        forwardArrow: {
            marginLeft: 5,
        },
        topSection: {
            display: 'flex',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.separatorGrey,
            borderBottomStyle: 'solid',
            marginBottom: 12,
        },
        midSection: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8,
        },
        bottomSection: {
            display: 'flex',
            flexDirection: 'row',
            borderTopWidth: 1,
            borderTopColor: theme.palette.separatorGrey,
            borderTopStyle: 'solid',
            paddingTop: 12,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };
};
