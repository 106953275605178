import { STATUS } from '../../../store/api/constants';

export enum QUEUED_SHOWING_ACTION {
    QueuedShowingsPersist = '[Queued Showings] Queue Showing Persist',
    QueueShowingInteraction = '[Queued Showings] Queue Showing Interaction',
    FetchGroupDelegationPrice = '[Queued Showings] Fetch Group Delegation Price',
    FetchListings = '[Queued Showings] Fetch Listings',
    FetchClient = '[Queued Client Fetch] Fetch Client',
    ClearQueuedShowings = '[Queued Showings] Clear Showings',
    SaveShowingQueue = '[Queued Showings] Save Showing Queue',
    SetDefaultWaypoint = '[Queued Showings] Set Default Waypoint',
}

export const CLEAR_QUEUED_SHOWING_SUCCESS_MESSAGE = 'CLEAR_QUEUED_SHOWING_SUCCESS_MESSAGE';
export const clearQueuedShowingsSuccessMessage = () =>
    <const>{ type: CLEAR_QUEUED_SHOWING_SUCCESS_MESSAGE };

export const persistQueuedShowings = (queuedShowingsToPersist: object[] | null) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.QueuedShowingsPersist,
        showingQueue: queuedShowingsToPersist,
    };

// --------------------------------------------------------------------------

export const queueShowingInteractionRequested = (
    interactionType: 'add' | 'update' | 'remove' | 'schedule' | 'clear',
    showing: any | null,
    updateIndex: number | -1,
    callback?: any,
    customScheduleQueue?: any,
) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.QueueShowingInteraction,
        status: STATUS.Requested,
        interactionType,
        showing,
        updateIndex,
        callback,
        customScheduleQueue,
    };
export const queueShowingInteractionSucceeded = (showingQueue: any[], interactionType: string) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.QueueShowingInteraction,
        status: STATUS.Succeeded,
        showingQueue,
        interactionType,
    };
export const queueShowingInteractionFailed = (error: string) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.QueueShowingInteraction,
        status: STATUS.Failed,
        error,
    };
export type QueueShowingInteraction =
    | ReturnType<typeof queueShowingInteractionRequested>
    | ReturnType<typeof queueShowingInteractionSucceeded>
    | ReturnType<typeof queueShowingInteractionFailed>;

// --------------------------------------------------------------------------

export const fetchGroupDelegationPriceRequested = (showings: any) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchGroupDelegationPrice,
        status: STATUS.Requested,
        showings,
    };
export const fetchGroupDelegationPriceSucceeded = (groupDelegationPrice: number) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchGroupDelegationPrice,
        status: STATUS.Succeeded,
        groupDelegationPrice,
    };
export const fetchGroupDelegationPriceFailed = (error: string) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchGroupDelegationPrice,
        status: STATUS.Failed,
        error,
    };
export type FetchGroupDelegationPrice =
    | ReturnType<typeof fetchGroupDelegationPriceRequested>
    | ReturnType<typeof fetchGroupDelegationPriceSucceeded>
    | ReturnType<typeof fetchGroupDelegationPriceFailed>;

// --------------------------------------------------------------------------

export const fetchAllListingsForQueuedShowingsRequested = (listingIds: any[] = [], bad?: any) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchListings,
        status: STATUS.Requested,
        listingIds,
        bad,
    };

export const fetchAllListingsForQueuedShowingsSucceeded = (queuedShowings: any[] = []) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchListings,
        status: STATUS.Succeeded,
        queuedShowings,
    };

export const fetchAllListingsForQueuedShowingsFailed = (errors: string[] = []) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchListings,
        status: STATUS.Failed,
        errors,
    };

export type FetchAllListings =
    | ReturnType<typeof fetchAllListingsForQueuedShowingsRequested>
    | ReturnType<typeof fetchAllListingsForQueuedShowingsSucceeded>
    | ReturnType<typeof fetchAllListingsForQueuedShowingsFailed>;

// Actions for client fetch if a client is attached to a showing

export const fetchClientRequested = (stitchIds: string[]) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchClient,
        status: STATUS.Requested,
        stitchIds,
    };

export const fetchClientSucceeded = (clientObjects: any[]) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchClient,
        status: STATUS.Succeeded,
        clientObjects,
    };

export const fetchClientFailed = (clientFetchError: any[]) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.FetchClient,
        status: STATUS.Failed,
        clientFetchError,
    };

export type fetchClient =
    | ReturnType<typeof fetchClientRequested>
    | ReturnType<typeof fetchClientSucceeded>
    | ReturnType<typeof fetchClientFailed>;

// Actions for clearing the showing queue

export const clearShowingQueue = () =>
    <const>{
        type: QUEUED_SHOWING_ACTION.ClearQueuedShowings,
    };

export type clearShowingQueue = ReturnType<typeof clearShowingQueue>;

// --------------------------------------------------------------------------

// Saving showing queue on agent's document
export const saveShowingQueue = (showingQueue: string[] | any) =>
    <const>{
        type: QUEUED_SHOWING_ACTION.SaveShowingQueue,
        status: STATUS.Requested,
        showingQueue,
    };

export const saveShowingQueueSucceeded = () =>
    <const>{
        type: QUEUED_SHOWING_ACTION.SaveShowingQueue,
        status: STATUS.Succeeded,
    };

export const setDefaultWaypoint = (waypoints: any) => {
    return {
        type: QUEUED_SHOWING_ACTION.SetDefaultWaypoint,
        status: STATUS.Requested,
        waypoints,
    };
};
