import { createSelector } from 'reselect';
import { ShowingManagerState } from './reducer';

const getShowingManagerState = (state: { showingRequests: ShowingManagerState }) => {
    return state?.showingRequests;
};

export const getShowingLoading = createSelector(getShowingManagerState, (showingManagerState) => {
    return showingManagerState?.loading;
});

export const getUnavailableDates = createSelector(
    getShowingManagerState,
    (showingManagerState) => showingManagerState.unavailableDates,
);

export const getAgentSchedule = createSelector(
    getShowingManagerState,
    (showingManagerState) => showingManagerState.agentSchedule,
);

export const getScheduleCalendarLoading = createSelector(
    getShowingManagerState,
    (showingManagerState) => showingManagerState.calendarListingLoading,
);

export const getCreatedShowingId = createSelector(
    getShowingManagerState,
    (showingManagerState) => showingManagerState.createdShowing,
);

export const getRescheduledShowingId = createSelector(
    getShowingManagerState,
    (showingManagerState) => showingManagerState.rescheduledShowing,
);
