import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BSON } from 'realm-web';
import { Assets } from 'asset-lib/src/index';
import noImageAvailable from '../../../images/noImageAvailable.svg';
import { SearchBar, PropertyListingCard, ScheduleDrawer, PreviewCard, ButtonV4 } from 'web-lib';

import {
    fetchActiveListings,
    fetchListingRequested,
    createShowingRequested,
    clearShowingSuccessStatus,
    loadSearchHistory,
    clearScheduleShowingError,
    fetchShowingsForListingRequested,
} from './actions';
import {
    didCreateNewShowing,
    getActiveListings,
    getActiveListingsState,
    getActiveRawListings,
    getListingRetrievalLoading,
    getSelectedListing,
    getShowingErrors,
    getShowingIsBeingScheduled,
    getShowingsOnListing,
    getShowingsOnListingLoading,
    getStateListings,
    getStateShowings,
} from './selectors';
import { Listing, ShowingType } from '../../../utils/constants';
import {
    PropertyModalImproved,
    ScheduleShowingModal,
    Switch,
    UnconnectedShowingModal,
    UpgradeSnackBar,
} from '../../../components';
import { queueShowingInteractionRequested } from '../QueuedShowings/actions';
import { fetchMyShowingsRequested } from '../MyShowings/actions';
import { fetchScheduleRequested } from '../SearchListings/actions';

import styles from './index.module.css';
import { clearCurrentListingAndShowing } from '../../../store/api/actions';
import { MY_SHOWINGS_FILTER } from '../MyShowings/types';
import { DateTime } from 'luxon';
import MapView from '../Map/MapView';
import { saveListingInRedux } from '../ListingDetailsScreen/actions';
import { getMapListings } from '../Map/selectors';
import ReactList from 'react-list';
import { MapListingObject } from '../Map/types';
import useWindowDimensions, { getMetaData } from '../../../utils/common';
import { getMyShowingsList, getNoneShowings } from '../MyShowings/selectors';
import { getListingShowingTimes } from '../../../store/api/sagas';
import db from './db';
import { SlideOutDrawer } from 'web-lib';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { Checkbox } from 'web-lib';
import TallPropertyCard from '../../../components/TallPropertyCard';

const SearchListings = ({ searchListings, showQueue }: any) => {
    const errors = useSelector(getShowingErrors);
    const activeListings = useSelector(getActiveRawListings);
    const mapListings = useSelector(getMapListings);
    const showingSuccessfullyCreated = useSelector(didCreateNewShowing);
    const showings = useSelector(getStateShowings);
    const listing = useSelector(getStateListings);
    const showingsOnListing = useSelector(getShowingsOnListing);
    const stateListing = useSelector(getSelectedListing);
    const loading = useSelector(
        (state) =>
            getShowingIsBeingScheduled(state) ||
            getShowingsOnListingLoading(state) ||
            getListingRetrievalLoading(state),
    );
    const dispatch = useDispatch();
    const onSearch = (searchText: any, mlsIdSearch?: boolean) =>
        dispatch(fetchActiveListings(searchText, mlsIdSearch));
    const onFetchListing = (listingId: string, listingDetails: boolean) =>
        dispatch(fetchListingRequested(listingId, listingDetails, null));
    const onRequestShowing = (
        showingType: ShowingType,
        listingId: string,
        startTime: Date,
        endTime: Date,
        clientIds: any,
        utcOffset: number,
    ) =>
        dispatch(
            createShowingRequested(
                showingType,
                listingId,
                startTime,
                endTime,
                clientIds,
                utcOffset,
            ),
        );

    const onAttemptQueueShowing = (interactionType: any, showing: any, updateIndex: number) =>
        dispatch(queueShowingInteractionRequested(interactionType, showing, updateIndex));
    const fetchMyShowings = () =>
        dispatch(fetchMyShowingsRequested(MY_SHOWINGS_FILTER.None, false));
    const fetchSchedule = (startTime: any, endTime: any) =>
        dispatch(fetchScheduleRequested(startTime, endTime));

    const [selectedListing, setSelectedListing] = useState<any>();
    const [selectedListingShowingTimes, setSelectedListingShowingTimes] = useState<any>([]);
    const [selectedListingMap, setSelectedListingMap] = useState<MapListingObject | null>();
    const [continueSelected, setContinueSelected] = useState<boolean>(false);
    const [exitSelected, setExitSelected] = useState<boolean>(false);
    const [listingSelected, setListingSelected] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState('Map');
    const history = useHistory();
    const scrollRef = useRef(null) as any;
    const scrollRefSmooth = useRef<any>(null);

    const [mlsIdSearch, setMlsIdSearch] = useState<boolean>(false);
    const [activeSearchText, setActiveSearchText] = useState<string>('');

    const queueData: any = sessionStorage.getItem('queueData');
    const queueDataParsed = JSON.parse(queueData);

    // if user routes to the queue, open the queue cart
    useEffect(() => {
        if (queueDataParsed?.mlsName && queueDataParsed?.listingid) {
            document.getElementById('queueCartAuthApp')?.click();
        }
    }, [showQueue]);

    /**
     * Whenever the user searches for a new listing or presses one on the map,
     * smooth scroll to the top of the reactList container.
     *
     * Required as reactList doesn't support smooth scroll
     */
    useEffect(() => {
        scrollRefSmooth?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedListingMap, activeSearchText]);

    /**
     * Need to make sure we have the live listing data, especially
     * if the listing data was previously stored in local storage
     */
    useEffect(() => {
        if (stateListing) {
            setListingSelected(true);
            setSelectedListing(stateListing);
        }
    }, [stateListing]);

    // const activeListings = searchListings?.activeListings;
    const minutesUtcOffset = new Date().getTimezoneOffset();
    const utcOffset = Math.floor(minutesUtcOffset / 60);

    const [navToQueue, setNavToQueue] = useState<boolean>(false);

    useEffect(() => {
        // when leaving the screen, remove any schedule showing errors
        return () => {
            dispatch(clearScheduleShowingError());
        };
    }, []);

    useEffect(() => {
        if (navToQueue) {
            dispatch(clearCurrentListingAndShowing());
            navToQueue && history.push('/myQueue');
            setNavToQueue(false);
        }
    }, [navToQueue]);
    useEffect(() => {
        async function getListings(db: any) {
            return await db.table('searchListings').toArray();
        }
        getListings(db).then((savedListings) => {
            const filtered = savedListings
                ? savedListings
                      .map((d: any) => JSON.parse(d.listing))
                      .filter((d: any) => d.is_displayed && d.mls.status === 'Active')
                : [];
            dispatch(loadSearchHistory(filtered));
        });
    }, []);

    useEffect(() => {
        setSelectedListingMap(activeListings?.length ? activeListings[0] : null);
        if (activeListings?.length > 0) {
            // localStorage.setItem("searchHistory", JSON.stringify(activeListings))
            db.table('searchListings').clear();
            activeListings?.map((d: any) =>
                db.table('searchListings').add({ listing: JSON.stringify(d) }),
            );
        }
    }, [activeListings]);
    // if the user creates a new showing, lets take them to /myShowings
    useEffect(() => {
        if (showingSuccessfullyCreated) {
            setTimeout(() => {
                setSelectedListing(null);
                dispatch(clearShowingSuccessStatus());
                dispatch(clearCurrentListingAndShowing());
                history.push('/myShowings');
                setContinueSelected(false);
            }, 2000);
        }
    }, [showingSuccessfullyCreated]);

    //Fetches already scheduled showings for the scheduling feature
    useEffect(() => {
        const startDate = DateTime.fromMillis(new Date().setHours(0, 0, 0, 0)).toJSDate();
        const endDate = DateTime.fromMillis(new Date().getTime()).plus({ week: 1 }).toJSDate();
        fetchSchedule(startDate, endDate);
    }, []);

    useEffect(() => {
        if (listingSelected) {
            if (selectedListing.agentListing === undefined) {
                if (continueSelected && !exitSelected) {
                    setListingSelected(false);
                } else if (exitSelected && !continueSelected) {
                    setSelectedListing(null);
                    dispatch(clearCurrentListingAndShowing());
                    setExitSelected(false);
                    setListingSelected(false);
                }
            } else {
                setListingSelected(false);
            }
        }
    }, [listingSelected, continueSelected, exitSelected]);

    useEffect(() => {
        if (selectedListing?._id) {
            dispatch(fetchShowingsForListingRequested(selectedListing._id));
            const startDate = DateTime.fromMillis(new Date().setHours(0, 0, 0, 0)).toJSDate();
            const endDate = DateTime.fromMillis(new Date().getTime()).plus({ week: 1 }).toJSDate();
            fetchSchedule(startDate, endDate);
        }
    }, [selectedListing]);

    useEffect(() => {
        setSelectedListingShowingTimes(showingsOnListing);
    }, [showingsOnListing]);

    function useOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !event.target.className.includes('Dropdown-option')
                ) {
                    setSelectedListing(undefined);
                    dispatch(clearCurrentListingAndShowing());
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);
    const renderItem = (index: number | string, key: number | string) => {
        const listing = mapListings[index];
        const isSelected = JSON.stringify(listing) === JSON.stringify(selectedListingMap);
        const metaData = {
            type: 'listing',
            address: listing?.address,
            price: listing?.listPrice,
            bedrooms: listing?.property?.bedrooms ?? '0',
            bathrooms: listing?.property?.bathrooms ?? '0',
            area: listing?.property?.area ?? '0',
        };

        return (
            <React.Fragment key={key}>
                {/* Dummy div for scroll ref */}
                {index === 0 ? <div ref={scrollRefSmooth} /> : null}
                <div
                    className={styles.previewCardContainer}
                    onClick={() => {
                        dispatch(saveListingInRedux(listing, 'buyer'));
                        history.push(
                            `/listingDetails/${
                                listing?.mlsList?.length
                                    ? listing?.mlsList[0]?.mlsName +
                                      '/' +
                                      listing?.mlsList[0]?.listingId
                                    : ''
                            }`,
                        );
                    }}
                >
                    <TallPropertyCard listing={listing} />
                </div>
            </React.Fragment>
        );
    };

    const previewListings = useCallback(() => {
        const scrollID = mapListings?.findIndex(
            (listing: MapListingObject) =>
                listing?._id.toString() === selectedListingMap?._id.toString(),
        );

        if (scrollID >= 0) {
            // Take the selected listing and put it at the front of the list.
            mapListings.splice(scrollID, 1);
            mapListings.unshift(selectedListingMap);
        }

        return (
            <ReactList
                ref={scrollRef}
                itemRenderer={(index, key) => renderItem(index, key)}
                length={mapListings?.length ?? 0}
                pageSize={mapListings?.length ?? 0}
                initialIndex={0}
                useStaticSize={true}
            />
        );
    }, [mapListings, selectedListingMap]);

    const { height, width } = useWindowDimensions();

    return (
        <div style={{ height: '80%', width: '100%' }}>
            <UpgradeSnackBar />
            <div className={styles.selectionRow}>Search & Schedule</div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' ,height: height - 86 - 61 - 60,}}>
                <MapView
                    onPressMarker={(listing: MapListingObject) => {
                        setSelectedListingMap(listing);
                    }}
                    onMapClicked={() => {
                        setSelectedListingMap(null);
                    }}
                    containerStyles={styles.mapView}
                    selectedListing={selectedListingMap}
                    // setSelectedListing={setSelectedListingMap}
                    center={activeListings?.length ? activeListings[0].geo : null}
                    activeListings={activeListings}
                />
                <div
                    style={{
                        backgroundColor: '#f6f6f6',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        // height - header - pageheader - upgradeSnackBar
                        height: height - 86 - 61 - 60,
                        borderWidth: 1,
                        borderColor: '#d3d3d3',
                    }}
                >
                    <div className={styles.searchContainer}>
                        <SearchBar
                            inputStyles={styles.searchBarInputStyles}
                            onSubmit={(searchText: any) => {
                                onSearch(searchText, mlsIdSearch);
                                setActiveSearchText(searchText);
                            }}
                            disabled={selectedListing}
                            placeholderText={`Search for Listings`}
                        />
                    </div>
                    <div className={styles.listingsOverlayMap}>{previewListings()}</div>
                </div>
            </div>
            {/* {showQueue && (
                <SlideOutDrawer
                    onCloseModal={() => history.push('/search')}
                    width="80%"
                    contentWidth="100%"
                    maxContentHeight={'unset'}
                >
                    <DndProvider options={HTML5toTouch}>
                        <QueuedShowings />
                    </DndProvider>
                </SlideOutDrawer>
            )} */}
        </div>
    );
};

export default SearchListings;
