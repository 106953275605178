import { BSON } from 'realm-web';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { CONSUMERS, SHOWING_REQUESTS } from '../../../store/api/constants';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    getAgentRecord,
} from '../../../store/api/sagas';
import { getStitchUser } from '../../../utils';
import {
    formatPhoneNumberToIntl,
    generateSignature,
    imageToBase64,
    parseStitchServiceError,
} from '../../../utils/common';
import { getProfileData } from '../Profile/selectors';
import * as Actions from './actions';
import { transformUser } from './ConfigureListing/utils';
import { getListings, getSelectedListing } from './selectors';

export function* fetchMyListings({ agentObjectId }: any): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const agentRecord = yield call(getAgentRecord, user);
        let _id = agentObjectId ? new BSON.ObjectID(agentObjectId) : agentRecord?._id;

        const listings = yield call(callStitchFunction, 'getAgentsListings', _id);

        const sortedListings = listings.sort((a: any, b: any) =>
            !!a?.agentListing === !!b?.agentListing ? 0 : !!a.agentListing ? 1 : -1,
        );

        yield put(Actions.fetchListingsSucceeded(sortedListings));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(Actions.fetchListingsFailed(message));
    }
}

export function* fetchSelectedListing({
    listingId,
    isEditing = false,
}: ReturnType<typeof Actions.fetchSelectedListingRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const agentRecord = yield call(getAgentRecord, user);
        if (typeof listingId === 'string' && listingId.length !== 24) {
            listingId = yield call(callStitchFunction, 'runAggregationListings', 'listingUrl', {
                agentObjectId: agentRecord?._id,
                listingId,
            });
        }
        const listing = yield call(findRecord, 'listings', {
            _id: new BSON.ObjectId(listingId),
        });

        // Put the encrypted link on the listing
        listing.link = yield call(callStitchFunction, 'runAggregationListings', 'listingUrl', {
            agentObjectId: agentRecord?._id,
            listingId: listing?._id.toString(),
        });

        if (isEditing && listing) {
            yield put(Actions.setConfigDetails(listing, 1));
        } else if (listing) {
            yield put(Actions.setSelectedListing(listing));
        } else {
            yield put(
                Actions.fetchSelectedListingFailed('An error occurred while getting your listing'),
            );
        }
    } catch (error) {
        yield put(Actions.fetchSelectedListingFailed(error));
    }
}

export function* textSearchAgents({
    searchText,
}: ReturnType<typeof Actions.textSearchAgentsRequested>): Generator<any, any, any> {
    try {
        const agentRecord = yield select(getProfileData);

        const marketName = agentRecord?.markets?.length
            ? agentRecord?.markets[0]?.marketName
            : null;

        let agents = [];
        agents = yield call(
            callStitchFunction,
            'textSearchAgents',
            searchText,
            null, //projection
            null, //match
            marketName,
            false,
            false,
        );
        if (agents) {
            yield put(Actions.textSearchAgentsSucceeded(agents));
        }
    } catch (errors) {
        yield put(Actions.textSearchAgentsFailed(errors));
    }
}

export function* textSearchConsumers({
    searchText,
}: ReturnType<typeof Actions.textSearchConsumersRequested>): Generator<any, any, any> {
    try {
        const { _id } = yield select(getProfileData);

        let consumers = [];

        consumers = yield call(callStitchFunction, 'textSearchConsumers', searchText, _id, null);
        if (consumers) {
            yield put(Actions.textSearchConsumersSucceeded(consumers));
        }
    } catch (errors) {
        yield put(Actions.textSearchConsumersFailed(errors));
    }
}

export function* connectListing({
    agentListing,
}: ReturnType<typeof Actions.connectListingRequested>): Generator<any, any, any> {
    try {
        const selectedListing = yield select(getSelectedListing);
        const listings = yield select(getListings);
        const response = yield call(callStitchFunction, 'agentConnectListing', {
            listingId: agentListing.listingId,
            agentListing,
        });
        const user = yield call(getStitchUser);
        const agentRecord = yield call(getAgentRecord, user);
        if (response) {
            yield put(Actions.setSelectedListing({ ...selectedListing, agentListing }));
            yield put(
                Actions.fetchListingsSucceeded([
                    ...listings.filter(
                        (d: any) => d._id.toString() !== agentListing.listingId.toString(),
                    ),
                    { ...selectedListing, agentListing },
                ]),
            );
            yield put(Actions.connectListingSucceeded());
        }
    } catch (err) {
        yield put(Actions.connectListingFailed(err));
    }
}

export function* addClient({
    client,
}: ReturnType<typeof Actions.addClientRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const agentRecord = yield call(getAgentRecord, user);
        const response = yield call(
            callStitchFunction,
            'addConsumers',
            [
                {
                    ...client,
                    phoneNumber: formatPhoneNumberToIntl(client.phoneNumber),
                },
            ],
            agentRecord._id,
        );

        if (response) {
            // Re-fetching the client to grab the _id
            // TODO: Rather than re-fetch this agent, we need to get the _id from the 'addConsumers' collection
            const newClient = yield findRecord(CONSUMERS, {
                phoneNumber: formatPhoneNumberToIntl(client.phoneNumber),
            });
            if (newClient) {
                yield put(Actions.addClientSucceeded(transformUser(newClient, 'consumer')));
            }
        }
    } catch (errors) {
        yield put(Actions.addClientFailed(errors));
    }
}

export function* storeLockboxPhoto({
    file,
}: ReturnType<typeof Actions.storeLockboxPhotoRequested>): Generator<any, any, any> {
    try {
        let base64Image = yield imageToBase64(file);
        const newPhotoData = yield call(
            callStitchFunction,
            'storePhoto',
            'lockbox',
            base64Image.split(',')[1],
            `${file.name}`,
            file.type,
        );
        let newContentData = {
            image: {
                as: file.name,
                uri: newPhotoData
                    ? `https://lockbox-photos.s3.amazonaws.com/${newPhotoData?.key}`
                    : '',
                path: newPhotoData
                    ? `https://lockbox-photos.s3.amazonaws.com/${newPhotoData?.key}`
                    : '',
            },
        };
        if (newPhotoData) {
            yield put(Actions.storeLockboxPhotoSucceeded(newContentData));
        }
    } catch (errors) {
        yield put(Actions.storeLockboxPhotoFailed(errors));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) => action.type === Actions.FETCH_MY_LISTINGS_REQUESTED,
            fetchMyListings,
        ),
        takeLatest(
            (action: any) => action.type === Actions.FETCH_SELECTED_LISTING_REQUESTED,
            fetchSelectedListing,
        ),
        takeLatest(
            (action: any) => action.type === Actions.TEXT_SEARCH_AGENTS_REQUESTED,
            textSearchAgents,
        ),
        takeLatest(
            (action: any) => action.type === Actions.TEXT_SEARCH_CONSUMERS_REQUESTED,
            textSearchConsumers,
        ),
        takeLatest(
            (action: any) => action.type === Actions.CONNECT_LISTING_REQUESTED,
            connectListing,
        ),
        takeLatest((action: any) => action.type === Actions.ADD_CLIENT_REQUESTED, addClient),
        takeLatest(
            (action: any) => action.type === Actions.STORE_LOCKBOX_PHOTO_REQUESTED,
            storeLockboxPhoto,
        ),
    ]);
}
