import { STATUS } from '../../../store/api/constants';
import { ListingDetailsActions, fetchRecipientsRequested } from './actions';

export const initialState = {
    listing: {},
    listingType: '',
    errors: {},

    messageBlastRecipients: [],

    recipientsLoading: false,
    recipientLoaded: false,
    recipientError: [],

    connectedListings: [],
};

export default function (state = initialState, action: any) {
    const { type } = action;

    switch (type) {
        case ListingDetailsActions.saveListing: {
            const { listing, listingType } = action;

            if (listing) {
                return {
                    ...state,
                    listing,
                    listingType,
                };
            }

            return {
                ...state,
            };
        }
        case ListingDetailsActions.fetch: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        recipientsLoading: true,
                        recipientLoaded: false,
                    };
                case STATUS.Succeeded:
                    const { Recipients } = action;
                    return {
                        ...state,
                        messageBlastRecipients: Recipients,
                        recipientsLoading: false,
                        recipientLoaded: true,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors: errors,
                        recipientsLoading: false,
                        recipientLoaded: false,
                        recipientError: errors,
                    };
            }
        }
        case ListingDetailsActions.send:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        blastMessageSending: true,
                    };
                case STATUS.Succeeded:
                    const { blast } = action;
                    return {
                        ...state,
                        blastMessageSending: false,
                        connectedListings: state.connectedListings.map((d: any) =>
                            d._id === blast.listingObjectId
                                ? { ...d, blasts: [...d.blasts, blast] }
                                : d,
                        ),
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        messageBlastLoading: false,
                        messageBlastErrors: errors,
                        blastMessageSending: false,
                    };
            }
        case ListingDetailsActions.resetMessageError: {
            return {
                ...state,
                messageBlastErrors: null,
            };
        }
        case ListingDetailsActions.resetRecipientError: {
            return {
                ...state,
                recipientError: null,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
