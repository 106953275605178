export default (theme: any) => {
    return {
        configuredRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            marginBottom: 10,
            overflow: 'visible',
            '& span': {
                fontWeight: 500,
            },
        },
        break: {
            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'solid',
            },
            marginBottom: '2vh',
            marginTop: '2vh',
            width: '100%',
            height: 0,
            fontWeight: 600,
            fontSize: 18,
        },
        priceRangeContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        dropdown: {
            ...theme.marginHorizontal(10),
            width: '20%',
            height: 30,
            border: 'none',
            borderRadius: 5,
            backgroundColor: theme.palette.background,
            paddingLeft: 10,
        },
        smallGreyText: {
            color: theme.palette.mediumGrey,
            fontWeight: 500,
            fontSize: 16,
        },
    };
};
