import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
    INITIALIZATION,
    initializationDone,
    USER_LOGIN_REQUESTED,
    userLoginRequestFailed,
    LOG_OUT_REQUESTED,
    userLoginRequestSucceeded,
    logoutRequestSucceeded,
    logoutRequestFailed,
    updateStitchUser,
    logoutRequested,
    storeAgentRecord,
    getMarketFailed,
    getMarketSucceeded,
    fetchAgentsDocumentsFailed,
    fetchAgentDocumentsSucceeded,
    sendVerificationMessageRequested,
    sendVerificationMessageFailed,
    sendVerificationMessageSucceedeed,
    updatePendingUser,
    verifyCodeFailed,
    verifyCodeRequested,
    verifyCodeSucceedeed,
    registrationRequestFailed,
    registrationRequestSucceeded,
    AUTH_ACTION,
    requestAccessTokenSSOSucceeded,
    requestAccessTokenSSOFailed,
    disableAuthLoading,
    hasAuthLinkExpiredOrIsInvalid,
    isUnauthenticated,
    fetchConsumerRecordSucceeded,
    fetchConsumerRecordFailed,
    acceptTermsOfServiceFailed,
    acceptTermsOfServiceSucceeded,
    RESET_PASSWORD_REQUESTED,
    passwordResetSucceeded,
    passwordResetFailed,
    textSearchAgentsSucceeded,
    textSearchAgentsFailed,
    agentLoginSucceeded,
    agentLoginFailed,
    AGENT_LOGIN_REQUESTED,
    resetPasswordSucceeded,
    resetPassFailed,
} from './actions';
import {
    fetchMyBrokeragesRequested,
    getProfileDataRequested,
    linkAgentRequested,
    verifyLicenseCodeFailed,
} from '../main/Profile/actions';
import {
    anonLogin,
    findRecord,
    findRecords,
    getAgentRecord,
    login,
    logout,
    upsertAgentProfile,
    entrySearchForAgents,
    requestEmailVerification,
    requestPhoneVerification,
    callStitchFunction,
    requestUserRegistration,
    customAuthLogin,
    upsertTempUser,
    upsertAgentRecordAsAnon,
    updateTosAcceptance,
    textSearchAgents,
    runQuery,
} from '../../store/api/sagas';
import { getStitchUser } from '../../utils';
import * as Realm from 'realm-web';

import {
    AGENTS,
    CONSUMERS,
    MARKETS_COLLECTION_NAME,
    USER_TYPE_AGENT,
} from '../../store/api/constants';
import { STATUS } from '../../utils/constants';
import { getPendingUser, getPendingPhoneNumber, getAuthMarket, getAuthAgent } from './selectors';
import { parseStitchServiceError, scrubAndFormatPhoneNumber, scrubEmail } from '../../utils/common';
import { jwtVerify, parseJwk } from './joseImportHelper';
import { fetchAgentSocialDocumentRequested } from '../main/Social/actions';
import { fetchMyShowingsRequested } from '../main/MyShowings/actions';
import { MY_SHOWINGS_FILTER } from '../main/MyShowings/types';
import { fetchClientsRequested } from '../main/CRM/actions';
// import { fetchCalendarShowingsRequested } from '../main/Calendar/actions';
import { fetchMyListingsRequested } from '../main/MyListings/actions';
import { getProfileData } from '../main/Profile/selectors';
import { fetchSSOUnconnectedListingRequested } from '../main/SearchListings/actions';
import { fetchListingsRequested } from '../main/Listings/actions';
import { getAuthEndPoint } from '../../utils/common';

/**
 * Upon booting the app, check the login status of the user that is
 * managed by the Stitch SDK.
 * @return {IterableIterator<object>}
 */
export function* initAuth(): Generator<any, any, any> {
    try {
        let user = yield call(getStitchUser);
        // if the user is an anon-user, lets search localstorage for another logged in user and see if we can use that
        // if(user.loggedInProviderName === 'anon-user') {
        //     const localStorageUsers = localStorage.getItem('__stitch.client.showingly-development-fndeq.all_auth_infos')
        //     if(localStorageUsers) {
        //         const parsedUsers = JSON.parse(localStorageUsers)
        //         //
        //         for(const parsedUser in parsedUsers) {
        //             //
        //             if(parsedUsers[parsedUser].logged_in_provider_name === 'local-userpass') {
        //                 user = parsedUsers[parsedUser]
        //             }
        //         }
        //     }
        // }
        const isRememberMe = localStorage.getItem('remember');
        const isOIDCUser = sessionStorage.getItem('isComingViaOIDC');
        const _queueData = sessionStorage.getItem('queueData');
        const queueData = (_queueData && JSON.parse(_queueData)) ?? null;

        if (isRememberMe === 'true') {
            if (user?.isLoggedIn && user.loggedInProviderName != 'anon-user') {
                // if (isOIDCUser && isOIDCUser === 'true') {
                //     // If the incoming user is via OIDC
                //     // Check for any sessions in the SDK and remove them
                //     yield put(logoutRequested());
                // } else {
                // load client data and profile data on page reload
                yield put(initializationDone(user?.isLoggedIn));
                yield put(userLoginRequestSucceeded(Realm.App));
                const agent = yield call(getAgentRecord, user);
                yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
                yield call(upsertAgentProfile, user, { lastSession: new Date() });
                yield put(getProfileDataRequested(false));
                yield put(fetchMyBrokeragesRequested(null));
                // yield put(fetchCalendarShowingsRequested(DateTime.fromMillis(Date.now())));
                yield put(fetchAgentSocialDocumentRequested(agent._id));
                yield put(fetchClientsRequested());
                yield put(fetchMyShowingsRequested(MY_SHOWINGS_FILTER.None, false));
                yield put(fetchMyListingsRequested(agent?._id.toString()));
                yield put(fetchListingsRequested(agent?._id.toString()));
            } else {
                yield put(initializationDone(false));
            }
        } else {
            if (user?.isLoggedIn) {
                // if (isOIDCUser && isOIDCUser === 'true') {
                //     yield put(logoutRequested());
                // }
                // else {
                const appRefreshFromSession = sessionStorage.getItem('appRefresh');
                const entryEndpointData = sessionStorage.getItem('entryEndpointData');
                if (appRefreshFromSession && entryEndpointData) {
                    // App was refreshed and the user session was found
                    // This indicates that the user came through OIDC or SHA Flow
                    // Keep them logged in for this window session only
                    // When the tab is closed, they will be logged out

                    // load client data and profile data on page reload
                    yield put(userLoginRequestSucceeded(Realm.App));
                    const agent = yield call(getAgentRecord, user);

                    yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));

                    yield call(upsertAgentProfile, user, { lastSession: new Date() });
                    yield put(getProfileDataRequested(false));
                    yield put(fetchMyBrokeragesRequested(null));
                    localStorage.removeItem('remember');
                    yield put(fetchMyListingsRequested(agent?._id.toString()));
                    yield put(fetchListingsRequested(agent?._id.toString()));
                    yield put(initializationDone(user?.isLoggedIn));
                } else if (sessionStorage.getItem('activeOIDCUser') === 'true') {
                    // load client data and profile data on page reload
                    yield put(userLoginRequestSucceeded(Realm.App));
                    const agent = yield call(getAgentRecord, user);
                    yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));

                    yield call(upsertAgentProfile, user, { lastSession: new Date() });
                    yield put(getProfileDataRequested(false));
                    localStorage.removeItem('remember');
                    yield put(fetchMyShowingsRequested(MY_SHOWINGS_FILTER.None, false));
                    yield put(fetchMyListingsRequested(agent?._id.toString()));
                    yield put(fetchListingsRequested(agent?._id.toString()));

                    yield put(initializationDone(user?.isLoggedIn));
                } else {
                    yield put(logoutRequested());
                    yield put(initializationDone(false));
                }
            } else {
                yield put(initializationDone(false));
            }
        }
    } catch (error) {
        yield put(initializationDone(false));
        //yield call(alert, 'here i am') //{ type: 'error', message: 'Failed to reach the servers. Please try again later.', });
    }
}

export function* loginRequested({ email, password, history }: any): Generator<any, any, any> {
    try {
        sessionStorage.clear();
        const scrubbedEmail = email.trim().toLocaleLowerCase();
        const loginRESP = yield call(login, scrubbedEmail, password);
        if (loginRESP) {
            const isAgent = yield call(findRecord, AGENTS, {
                email: scrubbedEmail,
            });
            if (isAgent) {
                const { client, user } = loginRESP;
                yield put(updateStitchUser(user));
                const agent = yield call(getAgentRecord, user);

                yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
                yield put(fetchMyBrokeragesRequested(null));
                yield call(upsertAgentProfile, user, { lastSession: new Date() });
                yield put(userLoginRequestSucceeded(client));
                yield put(getProfileDataRequested(false));
                yield put(fetchMyShowingsRequested(MY_SHOWINGS_FILTER.None, false));
                yield put(fetchClientsRequested());
                yield put(fetchMyListingsRequested(agent?._id.toString()));
                yield put(fetchListingsRequested(agent?._id.toString()));

                if (history) history.push('/search');
            } else {
                const err = 'No User Found';
                yield put(userLoginRequestFailed([err]));
            }
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(userLoginRequestFailed([err]));
    }
}

export function* agentLogin({ email, password, history }: any): Generator<any, any, any> {
    try {
        const rememberMe = true;
        localStorage.setItem('remember', rememberMe.toString());
        sessionStorage.clear();
        const scrubbedEmail = email.trim().toLocaleLowerCase();
        const loginRESP = yield call(login, scrubbedEmail, password);
        if (loginRESP) {
            const isAgent = yield call(findRecord, AGENTS, {
                email: scrubbedEmail,
            });
            if (isAgent) {
                const { client, user } = loginRESP;
                yield put(updateStitchUser(user));
                const agent = yield call(getAgentRecord, user);
                yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
                yield call(upsertAgentProfile, user, { lastSession: new Date() });
                yield put(agentLoginSucceeded(client));
                yield put(getProfileDataRequested(false));
                yield put(fetchListingsRequested(isAgent?._id.toString()));

                if (history) history.replace('/search');
            } else {
                const err = 'No User Found';
                yield put(agentLoginFailed([err]));
            }
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(agentLoginFailed([err]));
    }
}

export function* userLogoutRequested(): Generator<any, any, any> {
    try {
        const { clear } = localStorage;
        const { clear: clearSession } = sessionStorage;
        yield call(clear);
        yield call(clearSession);
        yield call(logout);
        yield put(logoutRequestSucceeded());
    } catch (error) {
        yield put(logoutRequestFailed([error]));
    }
}

export function* fetchMarkets(): Generator<any, any, any> {
    try {
        yield call(anonLogin);
        const markets = yield findRecords(MARKETS_COLLECTION_NAME, {});
        if (markets) {
            const sortedMarkets = markets.sort((marketA: any, marketB: any) =>
                marketA.name.localeCompare(marketB.name),
            );
            yield put(getMarketSucceeded(sortedMarkets));
        } else {
            yield put(getMarketFailed(['No Markets Found']));
        }
    } catch (error: any) {
        yield put(getMarketFailed(error.message));
    }
}

/**
 * Scrub the phone number and request sms code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param phoneNumber
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* verifyPhoneNumber(
    onSuccessHandler: any,
    onFailureHandler: any,
    phoneNumber: string,
    isResend = false,
): Generator<any, any, any> {
    const parsedPhoneNumber = isResend ? phoneNumber : scrubAndFormatPhoneNumber(phoneNumber, '');
    const currentPhoneNumber = yield select(getPendingPhoneNumber);
    if (parsedPhoneNumber !== currentPhoneNumber) {
        /*
         * Update the Auth store with pending phone number
         * so other screens can access this value
         */
        const user = yield select(getPendingUser);
        const newUser = {
            ...user,
            pendingPhoneNumber: parsedPhoneNumber,
        };
        yield put(updatePendingUser(newUser));
    }
    try {
        yield call(requestPhoneVerification, parsedPhoneNumber);
        yield put(onSuccessHandler(isResend));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(userLoginRequestFailed([errorMessage]));
        yield put(onFailureHandler());
    }
}

/**
 * Request email code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param email
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* verifyEmail(
    onSuccessHandler: any,
    onFailureHandler: any,
    email: string,
    isResend = false,
): Generator<any, any, any> {
    /*
     * Update the Auth store with pending email
     * so other screens can access this value
     */
    const user = yield select(getPendingUser);
    const newUser = {
        ...user,
        pendingEmail: email,
    };
    yield put(updatePendingUser(newUser));
    try {
        yield call(requestEmailVerification, email);
        yield put(onSuccessHandler(isResend));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(onFailureHandler());
        yield put(userLoginRequestFailed([errorMessage]));
    }
}

/**
 * Atlas text search for agents within a specific market
 * @param {searchText} text that the user searched in the input box
 * @param {marketName} market name that the agent selected
 */
function* fetchAgentDocuments({
    searchText,
    marketName = null,
    searchByAgentId = null,
}: any): Generator<any, any, any> {
    try {
        const projection: object = {
            _id: 1,
            stitchUserId: 1,
            firstName: 1,
            lastName: 1,
            profilePhotoUpload: 1,
            phoneNumber: 1,
            email: 1,
            markets: 1,
            status: 1,
            signUpCompleted: 1,
        };

        const potentialAgents = yield call(
            entrySearchForAgents,
            searchText,
            projection,
            null,
            marketName,
            searchByAgentId,
        );

        if (potentialAgents) {
            yield put(fetchAgentDocumentsSucceeded(potentialAgents));
        }
    } catch (errors: any) {
        yield put(fetchAgentsDocumentsFailed(errors));
    }
}

/**
 * Atlas text search for agents within a specific market
 * @param {searchText} text that the user searched in the input box
 * @param {marketName} market name that the agent selected
 */
function* textSearchEntryAgents({
    searchText,
    searchByAgentId = null,
}: any): Generator<any, any, any> {
    try {
        const market = yield select(getAuthMarket);
        const marketName = market.name;

        const potentialAgents = yield call(
            textSearchAgents,
            searchText,
            marketName,
            searchByAgentId,
        );

        if (potentialAgents) {
            yield put(textSearchAgentsSucceeded(potentialAgents));
        }
    } catch (errors) {
        yield put(textSearchAgentsFailed(errors as any[] | undefined));
    }
}

/**
 *
 * @param {method} 'phone' | 'email' to check what verification method to use
 * @param {receiver} receiving email or phonenumber
 * @param {isResend} has the verification been resent
 */
export function* sendVerification({
    method,
    receiver,
    isResend,
}: ReturnType<typeof sendVerificationMessageRequested>): Generator<any, any, any> {
    const pendingUser = yield select(getAuthAgent);
    if (method === 'phone' && !pendingUser.pendingEmail) {
        yield call(
            verifyPhoneNumber,
            sendVerificationMessageSucceedeed,
            sendVerificationMessageFailed,
            receiver,
            isResend,
        );
    } else if (method === 'email' || pendingUser?.email) {
        yield call(
            verifyEmail,
            sendVerificationMessageSucceedeed,
            sendVerificationMessageFailed,
            receiver,
            isResend,
        );
    }
}

/**
 * Call common auth handler for verifying phone code
 * @param {string|number} phoneCode The code that was sent via SMS to the user's phone.
 * @returns {IterableIterator<object>}
 * @param {emailOrPhone} optional phone number or email, used when sending a verification isn't done through a pending user
 */
export function* verifyEntryCode({
    method,
    code,
    emailOrPhone,
    linkingAgentObjId,
}: ReturnType<typeof verifyCodeRequested>): Generator<any, any, any> {
    var pendingUser;
    var user;
    user = yield call(anonLogin);
    pendingUser = yield select(getAuthAgent);

    const stitchUser = yield select(getStitchUser);
    const stitchUserId = stitchUser?.id;

    try {
        const check =
            method === 'phone'
                ? yield call(
                      callStitchFunction,
                      'confirmPhoneVerification',
                      emailOrPhone ? emailOrPhone : pendingUser.phoneNumber,
                      code,
                      USER_TYPE_AGENT,
                      false,
                      stitchUserId ? stitchUserId : pendingUser.stitchUserId,
                      false,
                  )
                : yield call(
                      callStitchFunction,
                      'verifyEmail',
                      emailOrPhone ? emailOrPhone : pendingUser.email,
                      code,
                      USER_TYPE_AGENT,
                      true,
                  );
        if (check) {
            if (emailOrPhone && linkingAgentObjId) {
                // attempt Linked License Flow
                yield put(linkAgentRequested(linkingAgentObjId));
            } else {
                yield put(verifyCodeSucceedeed());
            }
        } else {
            if (emailOrPhone) {
                yield put(
                    verifyLicenseCodeFailed([
                        'There was an error verifying your code',
                        'Code: 0100-3',
                    ]),
                );
            } else {
                yield put(
                    verifyCodeFailed([
                        'There was an error processing your account',
                        'Code: 0100-2',
                    ]),
                );
            }
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(verifyCodeFailed([error, 'Code: 0100-1']));
    }
}

/**
 * Request new user to be created in Stitch (also triggers email confirmation to be sent)
 * Then add user to consumer collection.
 *
 * @param navigation
 * @param {string} email
 * @param {string} password
 * @param {string|number} marketName
 * @returns {IterableIterator<object>}
 */
//
export function* registerUser({
    userEmail,
    password,
    userObjectId,
}: any): Generator<any, any, any> {
    const scrubbedEmail = scrubEmail(userEmail);
    const rememberMe = true;
    localStorage.setItem('remember', rememberMe.toString());
    try {
        // create new realm user
        yield call(requestUserRegistration, scrubbedEmail, password);

        // login the new user
        const loginRESP = yield call(login, scrubbedEmail, password);
        const newStitchUserId = loginRESP?.user?.id;
        const newUser = {
            email: scrubbedEmail.trim().toLocaleLowerCase(),
            status: 'active',
            signUpCompleted: true,
            stitchUserId: newStitchUserId,
            signUpDate: new Date(),
        };

        const anon = yield call(anonLogin);
        const response = yield call(
            callStitchFunction,
            'updateDocumentAsAdmin',
            AGENTS,
            userObjectId,
            newUser,
        );

        // if the user has been successfully created, lets go ahead and log em in
        if (response) {
            yield put(registrationRequestSucceeded());

            const { user } = yield call(login, scrubbedEmail, password);
            yield put(updateStitchUser(user));
            // load client data and profile data on page reload
            yield put(userLoginRequestSucceeded(Realm.App));
            const agent = yield call(getAgentRecord, user);

            yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
            yield call(upsertAgentProfile, user, { lastSession: new Date() });
            yield put(getProfileDataRequested(false));
            yield put(initializationDone(true));

            yield call(
                callStitchFunction,
                'markAgentsHidden',
                scrubbedEmail,
                agent.phoneNumber,
                agent.firstName,
                agent.lastName,
            );
        }
    } catch (error) {
        yield put(registrationRequestFailed([error as string]));
    }
}

/**
 * Re-alias user email, then request new user to be created in Realm.
 * Then update user in agent collection.
 *
 * @param userEmail
 * @param {string} password
 * @param {string} userObjectId
 * @returns {IterableIterator<object>}
 */
//
export function* resetPassword({
    userEmail,
    password,
    userObjectId,
}: any): Generator<any, any, any> {
    const scrubbedEmail = scrubEmail(userEmail);
    const randomNum = Math.floor(Math.random() * 999999) + 1000;
    const emailFront =
        scrubbedEmail.indexOf('+') != -1 ? scrubbedEmail.indexOf('+') : scrubbedEmail.indexOf('@');
    const emailAlias =
        scrubbedEmail.substring(0, emailFront) +
        '+' +
        randomNum +
        scrubbedEmail.substring(scrubbedEmail.indexOf('@'));
    const rememberMe = true;
    localStorage.setItem('remember', rememberMe.toString());
    try {
        // create new realm user
        yield call(requestUserRegistration, emailAlias, password);

        // login the new user
        const loginRESP = yield call(login, emailAlias, password);
        const newStitchUserId = loginRESP?.user?.id;
        const newUser = {
            email: emailAlias.trim().toLocaleLowerCase(),
            status: 'active',
            signUpCompleted: true,
            stitchUserId: newStitchUserId,
            signUpDate: new Date(),
        };

        const anon = yield call(anonLogin);
        const response = yield call(
            callStitchFunction,
            'updateDocumentAsAdmin',
            AGENTS,
            userObjectId,
            newUser,
        );

        // if the user has been successfully created, lets go ahead and log em in
        if (response) {
            yield put(resetPasswordSucceeded());

            const { user } = yield call(login, emailAlias, password);
            yield put(updateStitchUser(user));
            // load client data and profile data on page reload
            yield put(userLoginRequestSucceeded(Realm.App));
            const agent = yield call(getAgentRecord, user);

            yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
            yield call(upsertAgentProfile, user, { lastSession: new Date() });
            yield put(getProfileDataRequested(false));
            yield put(initializationDone(true));
        }
    } catch (error: any) {
        yield put(resetPassFailed([error]));
    }
}

type MlsProvider = 'neren' | 'recolorado' | 'sdar' | 'sef';

/**
 * Requests access token from the code provider for OIDC
 */
export function* requestTokenForSSO({
    code,
    codeProviderName,
    redirectURI,
    agentMlsId,
    key,
    dtm,
    withoutOIDC,
}: any): Generator<any, any, any> {
    try {
        const anon = yield call(anonLogin);
        if (code) {
            if (agentMlsId && dtm !== 'cypressTestDtm') {
                // Platform has received enough info, i.e. code and other required params
                // Proceed to authorizing the user to use the platform
                const payload = {
                    code: code,
                    codeProviderName: codeProviderName?.toLowerCase(),
                    redirect_uri: redirectURI,
                };
                const token = yield call(callStitchFunction, 'getAccessTokenOIDC', payload);
                yield put(requestAccessTokenSSOSucceeded(token, codeProviderName, agentMlsId));
            } else {
                // We did not receive the agentMlsId, we need to fetch this using the code
                const payload = {
                    code: code,
                    codeProviderName: codeProviderName?.toLowerCase(),
                    redirect_uri: redirectURI,
                };
                const token = yield call(callStitchFunction, 'getAccessTokenOIDC', payload);
                yield put(requestAccessTokenSSOSucceeded(token, codeProviderName, null));
            }
        }
        // Verify SHA Document and other processing
        else {
            const proceedingAgent = yield call(
                callStitchFunction,
                'getVerificationDataOIDC',
                key,
                dtm,
                codeProviderName,
                agentMlsId,
            );
            if (
                proceedingAgent?.length > 0 ||
                (key === 'cypressTestKey' && dtm === 'cypressTestDtm')
            ) {
                /**
                 * SSO-POTENTIAL-ERR
                 * r: not market exclusive query
                 */
                if (dtm !== 'cypressTestDtm') {
                    const prevSSODocument = yield call(
                        callStitchFunction,
                        'findRecordForAnon',
                        {
                            firstSSODate: { $ne: null },
                            'markets.agentMlsId': agentMlsId,
                            'markets.mlsName': codeProviderName,
                        },
                        AGENTS,
                    );
                    var updateFields: any = {
                        lastKeyDtmPairUsed: `${dtm}`,
                    };
                    if (!prevSSODocument) {
                        updateFields['firstSSODate'] = new Date();
                    }

                    // Update agent record with used dtm
                    /**
                     * SSO-POTENTIAL-ERR
                     * r: not market exclusive query
                     */
                    yield call(upsertAgentRecordAsAnon, updateFields, {
                        'markets.agentMlsId': agentMlsId,
                        'markets.mlsName': codeProviderName,
                    });
                }

                const data = proceedingAgent[0];

                /*
                 * Skip OIDC sign in flow for ODESSA
                 *
                 */

                if (withoutOIDC) {
                    const prevParams = JSON.parse(
                        sessionStorage.getItem('entryEndpointData') as any,
                    );
                    const paramsToEncode: any = {
                        ...prevParams,
                        proceedingAgentID: data?.stitchUserId,
                    };

                    sessionStorage.setItem('entryEndpointData', JSON.stringify(paramsToEncode));

                    yield put(
                        requestAccessTokenSSOSucceeded(null, codeProviderName, agentMlsId, true),
                    );
                } else {
                    // Open Authorization Flow at this Point
                    // Currently does for Miami-SEF Only, will be changed later for multiple markets
                    const parsedSearch: any = sessionStorage.getItem('entryEndpointData');
                    const existingParams = JSON.parse(parsedSearch);
                    const paramsToEncode = {
                        ...existingParams,
                        proceedingAgentID: data?.stitchUserId,
                    };
                    const paramsStringified = JSON.stringify(paramsToEncode);

                    // beta subdomain is only used on development when testing
                    const BETA = process.env.REACT_APP_MODE !== 'production' ? 'beta.' : '';

                    const redirectUrl = `https://${BETA}agent.showingly.com/entry/search`;
                    const state = Buffer.from(paramsStringified).toString('base64');

                    window.open(
                        getAuthEndPoint(
                            codeProviderName?.toLocaleLowerCase() as MlsProvider,
                            redirectUrl,
                            state,
                        ),
                        '_self',
                    );
                }
            }
        }
    } catch (error) {
        yield call(
            callStitchFunction,
            'logSystemError',
            'requestTokenForSSO',
            'realm',
            null,
            'no message',
            null,
            {
                codeProviderName,
                redirectURI,
                agentMlsId,
                withoutOIDC,
            },
            { errData: error },
        );
        yield put(requestAccessTokenSSOFailed([error as string]));
    }
}

/**
 * Requests access token from the code provider for OIDC
 */
export function* proceedForUserAuthSSO({
    codeProviderName,
    retrievedToken,
    agentMlsId,
    withoutOIDC,
}: any): Generator<any, any, any> {
    var errData: any = {};
    var logErr: boolean = false;
    var location = 0;
    var location2 = 0;
    try {
        if (!withoutOIDC && retrievedToken?.error) {
            errData['retrievedTokenError'] = retrievedToken.error;
            location = 1;
            logErr = true;
            // error
            /**
             * SSO-POTENTIAL-ERR
             * r: fallthrough
             */
        } else {
            let payload;
            let verifyRESP;
            const sessionParams: any = sessionStorage.getItem('entryEndpointData');
            const sessionQueue: any = sessionStorage.getItem('queueData');
            const parsedParams = JSON.parse(sessionParams);
            const parsedQueue = JSON.parse(sessionQueue);

            errData['sessionParams'] = sessionParams;
            errData['parsedParams'] = parsedParams;

            /*
             * ODESSA don't need to use OpenID auth so we need to bypass this part
             *
             */
            if (withoutOIDC) {
                if (agentMlsId) {
                    payload = {
                        agentMlsId: agentMlsId,
                        agentStitchID: parsedParams?.proceedingAgentID,
                    };
                    errData['payloadIF'] = payload;
                }
            } else {
                const providerConfig = yield call(callStitchFunction, 'getProviderInfo', {
                    codeProviderName: codeProviderName?.toLowerCase(),
                });

                errData['providerConfig'] = providerConfig;

                const fetchedJWK = providerConfig?.keys[0];

                const publicKey = yield call(parseJwk, fetchedJWK);

                verifyRESP = yield call(jwtVerify, retrievedToken?.id_token, publicKey, {
                    issuer: providerConfig?.issuer,
                });
                errData['verifyRESP'] = verifyRESP;

                if (agentMlsId) {
                    payload = {
                        agentMlsId: agentMlsId,
                        agentStitchID: parsedParams?.proceedingAgentID,
                    };
                    errData['payloadIF'] = payload;
                } else {
                    // Agent Mls ID was not found, attempt to extract this from the verify token response
                    const { payload: verifyJWTPayload = {} } = verifyRESP;
                    if (verifyJWTPayload) {
                        let agentMlsId;

                        // Neren don't give us the agentID but tUserCode
                        if (codeProviderName.toLowerCase() === 'neren') {
                            const agentRecord = yield call(
                                callStitchFunction,
                                'findRecordForAnon',
                                {
                                    'markets.agentMlsUserCode':
                                        verifyJWTPayload[
                                            'https://mmsi-neren/cust_no'
                                        ].toLowerCase(),
                                    'markets.mlsName': 'neren',
                                },
                                AGENTS,
                            );
                            agentMlsId = agentRecord?.markets[0]?.agentMlsId;
                        } else {
                            agentMlsId =
                                verifyJWTPayload?.loginid ||
                                verifyJWTPayload[
                                    `https://mmsi-${codeProviderName?.toLowerCase()}/login`
                                ];
                        }

                        /**
                         * SSO-POTENTIAL-ERR
                         * r: not market exclusive query
                         */
                        const {
                            0: { stitchUserId: requestingAgentStitchID },
                        } = yield call(runQuery, AGENTS, {
                            $and: [
                                {
                                    $or: [
                                        { 'markets.agentMlsId': agentMlsId },
                                        { 'markets.agentMlsId': agentMlsId.toLowerCase() },
                                    ],
                                },
                                {
                                    $or: [
                                        { 'markets.mlsName': codeProviderName },
                                        { 'markets.mlsName': codeProviderName.toLowerCase() },
                                    ],
                                },
                            ],
                        });
                        payload = {
                            agentMlsId: agentMlsId,
                            agentStitchID: requestingAgentStitchID,
                        };
                        errData['payloadELSE'] = payload;
                    }
                }
            }

            if (
                (verifyRESP || withoutOIDC) &&
                payload &&
                payload.agentMlsId &&
                payload.agentStitchID
            ) {
                location2 = 1;
                const { client, user } = yield call(customAuthLogin, payload);
                location2 = 2;
                if (client && user) {
                    location2 = 3;
                    const { identities } = user;
                    const userID = identities[0]?.id;
                    yield call(upsertTempUser, userID, user?.id);
                    location2 = 4;
                    // load client data and profile data on page reload
                    yield put(userLoginRequestSucceeded(Realm.App));
                    const agent = yield call(getAgentRecord, user);

                    yield put(storeAgentRecord({ ...agent, lastSession: new Date() }));
                    if (agent?.firstSSODate) {
                        yield call(upsertAgentProfile, user, { lastSession: new Date() });
                    } else {
                        yield call(upsertAgentProfile, user, {
                            lastSession: new Date(),
                            firstSSODate: new Date(),
                        });
                    }
                    // note: SSO-POTENTIAL-ERR => note to easily find issue
                    const profileData = yield select(getProfileData);
                    if (!profileData?._id) {
                        yield put(getProfileDataRequested(true));
                        yield put(fetchMyBrokeragesRequested(null));
                        yield put(fetchMyListingsRequested(agent?._id.toString()));
                        yield put(fetchListingsRequested(agent?._id.toString()));
                        if (parsedQueue['unconnected'] === 'true') {
                            yield put(
                                fetchSSOUnconnectedListingRequested(
                                    parsedQueue['mlsname'],
                                    parsedQueue['listingid'],
                                ),
                            );
                        }
                    }
                    sessionStorage.setItem('activeOIDCUser', 'true');
                } else {
                    errData['client'] = client;
                    errData['user'] = user;
                    location = 2;
                    logErr = true;
                }
            } else {
                location = 3;
                logErr = true;
            }
            /**
             * SSO-POTENTIAL-ERR
             * r: no case if if block exits false
             */
        }
    } catch (error) {
        // eslint-disable-next-line
        /**
         * SSO-POTENTIAL-ERR
         * r: no catch clause
         */
        errData['caughtError'] = error;
        logErr = true;
    }

    // if an error needs to be logged, do so
    if (logErr) {
        try {
            yield call(
                callStitchFunction,
                'logSystemError',
                'proceedForUserAuthSSO', // functionName,
                'realm', // application,
                null, // location,
                'no message', // message,
                null, // clientId,
                {
                    codeProviderName,
                    agentMlsId,
                }, // params,
                {
                    location,
                    location2,
                    retrievedTokenExists: !!retrievedToken?.length,
                    errData,
                }, // otherData,
            );
        } catch (err) {
            // intentionally do nothing
        }
    }
}

/**
 * Fetch the consumer record from the database
 */
// TO DO: Make this a find one
export function* fetchConsumerRecord({ consumerUserId }: any): Generator<any, any, any> {
    try {
        const consumerRecord = yield call(findRecord, CONSUMERS, { stitchUserId: consumerUserId });
        yield put(fetchConsumerRecordSucceeded(consumerRecord));
    } catch (error) {
        yield put(fetchConsumerRecordFailed(error));
    }
}

export function* acceptTOS({ urls, tempUserId }: any): Generator<any, any, any> {
    try {
        yield call(updateTosAcceptance, USER_TYPE_AGENT, urls, tempUserId);
        yield put(acceptTermsOfServiceSucceeded());
        yield put(getProfileDataRequested(true));
    } catch (error) {
        yield put(acceptTermsOfServiceFailed(error));
    }
}

export function* sendAppDownloadText({ phone }: any): Generator<any, any, any> {
    try {
        yield call(callStitchFunction, 'sendAppDownloadLinkText', phone);
    } catch (error) {}
}

export function* requestPasswordReset({ email }: any): Generator<any> {
    try {
        if (process.env.REACT_APP_STITCH_APP_ID) {
            const App = Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID);
            const result = App.emailPasswordAuth.sendResetPasswordEmail(email);

            if (result !== undefined) {
                yield put(passwordResetSucceeded());
            } else {
                yield put(passwordResetFailed('Error'));
            }
        }
    } catch (error) {
        yield put(passwordResetFailed('Error'));
    }
}
export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(INITIALIZATION, initAuth),
        yield takeLatest(USER_LOGIN_REQUESTED, loginRequested),
        yield takeLatest(AGENT_LOGIN_REQUESTED, agentLogin),
        yield takeLatest(LOG_OUT_REQUESTED, userLogoutRequested),
        yield takeLatest(RESET_PASSWORD_REQUESTED, requestPasswordReset),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Market && action.status === STATUS.Requested,
            fetchMarkets,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Agent_Documents && action.status === STATUS.Requested,
            fetchAgentDocuments,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Agent_Search && action.status === STATUS.Requested,
            textSearchEntryAgents,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Verification && action.status === STATUS.Requested,
            sendVerification,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.VerifyCode && action.status === STATUS.Requested,
            verifyEntryCode,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Registration && action.status === STATUS.Requested,
            registerUser,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.ResetPassword && action.status === STATUS.Requested,
            resetPassword,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.OIDC_RequestToken && action.status === STATUS.Requested,
            requestTokenForSSO,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.OIDC_RequestToken && action.status === STATUS.Succeeded,
            proceedForUserAuthSSO,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.FetchConsumerRecord &&
                action.status === STATUS.Requested,
            fetchConsumerRecord,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.AcceptTermsOfService &&
                action.status === STATUS.Requested,
            acceptTOS,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.SendAppDownloadLink &&
                action.status === STATUS.Requested,
            sendAppDownloadText,
        ),
    ]);
}
