import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import indexTheme from '../../../../theme';
import Tag from '../../../../components/Tag';
import Typography from '../../../../components/Typography';

interface Props {
    userType: string;
    users: any[];
}

const UserSection = ({ userType, users }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const UserCard = ({ user }: { user: any }) => {
        return (
            <div className={styleSheet.userCard}>
                <Typography
                    textStyle={'h4'}
                    color={indexTheme.palette.mediumGrey}
                    style={{
                        marginTop: 16,
                    }}
                >
                    {user.firstName} {user.lastName}
                </Typography>
                <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                    {userType === 'Listing Agents' ? user?.type : 'Seller Client'}
                </Typography>
                <div style={{ marginTop: 16 }} className={styleSheet.textAndTagContainer}>
                    {user?.canApprove && (
                        <Tag
                            style={{ width: 114, marginRight: 16 }}
                            text={'Can Approve'}
                            color={'green'}
                        />
                    )}
                    {user?.notificationSettings?.includes('text') && (
                        <Tag
                            text="Text"
                            color="mediumGrey"
                            style={{
                                width: 57,
                                marginRight: 16,
                                color: indexTheme.palette.darkGrey,
                            }}
                        />
                    )}
                    {user?.notificationSettings.includes('email') && (
                        <Tag
                            text="Email"
                            color="mediumGrey"
                            style={{
                                width: 57,
                                marginRight: 16,
                                color: indexTheme.palette.darkGrey,
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };
    return (
        <div>
            <Typography textStyle={'b1'}>{userType}</Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {users?.map((user) => {
                    return <UserCard user={user} />;
                })}
            </div>
        </div>
    );
};

export default UserSection;
