import React from 'react';

import styles from './index.module.css';

const Suspended = () => {
    return (
        <div className={styles.root}>
            <h1>Your Account is Suspended</h1>
            <p>Please contact support for more information</p>
            <p>Email us at support@getshowingly.com or call us at (833) 217-7578</p>
        </div>
    );
};

export default Suspended;
