import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { getCurrentListing } from './selectors';
import { ShowingType } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { fetchListingRequested } from '../SearchListings/actions';
import { formatCurrency } from '../../../utils/common/transforms';
import ButtonComponent from '../../../components/ButtonComponent';
import ShoppingCart from '../../../images/shoppingCart.svg';
import { queueShowingInteractionRequested } from '../QueuedShowings/actions';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { getQueueLoading, getShowingQueue } from '../QueuedShowings/selectors';
// import { Showing } from '../../../../../showingly-web-lib/dist/constants';
import { Showing } from 'web-lib/dist/constants';
import { ConnectedScheduleScreen } from '../ShowingRequests/ScheduleScreen/index';
import ListingDetailsComponent from './ListingDetailsComponent';
import indexTheme from '../../../theme';
import AddClientDrawer from '../../../components/AddClientDrawer';
import { Typography } from '../../../components';
import { getSubscriptionTier } from '../Profile/selectors';
import { updateUpgradeModalVisible } from '../Profile/actions';

interface Props {}

// Wireframes to this page as of11/20/2021: https://www.figma.com/file/Q1SANfbaws9b6TnmkhQYkG/Agent-Web-2.0?node-id=19%3A140

const ListingDetails = (props: Props) => {
    const listing = useSelector(getCurrentListing);
    const showingQueue = useSelector(getShowingQueue);
    const queueLoading = useSelector(getQueueLoading);
    const dispatch = useDispatch();
    const currentPlanTier = useSelector(getSubscriptionTier);
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);
    const [showAddClientDrawer, setShowAddClientDrawer] = useState<boolean>(false);

    const minutesUtcOffset = new Date().getTimezoneOffset();
    const utcOffset = Math.floor(minutesUtcOffset / 60);

    // If the user comes in from a link or refreshes the page, make it so we fetch the listing they're looking at.
    const history = useHistory();
    const { pathname } = history.location;
    const splitPath = pathname.split('/');

    useEffect(() => {
        if (!listing?._id) {
            dispatch(
                fetchListingRequested(
                    splitPath[splitPath.length - 1],
                    true,
                    splitPath[splitPath.length - 2],
                ),
            );
        }
    }, []);

    useEffect(() => {
        showingQueue?.map((showing: Showing) => {
            if (
                JSON.stringify(showing?.listing?._id) === JSON.stringify(listing?._id) &&
                queuePressed
            ) {
                setQueued(true);
                setQueuePressed(false);
            }
        });
    }, [showingQueue]);

    const [queued, setQueued] = useState(false);
    const [queuePressed, setQueuePressed] = useState(false);
    function Alert(props: any) {
        return <MuiAlert color="info" elevation={6} variant="filled" {...props} />;
    }

    const connected = !!listing?.agentListing?.listingId;

    const header = (
        <div className={styleSheet.header}>
            <div className={styleSheet.leftHeader}>
                <span className={styleSheet.price}>{'$' + formatCurrency(listing?.listPrice)}</span>
                <div className={styleSheet.verticalSeparator} />
                <span className={styleSheet.address1}>
                    {listing?.address?.unit
                        ? listing?.address?.full.includes(listing?.address?.unit)
                            ? listing?.address?.full
                            : listing?.address?.full + ' ' + listing?.address?.unit
                        : listing?.address?.full}
                </span>
                <span className={styleSheet.address2}>
                    {listing?.address?.city}, {listing?.address?.state} {listing?.address?.postal}
                </span>
                {listing?.mlsList?.length && listing?.mlsList[0]?.standardStatus === 'Active' ? (
                    <span className={styleSheet.tag}>For Sale</span>
                ) : null}
            </div>
            {!connected || listing?.agentListing?.acceptingShowings ? (
                <div className={styleSheet.buttonContainer}>
                    <ButtonComponent
                        className={styleSheet.button}
                        fill
                        width={257}
                        onClick={() => {
                            setShowScheduleDrawer(true);
                        }}
                    >
                        {connected ? 'Schedule' : 'Log'}
                    </ButtonComponent>
                    <ButtonComponent
                        width={121}
                        onClick={() => {
                            if (currentPlanTier > 0 || agentHasFreeTrial) {
                                setQueuePressed(true);
                                dispatch(
                                    queueShowingInteractionRequested(
                                        'add',
                                        {
                                            listing,
                                            showingType: ShowingType.Agent,
                                            listingId: listing?._id.toString(),
                                            startDate: '',
                                            endDate: '',
                                            clientId: null,
                                            utcOffset,
                                        },
                                        -1,
                                    ),
                                );
                            } else {
                                dispatch(updateUpgradeModalVisible(true));
                            }
                        }}
                        noop={queueLoading}
                    >
                        <div className={styleSheet.queue}>
                            <img style={{ marginTop: 2 }} src={ShoppingCart} />
                            Queue
                        </div>
                    </ButtonComponent>
                </div>
            ) : (
                <Typography
                    textStyle={'p1'}
                    color={theme.palette.mediumGrey}
                    style={{ fontStyle: 'italic' }}
                >
                    Currently not accepting requests
                </Typography>
            )}
        </div>
    );

    return (
        <>
            <ConnectedScheduleScreen
                listing={listing}
                opType={'Schedule'}
                onClose={() => setShowScheduleDrawer(false)}
                clientDrawerInteract={setShowAddClientDrawer}
                visible={showScheduleDrawer}
            />
            <AddClientDrawer
                visible={showAddClientDrawer}
                onClose={() => setShowAddClientDrawer(false)}
                backgroundOpacity={0}
            />
            <ListingDetailsComponent
                listing={listing}
                header={
                    <>
                        {header}
                        <div className={styleSheet.separator} />
                    </>
                }
            />
            <Snackbar open={queued} autoHideDuration={3500} onClose={() => setQueued(false)}>
                <Alert className={styleSheet.snackbarAlert} severity={'success'}>
                    <div>
                        Queued
                        <div
                            style={{
                                marginLeft: 50,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                document.getElementById('queueCartAuthApp')?.click();
                            }}
                        >
                            View My Queue
                        </div>
                    </div>
                </Alert>
            </Snackbar>
        </>
    );
};
export default ListingDetails;
