export default (theme: any) => {
    const textStyles = {
        fontFamily: 'Poppins-medium',
        fontSize: 16,
        color: theme.palette.black,
    };
    const mobilePadding = {
        top: 0,
        right: 20,
        bottom: 0,
        left: 18
    };
    return {
        root: {},
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginVertical(16),
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        loaderContainer: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        leftHeader: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        subHeader: {
            fontSize: 18,
            fontWeight: 'bold'
        },
        textRight: {
            textAlign: 'right'
        },
        textCenter: {
            textAlign: 'center'
        },
        price: {
            ...textStyles,
            fontFamily: 'Poppins-semiBold',
            fontSize: 26,
        },
        address1: {
            ...textStyles,
            fontSize: 18,
            fontFamily: 'Poppins-semiBold',
            marginRight: 6,
        },
        address2: {
            ...textStyles,
            fontSize: 18,
            marginRight: 26,
        },
        tag: {
            backgroundColor: theme.palette.lightGreen,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.green,
            fontSize: 14,
        },
        redTag: {
            backgroundColor: theme.palette.lightRed,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.red,
            fontSize: 14,
        },
        greyTag: {
            backgroundColor: theme.palette.background,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.mediumGrey,
            fontSize: 14,
            marginLeft: 26,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            ...theme.marginHorizontal(16),
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        overlayBackground: {
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 999999999999,
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        whiteContainer: {
            width: 812,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            padding: {
                left: 48,
                right: 48,
                top: 27,
                bottom: 24,
            },
            flexDirection: 'column',
            position: 'relative',
        },
        whiteContainerMobile: {
            top: 20,
            position: 'fixed',
            width: '100%',
            padding: 20,
            height: '50%',
            textAlign: 'center',
            backgroundColor: 'white'
        },
        scheduledHeader: {
            ...theme.typography.bold,
            color: theme.palette.darkGrey,
            fontSize: 26,
            height: 34,
        },
        scheduledHeaderMobile: {
            ...theme.typography.bold,
            color: theme.palette.blue,
            fontSize: 30,
            height: 34,
        },
        scheduledBody: {
            marginTop: 10,
            ...theme.typography.semiBold,
            color: theme.palette.darkGrey,
            fontSize: 18,
            height: 52,
        },
        scheduledBodyTitleMobile: {
            marginTop: 30,
            ...theme.typography.semiBold,
            color: theme.palette.blue,
            fontSize: 26,
            height: 52,
            padding: 20
        },
        scheduledBodyMobile: {
            marginTop: 10,
            ...theme.typography.semiBold,
            color: theme.palette.mediumGrey,
            fontSize: 18,
            height: 52,
            padding: 20
        },
        scheduledButtonMobile: {
            paddingTop: 100
        },
        closeIconContainer: {
            position: 'absolute',
            top: 27.5,
            right: 27.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
        },
        closeIcon: {
            height: 12.06,
            width: 12.06,
        },
        table: {
            display: 'table'
        },
        tableRow: {
            display: 'table-row'
        },
        tableCol: {
            display: 'table-cell',
        },
    };
};
