import {
    CLEAR_SELECTED_LISTING_AND_SHOWING,
    REFETCH_CURRENT_LISTING_AND_SHOWING,
} from './constants';

export const clearCurrentListingAndShowing = () =>
    <const>{
        type: CLEAR_SELECTED_LISTING_AND_SHOWING,
    };

export const refetchCurrentListingAndShowing = () =>
    <const>{
        type: REFETCH_CURRENT_LISTING_AND_SHOWING,
    };
