import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { SlideOutDrawer } from 'web-lib';
import { SelectedAgentSocial, SocialUserSimple } from '../../../../../utils/constants';
import { Assets } from 'asset-lib/src/index';

import styles from './index.module.css';
import { clearSelectedAgent, setSelectedAgent } from '../../actions';

interface FollowersPanelProps {
    followers?: Array<SocialUserSimple>;
    following?: Array<SocialUserSimple>;
    onExit: Function;
}

const FollowerOrFollowingPanel = ({
    followers = [],
    following = [],
    onExit,
}: FollowersPanelProps) => {
    const dispatch = useDispatch();
    const clearSelectedAgent: Function = () => dispatch(clearSelectedAgent());
    const setSelectedAgent: Function = (agent: SelectedAgentSocial) =>
        dispatch(setSelectedAgent(agent));

    const FollowersList = () => {
        if (followers.length) {
            return (
                <>
                    <span className={styles.listTitle}>Followers</span>
                    {followers.map((user, index) => (
                        <div
                            key={index}
                            className={styles.followerCard}
                            onClick={() => {
                                clearSelectedAgent();
                                setSelectedAgent({
                                    agentId: user.agentObjectId,
                                    name: user.firstName + ' ' + user.lastName,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    photoUri: user.profilePhotoUpload?.uri,
                                });
                                onExit();
                            }}
                        >
                            <img
                                className={styles.image}
                                src={user.profilePhotoUpload?.uri || Assets.Svgs.DefaultUser}
                                onError={(e) => (e.currentTarget.src = Assets.Svgs.DefaultUser)}
                            />
                            <span className={styles.name}>
                                {user.firstName + ' ' + user.lastName}
                            </span>
                        </div>
                    ))}
                </>
            );
        } else {
            //Ideally the sidebar never opens up if there are no followers
            //But just in case, we have a message to show them.
            return <span>Looks like you dont have any followers</span>;
        }
    };

    const FollowingList = () => {
        if (following.length) {
            return (
                <>
                    <span className={styles.listTitle}>Following</span>
                    {following.map((user, index) => (
                        <div
                            key={index}
                            className={styles.followerCard}
                            onClick={() => {
                                clearSelectedAgent();
                                setSelectedAgent({
                                    agentId: user.agentObjectId,
                                    name: user.firstName + ' ' + user.lastName,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    photoUri: user.profilePhotoUpload?.uri,
                                });
                                onExit();
                            }}
                        >
                            <img
                                className={styles.image}
                                src={user.profilePhotoUpload?.uri || Assets.Svgs.DefaultUser}
                                onError={(e) => (e.currentTarget.src = Assets.Svgs.DefaultUser)}
                            />
                            <span className={styles.name}>
                                {user.firstName + ' ' + user.lastName}
                            </span>
                        </div>
                    ))}
                </>
            );
        } else {
            //Ideally the sidebar never opens up if there is no one that you are following.
            //But just in case, we have a message to show them.
            return <span>Looks like no one is following you.</span>;
        }
    };

    return (
        <>
            {Boolean(followers.length) && (
                <SlideOutDrawer
                    drawerContentContainerStyle={styles.drawerContainer}
                    width={'40vw'}
                    children={<FollowersList />}
                    onCloseModal={() => onExit()}
                />
            )}

            {Boolean(following.length) && (
                <SlideOutDrawer
                    drawerContentContainerStyle={styles.drawerContainer}
                    width={'40vw'}
                    children={<FollowingList />}
                    onCloseModal={() => onExit()}
                />
            )}
        </>
    );
};

export default FollowerOrFollowingPanel;
