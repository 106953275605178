import { Listing, MLS_AUTH_ENDPOINTS, STATUS_DISPLAY_LABELS } from '../constants';
import { DateTime } from 'luxon';
import { BSON } from 'realm-web';
import { useEffect, useState } from 'react';
import sha256 from 'crypto-js/sha256';
import { lab } from 'd3';

export const CAL_CARD_CONFIRMED = 'Confirmed';
export const CAL_CARD_PENDING = 'Pending';
export const CAL_CARD_REQUESTED = 'Client Request';
export const CAL_CARD_COMPLETED = 'Completed';
export const CAL_CARD_CANCELLED = 'Cancelled';
export const CAL_CARD_DENIED = 'Denied';
export const CAL_CARD_QUEUED = 'Queued';
export const CAL_CARD_STATUSES = [
    CAL_CARD_CONFIRMED,
    CAL_CARD_PENDING,
    CAL_CARD_REQUESTED,
    CAL_CARD_COMPLETED,
    CAL_CARD_CANCELLED,
    CAL_CARD_DENIED,
    CAL_CARD_QUEUED,
];

export const showingTypeData = {
    _id: '',
    showingStart: DateTime.local(),
    photoURI: '',
    user: {
        fullName: '',
        title: '',
        phoneNumber: '',
        email: '',
    },
    status: '',
    type: '',
    streetName: '',
    streetNumberText: '',
    city: '',
    state: '',
    postalCode: '',
    listingAgentFirstName: '',
    listingAgentLastName: '',
    listPrice: 0,
    bedrooms: 0,
    bathrooms: 0,
    area: 0,
    lotSizeArea: 0,
    yearBuilt: '',
    parkingSpace: 0,
    mlsId: '',
    elementarySchool: '',
    middleSchool: '',
    highSchool: '',
    district: '',
    listingPhotos: [],
    officeName: '',
    taxAnnualAmount: 0,
    source: '',
    disclaimer: '',
    lockCombo: '',
    lockboxType: '',
    lockboxLocation: '',
    showingInstructions: '',
    showingType: '',
    information: '',
};

export type ShowingTypeData = {
    _id: string | BSON.ObjectID;
    startTime: Date | string;
    photoURI: string;
    user: {
        fullName: string;
        title: string;
        phoneNumber: string;
        email: string;
    };
    status: string;
    type: string;
    streetName: string;
    streetNumberText: string;
    city: string;
    state: string;
    postalCode: string;
    listingAgentFirstName: string;
    listingAgentLastName: string;
    listPrice: number;
    bedrooms: number;
    bathrooms: string | number;
    area: number;
    lotSizeArea: number;
    yearBuilt: string;
    parkingSpace: number;
    mlsId: string;
    elementarySchool: string;
    middleSchool: string;
    highSchool: string;
    district: string;
    listingPhotos: string[];
    officeName: string;
    taxAnnualAmount: number;
    source: string;
    disclaimer: string;
    lockCombo: string;
    lockboxType: string;
    lockboxLocation: string;
    showingInstructions: string;
    showingType: string;
    information: string;
    listing: Listing;
    showingDuration?: number;
};

export type RowItem = {
    class: string; //General styling for each individual row element
    optionName: string; //Should be the property you're rendering
    optionIcon?: string; //If the option needs to also render some kind of icon place that icon in here
    optionIconStyle?: string; //If an option icon is passed also pass the style for that icon
    buttonDefault?: string; //Button styling default
    buttonSelected?: string; //Button styling selected
    buttonName?: string; //If the item is a button pass it a name
    type: 'button' | 'no button' | 'profile'; //Is the type of this filter going to be a button, if so need to render button
};

export type FilterOptions = {
    class: string; //Styling for the overall filter containers
    optionName: string; //This is the title that will be rendered on the header
    canFilter: boolean; //Is this header item going to behave as a filter
    buttonDefault?: string; //Button styling default
    buttonSelected?: string; //Button styling selected
    buttonName?: string; //If the option is a button have the option for a name if necessary
    type: 'button' | 'no button'; //Is the type of this filter going to be a button, if so need to render button
    checkmarkIcon?: string; //Src for the checkmark icon if the button is used as a checkbox
    checkmarkStyles?: string; //Styling for the checkmark icon
};

export type Filter = {
    filterStyle: string; //General styling for the filter icon
    filterSelected: string; //The selected filter icon selected style
    filterDefault: string; //The default filter icon default style
    filterIcon: string; //Filter Icon
};

export type ButtonItem = {
    searchStyle?: string; //If the button will be a search bar this will store the style
    buttonDisabledStyle?: string; //If the button can be enabled and disabled pass disabled style here
    buttonEnabledStyle?: string; //If the button can be enabled and disabled pass enabled style here
    buttonText?: string; //Store the text for the button, can be empty string for no text
    disabled: any; //Store the conditions to disable the buttons
    type: 'search' | 'button'; //Store the button type as a search bar or a standard button
};

export type TextBlastModalStyles = {
    modalRoot: string; //The root styling for the modal
    modalHeader: string; //The styling for the modal title
    modalMessage: string; //The styling for whatever message the modal will render
    modalTextContainerStyle: string; //The styling for the text box outside
    modalTextInputStyles: string; //The styling for the text box inside
    characterCounterContainerStyle: string; //The styling for the character counting container
    characterNumberMessageStyles: string; //The styling for the character counter message below the input box
    characterCounterStyles: string; //The styling for the character counter itself
    modalLoaderStyle: string; //The styling for the modal loader
};

export type ModalExitButton = {
    modalExitIcon: string; //The exit icon for the modal
    modalExitStyle: string; //The exit icon styling
};

export type ModalButtons = {
    confirmButtonText: string; //The name to display for the confirm button text
    cancelButtonText: string; //The name to display for the cancel button text
    modalButtonsContainer: string; //The button container styling for the modal
    confirmButtonDefaultStyle: string; //The confirm button enabled styling
    confirmButtonDisabledStyle: string; //The confirm button disabled styling
    cancelButtonDefaultStyle: string; //The cancel button styling
};

export type ModalSuccessMessage = {
    confirmButtonText: string; //The name to display for the confirm button text
    successMessageStyle: string; //The styling for the message sent confirmation
    successConfirmButtonStyle: string; //The styling for the confirm message close modal button
};

export type YourPlanStyles = {
    planRoot: string; //This is the root styling for the entire payment plans container
    planRowRoot: string; //This is the basic row styling for plans and header
    subscriptionCol: string; //This is the monthly or yearly subscription column that aligns the underline to the name
    subscriptionUnderline: string; //This is the styling for the month and year underline
    freePlanContainer: string; //This is the root styling for the free plan
    proPlanContainer: string; //This is the root styling for the pro plan
    planColumn: string; //This is the column container for the list of plan features
    priceNumber: string; //This is the styling for the price number
    priceContainer: string; //This is the styling for the month abbrev. and the price container
    features: string; //This is the styling for the features container
    rowIncluded: string; //This is the styling for a row that shows the feature as included
    rowSomewhatInclude: string; //This is the styling for a row that shows the features as not fully included
    rowExcluded: string; //This is the styling for a row that shows the features as excluded
    disclaimerContainer: string; //This is the styling for a disclaimer container about the prices
    cancelSubscriptionButton: string; //This is the styling for the cancel subscription button
    subscribedStyling: string; // This is the styling for when the user is already subscribed
    subscribedHeaderStyling: string; // This is the styling for the "your plan" header when the user is already subscribed
    planEndText?: string; // The text to display if the subscription has a cancelDate
};

export type PlanButtonStyling = {
    buttonContainer: string; //This is the styling for the button container
    currentPlan: string; //This is the styling for the current plan button
    upgradePlan: string; //This is the styling for the upgrade plan button
};

export type SubscriptionInclusions = {
    itemDescription: string; //This is the line that describes the included feature with a plan
    included: 'included' | 'somewhat included' | 'excluded'; //This is a simple string that states whether or not the feature is included
};

export type PaymentMethod = {
    id: string;
    object: string;
    created: number;
    customer: string;
    livemode: boolean;
    metadata: {};
    type: string;
};

export const calendarGridHours: any = {
    0: 5,
    1: 6,
    2: 7,
    3: 8,
    4: 9,
    5: 10,
    6: 11,
    7: 12,
    8: 13,
    9: 14,
    10: 15,
    11: 16,
    12: 17,
    13: 18,
    14: 19,
    15: 20,
    16: 21,
    17: 22,
    18: 23,
    19: 24,
};
export const calendarGridHoursToDisplay: any = [
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
    '12 AM',
];
export const calendarGridDays: any = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
};

export const days: any = {
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

/**
 * Takes the phone number format stored in stitch and returns the number in
 * the format `+1 (012) 345-6789` or w/o international code if none is given.
 * @param {string} phoneNumber in the format `+10123456789` or w/o intl code
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function formatPhoneNumberForDisplay(phoneNumber: string, includeDialingCode = false) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match && match.length > 4) {
        const intlCode = match[1] ? '+1 ' : '';
        return includeDialingCode
            ? [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            : ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phoneNumber;
}

export function formatEmailForDisplay(email: any) {
    var formattedEmail = email;
    if (email?.indexOf('+')) {
        const front = email.substr(0, email.indexOf('+'));
        const end = email.substr(email.indexOf('@'), email.length);
        if (front?.length && end?.length) {
            formattedEmail = front + end;
        }
    }
    return formattedEmail;
}

export const getStatusInfo = (status: string) => {
    switch (status) {
        case 'logged':
            return {
                text: 'Logged',
                color: 'green',
            };
        case 'completed':
            return { text: 'Completed', color: 'green' };
        case 'pending_internal':
            return { text: 'Pending', color: 'mediumGrey' };
        case 'pending_external':
            return { text: 'Pending', color: 'mediumGrey' };
        case 'requested':
            return { text: 'Client Request', color: 'mediumGrey' };
        case 'confirmed':
            return { text: 'Confirmed', color: 'blue' };
        case 'denied':
            return { text: 'Denied', color: 'red' };
        case 'cancelled':
            return { text: 'Cancelled', color: 'red' };
        default:
            return { text: 'Confirmed', color: 'green' };
    }
};

export function formatPhoneNumberToIntl(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = '+1';
        return [intlCode, match[2], match[3], match[4]].join('');
    }
    return `${phoneNumberString} is not a valid Number`;
}

/**
 * Returns the given string with the first letter capitalized.
 *
 * @param string
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Convert image to base64, thanks stack overflow
 *
 */
export const imageToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

/**
 * Give a status and a display value for that status will be returned.
 * @param {string} status
 * @returns {string} the label for status from STATUS_DISPLAY_LABELS or if not found in there, the status with the first letter capitalized
 */
export const getStatusForDisplay = (status: string) => {
    const statusDisplayLabel = STATUS_DISPLAY_LABELS.find(
        ({ status: labelStatus }) => status === labelStatus,
    );
    return statusDisplayLabel ? statusDisplayLabel.label : capitalizeFirstLetter(status);
};

export function isValidEmail(email: string) {
    return email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ? email
        : `${email} is not a valid Email`;
}

/* Attempt to parse the error for its string message.
 * If that fails, fall back to error name or as a last resort, show generic error message.
 * @param stitchError Instance of error object `StitchServiceError` caught from API
 * @returns {string}
 */
export function parseStitchServiceError(stitchError: any) {
    try {
        const parsedError = JSON.parse(stitchError.message);
        if (parsedError?.message?.includes('invalid username/password'))
            return 'Invald username/password';
        else return parsedError.message;
    } catch {
        if (stitchError?.message && typeof stitchError.message === 'string') {
            if (stitchError?.message?.includes('invalid username/password'))
                return 'Invald username/password';
            else return stitchError.message;
        } else {
            return stitchError?.name || 'Something Went Wrong.';
        }
    }
}

export const noop = () => {};

/**
 * Function used to update single object's specific field's value in array of object
 *
 * @param {array} formFieldList - data to manipulate
 * @param {string} field - name/key of field to update in passed data
 * @param {string|number} value - value to assign to the passed field
 * @param {string} keyToMatch - key to be used for conditional matching
 * @param {string|number} valueToMatch - value to match in conditional matching
 */
export function updateField(
    formFieldList: any,
    field: string,
    value: string | number,
    keyToMatch: string,
    valueToMatch: string | number,
) {
    const filtered = formFieldList.filter((item: any) => item[keyToMatch] === valueToMatch);

    filtered[0][field] = value;

    return [...formFieldList];
}

export const numberWithCommas = (num: number) => {
    if (!num && num !== 0) return '--';

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Function to determine if the user is on mobile or not
 */
export const isMobile = () => {
    var check = false;
    ((a) => {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4),
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
};

/**
 * Remove dashes, spaces, and other special characters from phone number.
 * Optionally adds International dialing code
 * @param {string|number} phoneNumber
 * @param {string} dialingCode - defaults to U.S.
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function scrubAndFormatPhoneNumber(
    phoneNumber: string,
    dialingCode = '1',
    includeDialingCode = true,
) {
    const scrubbedPhoneNumber = `${phoneNumber}`.replace(/[^\d]/g, '');
    return includeDialingCode ? `+${dialingCode}${scrubbedPhoneNumber}` : `${scrubbedPhoneNumber}`;
}

/**
 * Remove extra spaces and lowercase all characters in email
 * @param {string} email
 * @returns {string}
 */
export function scrubEmail(email: string) {
    return email.trim().toLocaleLowerCase();
}

/**
 * Abbreviates long numbers into thousands, millions, billions and trillions
 * @param {number} value
 * @returns {string}
 */
//Modified from stack overflow
//https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(value: number) {
    let newValue: any = value;
    const suffixes = ['', 'k', 'm', 'b', 't'];
    let suffixNum = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixNum++;
    }
    newValue = parseFloat(newValue?.toFixed(1));
    newValue += suffixes[suffixNum];
    return newValue;
}

export function useOutsideClick(ref: any, doStuff: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                doStuff();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

// display the user's email without the trailing '+12345' display, but use '+12345' to login
export const getDisplayEmail = (email: any) => {
    if (email?.indexOf('+')) {
        const front = email.substr(0, email.indexOf('+'));
        const end = email.substr(email.indexOf('@'), email.length);
        if (front?.length && end?.length) {
            return front + end;
        }
    }
    return email;
};

// returns an array of strings containing timeOptions for a timeSelector dropdown
// thanks stackOverflow
export const getTimeOptions = (startHour: number, endTime: string, includeMidnight: boolean) => {
    let x = 15; //minutes interval
    let timeOptions = [];
    let tt = startHour * 60; // start time
    let ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
        let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        let mm = tt % 60; // getting minutes of the hour in 0-55 format

        // make sure 8pm is the limit
        if (
            ('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)] ===
            endTime
        )
            break;

        // ensures that times such as 00:00pm are returned as 12:00PM
        let h: any = ('0' + (hh % 12)).slice(-2) === '00' ? '12' : ('0' + (hh % 12)).slice(-2);
        timeOptions[i] = h + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }

    if (includeMidnight) {
        timeOptions.push('12:00am');
    }

    // remove 0 if it's the first number
    timeOptions = timeOptions.map((option: string) =>
        option.charAt(0) === '0' ? option.slice(1) : option,
    );

    return timeOptions;
};

export const getStatusColor = (statusLabel: any) => {
    let color = '';
    switch (statusLabel?.label) {
        case 'Cancelled':
        case 'Denied':
            color = '#F53B31';
            break;

        case 'Confirmed':
            color = '#FF8238';
            break;

        case 'Completed':
            color = '#2FD2A8';
            break;

        case 'Queued':
        case 'Pending_external':
        case 'Pending_internal':
        default:
            color = '#6A6A6A';
            break;
    }
    return color;
};

// Given a string and a max value, this function returns
// the string if it exceeds the value with an ellipsis
//example: truncateString('Hello World!', 5)
//output: 'Hello...'
export function truncateString(input: string, max: number) {
    if (input.length > max) {
        return input.substring(0, max) + '...';
    }
    return input;
}
export const getMetaData = (listing: Listing) => {
    //returns a listingCardMetaData object from a listing object
    const { address, property } = listing;
    const metaData = {
        address: {
            street: `${address.streetNumberText} ${address.streetName}`,
            city: address.city,
            state: address.state,
            zip: address.postalCode,
        },
        price: listing.listPrice,
        baths: property?.bathrooms,
        beds: property?.bedrooms,
        sqft: property?.area,
        isConnectedListing: listing.agentListing ? true : false,
    };
    return metaData;
};

/**
 *
 * @param {number} day number to be passed in
 * @returns {string} suffix for that number
 */
export const getDateSuffix = (day: number) => {
    const dayString = day.toString();
    if (dayString.slice(-2) === '11' || dayString.slice(-2) === '12') {
        return 'th';
    }
    switch (dayString.slice(-1)[0]) {
        case '1':
            return 'st';
        case '2':
            return 'nd';
        case '3':
            return 'rd';
        default:
            return 'th';
    }
};

export const generateSignature = () => {
    const secret = process.env.REACT_APP_CLIENT_VALIDATION;
    const timestamp = new Date().toISOString();
    const hash = sha256(`${timestamp} ${secret}`).toString();
    return { hash, timestamp };
};

/**
 *  Breaks/Splits array into multiple smaller chunks
 *
 *  @param arrayToSplit Array of items that needs to be split into chunks
 *  @param chunkSize the size of each chunk
 */

export function chunk(arrayToSplit: any[], chunkSize: number) {
    const chunked = [];

    for (let i = 0, len = arrayToSplit.length; i < len; i += chunkSize) {
        chunked.push(arrayToSplit.slice(i, i + chunkSize));
    }

    chunked.filter((item) => item);

    return chunked;
}

// generate the auth endpoint url based on the  provider
export const getAuthEndPoint = (
    provider: keyof typeof MLS_AUTH_ENDPOINTS,
    redirectUri: string,
    state?: string,
) =>
    `https://${MLS_AUTH_ENDPOINTS[provider].url}?client_id=${
        MLS_AUTH_ENDPOINTS[provider].clientId
    }&scope=openid&response_type=code&redirect_uri=${redirectUri}${state ? `&state=${state}` : ''}`;

export const getTimeDateShowing = (showing: any) => {
    if (showing) {
        let min = Math.ceil(showing?.start.getMinutes() / 15) * 15;
        let hours = showing.start.getHours();
        if (min >= 60) {
            min = 0;
            hours++;
        }
        const hoursLabel = hours > 12 ? hours - 12 : hours;

        const end = DateTime.fromJSDate(showing.end);
        const start = DateTime.fromJSDate(showing.start)
        const diffMinutes: any = end.diff(start, 'minutes').toObject();
        const diffHours: any = end.diff(start, 'hours').toObject();
        let diffLabel = '';
        if (diffHours.hours >= 1) {
            const hour = Math.trunc(diffHours.hours);
            const hourLabel = hour > 1 ? 'hours' : 'hour';
            diffLabel = diffMinutes.minutes % 60 === 0 ? `${hour} ${hourLabel}` : `${hour} ${hourLabel} & ${diffMinutes.minutes % 60} minutes`;
        } else {
            diffLabel = `${diffMinutes.minutes} minutes`;
        }

        return {
            date: new Date(showing.start),
            defaultTime: {
                hours: { label: hoursLabel.toString(), value: hours },
                minutes: {
                    label: min ? min.toString() : '00',
                    value: min,
                },
                meridian: {
                    label: hours < 12 ? 'AM' : 'PM',
                    value: hours < 12 ? 'am' : 'pm',
                },
                selectedValue: {
                    label: formatAMPM(showing?.start),
                    value: parseInt(`${hours}${min ? min.toString() : '00'}`),
                }
            },
            defaultDuration: {
                selectedValue: {
                    label: diffLabel,
                    value: diffMinutes.minutes
                }
            },
        };
    }
}

function formatAMPM(date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ampm;
}
