export default (theme: any) => {
    return {
        root: {
            backgroundColor: theme.palette.background,
            paddingBottom: 10,
        },
        checkBox: {
            marginLeft: 15,
        },
        blueCheck: {
            width: 14,
            ...theme.paddingHorizontal(8),
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        rowData: {
            display: 'flex',
            flex: 1,
        },
        info: {
            flex: 1,
        },
        infoEmail: {
            flex: 1.7,
        },
        infoPhone: {
            flex: 1.1,
        },
    };
};
