import { createAction } from '@reduxjs/toolkit';
import {
    ChangeShowingRequestParams,
    ShowingsType,
    FetchShowingsParams,
    SubmitFeedbackParams,
} from './constants';

const PREFIX = 'showings/';
// ===================================================================================
// #region FETCH_SHOWINGS

export const FETCH_SHOWINGS_REQUESTED = PREFIX + 'FETCH_SHOWINGS_REQUESTED';
export const FETCH_SHOWINGS_SUCCEEDED = PREFIX + 'FETCH_SHOWINGS_SUCCEEDED';
export const FETCH_SHOWINGS_FAILED = PREFIX + 'FETCH_SHOWINGS_FAILED';

export const fetchShowingsRequested = createAction<FetchShowingsParams>(FETCH_SHOWINGS_REQUESTED);
export const fetchShowingsSucceeded = createAction<ShowingsType>(FETCH_SHOWINGS_SUCCEEDED);
export const fetchShowingsFailed = createAction<any>(FETCH_SHOWINGS_FAILED);

// #endregion FETCH_SHOWINGS
// ===================================================================================
// #region CHANGE_SHOWING_STATUS

export const CHANGE_SHOWING_STATUS_REQUESTED = PREFIX + 'CHANGE_SHOWING_STATUS_REQUESTED';
export const CHANGE_SHOWING_STATUS_SUCCEEDED = PREFIX + 'CHANGE_SHOWING_STATUS_SUCCEEDED';
export const CHANGE_SHOWING_STATUS_FAILED = PREFIX + 'CHANGE_SHOWING_STATUS_FAILED';

export const changeShowingStatusRequested = createAction<ChangeShowingRequestParams>(
    CHANGE_SHOWING_STATUS_REQUESTED,
);

export const changeShowingStatusSucceeded = createAction(
    CHANGE_SHOWING_STATUS_SUCCEEDED,
);

export const changeShowingStatusFailed = createAction<any>(CHANGE_SHOWING_STATUS_FAILED);

// #endregion CHANGE_SHOWING_STATUS
// ===================================================================================
// #region UPDATE_SHOWINGS_FILTER
export const UPDATE_SHOWINGS_FILTER = PREFIX + 'UPDATE_SHOWINGS_FILTER';
export const updateShowingsFilter = createAction<{
    type?: string;
    id?: any;
    displayText?: string;
}>(UPDATE_SHOWINGS_FILTER);
// #endregion UPDATE_SHOWINGS_FILTER
// ===================================================================================

// #region SUBMIT_FEEDBACK
export const SUBMIT_FEEDBACK_REQUESTED = PREFIX + 'SUBMIT_FEEDBACK_REQUESTED';
export const SUBMIT_FEEDBACK_SUCCEEDED = PREFIX + 'SUBMIT_FEEDBACK_SUCCEEDED';
export const SUBMIT_FEEDBACK_FAILED = PREFIX + 'SUBMIT_FEEDBACK_FAILED';

export const submitFeedbackRequested = createAction<SubmitFeedbackParams>(
    SUBMIT_FEEDBACK_REQUESTED,
);

export const submitFeedbackSucceeded = createAction(SUBMIT_FEEDBACK_SUCCEEDED);

export const submitFeedbackFailed = createAction<string>(SUBMIT_FEEDBACK_FAILED);
// #endregion SUBMIT_FEEDBACK
// ===================================================================================

// #region New Showing Requested
export const NEW_SHOWING_REQUESTED = PREFIX + 'NEW_SHOWING_REQUESTED';
export const UPDATE_SHOWING_REQUESTED = PREFIX + 'UPDATE_SHOWING_REQUESTED';
export const SHOWING_REFRESH_SUCCEEDED = PREFIX + 'SHOWING_REFRESH_SUCCEEDED';
export const CALENDAR_REFRESH_SUCCEEDED = PREFIX + 'CALENDAR_REFRESH_SUCCEEDED';
export const DETAIL_MESSEGE_CLOSED = PREFIX + 'DETAIL_MESSEGE_CLOSED';

export const newShowingRequested = createAction(NEW_SHOWING_REQUESTED);

export const updateShowingRequested = createAction(UPDATE_SHOWING_REQUESTED);

export const showingRefreshSucceeded = createAction(SHOWING_REFRESH_SUCCEEDED);

export const calendarRefreshSucceeded = createAction(CALENDAR_REFRESH_SUCCEEDED);

export const closeDetailMsg = createAction(DETAIL_MESSEGE_CLOSED);
// #endregion hasChanges UPDATE
// ===================================================================================