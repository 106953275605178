import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import { DASHBOARD_ACTIONS, chartsData as chartsDataType } from './actions';
import * as DashActions from './actions';
import { STATUS } from '../../../store/api/constants';
import { callStitchFunction } from '../../../store/api/sagas';
import AgentStats from '../Social/AgentProfile/AgentStats';

export function* getAgentStats({
    agentObjectId,
}: ReturnType<typeof DashActions.getAgentStatsRequested>): Generator<any, any, any> {
    try {
        const agentStats = yield call(
            callStitchFunction,
            'metricsGetStats',
            agentObjectId,
            'agent',
            true,
        );
        var agentStatsMap: any = {};
        agentStats.map((stats: any) => {
            agentStatsMap[`${stats.displayMlsName}`] = stats;
        });
        const agentMarkets = Object.keys(agentStatsMap);
        if (agentStatsMap) {
            yield put(DashActions.getAgentStatsSucceeded(agentStatsMap, agentMarkets));
        } else {
            yield put(DashActions.getAgentStatsFailed('Unable to get data. Error code: 0109-1'));
        }
    } catch (error) {
        yield put(
            DashActions.getAgentStatsFailed(
                'Dashboard data fetch failed. Please try again Error code: 0109-1',
            ),
        );
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === DASHBOARD_ACTIONS.Fetch && action.status === STATUS.Requested,
            getAgentStats,
        ),
    ]);
}
