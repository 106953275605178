import React, { useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { ShowingRequest } from '../constants';
import Tag from '../../../../components/Tag';
import indexTheme from '../../../../theme';
import { ClickableWithFeedback, Loader, Typography } from '../../../../components';
import useWindowDimensions, {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
} from '../../../../utils/common';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import GrayPerson from '../../../../images/grayPerson.svg';
import { DateTime } from 'luxon';
import ButtonComponent from '../../../../components/ButtonComponent';

interface Props {
    showing: ShowingRequest;
    selectedType?: 'buySide' | 'listSide';
    updatingShowing: string;
    onViewFeedback?: Function;
}
const ShowingFeedbackCard = ({
    showing,
    selectedType,
    updatingShowing,
    onViewFeedback = () => {},
}: Props) => {
    const { width, height } = useWindowDimensions();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...{ width, height }, theme });

    let userData =
        selectedType === 'buySide'
            ? showing?.listing?.agent
            : showing?.unverifiedUser
            ? showing.unverifiedUser
            : showing?.agent;
    const phoneNumber =
        selectedType === 'buySide'
            ? showing?.listing?.agent?.contact?.cell
            : showing.unverifiedUser
            ? showing.unverifiedUser.phoneNumber
            : showing?.agent?.phoneNumber;
    const email =
        selectedType === 'buySide'
            ? showing?.listing?.agent?.contact?.email
            : showing.unverifiedUser
            ? showing.unverifiedUser.email
            : showing?.agent?.email;
    const office =
        selectedType === 'buySide'
            ? showing?.listing?.office?.name
            : showing.unverifiedUser
            ? showing.unverifiedUser.company
            : showing?.agent?.brokerages?.name;

    const getStatusInfo = () => {
        switch (showing.status) {
            case 'logged':
                return {
                    text: 'Logged',
                    color: 'green',
                };
            case 'completed':
                return { text: 'Completed', color: 'green' };
            case 'pending_internal':
                return { text: 'Pending', color: 'mediumGrey' };
            case 'pending_external':
                return { text: 'Pending', color: 'mediumGrey' };
            case 'requested':
                return { text: 'Client Request', color: 'mediumGrey' };
            case 'confirmed':
                return { text: 'Confirmed', color: 'blue' };
            case 'denied':
                return { text: 'Denied', color: 'red' };
            case 'cancelled':
                return { text: 'Cancelled', color: 'red' };
            default:
                return { text: 'Confirmed', color: 'green' };
        }
    };
    const topSection = (
        <div className={styleSheet.topSection}>
            <span>
                <Typography textStyle={'p1'}>{showing.listing.address.full} </Typography>
                <Typography style={{ marginLeft: 5 }} textStyle={'b1'}>
                    {showing.listing.address.city}, {showing.listing.address.state}{' '}
                    {showing.listing.address.postalCode}
                </Typography>
            </span>
            <Tag text={getStatusInfo().text} color={getStatusInfo().color} />
        </div>
    );

    const bottomSection = (
        <div className={styleSheet.bottomSection}>
            <ButtonComponent
                className={styleSheet.button}
                fill
                width={width * 0.14}
                onClick={() => onViewFeedback(showing)}
                screenWidth={width}
            >
                View Feedback
            </ButtonComponent>
        </div>
    );

    return (
        <div className={styleSheet.root}>
            {topSection}
            <div className={styleSheet.midSectionRow}>
                <div className={styleSheet.midSectionColumn}>
                    <span className={styleSheet.iconRow}>
                        <img
                            src={GrayCal}
                            height={16.36}
                            style={{ filter: 'brightness(0)', marginRight: 12 }}
                        />
                        <Typography textStyle={width <= 1310 ? 'b3' : 'b1'}>
                            {DateTime.fromJSDate(showing.start).weekdayLong +
                                ' ' +
                                DateTime.fromJSDate(showing.start).toFormat('DD')}
                        </Typography>
                    </span>
                </div>
                <div className={styleSheet.midSectionColumn}>
                    <span className={styleSheet.iconRow}>
                        <img
                            src={GrayClock}
                            height={16.36}
                            style={{ filter: 'brightness(0)', marginRight: 12 }}
                        />
                        <Typography textStyle={width <= 1310 ? 'b3' : 'b1'}>{`${DateTime.fromJSDate(
                            showing.start,
                        ).toFormat('t')} - ${DateTime.fromJSDate(showing.end).toFormat(
                            't',
                        )}`}</Typography>
                    </span>
                </div>
            </div>
            <div className={styleSheet.midSectionRow}>
                <div className={styleSheet.midSectionColumn}>
                    <span className={styleSheet.iconRow}>
                        <img src={GrayPerson} height={16} />
                        <Typography textStyle={'p1'} color={indexTheme.palette.mediumGrey}>
                            {userData ? userData?.firstName + ' ' + userData?.lastName : null}
                        </Typography>
                    </span>
                    <Typography textStyle={'p1'} color={indexTheme.palette.mediumGrey}>
                        {office ? office : null}
                    </Typography>
                </div>
                <div className={styleSheet.midSectionColumn}>
                    <Typography
                        textStyle={width <= 1310 ? 'b3' : 'b1'}
                        color={indexTheme.palette.mediumGrey}
                    >
                        {phoneNumber ? `+1 ${formatPhoneNumberForDisplay(phoneNumber)}` : null}
                    </Typography>
                    <Typography
                        textStyle={width <= 1310 ? 'b3' : 'b1'}
                        color={indexTheme.palette.mediumGrey}
                    >
                        {email ? formatEmailForDisplay(email) : null}
                    </Typography>
                </div>
            </div>

            {bottomSection}
        </div>
    );
};
export default ShowingFeedbackCard;
