import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import DropdownIcon from '../../images/dropDownIcon.svg';
import Typography from '../Typography';
import PickerComponent from '../PickerComponent';

interface SearchDropdownSelectProps {
    width?: any;
    list: any[];
    selectOption: any;
    selectedOption: any;
    searching: boolean;
    setSearching: Function;
    searchText: string;
    setSearchText: Function;
    filterOptions?: any[] | null;
    selectFilterOption?: any;
    selectedFilterOption?: any;
}

const SearchDropdownSelect = (props: SearchDropdownSelectProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });
    const {
        list,
        selectOption,
        selectedOption,
        searching,
        setSearching,
        searchText,
        setSearchText,
        filterOptions,
        selectFilterOption,
        selectedFilterOption,
    } = props;

    const focusSearch = () => {
        document.getElementById('search')?.focus();
    };

    const handleSelect = (item: any) => {
        setSearching(false);
        selectOption(item);
    };

    useEffect(() => {
        focusSearch();
    }, [selectedFilterOption]);

    return searching ? (
        <div style={{ position: 'relative' }}>
            <div className={styleSheet.topRow}>
                {filterOptions?.length && (
                    <PickerComponent
                        data={filterOptions}
                        selectedOption={selectedFilterOption}
                        onPressButton={selectFilterOption}
                        height={31}
                        optionWidth={90}
                        lowerCornersSquare
                    />
                )}
                <div
                    className={styleSheet.searchBar}
                    style={{ width: filterOptions?.length ? 270 : 536 }}
                    onClick={() => {
                        setSearching(true);
                        focusSearch();
                    }}
                >
                    <input
                        placeholder="Search"
                        id="search"
                        value={searchText}
                        onChange={(e: any) => {
                            setSearchText(e?.nativeEvent?.target?.value);
                        }}
                        autoFocus
                        className={styleSheet.inputText}
                        style={{ width: filterOptions?.length ? 260 : 516 }}
                    />
                </div>
            </div>
            <div className={styleSheet.menu}>
                {list?.length ? (
                    list?.map((item, index) => {
                        const { mainDisplay, subDisplay } = item;
                        const includeBorder = index !== list.length - 1;
                        const borderStyles = includeBorder
                            ? {
                                  borderBottom: `1px solid ${theme.palette.grey}`,
                              }
                            : {};
                        return (
                            <div
                                key={`item${index + 1}`}
                                className={styleSheet.menuItem}
                                style={{ ...borderStyles }}
                                onClick={() => {
                                    handleSelect(item);
                                }}
                            >
                                <Typography
                                    color={theme.palette.black}
                                    textStyle="b2"
                                    style={{ fontSize: 15 }}
                                >
                                    {mainDisplay}
                                    <Typography
                                        color={theme.palette.mediumGrey}
                                        textStyle="b2"
                                        style={{ fontSize: 15, paddingLeft: 5 }}
                                    >
                                        {subDisplay}
                                    </Typography>
                                </Typography>
                            </div>
                        );
                    })
                ) : (
                    <div className={styleSheet.menuItem}>
                        <Typography
                            color={theme.palette.grey}
                            textStyle="b3"
                            style={{ fontSize: 15 }}
                        >
                            No Results Found
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div className={styleSheet.topRow}>
            {filterOptions?.length && (
                <PickerComponent
                    data={filterOptions}
                    selectedOption={selectedFilterOption}
                    onPressButton={selectFilterOption}
                    height={31}
                    optionWidth={90}
                />
            )}
            <div
                className={styleSheet.searchBar}
                style={{ width: filterOptions?.length ? 270 : 536 }}
                onClick={() => setSearching(true)}
            >
                {selectedOption ? (
                    <Typography color={theme.palette.black} textStyle="b2" style={{ fontSize: 15 }}>
                        {selectedOption?.mainDisplay}
                        <Typography
                            color={theme.palette.mediumGrey}
                            textStyle="b2"
                            style={{ fontSize: 15, paddingLeft: 5 }}
                        >
                            {selectedOption?.subDisplay}
                        </Typography>
                    </Typography>
                ) : (
                    <Typography
                        color={theme.palette.lightGrey}
                        textStyle="b1"
                        style={{ fontSize: 18, alignSelf: 'center' }}
                    >
                        {'Search'}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default SearchDropdownSelect;
