export default (theme: any) => {
    const widthStyles = {
        width: '56%',
        minWidth: 970,
    };
    return {
        root: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 48,
        },
        pageRoot: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        pageContainer: {
            ...widthStyles,
            alignSelf: 'center',
        },
        selectBox: {
            backgroundColor: theme.palette.background,
            border: 'none',
            width: 257,
            height: 30,
            borderRadius: 5,
            ...theme.typography.medium,
            fontSize: 15,
            padding: 5,
            cursor: 'pointer',
        },

        buttonContainer: {
            ...widthStyles,
            alignSelf: 'center',
            display: 'flex',
            marginTop: 26,
        },
        button: {
            alignSelf: 'flex-end',
        },
        lockCombo: {
            backgroundColor: theme.palette.background,
            borderRadius: 5,
            ...theme.marginHorizontal(16),
            border: 'none',
            ...theme.typography.semiBold,
        },
        inputBox: {
            backgroundColor: theme.palette.background,
            borderRadius: 5,
            ...theme.marginHorizontal(16),
            border: 'none',
            ...theme.typography.semiBold,
            width: '100%',
            height: 59,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            resize: 'none',
            ...theme.paddingVertical(11),
            ...theme.paddingHorizontal(5),
        },
        progressBar: {
            height: 26,
            width: 26,
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginVertical(16),
        },
        breadCrumbs: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(23),
            ...theme.paddingHorizontal(48),
            borderBottom: {
                color: theme.palette.grey,
                style: 'solid',
                width: 1,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        individualContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        selectDateBox: {
            backgroundColor: theme.palette.background,
            border: 'none',
            width: 247,
            height: 20,
            borderRadius: 5,
            ...theme.typography.medium,
            fontSize: 15,
            padding: 5,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        datePickerContainer: {
            position: 'absolute',
            display: 'flex',
            width: 227,
            flex: 1,
            padding: 15,
            flexDirection: 'column',
            backgroundColor: theme.palette.white,
            ...theme.cardShadow,
        },
        snackbarAlert: {
            backgroundColor: theme.palette.blue,
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            '& div': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        loaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalBackground: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
};
