import React, { useRef, useState } from 'react';
import styles from './styles';
import { useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, ClickableWithFeedback, Typography } from '..';
import WhiteCloseIcon from '../../images/xWhite.svg';
import CheckIcon from '../../images/blueCheck.svg';
import greenCheckIcon from '../../images/greenCheck.svg';
import { plansExtended as plans } from './utils';
import ButtonComponent from '../ButtonComponent';
import {
    getPrices,
    getSubscription,
    getSubscriptionPlan,
} from '../../domains/main/Profile/selectors';
import { findIndex, propEq } from 'ramda';
import PaymentModal from '../PaymentModal';
import DowngradeModal from '../DowngradeModal';
import Tag from '../Tag';
import { useHistory } from 'react-router-dom';

interface Props {
    onClose: Function;
    visible: boolean;
    additionalHeaderText?: string;
}

const UpgradeSubscriptionModal = (props: Props) => {
    const prices: any = useSelector(getPrices) || {};
    const subPlan = useSelector(getSubscriptionPlan);
    const currentPlanTier = subPlan?.tier || 0;
    const subscription = useSelector(getSubscription);
    const history = useHistory();

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const animatedModalRef = useRef<any>(null);

    // cannot be in styles file bc passed as style, not className
    const animatedModalStyle = {
        maxWidth: 1223,
        maxHeight: 752,
        minHeight: 350,
        minWidth: 750,
        height: '75vh',
        width: '45vw',
        top: '3%',
    };

    const [paymentVisible, setPaymentVisible] = useState<any>(null);
    const [downgradeVisible, setDowngradeVisible] = useState<any>(undefined);

    const currentSubId = subscription?.id;

    // number 0-2 to represent associated plan
    const planType = currentPlanTier;
    let planPrices = Object.keys(prices);
    planPrices.shift();
    const formattedPlans = [plans[0]];
    planPrices.map((planPrice: any) => {
        if (prices[planPrice]) {
            const price = prices[planPrice];
            const {
                id,
                unit_amount,
                nickname,
                recurring: { interval },
            } = price;

            // const intervalFormatted = interval === 'month' ? 'monthly' : interval;
            // const formattedPrice = `$${(unit_amount / 100).toFixed(0)}/${intervalFormatted}`;
            const planIndex = findIndex(propEq('title', nickname.split(' ').reverse()[0]), plans);

            const plan: any = plans[planIndex];
            // plan.price = formattedPrice;
            plan.id = id;
            formattedPlans.push(plan);
        }
    });

    const headerBar = (
        <div className={styleSheet.headerBar}>
            <Typography textStyle="h1" color={theme.palette.white}>
                Unlock Showingly+
            </Typography>
            <Typography textStyle="b2" color={theme.palette.white}>
                to batch schedule showings, connect with clients, and more!
            </Typography>
            <ClickableWithFeedback
                onClick={() => animatedModalRef.current.closeModal()}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
            >
                <img src={WhiteCloseIcon} />
            </ClickableWithFeedback>
        </div>
    );

    const mainContent = (
        <div className={styleSheet.mainContainer}>
            {formattedPlans.map((e: any, index: number) => (
                <div
                    className={
                        e.title == 'Free' ? styleSheet.cardContainer : styleSheet.cardContainerAlt
                    }
                >
                    <div
                        className={
                            e.title == 'Free'
                                ? styleSheet.cardHeaderContainer
                                : styleSheet.cardHeaderContainerAlt
                        }
                    >
                        <Typography
                            textStyle="h3"
                            color={e.title == 'Free' ? theme.palette.blue : theme.palette.white}
                        >
                            {e.planName}
                        </Typography>
                        <Typography
                            style={{ marginBottom: '10px' }}
                            textStyle="b2"
                            color={
                                e.title == 'Free' ? theme.palette.lightGrey : theme.palette.white
                            }
                        >
                            {e.description}
                        </Typography>
                        <Typography
                            textStyle="h1"
                            color={e.title == 'Free' ? theme.palette.black : theme.palette.white}
                        >
                            {e.title == 'Free' ? e.title : e.price}
                        </Typography>
                        <Typography
                            textStyle="b2"
                            color={
                                e.title == 'Free' ? theme.palette.lightGrey : theme.palette.white
                            }
                            style={{ ...theme.typography.medium }}
                        >
                            {e.title == 'Free' ? e.price : '/monthly'}
                        </Typography>
                    </div>
                    <Typography
                        style={{ marginTop: '50px' }}
                        className={styleSheet.featuresContainer}
                        textStyle="h4"
                        color={e.title == 'Free' ? theme.palette.black : theme.palette.white}
                    >
                        Features
                    </Typography>
                    <div className={styleSheet.itemsContainer}>
                        {e.plan.map((f: any) => {
                             let index = e.plan.indexOf(f);
                             if(index < 7){
                                 return (
                                     <div className={styleSheet.itemRow}>
                                         <img
                                             src={e.title == 'Free' ? CheckIcon : greenCheckIcon}
                                             className={styleSheet.checkIcon}
                                         />
                                         <Typography
                                             textStyle="b2"
                                             color={
                                                 e.title == 'Free'
                                                     ? theme.palette.mediumGrey
                                                     : theme.palette.white
                                             }
                                         >
                                             {f}
                                         </Typography>
                                     </div>
                                 );
                             }
                        })}
                        {e.title != 'Free' ? (
                            <div style={{ marginLeft: 48 }} className={styleSheet.itemRow}>
                                <ClickableWithFeedback
                                    onClick={() => {
                                        animatedModalRef.current.closeModal();
                                        history.push(`/readMore`);
                                    }}
                                >
                                    <Typography textStyle="b2" color={theme.palette.white}>
                                        <u> Read more...</u>
                                    </Typography>
                                </ClickableWithFeedback>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {subscription?.cancelDate && planType === index && (
                        <Tag
                            text={'Cancels on ' + subscription.cancelDateString}
                            color={'red'}
                            style={{
                                borderRadius: 'unset',
                                borderColor: '#E9E9E9',
                                borderStyle: 'solid',
                                borderWidth: 0,
                                borderBottomWidth: 1,
                            }}
                        />
                    )}
                    <div className={styleSheet.cardFooterContainer}>
                        {/* There could be permanent subscriptions, in this case user cannot downgrade */}
                        {!subscription?.permanent &&
                            (planType === index ? (
                                <ButtonComponent
                                    fill
                                    width={257}
                                    editStyle={{
                                        color: theme.palette.lightGrey,
                                        backgroundColor: theme.palette.white,
                                        height: '50px',
                                        fontFamily: 'Poppins',
                                        borderColor: theme.palette.lightGrey,
                                        borderWidth: 2,
                                        disabled: true,
                                        noop: true,
                                    }}
                                >
                                    Current Plan
                                </ButtonComponent>
                            ) : planType > index ? (
                                <ButtonComponent
                                    fill={true}
                                    width={257}
                                    onClick={() => setDowngradeVisible(e.id || null)}
                                    editStyle={{
                                        borderWidth: 0,
                                    }}
                                    disabled={!!subscription?.cancelDate}
                                >
                                    Downgrade
                                </ButtonComponent>
                            ) : (
                                <ButtonComponent
                                    fill
                                    width={257}
                                    onClick={() => setPaymentVisible(e.id)}
                                    editStyle={{
                                        backgroundColor: theme.palette.green,
                                        height: '50px',
                                        fontFamily: 'Poppins',
                                    }}
                                >
                                    Upgrade
                                </ButtonComponent>
                            ))}
                    </div>
                </div>
            ))}
        </div>
    );

    // const vis = props.visible && !subscribedAlready
    return paymentVisible ? (
        <PaymentModal goBack={() => setPaymentVisible(null)} planId={paymentVisible} />
    ) : downgradeVisible || downgradeVisible === null ? (
        <DowngradeModal
            goBack={() => setDowngradeVisible(undefined)}
            planId={downgradeVisible}
            currentSubId={currentSubId}
        />
    ) : (
        <AnimatedModal
            set={props.onClose}
            modalVisible={props.visible}
            style={animatedModalStyle}
            ref={animatedModalRef}
        >
            {headerBar}
            {mainContent}
        </AnimatedModal>
    );
};
export default UpgradeSubscriptionModal;
