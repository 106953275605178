import React, { useState } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import { Option, Options } from './types';

interface Props {
    options: Options;
    onSelect: Function;
    defaultValue?: any;
}

const TimeSelector = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    return (
        <div>
            <select
                name="Select a Time"
                defaultValue={JSON.stringify(props.defaultValue)}
                placeholder="Select a Time"
                className={styleSheet.selectionContainer}
                onChange={(e: any) => props.onSelect(e.target.value)}
            >
                {props.options.map((e: Option) => {
                    return (
                        <option
                            key={e.value.toString()}
                            value={JSON.stringify(e)}
                            className={styleSheet.selection}
                        >
                            {e.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
export default TimeSelector;
