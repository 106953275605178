import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import CRMRow from './CRMRow';
import { BSON } from 'realm-web';

export interface TableProps {
    rows: Array<any>;
    showBlueTick?: Function;
    rowStyle?: Object;
    setChecked: Function;
    selectedRows: Map<any, boolean>;
    tableBackgroundColor?: string;
}
const Table = (props: TableProps) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const { rows, showBlueTick, rowStyle, selectedRows, setChecked, tableBackgroundColor } = props;

    return (
        <div className={styleSheet.root} style={{ background: tableBackgroundColor }}>
            <div>
                {rows?.map((row, i) => {
                    return (
                        <CRMRow
                            key={i}
                            row={row}
                            showBlueTick={showBlueTick ? showBlueTick(row) : false}
                            customStyle={rowStyle}
                            checked={selectedRows.get(row._id) || false}
                            setChecked={(checked: boolean) => setChecked(row._id, checked)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Table;
