import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, Typography } from '../../../../components';
import indexTheme from '../../../../theme';
import close from '../../../../images/close.svg';
import Tag from '../../../../components/Tag';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import { ShowingRequest } from '../constants';
import { DateTime } from 'luxon';

interface Props {
    set: Function;
    modalVisible: boolean;
    ref: any;
    showing: ShowingRequest;
    closeModal: Function;
}
const DenialModal = (props: Props) => {
    const { showing } = props;
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    return (
        <AnimatedModal style={{ top: null, margin: 'auto', width: 812 }} {...props}>
            <div className={styleSheet.header}>
                <Typography
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                    style={{ marginLeft: 15 }}
                >
                    Denial Reason
                </Typography>
                <img
                    className={styleSheet.closeButton}
                    src={close}
                    onClick={() => {
                        props.closeModal();
                        props.set(false);
                    }}
                />
            </div>
            <div className={styleSheet.childrenContainer}>
                <div className={styleSheet.addressHeader}>
                    <Typography textStyle={'h2'}>{showing.listing.address.full}</Typography>
                    <Typography style={{ fontFamily: 'Poppins-medium' }} textStyle={'h2'}>
                        {showing.listing.address.city}, {showing.listing.address.state}{' '}
                        {showing.listing.address.postalCode}
                    </Typography>
                    <Tag style={{ marginTop: 10 }} text={'Denied'} color={'red'} />
                </div>
                <div className={styleSheet.showingTimeDetails}>
                    <div className={styleSheet.iconAndText}>
                        <img style={{ marginRight: 5 }} height={20} width={20} src={GrayCal} />
                        <Typography textStyle={'h4'}>
                            {DateTime.fromJSDate(showing.start).toFormat('DDDD')}
                        </Typography>
                    </div>
                    <div className={styleSheet.iconAndText}>
                        <img style={{ marginRight: 5 }} height={20} width={20} src={GrayClock} />
                        <Typography textStyle={'h4'}>
                            {DateTime.fromJSDate(showing.start).toFormat('t')} -{' '}
                            {DateTime.fromJSDate(showing.end).toFormat('t')}
                        </Typography>
                    </div>
                </div>
                {showing?.denialReason ? (
                    <div className={styleSheet.denialReasonContainer}>
                        <Typography textStyle={'b1'}>{showing.denialReason}</Typography>
                    </div>
                ) : (
                    <div className={styleSheet.denialReasonContainer}>
                        <Typography color={indexTheme.palette.mediumGrey} textStyle={'h2'}>
                            No denial reason was provided.
                        </Typography>
                    </div>
                )}
            </div>
        </AnimatedModal>
    );
};
export default DenialModal;
