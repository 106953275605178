export default (theme: any) => {
    return {
        filterDrawer: {
            position: 'relative',
            height: '100%',
            padding: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'scroll',
        },
        confirmFilterButton: {
            backgroundColor: theme.palette.blue,
            borderRadius: 5,
            padding: 5,
            width: '12vw',
            marginRight: 25,
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontWeight: 600,
        },
        cancelFilterButton: {
            borderRadius: 5,
            border: {
                color: theme.palette.blue,
                width: 2,
                style: 'solid',
            },
            padding: 5,
            width: '8vw',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontWeight: 600,
            color: theme.palette.blue,
        },
        headerText: {
            color: theme.palette.mediumGrey,
            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'solid',
            },
            marginBottom: '2vh',
            paddingBottom: 10,
            width: '100%',
            fontWeight: 600,
            fontSize: 18,
        },
        smallGreyText: {
            color: theme.palette.mediumGrey,
            fontWeight: 500,
            fontSize: 16,
            marginBottom: 10,
            alignSelf: 'flex-start',
        },
    };
};
