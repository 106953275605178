export default (theme: any) => {
    return {
        box: {
            border: `2px solid ${theme.palette.separatorGrey}`,
            height: 36,
            width: 384,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
        },
        topSection: {
            height: 36,
            width: 384,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
        },
        expandedBox: ({ paymentMethods }: any) => ({
            height: ((paymentMethods?.data?.length || 0) + 2) * 36 - 6,
            border: `2px solid ${theme.palette.separatorGrey}`,
            width: 384,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'flex-start',
            position: 'absolute',
            zIndex: 99999,
            flexDirection: 'column',
            backgroundColor: theme.palette.white,
        }),
        line: {
            flex: 1,
            maxHeight: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.separatorGrey,
            marginLeft: 12,
            marginRight: 12,
            width: 360,
        },
        option: {
            maxHeight: 33,
            flex: 1,
            width: 360,
            marginLeft: 12,
            marginRight: 12,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
        },
        arrow: {
            width: 20,
            height: 20,
            position: 'absolute',
            right: 12,
            top: 8,
        },
        inputText: {
            fontSize: 18,
            ...theme.typography.medium,
            color: theme.palette.black,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            padding: 0,
            width: 368,
        },
        inputBox: (props: any) => ({
            height: 35,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            width: props?.width ?? 360,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: 5,
        }),
        cardNumberContainer: {
            width: 388,
            backgroundColor: theme.palette.background,
            height: 27,
            borderRadius: 5,
            paddingTop: 8,
            paddingBottom: 1,
            marginTop: 20,
            position: 'relative',
        },
        cardNumberPlaceholder: {
            position: 'absolute',
            top: 0,
            width: 388,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 37,
            color: theme.palette.grey,
            fontSize: 18,
            fontFamily: 'Poppins-Semibold',
            pointerEvents: 'none',
        },
        cardExpiryContainer: {
            borderRadius: 5,
            height: 27,
            paddingTop: 8,
            paddingBottom: 1,
            backgroundColor: theme.palette.background,
            marginRight: 6,
            alignItems: 'center',
            width: 188,
            position: 'relative',
        },
        cardExpiryPlaceholder: {
            position: 'absolute',
            top: 0,
            width: 188,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 37,
            color: theme.palette.grey,
            fontSize: 18,
            fontFamily: 'Poppins-Semibold',
            pointerEvents: 'none',
        },
        cardCVCContainer: {
            borderRadius: 5,
            height: 27,
            paddingTop: 8,
            paddingBottom: 1,
            backgroundColor: theme.palette.background,
            marginLeft: 6,
            alignItems: 'center',
            width: 188,
            position: 'relative',
        },
        cardCVCPlaceholder: {
            position: 'absolute',
            top: 0,
            width: 188,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 37,
            color: theme.palette.grey,
            fontSize: 18,
            fontFamily: 'Poppins-Semibold',
            pointerEvents: 'none',
        },
        zipInput: {
            borderRadius: 5,
            height: 35,
            width: 184,
            textAlign: 'center',
            backgroundColor: theme.palette.background,
            alignItems: 'center',
            color: theme.palette.black,
            fontFamily: 'Poppins-Semibold',
            border: 0,
            fontSize: 18,
        },
        cardInfoContainer: {
            position: 'absolute',
            top: 80,
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            left: 0,
        },
    };
};
