import { STATUS, FeedItem, SocialItem } from '../../../utils/constants';
import { BSON } from 'realm-web';

export enum BROKERAGE_HUB_ACTIONS {
    Create = '[BrokerageData] Create',
    Fetch = '[BrokerageData] Fetch',
    Feed = '[BrokerageData] Fetch Feed',
    UpdateFeed = '[BrokerageData] Update Feed',
    UpdateFeedItem = '[BrokerageData] Update Feed Item',
    ResetContentCreated = '[BrokerageData] Reset Content',
    ClearErrors = '[BrokerageData] Clear Errors',
    FetchBrokerageListings = '[BrokerageData] Fetch Brokerage Listings',
    AppendBrokerageListings = '[BrokerageData] Append Brokerage Listings',
    FetchBrokerageAgents = '[BrokerageData] Fetch Brokerage Agents',
    AppendBrokerageAgents = '[BrokerageData] Append Brokerage Agents',
    ClearBrokerageAgents = '[BrokerageData] Clear Brokerage Agents',
    UpdateSelectedBrokerage = '[BrokerageData] Update Selected Brokerage',
    SetViewingRosterProfile = '[BrokerageData] Set Viewing Roster Profile',
}

export type UpdateFeedData = {
    eventId: BSON.ObjectId;
    userObjectId: BSON.ObjectId;
    type: 'upvote' | 'unvote' | 'comment';
    comment: string;
};

//#region Create Content
export function createContentRequested(
    brokerageId: BSON.ObjectId,
    contentData: FeedItem,
    setContentData: Function,
) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Create,
        status: STATUS.Requested,
        brokerageId,
        contentData,
        setContentData,
    };
}

export function createContentSucceeded() {
    return {
        type: BROKERAGE_HUB_ACTIONS.Create,
        status: STATUS.Succeeded,
    };
}

export function createContentFailed(errors: string[] = []) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Create,
        status: STATUS.Failed,
        errors,
    };
}
//#endregion

//#region Fetch Brokerage Data
export function getBrokerageDataRequested(brokerageId: BSON.ObjectId | string) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Fetch,
        status: STATUS.Requested,
        brokerageId,
    };
}

export function getBrokerageDataSucceeded(brokerage: any, brokerageAdmins: any) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Fetch,
        status: STATUS.Succeeded,
        brokerage,
        brokerageAdmins,
    };
}

export function getBrokerageDataFailed(errors: string[] = []) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Fetch,
        status: STATUS.Failed,
        errors,
    };
}

//#region Fetch Brokerage Feed
export function getBrokerageFeedRequested(brokerageId: BSON.ObjectID | string) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Feed,
        status: STATUS.Requested,
        brokerageId,
    };
}

export function getBrokerageFeedFailed(errors: string[] = []) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Feed,
        status: STATUS.Failed,
        errors,
    };
}

export function getBrokerageFeedSucceeded(feed: any[] = []) {
    return {
        type: BROKERAGE_HUB_ACTIONS.Feed,
        status: STATUS.Succeeded,
        feed,
    };
}
//#endregion

export function updateBrokerageFeedRequested(content: any) {
    return {
        type: BROKERAGE_HUB_ACTIONS.UpdateFeed,
        status: STATUS.Requested,
        content,
    };
}

export function resetContentCreated() {
    return {
        type: BROKERAGE_HUB_ACTIONS.ResetContentCreated,
    };
}

export function clearBrokerageErrors() {
    return {
        type: BROKERAGE_HUB_ACTIONS.ClearErrors,
    };
}

// Start Brokerage listings actions

export const fetchBrokerageListingsRequested = (
    markets: { brokerageId: string; mlsName: string }[],
    skip: number,
) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageListings,
        status: STATUS.Requested,
        markets,
        skip,
    };

export const fetchBrokerageListingsSucceeded = (brokerageListings: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageListings,
        status: STATUS.Succeeded,
        brokerageListings,
    };

export const fetchBrokerageListingsFailed = (brokerageListingErrors: string[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageListings,
        status: STATUS.Failed,
        brokerageListingErrors,
    };

export const appendBrokerageListingsRequested = (toAppendListings: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.AppendBrokerageListings,
        status: STATUS.Requested,
        toAppendListings,
    };

export const appendBrokerageListingsSucceeded = (totalListings: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.AppendBrokerageListings,
        status: STATUS.Succeeded,
        totalListings,
    };

export type fetchBrokerageListings =
    | ReturnType<typeof fetchBrokerageListingsRequested>
    | ReturnType<typeof fetchBrokerageListingsSucceeded>
    | ReturnType<typeof fetchBrokerageListingsFailed>;

// End Brokerage listings actions

// Start brokerage agent actions

export const fetchBrokerageAgentsRequested = (
    brokerageId: BSON.ObjectID,
    skip: number,
    filter: string,
) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageAgents,
        status: STATUS.Requested,
        brokerageId,
        skip,
        filter,
    };
export const fetchBrokerageAgentsSucceeded = (brokerageAgents: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageAgents,
        status: STATUS.Succeeded,
        brokerageAgents,
    };

export const fetchBrokerageAgentsFailed = (brokerageAgentErrors: string[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.FetchBrokerageAgents,
        status: STATUS.Failed,
        brokerageAgentErrors,
    };

export const clearBrokerageData = () =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.ClearBrokerageAgents,
        status: STATUS.Requested,
    };
export const updateSelectedBrokerage = (selectedBrokerage: any) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.UpdateSelectedBrokerage,
        status: STATUS.Requested,
        selectedBrokerage,
    };
export const appendBrokerageAgentsRequested = (toAppendAgents: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.AppendBrokerageAgents,
        status: STATUS.Requested,
        toAppendAgents,
    };

export const appendBrokerageAgentsSucceeded = (totalAgents: any[]) =>
    <const>{
        type: BROKERAGE_HUB_ACTIONS.AppendBrokerageAgents,
        status: STATUS.Succeeded,
        totalAgents,
    };

export type fetchBrokerageAgents =
    | ReturnType<typeof fetchBrokerageAgentsRequested>
    | ReturnType<typeof fetchBrokerageAgentsSucceeded>
    | ReturnType<typeof fetchBrokerageAgentsFailed>;

// End brokerage agent actions

//#region UpdateFeedItem

export function updateFeedItemRequested(feedData: UpdateFeedData) {
    return {
        type: BROKERAGE_HUB_ACTIONS.UpdateFeedItem,
        status: STATUS.Requested,
        feedData,
    };
}

export function updateFeedItemSucceeded(updatedItem: SocialItem, updateType: string) {
    return {
        type: BROKERAGE_HUB_ACTIONS.UpdateFeedItem,
        status: STATUS.Succeeded,
        updatedItem,
        updateType,
    };
}

export function updateFeedItemFailed(errors: string[] = [], feedData: UpdateFeedData) {
    return {
        type: BROKERAGE_HUB_ACTIONS.UpdateFeedItem,
        status: STATUS.Failed,
        errors,
        feedData,
    };
}

export function setViewingRosterProfile(viewingRosterProfile: boolean) {
    return {
        type: BROKERAGE_HUB_ACTIONS.SetViewingRosterProfile,
        viewingRosterProfile,
    };
}

// export type updateFeedItem =
//     | ReturnType<typeof updateFeedItemRequested>
//     | ReturnType<typeof updateFeedItemFailed>
//     | ReturnType<typeof updateFeedItemSucceeded>;
