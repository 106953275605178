import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import Typography from '../../../components/Typography';
import DropdownIcon from '../../../images/dropDownIcon.svg';
import ButtonComponent from '../../../components/ButtonComponent';
import DropdownSelect from '../../../components/DropdownSelect';
import {
    getMarketRequested,
    setAuthAgentInfo,
    setAuthPracticeInfo,
    setPasswordResetActive,
    textSearchAgentsRequested,
} from '../actions';
import { getAuthMarket, getEntryAgents, getMarkets } from '../selectors';
import { findIndex, propEq } from 'ramda';
import SearchDropdownSelect from '../../../components/SearchDropdownSelect';
import { useHistory } from 'react-router';

interface EntryAgentSearchProps {}

const EntryAgentSearch = (props: EntryAgentSearchProps) => {
    const searchedAgents = useSelector(getEntryAgents);
    const authMarket = useSelector(getAuthMarket);
    const dispatch = useDispatch();

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();

    const [selectedFilterOption, selectFilterOption] = useState<string>('Name');

    const [searching, setSearching] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>('');

    const [selectedAgent, setSelectedAgent] = useState<any>(null);

    useEffect(() => {
        dispatch(textSearchAgentsRequested(searchText, selectedFilterOption === 'MLS ID'));
    }, [searchText, selectedFilterOption]);

    let displayAgents = [];
    if (searchedAgents) {
        displayAgents = searchedAgents.map((agent: any) => {
            const agentMarkets = agent?.markets || [];
            const authMarketIndex = findIndex(propEq('marketName', authMarket.name), agentMarkets);
            const authMarketInfo = agent?.markets[authMarketIndex];

            return {
                ...agent,
                mainDisplay: `${agent?.firstName} ${agent.lastName}`,
                subDisplay: authMarketInfo?.agentMlsId,
            };
        });
    }

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.contentBox}>
                <div className={styleSheet.topBox}>
                    <Typography textStyle="h2">Let's find you in the MLS database.</Typography>
                </div>
                <div className={styleSheet.midBox}>
                    <Typography
                        color={theme.palette.mediumGrey}
                        textStyle="b1"
                        style={{ fontSize: 18, paddingBottom: 20 }}
                    >
                        Find Yourself By
                    </Typography>
                    <SearchDropdownSelect
                        list={displayAgents}
                        searching={searching}
                        setSearching={(s: boolean) => setSearching(s)}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        selectedOption={selectedAgent}
                        selectOption={(so: any) => setSelectedAgent(so)}
                        selectedFilterOption={selectedFilterOption}
                        selectFilterOption={(fo: any) => selectFilterOption(fo)}
                        filterOptions={['Name', 'MLS ID']}
                    />
                </div>
                <div className={styleSheet.bottomBox}>
                    <ButtonComponent
                        onClick={() => {
                            const authAgentInfo = selectedAgent;
                            if (authAgentInfo?.mainDisplay) delete authAgentInfo.mainDisplay;
                            if (authAgentInfo?.subDisplay) delete authAgentInfo.subDisplay;
                            dispatch(setAuthAgentInfo(authAgentInfo));
                            dispatch(setPasswordResetActive(false));
                            if (authAgentInfo?.status === 'logged') {
                                history.push('/auth/verify');
                            } else {
                                history.push('/auth/login');
                            }
                        }}
                        disabled={!selectedAgent}
                        fill
                        width={257}
                    >
                        Continue
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
export default EntryAgentSearch;
