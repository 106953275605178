import { createSelector } from 'reselect';

export const getCalendarState = (state: any) => state.calendar;

export const getLoading = createSelector(
    getCalendarState,
    (calendarState) => calendarState.loading,
);

export const getAllShowings = createSelector(
    getCalendarState,
    (calendarState) => calendarState.allShowings,
);
