import React, { useEffect, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import _ from 'lodash';
// Import Image on SVG extension
import arrow from './paginationArrow.svg';
import dot from './selectedDot.svg';
import nonDot from './unselectedDot.svg';

interface PaginatorProps {
    data: Array<any>;
    itemsPerPage: number;
    currentPage: number;
    setCurrentPage: Function;
    setPaginatedData: Function;
    dots?: boolean;
}
const NewPaginator = (props: PaginatorProps) => {

    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    //IF dots is true, It will apperar dots instead of Number on the pagination
    const { data, itemsPerPage, currentPage, setCurrentPage, setPaginatedData, dots } = props;
    const [totalPages, setTotalPages] = useState<number>(Math.ceil(data.length / itemsPerPage));
    const [pages, setPages] = useState<Array<number>>([]);

    useEffect(() => {
        setTotalPages(Math.ceil(data.length / itemsPerPage));
    }, [data]);

    useEffect(() => {
        if (totalPages) setPages(_.range(1, totalPages > 6 ? 6 : totalPages + 1));
        else setPages([1]);
    }, [totalPages]);

    useEffect(() => {
        if (data) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            setPaginatedData(_(data).slice(startIndex).take(itemsPerPage).value());
        }
    }, [currentPage, data]);

    const handleClick = (type: string) => {
        if (
            (currentPage === 1 && type === 'dec') ||
            (currentPage === totalPages && type === 'inc')
        ) {
            return;
        }

        if (type === 'dec') {
            setCurrentPage(currentPage - 1);
            if (currentPage === pages[0]) setPages(pages.map((p) => p - 1));
        }
        if (type === 'inc') {
            setCurrentPage(currentPage + 1);
            if (currentPage === pages[4]) setPages(pages.map((p) => p + 1));
        }
        return;
    };

    return (
        <div className={styleSheet.root}>
            <img
                src={arrow}
                className={styleSheet.leftArrow}
                style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}
                onClick={() => handleClick('dec')}
            />
            {pages?.map((page, index) => {
                if (dots) {
                    return (
                        <div
                            key={index}
                            className={styleSheet.page}
                            onClick={() => setCurrentPage(page)}
                        >
                            {(page === currentPage) ? <img src={dot} /> : <img src={nonDot} />}
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={index}
                            className={page === currentPage ? styleSheet.selectedPage : styleSheet.page}
                            onClick={() => setCurrentPage(page)}
                        >
                            {page}
                        </div>
                    );
                }
            })}
            <img
                src={arrow}
                className={styleSheet.rightArrow}
                style={{ cursor: currentPage === totalPages ? 'default' : 'pointer' }}
                onClick={() => handleClick('inc')}
            />
        </div>
    );
};

export default NewPaginator;
