import { ANIMATED_DRAWER_WIDTH } from '../../../../components/AnimatedDrawer';
import { isMobile } from '../../../../utils/common';

const isMob = isMobile();

export default (theme: any) => {
    return {
        root: {},
        userType: {
            margin: {
                left: 48,
                right: 48,
                top: 20,
            },
        },
        userTypeMobile: {
            margin: {
                left: 25,
                right: 40,
                top: 20,
            },
        },
        separatorBar: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        searchBar: {
            ...theme.typography.medium,
            backgroundColor: theme.palette.background,
            height: 30,
            width: isMob ? '100%' : 248,
            border: {
                width: 0,
                radius: 5,
            },
            fontSize: 12,
            color: theme.palette.mediumGrey,
            marginTop: -2,
            paddingLeft: 7,
            marginLeft: isMob ? 10 : 0
        },
        container: {
            marginTop: 4,
        },
        metaRow: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: {
                top: 16,
                bottom: 16,
            },
        },
        typography: {
            height: 20,
        },

        scheduleButtonsContainer: {
            position: 'fixed',
            bottom: 0,
            width: ANIMATED_DRAWER_WIDTH,
            height: 68, // wireframe 88
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        scheduleButtonsContainerMobile: {
            width: 'auto',
            height: 68, // wireframe 88
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            textAlign: 'center',
            marginTop: 25
        },
        mb5: {
            marginBottom: 5
        },
        ml10: {
            marginLeft: 10
        }
    };
};
