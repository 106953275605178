import React, { ReactNode } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { getListings, getLoading } from './selectors';
import ClickableWithFeedback from '../../../components/ClickableWithFeedback';
import { useHistory } from 'react-router-dom';
import { setConfigDetails, setSelectedListing } from './actions';
import Typography from '../../../components/Typography';
import indexTheme from '../../../theme';
import { formatCurrency } from '../../../utils/common/transforms';
import Tag from '../../../components/Tag';
import Stars from './Stars';
import ButtonComponent from '../../../components/ButtonComponent';
import ExclaimIcon from '../../../images/exclaim.svg';
import { Loader, UpgradeSnackBar } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPlan } from '../Profile/selectors';
import { updateUpgradeModalVisible } from '../Profile/actions';

interface Props {}

const MyListings = (props: Props) => {
    const listings = useSelector(getListings);
    const loading = useSelector(getLoading);
    const dispatch = useDispatch();

    const history = useHistory();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const subPlan = useSelector(getSubscriptionPlan);
    const subTier = subPlan?.tier || 0;
    let configuredListingsIDs: Array<Object> = [];

    let configuredListings = 0;
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );
    listings?.map((e: any) => {
        if (e?.agentListing) {
            configuredListings++;
            configuredListingsIDs.push(e?._id);
        }
    });

    const CardHeader = ({ listing }: any) => (
        <div className={styleSheet.cardHeaderContainer}>
            <div className={styleSheet.cardHeader}>
                <div className={styleSheet.centerVertical}>
                    <Typography textStyle={'h3'}>
                        {'$'}
                        {formatCurrency(listing?.listPrice)}
                    </Typography>
                    <div className={styleSheet.verticalSeparator} />
                    <Typography textStyle={'h4'}>{listing?.address?.full}</Typography>
                    <Typography
                        textStyle={'b1'}
                        style={{ fontSize: 18, marginLeft: 6, marginRight: 26 }}
                    >
                        {listing?.address?.city} {listing?.address?.state}{' '}
                        {listing?.address?.postalCode}
                    </Typography>
                    {listing.agentListing && (
                        <div className={styleSheet.tagBox}>
                            <Tag
                                text={
                                    listing?.agentListing?.acceptingShowings
                                        ? 'Showings Enabled'
                                        : 'Showings Disabled'
                                }
                                color={listing?.agentListing?.acceptingShowings ? 'green' : 'red'}
                            />
                            <Tag
                                text={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'Syndication Enabled'
                                        : 'Syndication Disabled'
                                }
                                color={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'green'
                                        : 'red'
                                }
                            />
                        </div>
                    )}
                </div>
                <div className={styleSheet.centerVertical}>
                    {listing?.agentListing ? (
                        <ClickableWithFeedback
                            onClick={() => {
                                dispatch(setSelectedListing(listing));
                                window.scrollTo(0, 0);
                                history.push(`listings/${listing?.link?.toString()}`);
                            }}
                        >
                            <Typography textStyle={'h4'} color={indexTheme.palette.blue}>
                                View More
                            </Typography>
                        </ClickableWithFeedback>
                    ) : (
                        <Tag
                            Icon={ExclaimIcon}
                            style={{ height: 23 }}
                            text={'Needs Configuration'}
                            color={'red'}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    const Stat = ({ stat, title }: { stat: number | string | ReactNode; title: string }) => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                textStyle={'h3'}
                color={
                    stat === 'Coming Soon'
                        ? indexTheme.palette.mediumGrey
                        : indexTheme.palette.black
                }
            >
                {stat}
            </Typography>
            <div className={styleSheet.separator} />
            <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                {title}
            </Typography>
        </div>
    );

    const CardStatsSection = ({ listing }: any) => {
        // Calculate days on market
        var date1 = new Date(listing?.listDate);
        var date2 = new Date();
        var timeDiff = date2.getTime() - date1.getTime();
        var dayDiff = timeDiff / (1000 * 3600 * 24);
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    ...indexTheme.marginHorizontal(listing.agentListing ? 100 : 32),
                    justifySelf: 'center',
                    marginTop: 43,
                }}
            >
                {listing.agentListing ? (
                    <>
                        <Stat
                            stat={dayDiff ? Math.round(dayDiff) : 'N/A'}
                            title={'Days on Market'}
                        />
                        <Stat
                            stat={listing?.ratings?.totalShowings ?? 0}
                            title={'Total Showings'}
                        />
                        <Stat stat={'Coming Soon'} title={'Offers'} />
                        <Stat
                            stat={<Stars value={listing?.ratings?.overall || 0} />}
                            title={'Overall Rating'}
                        />
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            textStyle={'h4'}
                            style={{ marginBottom: 26 }}
                            color={indexTheme.palette.mediumGrey}
                        >
                            This listing needs to be configured before it can begin accepting
                            showings on Showingly.
                        </Typography>
                        <ButtonComponent
                            width={257}
                            fill
                            onClick={() => {
                                if (
                                    agentHasFreeTrial ||
                                    subTier > 0 ||
                                    configuredListings == 0 ||
                                    (configuredListings == 1 &&
                                        configuredListingsIDs[0] == listing?._id)
                                ) {
                                    dispatch(setConfigDetails(listing, 1));
                                    window.scrollTo(0, 0);
                                    history.push(`/listings/configure/${listing.link?.toString()}`);
                                } else {
                                    dispatch(updateUpgradeModalVisible(true));
                                }
                            }}
                        >
                            Configure Your Listing
                        </ButtonComponent>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styleSheet.root}>
            <UpgradeSnackBar />
            <div className={styleSheet.pageHeader}>
                <Typography textStyle={'h4'} color={indexTheme.palette.mediumGrey}>
                    Your Listings
                </Typography>
            </div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
                    <Loader />
                </div>
            ) : (
                listings?.map((listing: any) => {
                    return (
                        <div className={styleSheet.listingCard}>
                            <CardHeader listing={listing} />
                            <CardStatsSection listing={listing} />
                        </div>
                    );
                })
            )}
        </div>
    );
};
export default MyListings;
