import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    callStitchFunction,
    findRecords,
    createMessageBlast,
    updateShowingFields,
    findRecord,
    updateShowingRequestUnverified,
    removeAgentListing,
    updateShowingRequest,
} from '../../../store/api/sagas';
import {
    fetchShowingDetailsSucceeded,
    fetchShowingDetailsFailed,
    ShowingDetailsActions,
    acceptShowingRequestSucceded,
    acceptShowingRequestFailed,
    declineShowingRequestSucceded,
    declineShowingRequestFailed,
    fetchShowingDetailsRequested,
} from './actions';
import { STATUS } from '../../../utils/constants';
import { BSON } from 'realm-web';
import {
    CONSUMERS,
    SHOWING_REQUESTS,
    USER_TYPE_AGENT,
    LISTINGS_COLLECTION,
    MESSAGE_BLAST,
} from '../../../store/api/constants';
import { getProfileData } from '../Profile/selectors';

export function* fetchShowingDetails({ showingId }: any): Generator<any, any, any> {
    if (!showingId) {
        return;
    } else {
        try {
            const user = yield select(getProfileData);

            const { _id: agentObjectId } = user;

            const showing = yield findRecord(SHOWING_REQUESTS, {
                _id: showingId,
            });

            const agentListing = showing?.listing?.agentListing;
            const { listingId } = showing;

            const listing = yield findRecord(LISTINGS_COLLECTION, {
                _id: listingId,
            });

            if (listing) showing.listing = listing;

            if (showing.status === 'confirmed') {
                try {
                    const { lockboxLocation, lockboxType, lockCombo, lockboxPhotoUpload } =
                        yield call(
                            callStitchFunction,
                            'getLockboxInformation',
                            showingId,
                            agentObjectId,
                        );
                    showing.listing.agentListing.lockboxLocation = lockboxLocation;
                    showing.listing.agentListing.lockboxType = lockboxType;
                    showing.listing.agentListing.lockCombo = lockCombo;
                    showing.listing.agentListing.lockboxPhotoUpload = lockboxPhotoUpload;
                } catch (error) {
                    console.error('Somthing goes Wrong', error);
                }
            }

            if (agentListing) {
                let isNotListingAgent = true;
                agentListing.agents.map((agent: any) => {
                    if (agent._id?.toString() === agentObjectId.toString())
                        isNotListingAgent = false;
                });
                if (isNotListingAgent) {
                    const privateMessageBlast = yield findRecord(MESSAGE_BLAST, {
                        listingObjectId: listingId,
                        privateRecipients: agentObjectId,
                    });
                    const messageBlast = yield findRecord(MESSAGE_BLAST, {
                        listingObjectId: listingId,
                        privateRecipients: { $exists: false },
                    });
                    if (messageBlast || privateMessageBlast)
                        showing.messageBlast = privateMessageBlast ?? messageBlast;
                }
            }

            yield put(fetchShowingDetailsSucceeded(showing));
        } catch (error) {
            yield put(fetchShowingDetailsFailed());
        }
    }
}

export function* acceptShowingRequest({ showingId }: any): Generator<any, any, any> {
    if (!showingId) {
        return;
    } else {
        try {
            const showing = yield findRecord(SHOWING_REQUESTS, {
                _id: showingId,
            });

            // Get the type of the showing
            const { listing: { agentListing: { type: showingType = null } = {} } = {} } =
                showing || {};

            let statusToUpdate = 'logged';
            if (showingType) {
                if (showingType === 'appt_required') {
                    statusToUpdate = 'pending_internal';
                } else {
                    statusToUpdate = 'confirmed';
                }
            }

            const showingRequestData = {
                id: showingId,
                status: statusToUpdate,
            };
            const { upsertedId, modifiedCount } = yield call(
                updateShowingRequest,
                showingRequestData,
                {},
            );

            if (upsertedId || modifiedCount) {
                yield put(fetchShowingDetailsRequested(showingId));
                yield put(acceptShowingRequestSucceded());
            } else {
                yield put(
                    acceptShowingRequestFailed([
                        'Could not accept the showing request, please try again.',
                    ]),
                );
            }
        } catch (error) {
            yield put(acceptShowingRequestFailed());
        }
    }
}

export function* declineShowingRequest({ showingId }: any): Generator<any, any, any> {
    if (!showingId) {
        return;
    } else {
        try {
            let statusToUpdate = 'cancelled';

            const showingRequestData = {
                id: showingId,
                status: statusToUpdate,
            };
            const { upsertedId, modifiedCount } = yield call(
                updateShowingRequest,
                showingRequestData,
                {},
            );

            if (upsertedId || modifiedCount) {
                yield put(fetchShowingDetailsRequested(showingId));
                yield put(declineShowingRequestSucceded());
            } else {
                yield put(
                    declineShowingRequestFailed([
                        'Could not decline the showing request, please try again.',
                    ]),
                );
            }
        } catch (error) {
            yield put(declineShowingRequestFailed());
        }
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === ShowingDetailsActions.fetch && action.status === STATUS.Requested,
            fetchShowingDetails,
        ),
        takeLatest(
            (action: any) =>
                action.type === ShowingDetailsActions.accept && action.status === STATUS.Requested,
            acceptShowingRequest,
        ),
        takeLatest(
            (action: any) =>
                action.type === ShowingDetailsActions.decline && action.status === STATUS.Requested,
            declineShowingRequest,
        ),
    ]);
}
