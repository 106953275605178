import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Feed as FeedContainer } from 'web-lib';
import CreateNewPost from '../CreateNewPost';

import { FeedItem, FeedItemContentType } from '../../../../../../utils/constants';
import { defaultContentData } from '..';

import styles from './index.module.css';
import editIcon from '../../../../../../images/profile/editIcon.svg';
import editIconGray from '../../../../../../images/profile/editIconGray.svg';
import PostOrange from '../../../../../../images/feed/postOrange.svg';
import XIcon from '../../../../../../images/closeButton6A.svg';
import { updateFeedItemRequested } from '../../../actions';
import { getAgentObjectId } from '../../../../../../store/api/selectors';
import { getProfilePhoto } from '../../../../Profile/selectors';

import { BSON } from 'realm-web';
import { hasFetchedFeed, isFeedLoading } from '../../../selectors';
import { CustomFeedCard } from '../CustomFeedCard';

interface FeedProps {
    brokerageFeed: FeedItem[];
    createContentData: FeedItem;
    setCreatePost: Function;
    newContentCreated: boolean;
    resetContentCreated: Function;
    setCreateContentData: Function;
    newPostLoading: boolean;
    loading: boolean;
    errors: Array<any>;
    setSelectedItem: Function;
}

const Feed = ({
    brokerageFeed = [],
    createContentData,
    setCreatePost,
    newContentCreated,
    resetContentCreated,
    setCreateContentData,
    newPostLoading,
    loading,
    errors,
    setSelectedItem,
}: //Connected Props
FeedProps) => {
    const fetchedFeed: boolean = useSelector(hasFetchedFeed);
    const agentObjectId: BSON.ObjectId = useSelector(getAgentObjectId);
    const loadingFeed: boolean = useSelector(isFeedLoading);
    const profilePhoto: string = useSelector(getProfilePhoto);
    const [createNewPost, setCreateNewPost] = useState<boolean>(false);
    const dispatch = useDispatch();
    const updateFeedItem: Function = (feedItem: any) => dispatch(updateFeedItemRequested(feedItem));

    let brokerageFeedItems = [];

    brokerageFeedItems =
        !(loadingFeed && !fetchedFeed) && brokerageFeed.length
            ? brokerageFeed.map((feedItem: any) => {
                  const time = feedItem?.created?.createdAt || 0; // 0 should never happen, just getting TS off my back
                  const upvotesCount = feedItem.upvoting
                      ? (feedItem?.upvotesCount || 0) + 1
                      : feedItem.downvoting
                      ? feedItem?.upvotesCount - 1 || 0
                      : feedItem.upvotesCount || 0;
                  const likedByUser =
                      feedItem.upvoting || feedItem.downvoting
                          ? feedItem.upvotes?.hasOwnProperty('"' + agentObjectId?.toString() + '"')
                              ? false
                              : true
                          : feedItem.upvotes?.hasOwnProperty('"' + agentObjectId?.toString() + '"')
                          ? true
                          : false;
                  return {
                      creator: {
                          name: feedItem.created?.name || '',
                          image: feedItem.created?.photoUri || '',
                          type: feedItem.created?.type || '',
                      },
                      image: feedItem.image || '',
                      link: feedItem.link || '',
                      description: feedItem.description || '',
                      title: feedItem.title || '',
                      created: new Date(time),
                      upvotes: feedItem?.upvotes || {},
                      upvotesCount: upvotesCount,
                      startTime: feedItem?.start,
                      endTime: feedItem?.end,
                      item: feedItem,
                      onUpvote: () => {
                          feedItem.upvotes?.hasOwnProperty('"' + agentObjectId?.toString() + '"')
                              ? updateFeedItem({
                                    eventId: feedItem._id,
                                    userObjectId: agentObjectId,
                                    type: '',
                                    comment: null,
                                })
                              : updateFeedItem({
                                    eventId: feedItem._id,
                                    userObjectId: agentObjectId,
                                    type: 'upvote',
                                    comment: null,
                                });
                      },
                      disabled: feedItem?.upvoting || false,
                      likedByUser: likedByUser,
                      setSelectedItem: setSelectedItem,
                  };
              })
            : [];

    return (
        <div className={styles.root}>
            <div className={styles.createContentLinkContainer}>
                {!brokerageFeedItems?.length && !loadingFeed && (
                    <div className={styles.noPosts}>
                        <div>There’s no posts in your feed yet. Make the first one.</div>
                    </div>
                )}
                <div
                    className={styles.makePostButton}
                    onClick={() => {
                        setCreateContentData({
                            ...defaultContentData,
                            type: FeedItemContentType.Post,
                        });
                        setCreateNewPost(!createNewPost);
                    }}
                >
                    <img alt={'event'} className={styles.contentIcon} src={PostOrange} />
                    <span style={{ color: createNewPost ? '#6A6A6A' : '#ff8238' }}>
                        Make a Post
                    </span>
                </div>
            </div>

            {createNewPost && (
                <CreateNewPost
                    setCreatePost={setCreatePost}
                    errors={errors}
                    newPostLoading={newPostLoading}
                    createContentData={createContentData}
                    setCreateContentData={setCreateContentData}
                    profilePhoto={profilePhoto}
                    closeModal={setCreateNewPost}
                    modalOpen={createNewPost}
                    postCreated={newContentCreated}
                    resetContentCreated={resetContentCreated}
                />
            )}

            <FeedContainer
                containerStyling={styles.feedContainerStyling}
                noFeedMessage={''}
                customCards={brokerageFeedItems.map((item: any, index: number) => (
                    <>{CustomFeedCard(item)}</>
                ))}
            />
        </div>
    );
};

export default Feed;
