import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import Typography from '../../../components/Typography';
import DropdownIcon from '../../../images/dropDownIcon.svg';
import ButtonComponent from '../../../components/ButtonComponent';
import DropdownSelect from '../../../components/DropdownSelect';
import {
    agentLoginRequested,
    getMarketRequested,
    setAuthAgentInfo,
    setAuthPracticeInfo,
    setPasswordResetActive,
    textSearchAgentsRequested,
} from '../actions';
import {
    getAuthAgent,
    getAuthMarket,
    getEntryAgents,
    getMarkets,
    getLoginInProgress,
    getAuthErrors,
} from '../selectors';
import { findIndex, propEq } from 'ramda';
import SearchDropdownSelect from '../../../components/SearchDropdownSelect';
import { useHistory } from 'react-router';

interface AgentLoginProps {}

const AgentLogin = (props: AgentLoginProps) => {
    const authAgent: { email: string } = useSelector(getAuthAgent);
    const { email = 'Placeholder email that no one will have as an email' } = authAgent;
    const loginInProgress = useSelector(getLoginInProgress);
    const loginErrors = useSelector(getAuthErrors);

    const dispatch = useDispatch();

    const agentLogin: Function = (email: string, password: string, history: any) =>
        dispatch(agentLoginRequested(email, password, history));

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();

    useEffect(() => {
        dispatch(setPasswordResetActive(false));
    }, []);

    const [loginClicked, setLoginClicked] = useState<boolean>(false);

    const [placeholderText, setPlaceholderText] = useState<string>('Enter Password');
    const [passText, setPassText] = useState<string>('');

    const [errorText, setErrorText] = useState<string | null>(null);

    const focusPass = () => {
        document.getElementById('pass')?.focus();
    };

    useEffect(() => {
        const errorsPresent = !!loginErrors?.length;
        if (errorsPresent) {
            setPassText('');
            setPlaceholderText('Enter Password');
            setLoginClicked(false);
            setErrorText(loginErrors[0]);
        }
    }, [loginErrors]);

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.contentBox}>
                <div className={styleSheet.topBox}>
                    <Typography textStyle="h2">Welcome Back.</Typography>
                </div>
                <div className={errorText ? styleSheet.midBoxRelative : styleSheet.midBox}>
                    <div
                        className={styleSheet.searchBar}
                        style={{ width: 516 }}
                        onClick={() => {
                            focusPass();
                        }}
                    >
                        <input
                            placeholder={placeholderText}
                            id="pass"
                            type="password"
                            autoComplete={'new-password'}
                            onBlurCapture={() => {
                                setTimeout(() => {
                                    setPlaceholderText('Enter Password');
                                }, 100);
                            }}
                            onFocus={() => {
                                setPlaceholderText('');
                            }}
                            value={passText}
                            onChange={(e: any) => {
                                setErrorText(null);
                                setPassText(e?.nativeEvent?.target?.value);
                            }}
                            className={styleSheet.inputText}
                            style={{ width: 516, textAlign: 'center' }}
                        />
                    </div>
                    {errorText && (
                        <div style={{ position: 'absolute', top: 90, left: 48 }}>
                            <Typography textStyle="b2" color={theme.palette.red}>
                                {loginErrors[0]}
                            </Typography>
                        </div>
                    )}
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            style={{ marginTop: 44, cursor: 'pointer' }}
                            textStyle="p1"
                            color={theme.palette.blue}
                            onClick={() => {
                                history.push('/auth/verify');
                                dispatch(setPasswordResetActive(true));
                            }}
                        >
                            Reset Password
                        </Typography>
                    </div>
                </div>
                <div className={styleSheet.bottomBox}>
                    <ButtonComponent
                        onClick={() => {
                            setLoginClicked(true);
                            agentLogin(email, passText, history);
                        }}
                        disabled={false || loginClicked || loginInProgress}
                        fill
                        width={257}
                    >
                        {loginClicked || loginInProgress ? 'Logging In...' : 'Log In'}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
export default AgentLogin;
