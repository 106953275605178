// helper function for the FIND_ALL_SHOWING_DATA_ACTION.Success case
// loop through all finters to find the correct showing
// if we find the correct showing in this filter, update it, else return the showing
export const updateShowingsInRedux = (filterToUpdate: any, updatedShowing: any) => {
    const newFilterState = filterToUpdate.map((groupedShowing: any) => {
        const newPreviews = groupedShowing.showingPreviews.map((showing: any) => {
            if (showing._id.toString() === updatedShowing._id.toString()) {
                const agentListing =
                    updatedShowing.status === 'confirmed' || updatedShowing.status === 'completed'
                        ? updatedShowing.listing.agentListing
                        : null;
                return {
                    ...showing,
                    status: updatedShowing.status,
                    start: updatedShowing.start,
                    end: updatedShowing.end,
                    agentListing,
                    consumerId: updatedShowing.consumerId,
                    consumer: updatedShowing.consumer,
                };
            } else {
                return showing;
            }
        });
        return {
            ...groupedShowing,
            showingPreviews: newPreviews,
        };
    });

    return newFilterState;
};
