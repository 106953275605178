import { STATUS } from '../../../store/api/constants';
import { QUEUED_SHOWING_ACTION, CLEAR_QUEUED_SHOWING_SUCCESS_MESSAGE } from './actions';

export const initialState = {
    // queued showings
    showingQueue: [],
    queuedShowingsScheduled: false,
    updatingShowingInQueue: false,
    loading: false,
    queuedScheduleLoading: false,
    fetchingListings: false,
    uploadSuccessfull: false,
    error: null,
    loadingFetchedClient: false,
    clientObjects: [],
    clientFetchedError: [],
    // delegations
    groupDelegationPrice: 0,
    priceFetchError: null,
    priceLoading: false,
    waypoints: {},
    ssoQueueDataSaved: false,
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case QUEUED_SHOWING_ACTION.QueuedShowingsPersist:
            return {
                ...state,
                showingQueue: action.showingQueue,
            };

        case QUEUED_SHOWING_ACTION.QueueShowingInteraction:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                        queuedScheduleLoading:
                            action.interactionType === 'schedule'
                                ? true
                                : state.queuedScheduleLoading,
                        queuedShowingsScheduled: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loading: false,
                        queuedScheduleLoading:
                            action.interactionType === 'schedule'
                                ? false
                                : state.queuedScheduleLoading,
                        showingQueue: action.showingQueue,
                        queuedShowingsScheduled: action.interactionType === 'schedule',
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        queuedScheduleLoading: false,
                        error: action.error,
                        queuedShowingsScheduled: false,
                    };
            }

        case QUEUED_SHOWING_ACTION.FetchGroupDelegationPrice:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        groupDelegationPrice: null,
                        priceFetchError: null,
                        priceLoading: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        groupDelegationPrice: action.groupDelegationPrice,
                        priceLoading: false,
                    };
                case STATUS.Failed: {
                    return {
                        ...state,
                        priceFetchError: action.error,
                        priceLoading: false,
                    };
                }
            }

        case QUEUED_SHOWING_ACTION.FetchListings:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        fetchingListings: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        showingQueue: action.queuedShowings,
                        fetchingListings: false,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                        fetchingListings: false,
                    };
                }
            }

        case QUEUED_SHOWING_ACTION.FetchClient:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingFetchedClient: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { clientObjects } = action;
                    return {
                        ...state,
                        loadingFetchedClient: false,
                        clientObjects: clientObjects,
                    };
                }
                case STATUS.Failed: {
                    const { errorFetchClient } = action;
                    return {
                        ...state,
                        loadingFetchedClient: false,
                        clientFetchedError: errorFetchClient,
                    };
                }
            }

        case CLEAR_QUEUED_SHOWING_SUCCESS_MESSAGE:
            return {
                ...state,
                error: null,
                uploadSuccessfull: false,
                queuedShowingsScheduled: false,
            };

        case QUEUED_SHOWING_ACTION.ClearQueuedShowings:
            return {
                ...state,
                showingQueue: [],
            };

        case QUEUED_SHOWING_ACTION.SetDefaultWaypoint: {
            return {
                ...state,
                waypoints: action.waypoints,
            };
        }

        case QUEUED_SHOWING_ACTION.SaveShowingQueue: {
            return {
                ...state,
                ssoQueueDataSaved: true,
            };
        }

        default:
            return state;
    }
}
