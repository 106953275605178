import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-grid-system';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import {
    AnimatedDrawer,
    ClickableWithFeedback,
    PickerComponent,
    RadioOptionRow,
} from '../../../../components';
import styles from './styles';
import {
    Break,
    FilterOptionType,
    MAP_FILTER_PICKER_OPTIONS,
    OnlyConfiguredOption,
    PRICE_OPTIONS,
    RangeRow,
    SQUARE_FEET_OPTIONS,
} from './Utils';

interface FilterDrawerProps {
    filterVisible: boolean;
    setFilterVisible: Function;
    filterOptions: FilterOptionType;
    setFilterOptions: Function;
}

const FilterDrawer = ({
    filterVisible,
    setFilterVisible,
    filterOptions,
    setFilterOptions,
}: FilterDrawerProps) => {
    const useStyles = createUseStyles(styles);
    const theme: DefaultTheme = useTheme();
    const styleSheet = useStyles({ theme });

    // Since the component has a confirm button, we need to store a temporary (unconfirmed)
    // version of the filterOptions. Upon the user confirming their choices, then set the state
    // that was passed down from the parent
    const [unconfirmedFilterOptions, setUnconfirmedFilterOptions] = useState<FilterOptionType>(
        cloneDeep(filterOptions),
    );

    const {
        onlyConfigured,
        beds,
        baths,
        parking,
        minPrice,
        maxPrice,
        minSqft,
        maxSqft,
        statuses,
        types,
    } = unconfirmedFilterOptions;

    // used to close the animated drawer
    const closeDrawerRef = useRef<any>();

    // update a specific field on the filter state object
    const setFilterOptionState = (val: any, field: string) => {
        var tempState: FilterOptionType = unconfirmedFilterOptions;
        (tempState as any)[field] = val;
        setUnconfirmedFilterOptions({ ...tempState });
    };
    // If val is already chosen, remove it
    // If it does not exist, add it
    // Update it on filter state object
    const setFilterOptionStateTypeOrStatus = (val: any, listName: 'types' | 'statuses') => {
        var tempState: FilterOptionType = unconfirmedFilterOptions;
        if ((tempState[listName] as any).includes(val)) {
            (tempState[listName] as any).splice((tempState[listName] as any).indexOf(val), 1);
        } else {
            (tempState[listName] as any).push(val);
        }
        setUnconfirmedFilterOptions({ ...tempState });
    };

    // Handle overlapping price options
    // Find the index of the value that was just selected and
    // bring the other value back or forward one in relation to the
    // newly chosen value
    useEffect(() => {
        if (maxPrice < minPrice) {
            var currIndex = PRICE_OPTIONS.findIndex((elem) => elem.value === maxPrice);
            setFilterOptionState(
                currIndex - 1 > 0 ? PRICE_OPTIONS[currIndex - 1].value : -1,
                'minPrice',
            );
        }
    }, [maxPrice]);
    useEffect(() => {
        if (maxPrice < minPrice) {
            var currIndex = PRICE_OPTIONS.findIndex((elem) => elem.value === minPrice);
            setFilterOptionState(
                currIndex + 1 < PRICE_OPTIONS.length - 1
                    ? PRICE_OPTIONS[currIndex + 1].value
                    : Number.MAX_VALUE,
                'maxPrice',
            );
        }
    }, [minPrice]);

    // Handle overlapping sqft options
    // Description above
    useEffect(() => {
        if (maxSqft < minSqft) {
            var currIndex = SQUARE_FEET_OPTIONS.findIndex((elem) => elem.value === maxSqft);
            setFilterOptionState(
                currIndex - 1 > 0 ? SQUARE_FEET_OPTIONS[currIndex - 1].value : -1,
                'minSqft',
            );
        }
    }, [maxSqft]);
    useEffect(() => {
        if (maxSqft < minSqft) {
            var currIndex = SQUARE_FEET_OPTIONS.findIndex((elem) => elem.value === minSqft);
            setFilterOptionState(
                currIndex + 1 < SQUARE_FEET_OPTIONS.length - 1
                    ? SQUARE_FEET_OPTIONS[currIndex + 1].value
                    : Number.MAX_VALUE,
                'maxSqft',
            );
        }
    }, [minSqft]);

    // Config for the option pickers
    const listingOptionPickers: Array<{
        val: any;
        func: Function;
        label: string;
    }> = [
        { val: beds, func: (val: number) => setFilterOptionState(val, 'beds'), label: 'Beds' },
        { val: baths, func: (val: number) => setFilterOptionState(val, 'baths'), label: 'Baths' },
        {
            val: parking,
            func: (val: number) => setFilterOptionState(val, 'parking'),
            label: 'Parking',
        },
    ];

    // Config for the house type option rows
    const houseTypeOptionRows: Array<{
        val: boolean;
        func: Function;
        label: string;
    }> = [
        {
            val: types.includes('House'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'House',
        },
        {
            val: types.includes('Condo'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'Condo',
        },
        {
            val: types.includes('Townhome'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'Townhome',
        },
        {
            val: types.includes('Apartment'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'Apartment',
        },
        {
            val: types.includes('Multifamily'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'Multifamily',
        },
        {
            val: types.includes('Other'),
            func: (val: boolean) => setFilterOptionStateTypeOrStatus(val, 'types'),
            label: 'Other',
        },
    ];

    // Config for bottom buttons
    const bottomButtons: Array<{
        text: string;
        className: 'confirmFilterButton' | 'cancelFilterButton';
        func: Function;
    }> = [
        {
            text: 'Save & Filter',
            className: 'confirmFilterButton',
            func: () => {
                setFilterOptions(cloneDeep(unconfirmedFilterOptions));
                setFilterVisible(false);
            },
        },
        {
            text: 'Cancel',
            className: 'cancelFilterButton',
            func: () => {
                setUnconfirmedFilterOptions(cloneDeep(filterOptions));
                setFilterVisible(false);
            },
        },
    ];

    return filterVisible ? (
        <AnimatedDrawer
            onClose={() => {
                setUnconfirmedFilterOptions(cloneDeep(filterOptions));
                setFilterVisible(false);
            }}
            ref={closeDrawerRef}
        >
            <div className={styleSheet.filterDrawer}>
                {/* Header and Line (underline comes from styling) */}
                <div className={styleSheet.headerText}>{'Filter'}</div>
                {/* Options */}
                <OnlyConfiguredOption
                    checked={onlyConfigured}
                    setChecked={(val: number) => setFilterOptionState(val, 'onlyConfigured')}
                />
                <Break />
                <div className={styleSheet.smallGreyText}>{`Listing Status:`}</div>
                <RadioOptionRow
                    title={'Active'}
                    checked={statuses.includes('Active')}
                    setChecked={(val: boolean) => setFilterOptionStateTypeOrStatus(val, 'statuses')}
                />
                <RadioOptionRow
                    title={'Pending'}
                    checked={statuses.includes('Pending')}
                    setChecked={(val: boolean) => setFilterOptionStateTypeOrStatus(val, 'statuses')}
                />
                <RadioOptionRow
                    title={'Coming Soon'}
                    checked={statuses.includes('Coming Soon')}
                    setChecked={(val: boolean) => setFilterOptionStateTypeOrStatus(val, 'statuses')}
                />
                <Break />
                <RangeRow
                    min={minPrice}
                    setMin={(val: number) => setFilterOptionState(val, 'minPrice')}
                    max={maxPrice}
                    setMax={(val: number) => setFilterOptionState(val, 'maxPrice')}
                    options={PRICE_OPTIONS}
                    label={'Price Range'}
                />
                <Break />
                {listingOptionPickers.map((picker, index) => (
                    <div>
                        <div className={styleSheet.smallGreyText}>{picker.label}</div>
                        <PickerComponent
                            data={Object.values(MAP_FILTER_PICKER_OPTIONS)}
                            selectedOption={picker.val}
                            onPressButton={(val: string) => {
                                picker.func(val);
                            }}
                            optionWidth={95}
                        />
                        <Break />
                    </div>
                ))}
                <RangeRow
                    min={minSqft}
                    setMin={(val: number) => setFilterOptionState(val, 'minSqft')}
                    max={maxSqft}
                    setMax={(val: number) => setFilterOptionState(val, 'maxSqft')}
                    options={SQUARE_FEET_OPTIONS}
                    label={'Square Feet'}
                />
                <Break />
                <div className={styleSheet.smallGreyText}>{`House Type:`}</div>
                {houseTypeOptionRows.map((row) => {
                    return (
                        <RadioOptionRow title={row.label} checked={row.val} setChecked={row.func} />
                    );
                })}
                <Break />

                {/* Confirm and Cancel Button Row */}
                <Row style={{ marginBottom: 100 }}>
                    {bottomButtons.map((button) => {
                        return (
                            <ClickableWithFeedback
                                className={styleSheet[button.className]}
                                onClick={button.func}
                            >
                                {button.text}
                            </ClickableWithFeedback>
                        );
                    })}
                </Row>
            </div>
        </AnimatedDrawer>
    ) : (
        <></>
    );
};

export default FilterDrawer;
