import Dexie from 'dexie';

const db = new Dexie('ShowinglyAgentsWeb');
db.version(1).stores({ searchListings: '++ignoreThisKey' }).upgrade(tx => {
    return tx.table("searchListings").toCollection().modify(listing => {
        delete listing.ignoreThisKey;
    });
});;


export default db;