import { DateTime, Interval } from 'luxon';

export const getStatusColor = (status: any) => {
    // Configure labels and colors for displaying status within showing modal
    const statusColors: any = {
        great: '#2fd2a8',
        good: '#48a4ff',
        bad: '#ff4343',
        neutral: '#9a9a9a',
    };
    const greatStatuses = ['completed', 'logged'];
    const goodStatuses = ['confirmed'];
    const badStatuses = ['cancelled', 'denied'];

    const statusStatus = greatStatuses.includes(status)
        ? 'great'
        : goodStatuses.includes(status)
        ? 'good'
        : badStatuses.includes(status)
        ? 'bad'
        : 'neutral';

    return statusColors[statusStatus];
};

export const mergeOverlappingShowings = (showingsForDay: any[]) => {
    showingsForDay.forEach((showing: any, index: any) => {
        showingsForDay[index].overlapping = 0;
    });

    /*
     * Create an array for final card output, and if there are any showings
     * add the first one to it
     */
    if (showingsForDay.length > 1) {
        // Sort all showings for this date by start time
        let swap;
        do {
            swap = false;
            for (let i = 0; i < showingsForDay.length - 1; i++) {
                if (showingsForDay[i].start > showingsForDay[i + 1].start) {
                    let temp = showingsForDay[i];
                    showingsForDay[i] = showingsForDay[i + 1];
                    showingsForDay[i + 1] = temp;
                    swap = true;
                }
            }
        } while (swap);

        // Merge all showings that overlap for a given time period
        for (let i = 1; i < showingsForDay.length; i++) {
            const curr = showingsForDay[i];
            const currStartTime = DateTime.fromJSDate(curr.start);
            const currEndTime = DateTime.fromJSDate(curr.end);
            const prev = showingsForDay[i - 1];
            let currInterval: any = 0;
            let prevInterval: any = 0;

            // Interval of current element being looked at
            currInterval = Interval.fromDateTimes(currStartTime, currEndTime);

            /**
             * If the previous index is not an array (currently has no overlapping showings),
             * compare the interval of the previous element. Otherwise compare the first index
             * of the previous element
             */
            if (!Array.isArray(prev)) {
                const prevStartTime = DateTime.fromJSDate(prev.start);
                const prevEndTime = DateTime.fromJSDate(prev.end);
                prevInterval = Interval.fromDateTimes(prevStartTime, prevEndTime);
            } else {
                const currPrev = prev[prev.length - 1];
                const currPrevStartTime = DateTime.fromJSDate(currPrev.start);
                const currPrevEndTime = DateTime.fromJSDate(currPrev.end);
                prevInterval = Interval.fromDateTimes(currPrevStartTime, currPrevEndTime);
            }

            /**
             * If the current showing overlaps the previous showing, merge the current
             * and overlapping showings. If the previous showing is already a list of
             * elements, move the current showing into that list of already overlapping showings.
             */
            if (currInterval.overlaps(prevInterval)) {
                if (!Array.isArray(prev)) {
                    showingsForDay[i - 1] = [{ ...prev }, { ...curr }];
                } else {
                    showingsForDay[i - 1].push(curr);
                }
                showingsForDay.splice(i, 1);
                i--;
            }
        }
    }
    return showingsForDay;
};

export const sortShowings = (showings: Array<any>, selectedFilter: string) => {
    const flip = selectedFilter === 'Upcoming' ? -1 : 1;
    const sortedShowings = showings.sort(function (a: any, b: any) {
        return a.start === b.start ? 0 : a.start < b.start ? 1 * flip : -1 * flip;
    });

    return sortedShowings;
};
