import React, { useCallback, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SearchListings from './domains/main/SearchListings';
import Listings from './domains/main/Listings';
import ConfirmDenyScreen from './domains/confirm_deny';
import ShowingsScreen from './domains/main/Showings';
import Suspended from './domains/auth/ProfileDataUpdates/Suspended';
import AddCardPage from './domains/main/Profile/AddCardPage';
import BrokerageHub from './domains/main/Brokerage/BrokerageHub';
import InvoiceModal from './domains/main/Profile/InvoiceModal';
import AgentProfile from './domains/main/Social/AgentProfile';
import ChangePhoneNumber from './domains/main/Profile/ChangePhoneNumber';
import LinkLicense from './domains/main/Profile/LinkLicense';
import UpgradePage from './domains/main/Profile/ManagePlan/Upgrade';
import DowngradePage from './domains/main/Profile/ManagePlan/Downgrade';

import SearchIcon from './images/search.svg';
import ListingsIcon from './images/myListingsGrey.svg';
import CalendarIcon from './images/calendar.svg';
import DoorIcon from './images/door.svg';
import HandshakeIcon from './images/handshakeIcon.svg';
import SearchSelectedIcon from './images/searchSelected.svg';
import ListingsSelectedIcon from './images/myListingsSelected.svg';
import CalendarSelectedIcon from './images/calendarSelected.svg';
import DoorSelectedIcon from './images/doorSelected.svg';
import HandshakeSelectedIcon from './images/handshakeIconSelected.svg';
import {
    ClickableWithFeedback,
    SSOBottomBar,
    SSOModal,
    UpgradeSubscriptionModal,
} from './components';

import styles from './utils/commonStyles.module.css';
import { acceptTermsOfServiceRequested, sendAppDownloadLink } from './domains/auth/actions';
import {
    getMyBrokerages,
    getProfilePhoto,
    subModalVisible,
} from './domains/main/Profile/selectors';
import { ConnectedSideBar } from './components/SideBar';
import agentWordmark from './agentWordmark.svg';
import NotiIcon from './images/notiIcon.svg';
import QueueCart from './images/queueShoppingCart.svg';
import NotiUnseenIcon from './images/notiUnseenIcon.svg';
import HelpIcon from './images/helpCenterIcon.svg';
import CalendarScreen from './domains/main/Calendar';
import { getCurrentListing } from './domains/main/ListingDetailsScreen/selectors';
import ListingDetails from './domains/main/ListingDetailsScreen';
import NotificationScreen from './domains/main/Notifications/NotificationScreen';
import ShowingDetails from './domains/main/ShowingDetailsScreen';
import SelectedListingScreen from './domains/main/Listings/SelectedListingScreen';
import QueueDrawer from './domains/main/QueuedShowings/QueueDrawer';
import ConfigureListing from './domains/main/Listings/ConfigureListing';
import ProfileScreenNew from './domains/main/Profile/ProfileScreen';
import CRMScreen from './domains/main/CRM/CRMScreen';
import { animated, useTransition } from 'react-spring';
import { ConnectedScheduleScreen } from './domains/main/ShowingRequests/ScheduleScreen';
import MessageBlastComponent from './domains/main/Listings/MessageBlastScreen';
import { updateUpgradeModalVisible } from './domains/main/Profile/actions';
import { saveListingInRedux } from './domains/main/ListingDetailsScreen/actions';
import { fetchSSOListingRequested } from './domains/main/SearchListings/actions';
import ReadMore from './domains/main/ReadMore';
import {
    getSSOListing,
    getSSOListingLoading,
    getSSOUnconnectedListingLoading,
    getSSOUnconnectedListingLink,
} from './domains/main/SearchListings/selectors';

const path = window.location.pathname;
const check = path.split('/');

interface AuthenticatedAppProps {
    userProfileData?: any;
    userPhoto?: string;
    isDelinquent?: boolean;
}

function AuthenticatedApp({ userProfileData, userPhoto, isDelinquent }: AuthenticatedAppProps) {
    const history = useHistory();
    const [isBottomBarPresent, setIsBottomBarPresent] = useState(true);

    const entryEndpointData: any = sessionStorage.getItem('entryEndpointData');
    const entryEndpointDataParsed: any = JSON.parse(entryEndpointData);

    const queueData: any = sessionStorage.getItem('queueData');
    const queueDataParsed: any = JSON.parse(queueData);

    const currentListing = useSelector(getCurrentListing);
    const ssoListing = useSelector(getSSOListing);
    const ssoListingLoading = useSelector(getSSOListingLoading);
    const ssoUnconnectedListingLoading = useSelector(getSSOUnconnectedListingLoading);
    const ssoUnconnectedListingLink = useSelector(getSSOUnconnectedListingLink);
    const queuedShowings = useSelector((state: any) => state.searchListings.showingQueue);
    const clientFirstName = useSelector((state: any) => state.auth.agent.firstName);
    const profilePhoto = useSelector(getProfilePhoto);
    const invoiceHistory = useSelector((state: any) => state.profile.invoiceHistory?.data);
    const brokerages = useSelector(getMyBrokerages);
    const dispatch = useDispatch();
    const onAcceptTermsOfService = useCallback(
        (urls: Array<String>, tempUserId: string) =>
            dispatch(acceptTermsOfServiceRequested(urls, tempUserId)),
        [],
    );
    const sendAppDownloadLinkView: any = useCallback(
        (phone: string) => dispatch(sendAppDownloadLink(phone)),
        [],
    );
    const [showNotificationDrawer, setShowNotificationDrawer] = useState<boolean>(false);
    const [seenNotifications, setSeenNotifications] = useState<boolean>(false);

    const [showQueueDrawer, setShowQueueDrawer] = useState<boolean>(false);
    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);
    const [activeQueueShowing, setActiveQueueShowing] = useState<any>(null);

    const upgradeVisible = useSelector(subModalVisible);

    const isComingViaOIDC = sessionStorage.getItem('isComingViaOIDC') === 'true';
    const showsSSOBottomBar = sessionStorage.getItem('showSSOBottomBar');

    const Sidebar = () => {
        let listItems = [
            {
                link: '/search',
                icon: SearchIcon,
                iconSelected: SearchSelectedIcon,
                pageTitle: 'Search',
            },
            {
                link: '/myShowings',
                icon: DoorIcon,
                iconSelected: DoorSelectedIcon,
                pageTitle: 'Showings',
            },
            {
                link: '/calendar',
                icon: CalendarIcon,
                iconSelected: CalendarSelectedIcon,
                pageTitle: 'Calendar',
            },
            {
                link: '/listings',
                icon: ListingsIcon,
                iconSelected: ListingsSelectedIcon,
                pageTitle: 'Listings',
            },
            {
                link: '/crm',
                icon: HandshakeIcon,
                iconSelected: HandshakeSelectedIcon,
                pageTitle: 'Clients',
            },
        ];

        if (!brokerages?.length) listItems.splice(7, 1); // <-- This is necessary, because if the agent doesn't have a brokerage attached to them, we don't need to render the brokerageHub page

        return isDelinquent ? null : (
            <ConnectedSideBar
                ListItems={listItems}
                userPhotoUri={profilePhoto}
                userProfileLink={'/profile'}
                loggedUser={clientFirstName}
                iconTextStyling={styles.iconText}
                iconTextStylingSelected={styles.iconTextSelected}
                defaultPhoto={userPhoto ? true : false}
                userPhotoContainerStyling={styles.photoContainerStyling}
                userPhotoStyling={styles.userPhotoStyling}
            />
        );
    };

    const checkProfileData = async () => {
        if (!userProfileData.stitchUserId) return;

        const { status } = userProfileData;

        if (status === 'suspended') {
            return history.push('/suspended');
        }
    };

    const Header = () => {
        const { notifications = null } = userProfileData;
        const getNotificationCount = () => {
            var count = 0;
            for (var i = 0; i < notifications?.length; i++) {
                if (notifications[i].seen === false) {
                    count++;
                }
            }
            return count;
        };

        return (
            <div className={styles.header}>
                <img src={agentWordmark} className={styles.wordmark} />
                <div className={styles.profileIcons}>
                    <ClickableWithFeedback
                        onClick={() => {
                            setShowQueueDrawer(true);
                        }}
                    >
                        <img src={QueueCart} className={styles.headerIcon} id="queueCartAuthApp" />
                    </ClickableWithFeedback>
                    <ClickableWithFeedback
                        onClick={() => {
                            setShowNotificationDrawer(true);
                            setSeenNotifications(true);
                        }}
                    >
                        <img
                            src={
                                getNotificationCount() && !seenNotifications
                                    ? NotiUnseenIcon
                                    : NotiIcon
                            }
                            className={styles.headerIcon}
                        />
                    </ClickableWithFeedback>
                    <ClickableWithFeedback
                        onClick={() => window.open('https://www.support.showingly.com/knowledge')}
                    >
                        <img src={HelpIcon} className={styles.headerIcon} />
                    </ClickableWithFeedback>
                    <img
                        src={userPhoto}
                        className={styles.headerIcon}
                        onClick={() => history.push('/profile')}
                    />
                </div>
            </div>
        );
    };

    const location = useLocation();
    const transitions = useTransition(location, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
    });

    // Effects
    useEffect(() => {
        const queue: any = sessionStorage.getItem('queueData');
        const queueParsed: any = JSON.parse(queue);
        if (
            queueParsed?.listingid &&
            queueParsed.mlsname &&
            (queueParsed?.unconnected === 'false' || queueParsed.unconnected === undefined)
        ) {
            dispatch(fetchSSOListingRequested(queueParsed.listingid, queueParsed.mlsname));
        }
    }, []);

    useEffect(() => {
        if (
            isComingViaOIDC &&
            ssoListingLoading === undefined &&
            ssoUnconnectedListingLoading === undefined
        ) {
            history.push('/search');
            sessionStorage.removeItem('isComingViaOIDC');
            return;
        }

        if (currentListing && ssoListingLoading === false) {
            history.push(`/listingDetails/${queueDataParsed.mlsname}/${queueDataParsed.listingid}`);
            sessionStorage.removeItem('isComingViaOIDC');
            return;
        }

        if (ssoUnconnectedListingLink && ssoUnconnectedListingLoading === false) {
            history.push(`/listings/configure/${ssoUnconnectedListingLink}`);
            sessionStorage.removeItem('isComingViaOIDC');
            return;
        }

        if (!ssoUnconnectedListingLink && ssoUnconnectedListingLoading === false) {
            history.push('/listings');
            sessionStorage.removeItem('isComingViaOIDC');
            return;
        }
    }, [ssoUnconnectedListingLoading, ssoUnconnectedListingLink, isComingViaOIDC, currentListing]);

    useEffect(() => {
        if (!entryEndpointDataParsed) {
            sessionStorage.setItem('isComingViaOIDC', 'false');
        }
    }, [entryEndpointDataParsed]);

    useEffect(() => {
        userProfileData && checkProfileData();
    }, [userProfileData]);

    return transitions((props, item) => (
        <div style={{ overflowY: 'hidden' }}>
            {isBottomBarPresent && isComingViaOIDC && showsSSOBottomBar !== 'false' && (
                <SSOBottomBar
                    sendAppDownloadLink={(phoneNumber: string) =>
                        sendAppDownloadLinkView(phoneNumber)
                    }
                    onPressCloseButton={() => {
                        const pathname = window.location.pathname;
                        setIsBottomBarPresent(false);
                        sessionStorage.setItem('showSSOBottomBar', 'false');
                    }}
                />
            )}
            {!userProfileData?.signUpCompleted && isComingViaOIDC && (
                <SSOModal
                    sendAppDownloadLink={(phoneNumber: string) =>
                        sendAppDownloadLinkView(phoneNumber)
                    }
                    acceptTermsAndService={(urls: Array<String>) =>
                        onAcceptTermsOfService(urls, userProfileData?.tempUserId || '')
                    }
                />
            )}
            {!(check[1].localeCompare('showingRequests') === 0) && (
                <>
                    {/* Header is no longer in the design for agents web */}
                    <Header />
                    <Sidebar />

                    {/* we put this here to ensure that the search bar always renders upon login */}

                    {(queuedShowings !== undefined && queuedShowings?.length > 0) ||
                    (entryEndpointDataParsed && entryEndpointDataParsed?.listingid) ||
                    (queueDataParsed && queueDataParsed?.listingid)
                        ? dispatch(saveListingInRedux(ssoListing, 'buyer')) && true
                        : ''}

                    {/* Empty string makes it so refresh doesn't redirect you to dashboard*/}
                </>
            )}

            <animated.div style={props}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return <Redirect to="/search" />;
                        }}
                    />
                    <Route
                        exact
                        path="/auth"
                        render={() => {
                            return <Redirect to="/search" />;
                        }}
                    />
                    {/* Every screen that will have the sidebar/header needs to go in here */}
                    <div style={{ marginLeft: 200, marginTop: 60 }}>
                        <Route
                            path="/showingRequests/:_id"
                            render={() => {
                                return <ConfirmDenyScreen />;
                            }}
                        />
                        <Route exact path="/profile/addCard/:type">
                            <AddCardPage />
                        </Route>
                        <Route exact path="/readMore">
                            <ReadMore />
                        </Route>
                        <Route exact path="/search">
                            <SearchListings />
                        </Route>
                        <Route exact path="/myShowings">
                            <ShowingsScreen />
                        </Route>
                        <Route exact path="/myShowings/listSide">
                            <ShowingsScreen listSide={true} />
                        </Route>
                        <Route exact path="/listings">
                            <Listings />
                        </Route>
                        <Route exact path="/profile">
                            <ProfileScreenNew />
                        </Route>
                        <Route exact path="/profile/invoiceHistory">
                            <InvoiceModal invoiceHistory={invoiceHistory} />
                        </Route>
                        <Route
                            exact
                            path="/myQueue"
                            render={() => {
                                return <Redirect to="/search" />;
                            }}
                        />
                        <Route exact path="/profile/link">
                            <LinkLicense />
                        </Route>
                        <Route exact path="/listings/:id">
                            <SelectedListingScreen />
                        </Route>
                        <Route exact path="/listings/configure/:id">
                            <ConfigureListing />
                        </Route>
                        <Route exact path="/calendar">
                            <CalendarScreen />
                        </Route>

                        <Route exact path="/crm">
                            <CRMScreen />
                        </Route>
                        <Route exact path="/messageBlast/:id">
                            <MessageBlastComponent />
                        </Route>
                        <Route exact path={`/listingDetails/:mlsName/:listingId`}>
                            <ListingDetails />
                        </Route>

                        <Route path={`/showingDetails/`}>
                            <ShowingDetails />
                        </Route>
                    </div>

                    <Route exact path="/social/agentProfile">
                        <AgentProfile />
                    </Route>

                    <Route exact path="/suspended">
                        <Suspended />
                    </Route>

                    <Route path="/brokerageHub">
                        <BrokerageHub />
                    </Route>

                    <Route exact path="/profile/phoneNumber">
                        <ChangePhoneNumber />
                    </Route>

                    <Route exact path="/profile/managePlan/upgrade">
                        <UpgradePage />
                    </Route>
                    <Route exact path="/profile/managePlan/downgrade">
                        <DowngradePage />
                    </Route>
                </Switch>

                {showNotificationDrawer && (
                    <NotificationScreen
                        visible={showNotificationDrawer}
                        onClose={() => setShowNotificationDrawer(false)}
                    />
                )}
                <QueueDrawer
                    visible={showQueueDrawer}
                    onClose={() => setShowQueueDrawer(false)}
                    scheduleDrawerInteract={(e: any) => {
                        setActiveQueueShowing(e);
                        setShowScheduleDrawer(true);
                    }}
                />
                <ConnectedScheduleScreen
                    listing={activeQueueShowing}
                    opType={'Queue'}
                    onClose={() => setShowScheduleDrawer(false)}
                    visible={showScheduleDrawer}
                    clientDrawerInteract={() => {}}
                    backgroundOpacity={0}
                />
                <UpgradeSubscriptionModal
                    onClose={() => dispatch(updateUpgradeModalVisible(false))}
                    visible={upgradeVisible}
                />
            </animated.div>
        </div>
    ));
}

export default AuthenticatedApp;
