import { BSON } from 'realm-web';
import { Showing } from '../../../utils/constants';
// Constants

export const LIST_SKIP_INCREMENT_SIZE = 10;
export enum MY_SHOWINGS_FILTER {
    None = 'None',
    NeedsAction = 'Needs Action',
    Upcoming = 'Upcoming',
    Leads = 'Showingly Leads',
    Delegated = 'Delegated',
    PickUp = 'Picked-Up',
    ClientShowing = 'Client Showing',
}
export enum MY_SHOWINGS_ACTION {
    Fetch = '[MyShowings] Fetch',
    Search = '[MyShowings] Search ',
    SetFilter = '[MyShowings] Set Filter',
    SetLoading = '[MyShowings] Set Loading',
    SetRefreshing = '[MyShowings] Set Refreshing',
    AddNew = '[MyShowings] Add New',
    GetFeedback = '[MyShowings] Get Feedback',
    SubmitFeedback = '[MyShowings] Add New Feedback',
    SetFeedbackDisabled = '[MyShowings] reset isFeedbackDisabled',
    GetLockBoxData = '[MyShowings] Get Lockbox Data',
    GetConsumerData = '[My Showings] Get Consumer Data',
    RescheduleShowing = '[My Showing] Reschedule Showing',
    Updating = '[My Showings] Updating',
    ClearErrors = '[My Showings] Clear Errors',
}
export enum STATUS {
    Requested,
    Succeeded,
    Failed,
}
export enum FIND_ALL_SHOWING_DATA_ACTION {
    GetAll = 'GET_ALL_SHOWING_DATA',
    Success = 'GET_ALL_SHOWING_DATA_SUCCESS',
    Failure = 'GET_ALL_SHOWING_DATA_FAILURE',
}

// Object Types
export type ShowingPreview = {
    _id: BSON.ObjectId;
    status: string;
    type: string;
    start: Date;
    clientPhoto: string;
};
export type ShowingListPreview = ShowingPreview & {
    listingId: BSON.ObjectId;
    listingPhoto: string;
    listingPrice: number;
    address: string;
};
export type ShowingsGroupedByListing = {
    _id: BSON.ObjectId;
    listingPhoto: string;
    price: number;
    address: string;
    lat: number;
    lng: number;
    showingCount: number;
    showingPreviews: ShowingPreview[];
    city: string;
    streetName: string;
    streetNumberText: string;
    postalCode: string;
    state: string;
    blasts: Object[];
    listing: Object;
};
export interface MyShowingsState {
    loading: boolean;
    refreshing: boolean;
    paginationLoader: boolean;
    filter: MY_SHOWINGS_FILTER;
    error: string;
    scheduleShowingErrors?: string[];
    mySearchedShowings: object[];
    // A cached list of showings for each filter.
    [MY_SHOWINGS_FILTER.None]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.NeedsAction]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.Upcoming]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.Leads]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.Delegated]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.PickUp]: ShowingsGroupedByListing[];
    [MY_SHOWINGS_FILTER.ClientShowing]: ShowingsGroupedByListing[];
    attempted: {
        [MY_SHOWINGS_FILTER.None]: boolean;
        [MY_SHOWINGS_FILTER.NeedsAction]: boolean;
        [MY_SHOWINGS_FILTER.Upcoming]: boolean;
        [MY_SHOWINGS_FILTER.Leads]: boolean;
        [MY_SHOWINGS_FILTER.Delegated]: boolean;
        [MY_SHOWINGS_FILTER.PickUp]: boolean;
        [MY_SHOWINGS_FILTER.ClientShowing]: boolean;
    };
    selectedShowing: Showing | null | undefined;
}

// Types for Action Functions

export interface MyShowingsFetch {
    type: MY_SHOWINGS_ACTION.Fetch;
}
export interface MyShowingsFetchRequest extends MyShowingsFetch {
    status: STATUS.Requested;
    filter: MY_SHOWINGS_FILTER;
    refresh: boolean;
}
export interface MyShowingsFetchSuccess extends MyShowingsFetch {
    status: STATUS.Succeeded;
    filter: MY_SHOWINGS_FILTER;
    myShowings: ShowingsGroupedByListing;
}
export interface MyShowingsFetchFailed extends MyShowingsFetch {
    status: STATUS.Failed;
    filter: MY_SHOWINGS_FILTER;
    error: string;
}
export interface MyShowingsSearchRequest {
    type: MY_SHOWINGS_ACTION.Search;
    status: STATUS.Requested;
    searchText: string;
}
export interface MyShowingsSearchSuccess {
    type: MY_SHOWINGS_ACTION.Search;
    status: STATUS.Succeeded;
    myShowings: object;
}
export interface MyShowingsSearchFailed {
    type: MY_SHOWINGS_ACTION.Search;
    status: STATUS.Failed;
    errors: string[];
}
export type MyShowingsSetFilter = {
    type: MY_SHOWINGS_ACTION.SetFilter;
    filter: MY_SHOWINGS_FILTER;
};
