import { Listing, Showing } from '../constants';
import _ from 'lodash';

type DateObj = {
    hours: number;
    minutes: number;
};

// thanks Zach
export const getUnavailableDates = (listing: Listing, showings: Showing[]) => {
    var unavailableDates: any = [];

    const listingAvailability = listing?.agentListing?.availability?.recurring || null;
    const noticeRequired = listing?.agentListing?.noticeRequired || 0;

    var now = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var finalHour = now.getHours() + noticeRequired / 60;
    var createTomorrowBlock = false;
    if (finalHour > 24) {
        createTomorrowBlock = true;
        finalHour -= 24;
    }

    var startHours = now.getHours();
    var startMinutes = Math.floor(now.getMinutes() / 15) * 15;
    var endHours =
        now.getHours() + noticeRequired / 60 < 22 ? now.getHours() + noticeRequired / 60 : 22;
    var endMinutes = now.getMinutes() + noticeRequired / 60;
    if (endMinutes > 60) {
        endHours++;
        endMinutes -= 60;
    }
    endMinutes = Math.ceil(now.getMinutes() / 15) * 15;

    var noticeBlock1 = noticeRequired
        ? {
              start: {
                  hours: startHours,
                  minutes: startMinutes,
              },
              end: {
                  hours: endHours,
                  minutes: endMinutes,
              },
              weekday: now.getDay(),
              overlap: false,
          }
        : null;

    var tomorrowBlock = createTomorrowBlock
        ? {
              start: {
                  hours: 7,
                  minutes: 0,
              },
              end: {
                  hours: finalHour,
                  minutes: endMinutes,
              },
              weekday: tomorrow.getDay(),
              overlap: false,
          }
        : null;

    var upTillNowBlock = {
        start: {
            hours: 7,
            minutes: 0,
        },
        end: {
            hours: startHours,
            minutes: startMinutes,
        },
        weekday: now.getDay(),
        overlap: false,
    };

    /**
     * Shift the date because listing availability date time starts
     * on monday, not on sunday
     */

    var shiftedNowIndex = now.getDay() - 1;
    var shiftedTomorrowIndex = now.getDay();
    if (shiftedNowIndex === -1) {
        shiftedNowIndex = 6;
    }
    if (shiftedTomorrowIndex === -1) {
        shiftedTomorrowIndex = 6;
    }
    // check if agentListing has changed listing availability
    if (listingAvailability && !_.isEmpty(listingAvailability)) {
        /**
         * Get the current day being looked at. Assuming listingAvailability
         * exists, this means that the listing is connected, thus the start
         * time is 7am and end time is 9pm.
         */
        var dayStart: any = new Date();
        var dayEnd: any = new Date();
        dayStart.setHours(7, 0, 0, 0);
        dayEnd.setHours(21, 0, 0, 0);
        const days = Object.keys(listingAvailability);
        days.map((dayKey: any, index: number) => {
            const day = listingAvailability[dayKey as keyof typeof listingAvailability];
            // get the current day being looked at
            var currentDayStart = dayStart;
            currentDayStart.setDate(currentDayStart.getDate() + index);
            var currentDayEnd = dayEnd;
            currentDayEnd.setDate(currentDayEnd.getDate() + index);
            /**
             * if startTime for a day is not an empty string, then the
             * agent has restricted part of that day, so there will be
             * one or two unavailability blocks
             */
            if (day && day.startTime !== '' && day.endTime !== '') {
                var dayAvailability = [];
                var startBlock = null;
                var endBlock = null;
                /**
                 * If the available start time comes after the day opens,
                 * then there is a block of unavailability at the
                 * beginning of the day
                 */
                if (day?.startTime?.getHours() !== currentDayStart?.getHours()) {
                    startBlock = {
                        start: {
                            hours: currentDayStart.getHours(),
                            minutes: currentDayStart.getMinutes(),
                        },
                        end: {
                            hours: day.startTime.getHours(),
                            minutes: day.startTime.getMinutes(),
                        },
                    };
                    dayAvailability.push(startBlock);
                }
                /**
                 * If the available end time comes before the day end,
                 * then there is a block of unavailability at the
                 * end of the day
                 */
                if (day.endTime.getHours() !== currentDayEnd.getHours()) {
                    endBlock = {
                        start: {
                            hours: day.endTime.getHours(),
                            minutes: day.endTime.getMinutes(),
                        },
                        end: {
                            hours: currentDayEnd.getHours(),
                            minutes: currentDayEnd.getMinutes(),
                        },
                    };
                    dayAvailability.push(endBlock);
                }
                unavailableDates.push(dayAvailability);
            } else {
                /**
                 * if day.startTime is an empty string, then the
                 * entire day is unavailable
                 */
                unavailableDates.push([
                    {
                        start: {
                            hours: currentDayStart.getHours(),
                            minutes: currentDayStart.getMinutes(),
                        },
                        end: {
                            hours: currentDayEnd.getHours(),
                            minutes: currentDayEnd.getMinutes(),
                        },
                    },
                ]);
            }
        });
        if (noticeBlock1) {
            unavailableDates[shiftedNowIndex].push(noticeBlock1);
        }
        if (tomorrowBlock) {
            unavailableDates[shiftedTomorrowIndex].push(tomorrowBlock);
        }
    }

    if (!unavailableDates?.length) {
        unavailableDates = [[], [], [], [], [], [], []];
    }

    unavailableDates[shiftedNowIndex].push(upTillNowBlock);

    // if showings exist, condense their times into an array
    if (showings && showings.length) {
        var listingAvailabilityExists: boolean = true;
        // first day of the week (current day)
        var dayStart: any = new Date();
        var dayEnd: any = new Date();

        if (!unavailableDates.length) {
            unavailableDates = [-1, -1, -1, -1, -1, -1, -1];
            //listingAvailabilityExists;
            dayStart.setHours(8, 0, 0, 0);
            dayEnd.setHours(20, 0, 0, 0);
        } else {
            dayStart.setHours(7, 0, 0, 0);
            dayEnd.setHours(21, 0, 0, 0);
        }
        /**
         * for each showing, find the correct day of the week
         * and compare it to the time slot it should sit in
         */
        showings.map((showing: any, index: number) => {
            const start = showing.start;
            const end = showing.end;
            const slot = {
                start: {
                    hours: start.getHours(),
                    minutes: start.getMinutes(),
                },
                end: {
                    hours: end.getHours(),
                    minutes: end.getMinutes(),
                },
                showing: true,
            };

            let shiftedDateIndex: any = showing.start.getDay() - 1;

            if (shiftedDateIndex == -1) {
                shiftedDateIndex = 6;
            }

            const unavailableDay = unavailableDates[shiftedDateIndex];
            /**
             * if unavailableDay is not equal to -1, then there are
             * already unavailable times for that day
             */
            if (unavailableDay !== -1 && unavailableDay?.length) {
                unavailableDates[shiftedDateIndex].push(slot);
            } else {
                unavailableDates[shiftedDateIndex] = [slot];
            }
        });
    }

    // sort unavailable dates by start hour
    unavailableDates = unavailableDates.map((date: DateObj[]) =>
        date.sort((a: any, b: any) => a.start.hours - b.start.hours),
    );

    return unavailableDates;
};

export const getDateNum = (day: string) => {
    switch (day) {
        case 'Mon': {
            return 0;
        }
        case 'Tue': {
            return 1;
        }
        case 'Wed': {
            return 2;
        }
        case 'Thu': {
            return 3;
        }
        case 'Fri': {
            return 4;
        }
        case 'Sat': {
            return 5;
        }
        case 'Sun': {
            return 6;
        }
        default: {
            return 0;
        } // <- should never happen but i need to get TS off my back
    }
};
