const height = window.innerHeight;

export default (theme: any) => {
    const contentWidth = 632;
    return {
        root: {
            height: height - 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            userSelect: 'none',
            backgroundColor: theme.palette.background,
        },
        contentBox: {
            height: 630,
            width: contentWidth,
            backgroundColor: theme.palette.white,
            borderRadius: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        topBox: {
            // ...theme.paddingHorizontal(48),
            padding: 48,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            width: 536,
        },
        midBox: {
            padding: 48,
            paddingTop: 76,
            paddingBottom: 20,
            height: 250,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            flexDirection: 'column',
            width: contentWidth - 96,
        },
        bottomBox: {
            marginTop: 44,
        },
        fieldsContainer: {
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        fieldContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        searchBar: (props: any) => ({
            height: 35,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            width: props?.width ?? 238,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: 5,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        inputText: {
            fontSize: 18,
            ...theme.typography.medium,
            color: theme.palette.black,
            '&::placeholder': {
                color: '#bcbcbc',
            },
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            padding: 0,
        },
        checkFieldsContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            marginLeft: -6.5,
            paddingTop: 48,
        },
        checkField: {
            flexDirection: 'row',
            display: 'flex',
            paddingBottom: 15,
        },
        blueText: {
            '&:active': {
                opacity: 0.5,
            },
            paddingLeft: 3,
            cursor: 'pointer',
        },
        checkFieldTextContainer: {
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    };
};
