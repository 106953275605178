import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import AddClientRow from './AddClientRow';
import {
    AnimatedModal,
    ClickableWithFeedback,
    Loader,
    Typography,
} from '../../../../../components';
import CloseIcon from '../../../../images/xBlack.svg';
import ButtonComponent from '../../../../../components/ButtonComponent';
import { NewClient } from '../constants';

interface AddManuallyProps {
    newClients: Array<NewClient>;
    setNewClients: Function;
}
const AddManually = ({ newClients, setNewClients }: AddManuallyProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if (newClients.length === 5) setButtonDisabled(true);
        if (newClients.length < 5) setButtonDisabled(false);
    }, [newClients]);

    const removeRow = (index: number) => {
        const temp = [...newClients];
        temp.splice(index, 1);
        setNewClients(temp);
    };

    const updateClientData = (clientData: NewClient, index: number) => {
        let temp = [...newClients];
        temp[index] = clientData;
        setNewClients(temp);
    };

    return (
        <div className={styleSheet.addManuallyRoot}>
            {newClients.map((client: NewClient, index: number) => {
                return (
                    <AddClientRow
                        key={index}
                        client={client}
                        index={index}
                        removeRow={removeRow}
                        updateClientData={updateClientData}
                    />
                );
            })}
            <ButtonComponent
                disabled={buttonDisabled}
                className={styleSheet.addRowButton}
                onClick={() =>
                    setNewClients([...newClients, { firstName: '', lastName: '', phoneNumber: '' }])
                }
            >
                Add Row
            </ButtonComponent>
        </div>
    );
};

export default AddManually;
