import { createSelector } from 'reselect';
import { BSON } from 'realm-web';
import { getProfileState } from '../../domains/main/Profile/selectors';

export const getBrokerageObjectId = createSelector(
    getProfileState,
    (profileState) => profileState.profileData.brokerage,
);

export const getAgentObjectId = createSelector(
    getProfileState,
    (profileState) => new BSON.ObjectId(profileState.profileData._id),
);
