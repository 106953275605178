export default (theme: any) => {
    return {
        rowRoot: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 720,
            padding: 5,
        },
        number: {
            height: 26,
            width: 26,
            textAlign: 'center',
            color: theme.palette.mediumGrey,
        },
        inputContainer: {
            background: theme.palette.background,
            border: 'none',
            borderRadius: 5,
            fontWeight: 500,
            fontSize: 16,
            width: 200,
            height: 30,
            textAlign: 'center',
        },
        xIcon: {
            height: 20,
            width: 20,
            marginTop: 5,
        },

        addManuallyRoot: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        addRowButton: {
            marginTop: '25px !important',
            height: '40px !important',
            fontSize: '16px !important',
            fontWeight: '600 !important',
            width: '150px !important',
        },

        addByCSVRoot: {
            height: 350,
            width: 800,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        row: {
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            width: 800,
            paddingTop: 10,
            paddingBottom: 10,
        },
        step1: {
            width: 650,
            margin: 'auto',
        },
        csvLabel: {
            color: theme.palette.blue,
            cursor: 'pointer',
            fontSize: 16,
            fontWeight: 600,
        },
        step2: {
            width: 650,
            margin: 'auto',
        },
        categoryRow: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        step3: {
            width: 650,
            margin: 'auto',
        },
        typography: {
            display: 'inline-block',
            height: 28,
            marginBottom: 5,
        },
        exampleContact: {
            backgroundColor: theme.palette.background,
            width: 190,
            height: 35,
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.paddingHorizontal(10),
        },
    };
};
