import { Consumer, Showing, STATUS } from '../../../utils/constants';
import {
    SetSelectedClient,
    ShowingAction,
    SHOWING_ACTION,
    FetchShowingAvailability,
    FetchAgentAvailability,
} from './actions';

// Record in-progress operations for every stored showing

type LocallyStoredShowing = {
    showingDetails: any;
    currentOp: SHOWING_ACTION | null;
    error: string | null;
};

const initialState = {
    // Cached Showings, mapped by their showingId.
    showings: new Map() as Map<string, LocallyStoredShowing>,
    createdShowing: [null, null] as any, // [creationId, createdShowingId]
    rescheduledShowing: [null, null],
    createShowingError: [null, null] as any,
    selectedClient: null as Consumer | null,
    // Prices/payouts for showing related services
    delegationPrice: null,
    pickupPayout: null,
    priceLoading: false,
    leadPrice: null,
    loading: false,
    priceFetchError: null as String | null,
    paymentNeeded: false,
    unavailableDates: null,
    agentSchedule: null,
    calendarListingLoading: false,
    calendarAgentLoading: false,
    toggleValue: true,
};

export type ShowingManagerState = typeof initialState;

export const showingRequests = (
    state: ShowingManagerState = initialState,
    action: ShowingAction | SetSelectedClient | FetchShowingAvailability | FetchAgentAvailability,
) => {
    var createdShowing = [null, null];
    var rescheduledShowing = [null, null];
    switch (action.type) {
        case SHOWING_ACTION.FetchShowingAvailability:
            switch (action.status) {
                case STATUS.Requested:
                    return { ...state, calendarListingLoading: true, unavailableDates: null };

                case STATUS.Succeeded:
                    return {
                        ...state,
                        unavailableDates: action.unavailableDates,
                        calendarListingLoading: false,
                    };

                case STATUS.Failed: {
                    return {
                        ...state,
                        calendarListingLoading: false,
                        error: action.error,
                    };
                }
            }
        case SHOWING_ACTION.FetchAgentAvailability:
            switch (action.status) {
                case STATUS.Requested:
                    return { ...state, calendarAgentLoading: true };

                case STATUS.Succeeded:
                    return {
                        ...state,
                        agentSchedule: action.agentSchedule,
                        calendarAgentLoading: false,
                    };

                case STATUS.Failed: {
                    return {
                        ...state,
                        calendarAgentLoading: false,
                        error: action.error,
                    };
                }
            }

        case SHOWING_ACTION.SetShowingClient:
            return {
                ...state,
                selectedClient: action.client,
            };

        case SHOWING_ACTION.ClearShowingIds:
            return {
                ...state,
                createdShowing: null,
                rescheduledShowing: null,
            };

        case SHOWING_ACTION.Create:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        createdShowing: null,
                        loading: true,
                    };

                case STATUS.Failed:
                    return {
                        ...state,
                        createShowingError: [action.listingId, action.error],
                        loading: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        createdShowing: [action.listingId, action.showing._id.toHexString()],
                        loading: false,
                    };
                // Intentional fallthrough
            }
        case SHOWING_ACTION.Reschedule:
            switch (action.status) {
                case STATUS.Requested: {
                    const currentShowingState = state.showings?.get(action.showingId);
                    return {
                        ...state,
                        loading: true,
                        showings: state.showings.set(action.showingId, {
                            showingDetails: currentShowingState?.showingDetails ?? null,
                            currentOp: currentShowingState?.currentOp ?? action.type,
                            error: null,
                        }),
                        rescheduledShowing: [null, null],
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        showings: state.showings.set(action?.showingId, {
                            showingDetails: state.showings.get(action?.showingId)?.showingDetails,
                            currentOp: null,
                            error: action.error,
                        }),
                        rescheduledShowing: [null, null],
                    };
                case STATUS.Succeeded: {
                    const showId: any = action.showingId;
                    return {
                        ...state,
                        loading: false,
                        showings: state.showings.set(showId?.toHexString(), {
                            showingDetails: action.showing,
                            currentOp: null,
                            error: null,
                        }),
                        rescheduledShowing: [
                            action.showing?.listingId,
                            action.showing?._id?.toHexString(),
                        ],
                    };
                    // Intentional fallthrough
                }
            }
        // case SHOWING_ACTION.Fetch:
        // case SHOWING_ACTION.UpdateClient:
        case SHOWING_ACTION.UpdateStatus:
            // case SHOWING_ACTION.Delegate:
            // case SHOWING_ACTION.UpdateDelegation:
            // case SHOWING_ACTION.AcceptPickUp:
            // case SHOWING_ACTION.PurchaseLead:
            switch (action.status) {
                case STATUS.Requested: {
                    const currentShowingState = state.showings.get(action.showingId);
                    return {
                        ...state,
                        loading: true,
                        showings: state.showings.set(action.showingId, {
                            showingDetails: currentShowingState?.showingDetails ?? null,
                            currentOp: currentShowingState?.currentOp ?? action.type,
                            error: null,
                        }),
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        createdShowing,
                        loading: false,
                        showings: state.showings.set(action.showing._id.toHexString(), {
                            showingDetails: action.showing,
                            currentOp: null,
                            error: null,
                        }),
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        showings: state.showings.set(action.showingId, {
                            showingDetails: state.showings.get(action.showingId)?.showingDetails,
                            currentOp: null,
                            error: action.error,
                        }),
                    };
            }
        default:
            return state;
    }
};
