import * as Realm from 'realm-web';
import {
    SHOWING_REQUESTS,
    AGENTS,
    LISTINGS_COLLECTION,
    BROKERAGES,
    AGENTS_SOCIAL_CONNECTIONS,
} from './constants';
import { DateTime } from 'luxon';
import { generateSignature } from '../../utils/common';

export async function login(email, password) {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    const credentials = Realm.Credentials.emailPassword(email, password);
    const user = await client.logIn(credentials);
    return { client, user };
}

// Logins with custom auth function
export async function customAuthLogin(payload) {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    const credentials = Realm.Credentials.function(payload);
    const user = await client.logIn(credentials);
    return { client, user };
}

/**
 * Logout the current signed in user from Stitch.
 * This is applicable for both an anonymous logged in user and
 * a user logged in via email/password.
 *
 * @returns {Promise<void>}
 */
export async function logout() {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return client.currentUser.logOut();
}

/*
 * Creates a payment method against the current user,
 * If the user does not have a stripe account, the function will take care of that
 * and set the provided card as default payment method
 * @param client Mongo Stitch Client returned from initialization
 * @param tokenId
 * @returns {Promise<T>}
 */
export async function createCustomerPaymentMethod(tokenId) {
    return callStitchFunction('setMyDefaultPaymentMethod', tokenId);
}

/*
 * Creates a stripe subscription with the given price and customer id's, assuming the customer
 * has a set payment method (`source` in this case) with which to make the monthly payments
 * @param client Mongo Stitch Client returned from initialization
 * @param customerId
 * @param priceId
 * @returns {Promise<T>}
 */

export function getCollection(collectionName) {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    const mongoClient = client.currentUser.mongoClient(
        process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
    );
    const db = mongoClient.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    return db.collection(collectionName);
}

/**
 * Anonymous stitch login so we can gain access to specific functions
 * @returns {Promise<StitchAppClient>}
 */
export async function anonLogin() {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    const credentials = Realm.Credentials.anonymous();
    const user = await client.logIn(credentials);
    return user;
}

/**
 * Call the given stitch function with its params
 * @param client Mongo Stitch Client returned from initialization
 * @param endpoint
 * @param params
 * @returns {Promise<*>}
 */
export function callStitchFunction(endpoint, ...params) {
    const client = Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return client.currentUser.functions.callFunction(
        endpoint,
        ...params.map((d) => (d ? d : undefined)),
    );
}

export async function generatePipeline(collectionName, totalPipeline) {
    const collection = getCollection(collectionName);
    return await collection.aggregate(totalPipeline).toArray();
}

export async function findShowingRequest(query = {}, projection = {}) {
    return getCollection(SHOWING_REQUESTS).findOne(query, { projection });
}

export async function updateShowingRequest(showingRequestData, unverifiedData) {
    return callStitchFunction(
        'updateShowingRequestStatusOrType',
        showingRequestData,
        unverifiedData,
    );
}

/**
 * Gets the agent's record associated to the given stitch user, if any.
 *
 * @param user
 * @returns {Promise<Document | null>}
 */
export async function getAgentRecord(user) {
    // make a find one
    const query = { $or: [{ stitchUserId: user.id }, { tempUserId: user.id }] };
    return getCollection(AGENTS).findOne(query);
}

export async function getAgentBrokerage(mlsName, brokerageId) {
    // make a find one
    const query = {
        markets: {
            $elemMatch: {
                mlsName: mlsName,
                brokerageId: brokerageId,
            },
        },
    };
    return getCollection(BROKERAGES).findOne(query);
}

/* Updates the agent's record associated to the given stitch user.
 *
 * @param user
 * @param data
 * @returns {Promise<Document | null>}
 */
export async function upsertAgentProfile(user, setData) {
    const agents = getCollection(AGENTS);
    const query = { $or: [{ stitchUserId: user.id }, { tempUserId: user.id }] };
    return await agents.updateOne(query, { $set: setData });
}

/* Updates the agent's social record associated to the given agentId.
 *
 * @param agentObjectId
 * @param setData
 * @returns {Promise<Document | null>}
 */
export async function upsertAgentSocial(agentObjectId, setData) {
    const agents = getCollection(AGENTS_SOCIAL_CONNECTIONS);
    const query = { agentObjectId };
    return agents.updateOne(query, { $set: setData });
}

/* Updates the agent's record associated to the given stitch user.
 *
 * @param user
 * @param data
 * @returns {Promise<Document | null>}
 */
export async function upsertTempUser(userId, tempUserId) {
    return await callStitchFunction('updateAgentRecord', userId, {
        tempUserId: tempUserId,
    });
}

export async function upsertTempUserUserCredAuth(userId, agentMlsId, mlsName) {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    client.switchUser(userId);
    return await callStitchFunction(
        'updateAgentRecord',
        null,
        { tempUserId: userId },
        { 'markets.agentMlsId': agentMlsId, 'markets.mlsName': mlsName },
    );
}

export async function upsertAgentRecordAsAnon(data, query) {
    return await callStitchFunction('updateAgentRecord', null, data, query);
}

/**
 * Runs a generalQuery stitch function on the listings collection to fetch listing based on passed ID.
 *
 * @param query The query to provide to the find listing.
 * @returns {Promise<Document | null>}
 */
export async function findListing(query = {}) {
    // make a find one
    return getCollection(LISTINGS_COLLECTION).findOne(query);
}

/**
 * Performs a find operation on the showing requests view with the given query,
 * to obtain map related data
 * @param query query object to use while finding the data.
 * @param projection The projection to provide to the find operation, if any.
 * @returns {Promise<Document[]>}
 */
export async function findShowingData(query, projection = {}) {
    const showingRequestsView = getCollection(SHOWING_REQUESTS);
    return showingRequestsView.find(query, projection).toArray();
}

/**
 * Runs a general query stitch function on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the findOne function.
 * @param projection
 * @returns {<MongoDocument> | null>}
 */
export async function runQuery(collectionName, query = {}, projection = null, sort = null) {
    // projections do not work at all
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    return await callStitchFunction('runGeneralQuery', {
        collectionName,
        query,
        projection,
        sort,
        signature: generateSignature(),
    });
}

/**
 * Runs a general query stitch function on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the findOne function.
 * @param projection
 * @returns {Promise<Document | null>}
 */
export async function findRecord(collectionName, query = {}, projection = {}, sort = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);

    return await collection.findOne(query, { projection, sort });
}

export async function findRecords(collectionName, query = {}, projection = {}, sort = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);
    return await collection.find(query, { projection, sort });
}

export async function createShowingFeedback(showingRequestId, feedback) {
    const showingRequestsCollection = getCollection(SHOWING_REQUESTS);
    return showingRequestsCollection.updateOne({ _id: showingRequestId }, { $set: { feedback } });
}

export async function addAgentListing(listingId, data, views) {
    const listings = getCollection(LISTINGS_COLLECTION);
    const query = { _id: listingId };
    const setData = { agentListing: data, views: views };
    const options = { upsert: true };
    return listings.updateOne(query, { $set: setData }, options);
}

export async function removeAgentListing(listingId) {
    const listings = getCollection(LISTINGS_COLLECTION);
    const query = { _id: listingId };
    const unsetData = { agentListing: '' };
    return listings.updateOne(query, { $unset: unsetData });
}

export async function upsertAgentRecord(user, data) {
    const agents = getCollection(AGENTS);
    const query = { $or: [{ stitchUserId: user.id }, { tempUserId: user.id }] };
    const options = { upsert: true };
    return agents.updateOne(query, { $set: data }, options);
}

export async function removeClaimListing(listingId) {
    const listings = getCollection(LISTINGS_COLLECTION);
    const query = { _id: listingId };
    const unsetData = { claimed: '' };
    return listings.updateOne(query, { $unset: unsetData });
}

/**
 * Updates the showing request with the the provided field, and corresponding record.
 * @param client Mongo Stitch Client returned from initialization
 * @param fieldToUpdate object with consumerId, agentId, showingAssistantAgentUserId, or listingId
 * for status, type, and delegationCost
 * @returns {Promise<Document | null>}
 */
export async function updateShowingFields(fieldToUpdate) {
    return callStitchFunction('updateShowingRequestField', fieldToUpdate);
}
/**
 * Search for agent's using a text search function
 *
 * @returns {Promise<T>}
 */
export async function entrySearchForAgents(
    searchText,
    projection = null,
    marketName = null,
    allowHiddenSearch = false,
    searchByAgentId = false,
) {
    return callStitchFunction(
        'textSearchAgents',
        searchText,
        projection,
        null,
        marketName,
        allowHiddenSearch,
        searchByAgentId,
    );
}

/**
 * Search for agent's using a text search function
 *
 * @returns {Promise<T>}
 */
export async function textSearchAgents(searchText, marketName = null, isIdSearch = false) {
    const projection = {
        _id: 1,
        stitchUserId: 1,
        firstName: 1,
        lastName: 1,
        profilePhotoUpload: 1,
        phoneNumber: 1,
        email: 1,
        markets: 1,
        status: 1,
        signUpCompleted: 1,
    };
    return callStitchFunction(
        'textSearchAgents',
        searchText,
        projection,
        null,
        marketName,
        false,
        isIdSearch,
    );
}

/**
 * Updates the showing request with the the provided status.
 * @param client Mongo Stitch Client returned from initialization
 * @param showingRequestData object with id of showing, and updated values
 * for status, type, and delegationCost
 * @returns {Promise<Document | null>}
 */
export async function updateShowingRequestUnverified(showingRequestData, unverifiedData) {
    return callStitchFunction(
        'updateShowingRequestStatusOrType',
        showingRequestData,
        unverifiedData,
    );
}

/**
 * API call to `requestPhoneVerification` in Mongo Stitch to trigger
 * Twilio to send SMS code to this phoneNumber
 * @param {string|number} phoneNumber
 * @param {StitchAppClient} client if provided this will be used,
 *                          otherwise anonymous login will be performed
 *                          and the anonymous login's client will be used
 * @returns {Promise<T>}
 */
export async function requestPhoneVerification(phoneNumber) {
    return callStitchFunction('requestPhoneVerification', phoneNumber);
}

/**
 * API call to `sendEmail` in Mongo Stitch to trigger
 * an AWS SES service to send emails
 * @param {string} email
 * @param {StitchAppClient} client if provided this will be used,
 *                          otherwise anonymous login will be performed
 *                          and the anonymous login's client will be used
 * @returns {Promise<T>}
 */
export async function requestEmailVerification(email) {
    return callStitchFunction('sendEmail', email);
}

/**
 * Creates a new Stitch User via email & password
 * @param {string} email
 * @param {string} password
 * @returns {Promise<boolean>}
 */
export async function requestUserRegistration(email, password) {
    const client = Realm.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return client.emailPasswordAuth.registerUser(email, password);
}

/**
 * Creates a message blast
 * @param {StitchAppClient} client
 * @param {object} messageBlastData
 * @returns {Promise}
 */
export async function createMessageBlast(messageBlastData) {
    return callStitchFunction('createMessageBlast', messageBlastData);
}

export async function addAgentCard(paymentMethodId, name, zip) {
    try {
        const response = await callStitchFunction(
            'setMyDefaultPaymentMethod',
            paymentMethodId,
            name,
            zip,
        );
        return response;
    } catch (error) {}
}
export async function getStripeSubscription(subscriptionId) {
    try {
        const subResponse = await callStitchFunction('getStripeSubscription', subscriptionId);
        return subResponse;
    } catch (error) {
        // console.log(error);
    }
}

/*
 * Creates a stripe subscription with the given price and customer id's, assuming the customer
 * has a set payment method (`source` in this case) with which to make the monthly payments
 * @param client Mongo Stitch Client returned from initialization
 * @param customerId
 * @param priceId
 * @returns {Promise<T>}
 */

export async function createAgentSubscription(payload) {
    try {
        const response = await callStitchFunction('createAgentSubscription', payload);
        return response;
    } catch (error) {
        //console.log(error);
    }
}

/*
 * @param client Mongo Stitch Client returned from initialization
 * @returns {Promise<T>}
 */
export async function createStripeInvoice(client) {
    return callStitchFunction(client, 'createStripeInvoice');
}

/**
 * Fetches brokerage user's payment methods (if any)
 * @param client Mongo Stitch Client returned from initialization
 * @returns {Object}
 */
export async function getAgentUserPaymentMethods() {
    return callStitchFunction('getMyPaymentMethods', 'agents');
}

/**
 * Sends a request to stripe to get the customer invoice history
 * @returns {Array<Object>}
 */
export async function getUserInvoiceHistory(userId) {
    try {
        const invoices = await callStitchFunction('fetchUserInvoiceHistory', userId, 'agents');
        return invoices;
    } catch (error) {
        // console.log(error)
    }
}

export async function getStripePrice(priceId) {
    try {
        const priceResponse = await callStitchFunction('getStripePrice', priceId);
        return priceResponse;
    } catch (error) {
        //console.log(error);
    }
}

export async function removePaymentMethodById(paymentMethodId, userCollection) {
    return callStitchFunction('removePaymentMethodById', paymentMethodId, userCollection);
}

export async function unsetAgentRecord(stitchUserId, setData) {
    const agentsCollection = getCollection(AGENTS);
    const options = { returnNewDocument: true };
    const data = {
        $unset: setData,
    };
    return await agentsCollection.findOneAndUpdate({ stitchUserId: stitchUserId }, data, options);
}

/**
 * API call to update agent record with accepted ToS
 * @param client
 * @param userType USER_TYPE_AGENT
 * @param {array} tosAcceptance contains term objects with url, timestamp, and ip
 * @returns {Promise<*>}
 */
export async function updateTosAcceptance(userType, tosAcceptance, tempUserId) {
    return callStitchFunction('updateTosAcceptance', userType, tosAcceptance, tempUserId);
}

export async function getSubscriptionDefaultPaymentMethod() {
    return callStitchFunction('getSubscriptionPaymentMethod');
}

/**
 * Get user's Bank Account Info if it exists in Stripe Connect
 * @param client Mongo Stitch Client returned from initialization
 * @returns {Promise<Document[]>}
 */
export async function getStripeAccountBankInfo() {
    return callStitchFunction('getStripeAccountBankInfo');
}

/**
 * Request stripe oauth dashboard token
 * @param client Mongo Stitch Client returned from initialization
 * @param stitchUserId Stitch id of the agent requesting the dashboad link
 * @returns {Promise<StripeOauth[]>}
 */
export async function connectWithStripeDashboard(stitchUserId) {
    return callStitchFunction('requestStripeDashboardLink', stitchUserId);
}

/**
 * Initiate stripe express connection flow
 * @param client Mongo Stitch Client returned from initialization.
 * @param agentId Agent Id.
 * @returns {Promise<Document[]>}
 */
export async function connectWithStripeExpress(agentId) {
    return callStitchFunction('requestInitiateStripeConnect', agentId);
}

export async function updateSubscriptionDefaultPaymentMethod(paymentMethodId) {
    return callStitchFunction('updateSubscriptionPaymentMethod', paymentMethodId);
}

// fetches all showings in the current week for a given listingId
export async function getListingShowingTimes(listingId) {
    const today = DateTime.fromMillis(Date.now()).startOf('day').toJSDate();
    const endOfWeek = DateTime.fromMillis(Date.now()).plus({ week: 1 }).toJSDate();
    const query = {
        listingId,
        start: {
            $gt: today,
            $lt: endOfWeek,
        },
    };

    const response = await callStitchFunction('runGeneralQuery', {
        collectionName: SHOWING_REQUESTS,
        query,
        signature: generateSignature(),
    });

    return response;
}
