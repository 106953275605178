export const radioButtonData = [
    { label: 'Licensed Agent', value: 'Licensed Agent' },
    { label: 'Inspector', value: 'Inspector' },
    { label: 'Appraiser', value: 'Appraiser' },
    { label: 'Unrepresented Homebuyer', value: 'Unrepresented Homebuyer' },
    { label: '', value: 'Other' },
];

export const checkValidPhone = (phone: string) => {
    const reg = new RegExp(/^\d+$/);
    return String(phone).match(reg) && phone.length >= 9 && phone.length < 13;
};

export const checkValidEmail = (email: string) => {
    const reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    return String(email).toLowerCase().match(reg);
};

/**
 * Given the current approvals and approval settings, return the
 * associated text to be displayed
 *
 * @param {listingApprovalType} matches {approvalType} on the listing.agentListing.approvalSettings
 * @param {approvals} matches {approvals} on the showingRequest
 * @param {status} matches {status} on the showingRequest
 * @returns {string | null}
 */
export const getApprovalsText = (listingApprovalType: any, approvals: any, status: any) => {
    switch (status) {
        case 'confirmed':
            return 'This showing has been approved.';
        case 'cancelled':
            return 'This showing has been cancelled.';
        case 'denied':
            return 'This showing has been denied.';
        case 'pending_internal':
            if (listingApprovalType === 'both' && approvals && approvals.includes('client')) {
                return 'You have approved this showing. Waiting for the listing agent to approve.';
            } else if (listingApprovalType === 'agent') {
                return 'Waiting for the listing agent to approve.';
            }
            return null;
        default:
            return null;
    }
};
