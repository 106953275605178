import React, { useEffect, useState } from 'react';

import { FeedItem, FeedItemContentType } from '../../../../../../utils/constants';
import XIcon from '../../../../../../images/closeButton6A.svg';
import CheckMark from '../../../../../../images/fancyCheckmark.svg';
import { Button, Feed as FeedContainer, InputBar, Loader } from 'web-lib';
import { ImageUploader } from '../../../../../../components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import styles from './index.module.css';

const CreateNewPost = ({
    setCreateContentData,
    createContentData,
    newPostLoading,
    errors,
    setCreatePost,
    profilePhoto,
    closeModal,
    modalOpen,
    postCreated,
    resetContentCreated,
}: {
    setCreateContentData: Function;
    createContentData: FeedItem;
    newPostLoading: boolean;
    errors: Array<any>;
    setCreatePost: Function;
    profilePhoto: string;
    closeModal: Function;
    modalOpen: boolean;
    postCreated: boolean;
    resetContentCreated: Function;
}) => {
    //The reason we have a tempContentData is because when the original contentData is changed,
    //it re-renders the entire feed. This significantly slowed down the typing responsivness in
    //while making a new post. Therefore, we update tempContentData and update the original
    //contentData when
    const [tempContentData, setTempContentData] = useState<FeedItem>(createContentData);

    const canMakePost = () => tempContentData.description.length;
    return (
        <div className={styles.createAPostContainer}>
            {!newPostLoading && !postCreated && (
                <>
                    <div className={styles.header}>
                        <div className={styles.titleRow}>
                            <div>Make a Post</div>
                            <img
                                onClick={() => {
                                    closeModal(!modalOpen);
                                    resetContentCreated();
                                }}
                                alt={'x'}
                                className={styles.xIcon}
                                src={XIcon}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.imgDiv}>
                            <img
                                alt={'profilePhoto'}
                                className={styles.profilePhoto}
                                src={profilePhoto}
                            />
                        </div>
                        <div>
                            <InputBar
                                charLimit={1000}
                                onCharInput={(text: string) =>
                                    setTempContentData({ ...tempContentData, description: text })
                                }
                                placeHolder={'What’s happening?'}
                                styling={styles.inputStylingArea}
                                title={''}
                                type={'Area'}
                            />
                            <div className={styles.line}></div>
                            <InputBar
                                charLimit={128}
                                onCharInput={(text: string) =>
                                    setTempContentData({ ...tempContentData, link: text })
                                }
                                placeHolder={'Add a Link (Optional)'}
                                styling={styles.inputStyling}
                                title={''}
                                type={'Input'}
                            />
                            <div className={styles.line}></div>
                            <div className={styles.bottomRow}>
                                <div className={styles.uploadImage}>
                                    <ImageUploader
                                        handleOnImageUpload={(image: string) =>
                                            setTempContentData({ ...tempContentData, image })
                                        }
                                    />
                                </div>
                                <div className={styles.rightSide}>
                                    <div className={styles.progressBarDiv}>
                                        <CircularProgressbar
                                            className={styles.progressBar}
                                            value={
                                                (tempContentData.description.length / 1000) * 100
                                            }
                                            strokeWidth={15}
                                            styles={buildStyles({
                                                pathColor: '#48a4ff',
                                                trailColor: '#E9E9E9',
                                            })}
                                        />
                                    </div>
                                    <Button
                                        buttonText={'Post'}
                                        className={
                                            canMakePost()
                                                ? styles.postButton
                                                : styles.disabledPostButton
                                        }
                                        disabled={!canMakePost()}
                                        onClick={() => {
                                            setCreateContentData(tempContentData);
                                            setCreatePost(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {newPostLoading && (
                <div className={styles.createPostLoaderContainer}>
                    <Loader />
                </div>
            )}
            {postCreated && (
                <div>
                    <div className={styles.close}>
                        <img
                            onClick={() => {
                                closeModal(!modalOpen);
                                resetContentCreated();
                            }}
                            alt={'x'}
                            className={styles.xIcon}
                            src={XIcon}
                        />
                    </div>
                    <div className={styles.createPostLoaderContainer}>
                        <img alt={'success'} className={styles.checkMark} src={CheckMark} />
                    </div>
                    <div className={styles.postedMessage}>Posted</div>
                </div>
            )}
            {Boolean(errors.length) && <span className={styles.errorText}>{errors[0]}</span>}
        </div>
    );
};

export default CreateNewPost;
