export const transformMarketNameForState = (marketName: string) => {
    switch (marketName) {
        case 'Colorado (REColorado)':
            return 'Colorado';
        case 'Utah':
            return 'Utah';
        case 'Alaska':
            return 'Alaska';
        case 'Hawaii (HIS)':
            return 'Hawaii';
        case 'Massachusetts (PIN)':
            return 'Massachusetts';
        case 'Tennessee (RealTracs)':
            return 'Tennessee';
        case 'South Carolina (CMLS)':
            return 'South Carolina';
        case 'Florida':
            return 'Florida';
        default:
            return marketName;
    }
};

export const transformMlsName = (mlsName: string) => {
    switch (mlsName) {
        case 'recolorado':
            return 'REColorado';
        case 'sef':
            return 'Miami Realtors';
        case 'ires':
            return 'Ires';
        default:
            return mlsName;
    }
};

export const removeEmailAlias = (email: string) => {
    return email.replace(/(\+.*)(?=\@)/, '');
};

export const phoneDisplay = (phone: string) => {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};
