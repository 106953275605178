export default (theme: any) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 65,
            ...theme.paddingHorizontal(15),
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.grey,
            borderStyle: 'solid',
            backgroundColor: theme.palette.white,
        },
        leftSide: {
            fontSize: 18,
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: theme.palette.mediumGrey,
            width: '100%',
        },
        rightSide: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            width: '100%',
        },
        searchBarContainer: {
            marginLeft: 'auto',
        },
        searchBar: {
            backgroundColor: theme.palette.background,
            height: 30,
            minWidth: 475,
            color: theme.palette.mediumGrey,
            fontSize: 18,
            textAlign: 'center',
            borderRadius: 5,
            borderWidth: 0,
        },
        button: {
            height: '30px !important',
            fontSize: '16px !important',
            marginLeft: '10px !important',
            fontWeight: '600 !important',
            minWidth: '170px !important',
            width: '40% !important',
        },
    };
};
