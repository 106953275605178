import { STATUS } from '../../../store/api/constants';
import { ShowingDetailsActions } from './actions';

export const initialState = {
    showing: null,
    errors: null,
    loading: false,
    initiallyViewingListingDetails: false,
    loadingShowingStatus: false,
};

export default function (state = initialState, action: any) {
    const { type } = action;

    switch (type) {
        case ShowingDetailsActions.saveShowing: {
            const { showing, initiallyViewingListingDetails } = action;
            return {
                ...state,
                showing,
                loading: false,
                initiallyViewingListingDetails,
            };
        }
        case ShowingDetailsActions.fetch: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { showing } = action;
                    return {
                        ...state,
                        showing,
                        loading: false,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors: errors,
                        loading: false,
                    };
            }
        }
        case ShowingDetailsActions.accept: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingShowingStatus: true,
                    };
                case STATUS.Succeeded:
                    const { showing } = action;
                    return {
                        ...state,
                        loadingShowingStatus: false,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors: errors,
                        loadingShowingStatus: false,
                    };
            }
        }
        case ShowingDetailsActions.decline: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingShowingStatus: true,
                    };
                case STATUS.Succeeded:
                    const { showing } = action;
                    return {
                        ...state,
                        loadingShowingStatus: false,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors: errors,
                        loadingShowingStatus: false,
                    };
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
