import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    callStitchFunction,
    findShowingData,
    createShowingFeedback,
    findShowingRequest,
} from '../../../store/api/sagas';
import {
    fetchMyShowingsFailed,
    fetchMyShowingsRequested,
    fetchMyShowingsSucceeded,
    searchMyShowingsFailed,
    searchMyShowingsSucceeded,
    setLoadingMyShowings,
    findAllShowingData,
    findAllShowingDataSuccess,
    findAllShowingDataFailure,
    updateShowingStatusRequested,
    updateShowingStatusSucceeded,
    updateShowingStatusFailed,
    submitFeedbackSucceeded,
    submitFeedbackFailed,
    setFeedbackDisabled,
    rescheduleShowingRequested,
    rescheduleShowingSucceeded,
    rescheduleShowingFailed,
    updatingShowingRequested,
    updatingShowingSucceeded,
} from './actions';
import { getAttemptedMyShowingsFetches } from './selectors';
// import { getThisWeeksShowings } from '../Calendar/selectors';
import { BSON } from 'realm-web';
import {
    MY_SHOWINGS_ACTION,
    STATUS,
    FIND_ALL_SHOWING_DATA_ACTION,
    MY_SHOWINGS_FILTER,
} from './types';
import { SHOWING_ACTION } from '../../../utils/constants';
import { isEmpty } from 'lodash';
import { fetchShowingRequestsRequested } from '../MyListings/actions';
import { getProfileData } from '../Profile/selectors';
import { setSelectedClient } from '../SearchListings/actions';
import { getAllShowings } from './selectors';
// import { fetchCalendarShowingsSucceeded, leftFeedbackOnShowing } from '../Calendar/actions';

/**
 * Attempt to parse the error for its string message.
 * If that fails, fall back to error name or as a last resort, show generic error message.
 * @param stitchError Instance of error object `StitchServiceError` caught from API
 * @returns {string}
 */
export function parseStitchServiceError(stitchError: any) {
    try {
        const parsedError = JSON.parse(stitchError.message);
        return parsedError.message;
    } catch {
        if (stitchError?.message && typeof stitchError.message === 'string') {
            return stitchError.message;
        } else {
            return stitchError?.name || 'Something Went Wrong.';
        }
    }
}

export function* fetchMyShowings({
    filter,
    forceRefresh,
}: ReturnType<typeof fetchMyShowingsRequested>): any {
    // If we've already attempted to fetch for this filter, don't try again unless
    // we're purposefully refreshing.
    const attemptedFetches = yield select(getAttemptedMyShowingsFetches);
    if (!attemptedFetches[filter] || forceRefresh) {
        try {
            const myShowings = yield call(callStitchFunction, 'fetchMyShowings');

            if (myShowings) {
                yield put(fetchMyShowingsSucceeded(myShowings, filter));
            } else {
                const message = 'No matching showings were found.';
                yield put(fetchMyShowingsFailed(message, filter));
            }
        } catch (error) {
            const message = parseStitchServiceError(error);
            yield put(fetchMyShowingsFailed(message, filter));
        }
    } else {
        yield put(setLoadingMyShowings(false));
    }
}

export function* searchMyShowings({
    searchText,
}: {
    type: typeof MY_SHOWINGS_ACTION.Search;
    searchText: string;
}): Generator<any, any, any> {
    try {
        const myShowings =
            searchText && searchText.length > 0
                ? yield call(callStitchFunction, 'textSearchMyShowings', searchText)
                : [];
        if (myShowings) {
            yield put(searchMyShowingsSucceeded(myShowings));
        } else {
            const message = 'No matching showings were found.';
            yield put(searchMyShowingsFailed([message]));
            // yield call(alert, {
            //     type: 'error',
            //     message: message,
            // });
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(searchMyShowingsFailed([message]));
        // yield call(alert, {
        //     type: 'error',
        //     message: message,
        // });
    }
}

export function* getAllShowingData({ showingId }: any): Generator<any, any, any> {
    const query = { _id: showingId };

    const data = yield call(findShowingData, query);

    // if we find a showing with the given showingData._id
    // if we do not find a showing with the given showingData._id
    try {
        if (data.length) {
            yield put(findAllShowingDataSuccess(data[0]));
        } else {
            yield put(findAllShowingDataFailure(`No Showing found for ShowingId ${showingId}`));
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(findAllShowingDataFailure(message));
    }
}

const getUserPhoto = (photoPath: string) => callStitchFunction('getPhoto', photoPath);

export function* updateShowingStatus({
    showingId,
    newShowingStatus,
    stitchUserId,
    afterUpdate,
}: ReturnType<typeof updateShowingStatusRequested>): Generator<any, any, any> {
    try {
        const agentData = yield select(getProfileData);
        const { _id: agentObjectId } = agentData;
        yield put(updatingShowingRequested(showingId));

        const { modifiedCount } = yield call(
            callStitchFunction,
            'updateShowingRequestStatusOrType',
            {
                id: showingId,
                status: newShowingStatus,
            },
        );
        if (modifiedCount) {
            // TODO - Modify stitch function to return showing instead of the showingId
            const showing = yield call(findShowingRequest, {
                _id: new BSON.ObjectId(showingId),
            });

            if (showing?.agent?.profilePhotoUpload?.path)
                showing.agent.photo = yield call(
                    getUserPhoto,
                    showing.agent.profilePhotoUpload.path,
                );
            if (showing?.consumer?.profilePhotoUpload?.path)
                showing.consumer.photo = yield call(
                    getUserPhoto,
                    showing.consumer.profilePhotoUpload.path,
                );
            if (showing?.showingAssistant?.profilePhotoUpload?.path)
                showing.showingAssistant.photo = yield call(
                    getUserPhoto,
                    showing.showingAssistant.profilePhotoUpload.path,
                );
            yield put(updateShowingStatusSucceeded(showing));
            yield put(updatingShowingSucceeded(showingId));
            yield put(findAllShowingDataSuccess(showing)); // refetch showing data on user interaction

            if (afterUpdate) {
                afterUpdate();
            }
        } else yield put(updateShowingStatusFailed(showingId, 'Showing not updated.'));
        yield put(fetchShowingRequestsRequested(agentObjectId));
    } catch (error) {
        const message = parseStitchServiceError(error);
        // console.log(message)
        yield put(updateShowingStatusFailed(showingId, error.message));
    }
}

function* disableFeedbackSuccessState(): Generator<any, any, any> {
    yield put(setFeedbackDisabled());
}

function* postShowingFeedback({
    priceAccuracyRating,
    locationRating,
    interestLevel,
    reviewText,
    showingId,
}: any): Generator<any, any, any> {
    try {
        // const thisWeeksShowings = yield select(getThisWeeksShowings);
        const thisWeeksShowings: any = [];
        const allShowings = yield select(getAllShowings);
        const allNeedsAction = allShowings['Needs Action'];
        const allNone = allShowings['None'];
        // const needsActionsShowings = yield select(getNeedsActionsListing);
        if (isEmpty(reviewText) || reviewText.length < 10) {
            reviewText = 'No feedback submitted';
        }
        const feedbackToSubmit = {
            ratings: {
                priceAccuracy: priceAccuracyRating,
                location: locationRating,
                interestLevel: interestLevel,
            },
            review: reviewText,
        };

        /**
         * For some reason the useEffects in ShowingCardImproved component do not trigger on each re-render
         * Instead they trigger once when the grouped cards are selected
         * Therefore, in order for feedback to be reflected, state has to be updated when feedback is left, or else the feedback will not be reflected
         * Alternatively you could has the useEffect track feedback, so it does run when feedback it left and update accordingly
         * This, however fails, because the feedback reflects the same on all cards and useEffect triggers once for all cards, not on each card
         */
        let updatedShowing: any;
        const updatedShowings: any[] = thisWeeksShowings.map((weekDay: any) => {
            const paginationId = weekDay.paginationId;
            const id = weekDay._id;
            const dayRequests = weekDay.showingRequests.map((showingGroup: any) => {
                if (showingGroup.length > 0) {
                    const group = showingGroup.map((showing: any) => {
                        if (showingId.toString() === showing._id.toString()) {
                            const newShowingObj = {
                                ...showing,
                                feedback: feedbackToSubmit,
                            };
                            updatedShowing = newShowingObj;
                            return newShowingObj;
                        }
                        return showing;
                    });
                    return group;
                }
                // Else condition to handle situations where a showing does not belong to a group
                else {
                    if (showingId.toString() === showingGroup._id.toString()) {
                        const newShowingObj = {
                            ...showingGroup,
                            feedback: feedbackToSubmit,
                        };
                        updatedShowing = newShowingObj;
                        return newShowingObj;
                    }
                    return showingGroup;
                }
            });
            return {
                _id: id,
                paginationId: paginationId,
                showingRequests: dayRequests,
            };
        });

        let updatedShowingAddress: string;
        const updatedAllNeedsAction = allNeedsAction.map((listing: any, index: number) => {
            const showingPreviews = listing.showingPreviews.filter((showing: any) => {
                if (showing._id.toString() !== showingId.toString()) {
                    return showing;
                } else {
                    // In case feedback was added from the myShowings page instead of the calendar page
                    updatedShowing = updatedShowing
                        ? updatedShowing
                        : { ...showing, feedback: feedbackToSubmit };
                    updatedShowingAddress = listing.address;
                }
            });
            return {
                ...listing,
                showingPreviews: showingPreviews,
            };
        });

        const updatedNone = allNone.map((listing: any) => {
            if (listing.address === updatedShowingAddress) {
                // Need to use .concat, because .push is always returning the length of the array
                const updatedPreviews = listing.showingPreviews
                    .filter(
                        (showing: any) => showing._id.toString() !== updatedShowing._id.toString(),
                    )
                    .concat([updatedShowing]);
                return {
                    ...listing,
                    showingPreviews: updatedPreviews,
                };
            }
            return listing;
        });

        const updatedMyShowings = {
            ...allShowings,
            [MY_SHOWINGS_FILTER.NeedsAction]: updatedAllNeedsAction,
            [MY_SHOWINGS_FILTER.None]: updatedNone,
        };

        // yield put(fetchCalendarShowingsSucceeded(updatedShowings));
        // yield put(leftFeedbackOnShowing(updatedShowing));
        yield call(createShowingFeedback, showingId, feedbackToSubmit);
        yield put(fetchMyShowingsSucceeded(updatedMyShowings, MY_SHOWINGS_FILTER.None));
        yield put(submitFeedbackSucceeded());
    } catch (error) {
        const errorMsg = parseStitchServiceError(error);
        yield put(submitFeedbackFailed(errorMsg));
    }
}

function* rescheduleShowing({
    showingType,
    showingId,
    startTime,
    endTime,
    stitchUserId,
    utcOffset,
    calendarUpdate,
}: any): Generator<any, any, any> {
    try {
        yield put(updatingShowingRequested(showingId));
        const showing = yield call(
            callStitchFunction,
            'rescheduleShowing',
            showingType,
            showingId,
            startTime,
            endTime,
            stitchUserId,
            utcOffset,
        );
        if (showing) {
            yield put(rescheduleShowingSucceeded(showing));
            yield put(setSelectedClient(null));
            // if(calendarUpdate){
            // yield put(updateCalendarShowings(showing))
            // }
            // else{
            yield put(findAllShowingDataSuccess(showing));
            // }
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        // console.log('error',message)
        yield put(rescheduleShowingFailed(showingId, message));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === MY_SHOWINGS_ACTION.Fetch && action.status === STATUS.Requested,
            fetchMyShowings,
        ),
        takeLatest(
            (action: any) =>
                action.type === MY_SHOWINGS_ACTION.Search && action.status === STATUS.Requested,
            searchMyShowings,
        ),
        takeLatest(FIND_ALL_SHOWING_DATA_ACTION.GetAll, getAllShowingData),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_ACTION.UpdateStatus && action.status === STATUS.Requested,
            updateShowingStatus,
        ),
        takeLatest(
            (action: any) =>
                action.type === MY_SHOWINGS_ACTION.SubmitFeedback &&
                action.status === STATUS.Requested,
            postShowingFeedback,
        ),
        takeLatest(
            (action: any) =>
                action.type === MY_SHOWINGS_ACTION.SetFeedbackDisabled &&
                action.status === STATUS.Requested,
            disableFeedbackSuccessState,
        ),
        takeLatest(
            (action: any) =>
                action.type === MY_SHOWINGS_ACTION.RescheduleShowing &&
                action.status === STATUS.Requested,
            rescheduleShowing,
        ),
    ]);
}
