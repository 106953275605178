import { STATUS } from '../../../utils/constants';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { BSON } from 'realm-web';

import {
    BROKERAGE_HUB_ACTIONS,
    fetchBrokerageListingsRequested,
    fetchBrokerageListingsSucceeded,
    fetchBrokerageListingsFailed,
    getBrokerageFeedSucceeded,
    getBrokerageFeedFailed,
    createContentFailed,
    createContentSucceeded,
    updateBrokerageFeedRequested,
    getBrokerageDataRequested,
    resetContentCreated,
    appendBrokerageListingsRequested,
    fetchBrokerageAgentsRequested,
    fetchBrokerageAgentsSucceeded,
    fetchBrokerageAgentsFailed,
    appendBrokerageAgentsRequested,
    updateFeedItemRequested,
    updateFeedItemSucceeded,
    updateFeedItemFailed,
} from './actions';
import { getBrokerageListings, getBrokerageAgents, getSelectedBrokerage } from './selectors';
import { getAgentObject } from '../../auth/selectors';

// import { getPaginatedBrokerageListings, getPaginatedBrokerageAgents } from '../../../store/api/sagas';
import { callStitchFunction } from '../../../store/api/sagas';
import { defaultContentData } from './BrokerageHub/BrokerageFeed';
import { imageToBase64, parseStitchServiceError } from '../../../utils/common';

export function* createNewContent({
    brokerageId,
    contentData,
    setContentData,
}: any): Generator<any, any, any> {
    let BrokerageID;
    const selectedBrokerage = yield select(getSelectedBrokerage);
    if (typeof brokerageId === 'string') {
        BrokerageID = selectedBrokerage?._id;
    } else {
        BrokerageID = brokerageId;
    }
    let newPhotoData;

    //image upload
    if (contentData.image) {
        let base64Image;
        try {
            base64Image = yield imageToBase64(contentData.image);
        } catch (err) {
            yield put(createContentFailed(['Error uploading image file']));
        }

        try {
            newPhotoData = yield call(
                callStitchFunction,
                'storePhoto',
                'brokerageUser',
                base64Image.split(',')[1], // removes uneeded data type that is created in front of the base64 string
                `brokerageEvent_${contentData.title}_${contentData.start.toString()}`,
                contentData.image.type,
            );
        } catch (err) {
            return yield put(createContentFailed(['Error uploading file']));
        }
    }
    try {
        // determine if a url has http/https attached to it
        // if not, add it. This ensures all links work properly
        const pattern = /^((http|https):\/\/)/;
        const getContentLink = (link: string) => {
            if (!link) {
                return null;
            } else {
                return !pattern.test(link) ? 'http://' + link : link;
            }
        };

        let newContentData = {
            ...contentData,
            image: newPhotoData
                ? `https://brokerage-content-photos.s3.amazonaws.com/${newPhotoData?.key}`
                : '',
            link: getContentLink(contentData.link),
        };

        const response = yield call(callStitchFunction, 'addBrokerageContent', {
            brokerageId: BrokerageID,
            contentData: { ...newContentData },
        });
        setContentData(defaultContentData);

        if (response) {
            newContentData = {
                ...newContentData,
                brokerageId: brokerageId,
                _id: response.insertedId,
            };
            yield put(createContentSucceeded());
            yield put(getBrokerageDataRequested(brokerageId));
            yield put(updateBrokerageFeedRequested(newContentData));
        } else {
            yield put(resetContentCreated());
            yield put(createContentFailed(['Error creating new content']));
        }
    } catch (err) {
        yield put(resetContentCreated());
        yield put(createContentFailed(['Error creating new content']));
    }
}

export function* getBrokerageFeed({ brokerageId }: any): Generator<any, any, any> {
    const agent = yield select(getAgentObject);
    try {
        const brokerageFeed = yield call(
            callStitchFunction,
            'runAggregationBrokerageEventFeed',
            'agentWeb',
            {
                userObjectId: agent?._id,
                brokerageObjectId: brokerageId,
            },
        );
        yield put(getBrokerageFeedSucceeded(brokerageFeed));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(getBrokerageFeedFailed([message]));
    }
}

function* fetchBrokerageListings({
    markets,
    skip,
}: ReturnType<typeof fetchBrokerageListingsRequested>): Generator<any, any, any> {
    try {
        const limit = 50;
        const currentBrokerageListings = yield select(getBrokerageListings);
        const args = [markets, skip, limit];

        const paginatedListings = yield call(
            callStitchFunction,
            'validateSystemUser',
            'getPaginatedBrokerageListings',
            args,
        );

        if (paginatedListings.length > 0) {
            if (currentBrokerageListings.length > 0) {
                yield put(appendBrokerageListingsRequested(paginatedListings));
            } else {
                yield put(fetchBrokerageListingsSucceeded(paginatedListings));
            }
        } else if (paginatedListings.length === 0) {
            yield put(
                fetchBrokerageListingsFailed([
                    'No listings could be found for this brokerage. Error Code: 0154-1.',
                ]),
            );
        }
    } catch (error) {
        yield put(
            fetchBrokerageListingsFailed([
                'There was an issue when attempting to fetch the brokerage listings. Error Code: 0154-2.',
            ]),
        );
    }
}

function* fetchBrokerageAgents({
    brokerageId,
    skip,
    filter,
}: ReturnType<typeof fetchBrokerageAgentsRequested>): Generator<any, any, any> {
    try {
        const limit = 50;
        const brokerageObjectId = new BSON.ObjectId(brokerageId);

        const currentBrokerageAgents = yield select(getBrokerageAgents);
        const args = [brokerageObjectId, skip, limit, filter];

        const paginatedAgents = yield callStitchFunction(
            'validateSystemUser',
            'getPaginatedBrokerageAgents',
            args,
        );

        if (paginatedAgents.length > 0) {
            if (currentBrokerageAgents.length > 0) {
                yield put(appendBrokerageAgentsRequested(paginatedAgents));
            } else {
                yield put(fetchBrokerageAgentsSucceeded(paginatedAgents));
            }
        } else if (paginatedAgents.length === 0) {
            yield put(
                fetchBrokerageAgentsFailed([
                    'No agents could be found for this brokerage. Error Code: 0158-1',
                ]),
            );
        }
    } catch (error) {
        yield put(
            fetchBrokerageAgentsFailed([
                'There was an issue when attempting to fetch the brokerage agents. Error Code: 0158-2',
            ]),
        );
    }
}

export function* updateFeedItem({
    feedData,
}: ReturnType<typeof updateFeedItemRequested>): Generator<any, any, any> {
    const { eventId, userObjectId, type, comment } = feedData;
    try {
        const response = yield call(
            callStitchFunction,
            'interactWithBrokerageFeedEvent',
            eventId,
            userObjectId,
            type,
            comment,
        );

        if (response) {
            yield put(updateFeedItemSucceeded(response, type));
        } else {
            yield put(
                updateFeedItemFailed(
                    ['There was an issue updating this feed item. Code: 0161-01.'],
                    feedData,
                ),
            );
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(updateFeedItemFailed([message, 'Code: 0161-02.'], feedData));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === BROKERAGE_HUB_ACTIONS.Create && action.status === STATUS.Requested,
            createNewContent,
        ),
        takeLatest(
            (action: any) =>
                action.type === BROKERAGE_HUB_ACTIONS.FetchBrokerageListings &&
                action.status === STATUS.Requested,
            fetchBrokerageListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === BROKERAGE_HUB_ACTIONS.Feed && action.status === STATUS.Requested,
            getBrokerageFeed,
        ),
        takeLatest(
            (action: any) =>
                action.type === BROKERAGE_HUB_ACTIONS.FetchBrokerageAgents &&
                action.status === STATUS.Requested,
            fetchBrokerageAgents,
        ),
        takeLatest(
            (action: any) =>
                action.type === BROKERAGE_HUB_ACTIONS.UpdateFeedItem &&
                action.status === STATUS.Requested,
            updateFeedItem,
        ),
    ]);
}
