import { BSON } from 'realm-web';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { SHOWING_REQUESTS } from '../../../store/api/constants';
import { callStitchFunction, findShowingRequest } from '../../../store/api/sagas';
import { generateSignature, parseStitchServiceError } from '../../../utils/common';
import { ShowingType, STATUS } from '../../../utils/constants';
import { getProfileData } from '../Profile/selectors';
import { fetchShowingDetailsSucceeded, saveShowingInRedux } from '../ShowingDetailsScreen/actions';
import * as Actions from './actions';

const getUserPhoto: any = (photoPath: string) => callStitchFunction('getPhoto', photoPath);

// #region createShowing
/**
 * Create a showing at a given time for an agent. Attach
 * client(s) if specified
 *
 * @param {showingType} Type of showing created: 'agent'
 * @param {listingId} Listings collection document _id
 * @param {startTime}
 * @param {endTime}
 * @param {clientId} stitchUserId of the client to be added to the showing
 * @param {delegationPayment}
 */
function* createShowing({
    showingType,
    listingId,
    startTime,
    endTime,
    clientId,
    delegationPayment,
}: ReturnType<typeof Actions.createShowingRequested>): Generator<any, any, any> {
    try {
        const utcOffset = Math.round(new Date().getTimezoneOffset() / 60);
        const showing = yield call(
            callStitchFunction,
            'createShowing',
            showingType,
            listingId,
            startTime,
            endTime,
            clientId,
            utcOffset,
            delegationPayment,
        );
        yield put(saveShowingInRedux(null)); // used for navigation to showing details - send null because there is a bug with te start date 1 hour offset
        yield put(Actions.createShowingSucceeded(showing, listingId, showingType));
        yield put(Actions.setSelectedClient(null));
    } catch (error: any) {
        const message = parseStitchServiceError(error);
        yield call(alert, {
            type: 'error',
            message,
        });
        yield put(Actions.createShowingFailed(listingId, error.message));
    }
}

// #endregion createShowing

// #region rescheduleShowing
// TODO
/**
 * Reschedule a currently scheduled showing
 *
 * @param {showingType}
 * @param {showingId} _id of the document in showingRequests collection
 * @param {startTime}
 * @param {endTime}
 * @param {clientId} stitchUserId of client to be added
 * @param {previousShowingType}
 * @param {delegationPrice}
 */
function* rescheduleShowing({
    showingType,
    showingId,
    startTime,
    endTime,
    clientId,
    previousShowingType,
    delegationPrice,
}: ReturnType<typeof Actions.rescheduleShowingRequested>): Generator<any, any, any> {
    try {
        const utcOffset = Math.round(new Date().getTimezoneOffset() / 60);
        var showing = null;
        // if the type of the showing is NOT being CHANGED to delegated
        if (
            previousShowingType === ShowingType.Delegated ||
            showingType !== ShowingType.Delegated
        ) {
            showing = yield call(
                callStitchFunction,
                'rescheduleShowing',
                showingType,
                showingId,
                startTime,
                endTime,
                clientId,
                utcOffset,
                delegationPrice || null,
            );
        } else {
            const { upsertedId, modifiedCount } = yield call(
                callStitchFunction,
                'updateShowingRequestStatusOrType',
                {
                    id: showingId,
                    type: 'delegated',
                    start: startTime,
                    end: endTime,
                    delegationCost: delegationPrice,
                },
            );
            if (upsertedId || modifiedCount) {
                const updatedShowing = yield call(findShowingRequest, {
                    _id: new BSON.ObjectId(showingId),
                });
                if (updatedShowing?.agent?.profilePhotoUpload?.path)
                    updatedShowing.agent.photo = yield call(
                        getUserPhoto,
                        updatedShowing.agent.profilePhotoUpload.path,
                    );
                if (updatedShowing?.consumer?.profilePhotoUpload?.path)
                    updatedShowing.consumer.photo = yield call(
                        getUserPhoto,
                        updatedShowing.consumer.profilePhotoUpload.path,
                    );
                if (updatedShowing?.showingAssistant?.profilePhotoUpload?.path)
                    updatedShowing.showingAssistant.photo = yield call(
                        getUserPhoto,
                        updatedShowing.showingAssistant.profilePhotoUpload.path,
                    );
                showing = updatedShowing;
            } else {
                const mes = 'There was an error in updating this showing.';
                yield call(alert, {
                    type: 'error',
                    message: mes,
                });
                yield put(Actions.rescheduleShowingFailed(showingId, mes));
            }
        }
        yield put(fetchShowingDetailsSucceeded(showing)); // update showing displayed on showing details screen
        yield put(
            Actions.rescheduleShowingSucceeded(showing, showingId, showing?.listingId, showingType),
        );
        yield put(Actions.setSelectedClient(null));
    } catch (error: any) {
        const message = parseStitchServiceError(error);
        yield call(alert, {
            type: 'error',
            message,
        });
        yield put(Actions.rescheduleShowingFailed(showingId, error.message));
    }
}

// #endregion rescheduleShowing

// #region updateShowingStatus
// TODO
/**
 *
 * @param {showingId} document _id of the showingRequest
 * @param {newShowingStatus} status to update the showing to
 * @param {callback} optional function to be called if the update succeeds
 */
function* updateShowingStatus({
    showingId,
    newShowingStatus,
    callback,
}: ReturnType<typeof Actions.updateShowingStatusRequested>): Generator<any, any, any> {
    try {
        const { modifiedCount } = yield call(
            callStitchFunction,
            'updateShowingRequestStatusOrType',
            {
                id: showingId,
                status: newShowingStatus,
            },
        );
        if (modifiedCount) {
            // TODO - Modify stitch function to return showing instead of the showingId
            const showing: any = yield call(findShowingRequest, {
                _id: new BSON.ObjectId(showingId),
            });

            if (showing?.agent?.profilePhotoUpload?.path)
                showing.agent.photo = yield call(
                    getUserPhoto,
                    showing.agent.profilePhotoUpload.path,
                );
            if (showing?.consumer?.profilePhotoUpload?.path)
                showing.consumer.photo = yield call(
                    getUserPhoto,
                    showing.consumer.profilePhotoUpload.path,
                );
            if (showing?.showingAssistant?.profilePhotoUpload?.path)
                showing.showingAssistant.photo = yield call(
                    getUserPhoto,
                    showing.showingAssistant.profilePhotoUpload.path,
                );
            yield put(fetchShowingDetailsSucceeded(showing)); // update showing displayed on showing details screen
            yield put(Actions.updateShowingStatusSucceeded(showing));
            if (callback) {
                yield call(callback);
            }
        } else yield put(Actions.updateShowingStatusFailed(showingId, 'Showing not updated.'));
    } catch (error: any) {
        const message = parseStitchServiceError(error);
        yield call(alert, {
            type: 'error',
            message,
        });
        yield put(Actions.updateShowingStatusFailed(showingId, error.message));
    }
}
// #endregion updateShowingStatus

/**
 * This function will get upcoming showings for a given listing
 */
export function* fetchShowingAvailability({
    listingId,
    showingId,
}: ReturnType<typeof Actions.fetchShowingAvailabilityRequested>): Generator<any, any, any> {
    try {
        const firstDate: any = new Date();
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const showings = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: {
                listingId,
                _id: {
                    $ne: showingId,
                },
                /**
                 * only if allowOverlap is not true on a connected listing
                 */
                'listing.agentListing.listingId': { $exists: true },
                'listing.agentListing.allowOverlap': { $ne: true },

                start: {
                    $gte: firstDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            projection: {
                start: 1,
                end: 1,
                listing: 1,
            },
            signature: generateSignature(),
        });

        yield put(Actions.fetchShowingAvailabilitySucceeded(showings));
    } catch (error: any) {
        yield put(Actions.fetchShowingAvailabilityFailed(error));
        yield call(alert, {
            type: 'error',
            message:
                'There was an issue in retrieving information on this property. Please try again later. Code: 0101',
        });
    }
}

/**
 * This function will get the agents showing schedule
 */
export function* fetchAgentShowingSchedule({}: ReturnType<
    typeof Actions.fetchShowingAvailabilityRequested
>): Generator<any, any, any> {
    try {
        const agentData = yield select(getProfileData);
        const firstDate: any = new Date();
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);
        const agentSchedule = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: {
                $or: [
                    {
                        agentId: agentData._id,
                    },
                    {
                        showingAssistantAgentUserId: agentData.stitchUserId,
                    },
                ],
                start: {
                    $gte: firstDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            projection: {
                start: 1,
                end: 1,
                type: 1,
                showingAssistantAgentUserId: 1,
                consumer: 1,
                status: 1,
            },
            signature: generateSignature(),
        });
        yield put(Actions.fetchAgentAvailabilitySucceeded(agentSchedule));
    } catch (error: any) {
        yield put(Actions.fetchAgentAvailabilityFailed(error));
        yield call(alert, {
            type: 'error',
            message: 'There is an issue in retrieving your schedule information. Code: 0102',
        });
    }
}

export default function* () {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.SHOWING_ACTION.Create && action.status === STATUS.Requested,
            createShowing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SHOWING_ACTION.Reschedule &&
                action.status === STATUS.Requested,
            rescheduleShowing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SHOWING_ACTION.UpdateStatus &&
                action.status === STATUS.Requested,
            updateShowingStatus,
        ),
        /**
         * on calling FetchShowingAvailability, fetch the availability
         * adjacently to the agent's schedule
         */
        takeLatest(
            (action: any) =>
                action.type === Actions.SHOWING_ACTION.FetchShowingAvailability &&
                action.status === STATUS.Requested,
            fetchShowingAvailability,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SHOWING_ACTION.FetchAgentAvailability &&
                action.status === STATUS.Requested,
            fetchAgentShowingSchedule,
        ),
    ]);
}
