import { DateTime, Interval } from 'luxon';

export const noop = () => {};

// helper function used to split unavailable dates up to match our logic
// currently we get the unavailable dates in order of [ MonData, TueData, WedData, ... ]
// then say it's thursday and our scheduleData.dateIndex is 0,
// we will then set the unavailable dates for Thursday as if they were Monday
// this function is to re-order the unavailable dates array so the 0th index is always the current day, 1st is tomorrow, etc...
// thanks stack overflow
export const reorder = (array: any[], index: number) =>
    array.slice(index).concat(array.slice(0, index));

//LOCAL HELPER FUNCTIONS

// thanks stack overflow
// returns time interval to the nearest 15 minutes
export const roundTimeQuarterHour = () => {
    var timeToReturn = new Date();

    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn;
};

//Need this handler to successfully convert
export const convertMinsToNumber = (mins: number) => {
    switch (mins) {
        case 15: {
            return 0.25;
        }
        case 30: {
            return 0.5;
        }
        case 45: {
            return 0.75;
        }
    }
};

// helper function for 'canScheduleThisTimeSlot' to determine if a number is inside a range of numbers
//n = event length
//a = start time
//b = end time
export const isBetween = (n: number, a: number, b: number) => {
    return (n - a) * (n - b) < 0;
};

// helper function for 'canScheduleThisTimeSlot' to determine the start/end time range for the user's current schedule range
// returns the time range of the start/end range in the same format of { showingStart: Number, showingEnd: Number }
// i.e. a uesr scheduling a showing with a start at 2:30pm - 3:30pm would return { showingStart: 870, showingEnd: 930 }
export const getUsersCurrentRange = (scheduleData: any) => {
    let currentTimeSplit = scheduleData.time.split(':');
    let currentHour = parseInt(currentTimeSplit[0]);
    if (currentTimeSplit[1].charAt(2) === 'P' && currentTimeSplit[0] !== '12') currentHour += 12;
    let currentMinute = parseInt(currentTimeSplit[1].substring(0, 2));

    let thisShowingRangeStart = currentHour * 60 + currentMinute;
    let thisShowingRangeEnd = thisShowingRangeStart;
    switch (scheduleData.duration) {
        case '15min':
            {
                thisShowingRangeEnd += 15;
            }
            break;
        case '30min':
            {
                thisShowingRangeEnd += 30;
            }
            break;
        case '1hr':
            {
                thisShowingRangeEnd += 60;
            }
            break;
        case '2hr':
            {
                thisShowingRangeEnd += 120;
            }
            break;
    }

    return {
        showingStart: thisShowingRangeStart,
        showingEnd: thisShowingRangeEnd,
    };
};

//ALL OTHER FUNCTIONS

export const determineTimeMap = (timeToCompare: string, allTimesToNotSchedule: string[]) => {
    if (allTimesToNotSchedule?.length > 0) {
        const timeLength = allTimesToNotSchedule?.filter((time: string) => time === timeToCompare)
            ?.length;

        if (timeLength > 0) {
            return false;
        }

        return true;
    } else if (allTimesToNotSchedule?.length === 0) {
        return true;
    }
};

//This function will help determine whether or not to map a blocked off time, depending on if it overlaps with the current time or blocked off connect listing times
export const determineShouldMap = (
    showingToMap: any,
    currentDay: number,
    timesUnableToSchedule: any,
    currentDayWindow: any,
    agentListing: any,
) => {
    if (timesUnableToSchedule) {
        /**
         * Need to first determine whether or not overlap is allowed
         */
        if (!agentListing?.allowOverlap) {
            const showingStart = showingToMap.start.hours + showingToMap.start.minutes / 60;
            const showingEnd = showingToMap.end.hours + showingToMap.end.minutes / 60;
            const currentTime = new Date().getMinutes() / 60 + new Date().getHours();

            /**
             * Three cases:
             * First: The selected day is today, so need to check if the showing overlaps with connect listing of course, but also ensure the time of the showing hasn't already passed
             * Second: The selected day is any other day of teh week, so just need to check if it conflicts with connected listing configuration
             * Third: The showingToMap is something defined by the connected listing, so just map it no matter what
             */
            if (
                showingStart > currentTime &&
                showingStart > currentDayWindow.start.end &&
                showingEnd < currentDayWindow.end.start &&
                currentDay === 0
            ) {
                return true;
            } else if (
                showingStart > currentDayWindow.start.end &&
                showingEnd < currentDayWindow.end.start &&
                currentDay > 0
            ) {
                return true;
            } else if (
                (showingStart === currentDayWindow.start.start &&
                    showingEnd === currentDayWindow.start.end) ||
                (showingStart === currentDayWindow.end.start &&
                    showingEnd === currentDayWindow.end.end)
            ) {
                return true;
            }
            return false;
        } else if (agentListing?.allowOverlap) {
            const showingStart = showingToMap.start.hours + showingToMap.start.minutes / 60;
            const showingEnd = showingToMap.end.hours + showingToMap.end.minutes / 60;

            /**
             * In this scenario we only need to block out times as defined by the connected listing, because listings can overlap
             */
            if (
                (showingStart === currentDayWindow.start.start &&
                    showingEnd === currentDayWindow.start.end) ||
                (showingStart === currentDayWindow.end.start &&
                    showingEnd === currentDayWindow.end.end)
            ) {
                return true;
            }
            return false;
        }
    } else if (!timesUnableToSchedule) {
        return true;
    }
};

//function to handle if multiple showings overlap in start or end times
//This will block the showings together so that only one time block will appear instead of many
export const createLargeGroups = (timesUnableToSchedule: any, currentDay: number) => {
    if (timesUnableToSchedule && timesUnableToSchedule[currentDay]) {
        var selectedDayShowings = timesUnableToSchedule[currentDay];
        var scheduleWindowBoundary = {
            start: {
                start: 0,
                end: 0,
            },
            end: {
                start: 0,
                end: 0,
            },
        };
        var overlapShowings: any[] = [];
        var overlapShowing = {
            end: {
                hours: undefined,
                minutes: undefined,
            },
            start: {
                hours: undefined,
                minutes: undefined,
            },
        };

        //Compare each showings to the entire array of showings to determine if a showing overlaps
        selectedDayShowings?.map((dateTimes: any, key: number) => {
            var isOverlapStart = false;
            var isOverlapEnd = false;

            for (var i in selectedDayShowings) {
                if (
                    dateTimes.end?.hours === selectedDayShowings[i].start?.hours &&
                    dateTimes.end?.minutes === selectedDayShowings[i].start?.minutes
                ) {
                    isOverlapEnd = true;
                } else if (
                    dateTimes.start?.hours === selectedDayShowings[i].end?.hours &&
                    dateTimes.start?.minutes === selectedDayShowings[i].end?.minutes
                ) {
                    isOverlapStart = true;
                }
            }
            if (dateTimes.start.hours === 7 && dateTimes.start.minutes === 0) {
                scheduleWindowBoundary = {
                    ...scheduleWindowBoundary,
                    start: {
                        start: dateTimes.start.hours + dateTimes.start.minutes / 60,
                        end: dateTimes.end.hours + dateTimes.end.minutes / 60,
                    },
                };
            }
            if (dateTimes.end.hours === 23 && dateTimes.end.minutes === 0) {
                scheduleWindowBoundary = {
                    ...scheduleWindowBoundary,
                    end: {
                        start: dateTimes.start.hours + dateTimes.start.minutes / 60,
                        end: dateTimes.end.hours + dateTimes.end.minutes / 60,
                    },
                };
            }
            if (isOverlapEnd && !isOverlapStart) {
                //If the showing overlaps the end time of another showing, make the current showing's start the windows start
                //Think about it... if this showing overlaps the start of another with it's end, then it's start is earlier
                overlapShowing = {
                    ...overlapShowing,
                    start: {
                        hours: dateTimes.start.hours,
                        minutes: dateTimes.start.minutes,
                    },
                };

                //If the end time is already set and there's no more overlap just add the block to the array
                if (overlapShowing.end.hours) {
                    overlapShowings.push(overlapShowing);
                    overlapShowing = {
                        end: {
                            hours: undefined,
                            minutes: undefined,
                        },
                        start: {
                            hours: undefined,
                            minutes: undefined,
                        },
                    };
                }
            } else if (!isOverlapEnd && isOverlapStart) {
                //If the showing overlaps the start time of another showing, make the current showing's end the windows end
                //Think about it... if this showing overlaps the end of another with it's start, then it's end is later
                overlapShowing = {
                    ...overlapShowing,
                    end: {
                        hours: dateTimes.end.hours,
                        minutes: dateTimes.end.minutes,
                    },
                };

                //If the start time is already set and there's no more overlap just add the block to the array
                if (overlapShowing.start.hours) {
                    overlapShowings.push(overlapShowing);
                    overlapShowing = {
                        end: {
                            hours: undefined,
                            minutes: undefined,
                        },
                        start: {
                            hours: undefined,
                            minutes: undefined,
                        },
                    };
                }
            }
        });

        //Return the overlapping showings in an array and the schedule window boundary for the given day
        return {
            overlapShowings: overlapShowings,
            scheduleWindowBoundary: scheduleWindowBoundary,
        };
    }
};

// Gets start and end times and the display name based on the data in the showing
export const getTimesAndDisplayName = (showing: any) => {
    // grouped showings are easy, just return the start of the beginning and end of the last showing in the group
    if (Array.isArray(showing)) {
        return {
            start: showing[0].start,
            end: showing[showing?.length - 1].end,
            displayName: 'Multiple Showings',
        };
    }
    let start = showing.start;
    let end = showing.end;
    let displayName = '';

    if (showing.status === 'queued') {
        displayName += 'Queued: ';
    }

    displayName += showing?.listing?.address?.full || 'Showing';
    if (showing?.consumer?.firstName) {
        start = showing.start;
        end = showing.end;
        if (showing?.type == 'agent') {
            displayName = `Client Showing w/ ${showing?.consumer?.firstName} ${showing?.consumer?.lastName[0]}.`;
        } else if (showing?.type == 'lead') {
            displayName = `Lead Showing w/ ${showing?.consumer?.firstName} ${showing?.consumer?.lastName[0]}.`;
        } else {
            displayName = `Showing w/ ${showing?.consumer?.firstName} ${showing?.consumer?.lastName[0]}.`;
        }
    }

    return { start, end, displayName };
};

// handler for onClickSchedule and onClickQueue buttons
export const getStartAndEndTime = (scheduleData: any) => {
    const timeSplit = scheduleData.time.split(':');
    let startHour = parseInt(timeSplit[0]);
    if (timeSplit[1].charAt(2) === 'P' && timeSplit[0] !== '12') startHour += 12;
    let startMinute = parseInt(timeSplit[1].substring(0, 2));

    let endHour = startHour;
    let endMinute = startMinute;
    switch (scheduleData.duration) {
        case '15min':
            {
                endMinute += 15;
            }
            break;
        case '30min':
            {
                endMinute += 30;
            }
            break;
        case '1hr':
            {
                endHour += 1;
            }
            break;
        case '2hr':
            {
                endHour += 2;
            }
            break;
    }
    let startTime = DateTime.fromMillis(new Date().getTime())
        .set({
            month: scheduleData.date.month + 1,
            day: parseInt(scheduleData.date.date),
            hour: startHour,
            minute: startMinute,
            second: 0,
            millisecond: 0,
        })
        .toJSDate();
    let endTime = DateTime.fromMillis(new Date().getTime())
        .set({
            month: scheduleData.date.month + 1,
            day: parseInt(scheduleData.date.date),
            hour: endHour,
            minute: endMinute,
            second: 0,
            millisecond: 0,
        })
        .toJSDate();

    return { startTime, endTime };
};

// returns a blocked time slot for everything that happened earlier in the day
export const getTimeStyleUnavailableFromEarlierToday = (
    availableShowingTimes: any,
    renderYourSchedule: boolean,
) => {
    const getCurrentTime = DateTime.fromJSDate(roundTimeQuarterHour())
        .set({
            second: 0,
            millisecond: 0,
        })
        .toFormat('h:mma');

    const timeIndex = availableShowingTimes.indexOf(getCurrentTime);

    return {
        timeIndex,
        style: {
            height: `${20 * timeIndex}px`,
            width: renderYourSchedule ? '337px' : '675px',
            top: '10px',
        },
    };
};

// returns true if "This Showing" modal is not overlapping a "Time Unavailable" slot
// will also return true if "This Showing" modal is not overlapping a "My Schedule" showing AND allowOverlap is false
export const canScheduleThisTimeSlot = (
    status: string | undefined,
    yourScheduledShowings: any,
    showingData: any,
    agentListing: any,
    modalType: string,
    timesUnableToSchedule: any,
    scheduleData: any,
    availableShowingTimes: any,
    renderYourSchedule: boolean,
) => {
    let canSchedule = true;
    const { showingStart, showingEnd } = getUsersCurrentRange(scheduleData);

    // if the user is attempting to schedule on the current date
    // ensure that they cannot schedule earlier in the date / in the past
    // i.e if it's 1pm the user shouldn't be able to schedule a showing for 9am the same day
    if (scheduleData.dateIndex === 0) {
        const unavailableTimeIndex = getTimeStyleUnavailableFromEarlierToday(
            availableShowingTimes,
            renderYourSchedule,
        ).timeIndex;
        const currentTimeIndex = availableShowingTimes.indexOf(scheduleData.time);
        if (currentTimeIndex < unavailableTimeIndex) {
            canSchedule = false;
        }
    }

    if (
        !timesUnableToSchedule ||
        timesUnableToSchedule?.length < 1 ||
        timesUnableToSchedule[scheduleData.dateIndex] === -1
    ) {
        return canSchedule;
    }

    // convert start and end to their total minutes in the current day
    // if the minutes of the current "This showing" modal overlap the time of the unnavailable date range, return false
    timesUnableToSchedule[scheduleData.dateIndex] &&
        timesUnableToSchedule[scheduleData.dateIndex].map(({ start, end, showing }: any) => {
            // allow users to schedule over a showing if overlapping is allowed
            if (showing && agentListing?.allowOverlap) {
                return;
            }

            let startRange = start.hours * 60 + start.minutes;
            let endRange = end.hours * 60 + end.minutes;

            if (
                isBetween(showingStart, startRange, endRange) ||
                isBetween(showingEnd, startRange, endRange)
            ) {
                canSchedule = false;
            }
        });

    // if allow overlap is false, we need to handle that case
    if (!agentListing?.allowOverlap) {
        // showings stored inside of yourScheduledShowings need to be handled differently from the range of time we get from timesUnableToSchedule
        yourScheduledShowings &&
            yourScheduledShowings?.length &&
            yourScheduledShowings.map((showing: any) => {
                const { start, end } = showing;
                const startMinutes = parseInt(DateTime.fromJSDate(start).toFormat('m'));
                let startHours = parseInt(DateTime.fromJSDate(start).toFormat('h'));
                const endMinutes = parseInt(DateTime.fromJSDate(end).toFormat('m'));
                let endHours = parseInt(DateTime.fromJSDate(end).toFormat('h'));

                const meridiemStart = DateTime.fromJSDate(start).toFormat('a');
                const meridiemEnd = DateTime.fromJSDate(end).toFormat('a');
                if (meridiemStart === 'PM' && startHours !== 12) startHours += 12;
                if (meridiemEnd === 'PM' && endHours !== 12) endHours += 12;

                let startRange = startHours * 60 + startMinutes;
                let endRange = endHours * 60 + endMinutes;

                const { showingStart, showingEnd } = getUsersCurrentRange(scheduleData);

                if (
                    isBetween(showingStart, startRange, endRange) &&
                    isBetween(showingEnd, startRange, endRange) &&
                    showingStart === startRange // <- showing starts at the same time as another
                ) {
                    canSchedule = false;
                }
            });

        //If overlapping showings aren't allowed this makes sure that showings can't be scheduled that overlap
        if (canSchedule) {
            const startTime = showingStart / 60;
            const endTime = showingEnd / 60;

            timesUnableToSchedule &&
                timesUnableToSchedule[scheduleData.dateIndex] &&
                timesUnableToSchedule[scheduleData.dateIndex].map((showing: any, key: number) => {
                    var showingStart =
                        showing.start?.hours + convertMinsToNumber(showing.start?.minutes);
                    var showingEnd = showing.end?.hours + convertMinsToNumber(showing.end?.minutes);

                    if (showingStart === startTime) {
                        canSchedule = false;
                    } else if (showingEnd === endTime) {
                        canSchedule = false;
                    } else if (
                        isBetween(startTime, showingStart, showingEnd) ||
                        isBetween(endTime, showingStart, showingEnd)
                    ) {
                        canSchedule = false;
                    }
                });
        }
    }
    //Need to allow reschedule for the same time if the modal is reschedule and the current showing equals the blocked showing
    if (modalType === 'reschedule' && !canSchedule && status !== 'confirmed') {
        const currShowingStart = showingStart / 60;
        const currShowingEnd = showingEnd / 60;
        const oldShowingStart =
            parseInt(DateTime.fromJSDate(showingData.startDate).toFormat('H')) +
            parseInt(DateTime.fromJSDate(showingData.startDate).toFormat('m')) / 60;
        const oldShowingEnd =
            parseInt(DateTime.fromJSDate(showingData.endDate).toFormat('H')) +
            parseInt(DateTime.fromJSDate(showingData.endDate).toFormat('m')) / 60;

        //Necessary because a user may want to simply add a client to a showing without changing the time or slightly adjust the time by a few minutes
        //without changing the window of the showing
        if (
            (currShowingStart === oldShowingStart && currShowingEnd === oldShowingEnd) ||
            isBetween(currShowingStart, oldShowingStart, oldShowingEnd) ||
            isBetween(currShowingEnd, oldShowingStart, oldShowingEnd)
        ) {
            // <- Check if the new time is the same as the already scheduled time when reschedule set
            canSchedule = true;
        }
    }

    return canSchedule;
};

// calculate the height, width and the top offset for all the users scheduled showings
export const getListingStyleForYourSchedule = (startTime: any, endTime: any) => {
    let duration: any =
        DateTime.fromJSDate(endTime).toSeconds() - DateTime.fromJSDate(startTime).toSeconds();
    duration /= 60;
    let height;
    switch (duration) {
        case 15:
            {
                height = 20;
            }
            break;
        case 30:
            {
                height = 40;
            }
            break;
        case 60:
            {
                height = 80;
            }
            break;
        case 120:
            {
                height = 160;
            }
            break;
        default: {
            height = (duration / 15) * 20;
        }
    }
    let top = 0;
    let currentTime = DateTime.fromJSDate(startTime).toFormat('t').split(':');
    let pmOffset = parseInt(currentTime[0]) !== 12 && currentTime[1].charAt(3) === 'P' ? 12 : 0; // add 12 if the time is in the afternoon
    top += (parseInt(currentTime[0]) + pmOffset - 7) * 79; // current hour minus 7 hours, as 7am is the 0th time, *79 as that's the height between gray bars
    switch (parseInt(currentTime[1].charAt(0))) {
        case 0:
            {
                top += 0;
            }
            break; // 0min
        case 1:
            {
                top += 20;
            }
            break; // 15min
        case 3:
            {
                top += 40;
            }
            break; // 30min
        case 4:
            {
                top += 60;
            }
            break; // 45min
    }

    return {
        height: `${height}px`,
        top: `${top + 10}px`,
    };
};

// groups overlapping showings into an array
export function handleOverlap(showingsInDay: any) {
    if (showingsInDay.showingRequests?.length > 1) {
        let sortedShowings = showingsInDay.showingRequests
            .sort((a: any, b: any) => new Date(a.start).getTime() - new Date(b.start).getTime())
            .sort((a: any, b: any) => new Date(a.end).getTime() - new Date(b.end).getTime());

        // Merge all showings that overlap for a given time period
        for (let i = 1; i < sortedShowings?.length; i++) {
            const curr = sortedShowings[i];
            const prev = sortedShowings[i - 1];
            let currInterval: any = 0;
            let prevInterval: any = 0;

            // Interval of current element being looked at
            currInterval = Interval.fromDateTimes(
                DateTime.fromJSDate(curr.start),
                DateTime.fromJSDate(curr.end),
            );

            /**
             * If the previous index is not an array (currently has no overlapping showings),
             * compare the interval of the previous element. Otherwise compare the first index
             * of the previous element
             */
            if (!Array.isArray(prev)) {
                prevInterval = Interval.fromDateTimes(prev.start, prev.end);
            } else {
                prevInterval = Interval.fromDateTimes(
                    prev[prev?.length - 1].start,
                    prev[prev?.length - 1].end,
                );
            }

            /**
             * If the current showing overlaps the previous showing, merge the current
             * and overlapping showings. If the previous showing is already a list of
             * elements, move the current showing into that list of already overlapping showings.
             */
            if (currInterval.overlaps(prevInterval)) {
                if (!Array.isArray(prev)) {
                    sortedShowings[i - 1] = [{ ...prev }, { ...curr }];
                } else {
                    sortedShowings[i - 1].push(curr);
                }
                sortedShowings.splice(i, 1);
                i--;
            }
        }
    }
}

// calculate the height, width, and the top offset of the users current showing time
export const getListingStyle = (scheduleData: any, renderYourSchedule: boolean) => {
    let height;
    switch (scheduleData.duration) {
        case '15min':
            {
                height = 20;
            }
            break;
        case '30min':
            {
                height = 40;
            }
            break;
        case '1hr':
            {
                height = 80;
            }
            break;
        case '2hr':
            {
                height = 160;
            }
            break;
    }

    let top = 0;
    let currentTime = scheduleData.time.split(':');
    let pmOffset = parseInt(currentTime[0]) !== 12 && currentTime[1].charAt(2) === 'P' ? 12 : 0; // add 12 if the time is in the afternoon
    top += (parseInt(currentTime[0]) + pmOffset - 7) * 79; // current hour minus 7 hours, as 7am is the 0th time, *79 as that's the height between gray bars
    switch (parseInt(currentTime[1].charAt(0))) {
        case 0:
            {
                top += 0;
            }
            break; // 0min
        case 1:
            {
                top += 20;
            }
            break; // 15min
        case 3:
            {
                top += 40;
            }
            break; // 30min
        case 4:
            {
                top += 60;
            }
            break; // 45min
    }
    return {
        height: `${height}px`,
        top: `${top + 10}px`,
        width: renderYourSchedule ? '337px' : '675px',
    };
};

// calculate the height, width, and the top offset of the times the user is not able to schedule for
export const getUnavailableTimeStyle = (start: any, end: any, renderYourSchedule: boolean) => {
    const duration = ((end?.hours - start?.hours) * 60 - start?.minutes + end?.minutes) / 30;
    let height = duration * 40;

    let top = 0;
    top += (start?.hours - 7) * 79 + (start?.minutes / 15) * 20;

    return {
        height: `${height}px`,
        top: `${top + 10}px`,
        width: renderYourSchedule ? '337px' : '675px',
    };
};

// the date index is relative to the day it is currently in the world
// lets say its a monday, the date index is 0 for monday, so 1 is tuesday, etc...
// lets say now its friday, the date index  is 0 for friday, so 1 is saturday, etc...
// this function is used to get the given dateIndex
export const getDateIndex = (currentDate: string) => {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return days.indexOf(currentDate);
};
