import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { callStitchFunction } from '../../../store/api/sagas';
import { parseStitchServiceError } from '../../../utils/common';
import { formatPhoneNumberForDisplay, formatPhoneNumberToIntl } from '../../../utils/common';
import { getAgentObject } from '../../auth/selectors';
import * as Actions from './actions';
import { NewClient } from './CRMAddClientsModal/constants';

export function* fetchClients({}: ReturnType<typeof Actions.fetchClientsRequested>): Generator<
    any,
    any,
    any
> {
    try {
        const clients = yield call(callStitchFunction, 'fetchAgentClients');
        if (clients) {
            yield put(Actions.fetchClientsSucceeded(clients));
        } else {
            yield put(Actions.fetchClientsFailed([]));
        }
    } catch (err: any) {
        const mes: any = parseStitchServiceError(err);
        window.alert(`Error: ${mes}`);
        yield put(Actions.fetchClientsFailed(err));
    }
}

export function* deleteClients({
    clientsToDelete,
}: ReturnType<typeof Actions.deleteClientsRequested>): Generator<any, any, any> {
    const loggedClients: any = [];
    const otherClients: any = [];
    clientsToDelete.forEach((client: any) => {
        if (client.status === 'logged') loggedClients.push(client.stitchUserId);
        else otherClients.push(client.stitchUserId);
    });
    var loggedResult = null;
    var otherResult = null;
    let totalDeleted = 0;

    try {
        if (loggedClients.length) {
            loggedResult = yield call(callStitchFunction, 'deleteLoggedConsumer', loggedClients);
            if (loggedResult) totalDeleted += loggedClients.length;
        }
        if (otherClients.length) {
            otherResult = yield call(callStitchFunction, 'removeAgentClients', otherClients);
            if (otherResult) totalDeleted += otherClients.length;
        }
        if (totalDeleted === clientsToDelete.length) {
            yield put(
                Actions.deleteClientsSucceeded(
                    `Successfully deleted ${clientsToDelete.length} ${
                        clientsToDelete.length === 1 ? 'client' : 'clients'
                    }`,
                ),
            );
            yield put(Actions.fetchClientsRequested());
        } else {
            yield put(
                Actions.deleteClientsSucceeded(
                    `Successfully deleted ${totalDeleted} ${
                        totalDeleted === 1 ? 'client' : 'clients'
                    }`,
                ),
            );
            yield put(Actions.fetchClientsRequested());
        }
    } catch (err) {
        yield put(Actions.deleteClientsFailed('There was an error deleting these clients.'));
    }
}
export function* addClients({
    payload,
}: ReturnType<typeof Actions.addClientsRequested>): Generator<any, any, any> {
    const validNumbers: Array<any> = [];
    const invalidNumbers: Array<any> = [];
    const agent = yield select(getAgentObject);

    payload.forEach((client) => {
        const internationalPhone = formatPhoneNumberToIntl(client.phoneNumber);
        const displayPhone = formatPhoneNumberForDisplay(client.phoneNumber);

        if (internationalPhone.includes('valid')) {
            invalidNumbers.push({
                ...client,
                displayPhone,
                errorMessage: 'Not a valid phone number',
            });
        } else {
            validNumbers.push({ ...client, displayPhone, phoneNumber: internationalPhone });
        }
    });
    try {
        const response = yield call(
            callStitchFunction,
            'addClients',
            validNumbers,
            invalidNumbers,
            agent._id,
        );
        if (response) {
            yield put(Actions.addClientsSucceeded(response));
            yield put(Actions.fetchClientsRequested());
        }
    } catch (error) {
        Actions.addClientsFailed(error);
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.FETCH_CLIENTS_REQUESTED, fetchClients),
        takeLatest(Actions.DELETE_CLIENTS_REQUESTED, deleteClients),
        takeLatest(Actions.ADD_CLIENTS_REQUESTED, addClients),
    ]);
}
