export default (theme: any) => {
    return {
        root: {
            width: 'fit-content',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
        page: {
            width: 10,
            height: 10,
            padding: 10,
            margin: 5,
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 13,
            textAlign: 'center',
            color: theme.palette.black,
            cursor: 'pointer',
        },
        selectedPage: {
            width: 10,
            height: 10,
            padding: 10,
            margin: 5,
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 13,
            textAlign: 'center',
            color: theme.palette.white,
            backgroundColor: theme.palette.blue,
        },
        leftArrow: {
            width: 30,
            height: 30,
            marginRight: 5,
        },
        rightArrow: {
            width: 30,
            height: 30,
            marginLeft: 5,
            transform: 'rotate(180deg)',
        },
    };
};
