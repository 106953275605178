export default (theme: any) => {
    return {
        root: {
            flexDirection: 'row',
            display: 'flex',
            overflowX: 'scroll',
            backgroundColor: theme.palette.white,
            alignItems: 'center',
            height: 400,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        card: {
            height: 339,
            width: 394,
            backgroundColor: theme.palette.white,
            marginRight: 16,
            borderRadius: 5,
            ...theme.commonShadow,
            cursor: 'pointer',
            '& h1': {
                fontFamily: 'Poppins-medium',
                fontSize: 16,
                ...theme.paddingHorizontal(16),
                marginTop: 24,
                color: theme.palette.black,
            },
            '& span': {
                padding: 16,
                fontFamily: 'Poppins-semiBold',
                ...theme.paddingHorizontal(16),
                color: theme.palette.black,
                fontSize: 14,
            },
        },
        articleImage: {
            height: 222,
            width: 394,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
        },
        tag: {
            backgroundColor: theme.palette.background,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.mediumGrey,
            fontSize: 14,
            position: 'absolute',
            left: 16,
        },
        blog: {
            '& h1': {
                fontFamily: 'Poppins-semiBold',
                fontSize: 20,
            },
            '& span': {
                fontFamily: 'Poppins-medium',
                fontSize: 16,
            },
            '& h2': {
                fontFamily: 'Poppins-medium',
                fontSize: 18,
            },
            marginBottom: 48,
        },
    };
};
