import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Loader } from 'web-lib';
import styles from './index.module.css';
import { anonLogin, findShowingRequest, updateShowingRequest } from '../../store/api/sagas';
import { BSON } from 'realm-web';
import { DateTime } from 'luxon';

import consumerWordmark from '../../images/blueWordmark.svg';

const ConfirmDenyScreen = ({}) => {
    const loc = useLocation();
    const strings = loc.pathname.split('/');
    const _id = strings[strings.length - 1];

    //State
    const [showingRequest, setShowingRequest] = useState({});
    const [choiceMade, setChoiceMade] = useState(false);
    const [loading, setLoading] = useState(false);

    const startTime = DateTime.fromJSDate(showingRequest?.start);
    const endTime = DateTime.fromJSDate(showingRequest?.end);
    const displayDate = startTime.toFormat('cccc, LL/dd');
    const displayStartTime = startTime.toFormat('t');
    const displayEndTime = endTime.toFormat('t');
    const address = showingRequest?.listing?.address?.full;

    async function handleUpdateShowingRequest(showingRequestData) {
        try {
            setLoading(true);
            await updateShowingRequest(showingRequestData);
            getShowing(showingRequestData.id);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            // window.location.reload();
            window.alert(
                'There was an issue in updating this showing.  Please contact support at (833) 217-7578 ',
            );
        }
    }

    async function getShowing() {
        await anonLogin();
        const decodedShowingID = atob(_id);
        const showing = await findShowingRequest({ _id: new BSON.ObjectID(decodedShowingID) });
        setShowingRequest(showing);
    }

    useEffect(() => {
        getShowing();
    }, []);

    const NoModify = () => {
        return (
            <div className={styles.flexContainer}>
                <div className={styles.logoContainer}>
                    <img src={consumerWordmark} alt="logo" className={styles.logoImage} />
                </div>
                <div className={styles.title}>
                    {'This showing has' +
                        (!choiceMade ? ' already' : '') +
                        ' been ' +
                        showingRequest?.status +
                        '!'}
                </div>
                <div className={styles.card}>
                    <div className={styles.info}>{displayDate}</div>
                    <div className={styles.info}>
                        {displayStartTime} - {displayEndTime}{' '}
                    </div>
                    <div className={styles.info}>{address}</div>
                </div>
            </div>
        );
    };

    const Modify = () => {
        return loading ? (
            <div className={styles.loaderContainer}>
                <Loader icon={'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'} />
            </div>
        ) : (
            <div className={styles.flexContainer}>
                <div className={styles.logoContainer}>
                    <img src={consumerWordmark} alt="logo" className={styles.logoImage} />
                </div>
                {showingRequest?.approvals?.includes('client') ? (
                    <>
                        <div className={styles.title}>Waiting on Listing Agent</div>
                        <div className={styles.card}>
                            <div className={styles.info}>{displayDate}</div>
                            <div className={styles.info}>
                                {displayStartTime} - {displayEndTime}{' '}
                            </div>
                            <div className={styles.info}>{address}</div>
                        </div>
                        <div className={styles.outContainer}>
                            <div className={styles.flexDiv}>
                                <Button
                                    buttonText="Deny"
                                    className={styles.buttonStyleD}
                                    onClick={() => {
                                        setChoiceMade(true);
                                        handleUpdateShowingRequest({
                                            id: atob(_id),
                                            status: 'denied',
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.title}>Confirm Showing?</div>
                        <div className={styles.card}>
                            <div className={styles.info}>{displayDate}</div>
                            <div className={styles.info}>
                                {displayStartTime} - {displayEndTime}{' '}
                            </div>
                            <div className={styles.info}>{address}</div>
                        </div>
                        <div className={styles.outContainer}>
                            <div className={styles.flexDiv}>
                                <Button
                                    buttonText="Deny"
                                    className={styles.buttonStyleD}
                                    onClick={() => {
                                        setChoiceMade(true);
                                        handleUpdateShowingRequest({
                                            id: atob(_id),
                                            status: 'denied',
                                        });
                                    }}
                                />

                                <Button
                                    buttonText="Accept"
                                    className={styles.buttonStyleA}
                                    onClick={() => {
                                        setChoiceMade(true);
                                        handleUpdateShowingRequest({
                                            id: atob(_id),
                                            status: 'confirmed',
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const Loading = () => {
        return (
            <div className={styles.loaderContainer}>
                <Loader icon={'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'} />
            </div>
        );
    };

    const { status = null } = showingRequest || {};

    switch (status) {
        case 'pending_internal':
            return <Modify />;
        case 'denied':
        case 'cancelled':
        case 'canceled':
        case 'confirmed':
        case 'completed':
            return <NoModify />;
        default:
            return <Loading />;
    }
};

export default ConfirmDenyScreen;
