import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, ClickableWithFeedback, Loader, Typography } from '../../../../components';
import ButtonComponent from '../../../../components/ButtonComponent';
import CloseIcon from '../../../../images/xBlack.svg';
import AddManually from './common/AddManually';
import AddByCSV from './common/AddByCSV';
import { NewClient } from './constants';
import greenRedSvg from './common/greenCheckRedX.svg';
import greenCheck from './common/greenCheck.svg';
import redX from './common/redX.svg';

interface CRMAddClientsModalProps {
    visible: boolean;
    onClickClose: Function;
    onClickSubmit: Function;
    loading: boolean;
    message: any;
    errors: any;
}
const CRMAddClientsModal = ({
    visible,
    onClickClose,
    onClickSubmit,
    loading,
    message,
    errors,
}: CRMAddClientsModalProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [addMethod, setAddMethod] = useState<string | null>(null);
    const [newClients, setNewClients] = useState<Array<NewClient>>([
        {
            firstName: '',
            lastName: '',
            phoneNumber: '',
        },
    ]);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    useEffect(() => {
        if (addMethod === 'manual') {
            setSubmitDisabled(false);
            newClients.forEach((client: any) => {
                if (!client?.firstName || !client?.lastName || client?.phoneNumber.length !== 14) {
                    setSubmitDisabled(true);
                }
            });
        }
    }, [newClients]);

    const headerBar = (
        <div className={styleSheet.headerBar}>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                {addMethod === 'csv' ? 'Add Clients By .CSV' : 'Add Clients'}
            </Typography>
            <ClickableWithFeedback
                onClick={() => (
                    addMethod ? setAddMethod(null) : onClickClose(),
                    setNewClients([
                        {
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                        },
                    ])
                )}
            >
                <img src={CloseIcon} className={styleSheet.closeIcon} />
            </ClickableWithFeedback>
        </div>
    );
    const successMessage = (
        <>
            <div style={{ width: 530 }}>
                <div style={{ display: 'flex' }}>
                    <img style={{ paddingRight: 20 }} src={greenRedSvg} />
                    <div>
                        <Typography
                            style={{ marginTop: 20 }}
                            className={styleSheet.successRow}
                            textStyle="p1"
                            color={theme.palette.black}
                        >
                            <img className={styleSheet.checkImg} src={greenCheck} />{' '}
                            {message?.clientsAdded} requests successfully sent.
                        </Typography>
                        <Typography
                            className={styleSheet.successRow}
                            textStyle="p1"
                            color={theme.palette.black}
                        >
                            <img className={styleSheet.checkImg} src={redX} />{' '}
                            {message?.clientsFailed} clients failed to import.
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography style={{ marginTop: 15 }} textStyle="b1" color={theme.palette.mediumGrey}>
                We've Emailed you a detailed receipt.
            </Typography>
        </>
    );

    const buttonChoices = (
        <div className={styleSheet.buttonContainer}>
            <ButtonComponent className={styleSheet.button} onClick={() => setAddMethod('csv')} fill>
                Upload .csv
            </ButtonComponent>
            <ButtonComponent className={styleSheet.button} onClick={() => setAddMethod('manual')}>
                Add Manually
            </ButtonComponent>
        </div>
    );

    const buttonBar = (
        <div className={styleSheet.buttonBar}>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                <ButtonComponent
                    className={styleSheet.button}
                    disabled={submitDisabled}
                    onClick={() => {
                        onClickSubmit(newClients);
                    }}
                    fill
                >
                    Add Clients
                </ButtonComponent>
            </Typography>
        </div>
    );

    const middleDiv = (
        <div className={styleSheet.middleContainer}>
            {loading && <Loader />}
            {!loading && !message && !errors && (
                <>
                    {!addMethod && buttonChoices}
                    {addMethod === 'csv' && (
                        <AddByCSV
                            firstClient={newClients[0]}
                            setNewClients={setNewClients}
                            setSubmitDisabled={setSubmitDisabled}
                        />
                    )}
                    {addMethod === 'manual' && (
                        <AddManually newClients={newClients} setNewClients={setNewClients} />
                    )}
                </>
            )}
            {!loading && message && successMessage}
            {!loading && errors && (
                <Typography textStyle="p1" color={theme.palette.black}>
                    {' '}
                    There was an issue adding clients. Please try again later.
                </Typography>
            )}
        </div>
    );

    return (
        <AnimatedModal
            set={onClickClose}
            modalVisible={visible}
            style={{ minHeight: 600, width: 800, margin: 'auto', top: null }}
        >
            {headerBar}
            {middleDiv}
            {addMethod && !loading && !message && !errors && buttonBar}
        </AnimatedModal>
    );
};

export default CRMAddClientsModal;
