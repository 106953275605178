import { createSelector } from 'reselect';
import {
    //LIST_SKIP_INCREMENT_SIZE,
    MyShowingsState,
    MY_SHOWINGS_FILTER,
    ShowingPreview,
    ShowingsGroupedByListing,
} from './types';
// import { Showing } from '../../../utils/constants'
// import { DateTime } from 'luxon';
// import { searchMyShowingsFailed } from './actions';
//import { getSearchState } from '../../../' //'../maps/search/selectors';

/**
 * @constant
 * @type {{status: string, label: string}}
 */
export const STATUS_DISPLAY_LABELS = [
    { status: 'requested', label: 'Requested' },
    { status: 'pending_external', label: 'Pending' },
    { status: 'pending_internal', label: 'Pending' },
    { status: 'confirmed', label: 'Confirmed' },
    { status: 'cancelled', label: 'Cancelled' },
    { status: 'denied', label: 'Denied' },
    { status: 'completed', label: 'Completed' },
];

/**
 * Returns the given string with the first letter capitalized.
 *
 * @param string
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Give a status and a display value for that status will be returned.
 * @param {string} status
 * @returns {string} the label for status from STATUS_DISPLAY_LABELS or if not found in there, the status with the first letter capitalized
 */
export const getStatusForDisplay = (status: any) => {
    const statusDisplayLabel = STATUS_DISPLAY_LABELS.find(
        ({ status: labelStatus }) => status === labelStatus,
    );
    return statusDisplayLabel ? statusDisplayLabel.label : capitalizeFirstLetter(status);
};

export const getMyShowingsState = (state: { myShowings: MyShowingsState }) => state.myShowings;

// Creates a selector that retrieves the myShowings array for the given filter
export const getMyShowingsList = (filter: MY_SHOWINGS_FILTER) =>
    createSelector(getMyShowingsState, (myShowingsState) => {
        if (myShowingsState[filter]?.length) {
            const unGroupedShowings = myShowingsState[filter].flatMap(
                (listingGroup: ShowingsGroupedByListing) => {
                    const showingPreviewsOnThisListing = listingGroup.showingPreviews.map(
                        (showingPreview: ShowingPreview) => ({
                            ...showingPreview,
                            _id: showingPreview._id,
                            listingId: listingGroup._id,
                            listingPhoto: listingGroup.listingPhoto,
                            listingPrice: listingGroup.price,
                            address: listingGroup.address,
                            city: listingGroup.city,
                            streetName: listingGroup.streetName,
                            streetNumberText: listingGroup.streetNumberText,
                            postalCode: listingGroup.postalCode,
                            state: listingGroup.state,
                            blasts: listingGroup.blasts,
                            listing: listingGroup.listing
                        }),
                    );
                    return showingPreviewsOnThisListing;
                },
            );

            return unGroupedShowings.sort((a, b) => b.start?.valueOf() - a.start?.valueOf());
        }
        return [];
    });

export const getNoneShowings = createSelector(getMyShowingsState, (myShowingsState) => {
    if (myShowingsState['None'].length > 0) {
        const unGroupedShowings = myShowingsState['None'].flatMap(
            (listingGroup: ShowingsGroupedByListing) => {
                const showingPreviewsOnThisListing = listingGroup.showingPreviews.map(
                    (showingPreview: ShowingPreview) => ({
                        ...showingPreview,
                        _id: showingPreview._id,
                        listingId: listingGroup._id,
                        listingPhoto: listingGroup.listingPhoto,
                        listingPrice: listingGroup.price,
                        address: listingGroup.address,
                        city: listingGroup.city,
                        streetName: listingGroup.streetName,
                        streetNumberText: listingGroup.streetNumberText,
                        postalCode: listingGroup.postalCode,
                        state: listingGroup.state,
                    }),
                );
                return showingPreviewsOnThisListing;
            },
        );

        return unGroupedShowings.sort((a, b) => b.start?.valueOf() - a.start?.valueOf());
    } else if (myShowingsState['None'].length === 0) {
        return null;
    }
});

// export const getMyShowingsListForMap = createSelector(
//     getSearchState,
//     getMyShowingsState,
//     (mapSearchState, myShowingsState) => {
//         const { filter } = myShowingsState;
//         const { mapResults } = mapSearchState;
//         return mapResults.flatMap(
//             (listingGroup: ShowingsGroupedByListing) => {
//                 const showingPreviewsOnThisListing = listingGroup?.showingPreviews?.map(
//                     (showingPreview: ShowingPreview) => {
//                         const formattedStart = DateTime.fromJSDate(showingPreview.start);
//                         return {
//                             ...showingPreview,
//                             labelledStatus: showingPreview.status,
//                             listingId: listingGroup._id,
//                             showingType: showingPreview.type,
//                             startTimeDisplay: formattedStart.toFormat('t'),
//                             formattedDate: formattedStart.toFormat('ccc, LLLL d'),
//                             status: getStatusForDisplay(showingPreview.status),
//                             coordinates: {
//                                 latitude: listingGroup.lat,
//                                 longitude: listingGroup.lng,
//                             },
//                             id: showingPreview._id,
//                             cardType: 'showingMap',
//                             descriptions: [{
//                                     address1: listingGroup.address
//                             }],
//                             backgroundImageUrl: {
//                                 uri: listingGroup.listingPhoto,
//                             },
//                             listingPrice: listingGroup.price,
//                             address: listingGroup.address,
//                         };
//                     },
//                 );
//                 return showingPreviewsOnThisListing;
//             },
//         );
//     },
// );

export const getMyShowingsMap = createSelector(getMyShowingsState, (myShowingsState) => {
    const { filter } = myShowingsState;
    return myShowingsState[filter];
});

export const getMySearchedShowings = createSelector(getMyShowingsState, (myShowingsState) => {
    const { mySearchedShowings = [] } = myShowingsState;
    return mySearchedShowings.map((showing: any) => ({
        _id: showing._id,
        address: showing.listing.address.full,
        agentId: showing.agent ? showing.agent._id : '',
        listingId: showing.listing._id,
        listingPhoto: showing.listing.photos[0],
        listingPrice: showing.listing.listPrice,
        start: showing.start,
        end: showing.end,
        status: showing.status,
        type: showing?.type,
        lockboxType: showing.listing.agentListing?.lockboxType,
        lockCombo: showing.listing.agentListing?.lockCombo,
    }));
});

export const getMyShowingsFilter = createSelector(getMyShowingsState, (myShowingsState) => {
    const { filter } = myShowingsState;
    return filter;
});

export const isMyShowingsLoading = createSelector(
    getMyShowingsState,
    (myShowingsState) => myShowingsState.loading,
);

export const isMyShowingsRefreshing = createSelector(
    getMyShowingsState,
    (myShowingsState) => myShowingsState.refreshing,
);

// These functions are needed by our MyShowings saga in order to determine
// whether or not we need to fetch data from the database.
export const getAttemptedMyShowingsFetches = createSelector(
    getMyShowingsState,
    (myShowingsState) => myShowingsState.attempted,
);

// const getShowingState = (state: { showingManagerState: any }, showingId: string) =>
//     state.showingManagerState.showings.get(showingId);

// export const getShowingDetails = () =>
//     createSelector(getShowingState, (showingState) => showingState?.showingDetails);

export const getShowingMessageBlast = () =>
    createSelector(
        getMyShowingsState,
        (showingState) => showingState.selectedShowing?.listing?.agentListing?.messageBlast,
    );

export const getMyCurrentShowing = () =>
    createSelector(getMyShowingsState, (showingState) => showingState.selectedShowing);
// export const getFeedbackState = (state: { feedback: FeedbackState}) =>
//     state.myShowings;
// export const getHasFeedbackState = createSelector(
//     getFeedbackState,
//     (feedbackState) => feedbackState.hasFeedbackback
// )
export const getMyShowingsStateTest = () =>
    createSelector(getMyShowingsState, (showingState) => showingState);

export const getMyShowingUpdatedErrors = createSelector(
    getMyShowingsState,
    (showingState) => showingState.scheduleShowingErrors,
);

// export enum MY_SHOWINGS_FILTER {
//     None = 'None',
//     NeedsAction = 'Needs Action',
//     Upcoming = 'Upcoming',
//     Leads = 'Showingly Leads',
//     Delegated = 'Delegated',
//     PickUp = 'Picked-Up',
//     ClientShowing = 'Client Showing',
// }

export const getAllShowings = createSelector(getMyShowingsState, (showingState) => {
    return {
        [MY_SHOWINGS_FILTER.None]: showingState[MY_SHOWINGS_FILTER.None],
        [MY_SHOWINGS_FILTER.NeedsAction]: showingState[MY_SHOWINGS_FILTER.NeedsAction],
        [MY_SHOWINGS_FILTER.Upcoming]: showingState[MY_SHOWINGS_FILTER.Upcoming],
        [MY_SHOWINGS_FILTER.Leads]: showingState[MY_SHOWINGS_FILTER.Leads],
        [MY_SHOWINGS_FILTER.Delegated]: showingState[MY_SHOWINGS_FILTER.Delegated],
        [MY_SHOWINGS_FILTER.PickUp]: showingState[MY_SHOWINGS_FILTER.PickUp],
        [MY_SHOWINGS_FILTER.ClientShowing]: showingState[MY_SHOWINGS_FILTER.ClientShowing],
    };
});
