import React, { useState } from 'react';
import classnames from 'classnames';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import appDownloadBar from '../../images/appDownloadBar.svg';
import clostButtonWhite from '../../images/closeButtonWhite.svg';

import styles from './index.module.css';

interface SSOBottombarComponentProps {
    containerStyles?: string;
    sendAppDownloadLink: Function;
    onPressCloseButton: Function;
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SSOBottombar(props: SSOBottombarComponentProps) {
    const { containerStyles, sendAppDownloadLink, onPressCloseButton } = props;
    const root = classnames(styles.rootView, containerStyles);
    const [phone, setPhone] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const normalizeInput = (value: any, previousValue: any) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(
                6,
                10,
            )}`;
        }
    };

    const validateInput = (value: any) => {
        let error = '';

        if (!value) error = 'Phone Number Required!';
        else if (value.length !== 14) error = 'Invalid phone format. ex: (555) 555-5555';

        return error;
    };

    const handleChange = ({ target: { value } }: any) => {
        setPhone(normalizeInput(value, phone));
    };

    const handleClose = (_: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    return (
        <div className={root}>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    severity={validateInput(phone) !== '' ? 'error' : 'success'}
                    onClose={handleClose}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <div className={styles.contentContainer}>
                <div className={classnames(styles.innerContainer, styles.innerLeft)}>
                    <h3 className={styles.overlayTitle}>Welcome back.</h3>
                    <p className={styles.overlayText}>Take your business with you on the go.</p>
                </div>
                <div className={styles.appBarCode}>
                    <img src={appDownloadBar} />
                </div>
                <div className={styles.innerContainer}>
                    <div className={styles.textMeWrapper}>
                        <input
                            className={styles.input}
                            type="text"
                            name="phone"
                            placeholder="(000) 000-0000"
                            value={phone}
                            onChange={handleChange}
                        />
                        <button
                            className={styles.btnTextMeApp}
                            onClick={() => {
                                if (validateInput(phone) !== '') {
                                    setSnackBarMessage(validateInput(phone));
                                    setOpenSnackBar(true);
                                } else {
                                    sendAppDownloadLink(phone);
                                    setSnackBarMessage(
                                        'You will receive a link to download the app shortly',
                                    );
                                    setPhone('');
                                    setOpenSnackBar(true);
                                }
                            }}
                        />
                    </div>
                    <p className={styles.overlayText}>Don't worry, we won't keep texting you.</p>
                </div>
                <button className={styles.closeButton} onClick={() => onPressCloseButton()}>
                    <img src={clostButtonWhite} />
                </button>
            </div>
        </div>
    );
}

SSOBottombar.defaultProps = {
    containerStyles: null,
    sendAppDownloadLink: () => {},
    onPressCloseButton: () => {},
};

export default SSOBottombar;
