import { STATUS } from '../../../utils/constants';
import { BROKERAGE_HUB_ACTIONS } from './actions';

export const InitialState = {
    //Brokerage listings state
    loadingBrokerageListings: false,
    brokerageListings: [],
    toAppendListings: [],
    brokerageListingErrors: [],

    //Brokerage agents state
    loadingBrokerageAgents: false,
    brokerageAgents: [],
    toAppendAgents: [],
    brokerageAgentErrors: [],

    loading: false,
    brokerageData: [],
    brokerageAdmins: [],
    errors: [],
    newContentCreated: false,
    feed: [],
    selectedBrokerage: {},

    // used to tell when we are viewing a social profile from the roster (for back navigation)
    viewingBrokerageRosterProfile: false,
    // used to tell if the feed has been initially fetched or not
    hasFetchedFeed: false,
};

export default function (state = InitialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case BROKERAGE_HUB_ACTIONS.FetchBrokerageListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageListings: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { brokerageListings } = action;
                    return {
                        ...state,
                        brokerageListings: brokerageListings,
                        loadingBrokerageListings: false,
                    };
                }

                case STATUS.Failed: {
                    const { brokerageListingErrors } = action;
                    return {
                        ...state,
                        brokerageListingErrors: brokerageListingErrors,
                        loadingBrokerageListings: false,
                    };
                }

                default:
                    return state;
            }
        }

        case BROKERAGE_HUB_ACTIONS.Create: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        createLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        createLoading: false,
                        newContentCreated: true,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        createLoading: false,
                        errors: errors,
                    };
                }
            }
        }

        case BROKERAGE_HUB_ACTIONS.AppendBrokerageListings: {
            switch (status) {
                case STATUS.Requested: {
                    const { toAppendListings } = action;
                    return {
                        ...state,
                        toAppendListings: toAppendListings,
                        loadingBrokerageListings: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { totalListings } = action;
                    return {
                        ...state,
                        brokerageListings: totalListings,
                        toAppendListings: [],
                        loadingBrokerageListings: false,
                    };
                }

                default:
                    return state;
            }
        }

        case BROKERAGE_HUB_ACTIONS.Feed: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingFeed: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { feed } = action;
                    return {
                        ...state,
                        feed,
                        loadingFeed: false,
                        hasFetchedFeed: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingFeed: false,
                    };
                }
                default:
                    return state;
            }
        }

        case BROKERAGE_HUB_ACTIONS.FetchBrokerageAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageAgents: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { brokerageAgents } = action;
                    return {
                        ...state,
                        brokerageAgents: brokerageAgents,
                        loadingBrokerageAgents: false,
                    };
                }

                case STATUS.Failed: {
                    const { brokerageListingErrors } = action;
                    return {
                        ...state,
                        brokerageListingErrors: brokerageListingErrors,
                        loadingBrokerageAgents: false,
                    };
                }

                default:
                    return state;
            }
        }
        case BROKERAGE_HUB_ACTIONS.ClearBrokerageAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        brokerageAgents: [],
                        brokerageListings: [],
                        feed: [],
                    };
                }
            }
        }
        case BROKERAGE_HUB_ACTIONS.UpdateSelectedBrokerage: {
            switch (status) {
                case STATUS.Requested: {
                    const { selectedBrokerage } = action;
                    return {
                        ...state,
                        selectedBrokerage,
                    };
                }
            }
        }
        case BROKERAGE_HUB_ACTIONS.UpdateFeed: {
            const { content } = action;
            switch (status) {
                case STATUS.Requested: {
                    let newFeed: any = state.feed;
                    newFeed.push(content);
                    return {
                        ...state,
                        feed: newFeed,
                    };
                }
            }
        }

        case BROKERAGE_HUB_ACTIONS.AppendBrokerageAgents: {
            switch (status) {
                case STATUS.Requested: {
                    const { toAppendAgents } = action;
                    return {
                        ...state,
                        toAppendAgents: toAppendAgents,
                        loadingBrokerageAgents: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { totalAgents } = action;
                    return {
                        ...state,
                        brokerageAgents: totalAgents,
                        toAppendAgents: [],
                        loadingBrokerageAgents: false,
                    };
                }

                default:
                    return state;
            }
        }

        case BROKERAGE_HUB_ACTIONS.ResetContentCreated: {
            return {
                ...state,
                newContentCreated: false,
            };
        }

        case BROKERAGE_HUB_ACTIONS.ClearErrors: {
            return {
                ...state,
                errors: [],
            };
        }

        case BROKERAGE_HUB_ACTIONS.UpdateFeedItem: {
            switch (status) {
                case STATUS.Requested: {
                    const { feedData } = action;
                    const setUpdatingFeed = (feed: any) => {
                        const newFeed = feed.map((feedItem: any) => {
                            var newItem = feedItem;
                            if (feedItem?._id?.toString() === feedData?.eventId?.toString()) {
                                if (feedData.type === 'comment') {
                                    newItem.commenting = true;
                                } else if (feedData.type === '') {
                                    newItem.downvoting = true;
                                } else {
                                    newItem.upvoting = true;
                                }
                            } else {
                                newItem = feedItem;
                            }
                            return newItem;
                        });
                        return newFeed;
                    };

                    return {
                        ...state,
                        feed: setUpdatingFeed(state.feed),
                    };
                }
                case STATUS.Succeeded: {
                    const { updatedItem, updateType } = action;
                    const setUpdatingFeed = (feed: any) => {
                        const newFeed = feed.map((feedItem: any) => {
                            var newItem;
                            if (feedItem?._id?.toString() === updatedItem?._id?.toString()) {
                                newItem = updatedItem;
                            } else {
                                newItem = feedItem;
                            }
                            if (updateType === 'comment') {
                                newItem.commenting = false;
                            } else if (updateType === '') {
                                newItem.downvoting = false;
                            } else {
                                newItem.upvoting = false;
                            }
                            return newItem;
                        });
                        return newFeed;
                    };

                    return {
                        ...state,
                        feed: setUpdatingFeed(state.feed),
                    };
                }
                case STATUS.Failed: {
                    const { feedData, errors } = action;
                    const setUpvotingFeed = (feed: any) => {
                        const newFeed = feed.map((feedItem: any) => {
                            var newItem = feedItem;
                            if (feedItem?._id?.toString() === feedData?.eventId?.toString()) {
                                newItem.errors = errors;
                                if (feedData.type === 'comment') {
                                    newItem.commenting = false;
                                } else if (feedData.type === '') {
                                    newItem.downvoting = false;
                                } else {
                                    newItem.upvoting = false;
                                }
                            } else {
                                newItem = feedItem;
                            }
                            return newItem;
                        });
                        return newFeed;
                    };

                    return {
                        ...state,
                        feed: setUpvotingFeed(state.feed),
                    };
                }
                default:
                    return state;
            }
        }

        case BROKERAGE_HUB_ACTIONS.SetViewingRosterProfile: {
            const { viewingRosterProfile } = action;
            return {
                ...state,
                viewingBrokerageRosterProfile: viewingRosterProfile,
            };
        }

        default:
            return state;
    }
}
