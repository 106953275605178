import React, { useState, useEffect } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { Loader, Switch, Typography } from '../../../../components';
import indexTheme from '../../../../theme';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import BlueArrow from '../../../../images/forwardArrow.svg';
import CopyIcon from '../../../../images/copyBlue.svg';
import Stars from '../Stars';
import GreenDoor from '../../../../images/greenDoor.svg';
import OffersIcon from '../../../../images/offersIcon.svg';
import useWindowDimensions from '../../../../utils/common';
import { width } from 'dom7';
import { getUnverifiedLink } from '../../MyListings/utils';
import ListingDetailsComponent from '../../ListingDetailsScreen/ListingDetailsComponent';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connectListingRequested } from '../actions';
import { getConnectLoading } from '../selectors';
import { getSubscriptionTier } from '../../Profile/selectors';
import { isCreatingContent } from '../../Brokerage/selectors';
// import { isLoading } from '../../../../../../showingly-agent-app/srcConsumer/agent/selectors';
interface Props {
    listing: any;
}
const OverviewSection = ({ listing }: Props) => {
    const useStyles = createUseStyles(styles);
    const dispatch = useDispatch();
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const currentPlanTier = useSelector(getSubscriptionTier);
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );

    const { width, height } = useWindowDimensions();
    const [messageSelected, setMessageSelected] = useState(false);
    const loading = useSelector(getConnectLoading);

    const [isAcceptingShowings, updateAcceptingShowings] = useState(true);
    const [isListingSyndication, updateListingSyndication] = useState(true);

    useEffect(() => {
        if (!loading) {
            updateAcceptingShowings(false);
            updateListingSyndication(false);
        }
    }, [loading, isAcceptingShowings, isListingSyndication]);

    // Calculate days on market
    var date1 = new Date(listing?.listDate);
    var date2 = new Date();
    var timeDiff = date2.getTime() - date1.getTime();
    var dayDiff = timeDiff / (1000 * 3600 * 24);
    const history = useHistory();
    //props are needed for size
    const AcceptingShowings = (props: any) => (
        <>
            <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                Accepting Showings
                <span style={{ display: 'flex' }}>
                    {loading && isAcceptingShowings && <Loader size={20} />}
                    <Switch
                        checked={listing?.agentListing?.acceptingShowings}
                        onToggle={() => {
                            updateAcceptingShowings(true);
                            dispatch(
                                connectListingRequested({
                                    ...listing?.agentListing,
                                    acceptingShowings: !listing?.agentListing?.acceptingShowings,
                                }),
                            );
                        }}
                        // containerStyling={styleSheet.mlsIdSwitchStyle}
                    />
                </span>
            </div>
            <span className={styleSheet.subText}>
                We sync your listing with the MLS and if it goes under contract or is sold we will
                change the status. Independently of this, you can enable or disable showings on your
                listing here.
            </span>
        </>
    );
    // props are needed for size
    const ListingSyndication = (props: any) => (
        <>
            <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                Listing Syndication
                <span style={{ display: 'flex' }}>
                    {loading && isListingSyndication && <Loader size={20} />}
                    <Switch
                        checked={listing?.agentListing?.showToUnrepresentedConsumers}
                        onToggle={() => {
                            updateListingSyndication(true);
                            dispatch(
                                connectListingRequested({
                                    ...listing?.agentListing,
                                    showToUnrepresentedConsumers: !listing?.agentListing
                                        ?.showToUnrepresentedConsumers,
                                }),
                            );
                        }}
                        // containerStyling={styleSheet.mlsIdSwitchStyle}
                    />
                </span>
            </div>
            <span className={styleSheet.subText}>
                Allows Unrepresented Consumer to view and schedule on your listing. You can enable
                or disable this option here.
            </span>
        </>
    );

    const ViewSection = ({
        title,
        onClick,
        subTitle,
        large = false, // determine if card is large or not
    }: {
        title: string;
        onClick: Function;
        subTitle: string;
        large?: boolean;
    }) => (
        <>
            <div className={styleSheet.header}>
                {title}
                <ClickableWithFeedback onClick={onClick}>
                    <img src={BlueArrow} />
                </ClickableWithFeedback>
            </div>
            <span className={styleSheet.subText}>{subTitle}</span>
        </>
    );

    const StatSection = ({
        mainStat,
        statTitle,
        comingSoon = false,
    }: {
        mainStat: string | number;
        statTitle: string;
        comingSoon?: boolean;
    }) => (
        <div className={styleSheet.statSection}>
            {comingSoon ? (
                <span className={styleSheet.comingSoonText}>Coming Soon</span>
            ) : (
                <span className={styleSheet.mainStat}>{mainStat}</span>
            )}
            <div className={styleSheet.miniSeparator} />
            <span className={styleSheet.statTitle}>{statTitle}</span>
        </div>
    );

    const RatingsSection = ({ value, title }: { value: number; title: string }) => {
        return (
            <div className={styleSheet.statSection}>
                <div className={styleSheet.starRow}>
                    <Stars value={value} />
                </div>
                <div className={styleSheet.miniSeparator} />
                <span className={styleSheet.statTitle}>{title}</span>
            </div>
        );
    };

    const LinkSection = (props: any) => (
        <div style={{ ...indexTheme.rowStyles, height: '100%' }}>
            <div style={{ marginRight: 70, alignSelf: 'flex-start' }}>
                <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                    {props.title}
                </div>
                {props.comingSoon ? (
                    <span className={styleSheet.comingSoonText}>Coming Soon</span>
                ) : (
                    <span className={styleSheet.subText}>{props.subTitle}</span>
                )}
                {props.link ? (
                    <div
                        onClick={() => navigator.clipboard.writeText(getUnverifiedLink(listing))}
                        className={styleSheet.linkContainer}
                    >
                        <ClickableWithFeedback
                            onClick={() =>
                                navigator.clipboard.writeText(getUnverifiedLink(listing))
                            }
                        >
                            <img src={CopyIcon} />
                        </ClickableWithFeedback>
                        <Typography color={indexTheme.palette.blue} textStyle={'p1'}>
                            {props.link}
                        </Typography>
                    </div>
                ) : null}
            </div>
            <img
                style={{
                    position: 'absolute',
                    right: 20,
                    alignSelf: 'center',
                }}
                src={props.icon}
            />
        </div>
    );

    let overviewPageData = [
        <AcceptingShowings large />,
        <ListingSyndication large />,

        <StatSection
            mainStat={dayDiff ? Math.round(dayDiff) : 'N/A'}
            statTitle={'Days on Market'}
        />,
        <StatSection mainStat={listing?.views?.length ?? 0} statTitle={'Total Listing Views'} />,
        <StatSection
            mainStat={listing?.ratings?.totalShowings ?? 0}
            statTitle={'Total Showings'}
        />,
        <RatingsSection value={listing?.ratings?.overall || 0} title={'Overall Rating'} />,
        <RatingsSection value={listing?.ratings?.priceAccuracy || 0} title={'Price Accuracy'} />,
        <RatingsSection value={listing?.ratings?.location || 0} title={'Location'} />,
        <RatingsSection value={listing?.ratings?.interestLevel || 0} title={'Interest Level'} />,
        currentPlanTier > 0 || agentHasFreeTrial ? (
            <LinkSection
                large
                link={getUnverifiedLink(listing)}
                title={'Allow Non-MLS Members & Professionals to Request Showings'}
                subTitle={
                    'Agents who are members of the MLS can request showings through Showingly. Share this link with Non-MLS members to allow them to request showings as well.'
                }
                icon={GreenDoor}
            />
        ) : (
            ''
        ),
        <ViewSection
            title={'View Message Blasts'}
            onClick={() => {
                setMessageSelected(true);
                history.push('/messageBlast/' + listing?._id);
            }}
            subTitle={
                'Send messages to the buyer agents who have shown your home. Message blasts will send as push notifications, emails, and in-app messaging on the buyer agents’ showing screens.'
            }
            large
        />,
    ];

    // This would be used to reorder the array based on the width of the screen.
    if (width < 1663 && width > 1304) {
        // overviewPageData = [];
    }

    return (
        <div className={styleSheet.root}>
            {/* 
                Treat the cards like widgets with flex wrap, so if one card 
                needs to be removed it doesn't break the whole page layout.
             */}

            {overviewPageData.map((children: any) => (
                <div className={children.props?.large ? styleSheet.largeCard : styleSheet.card}>
                    {children}
                </div>
            ))}
        </div>
    );
};
export default OverviewSection;
