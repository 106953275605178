import { Showing, ShowingStatus, ShowingType } from '../../../utils/constants';
import { scheduleUnverifiedShowingRequested } from '../../unverifiedMain/actions';
import { queueShowingInteractionRequested } from '../QueuedShowings/actions';
import {
    createShowingRequested,
    rescheduleShowingRequested,
    updateShowingStatusRequested,
} from './actions';
import {
    operationType,
    rescheduleDetails,
    selectedDuration,
    selectedTime,
    showingBlock,
    QueuedShowing
} from './types';

export const getTimeWindows = () => {
    var times: any = [];

    for (let i = 7; i < 23; i++) {
        var displayTime = i;
        if (i > 12) {
            displayTime -= 12;
        }
        const meridian = i < 12 ? 'am' : 'pm';
        times.push({
            title: `${displayTime}${meridian}`,
            hours: i,
            minutes: 0,
        });
        times.push({
            title: `${displayTime}:15`,
            hours: i,
            minutes: 15,
        });
        times.push({
            title: `${displayTime}:30`,
            hours: i,
            minutes: 30,
        });
        times.push({
            title: `${displayTime}:45`,
            hours: i,
            minutes: 45,
        });
    }
    return times;
};

export const TIME_HEIGHT_PER_MIN_RATIO = 25 / 15;

export const noticeTime = [
    { label: 'Select a Time', value: -1, default: true },
    { label: '7:00am', value: 700 },
    { label: '7:15am', value: 715 },
    { label: '7:30am', value: 730 },
    { label: '7:45am', value: 745 },
    { label: '8:00am', value: 800 },
    { label: '8:15am', value: 815 },
    { label: '8:30am', value: 830 },
    { label: '8:45am', value: 845 },
    { label: '9:00am', value: 900 },
    { label: '9:15am', value: 915 },
    { label: '9:30am', value: 930 },
    { label: '9:45am', value: 945 },
    { label: '10:00am', value: 1000 },
    { label: '10:15am', value: 1015 },
    { label: '10:30am', value: 1030 },
    { label: '10:45am', value: 1045 },
    { label: '11:00am', value: 1100 },
    { label: '11:15am', value: 1115 },
    { label: '11:30am', value: 1130 },
    { label: '11:45am', value: 1145 },
    { label: '12:00pm', value: 1200 },
    { label: '12:15pm', value: 1215 },
    { label: '12:30pm', value: 1230 },
    { label: '12:45pm', value: 1245 },
    { label: '1:00pm', value: 1300 },
    { label: '1:15pm', value: 1315 },
    { label: '1:30pm', value: 1330 },
    { label: '1:45pm', value: 1345 },
    { label: '2:00pm', value: 1400 },
    { label: '2:15pm', value: 1415 },
    { label: '2:30pm', value: 1430 },
    { label: '2:45pm', value: 1445 },
    { label: '3:00pm', value: 1500 },
    { label: '3:15pm', value: 1515 },
    { label: '3:30pm', value: 1530 },
    { label: '3:45pm', value: 1545 },
    { label: '4:00pm', value: 1600 },
    { label: '4:15pm', value: 1615 },
    { label: '4:30pm', value: 1630 },
    { label: '4:45pm', value: 1645 },
    { label: '5:00pm', value: 1700 },
    { label: '5:15pm', value: 1715 },
    { label: '5:30pm', value: 1730 },
    { label: '5:45pm', value: 1745 },
    { label: '6:00pm', value: 1800 },
    { label: '6:15pm', value: 1815 },
    { label: '6:30pm', value: 1830 },
    { label: '6:45pm', value: 1845 },
    { label: '7:00pm', value: 1900 },
    { label: '7:15pm', value: 1915 },
    { label: '7:30pm', value: 1930 },
    { label: '7:45pm', value: 1945 },
    { label: '8:00pm', value: 2000 },
    { label: '8:15pm', value: 2015 },
    { label: '8:30pm', value: 2030 },
    { label: '8:45pm', value: 2045 },
    { label: '9:00pm', value: 2100 },
    // { label: '9:15pm', value: 2115 },
    // { label: '9:30pm', value: 2130 },
    // { label: '9:45pm', value: 2145 },
];

export const noticeHours = [
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '1', value: 13 },
    { label: '2', value: 14 },
    { label: '3', value: 15 },
    { label: '4', value: 16 },
    { label: '5', value: 17 },
    { label: '6', value: 18 },
    { label: '7', value: 19 },
    { label: '8', value: 20 },
    { label: '9', value: 21 },
];

export const noticeMins = [
    { label: '00', value: 0 },
    { label: '15', value: 15 },
    { label: '30', value: 30 },
    { label: '45', value: 45 },
];

export const noticeMeridian = [
    { label: 'AM', value: 'am' },
    { label: 'PM', value: 'pm' },
];

export const duration: any[] = [
    { label: 'Select a Duration', value: -1, default: true },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '1 hour & 15 minutes', value: 75 },
    { label: '1 hour & 30 minutes', value: 90 },
    { label: '1 hour & 45 minutes', value: 105 },
    { label: '2 hours', value: 120 },
];

/**
 * Showings based on duration of 60 minutes. Screen displays
 * showings at a height of x pixels. This ratio converts minutes
 * to fit x number of pixels
 */
export const MINUTE_TO_PIXEL_RATIO = 100 / 60;

export const EARLIEST_TIME_AVAILABLE_HOURS = 7;

/**
 * Based on the start time selected by the user, find the displacement from
 * the top of the screen that this card should be positioned at
 * @param {selectedTime} Object with hours and minutes fields as integers
 */
export const getShowingStartPosition = (selectedTime: selectedTime) => {
    if (!selectedTime?.hours?.value) {
        return 0;
    }

    const showingStartInMinutes =
        selectedTime.hours.value * 60 + (selectedTime.minutes?.value || 0);

    const diffDayStartMinutes = EARLIEST_TIME_AVAILABLE_HOURS * 60;

    return (showingStartInMinutes - diffDayStartMinutes) * MINUTE_TO_PIXEL_RATIO;
};

/**
 * Based on the start time of the time restriction, find the displacement from
 * the top of the screen that this card should be positioned at
 * @param {selectedTime}
 */
export const getTimeRestrictionStartPosition = (selectedTime: any) => {
    if (!selectedTime?.hours) {
        return 0;
    }

    const showingStartInMinutes = selectedTime.hours * 60 + (selectedTime.minutes || 0);

    const diffDayStartMinutes = EARLIEST_TIME_AVAILABLE_HOURS * 60;

    return (showingStartInMinutes - diffDayStartMinutes) * MINUTE_TO_PIXEL_RATIO;
};

/**
 * Get the duration of a showing card given the start time and end time
 * in integer hours/minute value format TYPE: SelectedTime
 */
export const getDuration = (time: showingBlock) => {
    const startMin: number = time.start.hours * 60 + time.start.minutes;
    const endMin: number = time.end.hours * 60 + time.end.minutes;
    const duration = endMin - startMin;
    return duration;
};

/**
 * Get the restrictions for a given date
 *
 * @param {agentListing} listing.agentListing
 *
 * @returns {restrictedTimes}<array>
 {
            start: {hours: number, minutes: number},
            end: {hours, number},
            date: JS Date
        }

 * NOTE: SKIP TO THE END OF THE FUNCTION. This function is broken down into various sub
 functions. Each sub function is segmented to its own section to make the
 code easier to read.
 * @param date
 * @param agentListing
 * @param showingsOnListing
 * @param opType
 */
export const getTimeRestrictionsForSingularDate = (
    date: any,
    agentListing: any,
    showingsOnListing: any,
    opType?: any
) => {
    const { blockedTimes, noticeRequired } = agentListing || {};

    // current day being looked at. set time to midnight for start of day check
    var scheduleDate: any = date;
    scheduleDate.setHours(0, 0, 0, 0);

    var showingsStartDate = agentListing?.showingsStartDate;
    showingsStartDate?.setHours(0, 0, 0, 0);

    const now: any = new Date();
    var restrictedTimes: any = [];

    //#region function-breakdown

    const blockOutDayCompletely = () => {
        restrictedTimes.push({
            start: {
                hours: 7,
                minutes: 0,
            },
            end: {
                hours: 23,
                minutes: 0,
            },
            type: 'blockOutDayCompletely',
        });
    };

    // If date is today, create start block from morning up until now
    const createMorningUntilNowBlock = () => {
        if (scheduleDate.getDate() === now.getDate()) {
            var endHours = now.getHours();
            var endMinutes = now.getMinutes();
            endMinutes = Math.ceil(now.getMinutes() / 15) * 15;
            if (endMinutes >= 60) {
                endHours++;
                endMinutes -= 60;
            }

            restrictedTimes.push({
                start: {
                    hours: 7,
                    minutes: 0,
                },
                end: {
                    hours: endHours,
                    minutes: endMinutes,
                },
                type: 'morningUntilNow',
            });
        }
    };

    // If current day is within the notice required, block up until most available time
    const createNoticeRequiredBlock = () => {
        if (noticeRequired) {
            var firstAvailableTime = new Date();
            firstAvailableTime.setMinutes(firstAvailableTime.getMinutes() + noticeRequired);
            // if current day is within the notice required window, generate a blocked time
            if (scheduleDate <= firstAvailableTime) {
                var availHours = firstAvailableTime.getHours();
                var availMinutes = firstAvailableTime.getMinutes();
                if (availMinutes > 60) {
                    availHours++;
                    availMinutes -= 60;
                }
                availMinutes = Math.ceil(availMinutes / 15) * 15;

                // if first available date isn't on the day being looked at, block out rest of day
                var deadlineToday = date;
                deadlineToday.setHours(23, 0, 0, 0); // 11pm local
                if (firstAvailableTime >= deadlineToday) {
                    blockOutDayCompletely();
                    return;
                }
                // block out from now up until notice required period ends
                restrictedTimes.push({
                    start: {
                        hours: now.getHours(),
                        minutes: Math.ceil(now.getMinutes() / 15) * 15, // NOTE: might need to subtract 60 if on dot
                    },
                    end: {
                        hours: availHours,
                        minutes: availMinutes,
                    },
                    type: 'noticeRequired',
                });
            }
        }
    };

    // Block any times on day if individual or recurring restrictions apply to the day selected
    const createListingRestrictionBlocks = () => {
        if (!blockedTimes?.length) return;
        blockedTimes.map((e: any) => {
            var pushRestriction = false;
            var start = e.startTime;
            if (typeof start === 'string') {
                start = new Date(start);
            }
            var end = e.endTime;
            if (typeof end === 'string') {
                end = new Date(end);
            }
            if (!e.recurring) {
                // OP FOR INDIVIDUAL RESTRICTIONS
                // check if restriction applies to date being looked at
                if (
                    scheduleDate.getDate() === start.getDate() &&
                    scheduleDate.getMonth() === start.getMonth() &&
                    scheduleDate.getFullYear() === start.getFullYear()
                ) {
                    pushRestriction = true;
                }
            } else {
                // OP FOR RECURRING RESTRICTIONS
                var startDate =
                    typeof e.recurringDetail.startDate === 'string'
                        ? new Date(e.recurringDetail.startDate)
                        : e.recurringDetail.startDate;
                var endDate =
                    typeof e.recurringDetail.endDate === 'string'
                        ? new Date(e.recurringDetail.endDate)
                        : e.recurringDetail.endDate;
                if (startDate <= scheduleDate && scheduleDate <= endDate) {
                    // current day selected within restriction range
                    if (e.recurringDetail.days.indexOf(scheduleDate.getDay()) > -1) {
                        /**
                         * current day exists in list of weekdays restriction
                         * applies to. =====> 0=sunday, 6=saturday
                         */
                        pushRestriction = true;
                    }
                }
            }

            if (pushRestriction) {
                var h = start.getHours();
                var hEnd = end.getHours();
                var m = Math.ceil(start.getMinutes() / 15) * 15;
                var mEnd = Math.ceil(end.getMinutes() / 15) * 15;
                if (m >= 60) {
                    h++;
                    m -= 60;
                }
                if (mEnd >= 60) {
                    hEnd++;
                    mEnd -= 60;
                }
                restrictedTimes.push({
                    start: {
                        hours: h,
                        minutes: m,
                    },
                    end: {
                        hours: hEnd,
                        minutes: mEnd,
                    },
                    type: 'listingRestriction',
                });
            }
        });
    };

    // Block any times on day if showing exists and double booking is false
    const createShowingsOnListingBlocks = () => {
        if (agentListing.allowOverlap || !showingsOnListing?.length) return;
        showingsOnListing.map((e: Showing) => {
            // check if showing is on the day being looked at
            if (
                scheduleDate.getDate() === e.start.getDate() &&
                scheduleDate.getMonth() === e.start.getMonth() &&
                scheduleDate.getFullYear() === e.start.getFullYear()
            ) {
                // block showing time on day being looked at
                restrictedTimes.push({
                    start: {
                        hours: e.start.getHours(),
                        minutes: Math.ceil(e.start.getMinutes() / 15) * 15,
                    },
                    end: {
                        hours: e.end.getHours(),
                        minutes: Math.ceil(e.end.getMinutes() / 15) * 15,
                    },
                    type: 'showingAtThisTime',
                });
            }
        });
    };

    // If listing is not connected, block out day up until 8am and after 9pm
    const createStartEndDayBlocks = () => {
        restrictedTimes.push({
            start: {
                hours: 7,
                minutes: 0,
            },
            end: {
                hours: 8,
                minutes: 0,
            },
            type: 'notConnectedStartEnd',
        });
        restrictedTimes.push({
            start: {
                hours: 21,
                minutes: 0,
            },
            end: {
                hours: 23,
                minutes: 0,
            },
            type: 'notConnectedStartEnd',
        });
    };

    //#endregion function-breakdown end

    // OPS ==========================================================================
    // if(agentListing?.listingId.showingsStartDate)
    if (scheduleDate < showingsStartDate) {
        blockOutDayCompletely();
    }
    // If date is today, create start block from morning up until now
    createMorningUntilNowBlock();
    // If current day is within the notice required, block up until most available time
    createNoticeRequiredBlock();
    if (agentListing?.listingId) {
        /**
         * Get all reoccurring restrictions that apply to the given date
         * Get all of the individual restrictions that apply to the given date
         */
        createListingRestrictionBlocks();
        // If double booking is false, block out all showing on the day selected 
        if (opType !== 'Reschedule') {
            createShowingsOnListingBlocks();
        }
    } else {
        // If listing is not connected, block out day up until 8am and after 9pm
        createStartEndDayBlocks();
    }

    // OPS ==========================================================================

    return restrictedTimes || [];
};

/**
 * Format the agent's schedule for display on screen
 *
 * @param {date} Any selected by the user
 * @param {agentSchedule} array of showingRequest documents of the agent's
 * current schedule. NOTE: These must best sorted by start date from earliest to latest
 */
export const getAgentScheduleForSingularDate = (date: any, agentSchedule: any) => {
    var schedule: any = [];
    if (!agentSchedule?.length) {
        return schedule;
    }

    var scheduleDate: any = date;
    scheduleDate.setHours(0, 0, 0, 0);

    agentSchedule.map((e: Showing) => {
        if (
            scheduleDate.getDate() === e.start.getDate() &&
            scheduleDate.getMonth() === e.start.getMonth() &&
            scheduleDate.getFullYear() === e.start.getFullYear()
        ) {
            var set = false;
            // block showing time on day being looked at
            schedule.map(
                (
                    s: {
                        showings: any;
                        startRAW: Date;
                        endRAW: Date;
                        start: any;
                        end: any;
                    },
                    index: number,
                ) => {
                    // check if next showing belongs in schedule block
                    if (e.start >= s.startRAW && e.start <= s.endRAW) {
                        const greaterEndTime = e.end > s.endRAW ? e.end : s.endRAW;
                        schedule[index] = {
                            showings: [...s.showings, e],
                            startRAW: s.startRAW,
                            endRAW: greaterEndTime,
                            start: {
                                hours: s.startRAW.getHours(),
                                minutes: Math.ceil(s.startRAW.getMinutes() / 15) * 15,
                            },
                            end: {
                                hours: greaterEndTime.getHours(),
                                minutes: Math.ceil(greaterEndTime.getMinutes() / 15) * 15,
                            },
                        };
                        set = true;
                    }
                },
            );
            if (!set) {
                schedule.push({
                    showings: [e],
                    startRAW: e.start,
                    endRAW: e.end,
                    start: {
                        hours: e.start.getHours(),
                        minutes: Math.ceil(e.start.getMinutes() / 15) * 15,
                    },
                    end: {
                        hours: e.end.getHours(),
                        minutes: Math.ceil(e.end.getMinutes() / 15) * 15,
                    },
                });
            }
        }
    });

    // TODO: Sort and Group showings together

    return schedule;
};

/**
 * Format the agent's showings queue for display on screen
 *
 * @param {date} Date selected by the user
 * @param {agentShowingQueue} array of showingQueue documents of the agent's
 * current schedule. NOTE: this function was based on getAgentScheduleForSingularDate function
 */
export const getAgentQueuedShowingsForSingularDate = (date: any, agentShowingQueue: any) => {
    var schedule: any = [];
    if (!agentShowingQueue?.length) {
        return schedule;
    }

    var scheduleDate: any = date;
    scheduleDate.setHours(0, 0, 0, 0);

    agentShowingQueue.map((e: QueuedShowing) => {
        if (
            e.startDate &&
            scheduleDate.getDate() === e.startDate.getDate() &&
            scheduleDate.getMonth() === e.startDate.getMonth() &&
            scheduleDate.getFullYear() === e.startDate.getFullYear()
        ) {

            var set = false;
            // block showing in queue time on day being looked at

            schedule.map(
                (
                    s: {
                        count: any;
                        startRAW: Date;
                        endRAW: Date;
                        start: any;
                        end: any;
                    },
                    index: number,
                ) => {
                    // check if next queued showing belongs in schedule block
                    if (e.startDate >= s.startRAW && e.startDate <= s.endRAW) {
                        const greaterEndTime = e.endDate > s.endRAW ? e.endDate : s.endRAW;
                        schedule[index] = {
                            count: s.count + 1,
                            startRAW: s.startRAW,
                            endRAW: greaterEndTime,
                            start: {
                                hours: s.startRAW.getHours(),
                                minutes: Math.ceil(s.startRAW.getMinutes() / 15) * 15,
                            },
                            end: {
                                hours: greaterEndTime.getHours(),
                                minutes: Math.ceil(greaterEndTime.getMinutes() / 15) * 15,
                            },
                        };
                        set = true;
                    }
                },
            );

            if (!set) {
                schedule.push({
                    count: 1,
                    startRAW: e.startDate,
                    endRAW: e.endDate,
                    start: {
                        hours: e.startDate.getHours(),
                        minutes: Math.ceil(e.startDate.getMinutes() / 15) * 15,
                    },
                    end: {
                        hours: e.endDate.getHours(),
                        minutes: Math.ceil(e.endDate.getMinutes() / 15) * 15,
                    },
                });
            }
        }
    });

    return schedule;
};

/**
 * Check if the schedule button is to be disabled for the day that
 * the user is trying to schedule a showing for
 *
 * @params {blockedTimes} array created from { getTimeRestrictionsForSingularDate }
 * @param startTime
 * @param selectedDuration
 * @param blockedTimes
 * @returns {boolean} true if schedule button is disabled, false if it is enabled
 */
export const getShowingButtonDisabled = (
    startTime: selectedTime,
    selectedDuration: selectedDuration,
    blockedTimes: showingBlock[] | null,
) => {
    if (!blockedTimes?.length) return false;

    var disabled = false;

    const startMinute = startTime.hours.value * 60 + startTime.minutes.value;
    const thisShowing = {
        startMinute,
        endMinute: startMinute + (selectedDuration.value > -1 ? selectedDuration.value : 15),
    };

    for (let i = 0; i < blockedTimes.length; i++) {
        const blockedShowingInMinutes = {
            startMinute: blockedTimes[i].start.hours * 60 + blockedTimes[i].start.minutes,
            endMinute: blockedTimes[i].end.hours * 60 + blockedTimes[i].end.minutes,
        };
        if (
            thisShowing.endMinute > blockedShowingInMinutes.startMinute &&
            thisShowing.startMinute <= blockedShowingInMinutes.startMinute
        ) {
            disabled = true;
            break;
        } else if (
            thisShowing.endMinute > blockedShowingInMinutes.startMinute &&
            thisShowing.startMinute < blockedShowingInMinutes.endMinute
        ) {
            disabled = true;
            break;
        }
    }
    return disabled;
};

/**
 * Format the blockedTimes array to group together so the cards on the calendar
 * view look better
 *
 * @param {blockedTimes} array created from {getTimeRestrictionsForSingularDate}
 * @returns array in same format as blockedTimes
 */
export const groupTimeRestrictionsForCalendarDisplay = (blockedTimes: any) => {
    // TODO: Sort and Group blocked Times together
    return blockedTimes;
};

/**
 * Given the listing, get format available duration options given the max duration
 * @param {listing} listing document
 */
export const getDurationOptions = (listing: any) => {
    var availDurations: any[] = []; // selectedDuration
    if (listing?.agentListing?.listingId && listing?.agentListing?.maxDuration) {
        const maxDuration = listing.agentListing.maxDuration;
        duration.map((e: selectedDuration) => {
            if (e.value <= maxDuration) {
                availDurations.push(e);
            }
        });
    } else {
        return duration;
    }
    return availDurations;
};

/**
 * Get the default selected start time if user is within scheduling hours
 */
export const getDefaultSelectedTime = () => {
    const now: Date = new Date();
    if (7 <= now.getHours() && now.getHours() <= 23) {
        var min = Math.ceil(now.getMinutes() / 15) * 15;
        var hours = now.getHours();
        if (min >= 60) {
            min = 0;
            hours++;
        }
        const hoursLabel = hours > 12 ? hours - 12 : hours;
        return {
            hours: { label: hoursLabel.toString(), value: hours },
            minutes: {
                label: min ? min.toString() : '00',
                value: min,
            },
            meridian: {
                label: hours < 12 ? 'AM' : 'PM',
                value: hours < 12 ? 'am' : 'pm',
            },
            default: true,
        };
    }
    return {
        hours: { label: '8', value: 8 },
        minutes: { label: '30', value: 30 },
        meridian: { label: 'AM', value: 'am' },
        default: true,
    };
};

/**
 * Get the default selected start time RAW
 */
export const getDefaultSelectedTimeRAW = () => {
    const now: Date = new Date();
    if (7 <= now.getHours() && now.getHours() <= 23) {
        var min = Math.ceil(now.getMinutes() / 15) * 15;
        var hours = now.getHours();
        if (min >= 60) {
            min = 0;
            hours++;
        }
        const timeKey = hours * 100 + min;

        const hoursLabel = hours > 12 ? hours - 12 : hours;

        return {
            label: `${hoursLabel}:${min ? min.toString() : '00'}${hours < 12 ? 'am' : 'pm'}`,
            value: timeKey,
        };
    }
    return {
        label: '8:00am',
        value: 800,
    };
};

/**
 * Get the text details to be displayed and flex on the bottom buttons
 *
 * @param {opType} operation type being performed on the screen
 * @param {isConnectedListing} boolean for whether or not this is a connected listing
 * @returns
 */
export const getBottomButtonText = (opType: operationType, isConnectedListing: boolean) => {
    switch (opType) {
        case 'Schedule':
            return {
                left: {
                    text: isConnectedListing ? 'Schedule Showing' : 'Log Showing',
                    flex: 2,
                },
                right: { text: 'Queue', flex: 1 },
            };
        case 'Reschedule':
            return {
                left: { text: 'Update Showing', flex: 1 },
                right: { text: 'Cancel Showing', flex: 1 },
            };
        case 'Queue':
            return {
                left: { text: 'Select Time', flex: 2 },
                right: { text: 'Remove', flex: 1 },
            };
        default:
            return { left: { text: 'Schedule' }, right: { text: 'Queue' } };
    }
};

/**
 * Get operation to be performed when hitting the bottom left button
 * on the schedule screen
 */
export const getLeftButtonOperation = (
    opType: any,
    listing: any,
    selectedTime: selectedTime,
    selectedDuration: selectedDuration,
    selectedDate: Date,
    client: any,
    rescheduleDetails?: any,
    callback?: Function,
) => {
    // Intentionally assign value not reference
    var startTime = new Date(selectedDate?.toString());
    startTime.setHours(selectedTime.hours.value, selectedTime.minutes.value, 0, 0);
    // Intentionally assign value not reference
    var endTime = new Date(startTime?.toString());
    endTime.setMinutes(endTime.getMinutes() + selectedDuration.value);

    switch (opType) {
        case 'Schedule':
            return createShowingRequested(
                ShowingType.Agent, // showingType
                listing?._id, // listingId: string,
                startTime, // startTime: Date,
                endTime, // endTime: Date,
                client?.stitchUserId || null, // clientId: string = null,
                null, // delegationPayment: any = null,
            );
        case 'UnverifiedSchedule':
            return scheduleUnverifiedShowingRequested(listing?._id, startTime, endTime);
        case 'Reschedule':
            return rescheduleShowingRequested(
                ShowingType.Agent,
                rescheduleDetails.passedShowingId,
                startTime,
                endTime,
                client?.stitchUserId || null,
                null,
            );
        case 'Queue':
            const minutesUtcOffset = new Date().getTimezoneOffset();
            const utcOffset = Math.floor(minutesUtcOffset / 60);
            return queueShowingInteractionRequested(
                'update',
                {
                    listing,
                    showingType: ShowingType.Agent,
                    listingId: listing?._id,
                    startDate: startTime,
                    endDate: endTime,
                    clientId: client?._id || listing.clientId || null,
                    utcOffset,
                    delegationPrice: null,
                }, // queued showing
                listing.showingQueueIndex,
                callback, // TODO
            );
        default:
            return null;
    }
};

/**
 * Get operation to be performed when hitting the bottom right button
 * on the schedule screen
 */
export const getRightButtonOperation = (
    opType: any,
    listing: any,
    selectedTime: selectedTime,
    selectedDuration: selectedDuration,
    selectedDate: Date,
    client: any,
    rescheduleDetails?: any,
    op?: Function,
) => {
    // Intentionally assign value not reference
    var startTime = new Date(selectedDate?.toString());
    startTime.setHours(selectedTime.hours.value, selectedTime.minutes.value, 0, 0);
    // Intentionally assign value not reference
    var endTime = new Date(startTime?.toString());
    endTime.setMinutes(endTime.getMinutes() + selectedDuration.value);

    switch (opType) {
        case 'Schedule':
            break;
        case 'Reschedule':
            return updateShowingStatusRequested(
                rescheduleDetails?.passedShowingId,
                ShowingStatus.Cancelled,
                () => op,
            );
        case 'Queue':
            return queueShowingInteractionRequested('remove', null, listing?.showingQueueIndex, op);
        default:
            return null;
    }
};

/**
 * Get operation to be performed after action has succeeded
 * from hitting the bottom left button
 */
export const getLeftOpCallback = (
    opType: operationType,
    history?: any,
    ShowingId?: any,
    op?: Function,
) => {
    switch (opType) {
        case 'Schedule':
            return history.replace(`/showingDetails/${ShowingId[1].toString()}`);
        case 'Update': // intentional fallthrough
        case 'Reschedule':
            return () => {
                op && op();
            };
        case 'Queue':
        default:
            return null;
    }
};

/**
 * The mobile time selector separates hours and minutes, whereas the web time
 * selector doesn't (based on the designs). All of the logic for this screen was
 * originally built gased on the mobile time selector logic.
 *
 * This takes a given web time value and converts it to the mobile format to
 * ensure that web and mobile have the exact same functional logic throughout
 * the schedule flow.
 *
 * @param {e} string or number in format of time in hundreds => i.e. 9:45pm is 2145
 */
export const webToMobileTimeConverter = (e: string | number) => {
    const val: number = typeof e === 'string' ? parseInt(e) : e;
    if (e === -1) {
        return getDefaultSelectedTime();
    }
    const hours: number = parseInt((val / 100).toFixed(0));
    const minutes: number = parseInt((((val / 10) % 10) * 10).toFixed(0));
    var time: selectedTime = {
        hours: { label: hours.toString(), value: hours },
        minutes: { label: minutes ? minutes.toString() : '00', value: minutes },
        meridian: {
            label: e < 1200 ? 'pm' : 'pm',
            value: e < 1200 ? 'am' : 'pm',
        },
    };
    return time;
};

// used to declare border width of the unavailable slot card
export const UNAVAILABLE_SLOT_BORDER_WIDTH = 2;
