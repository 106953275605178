import * as Actions from './actions';

export const initialState = {
    listings: [],
    selectedListing: {},
    configurationDetails: {
        listing: {},
        // DO NOT MERGE IF THIS IS NOT 1. May be changed for testing purposes
        pageNumber: 1,
    },
    agents: [],
    consumers: [],
    textSearchLoading: false,
    addClientLoading: false,
    selectedView: 'Overview',
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case Actions.FETCH_MY_LISTINGS_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case Actions.FETCH_MY_LISTINGS_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                listings: action.listings,
            };
        }
        case Actions.FETCH_MY_LISTINGS_FAILED: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            };
        }
        case Actions.FETCH_SELECTED_LISTING_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case Actions.SET_SELECTED_LISTING: {
            return {
                ...state,
                loading: false,
                selectedListing: action.listing,
            };
        }
        case Actions.SET_LISTING_CONFIG: {
            return {
                ...state,
                configurationDetails: {
                    listing: action.listing,
                    pageNumber: action.pageNumber,
                },
            };
        }
        case Actions.TEXT_SEARCH_AGENTS_REQUESTED: {
            return {
                ...state,
                textSearchLoading: true,
            };
        }
        case Actions.TEXT_SEARCH_AGENTS_SUCCEEDED: {
            return {
                ...state,
                agents: action.agents,
                textSearchLoading: false,
            };
        }
        case Actions.TEXT_SEARCH_AGENTS_FAILED: {
            return {
                ...state,
                errors: action.errors,
                textSearchLoading: false,
            };
        }
        case Actions.TEXT_SEARCH_CONSUMERS_REQUESTED: {
            return {
                ...state,
                textSearchLoading: true,
            };
        }
        case Actions.TEXT_SEARCH_CONSUMERS_SUCCEEDED: {
            return {
                ...state,
                textSearchLoading: false,
                consumers: action.consumers,
            };
        }
        case Actions.TEXT_SEARCH_CONSUMERS_FAILED: {
            return {
                ...state,
                textSearchLoading: false,
                errors: action.errors,
            };
        }
        case Actions.CONNECT_LISTING_REQUESTED: {
            return {
                ...state,
                connectLoading: true,
            };
        }
        case Actions.CONNECT_LISTING_SUCCEEDED: {
            return {
                ...state,
                connectLoading: false,
                connectListingSucceeded: true,
            };
        }
        case Actions.CONNECT_LISTING_FAILED: {
            return {
                ...state,
                connectLoading: false,
                connectionErrors: action.errors,
            };
        }
        case Actions.RESET_VALUES: {
            return {
                ...state,
                configurationDetails: {
                    listing: {},
                    pageNumber: 1,
                },
                connectListingSucceeded: false,
                connectionErrors: null,
                newClient: null,
            };
        }
        case Actions.ADD_CLIENT_REQUESTED: {
            return {
                ...state,
                addClientLoading: true,
            };
        }
        case Actions.ADD_CLIENT_SUCCEEDED: {
            return {
                ...state,
                addClientLoading: false,
                newClient: action.newClient,
            };
        }
        case Actions.ADD_CLIENT_FAILED: {
            return {
                ...state,
                addClientLoading: false,
                errors: action.errors,
            };
        }
        case Actions.STORE_LOCKBOX_PHOTO_SUCCEEDED: {
            return {
                ...state,
                lockboxImage: action.newContentData,
            };
        }
        case Actions.CHANGE_SELECTED_VIEW: {
            return{
                ...state,
                selectedView: action.view,
            }
        }
        default:
            return state;
    }
}
