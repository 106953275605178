import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import ReactCodeInput from 'react-verification-code-input';
import { BSON } from 'realm-web';
import { useHistory } from 'react-router';

import { Button, Loader, SearchBar } from 'web-lib';
import { getAuthErrors, getMarkets, getEntryAgents } from '../auth/selectors';
import {
    clearAuthErrors,
    fetchAgentDocumentsRequested,
    getMarketRequested,
    registrationRequested,
    sendVerificationMessageRequested,
    updatePendingUser,
    verifyCodeRequested,
    clearVerification,
} from '../auth/actions';

import styles from './index.module.css';
import agentLogo from '../../images/agentFullWordmarkGray.svg';
import check from '../../images/checkmark.svg';
import people from '../../images/people.svg';
import waves from '../../images/waves.svg';
import LeftArrow from '../../images/leftArrow.svg';
import { Switch } from '../../components';

export type Market = {
    _id: BSON.ObjectId;
    name: string;
    agentMlsId?: string;
    marketName?: string;
    displayMlsName?: string;
    marketObjectId?: BSON.ObjectId;
    mlsName?: string;
    brokerageId?: string;
};

type Agent = {
    _id: BSON.ObjectId;
    firstName: string;
    lastName: string;
    email: string;
    markets: Array<Market>;
    phoneNumber: string;
    status: string;
    stitchUserId: string;
    signUpCompleted?: boolean;
};

enum CurrentFrame {
    IdentityConfirmation = 'IdentityConfirmation',
    SelectVerificationMethod = 'SelectVerificationMethod',
    CodeVerification = 'CodeVerification',
    Support = 'Support',
    FinalConfirmation = 'FinalConfirmation',
}

const SignUpScreen = (props: {}) => {
    const agents: Agent[] = useSelector(getEntryAgents);
    const errors: string[] = useSelector(getAuthErrors);
    const markets: Market[] = useSelector(getMarkets);
    const creatingUser: boolean = useSelector((state: any) => state.auth.creatingUser);
    const newUserVerified: boolean = useSelector((state: any) => state.auth.newUserVerified);
    const verifyingCode: boolean = useSelector((state: any) => state.auth.verifyingCode);

    const dispatch = useDispatch();

    const clearErrors: Function = () => dispatch(clearAuthErrors());
    const fetchAgents: Function = (
        searchText: string,
        marketName: string,
        searchByAgentId?: boolean | null,
    ) => dispatch(fetchAgentDocumentsRequested(searchText, marketName, searchByAgentId));
    const fetchMarkets: Function = () => dispatch(getMarketRequested());
    const registerUser: Function = (
        userEmail: string,
        password: string,
        userObjectId: BSON.ObjectId,
    ) => dispatch(registrationRequested(userEmail, password, userObjectId));
    const sendVerificationMessage: Function = (method: 'email' | 'phone', reciever: string) =>
        dispatch(sendVerificationMessageRequested(method, reciever, false));
    const updatePendingUser: Function = (pendingUser: Agent) =>
        dispatch(updatePendingUser(pendingUser));
    const verifyCode: Function = (method: 'email' | 'phone', code: any) =>
        dispatch(verifyCodeRequested(method, code));
    const clearVerification: Function = () => dispatch(clearVerification());

    const [currentFrame, setCurrentFrame] = useState<CurrentFrame>(
        CurrentFrame.IdentityConfirmation,
    );
    const [marketName, setMarketName] = useState<string>('');
    const [agentSearchText, setAgentSearchText] = useState<string>('');
    const [selectedAgentId, setSelectedAgentId] = useState<BSON.ObjectId>();
    const [pendingUser, setPendingUser] = useState<Agent>();
    const [selectedVerification, setSelectedVerification] = useState<'email' | 'phone'>();
    const [passwordCheck, setPasswordCheck] = useState({
        password1: '',
        password2: '',
    });
    const [termsAndConditions, setTermsAndConditions] = useState({
        error: false,
        hasRead: false,
        render: false,
    });

    const [agentIdSearch, setAgentIdSearch] = useState(false);

    const history = useHistory();

    useEffect(() => {
        fetchMarkets();
        return () => {
            clearVerification();
        };
    }, []);

    useEffect(() => {
        // set selected market to first market
        if (markets?.length) {
            setMarketName(markets[0]?.name);
        }
    }, [markets]);

    useEffect(() => {
        if (agentSearchText.length > 0) {
            fetchAgents(agentSearchText, marketName, agentIdSearch);
        }
    }, [agentSearchText, agentIdSearch]);

    useEffect(() => {
        if (agents.length) {
            setSelectedAgentId(agents[0]._id);
        }
    }, [agents]);

    useEffect(() => {
        if (newUserVerified) {
            setCurrentFrame(CurrentFrame.FinalConfirmation);
        }
    }, [newUserVerified]);

    //if the user is not a logged agent, show them to the login modal
    useEffect(() => {
        if (currentFrame === CurrentFrame.FinalConfirmation) {
            if (pendingUser?.status && pendingUser?.status !== 'logged') {
                history.push('/login');
            }
        }
    }, [currentFrame]);

    // ensures that if the user presses back page in the middle of authentication, they are brought back to the first auth modal
    useEffect(() => {
        return history.listen((location: any) => {
            if (location.pathname === '/landing') {
                history.push('/auth');
            }
        });
    }, []);

    const authErrors = useSelector(getAuthErrors);

    const passwordsMatch = () =>
        passwordCheck.password1.length >= 6 &&
        passwordCheck.password1.length <= 20 &&
        passwordCheck.password1 === passwordCheck.password2;

    // if the user's password is greater than 20 characters, it's a special use case and we do NOT want them to verify via SMS
    // if the user's email is null, gray that button out
    const canVerifySMS = () => pendingUser && pendingUser?.phoneNumber?.length < 20;
    const canVerifyEmail = () => pendingUser && pendingUser?.email !== null;

    const authStyles = (
        <div>
            <div className={styles.logoContainer}>
                <img className={styles.logoImage} src={agentLogo} />
            </div>
            <Row>
                <Col>
                    <div className={styles.peopleStyle}>
                        <img src={people} />
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={styles.waveStyle}>
                    <img src={waves} />
                </div>
            </Row>
        </div>
    );

    return (
        <div className={styles.root}>
            {authStyles}
            <div className={styles.agentSignupContainer}>
                {currentFrame === CurrentFrame.IdentityConfirmation && (
                    <div className={styles.agentSearchContainer}>
                        <div className={styles.searchForMarket}>
                            <h2 style={{ paddingTop: '25px' }}>Where do you practice?</h2>
                            <select
                                name="market"
                                className={styles.dropdown}
                                onChange={(e) => setMarketName(e.target.value)}
                            >
                                {markets?.length > 0 ? (
                                    markets
                                        .sort((a: Market, b: Market) => (a.name > b.name ? 1 : -1))
                                        .map((market: Market) => (
                                            <option key={market._id.toString()} value={market.name}>
                                                {market.name}
                                            </option>
                                        ))
                                ) : (
                                    <option key={'loading'}>Loading...</option>
                                )}
                            </select>
                        </div>
                        <div className={styles.searchForAgent}>
                            <h2>Search for yourself in the MLS</h2>

                            {/* TODO: replace with SearchableDropdown component from weblib once approved*/}
                            <SearchBar
                                className={styles.searchForMarketBar}
                                onSubmit={(text: string) => {
                                    setAgentSearchText(text);
                                }}
                            />
                            <div className={styles.mlsIdSearchContainer}>
                                <Switch
                                    checked={agentIdSearch}
                                    onToggle={() => {
                                        // onSearch(activeSearchText, !mlsIdSearch);
                                        setAgentIdSearch(!agentIdSearch);
                                    }}
                                    containerStyling={styles.mlsIdSwitchStyle}
                                />
                                <span className={styles.mlsIdSearchText}>
                                    Search By Agent Mls Id
                                </span>
                            </div>
                            <select
                                id="agentSelect"
                                name="agents"
                                className={styles.dropdown}
                                onChange={(e) =>
                                    setSelectedAgentId(new BSON.ObjectId(e.target.value))
                                }
                            >
                                {agents.length > 0 ? (
                                    agents.map((agent: Agent) => {
                                        if (agent?.status !== 'hidden') {
                                            return (
                                                <option
                                                    className={styles.agentNames}
                                                    key={agent._id.toString()}
                                                    value={agent._id?.toHexString()}
                                                >
                                                    {agent.firstName} {agent.lastName},{' '}
                                                    {agent.markets[0].agentMlsId}
                                                </option>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <option key={'search'}>Search for Agents above</option>
                                )}
                            </select>
                            {/* <form className={styles.agentFindDD}>
								<label>
									<input 
										type="text" 
										name="name"
										placeholder="Search"
										onChange={() => {
											
										}}
									/>
								</label>
							</form> */}
                        </div>
                        <Button
                            buttonText={'Confirm'}
                            className={styles.confirmButton}
                            onClick={() => {
                                sessionStorage.clear();
                                // localStorage.clear();
                                if (!marketName || !selectedAgentId) return;

                                const agent = agents.find(
                                    (agent: Agent) =>
                                        agent._id.toString() === selectedAgentId.toString(),
                                );
                                updatePendingUser(agent);
                                setPendingUser(agent);
                                setCurrentFrame(CurrentFrame.SelectVerificationMethod);
                            }}
                        />
                    </div>
                )}

                {currentFrame === CurrentFrame.SelectVerificationMethod && (
                    <div className={styles.verificationButtonContainer}>
                        <div
                            className={styles.backContainer}
                            onClick={() => setCurrentFrame(CurrentFrame.IdentityConfirmation)}
                        >
                            <img src={LeftArrow} className={styles.leftIcon} />
                            <p className={styles.backText}>Back</p>
                        </div>
                        <h2>Choose a verification method:</h2>

                        {/* Currently if you try to sign up and your email is an empty string, it breaks. So the temporary solution to to prompt contacting support */}
                        {pendingUser?.email?.length ? (
                            <>
                                {' '}
                                <Button
                                    buttonText={'SMS'}
                                    className={
                                        canVerifySMS()
                                            ? styles.confirmButton
                                            : styles.disabledButton
                                    }
                                    disabled={!canVerifySMS()}
                                    onClick={() => {
                                        setCurrentFrame(CurrentFrame.CodeVerification);
                                        setSelectedVerification('phone');
                                        sendVerificationMessage('phone', pendingUser?.phoneNumber);
                                    }}
                                />
                                <Button
                                    buttonText={'Email'}
                                    className={
                                        canVerifyEmail()
                                            ? styles.confirmButton
                                            : styles.disabledButton
                                    }
                                    disabled={!canVerifyEmail()}
                                    onClick={() => {
                                        setCurrentFrame(CurrentFrame.CodeVerification);
                                        setSelectedVerification('email');
                                        sendVerificationMessage('email', pendingUser?.email);
                                    }}
                                />
                                <div className={styles.noteContainer}>
                                    <p>
                                        *Note: Verification codes will be sent to the phone number
                                        or email provided by the MLS. If your information has
                                        changed, please contact support for manual verification
                                    </p>
                                    <Button
                                        buttonText={'Support'}
                                        className={styles.supportButton}
                                        onClick={() => setCurrentFrame(CurrentFrame.Support)}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.noteContainer}>
                                <p>
                                    Looks like you don't have any verification methods. Please
                                    contact support to manually verify.
                                </p>
                                <Button
                                    buttonText={'Support'}
                                    className={styles.supportButton}
                                    onClick={() => setCurrentFrame(CurrentFrame.Support)}
                                />
                            </div>
                        )}
                    </div>
                )}

                {currentFrame === CurrentFrame.CodeVerification && (
                    <div className={styles.codeInputContainer}>
                        <h2>What's the code?</h2>
                        <p>
                            Most codes send instantly, if you do not receive your code within 1
                            minute, please try again or contact support.
                        </p>
                        <ReactCodeInput
                            autoFocus={true}
                            className={styles.codeInputBar}
                            fields={6}
                            loading={verifyingCode}
                            onComplete={(e: any) => {
                                verifyCode(selectedVerification, e);
                            }}
                        />
                        <div className={styles.errorsContainer}>
                            {errors.length ? (
                                <>
                                    {errors.map((error: string) => (
                                        <p key={error} className={styles.errorText}>
                                            {error}
                                        </p>
                                    ))}
                                    <p className={styles.supportText}>
                                        *If this seems like a mistake or you keep getting the same
                                        errors, reach out to our support team for assistance!
                                    </p>
                                </>
                            ) : (
                                ''
                            )}
                            <div className={styles.codeVerificationButtons}>
                                <Button
                                    buttonText={'Cancel'}
                                    className={styles.codeVerificationCancelButton}
                                    onClick={() => {
                                        clearErrors();
                                        setCurrentFrame(CurrentFrame.IdentityConfirmation);
                                    }}
                                />
                                <Button
                                    buttonText={'Support'}
                                    className={styles.codeVerificationSupportButton}
                                    onClick={() => setCurrentFrame(CurrentFrame.Support)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {currentFrame === CurrentFrame.Support && (
                    <div className={styles.supportPage}>
                        <h2>Need some help?</h2>
                        <p>Reach out to our Showingly support team!</p>
                        <p>
                            Email us at{' '}
                            <a className={styles.emailTag} href="mailto:support@getshowingly.com">
                                support@getshowingly.com
                            </a>
                        </p>
                        <p>Or Call us at (833)217-7578</p>
                        <Button
                            buttonText={'Cancel'}
                            className={styles.codeVerificationCancelButton}
                            onClick={() => {
                                clearErrors();
                                setCurrentFrame(CurrentFrame.IdentityConfirmation);
                            }}
                        />
                    </div>
                )}

                {currentFrame === CurrentFrame.FinalConfirmation && (
                    <div className={styles.finalConfirmation}>
                        <h2>
                            {!pendingUser?.signUpCompleted
                                ? `You're almost done, ${pendingUser?.firstName}!`
                                : `Please reset your password:`}
                        </h2>

                        {creatingUser ? (
                            <div className={styles.creatingUserIcon}>
                                <Loader
                                    icon={
                                        'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'
                                    }
                                />
                            </div>
                        ) : (
                            <>
                                <div className={styles.passwordInputContainers}>
                                    <input
                                        className={styles.passwordInput}
                                        onChange={(e: any) =>
                                            setPasswordCheck({
                                                ...passwordCheck,
                                                password1: e.target.value,
                                            })
                                        }
                                        placeholder={'Password'}
                                        type="password"
                                        value={passwordCheck.password1}
                                    />
                                    <input
                                        className={styles.passwordInput}
                                        onChange={(e: any) =>
                                            setPasswordCheck({
                                                ...passwordCheck,
                                                password2: e.target.value,
                                            })
                                        }
                                        placeholder={'Confirm Password'}
                                        type="password"
                                        value={passwordCheck.password2}
                                    />
                                    <p className={styles.passwordMessage}>
                                        *Passwords must be between 6 and 20 characters
                                    </p>
                                </div>

                                {!pendingUser?.signUpCompleted && (
                                    <div className={styles.checkdiv}>
                                        <div
                                            className={styles.checkbox}
                                            onClick={() => {
                                                setTermsAndConditions({
                                                    ...termsAndConditions,
                                                    hasRead: !termsAndConditions.hasRead,
                                                });
                                            }}
                                        >
                                            {termsAndConditions.hasRead && (
                                                <img className={styles.checkmark} src={check} />
                                            )}
                                        </div>

                                        <p>I have read the</p>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://s3.amazonaws.com/showingly.com-assets/Terms+Of+Use.pdf"
                                        >
                                            <p className={styles.tacText}>terms & conditions</p>
                                        </a>
                                    </div>
                                )}
                            </>
                        )}

                        {!pendingUser?.signUpCompleted ? (
                            <Button
                                buttonText={'Confirm'}
                                disabled={!passwordsMatch() || !termsAndConditions.hasRead}
                                className={
                                    passwordsMatch() && termsAndConditions.hasRead
                                        ? styles.passwordConfirmButton
                                        : styles.passwordConfirmButton_disabled
                                }
                                onClick={() => {
                                    registerUser(
                                        pendingUser?.email,
                                        passwordCheck.password1,
                                        new BSON.ObjectId(pendingUser?._id),
                                    );
                                }}
                            />
                        ) : (
                            <Button
                                buttonText={'Reset'}
                                className={
                                    passwordsMatch()
                                        ? styles.passwordConfirmButton
                                        : styles.passwordConfirmButton_disabled
                                }
                                onClick={() => {
                                    registerUser(
                                        pendingUser?.email,
                                        passwordCheck.password1,
                                        new BSON.ObjectId(pendingUser?._id),
                                    );
                                }}
                            />
                        )}
                        {authErrors?.length !== 0 && (
                            <div style={{ color: 'red' }}>{authErrors[0]?.error}</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignUpScreen;
