export default (theme: any) => {
    return {
        root: {},
        paginator: {
            bottom: 20,
            position: 'absolute',
            margin: 'auto',
            width: '100%',
        },
    };
};
