import { createSelector } from 'reselect';
import { transformActiveListings } from './transforms';

export const getActiveListingsState = (state: any) => state.searchListings || {};

export const getShowingIsBeingScheduled = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.loading,
);

export const getSearchedMlsId = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.searchedMlsId,
);

export const getActiveListings = createSelector(getActiveListingsState, (activeListingState) => {
    const activeListings = activeListingState.activeListings;
    return transformActiveListings(activeListings);
});

export const getActiveRawListings = createSelector(getActiveListingsState, (activeListingState) => {
    return activeListingState.activeListings;
});

export const getListingRetrievalLoading = createSelector(
    getActiveListingsState,
    (activeListingState) => {
        return activeListingState.fetchListingLoading;
    },
);

export const getSelectedListing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.selectedListing,
);

export const didCreateNewShowing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.showingSuccessfullyCreated,
);

export const getNewlyCreatedShowing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.newShowing,
);

export const getShowingErrors = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.errors,
);

export const getShowingsOnListing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.showingsOnListing,
);

export const getShowingsOnListingLoading = createSelector(
    getActiveListingsState,
    (activeListingState) => {
        return activeListingState.showingsOnListingLoading;
    },
);

export const getStateShowings = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.showings,
);

export const getStateListings = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.listings,
);

export const getSSOListing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.ssoListing,
);

export const getSSOListingLoading = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.ssoListingLoading,
);

export const getSSOUnconnectedListingLoading = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.ssoUnconnectedListingLoading,
);

export const getSSOUnconnectedListingLink = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.ssoUnconnectedListingLink,
);

export const isSSOUnconnectedListing = createSelector(
    getActiveListingsState,
    (activeListingState) => activeListingState.isSsoUnconnectedListing,
);
