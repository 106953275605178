import { STATUS } from '../../../store/api/constants';
import { FilterOptionType } from './FilterDrawer/Utils';
import { MapListingObject, Region } from './types';

export enum MAP_ACTIONS {
    FetchMapListings = '[Map] Fetch Listings',
}

export const fetchMapListingsRequested = (
    region: Region,
    activeListings: Array<MapListingObject> = [],
    filter: FilterOptionType,
) => {
    return {
        type: MAP_ACTIONS.FetchMapListings,
        status: STATUS.Requested,
        region,
        activeListings,
        filter,
    };
};

export const fetchMapListingsSucceeded = (listings: Array<MapListingObject>) => {
    return {
        type: MAP_ACTIONS.FetchMapListings,
        status: STATUS.Succeeded,
        listings,
    };
};

export const fetchMapListingsFailed = (errors: any[] = []) => {
    return {
        type: MAP_ACTIONS.FetchMapListings,
        status: STATUS.Failed,
        errors,
    };
};
