import { BSON } from 'realm-web';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    upsertAgentRecord,
} from '../../../store/api/sagas';
import { AGENTS, SHOWING_REQUESTS } from '../../../store/api/constants';
import { getStitchUser } from '../../../utils';
import { STATUS } from '../../../utils/constants';
import { getAgentObject } from '../../auth/selectors';

import * as Actions from './actions';

function* fetchNotifications({}: ReturnType<typeof Actions.fetchNotificationsRequested>): Generator<
    any,
    any,
    any
> {
    try {
        const user = yield select(getStitchUser);
        const agentObj = yield select(getAgentObject);
        const agent = yield call(findRecord, AGENTS, {
            stitchUserId: agentObj.stitchUserId,
        });

        const { notifications = null } = agent;

        if (notifications) {
            yield put(Actions.fetchNotificationsSucceeded(notifications));
            for (var i = 0; i < notifications.length; i++) {
                if (!notifications[i].seen) {
                    notifications[i].seen = true;
                }
            }
            yield call(upsertAgentRecord, user, {
                notifications: notifications,
            });
        } else {
            yield put(Actions.fetchNotificationsSucceeded([]));
        }
    } catch (error) {
        yield put(Actions.fetchNotificationsFailed(error));
    }
}

function* fetchPendingShowings({}: ReturnType<
    typeof Actions.fetchPendingShowingsRequested
>): Generator<any, any, any> {
    try {
        const agentObj = yield select(getAgentObject);
        const pendingShowingsQuery = {
            $or: [
                {
                    $and: [{ status: 'requested' }, { agentId: agentObj?._id }],
                },
                {
                    $and: [
                        { status: 'pending_internal' },
                        {
                            'listing.agentListing.approvalSettings.approvalType': 'agent',
                        },
                        {
                            'listing.agentListing.agents': {
                                $elemMatch: { _id: agentObj?._id },
                            },
                        },
                    ],
                },
            ],
        };
        const pendingShowings = yield call(findRecords, SHOWING_REQUESTS, pendingShowingsQuery);
        yield put(Actions.fetchPendingShowingsSucceeded(pendingShowings));
    } catch (error) {
        yield put(Actions.fetchPendingShowingsFailed(error));
    }
}

export default function* () {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.NOTIFICATION_ACTION.FetchNotifications &&
                action.status === STATUS.Requested,
            fetchNotifications,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.NOTIFICATION_ACTION.FetchPendingShowings &&
                action.status === STATUS.Requested,
            fetchPendingShowings,
        ),
    ]);
}
