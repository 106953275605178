import React from 'react';

import BackArrowIcon from '../../../../../images/backArrow.svg';

import styles from './index.module.css';

interface BackArrowProps {
    rootStyle?: string; // prop to style the container for the backArrow and the name
    onClick: Function; // onClick function handler
    arrowIcon?: string; // back arrow icon to display
    arrowStyle?: string; // prop for styling of the arrow icon
    placeHolder: string; // text of word to display next to back arrow
    placeHolderStyle?: string; // prop to style the word next to the back arrow
    width?: string; // width of backArrow container
}

const BackArrow = ({
    rootStyle = styles.root,
    onClick = () => {},
    arrowIcon = BackArrowIcon,
    arrowStyle = styles.arrowStyle,
    placeHolder,
    placeHolderStyle = styles.placeHolderStyle,
    width = '150px',
}: BackArrowProps) => {
    return (
        <div className={rootStyle} style={{ width: width }} onClick={() => onClick()}>
            <img src={arrowIcon} className={arrowStyle} />
            <h1 className={placeHolderStyle}>{placeHolder}</h1>
        </div>
    );
};

export default BackArrow;
