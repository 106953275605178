

export default (theme: any) => {
    const borderStyles = {
        borderBottom: { color: theme.palette.grey, style: 'solid', width: 1 },
    };
    
    const CALENDAR_SECTION_WIDTH = (width: number) => width <= 1024 ? width * 0.25 : width <= 1310 ? width * 0.28 : width <= 1484 ? width * 0.30 : width *0.34
    const HEADER_PADDING = 48
    const HEADER_PADDING_SMALL = 11
    const borderRight = {
        borderRight: {
            style: 'solid',
            color: theme.palette.grey,
            width: 1,
        },
    };
    return {
        root: ({ height }: any) => ({
            height: height - 60,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'hidden',
            // minWidth: 595 + 457,
        }),
        selectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        pageHeader: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(23),
            ...theme.paddingHorizontal(HEADER_PADDING),
            ...borderStyles,
            display: 'flex',
            justifyContent: 'space-between',
           

        },
        miniPageHeader: ({width}: any) => {
            return {
            backgroundColor: theme.palette.white,
            ...theme.paddingHorizontal(HEADER_PADDING),
            ...borderStyles,
            justifyContent: 'space-between',
            display: 'flex',
            height: 80,
            alignItems: 'center',
            }
        },
        calendarSection: ({ width }: any) => {
            return {
                backgroundColor: theme.palette.background,
                // - 3 accounts for the border
                width: CALENDAR_SECTION_WIDTH(width) - 3,
                ...borderRight,
                position: 'relative',
                minWidth: 257,
            };
        },
        scrollView: ({ height }: any) => ({
            height: height - 210,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
           
        }),
        calScrollView: ({ height, width }: any) => ({
            height: height - 210,
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'row',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            position: 'absolute',
            width: CALENDAR_SECTION_WIDTH(width) - 63,
            paddingTop: 30,
            marginLeft: 30,
        }),
        showingsSection: ({ width }: any) => {
            return {
                backgroundColor: theme.palette.background,
                //200 is the offset from the sidebar.
                width: width - CALENDAR_SECTION_WIDTH(width) - 200,
                minWidth: 595,
                borderLeft: {
                    width: 1,
                    style: 'solid',
                    color: theme.palette.grey,
                },
            };
        },
        paginatorContainer: ({ width }: any) => {
            return {
                backgroundColor: 'white',
                width: width - CALENDAR_SECTION_WIDTH(width) - 200,
                minWidth: 595,
                position: 'fixed',
                bottom: 0,
            };
        },
        filterButtonContainer: {
            display: 'flex',
        },
        filterOption: {
            backgroundColor: theme.palette.background,
            marginRight: 16,
            ...theme.paddingHorizontal(10),
            ...theme.paddingVertical(5),
            borderRadius: 5,
            display: "flex",
            alignItems: "center"
        },
        timeLinesContainer: ({ height }: any) => ({
            flexDirection: 'column',
            justifyContent: 'flex-start',
            display: 'flex',
            height: 1390,
        }),
        timeLines: {
            width: 85,
            height: 80,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            display: 'flex',
        },
        otherLines: {
            position: 'absolute',
            marginTop: 14,
            height: 80,
            width: 85,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        topRow: {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        topRowText: {
            ...theme.typography.bold,
            color: theme.palette.grey,
            fontSize: 14,
            paddingTop: 3,
            paddingBottom: 2,
        },
        timeLine: {
            width: 37,
            height: 2,
            borderRadius: 1,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#e9e9e9',
        },
        tinyLine: {
            width: 5,
            height: 2,
            borderRadius: 2,
            backgroundColor: '#d3d3d3',
            // marginTop: 10,
        },
        noLine: {
            width: 5,
            height: 0,
            borderRadius: 2,
            backgroundColor: '#f6f6f6',
        },
        smallLine: {
            width: 10,
            height: 2,
            borderRadius: 2,
            backgroundColor: '#d3d3d3',
            // marginTop: 18,
        },
        bottomNoLine: {
            width: 5,
            height: 1,
            borderRadius: 2,
            // marginTop: 18,
        },
        theGridAndLines: {
            flex: 1,
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: 1405,
        },
        lines: {
            height: 12,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        line: {
            height: 5,
            width: 2,
            borderRadius: 1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#e9e9e9',
        },
        theGrid: {
            height: 1360,
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            borderTop: '2px solid #e9e9e9',
        },
        gridColumn: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        gridBox: {
            flex: 1,
            borderBottom: '2px solid #e9e9e9',
            // borderRight: '2px solid #e9e9e9',
        },
        calendarShowingCard: {
            width: 460,
            backgroundColor: theme.palette.white,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            left: 48,
            borderRadius: 2,
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
            userSelect: 'none',
        },
        calendarShowingCardStatusBar: {
            backgroundColor: theme.palette.blue,
            borderTopLeftRadius: 1,
            borderBottomLeftRadius: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: 4,
        },
        littleClockIcon: {
            width: 15,
            height: 15,
            paddingLeft: 10,
            paddingRight: 5,
        },
        filterCard: {
            display: 'flex',
            justifyContent: 'center',
            ...theme.paddingVertical(10),
            borderBottom: {
                width: 1,
                style: 'solid',
                color: theme.palette.separatorGrey,
            },
            width: '80%',
            alignSelf: 'center',
        },
        feedbackDetailRow: {
            ...theme.marginHorizontal(50),
            ...theme.paddingVertical(14),
            borderBottom: '1px solid ' + theme.palette.separatorGrey,
            display: 'flex',
            alignItems: 'center',
        },
        feedbackDetailColumn: { flexDirection: 'column', display: 'flex', flex: 2 },
        feedbackSeparator: {
            height: 1,
            ...theme.marginHorizontal(7),
            borderBottom: '2px dotted ' + theme.palette.grey,
            flex: 1,
        },
        iconRow: {
            display: 'flex',
        },
    };
};
