export default (theme: any) => {
    return {
        disabledOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: 30,
            width: 30,
            backgroundColor: '#fff',
            opacity: 0.5,
        },
        icon: {
            height: 30,
            width: 30,
        },
    };
};
