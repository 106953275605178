import { createSelector } from 'reselect';

export const getListingDetailsState = (state: any) => state.listingDetails;

export const getCurrentListing = createSelector(
    getListingDetailsState,
    (listingDetailsState) => listingDetailsState.listing,
);

export const getShowingSuccess = createSelector(
    getListingDetailsState,
    (listingDetailsState) => listingDetailsState.showingSuccess,
);

export const getListingType = createSelector(
    getListingDetailsState,
    (listingDetailsState) => listingDetailsState.listingType,
);
export const getMessageBlastRecipients = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.messageBlastRecipients,
);
export const getBlastMessageSending = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.blastMessageSending
)
export const isRecipientLoading = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.recipientsLoading,
);
export const isRecipientLoaded = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.recipientLoaded
);

export const recipientError = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.recipientError,
);
export const messageBlastError = createSelector(
    getListingDetailsState,
    (myListingsState) => myListingsState.messageBlastErrors,
);

