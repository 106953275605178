import { blue } from '@material-ui/core/colors';
import { autoType } from 'd3-dsv';

export default (theme: any) => {
    return {
        buttonRow: {
            width: 'fit-content',
            margin: 'auto',
            ...theme.paddingHorizontal(47),
            ...theme.paddingVertical(15),
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        loader: {
            margin: 'auto',
            width: 'fit-content',
        },
        notificationsContainer: {
            ...theme.marginHorizontal(38),
            overflowY: 'scroll',
        },
        doesNotHaveApp: {
            height: 125,
            position: 'fixed',
            bottom: 0,
            width: 637,
            boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.1)',
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            ...theme.marginHorizontal(50),
            margin: 'auto',
            height: '100%',
        },
        qrCode: {
            width: 84,
            height: 84,
        },
        appText: {
            fontFamily: 'Poppins',
            fontSize: 18,
            height: 'fit-content',
            marginLeft: 15,
        },
        noNotifications: {
            fontSize: 30,
            color: theme.palette.mediumGrey,
            textAlign: 'center',
        },
    };
};
