import { createSelector } from 'reselect';
import { plans } from '../../../components/UpgradeSubscriptionModal/utils';
import DefaultUserPhoto from '../../../images/defaultUser.svg';

export const getProfileState = (state: any) => state.profile;

export const isProfileLoading = createSelector(
    getProfileState,
    (profileState) => profileState.loading,
);

export const getProfileData = createSelector(
    getProfileState,
    (profileState) => profileState.profileData,
);

export const getFormattedMarkets = createSelector(
    getProfileState,
    (profileState) => profileState.formattedMarkets,
);

export const getUserMarkets = createSelector(
    getProfileState,
    (profileState) => profileState.profileData?.markets,
);

export const getUserMarketName = createSelector(getProfileState, (profileState) => {
    return profileState.profileData?.markets
        ? profileState.profileData?.markets[0]?.marketName //Preferred is 0 for now
        : '';
});

export const getStripeActionStatus = createSelector(
    getProfileState,
    (profileState) => profileState.stripeActionStatus,
);
export const isPaymentLoading = createSelector(
    getProfileState,
    (profileState) => profileState.paymentLoading,
);

export const getCustomerStripeId = createSelector(
    getProfileState,
    (profileState) => profileState.profileData?.customerStripeId,
);
export const getPrices = createSelector(getProfileState, (profileState) => ({
    proPrice: profileState.proPrice,
    execPrice: profileState.execPrice,
}));

export const getSubscribedPrice = createSelector(
    getProfileState,
    (profileState) => profileState.currentlySubscribedPriceId,
);

export const getAgentMlsId = createSelector(
    getProfileState,
    (profileState) =>
        profileState.profileData.markets.length
            ? profileState.profileData.markets[0].agentMlsId
            : null, //Preferred is 0 for now
);

export const getBrokerageData = createSelector(
    getProfileState,
    (profileState) => profileState.brokerageData,
);

export const getBrokerages = createSelector(
    getProfileState,
    (profileState) => profileState.profileData.brokerages || [],
);

export const getBadges = createSelector(
    getProfileState,
    (profileState) => profileState.profileData.badges,
);

export const getAgentUserName = createSelector(getProfileState, (profileState) => ({
    firstName: profileState.profileData.firstName,
    lastName: profileState.profileData.lastName,
    full: profileState.profileData.firstName + ' ' + profileState.profileData.lastName,
}));

export const loadingProfile = createSelector(
    getProfileState,
    (profileState) => profileState?.loading,
);

export const getPaymentError = createSelector(
    getProfileState,
    (profileState) => profileState?.paymentError,
);

export const getUploadingPhoto = createSelector(
    getProfileState,
    (profileState) => profileState?.uploadingPhoto,
);

export const getUserProfileUpdateSuccessful = createSelector(
    getProfileState,
    (profileState: any) => {
        return profileState?.aboutUpdateSuccess && profileState?.uploadingPhotoSuccessful;
    },
);

export const getProfilePhoto = createSelector(getProfileState, (profileState) => {
    if (profileState?.updatedProfileData?.profilePhotoUpload?.uri) {
        return profileState?.updatedProfileData?.profilePhotoUpload?.uri;
    } else if (profileState?.profileData?.profilePhotoUpload?.uri) {
        return profileState?.profileData?.profilePhotoUpload?.uri;
    } else {
        return DefaultUserPhoto;
    }
});

export const getAgentId = createSelector(
    getProfileState,
    (profileState) => profileState.profileData._id,
);

export const didRemoveCreditCard = createSelector(
    getProfileState,
    (profileState) => profileState.removedPayment,
);

export const getUpdatedPaymentOptions = createSelector(
    getProfileState,
    (profileState) => profileState.updatedPaymentMethods?.data,
);

export const removingPayment = createSelector(
    getProfileState,
    (profileState) => profileState.loadingRemovePayment,
);

export const getPaymentModalType = createSelector(
    getProfileState,
    (profileState) => profileState.paymentModalType,
);
export const getSubscriptionPriceId = createSelector(
    getProfileState,
    (profileState) => profileState.subscriptionPriceId,
);
export const getIsSubscribed = createSelector(
    getProfileState,
    (profileState) =>
        profileState.subscription?.status &&
        !(
            profileState.subscription?.status !== 'cancelled' ||
            profileState.subscription?.status !== 'delinquent'
        ),
);

export const getCancelling = createSelector(
    getProfileState,
    (profileState) => profileState.cancellingSubscription,
);

export const getPaymentMethods = createSelector(
    getProfileState,
    (profileState) => profileState.paymentMethods,
);
export const getErrors = createSelector(getProfileState, (profileState) => profileState.errors);

export const getVerificationState = (state: any) => getProfileState(state).verification;

export const getIsVerifyingCode = createSelector(
    getVerificationState,
    (verificationState) => verificationState.verifyingCode,
);

export const getIsPhoneNumberVerified = createSelector(
    getVerificationState,
    (verificationState) => verificationState.newPhoneNumberVerified,
);

export const getIsUpdatePhoneNumberSuccessful = createSelector(
    getVerificationState,
    (verificationState) => verificationState.updatePhoneNumberSuccess,
);

export const getVerificationErrors = createSelector(
    getVerificationState,
    (verificationState) => verificationState.errors,
);

export const getIsUpdatingPhoneNumber = createSelector(
    getVerificationState,
    (verificationState) => verificationState.updatingPhoneNumber,
);

export const getLinkedAgentSuccess = createSelector(
    getProfileState,
    (profileState) => profileState.linkAgentSuccess,
);

export const codeVerificationSuccessful = createSelector(
    getProfileState,
    (profileState) => profileState.verifyCode.succeeded,
);

export const getVerifyingCode = createSelector(
    getProfileState,
    (ProfileState) => ProfileState.verifyCode.loading,
);

export const getMarkets = createSelector(
    getProfileState,
    (profileState) => profileState.markets.data,
);

export const loadingMarkets = createSelector(
    getProfileState,
    (profileState) => profileState.markets.loading,
);

export const getAgents = createSelector(
    getProfileState,
    (profileState) => profileState.agents.data,
);

export const loadingAgents = createSelector(
    getProfileState,
    (profileState) => profileState.agents.loading,
);

export const failedToSendCode = createSelector(
    getProfileState,
    (profileState) => profileState.sendCode.error,
);

export const failedToVerifyCode = createSelector(
    getProfileState,
    (profileState) => profileState.verifyCode.error,
);

export const getMyBrokerages = createSelector(
    getProfileState,
    (profileState) => profileState.myBrokerages,
);

export const getSubscriptionPaymentMethod = createSelector(
    getProfileState,
    (profileState) => profileState.subscriptionPaymentMethod,
);

export const getBankAccounts = createSelector(
    getProfileState,
    (profileState) => profileState.bankAccounts,
);

export const getSettingsLoading = createSelector(
    getProfileState,
    (profileState) => profileState.settingsLoading,
);

export const getStripeLink = createSelector(
    getProfileState,
    (profileState) => profileState.stripeLink,
);

export const getStripeOAuthLink = createSelector(
    getProfileState,
    (profileState) => profileState.stripeOAuthLink,
);

export const getSubscriptionEndDate = createSelector(
    getProfileState,
    (profileState) => profileState.subscriptionEndDate,
);

export const getSubscription = createSelector(
    getProfileState,
    (profileState) => profileState.subscription,
);

export const getShowingsSettings = createSelector(
    getProfileState,
    (profileState) => profileState.showingsSettings,
);

export const getPickUpSettings = createSelector(
    getProfileState,
    (profileState) => profileState.pickUpSettings,
);

export const getIsPickupTutorialComplete = createSelector(
    getProfileState,
    (profileState) => profileState.isPickupTutorialComplete,
);

export const getIsDelinquent = createSelector(
    getProfileState,
    (profileState) => profileState.isDelinquent,
);

export const getSocialSettings = createSelector(
    getProfileState,
    (profileState) => profileState.socialSettings,
);

export const getAboutUpdateLoading = createSelector(
    getProfileState,
    (profileState: any) => profileState.aboutUpdateLoading,
);

export const subModalVisible = createSelector(
    getProfileState,
    (profileState: any) => profileState.upgradeModalVisible,
);

/**
 * get the current plan of subscription the agent has
 * @returns {plan}  an object representing the plan name, tier value, price and description.
 */
export const getSubscriptionPlan = createSelector(getProfileState, (profileState) => {
    const subPlanType = profileState.subscription?.plan;

    // returns a plan object if found or null if not
    return (subPlanType && plans.find((pl) => subPlanType.includes(pl.title))) || null;
});

export const getSubscriptionTier = createSelector(getProfileState, (profileState) => {
    const subPlanType = profileState.subscription?.plan;

    let subPlan: any = null;
    plans.map((pl) => {
        const { title } = pl;
        if (subPlanType === title) subPlan = pl;
    });
    return subPlan?.tier || 0;
});

