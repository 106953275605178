const height = window.innerHeight;

export default (theme: any) => {
    const contentWidth = 632;
    return {
        root: {
            height: height - 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            userSelect: 'none',
            backgroundColor: theme.palette.background,
        },
        contentBox: {
            height: 488,
            width: contentWidth,
            backgroundColor: theme.palette.white,
            borderRadius: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        topBox: {
            padding: 48,
            width: contentWidth - 96,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        midBox: {
            padding: 48,
            paddingTop: 50,
            paddingBottom: 20,
            height: 132,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            flexDirection: 'column',
            width: contentWidth - 96,
        },
        midBoxRelative: {
            padding: 48,
            paddingTop: 50,
            paddingBottom: 20,
            height: 132,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            flexDirection: 'column',
            width: contentWidth - 96,
            position: 'relative',
        },
        bottomBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingBottom: 1,
        },
        searchBar: (props: any) => ({
            height: 35,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            width: props?.width ?? 238,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: 5,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        inputText: {
            fontSize: 18,
            ...theme.typography.medium,
            color: theme.palette.black,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            padding: 0,
        },
    };
};
