import React from 'react';
import { BSON } from 'realm-web';
import { Button } from 'web-lib';
import { useHistory } from 'react-router';

import { SocialItem } from '../../utils/constants';
import { minutesToTimeDisplay } from '../../utils/common/dateTimeUtils';
import { numberWithCommas } from '../../utils/common/index';
import { Photo } from '../../components';

import styles from './index.module.css';
import CommentIcon from '../../images/feed/comment.svg';
import DefaultImage from '../../images/defaultProfile.svg';
import HeartIcon from '../../images/feed/heartEmpty.svg';
import HeartIconFilled from '../../images/feed/heartFull.svg';
import BathIcon from '../../images/feed/bathBold.svg';
import BedIcon from '../../images/feed/bedBold.svg';
import SqftIcon from '../../images/feed/sqFootBold.svg';

// display text aligned next to an icon properly
const textWithIcon = (text: string, icon: string) => (
    <div className={styles.itemWithIcon}>
        <img src={icon} alt={text} className={styles.itemIcon} />
        <span className={styles.itemText}>{text}</span>
    </div>
);

// data that displays all items relating to a listing, used for types listing and soldListing
const listingData = (item: SocialItem, isSoldListing: boolean, setSelectedAgent: Function) => {
    const addressStr = `${item.content.extraData?.listing?.address.city}, ${item.content.extraData?.listing?.address.state} ${item.content.extraData?.listing?.address.zip}`;

    return (
        <div className={styles.listingContainer}>
            <span>{isSoldListing ? 'Sold a listing:' : 'Added a new listing:'}</span>
            <div className={styles.listingContent}>
                {isSoldListing ? (
                    <div className={styles.soldListingContainer}>
                        <img
                            src={item?.content?.image?.uri}
                            alt={'listingImage'}
                            className={styles.soldListingImage}
                        />
                        <div className={styles.centerContainer}>
                            <span className={styles.soldForText}>
                                ${numberWithCommas(item.content?.extraData?.listing?.price || 0)}
                            </span>
                        </div>
                    </div>
                ) : (
                    <img
                        src={item?.content?.image?.uri}
                        alt={'listingImage'}
                        className={styles.listingImage}
                    />
                )}
                <div className={styles.listingInfo}>
                    {!isSoldListing && (
                        <span className={styles.listingPrice}>
                            ${numberWithCommas(item.content?.extraData?.listing?.price || 0)}
                        </span>
                    )}
                    <span className={styles.listingAddress}>
                        {item.content.extraData?.listing?.address.full}
                    </span>
                    <span className={styles.listingAddressFull}>{addressStr}</span>

                    <div
                        className={styles.listingStats}
                        style={{ marginTop: isSoldListing ? '30px' : '' }}
                    >
                        {textWithIcon(`${item.content.extraData?.listing?.beds || 0}`, BedIcon)}
                        {textWithIcon(`${item.content.extraData?.listing?.baths || 0}`, BathIcon)}
                        {textWithIcon(
                            `${item.content.extraData?.listing?.area || 0} sqft.`,
                            SqftIcon,
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface SocialCardProps {
    agentId: BSON.ObjectId;
    item: SocialItem;
    renderIcon?: boolean; // show comment/like icons or not
    setSelectedAgent?: Function; // Handles onClick of an agents name/photo to take them to their profile picture
    setSelectedItem: Function; // item to be commented on
    updateFeedItem: Function; // like/comment on a post
}

// the single items displayed inside of the feed
const SocialCard = ({
    agentId,
    item,
    renderIcon = true,
    setSelectedAgent = () => {},
    setSelectedItem,
    updateFeedItem,
}: SocialCardProps) => {
    const history = useHistory();
    const authorName = item.content.title;
    const otherName = `${item.content.extraData?.receivingAgentSocialDocument?.firstName} ${item.content.extraData?.receivingAgentSocialDocument?.lastName}`;
    const userHasLikedPost = item.upvotes.hasOwnProperty('"' + agentId.toString() + '"'); // add two "s to match the data structure in the object

    // when the user clicks the heart icon
    const handleLike = () => {
        const type = userHasLikedPost ? 'unvote' : 'upvote';

        updateFeedItem({
            agentId,
            eventId: item._id,
            type,
            comment: '',
        });
    };

    const handleSetAgent = () => {
        const newSelectedAgent = {
            agentId: item.agentObjectId,
            name: authorName,
            firstName: authorName.split(' ')[0],
            lastName: authorName.split(' ')[1],
            photoUri: item.content.agentImage,
        };
        setSelectedAgent(newSelectedAgent);
        history.push('/social/agentProfile');
    };

    return (
        <div className={styles.socialContainer}>
            {/* {item.content.extraData?.agentObjectId && ( */}
            <div className={styles.authorContainer}>
                {/* <img
                        src={item.content.agentImage || DefaultImage}
                        alt={'profilePic'}
                        className={styles.socialAuthorImage}
                        onClick={() => handleSetAgent()}
                    /> */}
                <Photo
                    containerStyles={styles.socialAuthorImage}
                    onClickImage={() => handleSetAgent()}
                    photoURI={item.content.agentImage || DefaultImage}
                />

                <div className={styles.authorInfoContainer}>
                    <div className={styles.authorInfoTopRowSocial}>
                        <span className={styles.authorName} onClick={() => handleSetAgent()}>
                            {authorName}
                        </span>
                        <span className={styles.postedAt}>
                            {minutesToTimeDisplay(item.activityDate)}
                        </span>
                    </div>
                </div>
            </div>
            {/* )} */}

            <div className={styles.socialContentContainer}>
                {item.type === 'badge' && (
                    <span>
                        {`Earned the ${
                            item.content.extraData?.badgeName || 'uknown badge name'
                        } badge.`}
                    </span>
                )}

                {item.type === 'post' && (
                    <div className={styles.postContainer}>
                        <span className={styles.postText}>{item.content.text}</span>
                        {item.content.image && (
                            <img
                                src={item.content.image.uri}
                                alt={'image'}
                                className={styles.postImage}
                            />
                        )}
                        {item?.content?.extraData?.link && (
                            <Button
                                buttonText={'Link'}
                                className={styles.linkButton}
                                onClick={() => window.open(item?.content?.extraData?.link)}
                            />
                        )}
                    </div>
                )}

                {item.type === 'follower' && <span>{otherName} has started following you</span>}

                {item.type === 'listing' && listingData(item, false, setSelectedAgent)}

                {item.type === 'soldListing' && listingData(item, true, setSelectedAgent)}
            </div>

            {renderIcon && (
                <div className={styles.socialIconContainer}>
                    <div className={styles.iconContainer}>
                        <img
                            src={userHasLikedPost ? HeartIconFilled : HeartIcon}
                            alt={'heartIcon'}
                            className={styles.icon}
                            onClick={() => handleLike()}
                        />
                        <span className={styles.iconText}>{item.upvotesCount}</span>
                    </div>
                    <div
                        className={styles.iconContainer}
                        onClick={() => {
                            setSelectedItem(item);
                            window.scrollTo(0, 0);
                        }}
                    >
                        <img src={CommentIcon} alt={'commentIcon'} className={styles.icon} />
                        <span className={styles.iconText}>{item.comments.length}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialCard;
