import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import classnames from 'classnames';
import styles from './index.module.css';
import Marker from '../Marker';
import { DEFAULT_REGION } from '../constants';
import { fetchMapListingsRequested } from '../actions';
import { MapListingObject, Region } from '../types';
import { getMapListings, getMapLoading } from '../selectors';
import FilterIcon from '../../../../images/FilterIcon.svg';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import FilterDrawer from '../FilterDrawer';
import { FilterOptionType } from '../FilterDrawer/Utils';
interface MapViewProps {
    activeListings?: Array<MapListingObject>;
    queuedListings?: Array<MapListingObject> | null;
    onMapClicked: Function;
    onPressMarker: Function;
    containerStyles?: string;
    selectedListing?: any;
    center?: any;
    distanceTime?: Function;
    startEnd?: any;
    setWaypointOrder?: Function;
    setLastOptimalRoute?: Function;
    lastOptimalRoute?: any;
    setRouteIsOptimal?: Function;
}
interface MapProps {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
}
function usePrevious(value: Array<MapListingObject>) {
    const ref = useRef<any>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
const MapView = forwardRef(
    (
        {
            activeListings,
            queuedListings,
            onPressMarker,
            onMapClicked,
            containerStyles,
            selectedListing,
            center,
            distanceTime,
            startEnd, // origin & destination place_id
            setWaypointOrder,
            setLastOptimalRoute,
            setRouteIsOptimal,
            lastOptimalRoute, // geo-coded polygon as string
        }: MapViewProps,
        ref,
    ) => {
        const listings: Array<MapListingObject> = useSelector(getMapListings);
        const loading: boolean = useSelector(getMapLoading);
        const dispatch = useDispatch();
        const fetchMapListings: Function = (
            region: Region,
            activeListings: Array<MapListingObject>,
            filter: any,
        ) => dispatch(fetchMapListingsRequested(region, activeListings, filter));

        const [mapProps, setMapProps] = useState<MapProps>(DEFAULT_REGION);
        const [map, setMap] = useState<any>(null);
        const [maps, setMaps] = useState<any>(null);
        const [directionData, setDirectionData] = useState(null);
        const [origin, setOrigin] = useState<any>(null);
        const [dest, setDest] = useState<any>(null);
        const directionsRendererRef = useRef(null);
        const directionsServiceRef = useRef(null);
        const placesServiceRef = useRef(null);
        const prevQueuedListings = queuedListings ? usePrevious(queuedListings) : null;
        const [apiLoading, setApiLoading] = useState<any>(true);
        const [filterVisible, setFilterVisible] = useState<boolean>(false);
        const [minMaxCoords, setMinMaxCoords] = useState<{
            minCoords: GoogleMapReact.Coords | undefined;
            maxCoords: GoogleMapReact.Coords | undefined;
        }>({
            minCoords: undefined,
            maxCoords: undefined,
        });

        const [filterOptions, setFilterOptions] = useState<FilterOptionType>({
            statuses: ['Active'],
            types: [],
            onlyConfigured: false,
            beds: 'Any',
            baths: 'Any',
            parking: 'Any',
            minPrice: -1,
            maxPrice: Number.MAX_VALUE,
            minSqft: -1,
            maxSqft: Number.MAX_VALUE,
        });

        useImperativeHandle(ref, () => ({ direct }));

        // Mount - Check user location
        useEffect(() => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    const mapProps = {
                        center: {
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude,
                        },
                        zoom: 10,
                    };
                    setMapProps(mapProps);
                });
            }
        }, []);

        useEffect(() => {
            if (center) {
                const mapProps = {
                    center: {
                        lat: center.lat,
                        lng: center.lng,
                    },
                    zoom: 16,
                };
                setMapProps(mapProps);
            }
        }, [center]);

        const markers = useCallback(() => {
            let copyListings = queuedListings?.map((d) => d);
            if (queuedListings) {
                if (origin) {
                    copyListings?.push(origin);
                }
                if (dest) {
                    copyListings?.push(dest);
                }
                return copyListings?.map((listingObj: MapListingObject) => {
                    if (listingObj?.geo?.lat && listingObj?.geo?.lng) {
                        return (
                            <Marker
                                key={`${listingObj._id}`}
                                lat={listingObj?.geo?.lat}
                                lng={listingObj?.geo?.lng}
                                onPressMarker={() => onPressMarker(listingObj)}
                                isSelected={`${listingObj._id}` === `${selectedListing?._id}`}
                            />
                        );
                    }
                });
            } else if (listings && listings.length) {
                return listings.map((listingObj: MapListingObject) => {
                    return (
                        <Marker
                            key={`${listingObj._id}`}
                            lat={listingObj?.geo?.lat}
                            lng={listingObj?.geo?.lng}
                            onPressMarker={() => onPressMarker(listingObj)}
                            isSelected={`${listingObj._id}` === `${selectedListing?._id}`}
                        />
                    );
                });
            }
        }, [listings, selectedListing, queuedListings, origin, dest]);

        useEffect(() => {
            if (!maps || !map) return;
            const placesService = placesServiceRef.current
                ? placesServiceRef.current
                : new maps.places.PlacesService(map);
            placesServiceRef.current = placesService;

            if (startEnd.origin) {
                placesService.getDetails(
                    {
                        placeId: startEnd.origin,
                        fields: ['geometry'],
                    },
                    (res: any) => {
                        setOrigin({
                            _id: startEnd.origin,
                            geo: {
                                lat: res.geometry.location.lat(),
                                lng: res.geometry.location.lng(),
                            },
                        });
                    },
                );
            } else {
                setOrigin(null);
            }
            if (startEnd.destination) {
                placesService.getDetails(
                    {
                        placeId: startEnd.destination,
                        fields: ['geometry'],
                    },
                    (res: any) => {
                        setDest({
                            _id: startEnd.destination,
                            geo: {
                                lat: res.geometry.location.lat(),
                                lng: res.geometry.location.lng(),
                            },
                        });
                    },
                );
            } else {
                setDest(null);
            }
        }, [startEnd, apiLoading]);

        const direct = useCallback(
            (optimize = false) => {
                if (
                    maps === null ||
                    map === null ||
                    !queuedListings ||
                    queuedListings?.length <= 1
                ) {
                    return;
                }
                const bounds = new maps.LatLngBounds();
                const directionsRenderer = directionsRendererRef.current
                    ? directionsRendererRef.current
                    : new maps.DirectionsRenderer();
                const directionsService = directionsServiceRef.current
                    ? directionsServiceRef.current
                    : new maps.DirectionsService();
                directionsRendererRef.current = directionsRenderer;
                directionsServiceRef.current = directionsService;
                const waypoints: Array<any> = [];
                if (origin) {
                    bounds.extend(new maps.LatLng(origin.geo.lat, origin.geo.lng));
                    waypoints.push({
                        location: origin.geo.lat + ',' + origin.geo.lng,
                    });
                }
                queuedListings.forEach((marker) => {
                    const { lat, lng } = marker.geo;
                    waypoints.push({
                        location: lat + ',' + lng,
                    });
                    bounds.extend(new maps.LatLng(lat, lng));
                });
                if (dest) {
                    bounds.extend(new maps.LatLng(dest.geo.lat, dest.geo.lng));
                    waypoints.push({
                        location: dest.geo.lat + ',' + dest.geo.lng,
                    });
                }
                directionsRenderer.setMap(map);

                var request = {
                    origin: waypoints.shift().location,
                    destination: waypoints.pop().location,
                    travelMode: 'DRIVING',
                    waypoints: waypoints,
                    optimizeWaypoints: optimize,
                };
                directionsService.route(request, function (result: any, status: string) {
                    if (status == 'OK') {
                        if (JSON.stringify(result) !== JSON.stringify(directionData))
                            setDirectionData(result);
                        directionsRenderer.setDirections(result);

                        const geoCodedWaypoints = JSON.stringify(
                            result?.geocoded_waypoints?.map((e: any) => e?.place_id),
                        );
                        if (setRouteIsOptimal) {
                            if (
                                lastOptimalRoute?.length &&
                                lastOptimalRoute === geoCodedWaypoints
                            ) {
                                setRouteIsOptimal(true);
                            } else {
                                setRouteIsOptimal(false);
                            }
                        }

                        if (
                            optimize &&
                            setWaypointOrder &&
                            setLastOptimalRoute &&
                            setRouteIsOptimal
                        ) {
                            setWaypointOrder(result?.routes?.[0]?.waypoint_order);

                            // save the current optimal route place_id fields
                            setLastOptimalRoute(geoCodedWaypoints);
                            setRouteIsOptimal(true);
                        }
                    } else {
                        console.log('Direction not rendered', result);
                    }
                });
                map?.fitBounds(bounds);
            },
            [map, maps, queuedListings, origin, dest],
        );
        useEffect(() => {
            if (JSON.stringify(prevQueuedListings) === JSON.stringify(queuedListings)) return;
            direct();
        }, [queuedListings]);
        useEffect(direct, [map, maps, origin, dest]);
        useEffect(() => {
            if (distanceTime) distanceTime(directionData);
        }, [directionData]);
        const handleApiLoaded = (map: any, maps: any) => {
            if (!queuedListings) return;
            setMap(map);
            setMaps(maps);
            setApiLoading(false);
        };

        useEffect(() => {
            if (minMaxCoords.minCoords && minMaxCoords.maxCoords) {
                fetchMapListings(
                    {
                        minCoords: minMaxCoords.minCoords,
                        maxCoords: minMaxCoords.maxCoords,
                    },
                    activeListings,
                    filterOptions,
                );
            }
        }, [filterOptions]);

        return (
            <div className={classnames(styles.root, containerStyles)}>
                {/* Filter Drawer */}
                <FilterDrawer
                    filterVisible={filterVisible}
                    setFilterVisible={setFilterVisible}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                />
                {/* Filter Button */}
                <ClickableWithFeedback
                    onClick={() => {
                        setFilterVisible(true);
                    }}
                    className={styles.filterButton}
                >
                    <img src={FilterIcon} alt={'Filter'} />
                    <span className={styles.filterText}>{`Filter`}</span>
                </ClickableWithFeedback>
                <GoogleMapReact
                    // @ts-ignore
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
                        libraries: ['places'],
                    }}
                    defaultCenter={DEFAULT_REGION.center}
                    defaultZoom={DEFAULT_REGION.zoom}
                    zoom={mapProps && mapProps.zoom}
                    center={mapProps && mapProps.center}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    onChange={(value: GoogleMapReact.ChangeEventValue) => {
                        if (queuedListings) {
                            // lol
                        } else {
                            const {
                                bounds: { ne: maxCoords, sw: minCoords },
                            } = value;
                            setMinMaxCoords({ minCoords, maxCoords });
                            fetchMapListings(
                                {
                                    minCoords,
                                    maxCoords,
                                },
                                activeListings,
                                filterOptions,
                            );
                        }
                    }}
                    onClick={() => {
                        onMapClicked(true);
                    }}
                >
                    {markers()}
                    {loading && (
                        <div className={styles.loadingBar}>
                            <p className={styles.loadingText}>Loading...</p>
                        </div>
                    )}
                </GoogleMapReact>
            </div>
        );
    },
);

export default MapView;
