import React, {
    useState,
    useEffect,
    useLayoutEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import { RemoveScroll } from 'react-remove-scroll';
import { useSpring, animated } from 'react-spring';
import { isMobile } from '../../utils/common';

interface Props {
    onClose: Function;
    children?: any;
    backgroundOpacity?: number;
}

export const ANIMATED_DRAWER_WIDTH: number = 637;

const AnimatedDrawer = forwardRef((props: Props, ref?) => {
    const { onClose, children } = props;
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    // used to trigger drawer open/close animations
    const [isOpen, setOpen] = useState<boolean>(false);

    const closeDrawer = () => {
        setOpen(false);
        setTimeout(() => {
            onClose();
        }, 350);
    };

    useImperativeHandle(ref, () => ({ closeDrawer }));

    // #region animation

    useEffect(() => {
        setOpen(true);
        return () => {};
    }, []);

    /**
     * Make sure that the schedule drawer repositions when the screen
     * is resized.
     */
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
        setScreenWidth(window.innerWidth);
        return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth));
    }, []);

    const animationProps: any = useSpring({
        left: isOpen
            ? (screenWidth || window.innerWidth) - ANIMATED_DRAWER_WIDTH
            : screenWidth || window.innerWidth,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: `${ANIMATED_DRAWER_WIDTH}px`,
    });

    const animationPropsMobile: any = useSpring({
        left: isOpen
            ? (screenWidth || window.innerWidth) - ANIMATED_DRAWER_WIDTH + 247
            : screenWidth || window.innerWidth,
    });

    const backgroundAnimatedProps: any = useSpring({
        backgroundColor: isOpen
            ? `rgba(0,0,0,${
                  0.8 * (typeof props?.backgroundOpacity === 'number' ? props.backgroundOpacity : 1)
              })`
            : 'transparent',
    });

    // #endregion animation

    const scheduleClass = isMobile() ? styleSheet.scheduleContainerMobile : styleSheet.scheduleContainer;
    const scheduleStyle = isMobile() ? animationPropsMobile : animationProps;

    return (
        <RemoveScroll>
            <animated.div
                className={styleSheet.root}
                style={backgroundAnimatedProps}
                onClick={() => {
                    setOpen(false);
                    setTimeout(() => {
                        onClose();
                    }, 350);
                }}
            >
                <animated.div
                    className={scheduleClass}
                    style={scheduleStyle}
                    onClick={(e) => {
                        e.stopPropagation(); // dont close the drawer
                    }}
                >
                    {children}
                </animated.div>
            </animated.div>
        </RemoveScroll>
    );
});
export default AnimatedDrawer;
