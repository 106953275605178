// import { AgentListing, Listing } from '../../../../../showingly-web-lib/dist/constants';
import { AgentListing, Listing } from 'web-lib/dist/constants';

const PREFIX = 'listings/';

export const FETCH_MY_LISTINGS_REQUESTED = PREFIX + 'FETCH_MY_LISTINGS_REQUESTED';
export const FETCH_MY_LISTINGS_SUCCEEDED = PREFIX + 'FETCH_MY_LISTINGS_SUCCEEDED';
export const FETCH_MY_LISTINGS_FAILED = PREFIX + 'FETCH_MY_LISTINGS_FAILED';

export const FETCH_SELECTED_LISTING_REQUESTED = PREFIX + 'FETCH_SELECTED_LISTING_REQUESTED';
export const FETCH_SELECTED_LISTING_FAILED = PREFIX + 'FETCH_SELECTED_LISTING_FAILED';

export const SET_SELECTED_LISTING = PREFIX + 'SET_SELECTED_LISTING';

export const SET_LISTING_CONFIG = PREFIX + 'SET_LISTING_CONFIG';

export const TEXT_SEARCH_AGENTS_REQUESTED = PREFIX + 'TEXT_SEARCH_AGENTS_REQUESTED';
export const TEXT_SEARCH_AGENTS_SUCCEEDED = PREFIX + 'TEXT_SEARCH_AGENTS_SUCCEEDED';
export const TEXT_SEARCH_AGENTS_FAILED = PREFIX + 'TEXT_SEARCH_AGENTS_FAILED';

export const TEXT_SEARCH_CONSUMERS_REQUESTED = PREFIX + 'TEXT_SEARCH_CONSUMERS_REQUESTED';
export const TEXT_SEARCH_CONSUMERS_SUCCEEDED = PREFIX + 'TEXT_SEARCH_CONSUMERS_SUCCEEDED';
export const TEXT_SEARCH_CONSUMERS_FAILED = PREFIX + 'TEXT_SEARCH_CONSUMERS_FAILED';

export const CONNECT_LISTING_REQUESTED = PREFIX + 'CONNECT_LISTING_REQUESTED';
export const CONNECT_LISTING_SUCCEEDED = PREFIX + 'CONNECT_LISTING_SUCCEEDED';
export const CONNECT_LISTING_FAILED = PREFIX + 'CONNECT_LISTING_FAILED';

export const ADD_CLIENT_REQUESTED = PREFIX + 'ADD_CLIENT_REQUESTED';
export const ADD_CLIENT_SUCCEEDED = PREFIX + 'ADD_CLIENT_SUCCEEDED';
export const ADD_CLIENT_FAILED = PREFIX + 'ADD_CLIENT_FAILED';

export const STORE_LOCKBOX_PHOTO_REQUESTED = PREFIX + 'STORE_LOCKBOX_PHOTO_REQUESTED';
export const STORE_LOCKBOX_PHOTO_SUCCEEDED = PREFIX + 'STORE_LOCKBOX_PHOTO_SUCCEEDED';
export const STORE_LOCKBOX_PHOTO_FAILED = PREFIX + 'STORE_LOCKBOX_PHOTO_FAILED';

export const CHANGE_SELECTED_VIEW = PREFIX + 'CHANGE_SELECTED_VIEW';

export const RESET_VALUES = PREFIX + 'RESET_VALUES';

export const fetchListingsRequested = (agentObjectId: string) => {
    return {
        type: FETCH_MY_LISTINGS_REQUESTED,
        agentObjectId,
    };
};

export const fetchListingsSucceeded = (listings: any) => {
    return {
        type: FETCH_MY_LISTINGS_SUCCEEDED,
        listings,
    };
};

export const fetchListingsFailed = (errors: any) => {
    return {
        type: FETCH_MY_LISTINGS_REQUESTED,
        errors,
    };
};

export const setSelectedListing = (listing: any) => {
    return {
        type: SET_SELECTED_LISTING,
        listing,
    };
};

export const fetchSelectedListingRequested = (
    listingId: string,
    isEditing?: boolean | undefined,
) => {
    return {
        type: FETCH_SELECTED_LISTING_REQUESTED,
        listingId,
        isEditing,
    };
};

export const fetchSelectedListingFailed = (errors: any) => {
    return {
        type: FETCH_SELECTED_LISTING_FAILED,
        errors,
    };
};

export const setConfigDetails = (listing: Listing, pageNumber: number) => {
    return {
        type: SET_LISTING_CONFIG,
        listing,
        pageNumber,
    };
};

export const textSearchAgentsRequested = (searchText = '') => {
    return {
        type: TEXT_SEARCH_AGENTS_REQUESTED,
        searchText,
    };
};

export const textSearchAgentsSucceeded = (agents = []) => {
    return {
        type: TEXT_SEARCH_AGENTS_SUCCEEDED,
        agents,
    };
};

export const textSearchAgentsFailed = (errors = []) => {
    return {
        type: TEXT_SEARCH_AGENTS_FAILED,
        errors,
    };
};

export const textSearchConsumersRequested = (searchText = '') => {
    return {
        type: TEXT_SEARCH_CONSUMERS_REQUESTED,
        searchText,
    };
};

export const textSearchConsumersSucceeded = (consumers = []) => {
    return {
        type: TEXT_SEARCH_CONSUMERS_SUCCEEDED,
        consumers,
    };
};

export const textSearchConsumersFailed = (errors = []) => {
    return {
        type: TEXT_SEARCH_CONSUMERS_FAILED,
        errors,
    };
};

export const connectListingRequested = (agentListing: AgentListing) => {
    return {
        type: CONNECT_LISTING_REQUESTED,
        agentListing,
    };
};

export const connectListingSucceeded = () => {
    return {
        type: CONNECT_LISTING_SUCCEEDED,
    };
};

export const connectListingFailed = (errors: any) => {
    return {
        type: CONNECT_LISTING_FAILED,
        errors,
    };
};

export const resetValues = () => {
    return {
        type: RESET_VALUES,
    };
};

export const addClientRequested = (client: any) => {
    return {
        type: ADD_CLIENT_REQUESTED,
        client,
    };
};

export const addClientSucceeded = (newClient: any) => {
    return {
        type: ADD_CLIENT_SUCCEEDED,
        newClient,
    };
};

export const addClientFailed = (errors: any) => {
    return {
        type: ADD_CLIENT_FAILED,
        errors,
    };
};

export const storeLockboxPhotoRequested = (file: {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}) => {
    return {
        type: STORE_LOCKBOX_PHOTO_REQUESTED,
        file,
    };
};

export const storeLockboxPhotoSucceeded = (newContentData: {
    image: { as: string; path: string; uri: string };
}) => {
    return {
        type: STORE_LOCKBOX_PHOTO_SUCCEEDED,
        newContentData,
    };
};

export const storeLockboxPhotoFailed = (errors: any) => {
    return {
        type: STORE_LOCKBOX_PHOTO_FAILED,
        errors,
    };
};

export const changeSelectedView = (view: string) => {
    if (view === 'Overview' || view === 'Listing Details' || view === 'Configuration') {
        return {
            type: CHANGE_SELECTED_VIEW,
            view,
        };
    }
}