import { STATUS } from '../../../store/api/constants';
import { BSON } from 'realm-web';
import {
    AgentMetrics,
    AgentStats,
    SocialItem,
    SocialDocument,
    FeedItem,
    SelectedAgentSocial,
} from '../../../utils/constants';
import { AgentSimple } from '../../../utils/constants';
import { BsPrefixComponent } from 'react-bootstrap/esm/helpers';

export enum SOCIAL_ACTION {
    FetchFeed = '[Social] Fetch',
    UpdateFeedItem = '[Social] Update Feed Item',
    CreateFeedItem = '[Social] Create Feed Item',
    UpdateFeedPagination = '[Social] Update Feed Pagination',
    SelectedAgent = '[Social] Selected Agent',
    FetchAgentMetrics = '[Social] Fetch Agent Metrics',
    FetchAgentStats = '[Social] Fetch Agent Stats',
    FetchAgentSocialDocument = '[Social] Fetch Agent Social Document',
    FollowAndUnfollowAgents = '[Social] Follow and Unfollow Agents',
    Badges = '[Social] Badges',
    BrokerageAndMarketData = '[Social] Brokerage and Market Data',
    FetchAgentFull = '[Social] Fetch Agent Full Document',
    FetchMyAgentFull = '[Social] Fetch My Agent Full Document',
    IsAgentPending = '[Social] Is Agent Pending',
    IsMyAgentPending = '[Social] Is My Agent Pending',
    FetchSearchAgents = '[Social] Fetch Search Agents',
}

// agentId is optional. If it's not passed in, we fetch the current user's feed
// if agentId IS passed in, then we use that id to fetch that users feed
// if isOnlyAgent is passed in as true, we only want to fetch that agent's posts, not their whole feed
export function fetchFeedRequested(
    isReset: boolean,
    agentId?: BSON.ObjectId,
    isOnlyAgent?: boolean,
) {
    return {
        type: SOCIAL_ACTION.FetchFeed,
        status: STATUS.Requested,
        isReset,
        agentId,
        isOnlyAgent,
    };
}

export function fetchFeedSucceeded(feedItems: any[], agentId?: BSON.ObjectId) {
    return {
        type: SOCIAL_ACTION.FetchFeed,
        status: STATUS.Succeeded,
        feedItems,
        agentId,
    };
}

export function fetchFeedFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.FetchFeed,
        status: STATUS.Failed,
        errors,
    };
}

// used to like and comment on posts
export type UpdateFeedData = {
    eventId: BSON.ObjectId;
    agentId?: BSON.ObjectId;
    type: 'upvote' | 'unvote' | 'comment';
    comment: string;
};
export function updateFeedItemRequested(feedData: UpdateFeedData) {
    return {
        type: SOCIAL_ACTION.UpdateFeedItem,
        status: STATUS.Requested,
        feedData,
    };
}

export function updateFeedItemSucceeded(updatedItem: SocialItem) {
    return {
        type: SOCIAL_ACTION.UpdateFeedItem,
        status: STATUS.Succeeded,
        updatedItem,
    };
}

export function updateFeedItemFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.UpdateFeedItem,
        status: STATUS.Failed,
        errors,
    };
}

export type SocialFeedPostData = {
    agentObjectId: BSON.ObjectId;
    post: {
        description: string;
        link?: string;
        imageRAW?: any;
    };
};
export function createSocialFeedPostRequested(postData: SocialFeedPostData) {
    return {
        type: SOCIAL_ACTION.CreateFeedItem,
        status: STATUS.Requested,
        postData,
    };
}

export function createSocialFeedPostSucceeded(newPost: SocialFeedPostData) {
    return {
        type: SOCIAL_ACTION.CreateFeedItem,
        status: STATUS.Succeeded,
        newPost,
    };
}

export function createSocialFeedPostFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.CreateFeedItem,
        status: STATUS.Failed,
        errors,
    };
}

export function setSelectedAgent(selectedAgent: SelectedAgentSocial) {
    return {
        type: SOCIAL_ACTION.SelectedAgent,
        status: STATUS.Requested,
        selectedAgent,
    };
}

export function clearSelectedAgent() {
    return {
        type: SOCIAL_ACTION.SelectedAgent,
        status: STATUS.Refresh,
    };
}

export const updateSocialFeedPaginationRequested = (
    currentPageNumber: Number | null,
    isReset: boolean = false,
) => {
    return {
        type: SOCIAL_ACTION.UpdateFeedPagination,
        currentPageNumber,
        isReset,
    };
};

export function fetchAgentMetricsRequested(agentId: string, mlsName: string) {
    return {
        type: SOCIAL_ACTION.FetchAgentMetrics,
        status: STATUS.Requested,
        agentId,
        mlsName,
    };
}

export function fetchAgentMetricsSucceeded(buyerMetrics: any, sellerMetrics: any) {
    return {
        type: SOCIAL_ACTION.FetchAgentMetrics,
        status: STATUS.Succeeded,
        buyerMetrics,
        sellerMetrics,
    };
}

export function fetchAgentMetricsFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.FetchAgentMetrics,
        status: STATUS.Failed,
        errors,
    };
}

export function fetchAgentStatsRequested(agentObjectId: BSON.ObjectId) {
    return {
        type: SOCIAL_ACTION.FetchAgentStats,
        status: STATUS.Requested,
        agentObjectId,
    };
}

export function fetchAgentStatsSucceeded(agentStats: any, agentMarkets: string[]) {
    return {
        type: SOCIAL_ACTION.FetchAgentStats,
        status: STATUS.Succeeded,
        agentStats,
        agentMarkets,
    };
}

export function fetchAgentStatsFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.FetchAgentStats,
        status: STATUS.Failed,
        errors,
    };
}

export function fetchAgentSocialDocumentRequested(agentId: BSON.ObjectId) {
    return {
        type: SOCIAL_ACTION.FetchAgentSocialDocument,
        status: STATUS.Requested,
        agentId,
    };
}

export function fetchAgentSocialDocumentSucceeded(socialDocument: SocialDocument) {
    return {
        type: SOCIAL_ACTION.FetchAgentSocialDocument,
        status: STATUS.Succeeded,
        socialDocument,
    };
}

export function fetchAgentSocialDocumentFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.FetchAgentSocialDocument,
        status: STATUS.Failed,
        errors,
    };
}

export function followAgentRequested(
    interactionType: 'follow' | 'unfollow' | 'removeRequest' | 'confirmRequest' | 'denyRequest',
    agent: AgentSimple,
    isPrivateAccount = false,
) {
    return {
        type: SOCIAL_ACTION.FollowAndUnfollowAgents,
        status: STATUS.Requested,
        interactionType,
        agent,
        isPrivateAccount,
    };
}

export function followAgentSucceeded(currentAgent: AgentSimple, newlyFollowedAgent: AgentSimple) {
    return {
        type: SOCIAL_ACTION.FollowAndUnfollowAgents,
        status: STATUS.Succeeded,
        currentAgent,
        newlyFollowedAgent,
    };
}

export function unfollowAgentSucceeded(
    currentAgent: AgentSimple,
    newlyUnfollowedAgent: AgentSimple | null,
) {
    return {
        type: SOCIAL_ACTION.FollowAndUnfollowAgents,
        status: STATUS.Refresh,
        currentAgent,
        newlyUnfollowedAgent,
    };
}

export function followAgentFailed(errors: string[] = []) {
    return {
        type: SOCIAL_ACTION.FollowAndUnfollowAgents,
        status: STATUS.Failed,
        errors,
    };
}

export const checkActionBadgeStatusRequested = (
    badgeType: string | string[],
    agentId?: null | string,
) => {
    return {
        type: SOCIAL_ACTION.Badges,
        status: STATUS.Requested,
        badgeType,
        agentId,
    };
};

export const checkActionBadgeStatusSucceeded = () => {
    return {
        type: SOCIAL_ACTION.Badges,
        status: STATUS.Succeeded,
    };
};

export const checkActionBadgeStatusFailed = (errors: string[] = []) => {
    return {
        type: SOCIAL_ACTION.Badges,
        status: STATUS.Failed,
        errors,
    };
};

export const fetchAgentFullRequested = (
    agentId: BSON.ObjectId | string,
    viewingAgentId: BSON.ObjectId | string,
) => {
    return {
        type: SOCIAL_ACTION.FetchAgentFull,
        status: STATUS.Requested,
        agentId,
        viewingAgentId,
    };
};

export const fetchAgentFullSucceeded = (agentFullDocument: object) => {
    return {
        type: SOCIAL_ACTION.FetchAgentFull,
        status: STATUS.Succeeded,
        agentFullDocument,
    };
};

export const fetchAgentFullFailed = (errors: string[] = []) => {
    return {
        type: SOCIAL_ACTION.FetchAgentFull,
        status: STATUS.Failed,
        errors,
    };
};

export const isAgentPending = (isPending = false) => {
    return {
        type: SOCIAL_ACTION.IsAgentPending,
        status: STATUS.Requested,
        isPending,
    };
};

export const isMyAgentPending = (isPending = false) => {
    return {
        type: SOCIAL_ACTION.IsMyAgentPending,
        status: STATUS.Requested,
        isPending,
    };
};

export const fetchSearchAgentsRequested = (searchString = '') => {
    return {
        type: SOCIAL_ACTION.FetchSearchAgents,
        status: STATUS.Requested,
        searchString,
    };
};

export const fetchSearchAgentsSucceeded = (searchedAgents = []) => {
    return {
        type: SOCIAL_ACTION.FetchSearchAgents,
        status: STATUS.Succeeded,
        searchedAgents,
    };
};

export const fetchSearchAgentsFailed = (errors: string[] = []) => {
    return {
        type: SOCIAL_ACTION.FetchSearchAgents,
        status: STATUS.Failed,
        errors,
    };
};

export const fetchMySocialProfileFullRequested = (agentId: BSON.ObjectId | string) => {
    return {
        type: SOCIAL_ACTION.FetchMyAgentFull,
        status: STATUS.Requested,
        agentId,
    };
};

export const fetchMySocialProfileFullSucceeded = (myAgentFullDocument: object) => {
    return {
        type: SOCIAL_ACTION.FetchMyAgentFull,
        status: STATUS.Succeeded,
        myAgentFullDocument,
    };
};

export const fetchSocialMyProfileFullFailed = (errors: string[] = []) => {
    return {
        type: SOCIAL_ACTION.FetchMyAgentFull,
        status: STATUS.Failed,
        errors,
    };
};

export type FeedActions =
    | ReturnType<typeof fetchFeedRequested>
    | ReturnType<typeof fetchFeedSucceeded>
    | ReturnType<typeof fetchFeedFailed>
    | ReturnType<typeof updateFeedItemRequested>
    | ReturnType<typeof updateFeedItemSucceeded>
    | ReturnType<typeof updateFeedItemFailed>
    | ReturnType<typeof updateSocialFeedPaginationRequested>
    | ReturnType<typeof fetchAgentSocialDocumentRequested>
    | ReturnType<typeof fetchAgentSocialDocumentSucceeded>
    | ReturnType<typeof fetchAgentSocialDocumentFailed>;
