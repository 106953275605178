export default (theme: any) => {
    return {
        root: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            // should cover up everything
            zIndex: 99999,
        },
        scheduleContainer: {
            backgroundColor: theme.palette.white,
            position: 'fixed',
            right: 0,
            top: 0,
            bottom: 0,
            width: 637,
            borderColor: theme.palette.grey,
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: 'solid',
        },
        scheduleContainerMobile: {
            top: 0,
            position: 'fixed',
            width: '100%',
            backgroundColor: theme.palette.white,
            borderColor: theme.palette.grey,
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: 'solid',
            left: '0 !important'
        }
    };
};
