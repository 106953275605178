export default (theme: any) => {
    return {
        headerBar: {
            height: 60,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: { left: 15, right: 17.91 },
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
            marginBottom: -3,
        },
        middleContainer: {
            height: 470,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: 110,
            justifyContent: 'space-between',
        },
        buttonBar: {
            height: 70,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderTopWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginTop: 'auto',
        },
        button: {
            height: '40px !important',
            fontSize: '16px !important',
            fontWeight: '600 !important',
            width: '250px !important',
            marginRight: '15px !important',
        },
        successRow: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
        },
        checkImg: {
            paddingRight: 10,
        },
    };
};
