import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
// Redux Imports
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowingDetailsRequested } from './actions';
import { closeDetailMsg } from '../Showings/actions';
import { getProfileData } from '../Profile/selectors';
import { getUpdatingShowing } from '../Showings/selectors';
import { getUpdateLog } from '../Showings/selectors';
import {
    getCurrentShowing,
    getInitiallyViewingListingDetails,
    getLoading,
    getLoadingShowingStatus,
} from './selectors';
// Screen Imports
import { ConnectedScheduleScreen } from '../ShowingRequests/ScheduleScreen';
// Componets Imports
import Tag from '../../../components/Tag';
import ButtonComponent from '../../../components/ButtonComponent';
import Modal from '../../../components/Modal';
import { AnimatedModal, Loader, Typography } from '../../../components';
import ClickableWithFeedback from '../../../components/ClickableWithFeedback';
import ListingDetailsComponent from '../ListingDetailsScreen/ListingDetailsComponent';
// Image Imports
import close from '../../../images/close.svg';
import GrayCal from '../../../images/grayCal.svg';
import GrayClock from '../../../images/grayClock.svg';
import RightArrow from '../../../images/rightArrowBlue.svg';
import QRCode from '../../../images/scannyAppyDownloady.svg';
import msgCheck from '../../../images/ConfirmationModalCheck.svg';
import msgCheckReq from '../../../images/ConfirmationTRequestedModalCheck.svg';
// Other Imports
import styles from './styles';
import { BSON } from 'realm-web';
import { DateTime } from 'luxon';
import indexTheme from '../../../theme';
import { formatCurrency } from '../../../utils/common/transforms';
import {
    formatPhoneNumberForDisplay,
    formatEmailForDisplay,
    getStatusForDisplay,
} from '../../../utils/common';
import AddClientDrawer from '../../../components/AddClientDrawer';
import LeaveFeedbackModal from '../Showings/Modals/LeaveFeedbackModal';
import { acceptShowingRequestRequested, declineShowingRequestRequested } from './actions';
import { getTimeDateShowing } from '../../../utils/common';

interface Props {}

// Wireframes to this page as of11/20/2021: https://www.figma.com/file/Q1SANfbaws9b6TnmkhQYkG/Agent-Web-2.0?node-id=19%3A140

const ShowingDetails = (props: Props) => {
    // ----------------------------- States Values and Logic. ------------------------------
    // Redux value
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const user = useSelector(getProfileData);
    const showing = useSelector(getCurrentShowing);
    const updatingShowing = useSelector(getUpdatingShowing);
    const initiallyListingDetails = useSelector(getInitiallyViewingListingDetails);
    const updatelog = useSelector(getUpdateLog);
    const loadingUpdateStatus = useSelector(getLoadingShowingStatus);
    //React Dom
    const history = useHistory();
    //Hooks
    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);
    const [showAddClientDrawer, setShowAddClientDrawer] = useState<boolean>(false);
    const [showLockboxPhotoModal, setShowLockboxPhotoModal] = useState<boolean>(false);
    const [feedbackModal, setFeedbackModal] = useState<any>(false);
    const [canResize, setCanResize] = useState(false);
    const [isdetailMsgOpen, setDetailMsgOpen] = useState(updatelog.detailMsg);
    const [selectedView, setSelectedView] = useState<string>(
        initiallyListingDetails ? 'Listing Details' : 'Showing Details',
    );
    const [cardContainerSize, setCardContainerSize] = useState<any>({
        width: window.innerWidth - 280,
        height: window.innerHeight - 60,
    });
    // If the user comes in from a link or refreshes the page, make it so we fetch the listing they're looking at.
    const [splitPath, setSplitPath] = useState<any>(history.location.pathname.split('/'));
    //Other Values
    const fetchShowing = (showingId: BSON.ObjectId) =>
        dispatch(fetchShowingDetailsRequested(showingId));
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const minutesUtcOffset = new Date().getTimezoneOffset();

    // If the user comes in from a link or refreshes the page, make it so we fetch the listing they're looking at.
    const agentListing = showing?.listing?.agentListing || {};
    const { lockboxLocation, lockboxType, lockCombo, lockboxPhotoUpload } = agentListing;
    const lockboxModalRef = useRef<any>(null);
    const feedbackModalRef = useRef<any>(null);
    const isUpdating = updatingShowing === showing?._id?.toString();
    const selections = ['Showing Details', 'Listing Details'];
    // -------------------------- End of States Values and Logic. --------------------------

    /**
     * if the user changes to a different showing request while still on
     * the showing details screen, make sure the correct showing is
     * fetched and displayed
     */
    useEffect(() => {
        history.listen((location: any) => {
            setSplitPath(location.pathname.split('/'));
        });
    }, [history]);

    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);

    useEffect(() => {
        // If showing id is not in path, add it so that the screen persists on refresh
        if (
            showing &&
            (splitPath[splitPath.length - 1]?.length === 0 ||
                splitPath[splitPath.length - 1] === 'showingDetails')
        ) {
            history.replace(`/showingDetails/${showing._id.toString()}`);
        }
        const showingObjectId = new BSON.ObjectID(splitPath[splitPath.length - 1]);
        if (!showing || JSON.stringify(showing._id) !== JSON.stringify(showingObjectId)) {
            fetchShowing(showingObjectId);
        }
    }, [splitPath]);

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const resize = () => {
        // STYLE_NOTE: SideBar and Headers taken into calculation of main content size
        setTimeout(() => {
            if (canResize) {
                setCardContainerSize({
                    width: window.innerWidth - 280,
                    height: window.innerHeight - 60,
                });
                setCanResize(false);
            }
        }, 500);
    };
    window.addEventListener('resize', resize);

    const closeMsg = () => {
        dispatch(closeDetailMsg());
        setDetailMsgOpen(false);
    };

    const aceptShowingRequest = () => {
        dispatch(acceptShowingRequestRequested(showing._id));
    };

    const declineShowingRequest = () => {
        dispatch(declineShowingRequestRequested(showing._id));
    };

    const header = showing && (
        <div className={styleSheet.header}>
            {`${getStatusForDisplay(showing.status)} Showing`}
            <div className={styleSheet.selectionContainer}>
                {selections.map((selection, index) => {
                    return (
                        <>
                            <ClickableWithFeedback
                                style={
                                    selection === selectedView
                                        ? {
                                              color: indexTheme.palette.blue,
                                          }
                                        : {}
                                }
                                onClick={() => {
                                    setSelectedView(selection);
                                }}
                            >
                                {selection}
                            </ClickableWithFeedback>
                            {index !== 1 && <div className={styleSheet.verticalSeparator} />}
                        </>
                    );
                })}
            </div>
        </div>
    );

    const buttons = [];

    if (showing?.status === 'requested') {
        buttons.push(
            {
                text: 'Accept',
                onPress: aceptShowingRequest,
                disabed: loadingUpdateStatus,
            },
            {
                text: 'Decline',
                onPress: declineShowingRequest,
                small: true,
                disabed: loadingUpdateStatus,
            },
        );
    }
    const negativeStatuses = ['cancelled', 'denied', 'declined'];
    if (negativeStatuses.includes(showing?.status)) {
        buttons.push({
            text: 'Reschedule',
            onPress: () => {
                setShowScheduleDrawer(true);
            },
        });
    }

    if (
        showing?.status === 'pending_internal' ||
        showing?.status === 'confirmed' ||
        showing?.status === 'logged'
    ) {
        buttons.push({
            text: 'Update Showing',
            onPress: () => {
                setShowScheduleDrawer(true);
            },
        });
    }

    if (showing?.status === 'completed') {
        if (!showing.feedback) {
            buttons.push({
                text: 'Leave Feedback',
                onPress: () => {
                    setFeedbackModal(true);
                },
            });
        }
    }

    const listingInfoBar = showing && (
        <div className={styleSheet.listingInfoBar}>
            <div className={styleSheet.leftHeader}>
                <span className={styleSheet.price}>
                    {'$' + formatCurrency(showing.listing.listPrice)}
                </span>
                <div className={styleSheet.verticalSeparator} />
                <span className={styleSheet.address1}>{showing.listing.address?.full}</span>
                <span className={styleSheet.address2}>
                    {showing.listing.address?.city}, {showing.listing.address?.state}{' '}
                    {showing.listing.address?.postal}
                </span>
                {showing.listing.mlsList?.length &&
                showing.listing.mlsList[0]?.standardStatus === 'Active' ? (
                    <span className={styleSheet.tag}>For Sale</span>
                ) : null}
            </div>
            <div className={styleSheet.buttonContainer}>
                {buttons.map((button, index) => {
                    return (
                        <ButtonComponent
                            className={styleSheet.button}
                            fill={!(index === 1)}
                            width={button.small ? 130 : 257}
                            onClick={button.onPress}
                            disabled={button.disabed}
                        >
                            {button.text}
                        </ButtonComponent>
                    );
                })}
            </div>
        </div>
    );

    const topContent = (
        <>
            {header}
            {listingInfoBar}
        </>
    );

    const overviewPageData = [];

    const statusColors: any = {
        Completed: 'green',
        Logged: 'green',
        Denied: 'red',
        Cancelled: 'red',
        Declined: 'red',
        Confirmed: 'blue',
        Pending: 'mediumGrey',
        Requested: 'mediumGrey',
    };

    let statusCopy: any = {};

    const agentRecordId = user?._id;
    const agentUserId = user?.stitchUserId;

    if (showing) {
        const { status, agentId, showingAssistantAgentUserId } = showing;

        const isBuyerAgent = agentRecordId.toString() === agentId.toString();
        const isShowingAssistant = agentUserId === showingAssistantAgentUserId;

        switch (status) {
            case 'requested':
                statusCopy.header = 'New Showing Request';
                break;
            case 'pending_internal':
                statusCopy.header = 'Almost There.';
                statusCopy.description =
                    'The seller client or listing agent will review your request shortly.';
                break;
            case 'confirmed':
                statusCopy.header = `You're all set.`;
                statusCopy.description = 'Your showing has been confirmed.';
                break;
            case 'completed':
                if (!showing.feedback) {
                    statusCopy.header = `How'd it go?`;
                    statusCopy.description =
                        'Your showing is complete, make sure to leave feedback for the listing agent.';
                } else {
                    statusCopy.header = `Your showing is complete`;
                    statusCopy.description = 'Thank you for leaving feedback.';
                }
                break;
            case 'denied':
                if (showing.denialReason?.length) {
                    statusCopy.header = 'Denial Reason';
                    statusCopy.description = showing.denialReason;
                } else {
                    statusCopy.header = 'Showing Denied';
                    statusCopy.description =
                        'The listing agent denied your showing. Please request another time to reschedule.';
                }
                break;
            case 'cancelled':
                statusCopy.header = 'No worries';
                statusCopy.description =
                    'This showing has been cancelled. If you need to reschedule, click the reschedule button above.';
                break;
            case 'logged':
                statusCopy.header = `We've logged your showing.`;
                statusCopy.description =
                    'This showing will now appear in your showings page and your clients itinerary.';
                break;
            case 'declined':
                statusCopy.header = `Declined Showing Request`;
                break;
            default:
                break;
        }

        const startTime = DateTime.fromJSDate(showing.start);
        const endTime = DateTime.fromJSDate(showing.end);

        const formattedShowingDate = startTime.toFormat('LLLL d, y');

        const formattedShowingTime = `${startTime.toFormat('h:mm')}${startTime
            .toFormat('a')
            .toLowerCase()} - ${endTime.toFormat('h:mm')}${endTime.toFormat('a').toLowerCase()}`;

        const formatLockBoxType = (type: string) => {
            switch (type) {
                case 'None':
                    return 'No Lockbox';
                case 'Other':
                    return 'Manual Lock Box';
                default:
                    return type;
            }
        };

        overviewPageData.push(
            <div className={styleSheet.cardContent}>
                <div className={styleSheet.cardFirstContent}>
                    <Tag
                        text={getStatusForDisplay(status)}
                        color={statusColors[getStatusForDisplay(status)]}
                        style={{ maxWidth: 82, marginBottom: 13 }}
                    />
                    <span className={styleSheet.cardHeader}>{statusCopy.header}</span>
                    {statusCopy.description && (
                        <span className={styleSheet.cardDescription}>{statusCopy.description}</span>
                    )}
                </div>
                <div className={styleSheet.timeAndDate}>
                    <div className={styleSheet.iconAndText}>
                        <img className={styleSheet.icon} src={GrayCal} />
                        <span className={styleSheet.dateTimeText}>{formattedShowingDate}</span>
                    </div>
                    <div className={styleSheet.iconAndText} style={{ marginTop: 8 }}>
                        <img className={styleSheet.icon} src={GrayClock} />
                        <span className={styleSheet.dateTimeText}>{formattedShowingTime}</span>
                    </div>
                </div>
            </div>,
        );

        if (showing.consumer && isBuyerAgent) {
            overviewPageData.push(
                <div className={styleSheet.cardContent}>
                    <div className={styleSheet.cardFirstContent}>
                        <span className={styleSheet.smallCardHeader}>Client</span>
                        {showing?.consumer?.profilePhotoUpload?.uri && (
                            <img
                                className={styleSheet.userPhoto}
                                src={showing.consumer.profilePhotoUpload.uri}
                            />
                        )}
                        <span className={styleSheet.smallCardHeader}>
                            {showing.consumer.firstName} {showing.consumer.lastName}
                        </span>
                        <span className={styleSheet.cardDescription} style={{ marginBottom: 10 }}>
                            {formatEmailForDisplay(showing.consumer.email)}
                        </span>
                        <span className={styleSheet.cardDescription}>
                            {formatPhoneNumberForDisplay(showing.consumer.phoneNumber)}
                        </span>
                    </div>
                    {/* <img className={styleSheet.cardArrowButton} src={DiagonalArrow} /> */}
                </div>,
            );
        }

        // Statuses with no current listing agent involvement
        const unmanagedStatuses = ['requested', 'logged', 'declined'];

        if (status === 'logged') {
            overviewPageData.push(
                <div className={styleSheet.reminderCardContent}>
                    <span className={styleSheet.reminderHeader}>Reminder:</span>
                    <span className={styleSheet.reminderText}>
                        Please make sure to schedule this showing using the listing agents’
                        preferred showing management platform.
                    </span>
                </div>,
            );
        }

        if (status === 'confirmed') {
            overviewPageData.push(
                <div className={styleSheet.cardContent}>
                    <span className={styleSheet.smallCardHeader} style={{ marginBottom: 10 }}>
                        Access Information
                    </span>
                    <span className={styleSheet.infoHeader}>Type</span>
                    <span className={styleSheet.infoText}>{formatLockBoxType(lockboxType)}</span>
                    {lockboxType !== 'None' && (
                        <>
                            <span className={styleSheet.infoHeader}>Location</span>
                            <span className={styleSheet.infoText}>{lockboxLocation}</span>
                            {lockboxPhotoUpload && (
                                <ClickableWithFeedback
                                    className={styleSheet.viewLocationLink}
                                    onClick={() => {
                                        setShowLockboxPhotoModal(true);
                                    }}
                                >
                                    <span>View Lock Location</span>
                                </ClickableWithFeedback>
                            )}
                            <span className={styleSheet.infoHeader}>Code</span>
                            <span className={styleSheet.infoText}>{lockCombo}</span>
                        </>
                    )}
                </div>,
                <div className={styleSheet.cardContent}>
                    <span
                        className={styleSheet.smallCardHeader}
                        style={{ marginBottom: 10, paddingRight: 50 }}
                    >
                        Download the mobile app to quickly access the home when you arrive at the
                        showing.
                    </span>
                    <div className={styleSheet.codeAndDescription}>
                        <img className={styleSheet.codeContainer} src={QRCode} />
                        <span
                            className={styleSheet.cardDescription}
                            style={{ width: 270, paddingRight: 0, marginLeft: 20 }}
                        >
                            View your appointment details & access the property at the time of your
                            showing with the Showingly mobile app. Scan the QR code to download.
                        </span>
                    </div>
                </div>,
            );
        }

        if (status === 'completed' || status === 'confirmed') {
            const showingInstructions = showing?.listing?.agentListing?.showingInstructions;
            overviewPageData.push(
                <div className={styleSheet.cardContent}>
                    <span className={styleSheet.smallCardHeader} style={{ marginBottom: 10 }}>
                        Showing Instructions
                    </span>
                    <span className={styleSheet.cardDescription}>{showingInstructions}</span>
                </div>,
            );
        }

        if (!unmanagedStatuses.includes(status)) {
            const listingAgents = showing?.listing?.agentListing?.agents;
            listingAgents?.map((listAg: any) => {
                overviewPageData.push(
                    <div className={styleSheet.cardContent}>
                        <div className={styleSheet.cardFirstContent}>
                            <span className={styleSheet.smallCardHeader}>
                                {listAg.type || 'Listing Agent'}
                            </span>
                            {listAg.profilePhotoUpload?.uri && (
                                <img
                                    className={styleSheet.userPhoto}
                                    src={listAg.profilePhotoUpload.uri}
                                />
                            )}
                            <span className={styleSheet.smallCardHeader}>
                                {listAg.firstName} {listAg.lastName}
                            </span>
                            <span
                                className={styleSheet.cardDescription}
                                style={{ marginBottom: 10 }}
                            >
                                {formatEmailForDisplay(listAg.email)}
                            </span>
                            <span className={styleSheet.cardDescription}>
                                {formatPhoneNumberForDisplay(listAg.phoneNumber)}
                            </span>
                        </div>
                        {/* <img className={styleSheet.cardArrowButton} src={DiagonalArrow} /> */}
                    </div>,
                );
            });

            if (showing.messageBlast?.message) {
                overviewPageData.push(
                    <div className={styleSheet.cardContent}>
                        <div className={styleSheet.cardRowHeader}>
                            {listingAgents[0].profilePhotoUpload?.uri && (
                                <img
                                    className={styleSheet.smallPhoto}
                                    src={listingAgents[0].profilePhotoUpload.uri}
                                />
                            )}
                            <span className={styleSheet.listingAgentName}>
                                {listingAgents[0].firstName} {listingAgents[0].lastName}
                            </span>
                            <span className={styleSheet.messageSentText}>
                                sent a message to you.
                            </span>
                        </div>
                        <div className={styleSheet.cardMessageBlast}>
                            <div className={styleSheet.viewMessages}>
                                View Message Blasts
                                <img className={styleSheet.messagesArrow} src={RightArrow} />
                            </div>
                            <span className={styleSheet.viewMessagesDescription}>
                                Stay up to date by viewing messages sent to you by the listing
                                agent.
                            </span>
                        </div>
                    </div>,
                );
            }
        }
    }
    const columnCount = Math.floor((cardContainerSize.width + 50) / 516);
    const alignProps = overviewPageData.length < columnCount ? { alignSelf: 'flex-start' } : {};
    const slotGrid = (
        <>
            <div
                className={styleSheet.cardsContainer}
                style={{ maxWidth: columnCount * 516, ...alignProps }}
            >
                <AnimatedModal
                    set={setShowLockboxPhotoModal}
                    modalVisible={showLockboxPhotoModal}
                    style={{ top: null, margin: 'auto', width: 812 }}
                    ref={lockboxModalRef}
                >
                    <div className={styleSheet.header} style={{ padding: 16, background: 'none' }}>
                        <Typography color={indexTheme.palette.mediumGrey} textStyle={'p1'}>
                            View Lock Location
                        </Typography>
                        <img
                            className={styleSheet.closeButton}
                            src={close}
                            onClick={() => {
                                lockboxModalRef?.current?.closeModal();
                            }}
                        />
                    </div>
                    <div
                        className={styleSheet.header}
                        style={{
                            ...indexTheme.paddingVertical(11),
                            ...indexTheme.paddingHorizontal(16),
                            background: 'none',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography color={indexTheme.palette.mediumGrey} textStyle={'p1'}>
                            Lock Location:
                        </Typography>
                        <Typography textStyle={'p1'}>{lockboxLocation}</Typography>
                    </div>
                    {lockboxPhotoUpload?.uri && (
                        <div className={styleSheet.lockboxPhotoContainer}>
                            <img
                                src={lockboxPhotoUpload?.uri}
                                className={styleSheet.lockboxPhoto}
                            />
                        </div>
                    )}
                </AnimatedModal>
                {overviewPageData.map((card) => {
                    return (
                        <div
                            className={styleSheet.card}
                            style={{
                                width: columnCount === 1 ? 600 : 500,
                            }}
                        >
                            {card}
                        </div>
                    );
                })}
            </div>
            {isdetailMsgOpen && showing && (
                <Modal icon={msgCheck} icon2={msgCheckReq} data={showing} isOpen={closeMsg} />
            )}
        </>
    );

    const listingDetails = (
        <div className={styleSheet.listingDetailsContainer}>
            <ListingDetailsComponent listing={showing?.listing || {}} header={null} />
        </div>
    );

    useEffect(() => {
        if (initiallyListingDetails) setSelectedView('Listing Details');
    }, [initiallyListingDetails]);
    const listing = showing?.listing;

    const photo = showing?.consumer?.photo;
    const profilePic = photo?.uri
        ? { uri: photo.uri }
        : photo?.base64 && photo?.mime
        ? { uri: `data:${photo.mime};base64,${photo.base64}` }
        : {
              uri: 'https://showingly-image-assets.s3.amazonaws.com/Default+Unadded+Avatar.png',
          };
    const consumerToPass = showing?.consumer
        ? {
              profilePicture: profilePic,
              firstName: showing.consumer.firstName,
              lastName: showing.consumer.lastName,
              stitchUserId: showing.consumer.stitchUserId,
          }
        : null;

    const defaultValue = getTimeDateShowing(showing);
    const content = (
        <>
            <ConnectedScheduleScreen
                listing={listing}
                opType={'Reschedule'}
                defaultValue={defaultValue}
                onClose={() => {
                    setShowScheduleDrawer(false);
                    if (updatelog.detailMsg) {
                        setDetailMsgOpen(showing?.status);
                    }
                }}
                clientDrawerInteract={setShowAddClientDrawer}
                visible={showScheduleDrawer}
                rescheduleDetails={{
                    passedShowingId: showing?._id,
                    passedAgentId: showing?.agentId,
                    passedClient: consumerToPass,
                    passedShowingStatus: showing?.status,
                    passedShowingType: showing?.type,
                }}
            />
            <AddClientDrawer
                visible={showAddClientDrawer}
                onClose={() => setShowAddClientDrawer(false)}
                backgroundOpacity={0}
            />
            <LeaveFeedbackModal
                showing={showing}
                ref={feedbackModalRef}
                set={setFeedbackModal}
                modalVisible={feedbackModal}
                closeModal={() => feedbackModalRef?.current?.closeModal()}
                loading={isUpdating}
                changeStatus={() => {}}
            />
            {topContent}
            {selectedView === 'Listing Details' ? listingDetails : slotGrid}
        </>
    );

    return (
        <div className={styleSheet.root} style={{ height: cardContainerSize.height }}>
            {showing && !loading ? (
                content
            ) : (
                <div className={styleSheet.loaderContainer}>
                    <Loader />
                </div>
            )}
        </div>
    );
};
export default ShowingDetails;
