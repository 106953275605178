import { DateTime } from 'luxon';

export const CALENDAR_PREFIX = 'calendar/';

export const FETCH_ALL_SHOWINGS_REQUESTED = CALENDAR_PREFIX + 'FETCH_ALL_SHOWINGS_REQUESTED';
export const FETCH_ALL_SHOWINGS_SUCCEEDED = CALENDAR_PREFIX + 'FETCH_ALL_SHOWINGS_SUCCEEDED';
export const FETCH_ALL_SHOWINGS_FAILED = CALENDAR_PREFIX + 'FETCH_ALL_SHOWINGS_FAILED';

export const fetchAllShowingsRequested = (startOfWeek: string | null, endOfWeek: string | null) => {
    return { type: FETCH_ALL_SHOWINGS_REQUESTED, startOfWeek, endOfWeek };
};

export const fetchAllShowingsSucceeded = (
    allShowings = [],
    startOfWeek: string | null,
    endOfWeek: string | null,
) => {
    return { type: FETCH_ALL_SHOWINGS_SUCCEEDED, allShowings, startOfWeek, endOfWeek };
};

export const fetchAllShowingsFailed = (errors: string[] = []) => {
    return { type: FETCH_ALL_SHOWINGS_FAILED, errors };
};
