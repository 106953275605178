import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddCardModal } from 'web-lib';

import {
    addCardAndChangePaymentRequested,
    addCardAndSubscribeRequested,
    addCardRequested,
    changePaymentRequested,
    setIsDelinquent,
    setStripeActionStatus,
    subscribeRequested,
} from '../actions';
import {
    getStripeActionStatus,
    isPaymentLoading,
    getPaymentModalType,
    getSubscriptionPriceId,
    getPaymentMethods,
    getErrors,
    getPaymentError,
    getIsDelinquent,
} from '../selectors';

type AddCardPageProps = {};

const AddCardPage = ({}: AddCardPageProps) => {
    const history = useHistory();
    const splitPath = history.location.pathname.split('/');
    const historyModalType = splitPath[splitPath.length - 1];

    const modalType: string = useSelector(getPaymentModalType);
    const subscriptionPriceId: string = useSelector(getSubscriptionPriceId);
    const paymentMethods: any = useSelector(getPaymentMethods);
    const stripeActionStatus: string | null = useSelector(getStripeActionStatus);
    const loading: boolean = useSelector(isPaymentLoading);
    const errors: string[] = useSelector(getErrors);
    const paymentError: string | null = useSelector(getPaymentError);
    const isDelinquent: boolean = useSelector(getIsDelinquent);

    const dispatch = useDispatch();
    const addCard: Function = (paymentMethodId: any, name: any, zip: any) =>
        dispatch(addCardRequested(paymentMethodId, name, zip));
    const addCardAndSubscribe: Function = (
        paymentMethodId: any,
        priceId: any,
        name: any,
        zip: any,
    ) => dispatch(addCardAndSubscribeRequested(paymentMethodId, priceId, name, zip));
    const subscribe: Function = (paymentMethodId: any, priceId: any) =>
        dispatch(subscribeRequested(paymentMethodId, priceId));
    const addCardAndChangePaymentMethod: Function = (paymentMethodId: any, name: any, zip: any) =>
        dispatch(addCardAndChangePaymentRequested(paymentMethodId, name, zip));
    const changePaymentMethod: Function = (paymentMethodId: any) =>
        dispatch(changePaymentRequested(paymentMethodId));
    const resetStripeActionStatus: Function = () => dispatch(setStripeActionStatus(null));
    const setNotDelinquent = () => dispatch(setIsDelinquent(false));
    const setDelinquent: Function = () => dispatch(setIsDelinquent('true'));

    return (
        <div style={{ paddingTop: 50 }}>
            <AddCardModal
                addCard={addCard}
                addCardAndSubscribe={addCardAndSubscribe}
                addCardAndChangePaymentMethod={addCardAndChangePaymentMethod}
                changePaymentMethod={changePaymentMethod}
                error={paymentError}
                subscribe={subscribe}
                modalType={historyModalType || modalType}
                subscriptionPriceId={subscriptionPriceId}
                paymentMethods={paymentMethods}
                stripeActionStatus={stripeActionStatus}
                loading={loading}
                resetStripeActionStatus={(removeDelinquency: any) => {
                    resetStripeActionStatus();
                    if (removeDelinquency === true) {
                        setNotDelinquent();
                    }
                }}
                backToDelinquentModal={() => setDelinquent()}
                isDelinquent={isDelinquent}
            />
        </div>
    );
};

export default AddCardPage;
