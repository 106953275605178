import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BSON } from 'realm-web';
import { DateTime } from 'luxon';
import { CommentModal, CommentPreviewCard } from 'web-lib';
import Feed from './Feed';
import UpcomingEvents from './UpcomingEvents';

import {
    createContentRequested,
    resetContentCreated,
    getBrokerageFeedRequested,
    clearBrokerageErrors,
    updateFeedItemRequested,
} from '../../actions';
import { getAgentObjectId, getBrokerageObjectId } from '../../../../../store/api/selectors';
import {
    FeedItem,
    FeedItemContentType,
    EventTypeOptions,
    BrokerageData,
    Comment,
} from '../../../../../utils/constants';

import styles from './index.module.css';

import {
    getBrokerageData,
    getBrokerageErrors,
    getBrokerageFeed,
    getSelectedBrokerage,
    hasFetchedFeed,
    isBrokerageLoading,
    isCreatingContent,
    isFeedLoading,
    isNewContentCreated,
} from '../../selectors';
import { getBrokerages, getMyBrokerages, getProfileData } from '../../../Profile/selectors';

const startDate = new Date();
startDate.setHours(0);
startDate.setMinutes(0);
startDate.setSeconds(0);
startDate.setMilliseconds(0);

export const defaultContentData: FeedItem = {
    end: startDate,
    start: startDate,
    description: '',
    image: '',
    link: '',
    eventType: EventTypeOptions.None,
    title: '',
    type: FeedItemContentType.Post,
    going: [],
    notGoing: [],
    likes: [],
    comments: [],
    upvotes: {},
    upvotesCount: 0,
};
interface BrokerageFeedProps {}
const BrokerageFeed = ({}: BrokerageFeedProps) => {
    const brokerageId = useSelector(getBrokerageObjectId);
    const profileData: any = useSelector(getProfileData);
    const brokerageData: BrokerageData = useSelector(getSelectedBrokerage);
    const brokerageFeed: FeedItem[] = useSelector(getBrokerageFeed);
    const agentObjectId: any = useSelector(getAgentObjectId);
    const createLoading: boolean = useSelector(isCreatingContent);
    const errors: any = useSelector(getBrokerageErrors);
    const fetchedFeed: boolean = useSelector(hasFetchedFeed);
    const loading: boolean = useSelector(isBrokerageLoading);
    const loadingFeed: boolean = useSelector(isFeedLoading);
    const newContentCreated: boolean = useSelector(isNewContentCreated);
    const brokerages: BrokerageData[] = useSelector(getMyBrokerages);

    const dispatch = useDispatch();
    const createContent: Function = (
        brokerageId: BSON.ObjectId,
        contentData: FeedItem,
        setContentData: Function,
    ) => dispatch(createContentRequested(brokerageId, contentData, setContentData));
    const clearErrors: Function = () => dispatch(clearBrokerageErrors());
    const updateFeedItem: Function = (postId: any) => dispatch(updateFeedItemRequested(postId));

    const [contentData, setContentData] = useState<FeedItem>(defaultContentData);
    const [createPost, setCreatePost] = useState<boolean>(false);
    const [containerHeight, setContainerHeight] = useState<number>(50);
    const [selectedItem, setSelectedItem] = useState<FeedItem>(); // used to conditionally render the CommentPost popup modal

    useEffect(() => {
        if (errors.length) {
            clearErrors();
        }
        if (createPost) {
            createContentHandler();
        }
    }, [contentData]);
    useEffect(() => {
        if (brokerageData) {
            dispatch(getBrokerageFeed(brokerageData?._id));
        }
    }, [brokerageData]);

    useEffect(() => {
        const updatedSelectedItem = brokerageFeed.find(
            (feedItem: FeedItem) => feedItem?._id?.toString() === selectedItem?._id?.toString(),
        );
        if (updatedSelectedItem) {
            setSelectedItem(updatedSelectedItem);
        }
    }, [brokerageFeed]);

    // calculate the size of the comment section on the modal
    useEffect(() => {
        const card = document.getElementById('card');
        const cardHeight = card?.clientHeight;
        const usableHeightInContainer = 570; // height of container is 700px, height of the comment container and button is is 225px
        const newContainerHeight = usableHeightInContainer - (cardHeight || 0);
        setContainerHeight(newContainerHeight);
    }, [selectedItem]);

    // append the created obj onto the document before being saved
    const createContentHandler = () => {
        const newContentData = {
            ...contentData,
            type: contentData.type.toLocaleLowerCase() as FeedItemContentType,
            created: {
                agentUserId: new BSON.ObjectId(profileData._id),
                name: profileData?.firstName + ' ' + profileData?.lastName,
                photoUri: (profileData?.profilePhotoUpload?.uri || '') as string,
                type: 'Agent',
                createdAt: DateTime.fromMillis(Date.now()).toJSDate(),
            },
        };
        createContent(brokerageData._id, newContentData, setContentData);
        setCreatePost(false);
    };

    const handleLeaveComment = (comment: Comment, item: FeedItem) => {
        window.scrollTo(0, 0);
        updateFeedItem({
            userObjectId: agentObjectId,
            eventId: item._id,
            type: 'comment',
            comment,
        });
    };

    return (
        <div className={styles.root}>
            {selectedItem && (
                <CommentModal
                    containerHeight={containerHeight}
                    card={
                        <div id={'card'} style={{ width: '100%' }}>
                            <CommentPreviewCard
                                userObjectId={agentObjectId}
                                item={selectedItem}
                                setSelectedItem={setSelectedItem}
                                updateFeedItem={updateFeedItem}
                            />
                        </div>
                    }
                    item={selectedItem}
                    onExit={() => setSelectedItem(undefined)}
                    onLeaveComment={(comment: Comment, item: FeedItem) =>
                        handleLeaveComment(comment, item)
                    }
                />
            )}

            <Feed
                brokerageFeed={brokerageFeed}
                createContentData={contentData}
                setCreatePost={setCreatePost}
                newContentCreated={newContentCreated}
                resetContentCreated={() => dispatch(resetContentCreated())}
                setCreateContentData={setContentData}
                newPostLoading={createLoading}
                loading={loadingFeed && !fetchedFeed}
                errors={errors}
                setSelectedItem={setSelectedItem}
            />

            <UpcomingEvents
                feed={brokerageFeed
                    .filter(
                        // toLowerCase since the item.type is stored with a lowercase first letter
                        (item: FeedItem) =>
                            item.type === FeedItemContentType.Event.toLocaleLowerCase() &&
                            item.start.getTime() > Date.now(),
                    )
                    .sort((a: FeedItem, b: FeedItem) => a.start.getTime() - b.start.getTime())
                    .slice(0, 5)}
                loading={loadingFeed && !fetchedFeed}
            />
        </div>
    );
};

export default BrokerageFeed;
