import { STATUS } from '../../utils/constants';
import { BSON } from 'realm-web';

import { AUTH_PREFIX, AUTH_UPDATE_PENDING_USER, AUTH_UPDATE_STITCH_USER } from './constants';

export enum AUTH_ACTION {
    Brokerage = '[Auth] Brokerage',
    Create = '[Auth] Create',
    Initialization = '[Auth] Initialization',
    Prices = '[Auth] Prices',
    Market = '[Auth] Market',
    Coupon = '[Auth] Coupon',
    User_Login = '[Auth] User Login',
    User_Logout = '[Auth] User Logout',
    Update_Credentials = '[Auth] Update Credentials',
    Finish_Signup = '[Auth] Finish Signup',
    Agent_Documents = '[Auth] Agent Documents',
    Agent_Search = '[Auth] Agent Search',
    Verification = '[Auth] Verification',
    VerifyCode = '[Auth] Verify Code',
    Registration = '[Auth] Registration',
    ResetPassword = '[Auth] Reset Password',
    OIDC_RequestToken = '[Auth] RequestAccessToken',
    DisableAuthLoading = '[Auth] DisableAuthLoader',
    AuthLinkExpiredOrIsInvalid = '[Auth] AuthLinkExpiredOrIsInvalid',
    IsUnauthenticated = '[Auth] IsUnauthenticated',
    FetchConsumerRecord = '[Auth] FetchConsumerRecord',
    ClearVerification = '[Auth] Clear Verification',
    AcceptTermsOfService = '[Auth] Accept Terms of Service',
    SendAppDownloadLink = '[Auth] Send App Download Link',
    ResetAppLinkSent = '[Auth] Reset Sent Status',
}

export const UPDATE_PENDING_USER = `${AUTH_PREFIX}${AUTH_UPDATE_PENDING_USER}`;
export const UPDATE_STITCH_USER = `${AUTH_PREFIX}${AUTH_UPDATE_STITCH_USER}`;

export const SET_PRACTICE_INFO = `${AUTH_PREFIX}SET_PRACTICE_INFO`;

export const SET_AGENT_INFO = `${AUTH_PREFIX}SET_AGENT_INFO`;

export const STORE_AGENT_RECORD = `${AUTH_PREFIX}STORE_AGENT_RECORD`;
export const INITIALIZATION = `${AUTH_PREFIX}INITIALIZATION`;
export const INITIALIZATION_DONE = `${AUTH_PREFIX}INITIALIZATION_DONE`;
export const INITIALIZATION_FAILED = `${AUTH_PREFIX}INITIALIZATION_FAILED`;

export const USER_LOGIN_REQUESTED = `${AUTH_PREFIX}USER_LOGIN_REQUESTED`;
export const USER_LOGIN_REQUEST_SUCCEEDED = `${AUTH_PREFIX}USER_LOGIN_REQUEST_SUCCEEDED`;
export const USER_LOGIN_REQUEST_FAILED = `${AUTH_PREFIX}USER_LOGIN_REQUEST_FAILED`;

export const AGENT_LOGIN_REQUESTED = `${AUTH_PREFIX}AGENT_LOGIN_REQUESTED`;
export const AGENT_LOGIN_SUCCEEDED = `${AUTH_PREFIX}AGENT_LOGIN_SUCCEEDED`;
export const AGENT_LOGIN_FAILED = `${AUTH_PREFIX}AGENT_LOGIN_FAILED`;

export const LOG_OUT_REQUESTED = `${AUTH_PREFIX}LOG_OUT_REQUESTED`;
export const LOG_OUT_REQUEST_SUCCEEDED = `${AUTH_PREFIX}LOG_OUT_REQUEST_SUCCEEDED`;
export const LOG_OUT_REQUEST_FAILED = `${AUTH_PREFIX}LOG_OUT_REQUEST_FAILED`;

export const RESET_PASSWORD_REQUESTED = `${AUTH_PREFIX}RESET_PASSWORD_REQUESTED`;
export const RESET_PASSWORD_SUCCEEDED = `${AUTH_PREFIX}RESET_PASSWORD_SUCCEEDED`;
export const RESET_PASSWORD_FAILED = `${AUTH_PREFIX}RESET_PASSWORD_FAILED`;

export const SET_PASSWORD_RESET_ACTIVE = `${AUTH_PREFIX}SET_PASSWORD_RESET_ACTIVE`;

export function updatePendingUser(pendingUser: any) {
    return { type: UPDATE_PENDING_USER, pendingUser };
}

export function updateStitchUser(stitchUser: any) {
    return { type: UPDATE_STITCH_USER, stitchUser };
}

export function storeAgentRecord(agent: any) {
    return {
        type: STORE_AGENT_RECORD,
        agent,
    };
}

export function initialization() {
    return {
        type: INITIALIZATION,
        initializing: true,
    };
}

export function initializationDone(isAuthenticated: boolean) {
    return {
        type: INITIALIZATION_DONE,
        initializing: false,
        isAuthenticated,
    };
}

export function initializationFailed() {
    return { type: INITIALIZATION_FAILED };
}

export function userLoginRequested(email: string, password: string, history: any, path?: any) {
    return { type: USER_LOGIN_REQUESTED, email, password, history, path };
}

export function userLoginRequestSucceeded(client: any) {
    return { type: USER_LOGIN_REQUEST_SUCCEEDED, client };
}

export function userLoginRequestFailed(errors: string[] = []) {
    return { type: USER_LOGIN_REQUEST_FAILED, errors };
}

export function agentLoginRequested(email: string, password: string, history: any) {
    return { type: AGENT_LOGIN_REQUESTED, email, password, history };
}

export function agentLoginSucceeded(client: any) {
    return { type: AGENT_LOGIN_SUCCEEDED, client };
}

export function agentLoginFailed(errors: string[] = []) {
    return { type: AGENT_LOGIN_FAILED, errors };
}

export function logoutRequested() {
    return { type: LOG_OUT_REQUESTED };
}

export function logoutRequestSucceeded() {
    return { type: LOG_OUT_REQUEST_SUCCEEDED };
}

export function logoutRequestFailed(errors: any) {
    return { type: LOG_OUT_REQUEST_FAILED, errors };
}

export function getMarketRequested() {
    return {
        type: AUTH_ACTION.Market,
        status: STATUS.Requested,
    };
}

export function getMarketSucceeded(markets: any) {
    return {
        type: AUTH_ACTION.Market,
        status: STATUS.Succeeded,
        markets,
    };
}

export function getMarketFailed(errors: string[] = []) {
    return {
        type: AUTH_ACTION.Market,
        status: STATUS.Failed,
        errors,
    };
}

export const fetchAgentDocumentsRequested = (
    searchText: string,
    marketName?: string | null,
    searchByAgentId?: boolean | null,
) => {
    return {
        type: AUTH_ACTION.Agent_Documents,
        status: STATUS.Requested,
        searchText,
        marketName,
        searchByAgentId,
    };
};

export const fetchAgentDocumentsSucceeded = (entryAgents: any[] | null) => {
    return {
        type: AUTH_ACTION.Agent_Documents,
        status: STATUS.Succeeded,
        entryAgents,
    };
};

export const fetchAgentsDocumentsFailed = (errors: any[] = []) => {
    return {
        type: AUTH_ACTION.Agent_Documents,
        status: STATUS.Failed,
        errors,
    };
};

export const textSearchAgentsRequested = (searchText: string, searchByAgentId?: boolean | null) => {
    return {
        type: AUTH_ACTION.Agent_Search,
        status: STATUS.Requested,
        searchText,
        searchByAgentId,
    };
};

export const textSearchAgentsSucceeded = (entryAgents: any[] | null) => {
    return {
        type: AUTH_ACTION.Agent_Search,
        status: STATUS.Succeeded,
        entryAgents,
    };
};

export const textSearchAgentsFailed = (errors: any[] = []) => {
    return {
        type: AUTH_ACTION.Agent_Search,
        status: STATUS.Failed,
        errors,
    };
};

export const sendVerificationMessageRequested = (
    method: 'email' | 'phone',
    receiver: string,
    isResend: boolean,
) => {
    return {
        type: AUTH_ACTION.Verification,
        status: STATUS.Requested,
        method,
        receiver,
        isResend,
    };
};

export const sendVerificationMessageSucceedeed = () => {
    return {
        type: AUTH_ACTION.Verification,
        status: STATUS.Succeeded,
    };
};

export const sendVerificationMessageFailed = (errors: any[] = []) => {
    return {
        type: AUTH_ACTION.Verification,
        status: STATUS.Failed,
        errors,
    };
};

export const verifyCodeRequested = (
    method: 'email' | 'phone',
    code: any,
    emailOrPhone?: string,
    linkingAgentObjId?: BSON.ObjectId,
) => {
    return {
        type: AUTH_ACTION.VerifyCode,
        status: STATUS.Requested,
        method,
        code,
        emailOrPhone,
        linkingAgentObjId,
    };
};

export const verifyCodeSucceedeed = () => {
    return {
        type: AUTH_ACTION.VerifyCode,
        status: STATUS.Succeeded,
    };
};

export const verifyCodeFailed = (errors: any[] = []) => {
    return {
        type: AUTH_ACTION.VerifyCode,
        status: STATUS.Failed,
        errors,
    };
};

export const CLEAR_ERRORS = `${AUTH_PREFIX}CLEAR_ERRORS`;
export const clearAuthErrors = () => ({ type: CLEAR_ERRORS });

export function registrationRequested(userEmail: string, password: string, userObjectId: any) {
    return {
        type: AUTH_ACTION.Registration,
        status: STATUS.Requested,
        userEmail,
        password,
        userObjectId,
    };
}

export function registrationRequestSucceeded() {
    return {
        type: AUTH_ACTION.Registration,
        status: STATUS.Succeeded,
    };
}

export function registrationRequestFailed(errors: string[] = []) {
    return {
        type: AUTH_ACTION.Registration,
        status: STATUS.Failed,
        errors,
    };
}

export function resetPasswordRequested(userEmail: string, password: string, userObjectId: any) {
    return {
        type: AUTH_ACTION.ResetPassword,
        status: STATUS.Requested,
        userEmail,
        password,
        userObjectId,
    };
}

export function resetPasswordSucceeded() {
    return {
        type: AUTH_ACTION.ResetPassword,
        status: STATUS.Succeeded,
    };
}

export function resetPassFailed(errors: string[] = []) {
    return {
        type: AUTH_ACTION.ResetPassword,
        status: STATUS.Failed,
        errors,
    };
}

export function requestAccessTokenSSO(
    code: string | null | string[],
    codeProviderName: string | null | string[],
    redirectURI: string | null | string[],
    agentMlsId: string | null | string[],
    key: string | null | string[],
    dtm: string | null | string[],
    withoutOIDC: boolean = false,
) {
    return {
        type: AUTH_ACTION.OIDC_RequestToken,
        status: STATUS.Requested,
        code,
        codeProviderName,
        redirectURI,
        agentMlsId,
        key,
        dtm,
        withoutOIDC,
    };
}

export function requestAccessTokenSSOSucceeded(
    retrievedToken: any,
    codeProviderName: string,
    agentMlsId: any,
    withoutOIDC: boolean = false,
) {
    return {
        type: AUTH_ACTION.OIDC_RequestToken,
        status: STATUS.Succeeded,
        retrievedToken,
        codeProviderName,
        agentMlsId,
        withoutOIDC,
    };
}

export function requestAccessTokenSSOFailed(errors: string[] = []) {
    return {
        type: AUTH_ACTION.OIDC_RequestToken,
        status: STATUS.Failed,
        errors,
    };
}

export function disableAuthLoading() {
    return {
        type: AUTH_ACTION.DisableAuthLoading,
        status: STATUS.Requested,
        loading: false,
    };
}

export function hasAuthLinkExpiredOrIsInvalid() {
    return {
        type: AUTH_ACTION.AuthLinkExpiredOrIsInvalid,
        status: STATUS.Requested,
    };
}

export function isUnauthenticated() {
    return {
        type: AUTH_ACTION.IsUnauthenticated,
        status: STATUS.Requested,
    };
}
export function fetchConsumerRecord(consumerUserId: string) {
    return {
        type: AUTH_ACTION.FetchConsumerRecord,
        status: STATUS.Requested,
        consumerUserId,
    };
}
export function fetchConsumerRecordSucceeded(consumerRecord: any) {
    return {
        type: AUTH_ACTION.FetchConsumerRecord,
        status: STATUS.Succeeded,
        consumerRecord,
    };
}

export function fetchConsumerRecordFailed(errors: any) {
    return {
        type: AUTH_ACTION.FetchConsumerRecord,
        status: STATUS.Failed,
        errors,
    };
}

export function clearVerification() {
    return {
        type: AUTH_ACTION.ClearVerification,
    };
}

export function setAuthPracticeInfo(authMarket: any, authMls: any) {
    return {
        type: SET_PRACTICE_INFO,
        authMarket,
        authMls,
    };
}

export const setAuthAgentInfo = (authAgent: any) => {
    return {
        type: SET_AGENT_INFO,
        authAgent,
    };
};

export function acceptTermsOfServiceRequested(urls: Array<String>, tempUserId = '') {
    return {
        type: AUTH_ACTION.AcceptTermsOfService,
        status: STATUS.Requested,
        urls,
        tempUserId,
    };
}

export function acceptTermsOfServiceSucceeded() {
    return {
        type: AUTH_ACTION.AcceptTermsOfService,
        status: STATUS.Succeeded,
    };
}

export function acceptTermsOfServiceFailed(errors: any) {
    return {
        type: AUTH_ACTION.AcceptTermsOfService,
        status: STATUS.Failed,
        errors,
    };
}

export function sendAppDownloadLink(phone: string) {
    return {
        type: AUTH_ACTION.SendAppDownloadLink,
        status: STATUS.Requested,
        phone,
    };
}

export function resetAppLinkSent() {
    return {
        type: AUTH_ACTION.ResetAppLinkSent,
        status: STATUS.Requested,
    };
}

export function passwordResetRequested(email: string) {
    return {
        type: RESET_PASSWORD_REQUESTED,
        status: STATUS.Requested,
        email,
    };
}

export function passwordResetSucceeded() {
    return { type: RESET_PASSWORD_SUCCEEDED };
}

export function passwordResetFailed(errors: any) {
    return { type: RESET_PASSWORD_FAILED, errors };
}

// Enable/Disenable the user to verify their identity and then reset password without leaving site
export function setPasswordResetActive(passwordResetActive: boolean) {
    return { type: SET_PASSWORD_RESET_ACTIVE, passwordResetActive };
}
