import React, { memo, ReactNode } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../../../../../components/Typography';
import Tag from '../../../../../components/Tag';
import ExclaimIcon from '../../../../../images/exclaim.svg';
import SuccessIcon from '../../../../../images/successCheck.svg';

interface Props {
    children: ReactNode;
    title: string;
    titleIcon?: ReactNode;
    required?: boolean;
    fulfilled?: boolean;
    optional?: boolean;
}
const ConfigureListingModule = ({
    children,
    title,
    titleIcon,
    required,
    fulfilled,
    optional,
}: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div className={styleSheet.moduleRoot}>
            <div className={styleSheet.titleRowContainer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography textStyle={'p1'}>{title}</Typography>
                    {titleIcon ?? null}
                </div>
                {required || optional ? (
                    <Tag
                        text={optional ? 'Optional' : 'Required'}
                        color={optional ? 'mediumGrey' : fulfilled ? 'green' : 'red'}
                        Icon={optional ? null : fulfilled ? SuccessIcon : ExclaimIcon}
                    />
                ) : null}
            </div>
            <div className={styleSheet.childContainer}>{children}</div>
        </div>
    );
};
export default ConfigureListingModule;
