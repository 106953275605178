import { SOCIAL_ACTION } from './actions';
import { STATUS } from '../../../store/api/constants';
import {
    AgentSimple,
    SocialDocument,
    SocialItem,
    SocialUserSimple,
} from '../../../utils/constants';

export const initialState = {
    errors: [],

    // feedItems are the items stored for the current user
    feedItems: [],
    feedItemsByAgentId: {} as any,

    loadingMetrics: false,
    loadingStats: false,
    updatingPostItem: false,
    creatingPostItem: false,
    socialFeedPageNumber: 0,
    feedLoading: true,
    selectedAgent: null,
    selectedAgentMetrics: { buyer: null, seller: null },

    // contains all the social documents fetched
    socialDocuments: [],
    fetchingSocialDocument: false,

    followingAgentLoading: false, // indicates the time loading the confirmation of following another agent
    loading: false,
    agentFullDocument: null,
    isAgentPending: false,
    isMyAgentPending: false,
    searchedAgents: [],
    myAgentFullDocument: null,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;

    // returns true if socialDocuments array contains social object, false if not
    const socialDocumentsIncludesDocument = (newDocument: SocialDocument) =>
        !!state.socialDocuments.find(
            (item: SocialDocument) =>
                item.agentObjectId?.toString() === newDocument.agentObjectId?.toString(),
        );

    switch (type) {
        case SOCIAL_ACTION.FetchFeed: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        feedLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    // handle optional agentId being passed in, storing a different users feed
                    if (action.agentId !== null) {
                        return {
                            ...state,
                            feedLoading: false,
                            feedItemsByAgentId: {
                                ...state.feedItemsByAgentId,
                                [action.agentId]: action.feedItems,
                            },
                        };
                    }

                    return {
                        ...state,
                        feedLoading: false,
                        feedItems: action.feedItems,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        feedLoading: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.UpdateFeedItem: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        updatingPostItem: true,
                    };
                }

                case STATUS.Succeeded: {
                    // we have to update every item inside of the feedItemsByAgentId just in case a liked post appears in multiple places
                    let newFeedItemsByAgentId: any = {};
                    for (const agentId in state.feedItemsByAgentId) {
                        newFeedItemsByAgentId[agentId] = state.feedItemsByAgentId[agentId].map(
                            (item: SocialItem) => {
                                if (item._id.toString() === action.updatedItem._id.toString()) {
                                    return action.updatedItem;
                                } else {
                                    return item;
                                }
                            },
                        );
                    }

                    return {
                        ...state,
                        updatingPostItem: false,

                        // update feed right away to remove unecessary calls
                        feedItems: state.feedItems.map((item: SocialItem) => {
                            if (item._id.toString() === action.updatedItem._id.toString()) {
                                return action.updatedItem;
                            } else {
                                return item;
                            }
                        }),

                        feedItemsByAgentId: newFeedItemsByAgentId,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        updatingPostItem: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.CreateFeedItem: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        creatingPostItem: true,
                    };
                }

                case STATUS.Succeeded: {
                    let newFeedItemsByAgentId: any = {};
                    for (const id in state.feedItemsByAgentId) {
                        if (id.toString() === action.newPost.agentObjectId.toString()) {
                            newFeedItemsByAgentId[id] = [
                                action.newPost,
                                ...state.feedItemsByAgentId[id],
                            ];
                        } else {
                            newFeedItemsByAgentId[id] = state.feedItemsByAgentId[id];
                        }
                    }

                    return {
                        ...state,
                        creatingPostItem: false,
                        feedItemsByAgentId: newFeedItemsByAgentId,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        creatingPostItem: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.UpdateFeedPagination: {
            const { currentPageNumber, isReset } = action;
            if (isReset) {
                return {
                    ...state,
                    socialFeedPageNumber: 0,
                    socialFeedEvents:
                        state.feedItems?.length > 50
                            ? state.feedItems.slice(0, 50)
                            : state.feedItems,
                };
            }
            return {
                ...state,
                socialFeedPageNumber: currentPageNumber + 1,
            };
        }

        case SOCIAL_ACTION.SelectedAgent: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        selectedAgent: action.selectedAgent,
                    };
                }

                case STATUS.Refresh: {
                    return {
                        ...state,
                        selectedAgent: null,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchAgentMetrics: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingMetrics: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loadingMetrics: false,
                        selectedAgentMetrics: {
                            buyer: action.buyerMetrics,
                            seller: action.sellerMetrics,
                        },
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingMetrics: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchAgentStats: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingStats: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loadingStats: false,
                        selectedAgentStats: action.agentStats,
                        selectedAgentMarkets: action.agentMarkets,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingStats: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchAgentSocialDocument: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        fetchingSocialDocument: true,
                    };
                }

                case STATUS.Succeeded: {
                    // if (socialDocumentsIncludesDocument(action.socialDocument)) {
                    //     return {
                    //         ...state,
                    //         fetchingSocialDocument: false,
                    //     };
                    // }

                    return {
                        ...state,
                        fetchingSocialDocument: false,
                        socialDocuments: [...state.socialDocuments, action.socialDocument],
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        fetchingSocialDocument: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FollowAndUnfollowAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        followingAgentLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const currentAgent = action.currentAgent;
                    const followedAgent = action.newlyFollowedAgent;

                    // map through social documents and append their new followers/followees to their social document locally
                    let socialDocuments = state.socialDocuments.map((agent: SocialDocument) => {
                        if (currentAgent.agentId.toString() === agent.agentObjectId.toString()) {
                            return {
                                ...agent,
                                followingAgentLoading: false,
                                following: [
                                    ...agent.following,
                                    {
                                        ...followedAgent,
                                        agentObjectId: followedAgent.agentId,
                                        profilePhotoUpload: { uri: followedAgent.photoUri || '' },
                                    },
                                ],
                            };
                        } else if (
                            followedAgent.agentId.toString() === agent.agentObjectId.toString()
                        ) {
                            return {
                                ...agent,
                                followingAgentLoading: false,
                                followers: [
                                    ...agent.followers,
                                    {
                                        ...currentAgent,
                                        agentObjectId: currentAgent.agentId,
                                        profilePhotoUpload: { uri: currentAgent.photoUri || '' },
                                    },
                                ],
                            };
                        } else {
                            return {
                                agent,
                                followingAgentLoading: false,
                            };
                        }
                    });

                    return {
                        ...state,
                        followingAgentLoading: false,
                        socialDocuments,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        followingAgentLoading: false,
                        errors: action.errors,
                    };
                }

                case STATUS.Refresh: {
                    const currentAgent = action.currentAgent;
                    const followedAgent = action.newlyUnfollowedAgent;

                    // map through social documents and remove their old followers/followees to their social document locally
                    let socialDocuments = state.socialDocuments.map((agent: SocialDocument) => {
                        if (currentAgent.agentId.toString() === agent.agentObjectId.toString()) {
                            return {
                                ...agent,
                                followingAgentLoading: false,
                                following: agent.following.filter(
                                    (user: SocialUserSimple) =>
                                        user.agentObjectId.toString() !==
                                        followedAgent.agentId.toString(),
                                ),
                            };
                        } else if (
                            followedAgent.agentId.toString() === agent.agentObjectId.toString()
                        ) {
                            return {
                                ...agent,
                                followingAgentLoading: false,
                                followers: agent.followers.filter(
                                    (user: SocialUserSimple) =>
                                        user.agentObjectId.toString() !==
                                        currentAgent.agentId.toString(),
                                ),
                            };
                        } else {
                            return {
                                agent,
                                followingAgentLoading: false,
                            };
                        }
                    });

                    return {
                        ...state,
                        followingAgentLoading: false,
                        socialDocuments,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchAgentFull: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        agentFullDocument: action?.agentFullDocument,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.IsAgentPending: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        isAgentPending: action?.isPending,
                    };
                }
                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.IsMyAgentPending: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        isMyAgentPending: action?.isPending,
                    };
                }
                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchSearchAgents: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        searchedAgents: action?.searchedAgents,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }

        case SOCIAL_ACTION.FetchMyAgentFull: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        myAgentFullDocument: action?.myAgentFullDocument,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }

        default:
            return state;
    }
}
