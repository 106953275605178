import React, { useState } from 'react';
import { Button, Loader } from 'web-lib';
import { DateTime } from 'luxon';

import { FeedItem } from '../../../../../../utils/constants';
import SingleEventView from './SingleEventView';

import styles from './index.module.css';
import DefaultEventImage from '../../../../../../images/feed/defaultEventImage.svg';

interface UpcomingEventsProps {
    feed: FeedItem[];
    loading: boolean;
}

const UpcomingEvents = ({ feed, loading }: UpcomingEventsProps) => {
    const [selectedEvent, setSelectedEvent] = useState<FeedItem | null>();
    const [currentEventCycle, setCurrentEventCycle] = useState<number>(0);

    // returns the th, nd, st, rd for a date
    // thanks stack overflow
    const getOrdinalNum = (n: number) =>
        n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');

    const eventAtString = (time: Date) => {
        if (!time) return '';

        const dateInt = parseInt(DateTime.fromMillis(time.getTime()).toFormat('d'));
        const year = DateTime.fromMillis(time.getTime()).toFormat('yyyy');
        const month = DateTime.fromMillis(time.getTime()).toFormat('MMMM');
        const timeOfDay = DateTime.fromMillis(time.getTime()).toFormat('t');

        return `${month} ${getOrdinalNum(dateInt)}, ${year} at ${timeOfDay
            .replace(/\s/g, '')
            .toLowerCase()}`;
    };

    return (
        <div className={styles.root}>
            {selectedEvent && (
                <SingleEventView
                    dateString={eventAtString(selectedEvent.start)}
                    onExit={() => setSelectedEvent(null)}
                    selectedEvent={selectedEvent}
                />
            )}

            <div className={styles.upcomingEventsContainer}>
                <h1 className={styles.upcomingEventsHeader}>Upcoming Events</h1>
                {loading ? (
                    <div className={styles.loaderContainer}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className={styles.eventDisplay}>
                            <div className={styles.imageDiv}>
                                <img
                                    src={feed[currentEventCycle]?.image || DefaultEventImage}
                                    className={styles.eventImage}
                                    alt={'eventImage'}
                                />
                            </div>
                            <span className={styles.eventTitle}>
                                {feed[currentEventCycle]?.title || ' '}
                            </span>
                            <span className={styles.eventDate}>
                                {eventAtString(feed[currentEventCycle]?.start)}
                            </span>

                            {feed[currentEventCycle] && (
                                <Button
                                    buttonText={'View Event'}
                                    className={styles.learnMoreButton}
                                    onClick={() => setSelectedEvent(feed[currentEventCycle])}
                                />
                            )}
                            {!feed?.length && !loading && (
                                <h1 className={styles.noEvents}>No Upcoming Events Yet</h1>
                            )}
                        </div>

                        <div className={styles.circles}>
                            {[...Array(feed.length).keys()].map((index: number) => (
                                <div
                                    className={styles.circle}
                                    key={index}
                                    onClick={() => setCurrentEventCycle(index)}
                                    style={{
                                        background: index === currentEventCycle ? '#d3d3d3' : '',
                                    }}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default UpcomingEvents;
