import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { formatCurrency, formatShowingType, formatMinutes } from '../../../utils/common/transforms';
import BedIcon from '../../../images/bedIcon.svg';
import BathIcon from '../../../images/bathIcon.svg';
import SQFTIcon from '../../../images/sqftIcon.svg';
import GoogleMap from 'google-map-react';
import BigMapMarker from '../../../images/bigMapMarker.svg';
import { formatPhoneNumberForDisplay, isMobile } from '../../../utils/common';
import { ConnectedNewsSection } from '../../../components/NewsSection';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface Props {
    header: any;
    listing: any;
}
const ListingDetailsComponent = ({ header = null, listing }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const connected = !!listing.agentListing?.listingId;

    const imageSection = (
        <div className={styleSheet.imageSectionContainer}>
            {listing.photos?.map((image: string) => {
                return (
                    <img
                        src={image}
                        className={styleSheet.image}
                        // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                        referrerPolicy={'no-referrer'}
                    />
                );
            })}
        </div>
    );

    const responsive = {
        mobile: {
            breakpoint: { max: 430, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30,
        },
    };

    const imageSectionMobile = (
        <div>
            <Carousel
                additionalTransfrom={0}
                arrows
                className=""
                keyBoardControl
                showDots={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                swipeable
            >
                {listing.photos?.map((image: string) => {
                    return (
                        <img
                            src={image}
                            className={styleSheet.image}
                            // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                            referrerPolicy={'no-referrer'}
                        />
                    );
                })}
            </Carousel>
        </div>
    );

    const listingBedBath = (
        <div className={styleSheet.listingBedBathContainer}>
            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={BedIcon} />{' '}
                {listing.property?.bedrooms ?? 'N/A'} bed
            </div>

            <div className={styleSheet.verticalSeparator} />

            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={BathIcon} />{' '}
                {listing.property?.bathrooms ?? 'N/A'} bath
            </div>

            <div className={styleSheet.verticalSeparator} />

            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={SQFTIcon} /> {listing.property?.area ?? 'N/A'}{' '}
                sqft.
            </div>
        </div>
    );

    const showingDetailsSection = (
        <div>
            {connected ? (
                <div className={styleSheet.detailSectionContainer}>
                    <div className={styleSheet.detailContainer}>
                        Showing Type:
                        <div className={styleSheet.greyTag}>
                            {formatShowingType(listing.agentListing?.type)}
                        </div>
                    </div>
                    <div className={styleSheet.verticalSeparator} />
                    <div className={styleSheet.detailContainer}>
                        Notice Required:
                        <div className={styleSheet.greyTag}>
                            {formatMinutes(listing.agentListing?.noticeRequired)}
                        </div>
                    </div>
                    <div className={styleSheet.verticalSeparator} />
                    <div className={styleSheet.detailContainer}>
                        Max. Showing Duration:
                        <div className={styleSheet.greyTag}>
                            {formatMinutes(listing.agentListing?.maxDuration)}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styleSheet.redTag}>
                    This Listing is Managed Outside of Showingly
                </div>
            )}
        </div>
    );

    const showingDetailsSectionMobile = (
        <div>
            {connected ? (
                <div className={styleSheet.table}>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Showing Type:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatShowingType(listing.agentListing?.type)}
                            </span>
                        </div>
                    </div>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Notice Required:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatMinutes(listing.agentListing?.noticeRequired)}
                            </span>
                        </div>
                    </div>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Max. Showing Duration:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatMinutes(listing.agentListing?.maxDuration)}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styleSheet.redTag}>
                    This Listing is Managed Outside of Showingly
                </div>
            )}
        </div>
    );

    const listingInfoSection = (
        <div className={styleSheet.listingInfoSectionContainer}>
            {listingBedBath}
            {showingDetailsSection}
        </div>
    );

    const listingInfoSectionMobile = (
        <div>
            <div className={styleSheet.listingInfoSectionContainer}>{listingBedBath}</div>
            <div className={styleSheet.listingInfoSectionContainer}>
                {showingDetailsSectionMobile}
            </div>
        </div>
    );

    const publicRemarksSection = (
        <div className={styleSheet.publicRemarksSectionContainer}>
            <div className={styleSheet.greyTitle}>Public Remarks</div>
            <div className={`${styleSheet.textStyle} ${styleSheet.textStyleJustify}`}>
                {listing.remarks}
            </div>
        </div>
    );

    const card = (metaDataArr: any[]) => (
        <div className={styleSheet.card}>
            {metaDataArr.map(({ key, value }) => {
                return (
                    <div className={styleSheet.dataRow}>
                        <span className={styleSheet.key}>{key}: </span>
                        <span className={styleSheet.value}> {value?.toString()}</span>
                    </div>
                );
            })}
        </div>
    );

    const card1 = [
        {
            key: 'Year Built',
            value: listing.property?.yearBuilt ?? 'N/A',
        },
        {
            key: 'Lot Area',
            value: listing.property?.acres + ' Acres' ?? 'N/A',
        },
        {
            key: 'Type',
            value: listing.property?.type ?? 'N/A',
        },
        {
            key: 'Status',
            value: listing.mlsList?.length ? listing.mlsList[0]?.standardStatus : 'N/A',
        },
    ];

    const card2 = [
        {
            key: 'Taxes',
            value: listing.tax?.taxAnnualAmount
                ? '$' + formatCurrency(listing.tax?.taxAnnualAmount) + '/yr'
                : 'N/A',
        },
        {
            key: 'Parking',
            value: listing.property?.parking?.spaces
                ? listing.property?.parking?.spaces + ' Spaces'
                : 'N/A',
        },
        {
            key: 'HOA',
            value: listing.association?.fee ? '$' + listing.association.fee : 'N/A',
        },
    ];
    const card3 = [
        {
            key: 'Elementary',
            value: listing.school?.elementarySchool ?? 'N/A',
        },
        {
            key: 'Middle',
            value: listing.school?.middleSchool ?? 'N/A',
        },
        {
            key: 'High',
            value: listing.school?.highSchool ?? 'N/A',
        },
        {
            key: 'District',
            value: listing.school?.district ?? 'N/A',
        },
    ];

    const RenderBigMarker = (lat: any, lng: any) => (
        <img style={{ marginLeft: -15, marginTop: -25 }} src={BigMapMarker} />
    );

    const mapClassName = isMobile() ? styleSheet.mapMobileContainer : styleSheet.mapContainer;

    const mapContainer = (
        <div className={mapClassName}>
            <GoogleMap
                // @ts-ignore
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
                    libraries: ['places'],
                }}
                zoom={11}
                center={{ lat: listing.geo?.lat, lng: listing.geo?.lng }}
            >
                <RenderBigMarker lat={listing.geo?.lat} lng={listing.geo?.lng} />
            </GoogleMap>
        </div>
    );

    const metaDataSection = (
        <div className={styleSheet.metaDataSectionContainer}>
            {mapContainer}
            {card(card1)}
            {card(card2)}
            {card(card3)}
        </div>
    );

    const listedBySection = (
        <div className={styleSheet.listedBySectionContainer}>
            Listed by{' '}
            <span className={styleSheet.listingAgent}>
                {' '}
                {listing.agent?.firstName} {listing.agent?.lastName}{' '}
            </span>
            {listing.agentListing?.agents[0].phoneNumber ? (
                <>
                    <div className={styleSheet.verticalSeparator} />{' '}
                    <span className={styleSheet.textStyle}>
                        +1{' '}
                        {formatPhoneNumberForDisplay(listing.agentListing?.agents[0].phoneNumber)}
                    </span>
                </>
            ) : null}
            {listing.name ? (
                <>
                    <div className={styleSheet.verticalSeparator} />
                    <span className={styleSheet.textStyle}>{listing.name}</span>
                </>
            ) : null}
        </div>
    );

    const mlsNumberSection = (
        <div>
            {listing.mlsList?.map((mls: any) => {
                return (
                    <>
                        <div className={styleSheet.mlsNumber}>MLS#: {mls?.listingId}</div>
                        {mls?.disclaimer ? (
                            <div className={styleSheet.disclaimer}>{mls?.disclaimer}</div>
                        ) : null}
                    </>
                );
            })}
        </div>
    );

    const newsSection = <ConnectedNewsSection />;

    return isMobile() ? (
        <div className={styleSheet.rootMobile}>
            {header}
            {imageSectionMobile}
            <div className={styleSheet.separator} />
            {listingInfoSectionMobile}
            <div className={styleSheet.separator} />
            {publicRemarksSection}
            <div className={styleSheet.separator} />
            {mapContainer}
            <div className={styleSheet.separator} />
            {listedBySection}
            {mlsNumberSection}
            {newsSection}
        </div>
    ) : (
        <div className={styleSheet.root}>
            {header}
            {imageSection}
            <div className={styleSheet.separator} />
            {listingInfoSection}
            <div className={styleSheet.separator} />
            {publicRemarksSection}
            {metaDataSection}
            {listedBySection}
            <div className={styleSheet.separator} />
            {mlsNumberSection}
            {newsSection}
        </div>
    );
};
export default ListingDetailsComponent;
