import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { ClickableWithFeedback, Typography, UpgradeSubscriptionModal } from '..';
import RightArrow from '../../images/rightArrowWhite.svg';
import { updateUpgradeModalVisible } from '../../domains/main/Profile/actions';
import { getSubscriptionPlan } from '../../domains/main/Profile/selectors';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import ButtonComponent from '../ButtonComponent';

interface Props {}

const UpgradeSnackBar = (props: Props) => {
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const dispatch = useDispatch();
    const subPlan = useSelector(getSubscriptionPlan);
    const subTier = subPlan?.tier || 0;
    const isSubscribedAlready = subTier > 0;

    return isSubscribedAlready || agentHasFreeTrial ? null : (
        <div className={styleSheet.root}>
            <Typography textStyle="h4" color={theme.palette.white}>
                Unlock Showingly+
            </Typography>
            {/* <Typography
                className={styleSheet.divider}
                textStyle="h4"
                color={theme.palette.white}
            ></Typography> */}
            <Typography textStyle="b3" color={theme.palette.white}>
                to batch schedule showings, connect with clients, and more!
            </Typography>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                <ButtonComponent
                    className={styleSheet.button}
                    onClick={() => {
                        dispatch(updateUpgradeModalVisible(true));
                    }}
                    fill
                >
                    Upgrade here
                </ButtonComponent>
            </Typography>
        </div>
    );
};
export default UpgradeSnackBar;
