import React, { useState } from 'react';

import classnames from 'classnames';
import defaultPhoto from '../../images/defaultUser.svg';
import styles from './index.module.css';

interface PhotoComponentProps {
    containerStyles: string;
    photoURI: string;
    onClickImage?: Function;
    onHover?: Function;
    onNotHover?: Function;
}

function Photo(props: PhotoComponentProps) {
    const { containerStyles, photoURI, onHover = () => {}, onNotHover = () => {} } = props;
    const root = classnames(styles.rootView, containerStyles);
    const [isBroken, setIsBroken] = useState<boolean>(false);
    return (
        <div className={root} onClick={() => props.onClickImage && props.onClickImage()}>
            <img
                id={'img'}
                src={isBroken ? defaultPhoto : photoURI}
                alt={''}
                className={styles.image}
                onError={() => setIsBroken(true)}
                onMouseOver={() => {
                    onHover();
                }}
                onMouseOut={() => {
                    onNotHover();
                }}
            />
        </div>
    );
}

Photo.defaultProps = {
    containerStyles: null,
    photoURI: null,
};

export default Photo;
