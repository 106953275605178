import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import {
    AnimatedDrawer,
    AnimatedDrawerHeader,
    ClickableWithFeedback,
    Loader,
    Typography,
} from '../../../../components';
import { useHistory } from 'react-router-dom';
import {
    fetchAllListingsForQueuedShowingsRequested,
    queueShowingInteractionRequested,
    saveShowingQueue,
} from '../actions';
import { getShowingQueue, getQueueLoading } from '../selectors';
import ButtonComponent from '../../../../components/ButtonComponent';
import { DateTime } from 'luxon';
import CheckBox from '../../../../images/queueCheckMark.svg';
import CheckBoxFilled from '../../../../images/queueCheckMarkFilled.svg';

interface Props {
    visible: boolean;
    onClose: Function;
    scheduleDrawerInteract: Function;
}

const QueueDrawer = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const queuedShowingsData = useSelector(getShowingQueue);
    const loading = useSelector(getQueueLoading);

    const dispatch = useDispatch();

    const closeDrawerRef = useRef<any>(null);

    const [shouldFetchListings, setShouldFetchListings] = useState<boolean>(true);

    const queueData: any = sessionStorage.getItem('queueData');
    const queueDataParsed: any = JSON.parse(queueData);
    const history = useHistory();

    const [queuedShowings, setQueuedShowings] = useState<any>([]);
    useEffect(() => {
        setQueuedShowings(queuedShowingsData);
    }, [queuedShowingsData]);

    useEffect(() => {
        if (queueDataParsed && shouldFetchListings) {
            sessionStorage.setItem('isOIDCRefresh', 'true');
            const queueDataListingId = queueDataParsed?.listingid;
            const queueDataMlsName = queueDataParsed?.mlsname;
            dispatch(saveShowingQueue([{ queueDataListingId, queueDataMlsName, ssoSet: true }]));
            setShouldFetchListings(false);
        }
        const isOIDCUser = sessionStorage.getItem('isComingViaOIDC');
        // if there is queueDataParsed, we need to save it before we can fetch anything
        if (isOIDCUser !== 'true' || !queueDataParsed) {
            dispatch(fetchAllListingsForQueuedShowingsRequested());
        }
    }, []);

    const [canResize, setCanResize] = useState(false);
    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);
    /**
     * Make sure that the schedule calendar resizes when the screen
     * is resized.
     */
    const [screenHeight, setScreenHeight] = useState<number>(0);
    useLayoutEffect(() => {
        const resize = () => {
            setTimeout(() => {
                setScreenHeight(window.innerHeight);
                setCanResize(false);
            }, 500);
        };
        window.addEventListener('resize', resize);
        setScreenHeight(window.innerHeight);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const [allSelected, setAllSelected] = useState<boolean>(false);
    const selectAllButton = (
        <div className={styleSheet.selectAllContainer}>
            <ClickableWithFeedback
                onClick={() => {
                    var q: any = [...queuedShowings];
                    q.map((e: any) => (e.selected = !allSelected));
                    setQueuedShowings(q);
                    setAllSelected(!allSelected);
                }}
            >
                <img
                    src={allSelected ? CheckBoxFilled : CheckBox}
                    className={styleSheet.allCheck}
                />
            </ClickableWithFeedback>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                Select All
            </Typography>
        </div>
    );

    const cardContent = (
        <div
            className={styleSheet.cardContainer}
            style={{
                height: screenHeight - 215,
            }}
        >
            {loading ? (
                <Loader size={50} />
            ) : queuedShowings?.length ? (
                queuedShowings.map((e: any, index: number) => {
                    const address = e?.listing?.address || {};
                    return (
                        <div className={styleSheet.queueCard}>
                            <ClickableWithFeedback
                                onClick={() => {
                                    var q: any = [...queuedShowings];
                                    q[index].selected = !q[index].selected;
                                    setQueuedShowings(q);
                                }}
                            >
                                <img
                                    src={queuedShowings[index].selected ? CheckBoxFilled : CheckBox}
                                    style={{ height: 34, width: 34 }}
                                />
                            </ClickableWithFeedback>
                            <div className={styleSheet.queueCardLeft}>
                                <Typography textStyle="p1" color={theme.palette.black}>
                                    {address.full}
                                </Typography>
                                <Typography textStyle="b1" color={theme.palette.black}>
                                    {' '}
                                    {`${address.city}, ${address.state} ${address.postalCode}`}
                                </Typography>
                                <ClickableWithFeedback
                                    onClick={() =>
                                        dispatch(
                                            queueShowingInteractionRequested(
                                                'remove',
                                                null,
                                                index,
                                                false,
                                            ),
                                        )
                                    }
                                >
                                    <Typography textStyle="p2" color={theme.palette.red}>
                                        Remove
                                    </Typography>
                                </ClickableWithFeedback>
                            </div>
                            <div className={styleSheet.queueCardRight}>
                                {e.startDate && e.endDate ? (
                                    <>
                                        <Typography textStyle="b2" color={theme.palette.mediumGrey}>
                                            {DateTime.fromJSDate(e.startDate).toFormat('DDD')}
                                        </Typography>
                                        <Typography textStyle="b2" color={theme.palette.mediumGrey}>
                                            {`${DateTime.fromJSDate(e.startDate).toFormat(
                                                't',
                                            )} - ${DateTime.fromJSDate(e.endDate).toFormat('t')}`}
                                        </Typography>
                                        <ClickableWithFeedback
                                            onClick={() =>
                                                props.scheduleDrawerInteract({
                                                    ...e.listing,
                                                    showingQueueIndex: index,
                                                    clientId: e.clientId,
                                                })
                                            }
                                        >
                                            <Typography textStyle="p2" color={theme.palette.blue}>
                                                Edit
                                            </Typography>
                                        </ClickableWithFeedback>
                                    </>
                                ) : (
                                    <ClickableWithFeedback
                                        onClick={() =>
                                            props.scheduleDrawerInteract({
                                                ...e.listing,
                                                showingQueueIndex: index,
                                                clientId: e.clientId,
                                            })
                                        }
                                    >
                                        <Typography textStyle="b1" color={theme.palette.blue}>
                                            Select a Time
                                        </Typography>
                                    </ClickableWithFeedback>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div>No queued showings</div>
            )}
        </div>
    );

    const checkScheduleDisabled = () => {
        if (queuedShowings?.length) {
            var atLeastOneSelected = false;
            for (const e of queuedShowings) {
                if (e.selected && !e.startDate) {
                    return true;
                } else if (e.selected) {
                    atLeastOneSelected = true;
                }
            }
            if (atLeastOneSelected) return false;
        }
        return true;
    };

    const scheduleButton = (
        <div className={styleSheet.scheduleButtonContainer}>
            <ButtonComponent
                width={257}
                onClick={() => {
                    dispatch(
                        queueShowingInteractionRequested('schedule', null, -1, false, [
                            ...queuedShowings.map((j: any) => {
                                if (j.selected) return j;
                            }),
                        ]),
                    );
                }}
                fill
                disabled={checkScheduleDisabled()}
            >
                Schedule Selected
            </ButtonComponent>
            <div style={{ width: 48 }} />
        </div>
    );

    if (!props?.visible) {
        return <></>;
    }

    return (
        <AnimatedDrawer onClose={props.onClose} ref={closeDrawerRef}>
            <AnimatedDrawerHeader
                title={'Queue'}
                onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
            />
            <div className={styleSheet.innerContentRoot}>
                {queuedShowings?.length ? selectAllButton : <div style={{ height: 80 }} />}
                {cardContent}
                {scheduleButton}
            </div>
        </AnimatedDrawer>
    );
};
export default QueueDrawer;
