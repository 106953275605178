import { BSON } from 'realm-web';

export const ONE_MILLION = 1000000;
export const ONE_HUNDRED_THOUSAND = 1000000;
export const ONE_THOUSAND = 1000;

/**
 * Format the bathrooms count for display.
 *
 * @param bathrooms
 * @param bathsHalf
 * @param bathsFull
 * @returns {string}
 */
export const formatBathrooms = (bathrooms: any, bathsHalf: any, bathsFull: any) => {
    if (bathrooms === null) {
        if (bathsHalf === null && bathsFull === null) {
            return null;
        }
        bathrooms = bathsHalf + bathsFull;
        const exactBaths = parseFloat(bathsFull + bathsHalf);
        return exactBaths.toFixed(parseFloat(bathrooms) === exactBaths ? 0 : 1);
    } else {
        const exactBaths = parseFloat(bathrooms);
        return exactBaths.toFixed(parseFloat(bathrooms) === exactBaths ? 0 : 1);
    }
};

/**
 * Formats a number with the input amount of decimal places, two by default.
 * @param amount
 * @param decimalCount The number of decimals to display
 * @returns {string}
 */
export const formatCurrency = (amount: any, decimalCount = 0) => {
    try {
        decimalCount = Math.abs(decimalCount);
        amount =
            isNaN(decimalCount) || decimalCount === 0
                ? amount
                : parseFloat(amount).toFixed(decimalCount);

        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch {
        return 0;
    }
};

export const formatShowingType = (showingType: string) => {
    switch (showingType) {
        case 'go_and_show':
            return 'Automatic Approval';
        case 'appt_required':
            return 'Appointment Required';
        case 'accompanied':
            return 'Accompanied';
        default:
            return showingType;
    }
};

export const formatMinutes = (minutes: any) => {
    if (minutes === 0) {
        return 'None';
    }
    if (minutes > 60) {
        let hours = minutes / 60;
        let splitHours = hours.toString().split('.');

        return `${splitHours[0]}h${splitHours[1] ? Number(`0.${splitHours[1]}`) * 60 + 'm' : ''}`;
    } else {
        return minutes + 'm';
    }
};
export const formatApprovalType = (approvalType: string, type: string) => {
    if (type === 'go_and_show') {
        return 'This listing has Automatic Approval configured.';
    }
    switch (approvalType) {
        case 'both':
            return 'Both the listing agent and seller must approve.';
        case 'agent':
            return 'Agent must approve.';
        case 'client':
            return 'Client must approve.';
        case 'either':
            return 'Either listing agent or seller can approve.';
        default:
            return approvalType;
    }
};

export const formatLockBoxType = (lockbox: string) => {
    switch (lockbox) {
        case 'Other':
            return 'Manual';

        default:
            return lockbox;
    }
};

/**
 * Format a given amount to be displayed under a marker on the MapView.
 * i.e. Shorten `1,100,000` to be `$1.1M` or 350,0000 to be `$350K`
 * @param {number} price
 * @returns {string|number}
 */
export const formatCurrencyForMap = (price: any) => {
    try {
        if (price >= ONE_MILLION) {
            return `$${(price / ONE_HUNDRED_THOUSAND).toFixed(1)}M`;
        } else if (`$${(price / ONE_THOUSAND).toFixed(0)}K` === '$1000K') {
            return `$1M`;
        } else {
            return `$${(price / ONE_THOUSAND).toFixed(0)}K`;
        }
    } catch {
        return 0;
    }
};

/**
 * Transforms a listing from the backend into
 * a format usable by the ListingCard component.
 *
 * @param listing
 * @returns {
 *             {
 *               id: *
 *               coordinate: {latitude: *, longitude: *}
 *               metas: {bedrooms: *, bathrooms: *}[],
 *               listingPrice: *,
 *               price: *,
 *               descriptions: {address2: string, address1: *}[],
 *               backgroundImageUrl: {uri: (*|string)}
 *            }
 *          }
 */
export const transformListingForListingCard = (listing: any) => {
    const findState = (marketName: any, state: any) => {
        if (state) {
            return state;
        } else if (marketName === 'Hawaii (HIS)') {
            return 'HI';
        }
        return '';
    };
    if (listing) {
        const {
            _id = '',
            property: {
                bedrooms: beds = '',
                bathrooms = '',
                bathsHalf = '',
                bathsFull = '',
                area = '',
            } = {},
            address: { full: address1 = '', city = '', state = '', postalCode = '' } = {},
            geo: { lat = '', lng = '' } = {},
            listPrice,
            photos,
            marketName,
        } = listing;
        return {
            id: new BSON.ObjectId(_id) || '',
            listingId: _id,
            coordinates: {
                latitude: lat,
                longitude: lng,
            },
            cardType: 'search',
            metas: [
                {
                    area: area || '--',
                    beds: beds || '--',
                    baths: formatBathrooms(bathrooms, bathsHalf, bathsFull) || '--',
                },
            ],
            price: `$${formatCurrency(listPrice)}`,
            listingPrice: formatCurrencyForMap(listPrice),
            descriptions: [
                {
                    address1,
                    address2: `${city}, ${findState(marketName, state)}, ${postalCode}`,
                },
            ],
            backgroundImageUrl: {
                uri: photos && photos.length > 0 ? photos[0] : '',
            },
        };
    }
    return {};
};
