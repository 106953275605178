import React, { useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { AnimatedModal, Loader, Typography } from '../../../../components';
import indexTheme from '../../../../theme';
import close from '../../../../images/close.svg';
import Tag from '../../../../components/Tag';
import GrayCal from '../../../../images/grayCal.svg';
import GrayClock from '../../../../images/grayClock.svg';
import { ShowingRequest } from '../constants';
import { DateTime } from 'luxon';
import ButtonComponent from '../../../../components/ButtonComponent';

interface Props {
    set: Function;
    modalVisible: boolean;
    ref: any;
    showing: ShowingRequest;
    closeModal: Function;
    onSend: Function;
    loading: boolean;
}
const DenyModal = (props: Props) => {
    const { showing } = props;
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const [denialReason, setDenialReason] = useState<string>('');

    return (
        <AnimatedModal style={{ top: null, margin: 'auto', width: 812 }} {...props}>
            <div className={styleSheet.header}>
                <Typography
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                    style={{ marginLeft: 15 }}
                >
                    Denial Reason
                </Typography>
                <img
                    className={styleSheet.closeButton}
                    src={close}
                    onClick={() => {
                        props.closeModal();
                        if (!showing.unverifiedUser) props.set(false);
                    }}
                />
            </div>
            <div
                style={{
                    marginTop: 15,
                    borderBottomStyle: 'solid',
                    borderBottomColor: indexTheme.palette.separatorGrey,
                    borderBottomWidth: 1,
                    width: '100%',
                    paddingBottom: 15,
                }}
            >
                <Typography
                    style={{ marginLeft: 15 }}
                    color={indexTheme.palette.mediumGrey}
                    textStyle={'p1'}
                >
                    Leave a reason for denying this showing for the buyer agent.
                </Typography>
            </div>
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <textarea
                    className={styleSheet.inputBox}
                    onChange={(e) => {
                        setDenialReason(e.target.value);
                    }}
                    // value={agentListing.lockboxLocation}
                    placeholder={'Describe where the showing agent can find the lockbox.'}
                    draggable={false}
                    maxLength={250}
                />
            </div>
            <div className={styleSheet.bottomButtonContainer}>
                {props.loading ? (
                    <Loader />
                ) : (
                    <ButtonComponent
                        disabled={!denialReason.length}
                        fill
                        width={200}
                        onClick={() => {
                            props.onSend(denialReason);
                            props.closeModal();
                            props.set(false);
                        }}
                    >
                        Send
                    </ButtonComponent>
                )}
            </div>
        </AnimatedModal>
    );
};
export default DenyModal;
