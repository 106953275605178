import { STATUS } from '../../../utils/constants';

import { NotificationAction, NOTIFICATION_ACTION } from './actions';

const initialState = {
    notifications: null,
    pendingShowings: null,
    loadingNotifications: false,
    loadingPendingShowings: false,
};

export type NotificationsState = typeof initialState;

export const notifications = (
    state: NotificationsState = initialState,
    action: NotificationAction,
) => {
    switch (action.type) {
        case NOTIFICATION_ACTION.FetchNotifications:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingNotifications: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        notifications: action.notifications,
                        loadingNotifications: false,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingNotifications: false,
                        error: action.error,
                    };
            }
        case NOTIFICATION_ACTION.FetchPendingShowings:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingPendingShowings: true,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        pendingShowings: action.pendingShowings,
                        loadingPendingShowings: false,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingPendingShowings: false,
                        error: action.error,
                    };
            }
        default:
            return state;
    }
};
