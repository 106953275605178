import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

interface Props {
    onClick?: Function;
    children?: any;
    className?: any;
    noop?: boolean;
    style?: any;
}

const ClickableWithFeedback = ({ onClick = () => {}, className, noop, children, style }: Props) => {
    const [clicked, set] = useState(false);
    const { scale } = useSpring({ scale: clicked ? 0.93 : 1 });
    return (
        <animated.div
            onMouseDown={() => set(true)}
            onMouseUp={() => set(false)}
            onMouseLeave={() => set(false)}
            style={{
                transform:scale.to((s) => `scale(${s})`),
                cursor: 'pointer',
                userSelect: 'none',
                ...style,
            }}
            onClick={() => (noop ? {} : onClick())}
            className={className}
        >
            {children}
        </animated.div>
    );
};
export default ClickableWithFeedback;
