import { Drawer, List, ListItem, ListItemIcon, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.css';
import ButtonComponent from '../ButtonComponent';
import { updateUpgradeModalVisible } from '../../domains/main/Profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionTier } from '../../domains/main/Profile/selectors';

const useStyles = makeStyles(() => ({
    drawerPaper: {
        width: 'inherit',
        'align-items': 'center',
        borderColor: '#BCBCBC',
        borderWidth: 1,
    },
}));

type ListItemProp = {
    link: string;
    icon: string;
    iconSelected: string;
    pageTitle?: string;
    beta?: boolean;
};

interface SideBarProps extends RouteComponentProps {
    ListItems: ListItemProp[];
    iconStyling?: string;
    userProfileLink?: string;
    loggedUser?: any;
    iconTextStyling?: string;

    userPhotoUri?: string;
    userPhotoContainerStyling?: string;
    userPhotoStyling?: string;
    userNameStyling?: string;
    defaultPhoto?: boolean;

    customWaterMark?: string;
    customWaterMarkStyling?: string;
}

const SideBar = (props: any) => {
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );
    const currentPlanTier = useSelector(getSubscriptionTier);
    const dispatch = useDispatch();
    const [path, setPath] = useState('');
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        setPath(window.location.pathname);
    }, [window.location.pathname]);

    // the component takes in an array of icons and relative links
    // this function converts that type into a usable side icon to click on
    const getListItems = () => {
        return props.ListItems.map((item: ListItemProp, index: number) => {
            return (
                //DO NOT DELETE THIS LINK CONTAINER! If this is deleted the Link component will overflow and make the sidebar horizontally scroll able
                <div className={styles.linkContainer} key={index}>
                    <Link
                        key={item.link}
                        to={item.link}
                        style={{ textDecoration: 'none', height: 40 }}
                        onClick={() => {
                            history.push(item.link);
                            setPath(item.link);
                        }}
                        className={styles.beanis}
                    >
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    height: 40,
                                    width: 3,
                                    backgroundColor:
                                        path === item.link || path.includes(item.link)
                                            ? '#48A4FF'
                                            : 'transparent',
                                }}
                            />
                            <ListItem style={{ height: 40 }} button={true}>
                                <div className={styles.iconAndName}>
                                    <ListItemIcon>
                                        <img
                                            src={
                                                path === item.link || path.includes(item.link)
                                                    ? item.iconSelected
                                                    : item.icon
                                            }
                                            alt={item.link + '_icon'}
                                            className={styles.icon}
                                        />
                                    </ListItemIcon>
                                    <p
                                        className={
                                            path === item.link || path.includes(item.link)
                                                ? styles.pageNameSelected
                                                : styles.pageName
                                        }
                                    >
                                        {item.pageTitle ? item.pageTitle : ''}
                                    </p>
                                </div>
                            </ListItem>
                        </div>
                    </Link>
                </div>
            );
        });
    };

    return (
        <div className={styles.routerMainDiv}>
            <Drawer
                className={styles.drawerMain}
                variant="persistent"
                anchor="left"
                open={true}
                classes={{ paper: classes.drawerPaper }}
            >
                <List className={styles.list}>
                    <div className={styles.navBarElements}>{getListItems()}</div>
                </List>
                {currentPlanTier < 1 && !agentHasFreeTrial && (
                    <ButtonComponent
                        className={styles.button}
                        onClick={() => {
                            dispatch(updateUpgradeModalVisible(true));
                        }}
                        fill
                    >
                        Upgrade Plan
                    </ButtonComponent>
                )}
            </Drawer>
        </div>
    );
};

export const ConnectedSideBar = withRouter(SideBar);
