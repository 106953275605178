import React, { ReactNode } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import pretypedTheme from '../../theme';
const indexTheme: any = pretypedTheme;

interface Props {
    text: string;
    color: 'green' | 'red' | 'blue' | 'mediumGrey' | 'purple' | string;
    customTextColor?: string;
    style?: any;
    Icon?: any;
}

const Tag = (props: Props) => {
    const { text, color = 'green', customTextColor, style, Icon } = props;
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const colorProps = {
        backgroundColor:
            color === 'mediumGrey'
                ? indexTheme.palette.background
                : indexTheme.palette['light' + `${color[0].toUpperCase() + color.slice(1)}`],
        color: customTextColor ?? indexTheme.palette[color],
    };
    return (
        <div style={{ ...colorProps, ...style }} className={styleSheet.tag}>
            {Icon ? <img src={Icon} style={{ marginRight: 6 }} /> : null}
            {text}
        </div>
    );
};
export default Tag;
