import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import {
    AnimatedModal,
    ClickableWithFeedback,
    ImageUploader,
    Loader,
    Typography,
} from '../../../../../components';
import CloseIcon from '../../../../../images/circleCloseBlack.svg';
import DefaultProfile from '../../../../../images/defaultUser.svg';
import ButtonComponent from '../../../../../components/ButtonComponent';
import { phoneDisplay, removeEmailAlias } from '../../utils';
import { updateProfileDataRequested, updateProfilePhotoRequested } from '../../actions';
import {
    getAboutUpdateLoading,
    getPaymentMethods,
    getUploadingPhoto,
    getUserProfileUpdateSuccessful,
} from '../../selectors';

interface Props {
    onClose: Function;
    visible: boolean;
    agent: any;
    agentProfilePhoto: any;
}

const EditProfileModal = (props: Props) => {
    const loading = useSelector(
        (state) => getAboutUpdateLoading(state) || getUploadingPhoto(state),
    );
    const updateSuccessful = useSelector(getUserProfileUpdateSuccessful);
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const dispatch = useDispatch();

    const animatedModalRef = useRef<any>(null);
    const aboutInputRef = useRef<any>(null);

    const { agent = {} } = props;

    const [aboutText, setAboutText] = useState<any>(agent.about || '');

    // profile photo upload
    const [showPhotoUpload, setShowPhotoUpload] = useState<boolean>(false);
    const [uploadPhoto, setUploadPhoto] = useState<any>({});

    // auto focus about section
    useEffect(() => {
        if (aboutInputRef?.current && props.visible) {
            aboutInputRef.current.focus();
        }
    }, [aboutInputRef, props.visible]);

    // if update is successful, close the modal
    useEffect(() => {
        if (!loading && updateSuccessful) {
            setUploadPhoto({});
            animatedModalRef.current.closeModal();
        }
    }, [updateSuccessful]);

    const headerBar = (
        <div className={styleSheet.headerBar}>
            <Typography textStyle="p1" color={theme.palette.mediumGrey}>
                Edit Profile
            </Typography>
            <ClickableWithFeedback onClick={() => animatedModalRef.current.closeModal()}>
                <img src={CloseIcon} className={styleSheet.closeIcon} />
            </ClickableWithFeedback>
        </div>
    );

    const photoUploadSection = (
        <input
            onChange={(e: any) => {
                setUploadPhoto({
                    text: e.target.files[0].name,
                    img: e.target.files[0],
                });
                setShowPhotoUpload(false);
            }}
            type="file"
            id="file-upload"
            accept="image/*"
        />
    );

    const topSection = (
        <div className={styleSheet.topSectionContainer}>
            <div className={styleSheet.topLeftContainer}>
                <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                    Profile Photo
                </Typography>
                <div className={styleSheet.profilePhotoIcon}>
                    <img
                        src={
                            uploadPhoto.img
                                ? URL.createObjectURL(uploadPhoto.img)
                                : props.agentProfilePhoto || DefaultProfile
                        }
                        className={styleSheet.profilePhotoIcon}
                        style={{ overflow: 'hidden' }}
                    />
                    {uploadPhoto.img ? (
                        <ClickableWithFeedback
                            className={styleSheet.deletePhotoButton}
                            onClick={() => setUploadPhoto({})}
                        >
                            <img src={CloseIcon} style={{ height: 19, width: 19 }} />
                        </ClickableWithFeedback>
                    ) : null}
                </div>
                <ClickableWithFeedback onClick={() => setShowPhotoUpload(true)}>
                    <label htmlFor="file-upload">
                        <Typography textStyle="b1" color={theme.palette.blue}>
                            Change
                        </Typography>
                    </label>
                </ClickableWithFeedback>
                {showPhotoUpload ? photoUploadSection : null}
            </div>
            <div className={styleSheet.topRightContainer}>
                <div className={styleSheet.nameRow}>
                    <div className={styleSheet.innerNameColumn}>
                        <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                            First Name
                        </Typography>
                        <input
                            onChange={(e: any) => {}}
                            className={styleSheet.inputContainer}
                            value={agent.firstName}
                            disabled
                        />
                    </div>
                    <div className={styleSheet.innerNameColumn}>
                        <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                            Last Name
                        </Typography>
                        <input
                            onChange={(e: any) => {}}
                            className={styleSheet.inputContainer}
                            value={agent.lastName}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                        Phone Number
                    </Typography>
                    <input
                        onChange={(e: any) => {}}
                        className={styleSheet.inputContainer}
                        style={{ width: 568 }}
                        disabled
                        value={phoneDisplay(agent.phoneNumber) || agent.phoneNumber}
                    />
                </div>
            </div>
        </div>
    );

    const bottomSection = (
        <div className={styleSheet.bottomSectionContainer}>
            <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                Email
            </Typography>
            <input
                onChange={(e: any) => {}}
                className={styleSheet.inputContainer}
                style={{ width: 716 }}
                disabled
                value={removeEmailAlias(agent.email)}
            />
            <Typography textStyle="b2" color={theme.palette.mediumGrey} style={{ marginTop: 10 }}>
                You cannot change your email at this time.
            </Typography>
            {/* ========== */}
            <Typography textStyle="b1" color={theme.palette.mediumGrey} style={{ marginTop: 26 }}>
                About
            </Typography>
            <textarea
                ref={aboutInputRef}
                onChange={(e: any) => setAboutText(e.target.value)}
                className={styleSheet.inputContainerBig}
                placeholder={
                    'Say something about yourself for other agents, your brokerage, and prospective clients to see when viewing your profile.'
                }
                style={{ resize: 'none' }}
                value={aboutText}
                // cause autofocus to be at end of input
                onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length,
                    )
                }
            />
            <Typography textStyle="b2" color={theme.palette.mediumGrey} style={{ marginTop: 10 }}>
                This is viewable by homebuyers and sellers.
            </Typography>
        </div>
    );

    const saveButton = (
        <div className={styleSheet.saveContainer}>
            {loading ? (
                <Loader size={40} />
            ) : (
                <ButtonComponent
                    fill
                    color={theme.palette.blue}
                    width={152}
                    onClick={() => {
                        var dispatcher = [];
                        if (aboutText?.length) {
                            dispatch(updateProfileDataRequested(aboutText));
                        }
                        if (uploadPhoto.img) {
                            dispatch(updateProfilePhotoRequested(uploadPhoto.img));
                        }
                    }}
                >
                    Save
                </ButtonComponent>
            )}
        </div>
    );

    return (
        <AnimatedModal
            set={props.onClose}
            modalVisible={props.visible}
            style={{ height: 698, width: 812, top: null }}
            ref={animatedModalRef}
        >
            {headerBar}
            {topSection}
            {bottomSection}
            {saveButton}
        </AnimatedModal>
    );
};
export default EditProfileModal;
