import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import Typography from '../../../components/Typography';
import DropdownIcon from '../../../images/dropDownIcon.svg';
import ButtonComponent from '../../../components/ButtonComponent';
import DropdownSelect from '../../../components/DropdownSelect';
import {
    getMarketRequested,
    registrationRequested,
    setAuthAgentInfo,
    setAuthPracticeInfo,
    textSearchAgentsRequested,
} from '../actions';
import { getAuthAgent, getAuthMarket, getEntryAgents, getMarkets } from '../selectors';
import { findIndex, propEq } from 'ramda';
import SearchDropdownSelect from '../../../components/SearchDropdownSelect';
import { useHistory } from 'react-router';
import CheckmarkComponent from '../../../components/CheckmarkComponent';
import { BSON } from 'realm-web';
import { TERMS_URL, PRIVACY_URL } from '../../../domains/auth/constants';

interface AgentSignupProps {}

const AgentSignup = (props: AgentSignupProps) => {
    const authAgent = useSelector(getAuthAgent);
    const dispatch = useDispatch();
    const requestSignUp = (userEmail: string, password: string, userObjectId: any) =>
        dispatch(registrationRequested(userEmail, password, userObjectId));

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();

    const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
    const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);

    const [signupClicked, setSignupClicked] = useState<boolean>(false);
    const [passText, setPassText] = useState<string>('');
    const [confirm, setConfirmText] = useState<string>('');

    // The password passes all of our imposed rules
    const passTextValid = passText?.length >= 6;

    const confirmed = passTextValid && passText === confirm;

    const allFieldsSatisfied = termsAgreed && policyAgreed && confirmed;

    const agentEmail = authAgent?.email;
    const password = passText;
    const agentObjectId = authAgent?._id ? new BSON.ObjectID(authAgent?._id) : null;

    const focusPass = () => {
        document.getElementById('pass')?.focus();
    };
    const focusConfirm = () => {
        document.getElementById('confirm')?.focus();
    };

    const onClickHyperLink = (type: string) => {
        if (type === 'terms') {
            window.open(TERMS_URL, '_blank');
        } else {
            window.open(PRIVACY_URL, '_blank');
        }
    };

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.contentBox}>
                <div className={styleSheet.topBox}>
                    <Typography textStyle="h2">Almost There.</Typography>
                </div>
                <div className={styleSheet.midBox}>
                    <div className={styleSheet.fieldsContainer}>
                        <div className={styleSheet.fieldContainer}>
                            <Typography
                                color={theme.palette.mediumGrey}
                                textStyle="b1"
                                style={{ fontSize: 18, paddingBottom: 20 }}
                            >
                                Password
                            </Typography>
                            <div
                                className={styleSheet.searchBar}
                                style={{ width: 240 }}
                                onClick={() => {
                                    focusPass();
                                }}
                            >
                                <input
                                    placeholder={'Create Password'}
                                    id="pass"
                                    name="pass"
                                    type="password"
                                    autoComplete={'new-password'}
                                    value={passText}
                                    onChange={(e: any) => {
                                        setPassText(e?.nativeEvent?.target?.value);
                                    }}
                                    onKeyUp={(e: any) => {
                                        var key = e.keyCode || e.charCode;
                                        if (key === 13) {
                                            if (passTextValid) {
                                                focusConfirm();
                                            }
                                        }
                                    }}
                                    className={styleSheet.inputText}
                                    style={{ width: 230 }}
                                />
                            </div>
                        </div>
                        <div className={styleSheet.fieldContainer}>
                            <Typography
                                color={theme.palette.mediumGrey}
                                textStyle="b1"
                                style={{ fontSize: 18, paddingBottom: 20 }}
                            >
                                Confirm
                            </Typography>
                            <div
                                className={styleSheet.searchBar}
                                style={{ width: 240 }}
                                onClick={() => {
                                    focusConfirm();
                                }}
                            >
                                <input
                                    placeholder={'Confirm Password'}
                                    id="confirm"
                                    type="password"
                                    name="confirm"
                                    autoComplete={'new-password'}
                                    value={confirm}
                                    onChange={(e: any) => {
                                        setConfirmText(e?.nativeEvent?.target?.value);
                                    }}
                                    className={styleSheet.inputText}
                                    style={{ width: 230 }}
                                />
                            </div>
                        </div>
                    </div>

                    {confirm != passText && (
                        <div className={styleSheet.fieldsContainer}>
                            <div className={styleSheet.fieldContainer}>
                                <Typography
                                    color={theme.palette.red}
                                    textStyle="b1"
                                    style={{ fontSize: 14, paddingTop: 20, width: 480 }}
                                >
                                    Please make sure your passwords match
                                </Typography>
                            </div>
                        </div>
                    )}

                    <div className={styleSheet.checkFieldsContainer}>
                        <div className={styleSheet.checkField}>
                            <CheckmarkComponent
                                disabled={false}
                                checked={termsAgreed}
                                setChecked={setTermsAgreed}
                            />
                            <div className={styleSheet.checkFieldTextContainer}>
                                <Typography
                                    color={theme.palette.black}
                                    textStyle="p1"
                                    style={{ paddingLeft: 6 }}
                                >
                                    I agree to the
                                    <Typography
                                        onClick={() => onClickHyperLink('terms')}
                                        color={theme.palette.blue}
                                        textStyle="p1"
                                        className={styleSheet.blueText}
                                    >
                                        Terms of Service
                                    </Typography>
                                </Typography>
                            </div>
                        </div>
                        <div className={styleSheet.checkField}>
                            <CheckmarkComponent
                                disabled={false}
                                checked={policyAgreed}
                                setChecked={setPolicyAgreed}
                            />
                            <div className={styleSheet.checkFieldTextContainer}>
                                <Typography
                                    color={theme.palette.black}
                                    textStyle="p1"
                                    style={{ paddingLeft: 6 }}
                                >
                                    I agree to the
                                    <Typography
                                        onClick={() => onClickHyperLink('privacy')}
                                        color={theme.palette.blue}
                                        textStyle="p1"
                                        className={styleSheet.blueText}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styleSheet.bottomBox}>
                    <ButtonComponent
                        onClick={() => {
                            setSignupClicked(true);
                            requestSignUp(agentEmail, password, agentObjectId);
                        }}
                        disabled={!allFieldsSatisfied || signupClicked}
                        fill
                        width={257}
                    >
                        {signupClicked ? 'Signing Up...' : 'Sign Up'}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
export default AgentSignup;
