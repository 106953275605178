import { Client, Showing, STATUS, ShowingType } from '../../../utils/constants';
import {
    CONNECT_LISTING_REQUESTED,
    CONNECT_LISTING_SUCCEEDED,
    CONNECT_LISTING_FAILED,
    CLAIM_LISTING_REQUESTED,
    CLAIM_LISTING_SUCCEEDED,
    CLAIM_LISTING_FAILED,
    SET_SELECTED_CLIENT,
    RESET_CONNECT_LISTINGS_SUCCESS_STATUS,
    FETCH_MY_LISTINGS_REQUESTED,
    FETCH_MY_LISTINGS_SUCCEEDED,
    FETCH_MY_LISTINGS_FAILED,
    FETCH_MY_LISTING_REQUESTED,
    FETCH_MY_LISTING_SUCCEEDED,
    FETCH_MY_LISTING_FAILED,
} from './constants';
import { BSON } from 'realm-web';

export enum SHOWING_ACTION {
    Fetch = '[Showing] Fetch',
    Create = '[Showing] Create',
    UpdateStatus = '[Showing] Update Status',
    UpdateClient = '[Showing] Update Client',
    Delegate = '[Showing] Delegate',
    AcceptPickUp = '[Showing] Accept Pickup',
    PurchaseLead = '[Showing] Purchase Lead',
    FetchPrices = '[Showing] Fetch D/L/P Prices',
    SetShowingClient = '[Showing] Set Current Client',
    QueueShowingInteraction = '[Showing] Queue Showing Interaction',
    FetchGroupDelegationPrice = '[Showing] Fetch Group Delegation Price',
    ShowingAvailability = '[Showing] Showing Availability',
    FetchSchedule = '[Showing] Fetch Schedule',
    AddNewShowing = '[Showing] Add New Showing',
    RemoveNewShowing = '[Showing] Remove New Showing',
}

export enum CLIENT_SEARCH_ACTION {
    Request = '[Client Search] Request',
    Succeeded = '[Client Search] Success',
}

export const ACTIVE_LISTING_ACTIONS_PREFIX = 'activeListings/';
const NOTIFICATION_ACTION_PREFIX = 'notification/';

export const FETCH_ACTIVE_LISTINGS_REQUESTED =
    ACTIVE_LISTING_ACTIONS_PREFIX + 'FETCH_ACTIVE_LISTINGS_REQUESTED';
export const FETCH_ACTIVE_LISTINGS_SUCCEEDED =
    ACTIVE_LISTING_ACTIONS_PREFIX + 'FETCH_ACTIVE_LISTINGS_SUCCEEDED';
export const FETCH_ACTIVE_LISTINGS_FAILED =
    ACTIVE_LISTING_ACTIONS_PREFIX + 'FETCH_ACTIVE_LISTINGS_FAILED';

export const LISTING_ACTIONS_PREFIX = 'listing/';
export const LOAD_SEARCH_HISTORY = 'LOAD_SEARCH_HISTORY';

export const FETCH_LISTING_REQUESTED = LISTING_ACTIONS_PREFIX + 'FETCH_LISTING_REQUESTED';
export const FETCH_LISTING_SUCCEEDED = LISTING_ACTIONS_PREFIX + 'FETCH_LISTING_SUCCEEDED';
export const FETCH_LISTING_FAILED = LISTING_ACTIONS_PREFIX + 'FETCH_LISTING_FAILED';

export const IS_LISTING_CONNECTED = LISTING_ACTIONS_PREFIX + 'IS_LISTING_CONNECTED';
export const STORE_RESERVED_SHOWINGS = LISTING_ACTIONS_PREFIX + 'STORE_RESERVED_SHOWINGS';
export const IS_LISTING_CONNECTED_SUCCEEDED =
    LISTING_ACTIONS_PREFIX + 'IS_LISTING_CONNECTED_SUCCEEDED';
export const IS_LISTING_CONNECTED_FAILED = LISTING_ACTIONS_PREFIX + 'IS_LISTING_CONNECTED_FAILED';

export const UPDATE_AGENT_NOTIFICATION_REQUESTED = `${NOTIFICATION_ACTION_PREFIX}UPDATE_AGENT_NOTIFICATION_REQUESTED`;
export const UPDATE_AGENT_NOTIFICATION_SUCCEEDED = `${NOTIFICATION_ACTION_PREFIX}UPDATE_AGENT_NOTIFICATION_SUCCEEDED`;
export const UPDATE_AGENT_NOTIFICATION_FAILED = `${NOTIFICATION_ACTION_PREFIX}UPDATE_AGENT_NOTIFICATION_FAILED`;

export const FETCH_SHOWINGS_FOR_LISTING_REQUESTED = `${NOTIFICATION_ACTION_PREFIX}FETCH_SHOWINGS_FOR_LISTING_REQUESTED`;
export const FETCH_SHOWINGS_FOR_LISTING_SUCCEEDED = `${NOTIFICATION_ACTION_PREFIX}FETCH_SHOWINGS_FOR_LISTING_SUCCEEDED`;
export const FETCH_SHOWINGS_FOR_LISTING_FAILED = `${NOTIFICATION_ACTION_PREFIX}FETCH_SHOWINGS_FOR_LISTING_FAILED`;

export const SHOWING_TYPE_APPOINTMENT = 'Showing Appointment';
export const REQUEST_SCHEDULE_SHOWING = 'REQUEST_SCHEDULE_SHOWING';
export const CLEAR_LISTING_ERRORS = 'CLEAR_LISTING_ERRORS';

export const SET_SELECTED_LISTING = 'SET_SELECTED_LISTING';
export const SET_SELECTED_SHOWING = 'SET_SELECTED_SHOWING';
export const SET_UPDATING_QUEUE = 'SET_UPDATING_QUEUE';

export const CLEAR_SELECTED_LISTING = 'CLEAR_SELECTED_LISTING';
export const CLEAR_SCHEDULE_SHOWING_ERROR = 'CLEAR_SCHEDULE_SHOWING_ERROR';

export const SQFT_PER_ACRE = 43560;

export const LISTING_SUBTYPES = [
    { value: 'Attached Single Family', label: 'Attached' },
    { value: 'Detached Single Family', label: 'Detached' },
    { value: 'Single Family Residence', label: 'Single Family' },
    { value: 'Farm and Ranch', label: 'Farm/Ranch' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Income', label: 'Income' },
    { value: 'Land', label: 'Land' },
    { value: 'Rental', label: 'Rental' },
];
export const loadSearchHistory = (activeListings: Array<any>) => {
    return { type: LOAD_SEARCH_HISTORY, activeListings };
};
export const fetchActiveListings = (
    searchedText: string | null = null,
    isMlsIdSearch?: boolean,
) => {
    return { type: FETCH_ACTIVE_LISTINGS_REQUESTED, searchedText, isMlsIdSearch };
};

export const fetchActiveListingsSucceeded = (activeListings = []) => {
    return { type: FETCH_ACTIVE_LISTINGS_SUCCEEDED, activeListings };
};

export const fetchActiveListingsFailed = (errors: string[] = []) => {
    return { type: FETCH_ACTIVE_LISTINGS_FAILED, errors };
};

export const fetchListingRequested = (
    listingId: string,
    listingDetails: boolean,
    mlsName: null | string,
) => {
    return { type: FETCH_LISTING_REQUESTED, listingId, listingDetails, mlsName };
};

export const fetchListingsSucceeded = (listing = {}, marketData = {}) => {
    return { type: FETCH_LISTING_SUCCEEDED, listing, marketData };
};

export const storeReservedShowings = (reservedShowings: Showing[]) => {
    return { type: STORE_RESERVED_SHOWINGS, reservedShowings };
};

export const fetchListingsFailed = (errors: string[] = []) => {
    return { type: FETCH_LISTING_FAILED, errors };
};

export const isListingConnected = (listingId: string) => {
    return { type: IS_LISTING_CONNECTED, listingId };
};

export const isListingConnectedSucceeded = (connectedListing: any) => {
    return { type: IS_LISTING_CONNECTED_SUCCEEDED, connectedListing };
};

export const isListingConnectedFailed = (errors: string[] = []) => {
    return { type: IS_LISTING_CONNECTED_FAILED, errors };
};

export const clearListingErrors = () => {
    return { type: CLEAR_LISTING_ERRORS };
};

export const requestShowingAction = () => {
    return { type: REQUEST_SCHEDULE_SHOWING };
};

export const textSearchClientRequested = (searchText: string = '') => {
    return { type: CLIENT_SEARCH_ACTION.Request, searchText };
};

export const textSearchClientRequestedSucceeded = (clients: Client[]) => {
    return { type: CLIENT_SEARCH_ACTION.Succeeded, clients };
};

export const fetchMyListings = (passedAgentId = null) => {
    return { type: FETCH_MY_LISTINGS_REQUESTED, passedAgentId };
};

export const fetchMyListingsSucceeded = (listings = []) => {
    return { type: FETCH_MY_LISTINGS_SUCCEEDED, listings };
};

export const fetchMyListingsFailed = (errors = []) => {
    return { type: FETCH_MY_LISTINGS_FAILED, errors };
};

export const fetchMyListing = (listingId: string | BSON.ObjectId, isEditMode: boolean) => {
    return { type: FETCH_MY_LISTING_REQUESTED, listingId, isEditMode };
};

export const fetchMyListingSucceeded = (listing: any = {}) => {
    return { type: FETCH_MY_LISTING_SUCCEEDED, listing };
};

export const fetchMyListingFailed = (errors: string[] = []) => {
    return { type: FETCH_MY_LISTING_FAILED, errors };
};
export const setSelectedListing = (selectedListing: any) => {
    return { type: SET_SELECTED_LISTING, selectedListing };
};

export const setUpdatingQueue = (status: boolean) => {
    return { type: SET_UPDATING_QUEUE, status };
};

export const setSelectedShowing = (selectedShowing: any) => {
    return { type: SET_SELECTED_SHOWING, selectedShowing };
};

// The effects of all showing management actions are handled the same way by the reducer, using the
// showingId to reference the showing that is being affected.
//
// When the creation of a new showing is requested, a showingId does not exist. This creates a
// problem for our reducer, which needs an Id to set the loading flag.  If the operation fails, it
// is also tricky to know which showing the operation failed for.
//
// We resolve this by assigning the showingId 0 to newly created showings.  This value will never be
// used by a legitimately created showing.

//--------------------------------------------------------------------------------------------
export const updateAgentNotificationRequested = (
    passedAgentIds: any = null,
    event = {},
    eventType = '',
) => {
    return {
        type: UPDATE_AGENT_NOTIFICATION_REQUESTED,
        passedAgentIds,
        event,
        eventType,
    };
};

export const updateAgentNotificationSucceeded = () => {
    return {
        type: UPDATE_AGENT_NOTIFICATION_SUCCEEDED,
    };
};

export const updateAgentNotificationFailed = (errors = []) => {
    return {
        type: UPDATE_AGENT_NOTIFICATION_FAILED,
        errors,
    };
};
//--------------------------------------------------------------------------------------------

export const createShowingRequested = (
    showingType: ShowingType,
    listingId: string,
    startTime: Date,
    endTime: Date,
    clientIds: BSON.ObjectId[],
    utcOffset: number,
) =>
    <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Requested,
        showingType,
        listingId,
        startTime,
        endTime,
        clientIds,
        utcOffset,
    };

export const createShowingSucceeded = (showing: Showing, listingId: string) =>
    <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Succeeded,
        listingId,
        showing,
    };

export const createShowingFailed = (listingId: string, error: string) =>
    <const>{
        type: SHOWING_ACTION.Create,
        status: STATUS.Failed,
        listingId,
        error,
    };

type CreateShowing =
    | ReturnType<typeof createShowingRequested>
    | ReturnType<typeof createShowingSucceeded>
    | ReturnType<typeof createShowingFailed>;

export const setSelectedClient = (client: any) =>
    <const>{
        type: SHOWING_ACTION.SetShowingClient,
        client,
    };

export type SetSelectedClient = ReturnType<typeof setSelectedClient>;

// ========================================================================

export const CONNECT_LISTING_ACTIONS_PREFIX = `connectListing/`;

export const connectListingRequested = (
    listingId: string | BSON.ObjectId,
    timeWindows: any | any[],
    connectionData: any,
) => {
    return {
        type: CONNECT_LISTING_REQUESTED,
        listingId,
        timeWindows,
        connectionData,
    };
};

export const connectListingSucceeded = () => {
    return { type: CONNECT_LISTING_SUCCEEDED };
};

export const connectListingFailed = (errors?: any) => {
    return { type: CONNECT_LISTING_FAILED, errors };
};
export const claimListingRequested = (
    listingId: string | BSON.ObjectId,
    agentId: string | BSON.ObjectId,
    claimType: any,
) => {
    return {
        type: CLAIM_LISTING_REQUESTED,
        listingId,
        agentId,
        claimType,
    };
};

export const claimListingSucceeded = () => {
    return { type: CLAIM_LISTING_SUCCEEDED };
};

export const claimListingFailed = (errors = []) => {
    return { type: CLAIM_LISTING_FAILED, errors };
};

export const setSelectedConnectListingClient = (selectedClient: any) => {
    return {
        type: SET_SELECTED_CLIENT,
        selectedClient,
    };
};

export const resetSuccessStatus = () => {
    return {
        type: RESET_CONNECT_LISTINGS_SUCCESS_STATUS,
    };
};

// --------------------------------------------------------------------------

export const fetchShowingAvailabilityRequested = (listingId: BSON.ObjectId) =>
    <const>{
        type: SHOWING_ACTION.ShowingAvailability,
        status: STATUS.Requested,
        listingId,
    };

export const fetchShowingAvailabilitySucceeded = (unavailableDates: any[] = []) =>
    <const>{
        type: SHOWING_ACTION.ShowingAvailability,
        status: STATUS.Succeeded,
        unavailableDates,
    };

export const fetchShowingAvailabilityFailed = (error: string) =>
    <const>{
        type: SHOWING_ACTION.ShowingAvailability,
        status: STATUS.Failed,
        error,
    };

export const CLEAR_SHOWING_AVAILABILITY = 'CLEAR_SHOWING_AVAILABILITY';
export const clearShowingAvailability = () =>
    <const>{
        type: CLEAR_SHOWING_AVAILABILITY,
    };

export const CLEAR_SHOWING_SUCCESS_STATUS = 'CLEAR_SHOWING_SUCCESS_STATUS';
export const clearShowingSuccessStatus = () =>
    <const>{
        type: CLEAR_SHOWING_SUCCESS_STATUS,
    };
export const fetchScheduleRequested = (startTime: any = null, endTime: any = null) => {
    return {
        type: SHOWING_ACTION.FetchSchedule,
        status: STATUS.Requested,
        startTime,
        endTime,
    };
};

export const fetchScheduleSucceeded = (showings = <any>[]) => {
    return {
        type: SHOWING_ACTION.FetchSchedule,
        status: STATUS.Succeeded,
        showings,
    };
};

export const fetchScheduleFailed = (errors = <any>[]) => {
    return { type: SHOWING_ACTION.FetchSchedule, status: STATUS.Failed, errors };
};

export const newlyCreatedShowingRequest = (showing: Showing) =>
    <const>{
        type: SHOWING_ACTION.AddNewShowing,
        showing,
    };

export const clearNewlyCreatedShowingRequest = () =>
    <const>{
        type: SHOWING_ACTION.RemoveNewShowing,
    };

export const clearSelectedListing = () =>
    <const>{
        type: CLEAR_SELECTED_LISTING,
    };

export const clearScheduleShowingError = () =>
    <const>{
        type: CLEAR_SCHEDULE_SHOWING_ERROR,
    };

//

export const fetchShowingsForListingRequested = (listingId: any) => {
    return {
        type: FETCH_SHOWINGS_FOR_LISTING_REQUESTED,
        listingId,
    } as const;
};
export const fetchShowingsForListingSucceeded = (showings: any) => {
    return {
        type: FETCH_SHOWINGS_FOR_LISTING_SUCCEEDED,
        showings,
    } as const;
};
export const fetchShowingsForListingFailed = (errors: any) => {
    return {
        type: FETCH_SHOWINGS_FOR_LISTING_FAILED,
        errors,
    } as const;
};

// ===================================================================================
// #region FETCH_SSO_LISTING
const PREFIX = 'ssoFetch/';

// connected listings
export const FETCH_SSO_LISTING_REQUESTED = PREFIX + 'FETCH_SSO_LISTING_REQUESTED';
export const FETCH_SSO_LISTING_SUCCEEDED = PREFIX + 'FETCH_SSO_LISTING_SUCCEEDED';
export const FETCH_SSO_LISTING_FAILED = PREFIX + 'FETCH_SSO_LISTING_FAILED';

// unconnected listings
export const FETCH_SSO_UNCONNECTED_LISTING_REQUESTED =
    PREFIX + 'FETCH_SSO_UNCONNECTED_LISTING_REQUESTED';
export const FETCH_SSO_UNCONNECTED_LISTING_SUCCEEDED =
    PREFIX + 'FETCH_SSO_UNCONNECTED_LISTING_SUCCEEDED';
export const FETCH_SSO_UNCONNECTED_LISTING_FAILED = PREFIX + 'FETCH_SSO_UNCONNECTED_LISTING_FAILED';

export const fetchSSOListingRequested = (listingId: any, mlsName: any) => {
    return {
        type: FETCH_SSO_LISTING_REQUESTED,
        listingId,
        mlsName,
    };
};

export const fetchSSOListingSucceeded = (listing: any) => {
    return {
        type: FETCH_SSO_LISTING_SUCCEEDED,
        listing,
    };
};

export const fetchSSOListingFailed = (errors: any) => {
    return {
        type: FETCH_SSO_LISTING_FAILED,
        errors,
    };
};

export const fetchSSOUnconnectedListingRequested = (mlsName: string, listingId: string) => {
    return {
        type: FETCH_SSO_UNCONNECTED_LISTING_REQUESTED,
        mlsName,
        listingId,
    };
};

export const fetchSSOUnconnectedListingSucceeded = (isListing: boolean, listingLink: string) => {
    return {
        type: FETCH_SSO_UNCONNECTED_LISTING_SUCCEEDED,
        isListing,
        listingLink,
    };
};

export const fetchSSOUnconnectedListingFailed = (errors: any) => {
    return {
        type: FETCH_SSO_UNCONNECTED_LISTING_FAILED,
        errors,
    };
};

// #endregion FETCH_SSO_LISTING
