import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import {
    duration,
    getAgentScheduleForSingularDate,
    getAgentQueuedShowingsForSingularDate,
    getBottomButtonText,
    getDefaultSelectedTime,
    getDuration,
    getDurationOptions,
    getLeftButtonOperation,
    getLeftOpCallback,
    getRightButtonOperation,
    getShowingButtonDisabled,
    getShowingStartPosition,
    getTimeRestrictionsForSingularDate,
    getTimeRestrictionStartPosition,
    getTimeWindows,
    groupTimeRestrictionsForCalendarDisplay,
    MINUTE_TO_PIXEL_RATIO,
    noticeTime,
    UNAVAILABLE_SLOT_BORDER_WIDTH,
    webToMobileTimeConverter,
} from '../utils';
import indexTheme from '../../../../theme';
import {
    operationType,
    rescheduleDetails,
    selectedDuration,
    selectedTime,
    selectedTimeForDisplay,
} from '../types';
import ClockIcon from '../../../../images/clockGrey2.svg';
import CloseIconLight from '../../../../images/closeIconLightGrey2.svg';
import {
    clearShowingIds,
    fetchShowingAvailabilityRequested,
    fetchAgentAvailabilityRequested,

} from '../actions';
import { newShowingRequested, updateShowingRequested } from '../../Showings/actions';
import { flatten } from 'ramda';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { useSpring, animated } from 'react-spring';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import TimeSelector from '../TimeSelector';
import { DatePicker } from '../../../../components/DatePicker';
import { DateTime } from 'luxon';
import { AnimatedDrawer, AnimatedDrawerHeader, Loader } from '../../../../components';
import { Listing } from '../../../../utils/constants';
import ButtonComponent from '../../../../components/ButtonComponent';
import { Element, animateScroll as scroll } from 'react-scroll';
import {
    getAgentSchedule,
    getCreatedShowingId,
    getRescheduledShowingId,
    getScheduleCalendarLoading,
    getShowingLoading,
    getUnavailableDates,
} from '../selectors';
import Lines from '../lines.png';
import { getSelectedClients } from '../../CRM/selectors';
import Typography from '../../../../components/Typography';
import { setSelectedClientsRequested } from '../../CRM/actions';
import { getQueueLoading } from '../../QueuedShowings/selectors';
import { useHistory } from 'react-router';
import { getUnverifiedShowingLoading } from '../../../unverifiedMain/selectors';
import { BSON } from 'realm-web';
import { getShowingQueue } from '../../QueuedShowings/selectors';
import { isMobile } from '../../../../utils/common';
import { findIndex } from 'lodash';

interface ScheduleScreenProps {
    listing: any;
    onClose: Function;
    opType: operationType;
    visible: boolean;
    clientDrawerInteract: Function;
    rescheduleDetails?: rescheduleDetails;
    unverifiedDetails?: any;
    backgroundOpacity?: number;
    defaultValue?: any;
}

// #endregion redux,props

const ScheduleScreen = (props: ScheduleScreenProps) => {
    const selectedClient = useSelector(getSelectedClients);
    const showingsOnThisListing = useSelector(getUnavailableDates);
    const createdShowingId = useSelector(getCreatedShowingId);
    const rescheduledShowingId = useSelector(getRescheduledShowingId);
    const agentSchedule: any = useSelector(getAgentSchedule);
    const queuedShowingsData = useSelector(getShowingQueue);
    const loading = useSelector(
        (state: any) =>
            getShowingLoading(state) || // includes creating showing
            getScheduleCalendarLoading(state) ||
            getQueueLoading(state) ||
            getUnverifiedShowingLoading(state),
    );
    const { opType, listing, clientDrawerInteract } = props;

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const history = useHistory();

    // #region hooks,refs

    const dispatch = useDispatch();

    // used to close the animated drawer
    const closeDrawerRef = useRef<any>();

    useEffect(() => {
        // fetch showing availability on the listing
        if (listing?._id) {
            dispatch(fetchShowingAvailabilityRequested(
                // If we get listing object as prop _id is string if not then _id is object (from fetching) and is not necesary to cast
                typeof listing._id === 'string' ? new BSON.ObjectId(listing._id) : listing._id
            ));
            dispatch(fetchAgentAvailabilityRequested());
        }
    }, [listing]);

    // every time the drawer is opened, attach the reschedule client (if exists)
    useEffect(() => {
        if (listing?._id && opType === 'Reschedule') {
            dispatch(setSelectedClientsRequested(props.rescheduleDetails?.passedClient));
        }
    }, [listing, props.visible]);

    // time selection
    const defaultSelectedTime = props.defaultValue ? props.defaultValue?.defaultTime : getDefaultSelectedTime();
    const [selectedTime, setSelectedTime] = useState<any>(defaultSelectedTime);
    // duration selection
    const defaultDuration = props.defaultValue ? props.defaultValue?.defaultDuration.selectedValue : getDefaultSelectedTime();
    const result = findIndex(duration, (dur: any) => dur.value === defaultDuration.value);
    const index = result > -1 ? result : 0;
    const [selectedDuration, setSelectedDuration] = useState<any>(duration[index]);
    // date selection
    const defaultSelectedDate = props.defaultValue ? props.defaultValue?.date : new Date();
    const [selectedDate, setSelectedDate] = useState<any>(defaultSelectedDate);

    // scroll to showing card on render and time/duration change
    useEffect(() => {
        var position = getShowingStartPosition(selectedTime) - 15;
        if (position < 0) {
            position = 0;
        }
        if (props.visible) {
            scroll.scrollTo(position, {
                duration: 800,
                delay: 100,
                smooth: true,
                containerId: 'calendarView', // container to scroll within
            });
        }
        return () => { };
        // listen for visible so this will run after all components render, not after mount
    }, [props?.visible, selectedTime, selectedDuration]);

    const timeUnavailable = getShowingButtonDisabled(
        selectedTime,
        selectedDuration,
        getTimeRestrictionsForSingularDate(
            selectedDate,
            listing?.agentListing || {},
            showingsOnThisListing || [],
            opType
        ),
    );

    /**
     * Get and dispatch action when pressing the bottom left/right button.
     * Changes based on @param {operationType}
     */
    const bottomButtonOperation = useCallback(
        (
            button: 'left' | 'right',
            type: operationType,
            lis: Listing,
            time: selectedTime,
            dur: selectedDuration,
            date: Date,
            cli: any,
            callback?: Function,
        ) => {
            const func = button === 'left' ? getLeftButtonOperation : getRightButtonOperation;
            const op = func(type, lis, time, dur, date, cli, props.rescheduleDetails, callback);
            if (op) {
                dispatch(op);
            }
        },
        [],
    );

    useEffect(() => {
        if (createdShowingId?.length && createdShowingId[1]) {
            getLeftOpCallback(opType, history, createdShowingId);
        } else if (
            rescheduledShowingId !== null &&
            rescheduledShowingId?.length &&
            rescheduledShowingId[1]
        ) {
            getLeftOpCallback(opType, history, null, closeDrawerRef?.current?.closeDrawer());
        }

        /**
         * on leaving the screen, clear the createdShowingId, rescheduledShowingId
         * and the selected client
         */
        return () => {
            dispatch(clearShowingIds());
            dispatch(setSelectedClientsRequested(null));
        };
    }, [createdShowingId, rescheduledShowingId]);

    const [canResize, setCanResize] = useState(false);
    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);
    /**
     * Make sure that the schedule calendar resizes when the screen
     * is resized.
     */
    const [screenHeight, setScreenHeight] = useState<number>(0);
    useLayoutEffect(() => {
        const resize = () => {
            setTimeout(() => {
                setScreenHeight(window.innerHeight);
                setCanResize(false);
            }, 500);
        };
        window.addEventListener('resize', resize);
        setScreenHeight(window.innerHeight);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    // #endregion hooks,refs

    // #region animations
    // used for all animated styles
    const animatedStyleSheet: any = {
        thisShowingAnimatedStyle: useSpring({
            top: 12 + getShowingStartPosition(selectedTime),
            height:
                (selectedDuration?.value >= 15 ? selectedDuration.value : 15) *
                MINUTE_TO_PIXEL_RATIO,
            borderColor: timeUnavailable ? theme.palette.red : theme.palette.green,
            zIndex: 9999,
        }),
        thisShowingAnimatedTextStyle: useSpring({
            color: timeUnavailable ? theme.palette.red : theme.palette.green,
        }),
    };

    // #endregion animations

    // #region Pat's code for calendar component
    const now = DateTime.fromJSDate(defaultSelectedDate);
    const [selectedMoment, setSelectedMoment] = useState<any>(now);
    type incDecOption = -1 | 1;
    // handle month is incremented or decremented via the DatePicker
    const changeMonth = (incDec: incDecOption) => {
        var newNow = selectedMoment.plus({ months: 1 });
        if (incDec === -1) {
            newNow = selectedMoment.minus({ months: 1 });
        }
        if (newNow.month === now.month && newNow.year === now.year) {
            newNow = newNow.set({ day: now.day });
        } else {
            newNow = newNow.set({ day: 1 });
        }
        setSelectedMoment(newNow);
    };
    // #endregion Pat's code for calendar component

    // #region date time duration

    // check if is Mobile
    const isMob = isMobile();
    // calendar date selection
    const calendarClass = isMob ? styleSheet.calendarContainerMobile : styleSheet.calendarContainer;
    const dateSelection = (
        <div className={calendarClass}>
            <div
                className={styleSheet.timeSelectionTitle}
                style={{
                    marginBottom: 5, // wireframe 20
                }}
            >
                Date
            </div>
            <DatePicker
                changeMonth={(incDec: incDecOption) => changeMonth(incDec)}
                selectedDay={selectedMoment}
                setSelectedDay={(sel: any) => {
                    setSelectedMoment(sel);

                    const e = sel.toJSDate();
                    setSelectedDate(e);
                }}
                removePadding={true}
                disablePast={true}
                {...(listing?.agentListing?.showingsStartDate
                    ? {
                        startDate: DateTime.fromISO(
                            listing?.agentListing?.showingsStartDate.toISOString(),
                        ),
                    }
                    : {})}
                blockedDates={listing?.agentListing?.blockedTimes
                    .map((d: any) => {
                        const start = DateTime.fromISO(d.startTime.toISOString());
                        const end = DateTime.fromISO(d.endTime.toISOString());
                        if (start.day === end.day && start.hour === 7 && end.hour === 21)
                            return start;
                    })
                    .filter((d: any) => d !== undefined)}
            />
        </div>
    );

    // time, duration, client
    const timeClientClass = isMob ? styleSheet.timeClientContainerMobile : styleSheet.timeClientContainer;
    const timeSelectorClass = isMob ? styleSheet.timeSelectorMobile : styleSheet.timeSelector;

    const timeAndClientSelection = (
        <div className={timeClientClass}>
            <div className={styleSheet.timeSelectionTitle}>Time</div>
            <div className={timeSelectorClass} style={{ marginBottom: 26 }}>
                <TimeSelector
                    options={noticeTime}
                    defaultValue={props.defaultValue?.defaultTime.selectedValue}
                    onSelect={(e: string) => {
                        const parsed: selectedTimeForDisplay = JSON.parse(e);
                        setSelectedTime(webToMobileTimeConverter(parsed.value));
                    }}
                />
            </div>
            <div className={styleSheet.timeSelectionTitle}>Duration</div>
            <div className={timeSelectorClass} style={{ marginBottom: 38 }}>
                <TimeSelector
                    options={getDurationOptions(listing)}
                    defaultValue={props.defaultValue?.defaultDuration.selectedValue}
                    onSelect={(e: string) => {
                        const parsed: selectedDuration = JSON.parse(e);
                        setSelectedDuration(parsed);
                    }}
                />
            </div>
            {opType !== 'UnverifiedSchedule' ? (
                <>
                    <div className={styleSheet.timeSelectionTitle} style={{ paddingTop: 0 }}>
                        Clients
                    </div>
                    {!selectedClient?.stitchUserId ? (
                        <ClickableWithFeedback
                            onClick={() => {
                                clientDrawerInteract(true);
                            }}
                        >
                            <div className={styleSheet.addClientsButton}>Add Client</div>
                        </ClickableWithFeedback>
                    ) : (
                        <div className={styleSheet.allClients}>
                            <div className={styleSheet.clientCard}>
                                <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                                    {`${selectedClient.firstName} ${selectedClient.lastName}`}{' '}
                                </Typography>
                                <ClickableWithFeedback
                                    onClick={() => dispatch(setSelectedClientsRequested(null))}
                                    className={styleSheet.lightCloseIcon}
                                >
                                    <img src={CloseIconLight} style={{ height: 18, width: 18 }} />
                                </ClickableWithFeedback>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );

    const agentBlockedTimeCard = (type: string, text: string, data: any) => {
        const duration = data.end.hours * 60 + data.end.minutes - (data.start.hours * 60 + data.start.minutes);
        return (
            <div
                className={styleSheet.thisShowingContainer}
                style={{
                    top: getTimeRestrictionStartPosition(data.start) + 14,
                    zIndex: 998,
                    height: (getDuration(data) || 15) * MINUTE_TO_PIXEL_RATIO,

                    borderColor: (type === 'scheduledShowing' ? theme.palette.mediumGrey : theme.palette.blue),
                }}
            >
                <div
                    className={styleSheet.thisShowingCardText}
                    style={{
                        color: (type === 'scheduledShowing' ? theme.palette.black : theme.palette.blue),
                    }}
                >
                    {text}
                </div>
                <div
                    className={styleSheet.agentScheduleTimeContainer}
                    style={duration < 30 ? { top: 0, right: 5 } : { top: 20, left: 5 }}
                >
                    <img src={ClockIcon} style={{ height: 12, width: 12 }} />
                    <div
                        className={styleSheet.thisShowingCardText}
                        style={{
                            color: theme.palette.lightGrey,
                            marginLeft: 4,
                        }}
                    >
                        {`${data.start.hours % 12 || 12}:${!data.start.minutes ? '00' : data.start.minutes
                            }${data.start.hours < 12 ? 'am' : 'pm'} - ${data.end.hours % 12 || 12
                            }:${!data.end.minutes ? '00' : data.end.minutes}${data.end.hours < 12 ? 'am' : 'pm'
                            }`}
                    </div>
                </div>
            </div>
        );
    }

    // #endregion date time duration

    // All pieces within the calendar view
    const calendarScroll = (
        <Element
            className={styleSheet.calendarScrollContainer}
            style={{
                // wireframe displacement 532
                height: screenHeight - 425,
            }}
            id="calendarView"
            name="calendarViewContainer"
        >
            <div>
                {getTimeWindows().map((time: any) => {
                    if (time.minutes === 0) {
                        return (
                            <div className={styleSheet.timeHeight}>
                                <div className={styleSheet.boldTime}>{time.title}</div>
                            </div>
                        );
                    }
                    return (
                        <div className={styleSheet.timeHeight}>
                            <div
                                className={
                                    time.minutes === 30 ? styleSheet.bigHash : styleSheet.smallHash
                                }
                            />
                        </div>
                    );
                })}
            </div>
            <div className={styleSheet.availabilitySlots}>
                <div className={styleSheet.hourBarContainer}>
                    {[...Array(16)].map((e, i) => (
                        <div className={styleSheet.hourBar} />
                    ))}
                </div>

                {/* Current Showing selected card */}
                <animated.div
                    className={styleSheet.thisShowingContainer}
                    style={animatedStyleSheet.thisShowingAnimatedStyle}
                >
                    <Element name="thisShowingScrollElement" id="thisShowingScrollContainerId">
                        <animated.div
                            className={styleSheet.thisShowingCardText}
                            style={animatedStyleSheet.thisShowingAnimatedTextStyle}
                        >
                            {timeUnavailable ? 'Time Unavailable.' : 'Time Available.'}
                        </animated.div>
                    </Element>
                </animated.div>

                {/* Blocked times cards */}
                {groupTimeRestrictionsForCalendarDisplay(
                    getTimeRestrictionsForSingularDate(
                        selectedDate,
                        listing?.agentListing || {},
                        showingsOnThisListing || [],
                        opType
                    ),
                ).map((restrictedTime: any) => {
                    return (
                        <div
                            className={styleSheet.unavailableSlot}
                            style={{
                                top:
                                    getTimeRestrictionStartPosition(restrictedTime.start) +
                                    15 -
                                    2 * UNAVAILABLE_SLOT_BORDER_WIDTH,
                                height: (getDuration(restrictedTime) || 15) * MINUTE_TO_PIXEL_RATIO,
                                backgroundImage: `url(${Lines})`,
                            }}
                        />
                    );
                })}

                {/* Agent Schedule time cards */}
                {getAgentScheduleForSingularDate(selectedDate, agentSchedule).map((e: any) => {
                    const multiShowing = e.showings?.length > 1;
                    var showingText = '';
                    if (multiShowing) {
                        showingText = `You have ${e.showings.length} showings at this time.`;
                    } else {
                        if (e.showings[0]?.consumer?.firstName) {
                            showingText = `Showing with ${e.showings[0]?.consumer?.firstName} ${e.showings[0]?.consumer?.lastName}`;
                        } else {
                            showingText = 'Showing';
                        }
                    }

                    return agentBlockedTimeCard('scheduledShowing', showingText, e);
                })}

                {/* Agent Queued showings cards */}
                {getAgentQueuedShowingsForSingularDate(selectedDate, queuedShowingsData).map((e: any) => {
                    let showingText = '';
                    if (e?.count > 1) {
                        showingText = `${e.count} queued showings.`;
                    } else {
                        showingText = 'Queued showing.';
                    }

                    return agentBlockedTimeCard('queuedShowing', showingText, e);
                })}
            </div>
        </Element>
    );

    const hideRightButton =
        opType === 'Schedule' ||
        opType === 'UnverifiedSchedule' ||
        (opType === 'Reschedule' && props.rescheduleDetails?.passedShowingStatus === 'cancelled');

    // schedule/log/update/cancel buttons at the bottom
    const buttonClassName = isMob ? styleSheet.scheduleButtonsContainerMobile : styleSheet.scheduleButtonsContainer;

    const schedularButtons = (
        <div className={buttonClassName}>
            {loading || !listing?._id ? (
                <Loader />
            ) : (
                <div
                    className={styleSheet.buttonsRow}
                    style={{ justifyContent: hideRightButton ? 'center' : 'space-between' }}
                >
                    <ButtonComponent
                        fill={true}
                        disabled={
                            timeUnavailable ||
                            loading ||
                            selectedTime?.default ||
                            selectedDuration?.default
                        }
                        onClick={() => {
                            bottomButtonOperation(
                                'left',
                                opType,
                                listing,
                                selectedTime,
                                selectedDuration,
                                selectedDate,
                                selectedClient,
                                () => closeDrawerRef?.current?.closeDrawer(),
                            );
                            switch (opType) {
                                case 'Schedule':
                                    dispatch(newShowingRequested());
                                    break;
                                case 'Reschedule':
                                    dispatch(updateShowingRequested());
                                    break;
                                case 'Queue':
                                    break;
                                default:
                                    dispatch(newShowingRequested());
                                    break;
                            }
                        }}
                    >
                        <div>
                            {
                                getBottomButtonText(opType, !!listing?.agentListing?.listingId).left.text
                            }
                        </div>
                    </ButtonComponent>
                    {hideRightButton ? null : (
                        <ButtonComponent
                            onClick={() => {
                                bottomButtonOperation(
                                    'right',
                                    opType,
                                    listing,
                                    selectedTime,
                                    selectedDuration,
                                    selectedDate,
                                    selectedClient,
                                    closeDrawerRef?.current?.closeDrawer,
                                );
                                //dispatch(newShowingRequested());
                            }
                            }
                        >
                            <div>
                                {
                                    getBottomButtonText(opType, !!listing?.agentListing?.listingId).right.text
                                }
                            </div>
                        </ButtonComponent>
                    )}
                </div>
            )}
        </div>
    );

    if (!props?.visible) {
        return <></>;
    }

    const dateTimeClass = isMob ? styleSheet.dateAndTimeMobile : styleSheet.dateAndTime;

    return (
        <AnimatedDrawer
            onClose={props.onClose}
            ref={closeDrawerRef}
            backgroundOpacity={
                typeof props.backgroundOpacity === 'number' ? props.backgroundOpacity : 1
            }
        >
            <AnimatedDrawerHeader
                title={`${!!listing?.agentListing?.listingId ? 'Schedule' : 'Log'} a Showing`}
                onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
            />
            <div className={dateTimeClass}>
                {dateSelection}
                {timeAndClientSelection}
            </div>
            {isMob ? <></> : calendarScroll}
            {schedularButtons}
        </AnimatedDrawer>
    );
};

export const ConnectedScheduleScreen = ScheduleScreen;
