import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    callStitchFunction,
    runQuery,
    findRecord,
    findRecords,
    updateShowingRequestUnverified,
    removeAgentListing,
    createCustomerPaymentMethod,
} from '../../../store/api/sagas';
import {
    fetchMyListingsFailed,
    fetchMyListingsRequested,
    fetchMyListingsSucceeded,
    fetchShowingHistoryFailed,
    fetchShowingHistorySucceeded,
    fetchShowingRequestsRequested,
    fetchShowingRequestsSucceeded,
    textSearchListingsFailed,
    textSearchListingsRequested,
    textSearchListingsSucceeded,
    fetchShowingReviewsSucceeded,
    fetchShowingReviewsFailed,
    confirmMyListingRequestSucceeded,
    confirmMyListingRequestFailed,
    CONFIRM_MY_LISTING_REQUEST_REQUESTED,
    fetchAllAgentsClientsFailed,
    fetchAllAgentsClientsSucceeded,
    addClientRequested,
    addClientSucceeded,
    addClientFailed,
    searchAgentRequested,
    searchAgentFailed,
    searchAgentSucceeded,
    connectListingRequested,
    connectListingSucceeded,
    connectListingFailed,
    fetchShowingRequestsFailed,
    FETCH_CONNECTION_PRICING,
    fetchConnectionPricingRequested,
    fetchConnectionPricingSucceeded,
    fetchConnectionPricingFailed,
} from './actions';
import {
    AGENT_TEXT_SEARCH_ACTION,
    CLIENT_ADD_ACTION,
    CLIENT_FETCH_ACTION,
    CONNECT_LISTING_ACTION,
    MESSAGE_BLAST_ACTION,
    MY_LISTINGS_ACTION,
    SHOWING_HISTORY_ACTION,
    SHOWING_REQUESTS_ACTION,
    SHOWING_REVIEW_ACTION,
    TEXT_SEARCH_LISTINGS_ACTION,
    LISTING_RECIPIENTS_ACTION,
    BLAST_MESSAGE_ACTION,
} from './types';
import { getShowingsGroupedByListing } from './selector';
import { STATUS } from '../../../utils/constants';
import { BSON } from 'realm-web';
import {
    CONSUMERS,
    SHOWING_REQUESTS,
    USER_TYPE_AGENT,
    LISTINGS_COLLECTION,
    MESSAGE_BLAST,
} from '../../../store/api/constants';
import { getProfileData } from '../Profile/selectors';
import { formatPhoneNumberToIntl, parseStitchServiceError } from '../../../utils/common';

/**
 * Get the connected listings for current user by querying
 * the listings collection and filtering by user's stitchUserId.
 * @param {string} agentStitchUserId
 */
export function* fetchMyListings({
    agentObjectId,
}: ReturnType<typeof fetchMyListingsRequested>): Generator<any, any, any> {
    try {
        const { _id, markets = [] } = yield select(getProfileData);

        var aID: any = agentObjectId;
        if (aID) {
            if (typeof aID === 'string') {
                aID = new BSON.ObjectId(agentObjectId);
            }
        } else {
            aID = _id;
        }

        const listings = yield call(callStitchFunction, 'getAgentsListings', aID);
        const sortedListings = listings.sort((a: any, b: any) =>
            !!a?.agentListing === !!b?.agentListing ? 0 : !!a.agentListing ? 1 : -1,
        );

        yield put(fetchMyListingsSucceeded(sortedListings));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(fetchMyListingsFailed(message));
    }
}

/**
 * Get the unconnected listings for current user by querying
 * the listings collection for absent agentListing objects.
 * @param {string} agentMlsId
 */
export function* fetchShowingRequests({
    agentUserId,
}: ReturnType<typeof fetchShowingRequestsRequested>): any {
    try {
        const showingRequests = yield call(callStitchFunction, 'getMyListingsRequests', true);

        yield put(fetchShowingRequestsSucceeded(showingRequests));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(fetchShowingRequestsFailed(message));
    }
}

/**
 * Perform a search on the listings collection using the given text input
 * @param {string} searchText
 * @returns {IterableIterator<object>}
 */
function* doTextSearch({
    searchText,
    markets,
}: ReturnType<typeof textSearchListingsRequested>): Generator<any, any, any> {
    try {
        if (searchText) {
            const searchResults = yield all(
                markets.map(async (market: any) => {
                    return await call(
                        callStitchFunction,
                        'textSearchConnectListing',
                        searchText,
                        // marketName,
                    );
                }),
            );
            const flatSearchResults = searchResults.flat();
            yield put(textSearchListingsSucceeded(flatSearchResults));
        } else {
            yield put(textSearchListingsSucceeded([]));
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(textSearchListingsFailed(errorMessage));
    }
}

/**
 * Get the unconnected listings for current user by querying
 * the listings collection for absent agentListing objects.
 * @param {string} agentMlsId
 */
export function* fetchShowingHistory({ listingId }: any): Generator<any, any, any> {
    if (!listingId) {
        return;
    }

    try {
        const history = yield call(
            callStitchFunction,
            'getMyShowingHistory',
            USER_TYPE_AGENT,
            {
                $match: {
                    listingId,
                },
            },
            50, // limit
        );

        if (history) {
            yield put(fetchShowingHistorySucceeded(history));
        } else {
            yield put(fetchShowingHistoryFailed(['Could not fetch history']));
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(fetchShowingHistoryFailed(message));
    }
}

export function* getListingReviews(listingIds: any): Generator<any, any, any> {
    const filteredIds = listingIds.listingIds;
    try {
        const allFetchedReviews = yield runQuery(SHOWING_REQUESTS, {
            $and: [
                {
                    listingId: {
                        $in: filteredIds,
                    },
                },
                {
                    feedback: {
                        $exists: true,
                    },
                },
            ],
        });
        if (allFetchedReviews.length !== 0) {
            yield put(fetchShowingReviewsSucceeded(allFetchedReviews));
        } else if (allFetchedReviews.length === 0) {
            yield put(
                fetchShowingReviewsFailed(['There are currently no reviews for this listing.']),
            );
        }
    } catch (err) {
        yield put(fetchShowingReviewsFailed(err));
    }
}

function* confirmMyListingRequest({
    newStatus,
    showingId,
    sendLockInfo,
    isUnverified,
}: any): Generator<any, any, any> {
    try {
        const currentPendingShowings = yield select(getShowingsGroupedByListing);
        const { _id: agentObjectId } = yield select(getProfileData);
        const showingRequestData = {
            id: showingId,
            status: newStatus,
        };
        const unverifiedData = {
            isUnverifiedApproval: isUnverified,
            sendLockInfo: sendLockInfo,
        };

        const { upsertedId, modifiedCount } = yield call(
            updateShowingRequestUnverified,
            showingRequestData,
            unverifiedData,
        );
        if (upsertedId || modifiedCount) {
            yield put(confirmMyListingRequestSucceeded());
            yield put(fetchShowingRequestsRequested(agentObjectId));
            // If the showing the user wanted to deny or confirm is successfully updated, then let's remove the pendingShowing from state
            const updatedPendingShowings = currentPendingShowings.map((listing: any) => {
                return {
                    showingRequests: listing.showingRequests.filter(
                        (showing: any) => showing._id.toString() !== showingId.toString(),
                    ),
                    _id: listing._id,
                };
            });
            yield put(fetchShowingRequestsSucceeded(updatedPendingShowings));
        } else {
            yield put(
                confirmMyListingRequestFailed([
                    'Could not confirm the showing request, please try again.',
                ]),
            );
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(confirmMyListingRequestFailed([message]));
    }
}

export function* fetchAgentsClients(): Generator<any, any, any> {
    const agent = yield select(getProfileData);

    try {
        const clients = yield findRecords(CONSUMERS, { agentId: agent._id });
        if (clients) {
            yield put(fetchAllAgentsClientsSucceeded(clients));
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(fetchAllAgentsClientsFailed([error]));
    }
}

export function* addClient({
    clientData,
}: ReturnType<typeof addClientRequested>): Generator<any, any, any> {
    const agentData = yield select(getProfileData);

    try {
        const response = yield call(
            callStitchFunction,
            'addConsumers',
            [
                {
                    ...clientData,
                    phoneNumber: formatPhoneNumberToIntl(clientData.phoneNumber),
                },
            ],
            agentData._id,
        );
        if (response) {
            // Re-fetching the client to grab the _id
            // TODO: Rather than re-fetch this agent, we need to get the _id from the 'addConsumers' collection
            const newClient = yield findRecord(CONSUMERS, {
                phoneNumber: formatPhoneNumberToIntl(clientData.phoneNumber),
            });
            if (newClient) {
                yield put(addClientSucceeded(newClient));
            }
        } else {
            yield put(addClientFailed(['There was an error adding this client.']));
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(addClientFailed([error]));
    }
}

export function* textSearchAgents({
    agentTextSearch,
}: ReturnType<typeof searchAgentRequested>): any {
    if (!agentTextSearch.length) return;

    try {
        const response = yield call(callStitchFunction, 'textSearchAgents', agentTextSearch);

        if (response) {
            yield put(searchAgentSucceeded(response));
        } else {
            yield put(searchAgentFailed(['There was an error searching for agents.']));
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(searchAgentFailed([error]));
    }
}

export function* connectThisListing({
    agentListing,
    connectionPricing,
    paymentMethod,
    isUpdating,
    mlsList,
    isNewCard = false,
}: ReturnType<typeof connectListingRequested>): any {
    try {
        const agentData = yield select(getProfileData);
        // Connect the listing before processing payment so if there are errors we don't have to charge and refund them.
        if (isNewCard) {
            yield call(createCustomerPaymentMethod, paymentMethod?.id);
        }

        const response = yield call(callStitchFunction, 'agentConnectListing', {
            listingId: agentListing.listingId,
            agentListing,
        });

        // Check if the agent is supposed to be paying
        // Check if the price they're paying is greater than 0
        // Check if the schedule they're paying on is perListing
        // Check if this is they're first time connecting the listing.
        if (
            connectionPricing?.payingEntity === 'agent' &&
            connectionPricing?.cost > 0 &&
            connectionPricing?.paymentSchedule === 'perListing' &&
            !isUpdating
        ) {
            const listingInfo: { mlsName: string; listingId: string }[] = [];
            mlsList?.forEach((item: { mlsName: string; listingId: string }) => {
                listingInfo.push({
                    mlsName: item?.mlsName,
                    listingId: item?.listingId,
                });
            });

            const processPayment = yield call(callStitchFunction, 'invoiceConnectListing', {
                paymentMethodId: paymentMethod?.id,
                price: connectionPricing?.cost,
                listingInfo,
            });
            if (processPayment?.status !== 'succeeded') {
                // Remove the agentListing
                yield call(removeAgentListing, agentListing?.listingId);
                // Put failed action
                yield put(
                    connectListingFailed([
                        processPayment?.errorMessage ??
                            'There was an error processing the payment for your listing.',
                    ]),
                );
            } else {
                yield put(connectListingSucceeded());
                yield put(fetchMyListingsRequested(agentData._id));
            }
        } else {
            // If the agent is not the one who should pay, then let them connect the listing.
            if (response) {
                yield put(connectListingSucceeded());

                // refetch listings so the new listing appears on /myListings when complete
                yield put(fetchMyListingsRequested(agentData._id));
            } else {
                yield put(connectListingFailed(['There was an error connecting this listing.']));
            }
        }
    } catch (err) {
        const error = parseStitchServiceError(err);

        yield put(connectListingFailed([error]));
        // If this is their first time connecting the listing, take it off just in case it was put there.
        if (!isUpdating) {
            yield call(removeAgentListing, agentListing?.listingId);
        }
    }
}

export function* fetchConnectionPricing({
    listingId, //ObjectId
}: ReturnType<typeof fetchConnectionPricingRequested>): Generator<any, any, any> {
    try {
        const agentData = yield select(getProfileData);
        const listingConnectionPrice = yield call(
            callStitchFunction,
            'getConnectListingCost',
            agentData?._id,
            new BSON.ObjectId(listingId),
        );
        if (!!listingConnectionPrice) {
            yield put(fetchConnectionPricingSucceeded(listingConnectionPrice));
        }
    } catch (errors) {
        yield put(fetchConnectionPricingFailed(errors));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === MY_LISTINGS_ACTION.Fetch && action.status === STATUS.Requested,
            fetchMyListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_REQUESTS_ACTION.Fetch && action.status === STATUS.Requested,
            fetchShowingRequests,
        ),
        takeLatest(
            (action: any) =>
                action.type === TEXT_SEARCH_LISTINGS_ACTION.Fetch &&
                action.status === STATUS.Requested,
            doTextSearch,
        ),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_HISTORY_ACTION.Fetch && action.status === STATUS.Requested,
            fetchShowingHistory,
        ),
        takeLatest(
            (action: any) =>
                action.type === SHOWING_REVIEW_ACTION.Fetch && action.status === STATUS.Requested,
            getListingReviews,
        ),
        takeLatest(CONFIRM_MY_LISTING_REQUEST_REQUESTED, confirmMyListingRequest),
        takeLatest(
            (action: any) =>
                action.type === CLIENT_FETCH_ACTION && action.status === STATUS.Requested,
            fetchAgentsClients,
        ),
        takeLatest(
            (action: any) =>
                action.type === CLIENT_ADD_ACTION && action.status === STATUS.Requested,
            addClient,
        ),
        takeLatest(
            (action: any) =>
                action.type === AGENT_TEXT_SEARCH_ACTION && action.status === STATUS.Requested,
            textSearchAgents,
        ),
        takeLatest(
            (action: any) =>
                action.type === CONNECT_LISTING_ACTION && action.status === STATUS.Requested,
            connectThisListing,
        ),
        takeLatest(
            (action: any) =>
                action.type === FETCH_CONNECTION_PRICING && action.status === STATUS.Requested,
            fetchConnectionPricing,
        ),
    ]);
}
