import React from 'react';
import classnames from 'classnames';
import styles from './index.module.css';

interface MarkerProps {
    lat?: number;
    lng?: number;
    style?: object;
    onPressMarker: Function;
    isSelected: boolean;
}
// DEVELOPERS NOTE
// We ts-ignore the below line because of the lat and lng props
// These props are passed to the MapView children and each children which has this prop
// will be considered a marker.
// We don't really use the lat and lng here for anything but we introduce them as props
// here so that we don't encounter any errors

// @ts-ignore
const Marker = ({ lat, lng, style, onPressMarker, isSelected }: MarkerProps) => {
    return (
        <div
            onClick={() => onPressMarker()}
            className={
                isSelected
                    ? classnames(styles.selectedMapMarker, style as Record<string, unknown>)
                    : classnames(styles.root, style as Record<string, unknown>)
            }
        />
    );
};

Marker.defaultProps = {
    onPressMarker: () => {},
};

export default Marker;
