import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { formatCurrency } from '../../utils/common/transforms';
import BedIcon from '../../images/bedIcon.svg';
import BathIcon from '../../images/bathIcon.svg';
import SQFTIcon from '../../images/sqftIcon.svg';
import ClickableWithFeedback from '../ClickableWithFeedback';

interface Props {
    listing: any;
}

const TallPropertyCard = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const { listing } = props;

    const IconAndText = (Icon: any, field: string, subText: string) => (
        <div className={styleSheet.iconContainer}>
            <img src={Icon} style={{ marginRight: 5 }} />
            {listing?.property[`${field}`] ?? '0'} {subText}
        </div>
    );

    return (
        <ClickableWithFeedback className={styleSheet.root}>
            <img
                src={listing.photos?.length ? listing.photos[0] : ''}
                className={styleSheet.listingImage}
                referrerPolicy={'no-referrer'}
            />
            <div className={styleSheet.addressSection}>
                {listing?.mlsList?.length && listing?.mlsList[0]?.standardStatus === 'Active' ? (
                    <div className={styleSheet.tag}>For Sale</div>
                ) : null}
                <span className={styleSheet.addressText}>
                    {'$'}
                    {formatCurrency(listing?.listPrice)}
                </span>
                <span style={{ fontSize: 14 }} className={styleSheet.addressText}>
                {
                    listing.address?.unit ? 
                    listing.address?.full.includes(listing.address?.unit) ? listing.address?.full : listing.address?.full + " " + listing.address?.unit :
                    listing.address?.full
                }
                </span>
                <span className={styleSheet.cityText}>
                    {listing.address.city}, {listing.address.state} {listing.address.postalCode}
                </span>
            </div>
            <div className={styleSheet.separatorLine} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
            >
                {IconAndText(BedIcon, 'bedrooms', 'bed')}
                {IconAndText(BathIcon, 'bathrooms', 'bath')}
                {IconAndText(SQFTIcon, 'area', 'sqft.')}
            </div>
        </ClickableWithFeedback>
    );
};
export default TallPropertyCard;
