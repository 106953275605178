export const bannersText = [
    {
        title: 'An extraordinary CRM.',
        description: [
            'Connect directly with clients through their own Showingly app.',
            'Upload clients from your contacts on mobile & a .csv file on web.',
            'Stay up to date on a client’s process by following their favorited listings.',
            'Log activity with your clients to stay organized.',
        ],
    },
    {
        title: 'Data for you.',
        description: [
            'Drive production through real time stats of your performance.',
            'Know the agents on the other side of a deal with social production stats.',
        ],
    },
    {
        title: 'Simple scheduling.',
        description: ['Schedule showings over the phone while on the go.'],
    },
];

export const noSubscriptions = [
    { included: true, text: 'Showing management.' },
    { included: true, text: 'Brokerage hub.' },
    { included: true, text: 'Syncs with client application.' },
    { included: true, text: 'Showingly social.' },
    { included: false, text: 'View clients' + "'" + ' favorites.' },
    { included: false, text: 'Full integrated CRM & log notes on client activity.' },
    { included: false, text: 'Bulk add clients to CRM.' },
    { included: false, text: 'Agent productions stats.*' },
    { included: false, text: 'Call to schedule.' },
    { included: false, text: 'Admin access.' },
];

export const subscriptions = [
    { included: true, text: 'Showing management.' },
    { included: true, text: 'Brokerage hub.' },
    { included: true, text: 'Syncs with client application.' },
    { included: true, text: 'Showingly social.' },
    { included: true, text: 'View clients' + "'" + ' favorites.' },
    { included: true, text: 'Full integrated CRM & log notes on client activity.' },
    { included: true, text: 'Bulk add clients to CRM.' },
    { included: true, text: 'Agent productions stats.*' },
    { included: true, text: 'Call to schedule.' },
    { included: true, text: 'Admin access.' },
];
