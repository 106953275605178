import { BSON } from 'realm-web';
import { unverifiedUser } from './types';

//
const NON_MLS_SHOWING_REQUESTS_PREFIX = 'nonMlsShowingRequests';

// ===================================================================================
// #region FETCH_LISTING_BY_KEY

export const FETCH_LISTING_BY_KEY_REQUESTED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_LISTING_BY_KEY_REQUESTED';
export const FETCH_LISTING_BY_KEY_SUCCEEDED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_LISTING_BY_KEY_SUCCEEDED';
export const FETCH_LISTING_BY_KEY_FAILED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_LISTING_BY_KEY_FAILED';

export const fetchListingByKeyRequested = (key: string) => {
    return {
        type: FETCH_LISTING_BY_KEY_REQUESTED,
        key,
    } as const;
};
export const fetchListingByKeySucceeded = (listing: any) => {
    return {
        type: FETCH_LISTING_BY_KEY_SUCCEEDED,
        listing,
    } as const;
};
export const fetchListingByKeyFailed = (errors: any) => {
    return {
        type: FETCH_LISTING_BY_KEY_FAILED,
        errors,
    } as const;
};

// #endregion FETCH_LISTING_BY_KEY

// ===================================================================================
// #region SCHEDULE_UNVERIFIED_SHOWING

export const SCHEDULE_UNVERIFIED_SHOWING_REQUESTED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'SCHEDULE_UNVERIFIED_SHOWING_REQUESTED';
export const SCHEDULE_UNVERIFIED_SHOWING_SUCCEEDED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'SCHEDULE_UNVERIFIED_SHOWING_SUCCEEDED';
export const SCHEDULE_UNVERIFIED_SHOWING_FAILED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'SCHEDULE_UNVERIFIED_SHOWING_FAILED';

export const scheduleUnverifiedShowingRequested = (
    listingId: BSON.ObjectId,
    startTime: Date,
    endTime: Date,
) => {
    return {
        type: SCHEDULE_UNVERIFIED_SHOWING_REQUESTED,
        listingId,
        startTime,
        endTime,
    } as const;
};

export const scheduleUnverifiedShowingSucceeded = (showing: any) => {
    return {
        type: SCHEDULE_UNVERIFIED_SHOWING_SUCCEEDED,
        showing,
    } as const;
};

export const scheduleUnverifiedShowingFailed = (errors: string[] = []) => {
    return {
        type: SCHEDULE_UNVERIFIED_SHOWING_FAILED,
        errors,
    } as const;
};

// #region SCHEDULE_UNVERIFIED_SHOWING

// ===================================================================================
// #region SET_UNVERIFIED_USER_DATA

export const SET_UNVERIFIED_USER_DATA_REQUESTED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'SET_UNVERIFIED_USER_DATA_REQUESTED';

export const setUnverifiedUserData = (data: unverifiedUser | null) => {
    return {
        type: SET_UNVERIFIED_USER_DATA_REQUESTED,
        data,
    } as const;
};

// #region SCHEDULE_UNVERIFIED_SHOWING

// ===================================================================================
// #region FETCH_UNVERIFIED_SHOWING

export const FETCH_UNVERIFIED_SHOWING_REQUESTED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_UNVERIFIED_SHOWING_REQUESTED';
export const FETCH_UNVERIFIED_SHOWING_SUCCEEDED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_UNVERIFIED_SHOWING_SUCCEEDED';
export const FETCH_UNVERIFIED_SHOWING_FAILED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'FETCH_UNVERIFIED_SHOWING_FAILED';

export const fetchUnverifiedShowingRequested = (key: string) => {
    return {
        type: FETCH_UNVERIFIED_SHOWING_REQUESTED,
        key,
    } as const;
};

export const fetchUnverifiedShowingSucceeded = (showing: any, listing: any) => {
    return {
        type: FETCH_UNVERIFIED_SHOWING_SUCCEEDED,
        showing,
        listing,
    } as const;
};

export const fetchUnverifiedShowingFailed = (errors: any) => {
    return {
        type: FETCH_UNVERIFIED_SHOWING_FAILED,
        errors,
    } as const;
};
// #endregion FETCH_UNVERIFIED_REGION

// ===================================================================================
// #region UPDATE_UNVERIFIED_SHOWING_STATUS

export const UPDATE_UNVERIFIED_SHOWING_STATUS_REQUESTED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'UPDATE_UNVERIFIED_SHOWING_STATUS_REQUESTED';
export const UPDATE_UNVERIFIED_SHOWING_STATUS_SUCCEEDED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'UPDATE_UNVERIFIED_SHOWING_STATUS_SUCCEEDED';
export const UPDATE_UNVERIFIED_SHOWING_STATUS_FAILED =
    NON_MLS_SHOWING_REQUESTS_PREFIX + 'UPDATE_UNVERIFIED_SHOWING_STATUS_FAILED';

export const updateUnverifiedShowingStatusRequested = (
    showingObjId: BSON.ObjectId,
    status: string,
) => {
    return {
        type: UPDATE_UNVERIFIED_SHOWING_STATUS_REQUESTED,
        showingObjId,
        status,
    };
};

export const updateUnverifiedShowingStatusSucceeded = () => {
    return {
        type: UPDATE_UNVERIFIED_SHOWING_STATUS_SUCCEEDED,
    };
};

export const updateUnverifiedShowingStatusFailed = (errors: any) => {
    return {
        type: UPDATE_UNVERIFIED_SHOWING_STATUS_FAILED,
        errors,
    };
};
