import { STATUS } from '../../../store/api/constants';
import { Listing } from '../../../utils/constants';
import { BSON } from 'realm-web';

export enum ListingDetailsActions {
    saveListing = '[Listings Details] Save Listing',
    clearListing = '[Listings Details] Clear Listing',
    clearShowingSuccess = '[Listings Details] Clear Showing Success',
    fetch = '[RecipientsList] Fetch',
    send = '[BlastMessage] Send',
    resetRecipientError = '[RecipientsList] Error',
    resetMessageError = '[BlastMessage] Error',
}

export const saveListingInRedux = (listing: Listing, listingType: string) => ({
    type: ListingDetailsActions.saveListing,
    listing,
    listingType,
});

export const removeListingInRedux = () => {
    return {
        type: ListingDetailsActions.clearListing,
    };
};

export const clearShowingSuccess = () => {
    return {
        type: ListingDetailsActions.clearShowingSuccess,
    };
};
export const fetchRecipientsRequested = (listingId: BSON.ObjectId) => {
    return {
        type: ListingDetailsActions.fetch,
        status: STATUS.Requested,
        listingId,
    };
};

export const fetchRecipientsSucceeded = (Recipients: any = []) => {
    return {
        type: ListingDetailsActions.fetch,
        status: STATUS.Succeeded,
        Recipients,
    };
};

export const fetchRecipientsFailed = (errors: string[] = []) => {
    return {
        type: ListingDetailsActions.fetch,
        status: STATUS.Failed,
        errors,
    };
};
export const sendBlastMessageRequested = (
    listingId: BSON.ObjectId,
    message: String,
    privateRecipients: any,
    notifications: any,
    when: any,
    callback: Function,
) => {
    return {
        type: ListingDetailsActions.send,
        status: STATUS.Requested,
        listingId,
        message,
        privateRecipients,
        notifications,
        when,
        callback,
    };
};

export const sendBlastMessageSucceeded = (
    listingId: BSON.ObjectId,
    message: String,
    privateRecipients: BSON.ObjectId[],
) => {
    return {
        type: ListingDetailsActions.send,
        status: STATUS.Succeeded,
        blast: { listingObjectId: listingId, message, privateRecipients },
    };
};

export const sendBlastMessageFailed = (errors: string[] = []) => {
    return {
        type: ListingDetailsActions.send,
        status: STATUS.Failed,
        errors,
    };
};
export const resetRecipientError = () => {
    return {
        type: ListingDetailsActions.resetRecipientError,
    };
};
export const resetMessageError = () => {
    return {
        type: ListingDetailsActions.resetMessageError,
    };
};
