import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import ClickableWithFeedback from '../ClickableWithFeedback';
import { getPaymentMethods } from '../../domains/main/Profile/selectors';
import { connect, ConnectedProps } from 'react-redux';
import { AnimatedModal, Typography } from '..';
import DownArrow from '../../images/greyDownArrow.svg';
import ButtonComponent from '../ButtonComponent';
import { capitalizeFirstLetter } from '../../utils/common';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js/';
import { loadStripe } from '@stripe/stripe-js';

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props {
    paymentMethods?: any;
    selectedPaymentMethod?: any;
    setSelectedPaymentMethod?: any;
    setNewPaymentMethodInfo: Function;
    enterNewSelected?: boolean;
}

type CardInfo = {
    fullName: string;
    cardNumber: string;
    monthDate: string;
    cvCode: string;
    zipCode: string;
};

type CardFormValidation = {
    isNameValid: boolean | null;
    isCardValid: boolean | null;
    isExpiryValid: boolean | null;
    isCvcValid: boolean | null;
    isZipValid: boolean | null;
};

const PaymentDropDownSelect = ({
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setNewPaymentMethodInfo,
    enterNewSelected = false,
}: Props) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const [expanded, setExpanded] = useState<boolean>(false);

    const paymentOptionList: any = [
        {
            label: 'Add New Credit/Debit Card',
            value: 'add',
        },
    ];

    function PaymentForm({ setInvalid }: any) {
        const stripe = useStripe();
        const elements = useElements();
        const [cardInfo, setCardInfo] = useState<CardInfo>({
            fullName: '',
            cardNumber: '',
            monthDate: '',
            cvCode: '',
            zipCode: '',
        });
        const [cardValuesValid, setCardValuesValid] = useState<CardFormValidation>({
            isNameValid: null,
            isCardValid: null,
            isExpiryValid: null,
            isCvcValid: null,
            isZipValid: null,
        });

        useEffect(() => {
            const {
                isNameValid: nv,
                isCardValid: cv,
                isExpiryValid: ev,
                isCvcValid: cvcv,
                isZipValid: zv,
            } = cardValuesValid;
            if (nv && cv && ev && cvcv && zv) update();
            else setNewPaymentMethodInfo(null);
        }, [cardValuesValid]);

        const [cardNumberPlaceHolder, setCardNumberPlaceholder] = useState<string>('Card Number');
        const [cardExpiryPlaceHolder, setCardExpiryPlaceholder] = useState<string>('MM / YY');
        const [cvcPlaceHolder, setCvcPlaceholder] = useState<string>('CVC');

        const [namePlaceholder, setNamePlaceholder] = useState<string>('Full Name');
        const [zipPlaceholder, setZipPlaceholder] = useState<string>('Zip Code');

        const update = async () => {
            const card = elements?.getElement(CardNumberElement);
            if (stripe && card) {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card,
                    billing_details: {
                        name: cardInfo?.fullName,
                        address: {
                            postal_code: cardInfo?.zipCode,
                        },
                    },
                });
                if (!error) {
                    setNewPaymentMethodInfo({
                        pmId: paymentMethod?.id,
                        fullName: cardInfo.fullName,
                        zipCode: cardInfo.zipCode,
                    });
                } else {
                }
            }
        };

        const handleCardNameChange = () => {
            if (cardInfo.fullName.length >= 2) {
                setCardValuesValid({ ...cardValuesValid, isNameValid: true });
            } else if (cardInfo.fullName.length < 2) {
                setCardValuesValid({ ...cardValuesValid, isNameValid: false });
            }
        };

        const handleZipChange = () => {
            if (cardInfo.zipCode.length == 5) {
                setCardValuesValid({ ...cardValuesValid, isZipValid: true });
            } else if (cardInfo.zipCode.length < 5) {
                setCardValuesValid({ ...cardValuesValid, isZipValid: false });
            }
        };

        const handleCardNumberChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: true });
            }
        };

        const handleExpiryChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: true });
            }
        };

        const handleCvcChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: true });
            }
        };

        useEffect(() => {
            handleZipChange();
        }, [cardInfo.zipCode]);

        useEffect(() => {
            handleCardNameChange();
        }, [cardInfo.fullName]);

        return (
            <>
                <div style={{ width: 384 }}>
                    <div className={styleSheet.inputBox} style={{ width: 368 }}>
                        <input
                            placeholder={namePlaceholder}
                            onBlur={() => setNamePlaceholder('Full Name')}
                            onFocus={() => setNamePlaceholder('')}
                            value={cardInfo.fullName}
                            onChange={(val: any) => {
                                setCardInfo({ ...cardInfo, fullName: val.target.value });
                            }}
                            className={styleSheet.inputText}
                            style={{
                                textAlign: 'center',
                                fontFamily: 'Poppins-Semibold',
                            }}
                        />
                    </div>
                    <div className={styleSheet.cardNumberContainer}>
                        <CardNumberElement
                            onFocus={() => setCardNumberPlaceholder('')}
                            onBlur={() => setCardNumberPlaceholder('Card Number')}
                            options={{
                                placeholder: '',
                                iconStyle: 'solid',
                                style: {
                                    base: {
                                        iconColor: 'rgb(20, 20, 20)',
                                        color: 'black',
                                        fontSize: '18px',
                                        fontFamily: 'Poppins',
                                        fontSmoothing: 'antialiased',
                                        '::placeholder': {
                                            color: '#d3d3d3',
                                            textAlign: 'center',
                                        },
                                        textAlign: 'center',
                                    },
                                    invalid: {
                                        color: '#ff0000',
                                        ':focus': {
                                            color: '#ff0000',
                                        },
                                    },
                                },
                            }}
                            onChange={(e: any) => handleCardNumberChange(e)}
                        />
                        {cardValuesValid.isCardValid === null && (
                            <div className={styleSheet.cardNumberPlaceholder}>
                                {cardNumberPlaceHolder}
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: 20,
                        }}
                    >
                        <div style={{ flex: 1 }} onClick={() => setExpanded(false)}>
                            <div className={styleSheet.cardExpiryContainer}>
                                <CardExpiryElement
                                    onFocus={() => setCardExpiryPlaceholder('')}
                                    onBlur={() => setCardExpiryPlaceholder('MM / YY')}
                                    options={{
                                        placeholder: '',
                                        style: {
                                            base: {
                                                iconColor: 'rgb(20, 20, 20)',
                                                color: 'black',
                                                fontSize: '18px',
                                                fontFamily: 'Poppins',
                                                fontWeight: '600',
                                                fontSmoothing: 'antialiased',
                                                '::placeholder': {
                                                    color: '#d3d3d3',
                                                    textAlign: 'center',
                                                },
                                                textAlign: 'center',
                                            },
                                            invalid: {
                                                color: '#ff0000',
                                                ':focus': {
                                                    color: '#ff0000',
                                                },
                                            },
                                        },
                                    }}
                                    onChange={(e: any) => handleExpiryChange(e)}
                                />
                                {cardValuesValid.isExpiryValid === null && (
                                    <div className={styleSheet.cardExpiryPlaceholder}>
                                        {cardExpiryPlaceHolder}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={styleSheet.cardCVCContainer}>
                                <CardCvcElement
                                    onFocus={() => setCvcPlaceholder('')}
                                    onBlur={() => setCvcPlaceholder('CVC')}
                                    options={{
                                        placeholder: '',
                                        style: {
                                            base: {
                                                iconColor: 'rgb(20, 20, 20)',
                                                color: 'black',
                                                fontSize: '18px',
                                                fontFamily: 'Poppins',
                                                fontWeight: '600',
                                                fontSmoothing: 'antialiased',
                                                '::placeholder': {
                                                    color: '#d3d3d3',
                                                    textAlign: 'center',
                                                },
                                                textAlign: 'center',
                                            },
                                            invalid: {
                                                color: '#ff0000',
                                                ':focus': {
                                                    color: '#ff0000',
                                                },
                                            },
                                        },
                                    }}
                                    onChange={(e: any) => {
                                        handleCvcChange(e);
                                    }}
                                />
                                {cardValuesValid.isCvcValid === null && (
                                    <div className={styleSheet.cardCVCPlaceholder}>
                                        {cvcPlaceHolder}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: 188,
                            display: 'flex',
                            marginTop: 20,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            value={cardInfo.zipCode}
                            onChange={(val: any) => {
                                if (isNaN(Number(val.target.value))) return;
                                else {
                                    setCardInfo({ ...cardInfo, zipCode: val.target.value });
                                }
                            }}
                            onBlur={() => setZipPlaceholder('Zip Code')}
                            onFocus={() => setZipPlaceholder('')}
                            type="text"
                            className={styleSheet.zipInput}
                            placeholder={zipPlaceholder}
                            maxLength={5}
                        />
                    </div>
                </div>
            </>
        );
    }

    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
    const stripePromise = loadStripe(stripeKey);

    const AddCardForm = () => {
        const options = {
            fonts: [
                {
                    cssSrc:
                        'https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap',
                },
            ],
        };

        return (
            <>
                <div>
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentForm />
                    </Elements>
                </div>
            </>
        );
    };

    if (paymentMethods?.data?.length)
        paymentMethods.data.map((pm: any) => {
            const {
                id,
                card: { brand, last4, exp_month, exp_year },
            } = pm;

            const expireMonth = `${exp_month}/${exp_year - 2000}`;

            paymentOptionList.unshift({
                label: `${capitalizeFirstLetter(brand)} ${last4}`,
                value: id,
                expireMonth,
            });
        });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            {expanded ? (
                <div
                    className={styleSheet.expandedBox}
                    style={{ height: ((paymentMethods?.data?.length || 0) + 2) * 36 - 6 }}
                >
                    <div className={styleSheet.topSection} onClick={() => setExpanded(false)}>
                        <Typography
                            textStyle="b1"
                            color={theme.palette.mediumGrey}
                            style={{
                                paddingLeft: 12,
                                height: 33,
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            Please Select a Payment Method.
                        </Typography>
                        <img src={DownArrow} className={styleSheet.arrow} />
                    </div>
                    {paymentOptionList.map((option: any) => {
                        return (
                            <>
                                <div className={styleSheet.line} />
                                <div
                                    className={styleSheet.option}
                                    onClick={() => {
                                        setSelectedPaymentMethod(option);
                                        setExpanded(false);
                                    }}
                                >
                                    <Typography textStyle="b1" color={theme.palette.black}>
                                        {option.label}
                                    </Typography>
                                </div>
                            </>
                        );
                    })}
                </div>
            ) : (
                <div className={styleSheet.box} onClick={() => setExpanded(true)}>
                    <Typography
                        textStyle="b1"
                        color={
                            selectedPaymentMethod || enterNewSelected
                                ? theme.palette.black
                                : theme.palette.mediumGrey
                        }
                        style={{ paddingLeft: 12 }}
                    >
                        {selectedPaymentMethod?.label
                            ? selectedPaymentMethod.label
                            : enterNewSelected
                            ? 'Add New Credit/Debit Card'
                            : 'Please Select a Payment Method.'}
                    </Typography>
                    <img src={DownArrow} className={styleSheet.arrow} />
                </div>
            )}
            {selectedPaymentMethod?.value &&
                selectedPaymentMethod?.value !== 'add' &&
                !enterNewSelected &&
                !expanded && (
                    <div className={styleSheet.cardInfoContainer}>
                        <Typography textStyle="h4" color={theme.palette.lightGrey}>
                            Name
                        </Typography>
                        <Typography
                            textStyle="h3"
                            style={{ fontSize: 24, paddingBottom: 30, paddingTop: 10 }}
                            color={theme.palette.black}
                        >
                            {selectedPaymentMethod?.label}
                        </Typography>
                        {selectedPaymentMethod?.expireMonth && (
                            <>
                                <Typography textStyle="h4" color={theme.palette.lightGrey}>
                                    Expires
                                </Typography>
                                <Typography
                                    textStyle="h3"
                                    style={{ fontSize: 24, paddingBottom: 30, paddingTop: 10 }}
                                    color={theme.palette.black}
                                >
                                    {selectedPaymentMethod?.expireMonth}
                                </Typography>
                            </>
                        )}
                    </div>
                )}
            {enterNewSelected && (
                <div
                    style={{
                        position: 'absolute',
                        top: 125,
                        zIndex: 9999,
                        left: expanded ? -194 : 0,
                    }}
                >
                    {AddCardForm()}
                </div>
            )}
        </div>
    );
};

// Must leave it exported with connector, otherwise the Forms are not stable and clear on state change
export default connector(PaymentDropDownSelect);
