import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { getNews } from './selectors';
import { fetchNewsRequested } from './actions';
import { SlideOutDrawer } from 'web-lib';
import ClickableWithFeedback from '../ClickableWithFeedback';

const NewsSection = (props: {}) => {
    const news = useSelector(getNews);
    const dispatch = useDispatch();

    // get the news
    useEffect(() => {
        if (!news.length) {
            dispatch(fetchNewsRequested());
        }
    }, []);
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const [selectedArticle, setSelectedArticle] = useState<any>({});

    return (
        <>
            <div className={styleSheet.root}>
                {news?.map((article: any) => {
                    return (
                        <ClickableWithFeedback
                            onClick={() => setSelectedArticle(article)}
                            className={styleSheet.card}
                        >
                            <img src={article.cardImage} className={styleSheet.articleImage} />
                            <div className={styleSheet.tag}>{article.type}</div>
                            <h1>{article.title}</h1>
                        </ClickableWithFeedback>
                    );
                })}
            </div>
            {selectedArticle?._id ? (
                <SlideOutDrawer
                    width={'25vw'}
                    children={
                        <div className={styleSheet.blog}>
                            <img
                                src={selectedArticle.cardImage}
                                className={styleSheet.articleImage}
                                style={{
                                    width: '100%',
                                    marginTop: 16,
                                    marginBottom: 16,
                                    borderRadius: 5,
                                }}
                            />
                            <h1>{selectedArticle.title}</h1>
                            {selectedArticle.content?.map((content: any) => {
                                if (content?.style?.toLowerCase() === 'header') {
                                    return <h2>{content?.content}</h2>;
                                } else if (content?.style?.toLowerCase() === 'paragraph') {
                                    return <span>{content?.content}</span>;
                                } else return null;
                            })}
                        </div>
                    }
                    onCloseModal={() => setSelectedArticle({})}
                />
            ) : null}
        </>
    );
};
export const ConnectedNewsSection = NewsSection;
