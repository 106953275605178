import {
    MY_SHOWINGS_FILTER,
    STATUS,
    MY_SHOWINGS_ACTION,
    FIND_ALL_SHOWING_DATA_ACTION,
} from './types';
import { Showing, ShowingStatus, ShowingType, SHOWING_ACTION } from '../../../utils/constants';
import { clearCurrentListingAndShowing } from '../../../store/api/actions';
import { BSON } from 'realm-web';

export const ADD_NEW_SHOWING = 'ADD_NEW_SHOWING';

export const addNewShowingToReduxAction = (showing: Showing) =>
    <const>{
        type: MY_SHOWINGS_ACTION.AddNew,
        showing,
    };

//#region Fetch My Showings

export const findAllShowingData = (showingData: Showing) =>
    <const>{
        type: FIND_ALL_SHOWING_DATA_ACTION.GetAll,
        status: STATUS.Requested,
        showingId: showingData._id,
    };

export const findAllShowingDataSuccess = (allShowingData: Showing) =>
    <const>{
        type: FIND_ALL_SHOWING_DATA_ACTION.Success,
        status: STATUS.Requested,
        allShowingData,
    };

export const findAllShowingDataFailure = (error: string) =>
    <const>{
        type: FIND_ALL_SHOWING_DATA_ACTION.Failure,
        status: STATUS.Requested,
        error,
    };

export const fetchMyShowingsRequested = (filter: MY_SHOWINGS_FILTER, forceRefresh: boolean) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Fetch,
        status: STATUS.Requested,
        filter,
        forceRefresh,
    };

export const fetchMyShowingsSucceeded = (myShowings = [], filter: MY_SHOWINGS_FILTER) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Fetch,
        status: STATUS.Succeeded,
        filter,
        myShowings,
    };

export const fetchMyShowingsFailed = (error: string, filter: MY_SHOWINGS_FILTER) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Fetch,
        status: STATUS.Failed,
        filter,
        error,
    };

export const submitFeedbackRequested = (
    priceAccuracyRating: number,
    locationRating: number,
    interestLevel: number,
    reviewText: string,
    showingId: any,
) =>
    <const>{
        type: MY_SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Requested,
        priceAccuracyRating,
        locationRating,
        interestLevel,
        reviewText,
        showingId,
    };

export const submitFeedbackSucceeded = () =>
    <const>{
        type: MY_SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Succeeded,
    };

export const submitFeedbackFailed = (error: string) =>
    <const>{
        type: MY_SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Failed,
        error,
    };

export const getFeedbackRequested = (showingId: any) =>
    <const>{
        type: MY_SHOWINGS_ACTION.GetFeedback,
        status: STATUS.Requested,
        showingId,
    };

export const getFeedbackSucceeded = () =>
    <const>{
        type: MY_SHOWINGS_ACTION.GetFeedback,
        status: STATUS.Succeeded,
    };

export const getFeedbackFailed = (errors = []) =>
    <const>{
        type: MY_SHOWINGS_ACTION.GetFeedback,
        status: STATUS.Failed,
        errors,
    };

type FetchMyShowingsAction =
    | ReturnType<typeof clearCurrentListingAndShowing>
    | ReturnType<typeof fetchMyShowingsRequested>
    | ReturnType<typeof fetchMyShowingsSucceeded>
    | ReturnType<typeof fetchMyShowingsFailed>
    | ReturnType<typeof findAllShowingData>
    | ReturnType<typeof findAllShowingDataFailure>
    | ReturnType<typeof findAllShowingDataSuccess>;

//#endregion
//#region Search My Showings

export const searchMyShowingsRequested = (searchText: string) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Search,
        status: STATUS.Requested,
        searchText,
    };

export const searchMyShowingsSucceeded = (myShowings: object[]) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Search,
        status: STATUS.Succeeded,
        myShowings,
    };

export const searchMyShowingsFailed = (errors: string[]) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Search,
        status: STATUS.Failed,
        errors,
    };

type SearchMyShowingsAction =
    | ReturnType<typeof searchMyShowingsRequested>
    | ReturnType<typeof searchMyShowingsSucceeded>
    | ReturnType<typeof searchMyShowingsFailed>;

//#endregion

export const setMyShowingsFilter = (filter: MY_SHOWINGS_FILTER) =>
    <const>{
        type: MY_SHOWINGS_ACTION.SetFilter,
        filter,
    };

export const setLoadingMyShowings = (isLoading: boolean) =>
    <const>{
        type: MY_SHOWINGS_ACTION.SetLoading,
        loading: isLoading,
    };
export const setFeedbackDisabled = () =>
    <const>{
        type: MY_SHOWINGS_ACTION.SetFeedbackDisabled,
    };

export type MyShowingsAction =
    | FetchMyShowingsAction
    | SearchMyShowingsAction
    | ReturnType<typeof addNewShowingToReduxAction>
    | ReturnType<typeof setMyShowingsFilter>
    | ReturnType<typeof setLoadingMyShowings>
    | ReturnType<typeof submitFeedbackRequested>
    | ReturnType<typeof submitFeedbackSucceeded>
    | ReturnType<typeof submitFeedbackFailed>
    | ReturnType<typeof getFeedbackRequested>
    | ReturnType<typeof getFeedbackSucceeded>
    | ReturnType<typeof getFeedbackFailed>
    | ReturnType<typeof setFeedbackDisabled>;

// ----------------------------------------------------------------------------

export const updateShowingStatusRequested = (
    showingId: any,
    newShowingStatus: ShowingStatus,
    stitchUserId: string,
    afterUpdate?: Function,
    calendarUpdate?: boolean,
) => {
    return {
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Requested,
        showingId,
        newShowingStatus,
        stitchUserId,
        afterUpdate,
        calendarUpdate,
    };
};

export const updateShowingStatusSucceeded = (showing: string) =>
    <const>{
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Succeeded,
        showing,
    };

export const updateShowingStatusFailed = (showingId: string, error: string) =>
    <const>{
        type: SHOWING_ACTION.UpdateStatus,
        status: STATUS.Failed,
        showingId,
        error,
    };

export type UpdateShowingStatus =
    | ReturnType<typeof updateShowingStatusRequested>
    | ReturnType<typeof updateShowingStatusSucceeded>
    | ReturnType<typeof updateShowingStatusFailed>;

// ----------------------------------------------------------------------------

export const rescheduleShowingRequested = (
    showingType: ShowingType,
    showingId: BSON.ObjectId,
    startTime: Date,
    endTime: Date,
    stitchUserId: BSON.ObjectId | string | null,
    utcOffset: number,
    calendarUpdate?: boolean,
) => {
    return {
        type: MY_SHOWINGS_ACTION.RescheduleShowing,
        status: STATUS.Requested,
        showingType,
        showingId,
        startTime,
        endTime,
        stitchUserId,
        utcOffset,
        calendarUpdate,
    };
};

export const rescheduleShowingSucceeded = (showing: string) =>
    <const>{
        type: MY_SHOWINGS_ACTION.RescheduleShowing,
        status: STATUS.Succeeded,
        showing,
    };

export const rescheduleShowingFailed = (showingId: string, error: string) =>
    <const>{
        type: MY_SHOWINGS_ACTION.RescheduleShowing,
        status: STATUS.Failed,
        showingId,
        error,
    };

export const CLEAR_SHOWING_RESCHEDULE_STATUS = 'CLEAR_SHOWING_RESCHEDULE_STATUS';
export const clearShowingRescheduleStatus = () =>
    <const>{
        type: CLEAR_SHOWING_RESCHEDULE_STATUS,
    };

export const CLEAR_FETCH_SHOWINGS_STATUS = 'CLEAR_FETCH_SHOWINGS_STATUS';
export const clearFetchShowingsStatus = () =>
    <const>{
        type: CLEAR_FETCH_SHOWINGS_STATUS,
    };

export const updatingShowingRequested = (showingId: BSON.ObjectId) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Updating,
        status: STATUS.Requested,
        showingId,
    };

export const updatingShowingSucceeded = (showingId: BSON.ObjectId) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Updating,
        status: STATUS.Succeeded,
        showingId,
    };

export const updatingShowingFailed = (errors: string[] = []) =>
    <const>{
        type: MY_SHOWINGS_ACTION.Updating,
        status: STATUS.Failed,
        errors,
    };

export const clearShowingErrors = () =>
    <const>{
        type: MY_SHOWINGS_ACTION.ClearErrors,
    };
