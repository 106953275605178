export default (theme: any) => {
    return {
        headerBar: {
            height: 62,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: { left: 15, right: 17.91 },
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
            marginBottom: -3,
        },

        topSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: 184,
            padding: { left: 48, right: 48 },
            marginTop: 26,
        },
        topLeftContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 100,
            marginRight: 48,
            '& *': {
                marginBottom: 10,
            },
        },
        topRightContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
        },
        nameRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
        },
        innerNameColumn: {
            display: 'flex',
            flexDirection: 'column',
            width: 260,
        },
        inputContainer: {
            height: 40,
            borderRadius: 5,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            ...theme.typography.semiBold,
            color: theme.palette.grey,
            marginTop: 10,
            padding: { left: 10, right: 10 },
            marginRight: -20, // because padding want to resize the width...
            width: 260,
        },
        inputContainerBig: {
            borderRadius: 5,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            ...theme.typography.semiBold,
            marginTop: 10,
            padding: { left: 10, right: 10, top: 10 },
            width: 716,
            height: 102,
            color: theme.palette.darkGrey,
        },

        bottomSectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: 332,
            padding: { left: 48, right: 48 },
            marginTop: 26,
            ...theme.commonBottomBorder,
        },

        saveContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 11,
        },

        profilePhotoIcon: {
            width: 100,
            height: 100,
            borderRadius: 50,
            position: 'relative',
        },
        deletePhotoButton: {
            position: 'absolute',
            top: -6,
            right: -6,
        },
    };
};
