import React, { useEffect, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { ClickableWithFeedback, Loader, Typography, UpgradeSnackBar } from '../../../../components';
import { useHistory } from 'react-router-dom';
import ButtonComponent from '../../../../components/ButtonComponent';
import RightArrow from '../../../../images/rightArrowBlue.svg';
import DefaultProfile from '../../../../images/defaultUser.svg';
import {
    fetchBankAccountsRequested,
    fetchMyBrokeragesRequested,
    getProfileDataRequested,
    removePaymentMethodsRequested,
    requestStripeLink,
    setPaymentModalType,
    updateUpgradeModalVisible,
} from '../actions';
import {
    getBrokerageData,
    getPaymentMethods,
    getProfileData,
    getProfilePhoto,
    getSubscriptionEndDate,
    getSubscriptionPlan,
    removingPayment,
} from '../selectors';
import { phoneDisplay, removeEmailAlias } from '../utils';
import EditProfileModal from './EditProfileModal';
import AccountSettings from '../AccountSettings';
import { capitalizeFirstLetter } from '../../../../utils/common';
import RemoveIcon from '../../../../images/removeIcon.svg';
import Tag from '../../../../components/Tag';

interface Props {}

const ProfileScreenNew = (props: Props) => {
    const brokerages = useSelector(getBrokerageData);
    const profileData = useSelector(getProfileData) || {};
    const cancelDate = useSelector(getSubscriptionEndDate);
    const profilePhoto = useSelector(getProfilePhoto);
    const paymentMethods = useSelector(getPaymentMethods);
    const subscriptionTierPlan = useSelector(getSubscriptionPlan);
    const removingPaymentLoading = useSelector(removingPayment);
    const agentHasFreeTrial: any = useSelector(
        (state: any) => state.auth.agent?.subscription?.isTrial,
    );

    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });
    const history = useHistory();
    const dispatch = useDispatch();

    const [editVisible, setEditVisible] = useState<boolean>(true);

    const [editPaymentMethods, setEditPaymentMethods] = useState<boolean>(false);

    useEffect(() => {
        if (!profileData) {
            dispatch(getProfileDataRequested());
        }
    }, []);
    useEffect(() => {
        dispatch(fetchBankAccountsRequested());
        dispatch(requestStripeLink());
    }, []);

    const cards = paymentMethods?.data?.length
        ? paymentMethods?.data?.map((paymentMethod: any) => {
              const {
                  card: { brand, last4, exp_month: expMonth, exp_year: expYear },
              } = paymentMethod;
              return {
                  brand,
                  last4,
                  expMonth,
                  expYear,
                  object: paymentMethod,
              };
          })
        : null;

    useEffect(() => {
        if (cards?.length >= 1) {
            setEditPaymentMethods(false);
        }
    }, [paymentMethods]);

    const cardSection = (
        <>
            {cards ? (
                !removingPaymentLoading ? (
                    cards.map((card: any) => {
                        return (
                            <div className={styleSheet.editOptions}>
                                <Typography textStyle="p1" color={theme.palette.black}>
                                    {capitalizeFirstLetter(card?.brand)} {card?.last4}
                                </Typography>
                                {editPaymentMethods ? (
                                    <img
                                        height={16}
                                        width={16}
                                        src={RemoveIcon}
                                        style={{ marginLeft: 5, cursor: 'pointer' }}
                                        onClick={() => {
                                            dispatch(removePaymentMethodsRequested(card?.object));
                                        }}
                                    />
                                ) : null}
                            </div>
                        );
                    })
                ) : (
                    <div
                        style={{
                            marginLeft: 25,
                            width: 'fit-content',
                        }}
                    >
                        <Loader />
                    </div>
                )
            ) : null}
        </>
    );

    const textButton = (text: string, onPress: Function, showButton?: boolean) => {
        return (
            <div className={styleSheet.textButtonContainer}>
                <ClickableWithFeedback
                    onClick={onPress}
                    className={styleSheet.textButtonInnerContainer}
                >
                    <Typography textStyle="p2" color={theme.palette.blue}>
                        {text}
                    </Typography>
                    {showButton ? <img src={RightArrow} className={styleSheet.blueArrow} /> : null}
                </ClickableWithFeedback>
            </div>
        );
    };

    const headerBar = (
        <div className={styleSheet.selectionRow}>
            <Typography textStyle="h4" color={theme.palette.mediumGrey}>
                Profile Overview
            </Typography>
            <ClickableWithFeedback
                onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push('/');
                    window.location.reload();
                }}
            >
                <Typography textStyle="h4" color={theme.palette.blue}>
                    Log Out
                </Typography>
            </ClickableWithFeedback>
        </div>
    );

    const userContent = (
        <div className={styleSheet.userContainer}>
            <div className={styleSheet.userInfoText}>
                <Typography
                    textStyle="h3"
                    color={theme.palette.darkGrey}
                    style={{ height: 26, marginTop: 50 }}
                >
                    {`${profileData.firstName} ${profileData.lastName}`}
                </Typography>
                <Typography textStyle="b1" color={theme.palette.black}>
                    {brokerages && `${brokerages.name}`}
                </Typography>
                <Typography textStyle="b1" color={theme.palette.black}>
                    {removeEmailAlias(profileData.email)}
                </Typography>
                <Typography textStyle="b1" color={theme.palette.black}>
                    {phoneDisplay(profileData.phoneNumber)}
                </Typography>
            </div>
            <div className={styleSheet.editProfileButton}>
                <ButtonComponent width={141} onClick={() => setEditVisible(true)}>
                    Edit Profile
                </ButtonComponent>
            </div>
            <div className={styleSheet.userPhotoContainer}>
                <img src={profilePhoto || DefaultProfile} style={{ width: 80, height: 80 }} />
            </div>
        </div>
    );

    const aboutContent = (
        <div className={styleSheet.aboutContainer}>
            <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                About
            </Typography>
            <Typography textStyle="b1" color={theme.palette.black} style={{ paddingTop: 10 }}>
                {profileData.about}
            </Typography>
        </div>
    );

    const showingContent =
        !subscriptionTierPlan || agentHasFreeTrial ? (
            <div className={styleSheet.aboutContainer}>
                <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                    Showing Management Plan
                </Typography>
                <Typography textStyle="b1" color={theme.palette.black} style={{ paddingTop: 10 }}>
                    {agentHasFreeTrial ? 'Premium Plan Free Trial' : 'Free Trial'}
                </Typography>
                {textButton(
                    'Upgrade Your Plan Today',
                    () => dispatch(updateUpgradeModalVisible(true)),
                    true,
                )}
            </div>
        ) : (
            <div className={styleSheet.aboutContainer}>
                <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                    Showing Management Plan
                </Typography>
                <span style={{ display: 'flex', alignItems: 'center', gap: 10, paddingTop: 10 }}>
                    <Typography textStyle="b1" color={theme.palette.black}>
                        {subscriptionTierPlan?.title} Plan
                    </Typography>
                    {!!cancelDate && <Tag text={'Valid till ' + cancelDate} color={'red'} />}
                </span>
                {textButton(
                    `${
                        subscriptionTierPlan?.tier === 2
                            ? 'View Plan'
                            : subscriptionTierPlan?.tier === 1
                            ? 'View/Upgrade Plan'
                            : 'Upgrade Your Plan Today'
                    }`,
                    () => dispatch(updateUpgradeModalVisible(true)),
                    true,
                )}
            </div>
        );

    const laContent = (
        <div className={styleSheet.aboutContainer}>
            <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                Linked Additional Licenses/Accounts
            </Typography>
            {profileData.markets?.length < 2 ? (
                <Typography textStyle="b1" color={theme.palette.black} style={{ paddingTop: 10 }}>
                    No additional licenses or accounts linked.
                </Typography>
            ) : (
                <>
                    <div style={{ paddingTop: 10 }} />
                    {profileData.markets.map((e: any) => {
                        return (
                            <>
                                <Typography textStyle="b1" color={theme.palette.black}>
                                    {`${e.marketName}`}
                                </Typography>
                                <Typography
                                    textStyle="b1"
                                    color={theme.palette.mediumGrey}
                                    style={{ marginLeft: 20 }}
                                >
                                    {`${e.mlsName}`}
                                </Typography>
                                <Typography
                                    textStyle="b1"
                                    color={theme.palette.mediumGrey}
                                    style={{ marginLeft: 40 }}
                                >
                                    {`${e.agentMlsId}`}
                                </Typography>
                            </>
                        );
                    })}
                </>
            )}
            {textButton('Link License/Account', () => history.push('/profile/link'))}
        </div>
    );

    const payContent = (
        <div className={styleSheet.aboutContainer}>
            <div className={styleSheet.paymentMethodRow}>
                <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                    Payment Method
                </Typography>
                {cards?.length > 1 ? (
                    <ClickableWithFeedback
                        style={{ marginLeft: 20 }}
                        onClick={() => setEditPaymentMethods(!editPaymentMethods)}
                    >
                        <Typography textStyle="b1" color={theme.palette.blue}>
                            Edit
                        </Typography>
                    </ClickableWithFeedback>
                ) : null}
            </div>
            {cardSection}
            {textButton('Add Payment Method', () => {
                history.push('/profile/addCard/add');
                dispatch(setPaymentModalType('add'));
            })}
        </div>
    );

    const invoicesContent = (
        <div className={styleSheet.aboutContainer}>
            <Typography textStyle="b1" color={theme.palette.mediumGrey}>
                Invoices
            </Typography>
            {textButton('View Invoice History', () => history.push('/profile/invoiceHistory'))}
        </div>
    );

    return (
        <div>
            <UpgradeSnackBar />
            {headerBar}
            <div className={styleSheet.uselessGreyBar} />
            <div className={styleSheet.innerRoot}>
                {userContent}
                {aboutContent}
                {showingContent}
                {laContent}
                {payContent}
                {invoicesContent}
            </div>
            <EditProfileModal
                onClose={() => setEditVisible(false)}
                visible={editVisible}
                agent={profileData}
                agentProfilePhoto={profilePhoto}
            />
        </div>
    );
};
export default ProfileScreenNew;
