import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './styles';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import indexTheme from '../../../theme';
import Typography from '../../../components/Typography';
import { DateTime, Interval } from 'luxon';
import useWindowDimensions, { formatPhoneNumberForDisplay } from '../../../utils/common';
import { fetchShowingsRequested, updateShowingsFilter, showingRefreshSucceeded } from './actions';
import {
    getShowingsFilter,
    getShowings,
    getShowingsLoading,
    getUpdatingShowing,
    getUpdateLog,
} from './selectors';
import {
    AnimatedDrawer,
    AnimatedDrawerHeader,
    ClickableWithFeedback,
    Loader,
    NewPaginator,
    UpgradeSnackBar,
} from '../../../components';
import GrayCal from '../../../images/grayCal.svg';
import GrayClock from '../../../images/grayClock.svg';
import { PAGINATION_LIMIT, ShowingGroupByDate, ShowingRequest, ShowingsPage } from './constants';
import ShowingCard from './ShowingCard';
import GreyClock from '../../../images/lightGreyClock.svg';
import { getStatusColor, mergeOverlappingShowings, sortShowings } from './utils';
import { fetchClientsRequested } from '../CRM/actions';
import FilterIcon from '../../../images/filter.svg';
import ShowingsFilter from './ShowingsFilter';
import GreyX from '../../../images/greyX.svg';
import RefreshIcon from '../../../images/refresh-ccw.svg';
import Stars from '../Listings/Stars';
import ShowingFeedbackCard from './ShowingFeedbackCard';
import { noop } from 'lodash';
import { getAgentObject } from '../../auth/selectors';

const feedbackStarStyle = { marginRight: 4, marginLeft: 0, height: 20 };
let visitedPages: Array<Number> = [];

const ShowingsScreen = (props: any) => {
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const agent = useSelector(getAgentObject);
    const showings = useSelector(getShowings);
    const loading = useSelector(getShowingsLoading);
    const updatingShowing = useSelector(getUpdatingShowing);
    const filter = useSelector(getShowingsFilter);
    const isUpdated = useSelector(getUpdateLog);
    // width & height are passed in so they can live update when the screen is being resized
    const styleSheet = useStyles({ ...{ width, height }, theme });

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedView, setSelectedView] = useState<any>(props.listSide ? 'listSide' : 'buySide');
    const [selectedFilter, setSelectedFilter] = useState(props.listSide ? 'All' : 'Upcoming');
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [feedbackDrawerShowing, setFeedbackDrawerShowing] = useState<any>(null);
    //const [hasChanges, setHasChanges] = useState(true);

    const drawerRef = useRef<any>(null);
    const feedbackGutterRef = useRef<any>(null);

    function fetchCurrentPage() {
        dispatch(
            fetchShowingsRequested({
                option: selectedView === 'buySide' ? 'Buy Side' : 'List Side',
                filterOption: selectedFilter,
                page: currentPage - 1,
            }),
        );
    }

    useEffect(() => {
        if (showings[selectedView][selectedFilter] == null || !visitedPages.includes(currentPage)) {
            visitedPages.push(currentPage);
            fetchCurrentPage();
        }
    }, [selectedView, selectedFilter, currentPage, updatingShowing]);

    useEffect(() => {
        if (!filter.id) {
            setCurrentPage(1);
            visitedPages = [1];
            // Checking if a new showing Changed the showingList to autoLoad it
            if (!isUpdated.showing) {
                fetchCurrentPage();
                dispatch(showingRefreshSucceeded());
            }
        }
    }, [selectedFilter, selectedView]);

    useEffect(() => {
        // If going over to listSide, set filter to All if feedback gutter is empty
        // Similarly, when going over to buySide, set filter to Upcoming if at a glance gutter is empty
        // Also, reset pagination

        if (selectedView === 'listSide' && !showings['listSide']['All']) {
            setSelectedFilter('All');
        }
        if (selectedView === 'buySide' && !showings['buySide']['Upcoming']) {
            setSelectedFilter('Upcoming');
        }
    }, [selectedView]);

    useEffect(() => {
        if (filter.id) {
            // See if the redux currently has anything
            const search = showings?.[selectedView]?.[filter.id];
            const fetching = loading.option === selectedView && loading.filterOption === filter.id;

            if (!search && !fetching) {
                dispatch(
                    fetchShowingsRequested({
                        option: selectedView === 'buySide' ? 'Buy Side' : 'List Side',
                        filterId: filter.id,
                    }),
                );
            }
        }
    }, [filter]);

    useEffect(() => {
        dispatch(fetchClientsRequested());
    }, []);

    const allShowings = [
        ...(showings.buySide.Upcoming
            ? showings.buySide.Upcoming.map(({ showings }: any) => showings).flat()
            : []),
        ...(showings.listSide?.Upcoming
            ? showings.listSide?.Upcoming.map(({ showings }: any) => showings).flat()
            : []),
    ];
    const now = DateTime.local();
    const atAGlanceShowings: any = allShowings.filter((sh: any) => {
        const { date } = sh;
        const dateTime = DateTime.fromJSDate(date);
        return (
            dateTime.day === now.day && dateTime.month === now.month && dateTime.year === now.year
        );
    });

    const mergedShowingsAtAGlance =
        atAGlanceShowings?.length && atAGlanceShowings[0]?.showingRequests
            ? mergeOverlappingShowings([...atAGlanceShowings[0]?.showingRequests])
            : [];

    const filterOptions = ['All', 'Upcoming', 'Needs Action'];
    const selections = ['Buy Side', 'List Side'];

    const displayHours = [
        '7am',
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
        '8pm',
        '9pm',
        '10pm',
        '11pm',
    ];

    const currentPageShowings = showings[selectedView][selectedFilter]?.find(
        (s: ShowingsPage) => s.page === currentPage - 1,
    );

    const filterButton = (text: string) => {
        const selected = selectedFilter === text;
        return (
            <ClickableWithFeedback
                onClick={() => setSelectedFilter(text)}
                style={{
                    backgroundColor: selected
                        ? indexTheme.palette.blue
                        : indexTheme.palette.background,
                }}
                className={styleSheet.filterOption}
            >
                <Typography
                    color={selected ? indexTheme.palette.white : indexTheme.palette.black}
                    textStyle={'h4'}
                    style={{ 
                        fontSize: width > 1224 ? 15 : 12, 
                        wordBreak: "keep-all",
                        
                     }}
                >
                    {text}
                </Typography>
            </ClickableWithFeedback>
        );
    };

    const noShowingsSection = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 200,
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                <Typography color={indexTheme.palette.mediumGrey} textStyle={'h3'}>
                    No Showings Found
                </Typography>
            )}
        </div>
    );

    const onScroll = () => {
        if (!filter.id && feedbackGutterRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = feedbackGutterRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (
                    selectedView === 'listSide' &&
                    loading.option !== 'listSide' &&
                    loading.filterOption !== 'All'
                ) {
                    const totalPages = Math.ceil(
                        agent.showingRequests['listSide']['all'].length / PAGINATION_LIMIT,
                    );
                    for (let i = 0; i < totalPages; i++) {
                        if (showings['listSide']['All']?.find((d: any) => d.page === i)) {
                            continue;
                        }
                        dispatch(
                            fetchShowingsRequested({
                                option: 'listSide',
                                filterOption: 'All',
                                page: i,
                            }),
                        );
                        break;
                    }
                }
            }
        }
    };

    return (
        <div className={styleSheet.root}>
            <UpgradeSnackBar />

            <div className={styleSheet.pageHeader}>
                <Typography textStyle={'h4'} color={indexTheme.palette.mediumGrey}>
                    Your Showings
                </Typography>
                <div className={styleSheet.selectionContainer}>
                    {selections.map((selection, index) => {
                        const lintedSelection = selection.split(' ');
                        const camelCasedSelection =
                            lintedSelection[0].toLowerCase() + lintedSelection[1];
                        return (
                            <>
                                <ClickableWithFeedback
                                    onClick={() => {
                                        setSelectedView(camelCasedSelection);
                                        if (
                                            filter.type === 'listingId' &&
                                            selection === 'Buy Side'
                                        ) {
                                            dispatch(updateShowingsFilter({}));
                                        } else if (
                                            filter.type === 'consumerId' &&
                                            selection === 'List Side'
                                        ) {
                                            dispatch(updateShowingsFilter({}));
                                        }
                                    }}
                                >
                                    <Typography
                                        color={
                                            camelCasedSelection === selectedView
                                                ? indexTheme.palette.blue
                                                : indexTheme.palette.mediumGrey
                                        }
                                        textStyle={'h4'}
                                    >
                                        {selection}
                                    </Typography>
                                </ClickableWithFeedback>
                                {index !== selections.length - 1 && (
                                    <div className={styleSheet.verticalSeparator} />
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div className={styleSheet.calendarSection}>
                    <div className={styleSheet.miniPageHeader} style={{ flexDirection: width < 1210 ? "column" : "row", justifyContent: width <= 1210 ? "space-around" : "space-between", alignItems: width <= 1210 ? "flex-start" : "center"}}>
                        <Typography textStyle={width <= 1024 ?'p2' : width <= 1310 ? 'p1' : 'h4'} color={indexTheme.palette.mediumGrey}
                            
                        >
                            {selectedView === 'buySide' ? 'At a Glance' : 'Feedback'}
                        </Typography>

                        {selectedView === 'buySide' && (
                            <Typography textStyle={width <= 1024 ?'p2' : width <= 1310 ? 'p1' : 'h4'} color={indexTheme.palette.mediumGrey}>
                                {DateTime.local().toFormat('DD')}
                            </Typography>
                        )}
                    </div>
                    <div
                        className={styleSheet.calScrollView}
                        style={
                            selectedView === 'listSide'
                                ? { flexDirection: 'column', marginBottom: 100 }
                                : {}
                        }
                        ref={feedbackGutterRef}
                        onScroll={onScroll}
                    >
                        {selectedView === 'buySide' ? (
                            <>
                                <div className={styleSheet.timeLinesContainer}>
                                    {displayHours.map((dh, index) => {
                                        return (
                                            <div className={styleSheet.timeLines}>
                                                <div className={styleSheet.topRow}>
                                                    <span className={styleSheet.topRowText}>
                                                        {dh}
                                                    </span>
                                                    <div className={styleSheet.timeLine} />
                                                </div>
                                                <div className={styleSheet.otherLines}>
                                                    <div className={styleSheet.noLine} />
                                                    <div className={styleSheet.tinyLine} />
                                                    <div className={styleSheet.smallLine} />
                                                    <div className={styleSheet.tinyLine} />
                                                    <div className={styleSheet.bottomNoLine} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={styleSheet.theGridAndLines}>
                                    <div className={styleSheet.lines} />
                                    <div className={styleSheet.theGrid}>
                                        <div className={styleSheet.gridColumn}>
                                            {displayHours.map(() => {
                                                return <div className={styleSheet.gridBox} />;
                                            })}
                                            {mergedShowingsAtAGlance.map((todayShowing: any) => {
                                                if (Array.isArray(todayShowing)) {
                                                    // If there is an array of overlapping showings rather than a single showing,
                                                    // we need special logic to render them properly

                                                    const cardWidth =
                                                        todayShowing.length > 3
                                                            ? 500 / todayShowing.length
                                                            : 460 / todayShowing.length;

                                                    return (
                                                        <>
                                                            {todayShowing.map(
                                                                (
                                                                    showingTime: any,
                                                                    index: number,
                                                                ) => {
                                                                    const dateTimeStart = DateTime.fromJSDate(
                                                                        showingTime.start,
                                                                    );
                                                                    const dateTimeEnd = DateTime.fromJSDate(
                                                                        showingTime.end,
                                                                    );
                                                                    const showingInterval = Interval.fromDateTimes(
                                                                        dateTimeStart,
                                                                        dateTimeEnd,
                                                                    );
                                                                    const duration: any = showingInterval
                                                                        .toDuration('minutes')
                                                                        .toObject().minutes;
                                                                    const posTop =
                                                                        (dateTimeStart.hour - 7) *
                                                                            80 +
                                                                        (dateTimeStart.minute /
                                                                            60) *
                                                                            80 +
                                                                        42;
                                                                    const heightCalc =
                                                                        duration * 1.33333333;
                                                                    const showTime = duration > 15;
                                                                    return (
                                                                        <div
                                                                            className={
                                                                                styleSheet.calendarShowingCard
                                                                            }
                                                                            style={{
                                                                                height: heightCalc,
                                                                                top: posTop,
                                                                                width: cardWidth,
                                                                                left:
                                                                                    48 +
                                                                                    index *
                                                                                        cardWidth,
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styleSheet.calendarShowingCardStatusBar
                                                                                }
                                                                                style={{
                                                                                    height: heightCalc,
                                                                                    backgroundColor: getStatusColor(
                                                                                        showingTime?.status,
                                                                                    ),
                                                                                }}
                                                                            />
                                                                            <Typography
                                                                                textStyle="p2"
                                                                                color={
                                                                                    theme.palette
                                                                                        .black
                                                                                }
                                                                                style={{
                                                                                    paddingLeft: 10,
                                                                                }}
                                                                            >
                                                                                Showing{' '}
                                                                                {showingTime.consumer &&
                                                                                    `with ${
                                                                                        showingTime
                                                                                            .consumer
                                                                                            .firstName
                                                                                    } ${showingTime.consumer.lastName?.charAt(
                                                                                        0,
                                                                                    )}.`}
                                                                            </Typography>
                                                                            {showTime && (
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        flexDirection:
                                                                                            'row',
                                                                                        alignItems:
                                                                                            'center',
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            GreyClock
                                                                                        }
                                                                                        className={
                                                                                            styleSheet.littleClockIcon
                                                                                        }
                                                                                    />
                                                                                    <Typography
                                                                                        textStyle="b2"
                                                                                        color={
                                                                                            theme
                                                                                                .palette
                                                                                                .lightGrey
                                                                                        }
                                                                                    >
                                                                                        {dateTimeStart.toFormat(
                                                                                            'h:mm',
                                                                                        )}
                                                                                        {dateTimeStart
                                                                                            .toFormat(
                                                                                                'a',
                                                                                            )
                                                                                            .toUpperCase()}{' '}
                                                                                        -{' '}
                                                                                        {dateTimeEnd.toFormat(
                                                                                            'h:mm',
                                                                                        )}
                                                                                        {dateTimeEnd
                                                                                            .toFormat(
                                                                                                'a',
                                                                                            )
                                                                                            .toUpperCase()}
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                        </>
                                                    );
                                                } else {
                                                    const dateTimeStart = DateTime.fromJSDate(
                                                        todayShowing.start,
                                                    );
                                                    const dateTimeEnd = DateTime.fromJSDate(
                                                        todayShowing.end,
                                                    );
                                                    const showingInterval = Interval.fromDateTimes(
                                                        dateTimeStart,
                                                        dateTimeEnd,
                                                    );
                                                    const duration: any = showingInterval
                                                        .toDuration('minutes')
                                                        .toObject().minutes;
                                                    const posTop =
                                                        (dateTimeStart.hour - 7) * 80 +
                                                        (dateTimeStart.minute / 60) * 80 +
                                                        42;
                                                    const heightCalc = duration * 1.33333333;
                                                    const showTime = duration > 15;
                                                    return (
                                                        <div
                                                            className={
                                                                styleSheet.calendarShowingCard
                                                            }
                                                            style={{
                                                                height: heightCalc,
                                                                top: posTop,
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    styleSheet.calendarShowingCardStatusBar
                                                                }
                                                                style={{
                                                                    height: heightCalc,
                                                                    backgroundColor: getStatusColor(
                                                                        todayShowing?.status,
                                                                    ),
                                                                }}
                                                            />
                                                            <Typography
                                                                textStyle="p2"
                                                                color={theme.palette.black}
                                                                style={{ paddingLeft: 10 }}
                                                            >
                                                                Showing{' '}
                                                                {todayShowing.consumer &&
                                                                    `with ${
                                                                        todayShowing.consumer
                                                                            .firstName
                                                                    } ${todayShowing.consumer.lastName?.charAt(
                                                                        0,
                                                                    )}.`}
                                                            </Typography>
                                                            {showTime && (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={GreyClock}
                                                                        className={
                                                                            styleSheet.littleClockIcon
                                                                        }
                                                                    />
                                                                    <Typography
                                                                        textStyle="b2"
                                                                        color={
                                                                            theme.palette.lightGrey
                                                                        }
                                                                    >
                                                                        {dateTimeStart.toFormat(
                                                                            'h:mm',
                                                                        )}
                                                                        {dateTimeStart
                                                                            .toFormat('a')
                                                                            .toUpperCase()}{' '}
                                                                        -{' '}
                                                                        {dateTimeEnd.toFormat(
                                                                            'h:mm',
                                                                        )}
                                                                        {dateTimeEnd
                                                                            .toFormat('a')
                                                                            .toUpperCase()}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {filter.id
                                    ? showings['listSide'][filter.id]?.map(
                                          (groupItem: ShowingGroupByDate) =>
                                              groupItem?.showingRequests?.map((showing: any) => {
                                                  if (
                                                      showing?.feedback &&
                                                      (!filter.type ||
                                                          showing?.listing?._id.toString() ===
                                                              filter.id)
                                                  )
                                                      return (
                                                          <ShowingFeedbackCard
                                                              showing={showing}
                                                              selectedType={selectedView}
                                                              updatingShowing={updatingShowing}
                                                              onViewFeedback={(showing: any) => {
                                                                  setFeedbackDrawerShowing(showing);
                                                                  setShowDrawer(true);
                                                              }}
                                                          />
                                                      );
                                              }),
                                      )
                                    : showings?.['listSide']?.['All']?.map(
                                          (page: {
                                              page: number;
                                              showings: ShowingGroupByDate[];
                                          }) =>
                                              page.showings.map((groupItem: ShowingGroupByDate) =>
                                                  groupItem?.showingRequests?.map(
                                                      (showing: any) => {
                                                          if (
                                                              showing?.feedback &&
                                                              (!filter.type ||
                                                                  showing?.listing?._id.toString() ===
                                                                      filter.id)
                                                          )
                                                              return (
                                                                  <ShowingFeedbackCard
                                                                      showing={showing}
                                                                      selectedType={selectedView}
                                                                      updatingShowing={
                                                                          updatingShowing
                                                                      }
                                                                      onViewFeedback={(
                                                                          showing: any,
                                                                      ) => {
                                                                          setFeedbackDrawerShowing(
                                                                              showing,
                                                                          );
                                                                          setShowDrawer(true);
                                                                      }}
                                                                  />
                                                              );
                                                      },
                                                  ),
                                              ),
                                      )}

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: 100,
                                    }}
                                >
                                    {loading.option === 'listSide' &&
                                    loading.filterOption === (filter.id ? filter.id : 'All') ? (
                                        <Loader />
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={styleSheet.showingsSection}>
                    <div className={styleSheet.miniPageHeader}>
                        <div className={styleSheet.filterButtonContainer}>
                            {filterOptions.map((option) => {
                                return filterButton(option);
                            })}
                            <ClickableWithFeedback
                                onClick={fetchCurrentPage}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img style={{ marginRight: 10 }} src={RefreshIcon} />
                            </ClickableWithFeedback>
                        </div>

                        {!filter.type ? (
                            <ClickableWithFeedback
                                onClick={() => {
                                    setShowDrawer(!showDrawer);
                                }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <img style={{ marginRight: 10 }} src={FilterIcon} />
                                <Typography textStyle={'p2'} color={indexTheme.palette.blue}>
                                    Filter by {selectedView === 'listSide' ? 'Listing' : 'Client'}
                                </Typography>
                            </ClickableWithFeedback>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: indexTheme.palette.separatorGrey,
                                    borderRadius: 25,
                                    ...indexTheme.paddingHorizontal(10),
                                    ...indexTheme.paddingVertical(20),
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 26,
                                    
                                }}
                            >
                                <Typography color={indexTheme.palette.mediumGrey} textStyle= {width <= 1024 ? "p4" : width <=1310 ? "p3" : "p1"}>
                                    {filter.displayText}
                                </Typography>
                                <ClickableWithFeedback
                                    style={{ marginLeft: 10, marginTop: 6.5 }}
                                    onClick={() => dispatch(updateShowingsFilter({}))}
                                >
                                    <img src={GreyX} />
                                </ClickableWithFeedback>
                            </div>
                        )}
                    </div>
                    <div className={styleSheet.scrollView}>
                        {!filter.id && (
                            <div className={styleSheet.paginatorContainer}>
                                <NewPaginator
                                    data={
                                        agent.showingRequests?.[selectedView]?.[
                                            selectedFilter === 'Needs Action'
                                                ? 'needsAction'
                                                : selectedFilter.toLowerCase()
                                        ] ?? []
                                    }
                                    itemsPerPage={PAGINATION_LIMIT}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    setPaginatedData={noop} // We don't really need to modify the data from within the paginator
                                />
                            </div>
                        )}
                        {!(
                            loading.option === selectedView &&
                            loading.filterOption === (filter.id ? filter.id : selectedFilter) &&
                            loading.page === currentPage - 1
                        ) &&
                        !filter.id &&
                        currentPageShowings?.showings?.length
                            ? currentPageShowings?.showings.map((groupItem: ShowingGroupByDate) => {
                                  const showingIds = groupItem.showingRequests.map((s: any) => {
                                      return s[filter.type]?.toString();
                                  });
                                  return (
                                      <div style={{ marginTop: 8 }}>
                                          {filter.type && showingIds.includes(filter.id) ? (
                                              <div style={{ marginBottom: 8 }}>
                                                  <Typography
                                                      textStyle={'b1'}
                                                      color={indexTheme.palette.mediumGrey}
                                                      style={{
                                                          marginLeft: 48,
                                                      }}
                                                  >
                                                      {DateTime.fromJSDate(groupItem.date).toFormat(
                                                          'DDDD',
                                                      )}
                                                  </Typography>
                                              </div>
                                          ) : !filter.type ? (
                                              <div style={{ marginBottom: 8 }}>
                                                  <Typography
                                                      textStyle={'b1'}
                                                      color={indexTheme.palette.mediumGrey}
                                                      style={{
                                                          marginLeft: 48,
                                                      }}
                                                  >
                                                      {DateTime.fromJSDate(groupItem.date).toFormat(
                                                          'DDDD',
                                                      )}
                                                  </Typography>
                                              </div>
                                          ) : null}
                                          {sortShowings(
                                              groupItem.showingRequests,
                                              selectedFilter,
                                          ).map((showing: any) => {
                                              if (
                                                  filter.type &&
                                                  filter.id === showing[filter.type]?.toString()
                                              ) {
                                                  return (
                                                      <ShowingCard
                                                          refresh={fetchCurrentPage}
                                                          showing={showing}
                                                          selectedType={selectedView}
                                                          updatingShowing={updatingShowing}
                                                          onViewFeedback={(showing: any) => {
                                                              setFeedbackDrawerShowing(showing);
                                                              setShowDrawer(true);
                                                          }}
                                                      />
                                                  );
                                              } else if (!filter.type) {
                                                  return (
                                                      <ShowingCard
                                                          refresh={fetchCurrentPage}
                                                          showing={showing}
                                                          selectedType={selectedView}
                                                          updatingShowing={updatingShowing}
                                                          onViewFeedback={(showing: any) => {
                                                              setFeedbackDrawerShowing(showing);
                                                              setShowDrawer(true);
                                                          }}
                                                      />
                                                  );
                                              }
                                          })}
                                      </div>
                                  );
                              })
                            : filter.id && showings[selectedView][filter.id]?.length
                            ? showings[selectedView][filter.id]
                                  .sort((a: any, b: any) =>
                                      selectedFilter === 'Upcoming'
                                          ? a.date - b.date
                                          : b.date - a.date,
                                  )
                                  .map((groupItem: ShowingGroupByDate) => {
                                      const sortedAndFiltered = sortShowings(
                                          groupItem.showingRequests,
                                          selectedFilter,
                                      ).filter((showing: any) => {
                                          if (selectedFilter === 'Upcoming') {
                                              return (
                                                  showing.start >= new Date() &&
                                                  ['pending_internal', 'confirmed'].includes(
                                                      showing.status,
                                                  )
                                              );
                                          } else if (selectedFilter === 'Needs Action') {
                                              if (selectedView === 'buySide') {
                                                  return (
                                                      showing.status === 'requested' ||
                                                      (showing.status === 'completed' &&
                                                          !showing.feedback)
                                                  );
                                              } else {
                                                  return showing.status === 'pending_internal';
                                              }
                                          } else {
                                              return true;
                                          }
                                      });
                                      return (
                                          <div style={{ marginTop: 8 }}>
                                              {sortedAndFiltered.length ? (
                                                  <div style={{ marginBottom: 8 }}>
                                                      <Typography
                                                          textStyle={'b1'}
                                                          color={indexTheme.palette.mediumGrey}
                                                          style={{
                                                              marginLeft: 48,
                                                          }}
                                                      >
                                                          {DateTime.fromJSDate(
                                                              groupItem.date,
                                                          ).toFormat('DDDD')}
                                                      </Typography>
                                                  </div>
                                              ) : null}
                                              {sortedAndFiltered.map((showing: any) => {
                                                  return (
                                                      <ShowingCard
                                                          refresh={fetchCurrentPage}
                                                          showing={showing}
                                                          selectedType={selectedView}
                                                          updatingShowing={updatingShowing}
                                                          onViewFeedback={(showing: any) => {
                                                              setFeedbackDrawerShowing(showing);
                                                              setShowDrawer(true);
                                                          }}
                                                      />
                                                  );
                                              })}
                                          </div>
                                      );
                                  })
                            : noShowingsSection}
                        {showDrawer ? (
                            <AnimatedDrawer ref={drawerRef} onClose={() => setShowDrawer(false)}>
                                <AnimatedDrawerHeader
                                    title={
                                        feedbackDrawerShowing
                                            ? 'Feedback'
                                            : `Select a ${
                                                  selectedView === 'listSide' ? 'Listing' : 'Client'
                                              }`
                                    }
                                    onClosePress={() => drawerRef?.current?.closeDrawer()}
                                />
                                {feedbackDrawerShowing ? (
                                    <>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <Typography textStyle={'p1'}>
                                                    {feedbackDrawerShowing.listing.address.full}{' '}
                                                </Typography>
                                                <Typography textStyle={'b1'}>
                                                    {feedbackDrawerShowing.listing.address.city},{' '}
                                                    {feedbackDrawerShowing.listing.address.state}{' '}
                                                    {
                                                        feedbackDrawerShowing.listing.address
                                                            .postalCode
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <Typography textStyle={'p1'}>
                                                    {feedbackDrawerShowing?.agent?.firstName +
                                                        ' ' +
                                                        feedbackDrawerShowing?.agent?.lastName}
                                                </Typography>
                                                <Typography
                                                    textStyle={'b1'}
                                                    color={theme.palette.mediumGrey}
                                                >
                                                    {feedbackDrawerShowing?.agent?.brokerages}
                                                </Typography>
                                            </div>
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <Typography textStyle={'b1'}>
                                                    {feedbackDrawerShowing?.agent?.email}
                                                </Typography>
                                                <Typography textStyle={'b1'}>
                                                    {`+1 ${formatPhoneNumberForDisplay(
                                                        feedbackDrawerShowing?.agent?.phoneNumber,
                                                    )}`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <span className={styleSheet.iconRow}>
                                                    <img
                                                        src={GrayCal}
                                                        height={20}
                                                        style={{ marginRight: 12 }}
                                                    />{' '}
                                                    <Typography
                                                        textStyle={'b1'}
                                                        color={theme.palette.mediumGrey}
                                                    >
                                                        {DateTime.fromJSDate(
                                                            feedbackDrawerShowing.start,
                                                        ).toFormat('DDDD')}
                                                    </Typography>
                                                </span>
                                            </div>
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <span className={styleSheet.iconRow}>
                                                    <img
                                                        src={GrayClock}
                                                        height={20}
                                                        style={{ marginRight: 12 }}
                                                    />
                                                    <Typography
                                                        color={theme.palette.mediumGrey}
                                                        textStyle={'b1'}
                                                    >
                                                        {DateTime.fromJSDate(
                                                            feedbackDrawerShowing.start,
                                                        ).toFormat('t')}{' '}
                                                        -{' '}
                                                        {DateTime.fromJSDate(
                                                            feedbackDrawerShowing.end,
                                                        ).toFormat('t')}
                                                    </Typography>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <Typography textStyle={'b1'}>Price Accuracy</Typography>
                                            <span className={styleSheet.feedbackSeparator}></span>
                                            <span>
                                                <Stars
                                                    value={
                                                        feedbackDrawerShowing?.feedback?.ratings
                                                            ?.priceAccuracy
                                                    }
                                                    starStyle={feedbackStarStyle}
                                                />
                                            </span>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <Typography textStyle={'b1'}>Location</Typography>
                                            <span className={styleSheet.feedbackSeparator}></span>
                                            <span>
                                                <Stars
                                                    value={
                                                        feedbackDrawerShowing?.feedback?.ratings
                                                            ?.location
                                                    }
                                                    starStyle={feedbackStarStyle}
                                                />
                                            </span>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <Typography textStyle={'b1'}>Interest Level</Typography>
                                            <span className={styleSheet.feedbackSeparator}></span>
                                            <span>
                                                <Stars
                                                    value={
                                                        feedbackDrawerShowing?.feedback?.ratings
                                                            ?.interestLevel
                                                    }
                                                    starStyle={feedbackStarStyle}
                                                />
                                            </span>
                                        </div>
                                        <div className={styleSheet.feedbackDetailRow}>
                                            <Typography textStyle={'p1'}>Overall Rating</Typography>
                                            <span className={styleSheet.feedbackSeparator}></span>
                                            <span>
                                                <Stars
                                                    value={
                                                        (feedbackDrawerShowing?.feedback?.ratings
                                                            ?.priceAccuracy +
                                                            feedbackDrawerShowing?.feedback?.ratings
                                                                ?.location +
                                                            feedbackDrawerShowing?.feedback?.ratings
                                                                ?.interestLevel) /
                                                        3
                                                    }
                                                    starStyle={feedbackStarStyle}
                                                />
                                            </span>
                                        </div>
                                        <div
                                            className={styleSheet.feedbackDetailRow}
                                            style={{ borderBottom: 'none' }}
                                        >
                                            <div className={styleSheet.feedbackDetailColumn}>
                                                <Typography
                                                    textStyle={'b1'}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Additional Feedback
                                                </Typography>
                                                <Typography textStyle={'b2'}>
                                                    {feedbackDrawerShowing.feedback.review}
                                                </Typography>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <ShowingsFilter
                                        onChange={(args: any) => {
                                            dispatch(updateShowingsFilter(args));
                                            drawerRef?.current?.closeDrawer();
                                            setShowDrawer(false);
                                        }}
                                        type={selectedView}
                                    />
                                )}
                            </AnimatedDrawer>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ShowingsScreen;
