import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import agentWordmark from './agentWordmark.svg';
import { routerRef } from './utils';
import LoginFlow from './domains/auth';
import Login from './domains/auth/Login';
import ConfirmDenyScreen from './domains/confirm_deny';
import styles from './utils/commonStyles.module.css';
import AgentStart from './domains/auth/AgentStart';
import EntryAgentSearch from './domains/auth/EntryAgentSearch';
import AgentLogin from './domains/auth/AgentLogin';
import AgentVerify from './domains/auth/AgentVerify';
import AgentSignup from './domains/auth/AgentSignup';
import PasswordReset from './domains/auth/PasswordReset';
import ListingDetailsScreenUnverified from './domains/unverifiedMain/NonMlsShowingRequests';
import UnverifiedShowingConfirmationScreen from './domains/unverifiedMain/UnverifiedShowingConfirmation';
import { isMobile } from './utils/common';

function UnauthenticatedApp() {
    const history = useHistory();

    // contains pages that need to be honored/rendered despite being un-authenticated
    const pages = ['showingRequests', 'schedule'];

    // bring user back to /auth on refresh if remember me is not selected
    // we need to leave this here. If this is not here the app white screens on re-fresh when remember me isn't
    const entryData = window.sessionStorage.getItem('entryEndpointData');
    const itemsInUrl = window.location.href.split('/');
    if (
        !entryData &&
        (!pages.some((item) => window.location.href.split('/').includes(item)) ||
            itemsInUrl.length < 5)
    ) {
        const location = window.location;
        const path = location.pathname;
        const main = path.split('/');
        if (!(main[1] === 'entry' && main[2] === 'search')) {
            history.push('/auth');
        }
    }

    const Header = () => {
        const className = isMobile() ? styles.headerMobile : styles.header;
        return (
            <div className={className}>
                <img src={agentWordmark} className={styles.wordmark} />
            </div>
        );
    };

    return (
        <BrowserRouter
            basename={process.env.PUBLIC_URL}
            ref={(e) => {
                routerRef.current = e;
            }}
        >
            <Header />
            <div style={{ height: 60, width: '100%' }} />
            <Switch>
                {/* CHANGE flip sginup and ath */}
                <Route exact path="/auth/login">
                    <AgentLogin />
                </Route>
                <Route exact path="/auth/search">
                    <EntryAgentSearch />
                </Route>
                <Route exact path="/auth/signup">
                    <AgentSignup />
                </Route>
                <Route exact path="/auth/verify">
                    <AgentVerify />
                </Route>
                <Route exact path="/auth/reset">
                    <PasswordReset />
                </Route>
                <Route exact path="/auth">
                    <AgentStart />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>

                <Route
                    path="/showingRequests/:_id"
                    render={() => {
                        return <UnverifiedShowingConfirmationScreen />;
                    }}
                />

                <Route
                    path="/schedule/:listingId"
                    render={(props) => (
                        <ListingDetailsScreenUnverified listingKey={props.match.params.listingId} />
                    )}
                />

                <Route
                    exact
                    path="/"
                    render={() => {
                        return <Redirect to="/auth" />;
                    }}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default UnauthenticatedApp;
