export default (theme: any) => {
    return {
        header: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            maxHeight: 60,
            flex: 1,
            borderBottom: `1px solid ${theme.palette.grey}`,
            position: 'relative',
        },
        xButton: {
            position: 'absolute',
            height: 30,
            width: 30,
            top: 15,
            left: 15,
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
        },
        footer: {
            maxHeight: 78,
            flex: 1,
            borderTop: `1px solid ${theme.palette.grey}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        button: {
            marginRight: 15,
        },
        loadingContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 105,
        },
        selectContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 15,
        },
        statusContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 105,
            textAlign: 'center',
        },
    };
};
