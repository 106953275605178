import {
    MyShowingsAction,
    UpdateShowingStatus,
    CLEAR_SHOWING_RESCHEDULE_STATUS,
    CLEAR_FETCH_SHOWINGS_STATUS,
} from './actions';
import { Showing, SHOWING_ACTION } from '../../../utils/constants';
import { CLEAR_SELECTED_LISTING_AND_SHOWING } from '../../../store/api/constants';
import {
    MY_SHOWINGS_ACTION,
    MY_SHOWINGS_FILTER,
    ShowingsGroupedByListing,
    STATUS,
    FIND_ALL_SHOWING_DATA_ACTION,
} from './types';
import { SET_SELECTED_SHOWING } from '../SearchListings/actions';
import { updateShowingsInRedux } from './utils';

export const initialState = {
    loading: false,
    filter: MY_SHOWINGS_FILTER.None,
    mySearchedShowings: [],
    error: '',
    [MY_SHOWINGS_FILTER.None]: [] as ShowingsGroupedByListing[] & Showing[],
    [MY_SHOWINGS_FILTER.NeedsAction]: [] as ShowingsGroupedByListing[],
    [MY_SHOWINGS_FILTER.Upcoming]: [] as ShowingsGroupedByListing[],
    [MY_SHOWINGS_FILTER.Leads]: [] as ShowingsGroupedByListing[],
    [MY_SHOWINGS_FILTER.Delegated]: [] as ShowingsGroupedByListing[],
    [MY_SHOWINGS_FILTER.PickUp]: [] as ShowingsGroupedByListing[],
    [MY_SHOWINGS_FILTER.ClientShowing]: [] as ShowingsGroupedByListing[],
    attempted: {
        [MY_SHOWINGS_FILTER.None]: false,
        [MY_SHOWINGS_FILTER.NeedsAction]: false,
        [MY_SHOWINGS_FILTER.Upcoming]: false,
        [MY_SHOWINGS_FILTER.Leads]: false,
        [MY_SHOWINGS_FILTER.Delegated]: false,
        [MY_SHOWINGS_FILTER.PickUp]: false,
        [MY_SHOWINGS_FILTER.ClientShowing]: false,
    },
    selectedShowing: null,
    updatingStatus: false,
    isSubmitFeedbackDisabled: false,
    rescheduleShowingSuccess: false,
    showingsHaveUpdated: false,
    refreshing: false,
    paginationLoader: false,
    showingsFetchedSuccessfully: false,
    rescheduling: false,

    // used to conditionally reender the loader on ShowingCards
    showingIdBeingUpdated: '',

    scheduleShowingErrors: [],
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case MY_SHOWINGS_ACTION.SubmitFeedback:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        isSubmitFeedbackDisabled: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        isSubmitFeedbackDisabled: true,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        error: action.error,
                    };
            }
        case MY_SHOWINGS_ACTION.GetFeedback:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        hasFeedback: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        hasFeedback: true,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
            }
        case MY_SHOWINGS_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Succeeded:
                    const showingsGroupedByFilter = action.myShowings;
                    return {
                        ...state,

                        loading: false,
                        refreshing: false,
                        attempted: {
                            ...state.attempted,
                            [action.filter]: true,
                        },
                        error: '',
                        //[action.filter]: action.myShowings,
                        [MY_SHOWINGS_FILTER.None]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.None as any],
                        [MY_SHOWINGS_FILTER.NeedsAction]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.NeedsAction as any],
                        [MY_SHOWINGS_FILTER.Upcoming]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.Upcoming as any],
                        [MY_SHOWINGS_FILTER.Leads]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.Leads as any],
                        [MY_SHOWINGS_FILTER.Delegated]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.Delegated as any],
                        [MY_SHOWINGS_FILTER.PickUp]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.PickUp as any],
                        [MY_SHOWINGS_FILTER.ClientShowing]:
                            showingsGroupedByFilter[MY_SHOWINGS_FILTER.ClientShowing as any],

                        showingsFetchedSuccessfully: true,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        refreshing: false,
                        attempted: {
                            ...state.attempted,
                            [action.filter]: true,
                        },
                        error: action.error,
                    };
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        showingsFetchedSuccessfully: false,
                    };
                }
            }
        case MY_SHOWINGS_ACTION.Search:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                        errors: [],
                        showingsHaveUpdated: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loading: false,
                        mySearchedShowings: action.myShowings,
                        showingsHaveUpdated: true,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                        mySearchedShowings: [],
                    };
            }

        case MY_SHOWINGS_ACTION.SetFilter:
            return {
                ...state,
                listIndexSkip: 0,
                filter: action.filter,
            };
        case MY_SHOWINGS_ACTION.SetLoading:
            return {
                ...state,
                mySearchedShowings: [],
                loading: action.loading,
            };
        case MY_SHOWINGS_ACTION.SetFeedbackDisabled:
            return {
                ...state,
                isSubmitFeedbackDisabled: false,
            };
        case MY_SHOWINGS_ACTION.AddNew:
            const { showing } = action;
            return {
                ...state,
                selectedShowing: showing,
            };

        case FIND_ALL_SHOWING_DATA_ACTION.Success: {
            // on the my showings screen we re-fetch data when the status is updated and/or the showing is rescheduled
            // this logic is to ensure the new showing status/info is added into redux without re-fetching the users myShowingsList
            const updatedShowing = action.allShowingData;
            if (!updatedShowing) {
                return {
                    ...state,
                    selectedShowing: action.allShowingData,
                };
            }

            // loop through every MY_SHOWINGS_FILTERs and if we find a match for showingId, replace the data
            return {
                ...state,
                [MY_SHOWINGS_FILTER.None]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.None],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.NeedsAction]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.NeedsAction],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.Upcoming]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.Upcoming],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.Leads]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.Leads],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.Delegated]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.Delegated],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.PickUp]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.PickUp],
                    updatedShowing,
                ),
                [MY_SHOWINGS_FILTER.ClientShowing]: updateShowingsInRedux(
                    state[MY_SHOWINGS_FILTER.ClientShowing],
                    updatedShowing,
                ),
                selectedShowing: updatedShowing,
            };
        }

        case FIND_ALL_SHOWING_DATA_ACTION.Failure:
            return {
                ...state,
                error: action.error,
            };
        case CLEAR_SELECTED_LISTING_AND_SHOWING:
            return {
                ...state,
                selectedShowing: null,
            };
        case SET_SELECTED_SHOWING:
            const { selectedShowing } = action;
            return {
                ...state,
                selectedShowing,
            };
        case CLEAR_SHOWING_RESCHEDULE_STATUS:
            return {
                ...state,
                rescheduleShowingSuccess: false,
            };
        case CLEAR_FETCH_SHOWINGS_STATUS:
            return {
                ...state,
                showingsFetchedSuccessfully: false,
            };

        case SHOWING_ACTION.UpdateStatus:
            if (!action.status) {
                return {
                    ...state,
                    updatingStatus: true,
                };
            } else {
                return {
                    ...state,
                    updatingStatus: false,
                };
            }

        case MY_SHOWINGS_ACTION.RescheduleShowing:
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        rescheduling: true,
                        rescheduleShowingSuccess: false,
                        showingIdBeingUpdated: action.showingId.toString(),
                        errors: [],
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        rescheduling: false,
                        rescheduleShowingSuccess: true,
                        showingIdBeingUpdated: '',
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        rescheduling: false,
                        showingIdBeingUpdated: '',
                        errors: action.errors,
                    };
            }
        default: {
            return state;
        }

        case MY_SHOWINGS_ACTION.Updating: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        showingIdBeingUpdated: action.showingId.toString(),
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        showingIdBeingUpdated: '',
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        errors: action.errors,
                        showingIdBeingUpdated: '',
                    };
            }
        }

        case MY_SHOWINGS_ACTION.ClearErrors: {
            return {
                ...state,
                scheduleShowingErrors: [],
            };
        }
    }
}
