export default (theme: any) => {
    return {
        root: {
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 99999999,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
        blur: {
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentContainer: {
            borderRadius: 5,
            padding: 25,
            display: 'flex',
            flexDirection: 'column',
            width: 812,
            height: 375,
            backgroundColor: theme.palette.white,
        },
    };
};
