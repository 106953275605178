import React, { useState } from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import DropdownIcon from '../../images/dropDownIcon.svg';
import Typography from '../Typography';

interface DropdownSelectProps {
    width?: any;
    height?: any;
    topMargin?: any;
    bottomMargin?: any;
    typographyAlignment?: any;
    text: string;
    list: any[];
    selectOption: any;
    selectedOption: any;
    selecting: boolean;
    setSelecting: Function;
    unsetSelecting: Function;
}

const DropdownSelect = (props: DropdownSelectProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });
    const {
        text,
        list,
        typographyAlignment = {},
        selectOption,
        selectedOption,
        selecting,
        setSelecting,
        unsetSelecting,
    } = props;
    return selecting ? (
        <div style={{ position: 'relative' }}>
            <div className={styleSheet.droppedDropDownComponent} onClick={() => unsetSelecting()}>
                <Typography
                    color={theme.palette.lightGrey}
                    textStyle="b1"
                    style={{
                        fontSize: 18,
                        alignSelf: 'flex-end',
                        marginBottom: 7.5,
                        ...typographyAlignment,
                    }}
                >
                    {selectedOption ?? text}
                </Typography>
                <img className={styleSheet.dropdownIcon} src={DropdownIcon} />
            </div>
            <div className={styleSheet.menu}>
                {list?.map((item, index) => {
                    const includeBorder = index !== list.length - 1;
                    const borderStyles = includeBorder
                        ? {
                              borderBottom: `1px solid ${theme.palette.grey}`,
                          }
                        : {};
                    return (
                        <div
                            className={styleSheet.menuItem}
                            style={{ ...borderStyles }}
                            onClick={() => {
                                selectOption(item?.label);
                                unsetSelecting();
                            }}
                        >
                            <Typography
                                color={theme.palette.black}
                                textStyle="b2"
                                style={{ fontSize: 15 }}
                            >
                                {item?.label}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    ) : (
        <div
            className={styleSheet.dropDownComponent}
            style={list?.length ? {} : { cursor: 'auto' }}
            onClick={
                list?.length
                    ? () => {
                          setSelecting();
                      }
                    : () => {}
            }
        >
            <Typography
                color={selectedOption ? theme.palette.black : theme.palette.lightGrey}
                textStyle="b1"
                style={{
                    fontSize: 18,
                    alignSelf: 'flex-end',
                    marginBottom: 7.5,
                    ...typographyAlignment,
                }}
            >
                {selectedOption ?? text}
            </Typography>
            <img className={styleSheet.dropdownIcon} src={DropdownIcon} />
        </div>
    );
};

export default DropdownSelect;
