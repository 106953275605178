import { Listing } from '../../../utils/constants';
import _ from 'lodash';

export const searchByAddressOrMLSId = (listings: Listing[], searchValue: string) => {
    const searchedListings = listings.filter((listing: Listing) => {
        //if MLSId matches
        if (listing?.mlsId?.toString().includes(searchValue)) {
            return true;
        }
        //if address matches
        if (checkAddressMatch(listing, searchValue)) {
            return true;
        }
        return false;
    });

    return searchedListings;
};

const checkAddressMatch = (listing: Listing, searchValue: string) => {
    //helper function adapated from ../MyShowings//MyShowingsList/index.tsx
    //so that the search logic is consistent across pages

    var searchTokens = searchValue.split(' ').filter((tok: any) => tok !== '');
    //the function maps through the address and sees if the search value is contained at all
    function checkStreetMatch(tokens: any, searchToken: any) {
        var addressMatch = false;
        tokens.map((token: any) =>
            token.toLowerCase().includes(searchToken.toLowerCase()) ? (addressMatch = true) : null,
        );
        return addressMatch;
    }

    const totalTokens: number = searchTokens.length;

    var score: number = 0;
    searchTokens.map((searchToken: any) => {
        if (
            checkStreetMatch(listing?.address.streetName.split(' '), searchToken) ||
            listing?.address.city?.toLowerCase().includes(searchToken.toLowerCase()) ||
            listing?.address.streetName?.toLowerCase().includes(searchToken.toLowerCase()) ||
            listing?.address.postalCode?.toLowerCase().includes(searchToken.toLowerCase()) ||
            (listing?.address.streetNumberText?.toLowerCase().includes(searchToken.toLowerCase()) &&
                searchToken !== '')
        ) {
            score++;
        }
    });
    if (score === totalTokens) {
        return true;
    }

    return false;
};

export const getMetaData = (listing: Listing) => {
    //returns a listingCardMetaData object from a listing object
    const { address, property } = listing;
    return {
        address: {
            street: `${address.full}`,
            city: address.city,
            state: address.state,
            zip: address.postalCode,
        },
        price: listing.listPrice,
        baths: property?.bathrooms,
        beds: property?.bedrooms,
        sqft: property?.area,
        isConnectedListing: !!listing.agentListing,
    };
};

export const getUnverifiedLink = (listing: Listing) => {
    //returns the unverified link if the listing has one, returns null otherwise
    if (listing.agentListing?.approvalSettings?.unverified?.allowed) {
        return `${process.env.REACT_APP_UNVERIFIED_URL}${listing.agentListing?.approvalSettings?.unverified?.hiddenId}`;
    }

    return '';
};

// takes in an array of the user's listings and returns a sorted array
// sorted by unconnected/connected first and alphabetically second
export const sortFilteredListings = (listings: Listing[]) => {
    let sortedListings: Listing[] = [];

    // split listings by connected and unconnected
    const split = _.groupBy(listings, 'agentListing');

    // sort the listings by their street name, not taking listing numbers into account
    for (const list in split) {
        const sorted = _.orderBy(split[list], [
            (listing) => listing.address.full.replace(/[0-9]/g, '').toLowerCase(),
        ]);
        sortedListings = [...sortedListings, ...sorted];
    }

    return sortedListings;
};
