import React, { useState } from 'react';
import { YourPlan } from 'web-lib';
import { connect, useSelector } from 'react-redux';
import styles from './index.module.css';
import { ButtonV4 } from 'web-lib';
import { useHistory } from 'react-router';
import { getMyListings } from '../selector';
import UnconnectedIcon from '../../../../images/unconnectedIcon.svg';
import { getProfileData } from '../../Profile/selectors';

type UnconnectedListingsModalProps = {};

const UnconnectedListingsModal = ({}: UnconnectedListingsModalProps) => {
    const myListings = useSelector(getMyListings);
    const agent = useSelector(getProfileData);

    let unconnectedListings = [];
    myListings?.map((listing: any) => {
        if (!listing?.agentListing) {
            unconnectedListings.push(listing);
        }
    });

    // We use this to see if they have had a session in the past hour, and if so then don't pop the modal
    const timeSinceLastSession = new Date().getTime() - agent?.lastSession.getTime();

    const history = useHistory();
    const [visible, setVisible] = useState(true);
    return unconnectedListings?.length && visible && timeSinceLastSession >= 3600000 ? (
        <div className={styles.modalRoot}>
            <div className={styles.iconContainer}>
                <img src={UnconnectedIcon} className={styles.iconStyles} />
            </div>
            <div className={styles.textContainer}>
                <span className={styles.text}>
                    You have {unconnectedListings?.length}{' '}
                    {unconnectedListings?.length > 1 ? 'listings' : 'listing'} that needs
                    configuration.
                </span>
                <span className={styles.smallText}>
                    A listing must be fully configured before buyer agents can schedule appointments
                    through Showingly.
                </span>
            </div>
            <button
                className={styles.buttonStyle}
                onClick={() => {
                    history.push('/myListings');
                    setVisible(false);
                }}
            >
                View My Listings
            </button>
            <span onClick={() => setVisible(false)} className={styles.redText}>
                Continue without connecting.
            </span>
        </div>
    ) : null;
};

export default UnconnectedListingsModal;
