import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    GET_PROFILE_DATA_REQUESTED,
    getProfileDataSucceeded,
    getProfileDataFailed,
    UPDATE_PROFILE_DATA_REQUESTED,
    updateProfileDataRequested,
    updateProfileDataSucceeded,
    updateProfileDataFailed,
    PROFILE_ACTIONS,
    setShowinglyPlusStatus,
    setBrokerage,
    searchBrokerageSucceeded,
    searchBrokerageFailed,
    addBrokerageToAgentFailed,
    addBrokerageToAgentSucceeded,
    updateProfilePhotoSucceeded,
    updateProfilePhotoFailed,
    GET_PROFILE_DATA_SUCCEEDED,
    updateProfilePhoneNumberRequested,
    updateProfilePhoneNumberSucceeded,
    updateProfilePhoneNumberFailed,
    addCardSucceeded,
    setStripeActionStatus,
    addCardAndSubscribeFailed,
    subscribeSucceeded,
    setSubscribedPrice,
    fetchPricingRequested,
    fetchPricingSucceeded,
    fetchPricingFailed,
    removePaymentMethodsRequested,
    removePaymentMethodsSucceeded,
    removePaymentMethodsFailed,
    getPaymentMethodsFailed,
    getPaymentMethodsRequested,
    getPaymentMethodsSucceeded,
    addCardFailed,
    cancelSubscriptionFailed,
    cancelSubscriptionRequested,
    cancelSubscriptionSucceeded,
    verifyPhoneNumberRequested,
    verifyPhoneNumberSucceeded,
    verifyPhoneNumberFailed,
    linkAgentRequested,
    linkAgentFailed,
    linkAgentSucceeded,
    LINKED_LICENSE_ACTIONS,
    sendLinkedLicenseVerificationCodeRequested,
    sendLinkedLicenseVerificationCodeSucceeded,
    sendLinkedLicenseVerificationCodeFailed,
    verifyLinkedLicenseCodeRequested,
    verifyLinkedLicenseCodeSucceeded,
    verifyLinkedLicenseCodeFailed,
    getMarketSucceeded,
    getMarketFailed,
    fetchAgentDocumentsRequested,
    fetchAgentDocumentsSucceeded,
    fetchAgentDocumentsFailed,
    updateLinkLicenses,
    fetchMyBrokeragesSucceeded,
    fetchMyBrokeragesFailed,
    fetchMyBrokeragesRequested,
    fetchSubscriptionPaymentMethodSucceeded,
    fetchSubscriptionPaymentMethodFailed,
    fetchBankAccountsSucceeded,
    fetchBankAccountsFailed,
    setStripeDashboardLink,
    setStripeOAuthLink,
    addCardAndChangePaymentFailed,
    changePaymentFailed,
    changePaymentSucceeded,
    addCardAndChangePaymentSucceeded,
    fetchSubscriptionPaymentMethodRequested,
    updateNotificationsFailed,
    updateNotificationsSucceeded,
    setIsDelinquent,
    payStripeInvoiceSucceeded,
    payStripeInvoiceFailed,
    payStripeInvoiceRequested,
    getProfileDataRequested,
    downgradeRequested,
    downgradeFailed,
    downgradeSucceeded,
} from './actions';
import { getStitchUser } from '../../../utils';
import {
    getAgentBrokerage,
    getAgentRecord,
    findRecord,
    upsertAgentProfile,
    upsertAgentSocial,
    callStitchFunction,
    createAgentSubscription,
    addAgentCard,
    getStripeSubscription,
    getAgentUserPaymentMethods,
    getUserInvoiceHistory,
    getStripePrice,
    removePaymentMethodById,
    upsertAgentRecord,
    getSubscriptionDefaultPaymentMethod,
    getStripeAccountBankInfo,
    connectWithStripeDashboard,
    connectWithStripeExpress,
    updateSubscriptionDefaultPaymentMethod,
    requestPhoneVerification,
    requestEmailVerification,
    runQuery,
    findRecords,
    entrySearchForAgents,
} from '../../../store/api/sagas';
import {
    STATUS,
    MARKETS_COLLECTION_NAME,
    AGENTS,
    USER_TYPE_AGENT,
    AGENTS_SOCIAL_CONNECTIONS,
    SUBSCRIPTION_SETTINGS,
} from '../../../store/api/constants';
import { getAgentObject } from '../../auth/selectors';
import { disableAuthLoading, initializationDone, storeAgentRecord } from '../../auth/actions';
import { getCustomerStripeId, getIsDelinquent, getProfileData, getIsSubscribed } from './selectors';
import { DateTime } from 'luxon';
import { getOrdinalNum } from '../../../utils/common/dateTimeUtils';
import {
    getDateSuffix,
    parseStitchServiceError,
    scrubAndFormatPhoneNumber,
} from '../../../utils/common';
import { isEmpty } from 'lodash';
import { getSelectedBrokerage } from '../Brokerage/selectors';

export const imageToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export function* getAgentProfile({ requestFromCustomAuth }: any): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const profileData = yield call(getAgentRecord, user);
        if (profileData) {
            // if the user is part of showingly plus, save that to state
            if (profileData.isSubscribed) {
                yield put(setShowinglyPlusStatus(true));
            }

            // if the agent has a brokerage, lets fetch the full brokerage data
            let invoices;
            let payment;

            const { subscription } = profileData;

            const marketName = profileData?.markets[0]?.marketName; // WIll be handled later, for now get pricing of preferred market.
            if (marketName) {
                const query = { name: marketName };
                const market = yield call(findRecord, MARKETS_COLLECTION_NAME, query);
                if (market?.agentSubscriptionSetting) {
                    const { agentSubscriptionSetting } = market;
                    const agentSubSetting = yield call(findRecord, SUBSCRIPTION_SETTINGS, {
                        _id: agentSubscriptionSetting,
                    });

                    const {
                        prices: { professional, executive },
                    } = agentSubSetting;

                    const proPrice = yield call(getStripePrice, professional);
                    const execPrice = yield call(getStripePrice, executive);
                    yield put(fetchPricingSucceeded(proPrice, execPrice));
                    const subPriceId = subscription?.priceId;
                    if (subPriceId === proPrice?.id) {
                        subscription.plan = 'Professional';
                    } else if (subPriceId === execPrice?.id) {
                        subscription.plan = 'Executive';
                    }
                } else {
                    yield put(fetchPricingFailed(`No prices found for a ${marketName} brokerage.`));
                }
            }

            invoices = yield call(getUserInvoiceHistory, user.id);
            payment = yield call(getAgentUserPaymentMethods);
            if (subscription?.status && subscription?.status === 'preCancel') {
                const { cancelDate = null, priceId = null } = subscription;
                const cancelDateTime = DateTime.fromJSDate(cancelDate);
                const cancelDateString = `${cancelDateTime.toFormat('MMMM')} ${
                    cancelDateTime.day
                }${getDateSuffix(cancelDateTime.day)}`;
                profileData.subscription.cancelDateString = cancelDateString;
                yield put(setSubscribedPrice(priceId));
            } else if (subscription?.status && subscription?.status !== 'cancelled') {
                const stripeSub = yield call(getStripeSubscription, subscription.id);
                if (subscription.status === 'delinquent') {
                    yield put(setIsDelinquent(true));
                }
                const currentPriceId = stripeSub?.plan?.id || null;

                yield put(setSubscribedPrice(currentPriceId));
            }

            // not all brokerage data exists on agent object, so we need to fetch all brokerage data
            let agentsBrokerage;
            try {
                agentsBrokerage = yield call(
                    getAgentBrokerage,
                    profileData?.markets[0]?.mlsName,
                    profileData?.markets[0]?.brokerageId,
                );
                yield put(setBrokerage(agentsBrokerage));
            } catch (err) {
                yield put(
                    getProfileDataFailed([
                        'There was an error fetching your brokerage data. Code: 0082',
                    ]),
                );
                // }

                // if the brokerage brokerage is subscribed to showingly plus AND the user has plus on their record
                // we want to delete the plus off of the agent record
            }
            if (!profileData?.about) {
                profileData.about = '';
            }
            if (!profileData.marketingPlan) {
                profileData.marketingPlan = '';
            }
            const formattedMarkets = yield call(
                callStitchFunction,
                'formatUserMarkets',
                AGENTS,
                profileData._id,
            );

            yield put(
                getProfileDataSucceeded(
                    profileData,
                    requestFromCustomAuth,
                    payment,
                    invoices,
                    formattedMarkets,
                ),
            );
        } else {
            const errMsg: any = 'Could not fetch data, please try again';
            yield put(getProfileDataFailed(errMsg));
        }
    } catch (err) {
        yield put(
            getProfileDataFailed([
                'There was an error fetching your profile data. Code: 0081' + err,
            ]),
        );
    }
}

function* getPaymentMethods({}: any): Generator<any, any, any> {
    try {
        const payments = yield call(getAgentUserPaymentMethods);
        if (payments) {
            yield put(getPaymentMethodsSucceeded(payments));
        } else {
            getPaymentMethodsFailed('Could not fetch data, please try again');
        }
    } catch (error) {
        yield put(getPaymentMethodsFailed('There was error fetching your payment data'));
    }
}

function* getSubscription(): Generator<any, any, any> {
    const user = yield call(getStitchUser);
    const profileData = yield call(getAgentRecord, user);
    if (profileData) {
        // if the user is part of showingly plus, save that to state
        if (profileData.isSubscribed) {
            yield put(setShowinglyPlusStatus(true));
        }
        const { subscription } = profileData;

        if (subscription?.status && subscription?.status !== 'cancelled') {
            const stripeSub = yield call(getStripeSubscription, subscription.id);
            const currentPriceId = stripeSub?.plan?.id || null;

            yield put(setSubscribedPrice(currentPriceId));
        }
    }
}

function* getAgentProfileSuccess({ requestFromCustomAuth }: any): Generator<any, any, any> {
    if (requestFromCustomAuth) {
        yield put(disableAuthLoading());
        yield put(initializationDone(true));
    }
}

export function* updateAgentProfile({
    about,
}: ReturnType<typeof updateProfileDataRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const setData = { about };
        const data = yield call(upsertAgentProfile, user, setData);
        const profileData = yield call(getAgentRecord, user);
        if (profileData) {
            yield put(updateProfileDataSucceeded(profileData));
        } else {
            const errMsg: any = 'Could not fetch data, please try again';
            yield put(updateProfileDataFailed(errMsg));
        }
    } catch (error: any) {
        yield put(updateProfileDataFailed(error));
    }
}

export function* fetchBrokerages({ marketName, searchText }: any): Generator<any, any, any> {
    try {
        let brokerages = [];

        if (searchText.length < 1) {
            return;
        }

        brokerages = yield call(callStitchFunction, 'textSearchBrokerage', searchText, marketName);

        if (brokerages) {
            const brokerageProjection = brokerages.map(({ _id, image, name }: any) => ({
                _id,
                image,
                name,
            }));
            yield put(searchBrokerageSucceeded(brokerageProjection));
        } else {
            yield put(searchBrokerageFailed(['Failed to fetch brokerages. Code: 0083']));
        }
    } catch (error) {
        yield put(searchBrokerageFailed(['Failed to fetch brokerages. Code: 0084']));
    }
}

export function* addAgentToBrokerage({ brokerageId, agentInfo }: any): Generator<any, any, any> {
    try {
        let brokerageInfo;
        try {
            brokerageInfo = yield call(findRecord, 'brokerages', { _id: brokerageId });
        } catch (err) {
            yield put(addBrokerageToAgentFailed(['Error searching for brokerage. Code: 0085']));
            return;
        }

        if (brokerageInfo) {
            try {
                const res = yield call(
                    callStitchFunction,
                    'addBrokerageAgents',
                    [agentInfo],
                    brokerageId,
                );
                const { agentsToAdd } = res;

                if (agentsToAdd) {
                    yield put(addBrokerageToAgentSucceeded());
                    yield call(getAgentProfile, false);
                } else {
                    yield put(
                        addBrokerageToAgentFailed([
                            'There was an error adding you to this brokerage. Code: 0089',
                        ]),
                    );
                }
            } catch (err) {
                yield put(
                    addBrokerageToAgentFailed(['Error adding agent to a brokerage. Code: 0088']),
                );
            }
        } else {
            yield put(addBrokerageToAgentFailed(['No brokerage found. Code: 0086']));
            return;
        }
    } catch (err) {
        yield put(addBrokerageToAgentFailed(['Error adding agent to a brokerage. Code: 0087']));
    }
}

export function* uploadProfilePhoto({ photo }: any): Generator<any, any, any> {
    try {
        if (photo) {
            let base64Image;
            let newPhotoData;
            try {
                base64Image = yield imageToBase64(photo);
            } catch (err) {
                yield put(updateProfilePhotoFailed(['Error uploading image file']));
            }

            try {
                newPhotoData = yield call(
                    callStitchFunction,
                    'storePhoto',
                    'agent',
                    base64Image.split(',')[1], // removes uneeded data type that is created in front of the base64 string
                    `profilePicture`,
                    photo.type,
                );
            } catch (err) {
                yield put(updateProfilePhotoFailed(['Error uploading file']));
            }
            try {
                const photoBucket = process.env.REACT_APP_PHOTO_BUCKET_NAME;
                const photoUrl = `https://${photoBucket}.s3.amazonaws.com/`;
                photo = {
                    path: `${newPhotoData?.key}`,
                    as: 'profilePicture',
                    uri: `${photoUrl}${newPhotoData?.key}`,
                };
                const user = yield call(getStitchUser);
                const agent = yield call(getAgentRecord, user);
                const success = yield call(upsertAgentProfile, user, { profilePhotoUpload: photo });

                // ensure that we save the image inside the agentSocialCollection as well
                const successSocial = yield call(upsertAgentSocial, agent._id, {
                    profilePhotoUpload: photo,
                });

                if (success && successSocial) {
                    yield put(updateProfilePhotoSucceeded(photo.uri));
                } else {
                    yield put(updateProfilePhotoFailed(['Could not upload profile picture']));
                }
            } catch (err) {
                yield put(updateProfilePhotoFailed([err]));
            }
        }
    } catch (err) {
        yield put(updateProfilePhotoFailed([err]));
    }
}

export function* updateAgentPhoneNumber({
    newPhoneNumber,
}: ReturnType<typeof updateProfilePhoneNumberRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const isFormatted = newPhoneNumber.includes('+1');
        const setData = isFormatted
            ? { phoneNumber: newPhoneNumber }
            : { phoneNumber: '+1' + newPhoneNumber };
        const data = yield call(upsertAgentProfile, user, setData);
        if (data) {
            yield put(updateProfilePhoneNumberSucceeded(setData.phoneNumber));
        } else {
            const errMsg: any = 'Could not fetch data, please try again';
            yield put(updateProfilePhoneNumberFailed(errMsg));
        }
    } catch (error) {
        yield put(updateProfilePhoneNumberFailed(error));
    }
}

export function* addCard({ paymentMethodId, name, zip }: any): Generator<any, any, any> {
    try {
        const res = yield call(addAgentCard, paymentMethodId, name, zip);
        const parsedRes = JSON.parse(res?.Payload.buffer);
        if (parsedRes?.errorMessage?.length) {
            yield put(addCardFailed(parsedRes.errorMessage));
        } else {
            yield put(getPaymentMethodsRequested());
            yield put(setStripeActionStatus('success'));
            yield put(addCardSucceeded());
        }
    } catch (error) {}
}

export function* addCardAndSubscribe({
    paymentMethodId,
    priceId,
    name,
    zip,
}: any): Generator<any, any, any> {
    try {
        const res = yield call(addAgentCard, paymentMethodId, name, zip);
        const parsedRes = JSON.parse(res?.Payload);
        if (parsedRes?.errorMessage?.length) {
            yield put(addCardAndSubscribeFailed(parsedRes.errorMessage));
        } else {
            const user = yield select(getStitchUser);
            const profileData = yield call(getAgentRecord, user);
            const { customerStripeId } = profileData;
            let customerId: any = 'Placeholder non-existant customer Id';
            if (customerStripeId) {
                customerId = customerStripeId;
            }

            const stripeSubscriptionDetails = {
                customerId,
                priceId,
                paymentMethodId,
            };

            const isAlreadySubscribed = yield select(getIsSubscribed);
            const agentDocument = yield select(getProfileData);

            if (isAlreadySubscribed && agentDocument?.subscription?.status === 'active') {
                const alreadySubscribedId = agentDocument?.subscription?.id;
                const changeResponse = yield call(callStitchFunction, 'changeSubscriptionPrice', {
                    subscriptionId: alreadySubscribedId,
                    newPriceId: priceId,
                });
                const parsed = JSON.parse(changeResponse?.Payload);
                if (parsed?.id === alreadySubscribedId) {
                    const setDetails = {
                        subscription: {
                            status: 'active',
                            id: alreadySubscribedId,
                            priceId: parsed?.plan?.id,
                            productId: parsed?.plan?.product,
                            plan: parsed?.plan?.nickname,
                            paymentMethodId,
                            subscribeDate: parsed?.created
                                ? new Date(parsed?.created * 1000)
                                : null,
                            delinquentDate: null,
                            cancelDate: null,
                        },
                    };

                    const updateAgentSuccessful = yield call(upsertAgentRecord, user, setDetails);

                    if (updateAgentSuccessful) {
                        const newSubscription = {
                            status: 'active',
                            id: parsed?.id,
                            priceId: parsed?.plan?.id,
                            productId: parsed?.plan?.product,
                            plan: parsed?.plan?.nickname,
                            paymentMethodId,
                            subscribeDate: parsed?.created
                                ? new Date(parsed?.created * 1000)
                                : null,
                            delinquentDate: null,
                            cancelDate: null,
                        };
                        const currentPriceId = parsed?.plan?.id || null;
                        yield put(setSubscribedPrice(currentPriceId));
                        yield put(setStripeActionStatus('success'));
                        yield put(subscribeSucceeded(newSubscription));
                    } else {
                        yield put(
                            addCardAndSubscribeFailed(
                                parsed?.errorMessage ||
                                    'There was an issue processing your request. Please try again or contact support for more information.',
                            ),
                        );
                    }
                }
            } else {
                const response = yield call(createAgentSubscription, stripeSubscriptionDetails);

                const subscription = JSON.parse(response?.Payload);
                const subscriptionId = subscription?.id;

                //Register the user via email and password

                if (subscription?.errorMessage?.length) {
                    yield put(addCardAndSubscribeFailed(subscription.errorMessage));
                } else if (subscriptionId) {
                    const setDetails = {
                        subscription: {
                            status: 'active',
                            id: subscriptionId,
                            priceId: subscription?.plan?.id,
                            productId: subscription?.plan?.product,
                            plan: subscription?.plan?.nickname,
                            paymentMethodId,
                            subscribeDate: subscription?.created
                                ? new Date(subscription?.created * 1000)
                                : null,
                            delinquentDate: null,
                            cancelDate: null,
                        },
                    };
                    const updateAgentSuccessful = yield call(upsertAgentRecord, user, setDetails);

                    if (updateAgentSuccessful) {
                        const newSubscription = {
                            status: 'active',
                            id: subscriptionId,
                            priceId: subscription?.plan?.id,
                            productId: subscription?.plan?.product,
                            plan: subscription?.plan?.nickname,
                            paymentMethodId,
                            subscribeDate: subscription?.created
                                ? new Date(subscription?.created * 1000)
                                : null,
                            delinquentDate: null,
                            cancelDate: null,
                        };
                        const currentPriceId = subscription?.plan?.id || null;
                        yield put(setSubscribedPrice(currentPriceId));
                        yield put(setStripeActionStatus('success'));
                        yield put(subscribeSucceeded(newSubscription));
                        yield put(getPaymentMethodsRequested());
                    }
                } else {
                    yield put(
                        addCardAndSubscribeFailed(
                            'An Error occurred processing your payment, please contact support.',
                        ),
                    );
                }
            }
        }
    } catch (error) {
        addCardAndSubscribeFailed(
            'An Error occurred processing your payment, please contact support.',
        );
    }
}

export function* subscribe({ paymentMethodId, priceId }: any): Generator<any, any, any> {
    //TODO PAYMENT: in changeSubscriptionPrice, actually update paymentMethod if new one is passed
    try {
        const user = yield select(getStitchUser);
        const customerStripeId = yield select(getCustomerStripeId);
        const isAlreadySubscribed = yield select(getIsSubscribed);
        const agentDocument = yield select(getProfileData);

        let customerId: any = 'Placeholder non-existant customer Id';
        if (customerStripeId) {
            customerId = customerStripeId;
        }

        const stripeSubscriptionDetails = {
            customerId,
            priceId,
            paymentMethodId,
        };

        if (isAlreadySubscribed && agentDocument?.subscription?.status === 'active') {
            const alreadySubscribedId = agentDocument.subscription.id;
            const changeResponse = yield call(callStitchFunction, 'changeSubscriptionPrice', {
                subscriptionId: alreadySubscribedId,
                newPriceId: priceId,
            });
            const parsed = JSON.parse(changeResponse?.Payload);
            if (parsed?.id === alreadySubscribedId) {
                const setDetails = {
                    subscription: {
                        status: 'active',
                        id: alreadySubscribedId,
                        priceId: parsed?.plan?.id,
                        productId: parsed?.plan?.product,
                        plan: parsed?.plan?.nickname,
                        paymentMethodId,
                        subscribeDate: parsed?.created ? new Date(parsed?.created * 1000) : null,
                        delinquentDate: null,
                        cancelDate: null,
                    },
                };

                const updateAgentSuccessful = yield call(upsertAgentRecord, user, setDetails);

                if (updateAgentSuccessful) {
                    const newSubscription = {
                        status: 'active',
                        id: parsed?.id,
                        priceId: parsed?.plan?.id,
                        productId: parsed?.plan?.product,
                        plan: parsed?.plan?.nickname,
                        paymentMethodId,
                        subscribeDate: parsed?.created ? new Date(parsed?.created * 1000) : null,
                        delinquentDate: null,
                        cancelDate: null,
                    };
                    const currentPriceId = parsed?.plan?.id || null;
                    yield put(setSubscribedPrice(currentPriceId));
                    yield put(setStripeActionStatus('success'));
                    yield put(subscribeSucceeded(newSubscription));
                } else {
                    yield put(
                        addCardAndSubscribeFailed(
                            parsed?.errorMessage ||
                                'There was an issue processing your request. Please try again or contact support for more information.',
                        ),
                    );
                }
            }
        } else {
            const response = yield call(createAgentSubscription, stripeSubscriptionDetails);
            const subscription = JSON.parse(response?.Payload);
            const subscriptionId = subscription?.id;

            //Register the user via email and password
            if (subscription?.errorMessage?.length) {
                yield put(addCardAndSubscribeFailed(subscription.errorMessage));
            } else if (subscriptionId) {
                const setDetails = {
                    subscription: {
                        status: 'active',
                        id: subscriptionId,
                        priceId: subscription?.plan?.id,
                        productId: subscription?.plan?.product,
                        plan: subscription?.plan?.nickname,
                        paymentMethodId,
                        subscribeDate: subscription?.created
                            ? new Date(subscription?.created * 1000)
                            : null,
                        delinquentDate: null,
                        cancelDate: null,
                    },
                };

                const updateAgentSuccessful = yield call(upsertAgentRecord, user, setDetails);

                if (updateAgentSuccessful) {
                    const newSubscription = {
                        status: 'active',
                        id: subscriptionId,
                        priceId: subscription?.plan?.id,
                        productId: subscription?.plan?.product,
                        plan: subscription?.plan?.nickname,
                        paymentMethodId,
                        subscribeDate: subscription?.created
                            ? new Date(subscription?.created * 1000)
                            : null,
                        delinquentDate: null,
                        cancelDate: null,
                    };
                    const currentPriceId = subscription?.plan?.id || null;
                    yield put(setSubscribedPrice(currentPriceId));
                    yield put(setStripeActionStatus('success'));
                    yield put(subscribeSucceeded(newSubscription));
                }
            } else {
                yield put(
                    addCardAndSubscribeFailed(
                        'An Error occurred processing your payment, please contact support.',
                    ),
                );
            }
        }
    } catch (error) {
        addCardAndSubscribeFailed(
            'An Error occurred processing your payment, please contact support.',
        );
    }
}

export function* fetchSubscriptionPricing({
    marketName,
}: ReturnType<typeof fetchPricingRequested>): Generator<any, any, any> {
    const query = { name: marketName };
    const market = yield call(findRecord, MARKETS_COLLECTION_NAME, query);
    try {
        if (market?.agentSubscriptionSetting) {
            const { agentSubscriptionSetting } = market;
            const agentSubSetting = yield call(findRecord, SUBSCRIPTION_SETTINGS, {
                _id: agentSubscriptionSetting,
            });

            const {
                prices: { professional, executive },
            } = agentSubSetting;

            const proPrice = yield call(getStripePrice, professional);
            const execPrice = yield call(getStripePrice, executive);
            yield put(fetchPricingSucceeded(proPrice, execPrice));
        } else {
            yield put(fetchPricingFailed(`No prices found for a ${marketName} brokerage.`));
        }
    } catch (error) {
        yield put(
            fetchPricingFailed(
                'An error occurred while fetching the subscription price. Code 0149',
            ),
        );
    }
}

export function* removePayment({
    paymentMethod,
}: ReturnType<typeof removePaymentMethodsRequested>): Generator<any, any, any> {
    try {
        const userCollection = AGENTS;
        if (paymentMethod) {
            const paymentMethodId = paymentMethod.id;

            const removedPaymentMethod = yield call(
                removePaymentMethodById,
                paymentMethodId,
                userCollection,
            );

            if (removedPaymentMethod) {
                const paymentMethods = yield call(getAgentUserPaymentMethods);

                //Let's provide the illusion of profile data updating right away by fetching the new array of payment methods and displaying that over the old array
                yield put(removePaymentMethodsSucceeded(paymentMethods));
            } else if (!removedPaymentMethod) {
                yield put(
                    removePaymentMethodsFailed([
                        'There was an error removing the payment method. Error Code: 0151-1',
                    ]),
                );
            }
        } else if (!paymentMethod) {
            yield put(
                removePaymentMethodsFailed(['No payment method was passed. Error Code: 0151-2']),
            );
        }
    } catch (error) {
        yield put(
            removePaymentMethodsFailed(['Failed to remove payment method. Error Code: 0151-3']),
        );
    }
}

export function* cancelSubscription({
    subscriptionId,
}: ReturnType<typeof cancelSubscriptionRequested>): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const payload = { subscriptionId };

        const response = yield call(callStitchFunction, 'cancelStripeSubscription', payload);

        if (response.errorMessage) {
            yield put(cancelSubscriptionFailed([response.errorMessage, 'Error Code: 0153-2']));
        }
        // if the process succeeds, delete the subscription obj off of the brokerage document
        const parsed = JSON.parse(response.Payload);
        if (parsed.id === subscriptionId) {
            let endDate;
            const endMonth = DateTime.fromMillis(parsed.current_period_end * 1000).toFormat('MMMM');
            const endDay = getOrdinalNum(
                DateTime.fromMillis(parsed.current_period_end * 1000).get('day'),
            );
            const cancelDate = new Date(parsed.current_period_end * 1000);
            yield call(upsertAgentRecord, user, {
                'subscription.status': 'preCancel',
                'subscription.cancelDate': cancelDate,
            });

            endDate = `${endMonth} ${endDay}`;
            yield put(cancelSubscriptionSucceeded(endDate));
            yield put(getProfileDataRequested());
        }
    } catch (error) {
        yield put(cancelSubscriptionFailed(['Error Code: 0153-1']));
    }
}

export function* downgrade({
    subscriptionId,
    newPriceId,
}: ReturnType<typeof downgradeRequested>): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const payload = { subscriptionId, newPriceId };

        const response = yield call(callStitchFunction, 'changeSubscriptionPrice', payload);
        const parsedResponse = JSON.parse(response?.Payload);
        if (parsedResponse.errorMessage) {
            yield put(downgradeFailed([parsedResponse.errorMessage, 'Error Code: 0153-2']));
        }
        if (parsedResponse.id === subscriptionId) {
            yield call(upsertAgentRecord, user, {
                'subscription.priceId': newPriceId,
            });
            yield put(getProfileDataRequested());
        }
    } catch (error) {
        yield put(cancelSubscriptionFailed(['Error Code: 0153-1']));
    }
}

/**
 * Call handler for verifying phone number change
 * @param {string|number} code The code that was sent via SMS to the user's phone.
 * @param {string|number} phoneNumber The user's requested change of phone number
 * @returns {IterableIterator<object>}
 */
export function* verifyPhoneNumber({
    code,
    phoneNumber,
}: ReturnType<typeof verifyPhoneNumberRequested>): Generator<any, any, any> {
    const user = yield call(getStitchUser);
    try {
        const check = yield call(
            callStitchFunction,
            'confirmPhoneVerification',
            phoneNumber,
            code,
            USER_TYPE_AGENT,
            true,
            user.id,
            true,
        );
        if (check) {
            yield put(verifyPhoneNumberSucceeded());
            yield put(updateProfilePhoneNumberRequested(phoneNumber));
        } else {
            yield put(
                verifyPhoneNumberFailed([
                    'There was an error processing your account',
                    'Code: 0100-2',
                ]),
            );
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(
            verifyPhoneNumberFailed([
                'There was an error processing your account',
                error,
                'Code: 0100-1',
            ]),
        );
    }
}

export function* linkAgentAccount({
    linkingAgentObjId,
}: ReturnType<typeof linkAgentRequested>): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const profileData = yield call(getAgentRecord, user);
        const agentRecord = yield call(
            callStitchFunction,
            'validateSystemUser',
            'sysLinkAgentLicense',
            [linkingAgentObjId],
        );

        if (!agentRecord) {
            yield put(linkAgentFailed());
        } else {
            // Once the link is successful let's add that market to the displayed markets to provide instant feedback
            const formattedMarkets = yield call(
                callStitchFunction,
                'formatUserMarkets',
                AGENTS,
                profileData._id,
            );
            yield put(linkAgentSucceeded());
            yield put(storeAgentRecord(agentRecord));
            yield put(updateLinkLicenses(formattedMarkets));
        }

        //
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(
            linkAgentFailed([
                'There was an issue linking your agent accounts',
                error,
                'Code: 0167-01',
            ]),
        );
    }
}

export function* fetchMyBrokerage({
    passedAgentId,
}: ReturnType<typeof fetchMyBrokeragesRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);

        const agentData = yield call(getAgentRecord, user);

        const selectedBrokerage = yield select(getSelectedBrokerage);

        const brokerages = yield call(
            callStitchFunction,
            'runAggregationBrokerages',
            'getAgentBrokerages',
            {
                agentObjectId: agentData?._id,
            },
        );
        yield put(fetchMyBrokeragesSucceeded(brokerages));

        if (isEmpty(selectedBrokerage) && brokerages?.length) {
            // yield put(updateSelectedBrokerage(brokerages[0]));
        }
        // this case is only hit if the agent clicks on another agents social profile from the agent roster screen.
        // The brokerages need to be refetched, but the selected brokerage needs to stay at the front of the array
        if (
            !isEmpty(selectedBrokerage) &&
            JSON.stringify(brokerages[0]?._id) !== JSON.stringify(selectedBrokerage?._id)
        ) {
            brokerages?.map((brokerage: any, index: any) => {
                if (JSON.stringify(brokerage?._id) === JSON.stringify(selectedBrokerage?._id)) {
                    brokerages?.splice(index, 1);
                }
            });
            yield put(fetchMyBrokeragesSucceeded([selectedBrokerage, ...brokerages]));
        }
    } catch (error) {
        const errorMsg = parseStitchServiceError(error);
        yield put(fetchMyBrokeragesFailed(errorMsg));
    }
}

/**
 * Scrub the phone number and request sms code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param phoneNumber
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* sendPhoneCode(
    onSuccessHandler: any,
    onFailureHandler: any,
    phoneNumber: string,
    isResend = false,
): Generator<any, any, any> {
    try {
        const parsedPhoneNumber = scrubAndFormatPhoneNumber(phoneNumber);
        const isSent = yield call(requestPhoneVerification, parsedPhoneNumber);
        if (isSent) {
            yield put(onSuccessHandler());
        } else {
            yield put(
                onFailureHandler(
                    'A verification code could not be sent. Please ensure that you are using a valid phone number. Error Code 0168-1.',
                ),
            );
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(onFailureHandler(errorMessage + '. Error Code 0168-2.'));
    }
}

/**
 * Request email code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param email
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* sendEmailCode(
    onSuccessHandler: any,
    onFailureHandler: any,
    email: string,
    isResend = false,
): Generator<any, any, any> {
    try {
        const isSent = yield call(requestEmailVerification, email);
        if (isSent) {
            yield put(onSuccessHandler());
        } else {
            yield put(
                onFailureHandler(
                    'A verification code could not be sent. Please ensure that you are using a valid email address. Error Code 0168-3.',
                ),
            );
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(onFailureHandler(errorMessage + '. Error Code 0168-4.'));
    }
}

function* sendVerificationCode({
    method,
    receiver,
    isResend,
}: ReturnType<typeof sendLinkedLicenseVerificationCodeRequested>) {
    try {
        // const agentUser = yield select(getAgentObject);
        if (method === 'phone') {
            yield call(
                sendPhoneCode,
                sendLinkedLicenseVerificationCodeSucceeded,
                sendLinkedLicenseVerificationCodeFailed,
                receiver,
                isResend,
            );
        } else if (method === 'email') {
            yield call(
                sendEmailCode,
                sendLinkedLicenseVerificationCodeSucceeded,
                sendLinkedLicenseVerificationCodeFailed,
                receiver,
                isResend,
            );
        }
    } catch (error) {
        yield put(sendLinkedLicenseVerificationCodeFailed(error + '. Error Code: 0169-1'));
    }
}

export function* fetchSubscriptionPaymentMethod(): Generator<any, any, any> {
    try {
        const subscriptionPaymentMethod = yield call(getSubscriptionDefaultPaymentMethod);

        const parsedSubcsriptionPaymentMethod = JSON.parse(subscriptionPaymentMethod?.Payload);
        yield put(fetchSubscriptionPaymentMethodSucceeded(parsedSubcsriptionPaymentMethod));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(fetchSubscriptionPaymentMethodFailed([message]));
    }
}

/**
 * Call common auth handler for verifying phone code
 * @param {string|number} phoneCode The code that was sent via SMS to the user's phone.
 * @returns {IterableIterator<object>}
 * @param {emailOrPhone} optional phone number or email, used when sending a verification isn't done through a pending user
 */
function* verifyCode({
    method,
    code,
    emailOrPhone,
}: ReturnType<typeof verifyLinkedLicenseCodeRequested>): Generator<any, any, any> {
    const agentUser = yield select(getAgentObject);
    const stitchUserId = agentUser?.stitchUserId;
    try {
        const check =
            method === 'phone'
                ? yield call(
                      callStitchFunction,
                      'confirmPhoneVerification',
                      emailOrPhone,
                      code,
                      USER_TYPE_AGENT,
                      false,
                      stitchUserId,
                      false,
                  )
                : yield call(
                      callStitchFunction,
                      'verifyEmail',
                      emailOrPhone,
                      code,
                      USER_TYPE_AGENT,
                      true,
                  );
        if (check) {
            yield put(verifyLinkedLicenseCodeSucceeded());
        } else {
            yield put(
                verifyLinkedLicenseCodeFailed(
                    'There was an error attempting to verify the inputted code. Please ensure the code has been inputted correctly. Error Code: 0170-1.',
                ),
            );
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(verifyLinkedLicenseCodeFailed(errorMessage + ' Error code: 0170-2.'));
    }
}

function* fetchMarkets(): Generator<any, any, any> {
    try {
        const markets = yield findRecords(MARKETS_COLLECTION_NAME, {});
        if (markets) {
            yield put(getMarketSucceeded(markets));
        } else {
            yield put(getMarketFailed('No Markets Found'));
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(getMarketFailed(errorMessage));
    }
}

/**
 * Atlas text search for agents within a specific market
 * @param {searchText} text that the user searched in the input box
 * @param {marketName} market name that the agent selected
 */
function* fetchAgents({
    searchText = '',
    marketName,
}: ReturnType<typeof fetchAgentDocumentsRequested>): Generator<any, any, any> {
    try {
        // NOTE: This variable is purely for making future development easier
        const allowHiddenSearch = true; // Made this a separate variable in case we want to change allow hidden in the future
        const projection: object = {
            _id: 1,
            stitchUserId: 1,
            firstName: 1,
            lastName: 1,
            profilePhotoUpload: 1,
            phoneNumber: 1,
            email: 1,
            markets: 1,
            status: 1,
            signUpCompleted: 1,
        };
        const potentialAgents = yield call(
            entrySearchForAgents,
            searchText,
            projection,
            marketName,
            allowHiddenSearch,
        );
        if (potentialAgents) {
            yield put(fetchAgentDocumentsSucceeded(potentialAgents));
        } else if (!potentialAgents) {
            yield put(
                fetchAgentDocumentsFailed('No agents were found for the inputted search values.'),
            );
        }
    } catch (errors) {
        yield put(fetchAgentDocumentsFailed(errors));
    }
}

function* fetchStripeAccountBankInfo(): Generator<any, any, any> {
    try {
        const bankAccountInfo = yield call(getStripeAccountBankInfo);
        const bankAccountInfoParsed = JSON.parse(bankAccountInfo?.Payload);
        const bankAccounts = bankAccountInfoParsed?.data || null;
        yield put(fetchBankAccountsSucceeded(bankAccounts));
    } catch (error) {
        yield put(fetchBankAccountsFailed());
    }
}

function* initiateStripeConnect(): Generator<any, any, any> {
    const stitchUser = yield select(getStitchUser);
    const stripeExpress = yield call(connectWithStripeExpress, stitchUser?.id);
    yield put(setStripeOAuthLink(stripeExpress.stripeOAuthLink));
}

function* requestStripeLink(): Generator<any, any, any> {
    const stitchUser = yield select(getStitchUser);
    const stripeDashboard = yield call(connectWithStripeDashboard, stitchUser?.id);
    if (stripeDashboard.url) {
        yield put(setStripeDashboardLink(stripeDashboard.url));
    } else {
        yield call(initiateStripeConnect);
    }
}

export function* addCardAndChangePayment({
    paymentMethodId,
    name,
    zip,
}: any): Generator<any, any, any> {
    try {
        const res = yield call(addAgentCard, paymentMethodId, name, zip);
        const parsedRes = JSON.parse(res?.Payload);
        if (parsedRes?.errorMessage?.length) {
            yield put(addCardAndChangePaymentFailed(parsedRes.errorMessage));
        } else {
            const user = yield select(getStitchUser);

            const updatePaymentResponse = yield call(
                updateSubscriptionDefaultPaymentMethod,
                paymentMethodId,
            );

            const parsedUpdatePaymentResponse = JSON.parse(updatePaymentResponse?.Payload);

            if (parsedUpdatePaymentResponse?.id) {
                const data = {
                    'subscription.paymentMethodId': paymentMethodId,
                };
                yield call(upsertAgentRecord, user, data);
                yield put(addCardAndChangePaymentSucceeded());
                yield put(fetchSubscriptionPaymentMethodRequested());
                const isDelinquent = yield select(getIsDelinquent);
                if (isDelinquent) {
                    yield put(payStripeInvoiceRequested());
                }
            } else {
                yield put(changePaymentFailed([parsedUpdatePaymentResponse?.errorMessage]));
            }
        }
    } catch (error) {
        addCardAndChangePaymentFailed(
            'An Error occurred processing your payment, please contact support.',
        );
    }
}

export function* changePayment({ paymentMethodId }: any): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const updatePaymentResponse = yield call(
            updateSubscriptionDefaultPaymentMethod,
            paymentMethodId,
        );

        const parsedUpdatePaymentResponse = JSON.parse(updatePaymentResponse?.Payload);
        if (parsedUpdatePaymentResponse?.id) {
            const data = {
                'subscription.paymentMethodId': paymentMethodId,
            };
            yield call(upsertAgentRecord, user, data);
            yield put(changePaymentSucceeded(parsedUpdatePaymentResponse));
            yield put(fetchSubscriptionPaymentMethodRequested());

            const isDelinquent = yield select(getIsDelinquent);
            if (isDelinquent) {
                yield put(payStripeInvoiceRequested());
            }
        } else {
            yield put(changePaymentFailed([parsedUpdatePaymentResponse?.errorMessage]));
        }
    } catch (error) {
        changePaymentFailed('An Error occurred processing your payment, please contact support.');
    }
}

export function* payStripeInvoice({}: any): Generator<any, any, any> {
    try {
        const stripeRes = yield call(callStitchFunction, 'payStripeInvoice');
        const parsedStripeRes = JSON.parse(stripeRes?.Payload);
        const paymentStatus = parsedStripeRes?.status;
        if (paymentStatus === 'paid') {
            yield put(payStripeInvoiceSucceeded());
        } else {
            yield put(payStripeInvoiceFailed());
        }
    } catch (error) {
        yield put(payStripeInvoiceFailed());
    }
}

export function* payCurrentBalance({}: any): Generator<any, any, any> {
    try {
        const pdat = yield select(getProfileData);
        const { subscription: subbo } = pdat;

        const stripeRes = yield call(callStitchFunction, 'updateStripeSubscription', subbo?.id, {
            billing_cycle_anchor: 'now',
        });

        const parsedStripeRes = JSON.parse(stripeRes?.Payload);
    } catch (error) {}
}

export function* updateNotifications({
    notiType,
    pathName,
    settingType,
    toValue,
}: any): Generator<any, any, any> {
    try {
        const agent = yield select(getProfileData);
        const agentSocialDocument = yield call(findRecord, AGENTS_SOCIAL_CONNECTIONS, {
            agentObjectId: agent._id,
        });
        const values = [notiType, pathName, settingType, toValue, agentSocialDocument];
        const notificationSettings = yield call(
            callStitchFunction,
            'validateSystemUser',
            'updateNotificationSettings',
            values,
        );
        yield put(updateNotificationsSucceeded(notificationSettings));
    } catch (error) {
        updateNotificationsFailed(
            'An Error occurred updating your settings, please contact support.',
        );
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(GET_PROFILE_DATA_REQUESTED, getAgentProfile),
        yield takeLatest(GET_PROFILE_DATA_SUCCEEDED, getAgentProfileSuccess),
        yield takeLatest(UPDATE_PROFILE_DATA_REQUESTED, updateAgentProfile),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.BrokerageSearch &&
                action.status === STATUS.Requested,
            fetchBrokerages,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.AddBrokerageToAgent &&
                action.status === STATUS.Requested,
            addAgentToBrokerage,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.ProfilePhotoUpdate &&
                action.status === STATUS.Requested,
            uploadProfilePhoto,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.UpdatePhoneNumber &&
                action.status === STATUS.Requested,
            updateAgentPhoneNumber,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.AddCard && action.status === STATUS.Requested,
            addCard,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.AddCardAndSubscribe &&
                action.status === STATUS.Requested,
            addCardAndSubscribe,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.Subscribe && action.status === STATUS.Requested,
            subscribe,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.FetchPricing && action.status === STATUS.Requested,
            fetchSubscriptionPricing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.RemovePayment && action.status === STATUS.Requested,
            removePayment,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.FetchPaymentMethods &&
                action.status === STATUS.Requested,
            getPaymentMethods,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.CancelSubscription &&
                action.status === STATUS.Requested,
            cancelSubscription,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.Downgrade && action.status === STATUS.Requested,
            downgrade,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.Verify && action.status === STATUS.Requested,
            verifyPhoneNumber,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.LinkAgentAccounts &&
                action.status === STATUS.Requested,
            linkAgentAccount,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === LINKED_LICENSE_ACTIONS.sendCode &&
                action.status === STATUS.Requested,
            sendVerificationCode,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === LINKED_LICENSE_ACTIONS.verifyCode &&
                action.status === STATUS.Requested,
            verifyCode,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === LINKED_LICENSE_ACTIONS.searchMarkets &&
                action.status === STATUS.Requested,
            fetchMarkets,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === LINKED_LICENSE_ACTIONS.searchAgents &&
                action.status === STATUS.Requested,
            fetchAgents,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.FetchMyBrokerages &&
                action.status === STATUS.Requested,
            fetchMyBrokerage,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.FetchSubscriptionPaymentMethod &&
                action.status === STATUS.Requested,
            fetchSubscriptionPaymentMethod,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.FetchBankAccounts &&
                action.status === STATUS.Requested,
            fetchStripeAccountBankInfo,
        ),
        yield takeLatest(
            (action: any) => action.type === PROFILE_ACTIONS.RequestStripeLink,
            requestStripeLink,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.AddCardAndChangePayment &&
                action.status === STATUS.Requested,
            addCardAndChangePayment,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.ChangePayment && action.status === STATUS.Requested,
            changePayment,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.UpdateNotifications &&
                action.status === STATUS.Requested,
            updateNotifications,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === PROFILE_ACTIONS.PayStripeInvoice &&
                action.status === STATUS.Requested,
            payStripeInvoice,
        ),
    ]);
}
