import { FETCH_NEWS_FAILED, FETCH_NEWS_REQUESTED, FETCH_NEWS_SUCCEEDED } from './actions';

export const initialState = {
    news: [],
    loading: false,
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case FETCH_NEWS_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case FETCH_NEWS_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                news: action.news,
            };
        }
        case FETCH_NEWS_FAILED: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            };
        }
        default:
            return state;
    }
}
