import * as authActions from './actions';
import { STATUS } from '../../utils/constants';

const { AUTH_ACTION, CLEAR_ERRORS, UPDATE_PENDING_USER } = authActions;

export const initialState = {
    // StitchUser object that's populated after successfully logging in
    stitchUser: null,

    loading: false,

    // The stitch client
    client: null,

    // Whether the app is in the process of booting up or not
    initializing: false,

    // stored record of the current logged in agent
    agent: {},

    // Identifies whether the connection is valid
    isConnected: true,

    loginInProgress: false,

    errors: [],

    isAuthenticated: false,

    loginRejected: false,

    // sign up
    markets: [],
    entryAgents: [],
    pendingUser: {
        firstName: null,
        lastName: null,
        email: null,
        pendingPhoneNumber: null,
        market: null,
    },
    verifyingCode: false,
    newUserVerified: false,
    creatingUser: false,

    // Showingly - SSO
    code: '',
    codeProviderName: '',
    retrievedToken: '',

    hasAuthLinkExpiredOrIsInvalid: false,
    isUnauthenticated: false,

    // Consumer Data
    consumerRecord: {},

    //Whether a link was sent to the phone or not
    appLinkSent: false,

    //Password reset email sent or not
    passResetEmailSent: false,

    isSendingPasswordResetEmail: false,

    // Market for agent authorization flow
    authMarket: null,

    // MLS for agent authorization flow
    authMls: null,

    // Agent data for agent authorization flow
    authAgent: null,

    // The user is currently resetting their password (not via reset email)
    passwordResetActive: false,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case authActions.STORE_AGENT_RECORD: {
            const { agent } = action;
            return {
                ...state,
                agent,
            };
        }
        case authActions.SET_PRACTICE_INFO: {
            const { authMarket, authMls } = action;
            return {
                ...state,
                authMarket,
                authMls,
            };
        }
        case authActions.SET_AGENT_INFO: {
            const { authAgent } = action;
            return {
                ...state,
                authAgent,
            };
        }
        case authActions.INITIALIZATION_DONE: {
            const { isAuthenticated } = action;
            return {
                ...state,
                loading: false,
                isAuthenticated,
            };
        }
        case authActions.INITIALIZATION: {
            const { initializing } = action;
            return {
                ...state,
                initializing,
                loading: true,
            };
        }
        case authActions.LOG_OUT_REQUESTED: // Intentional Fallthrough
        case authActions.AGENT_LOGIN_REQUESTED:
        case authActions.USER_LOGIN_REQUESTED: {
            return {
                ...state,
                loginInProgress: true,
                loginRejected: false,
            };
        }
        case authActions.LOG_OUT_REQUEST_FAILED: // Intentional Fallthrough
        case authActions.USER_LOGIN_REQUEST_FAILED: // Intentional Fallthrough
        case authActions.AGENT_LOGIN_FAILED: {
            const { errors } = action;
            return {
                ...state,
                errors,
                loginInProgress: false,
                loginRejected: true,
            };
        }
        case authActions.USER_LOGIN_REQUEST_SUCCEEDED: // Intentional Fallthrough
        case authActions.AGENT_LOGIN_SUCCEEDED: {
            const { client } = action;
            return {
                ...state,
                loginInProgress: false,
                isAuthenticated: true,
                client,
            };
        }
        case authActions.LOG_OUT_REQUEST_SUCCEEDED: {
            return {
                ...state,
                loginInProgress: false,
                isAuthenticated: false,
            };
        }
        case authActions.RESET_PASSWORD_REQUESTED: {
            return {
                ...state,
                passResetEmailSent: false,
                isSendingPasswordResetEmail: true,
            };
        }
        case authActions.RESET_PASSWORD_SUCCEEDED: {
            return {
                ...state,
                passResetEmailSent: true,
                isSendingPasswordResetEmail: false,
            };
        }
        case authActions.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                passResetEmailSent: false,
                isSendingPasswordResetEmail: false,
            };
        }

        case AUTH_ACTION.Market: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokeragesAndMarkets: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { markets } = action;
                    return {
                        ...state,
                        loadingBrokeragesAndMarkets: false,
                        markets,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loadingBrokeragesAndMarkets: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.Agent_Documents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        entryAgent: action.agent,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        entryAgents: action.entryAgents,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.Agent_Search: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        entryAgents: action.entryAgents,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.VerifyCode: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        verifyingCode: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        verifyingCode: false,
                        newUserVerified: true,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                        verifyingCode: false,
                    };
                }

                default:
                    return state;
            }
        }

        // Showingly - SSO
        case AUTH_ACTION.OIDC_RequestToken: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        code: action.code,
                        codeProviderName: action.codeProviderName,
                        loading: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        retrievedToken: action.retrievedToken,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.DisableAuthLoading: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: false,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.AuthLinkExpiredOrIsInvalid: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        hasAuthLinkExpiredOrIsInvalid: true,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.IsUnauthenticated: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        isUnauthenticated: true,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.Registration: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        creatingUser: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        creatingUser: false,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                        creatingUser: false,
                    };
                }

                default:
                    return state;
            }
        }

        case UPDATE_PENDING_USER: {
            const { pendingUser } = action;
            return { ...state, pendingUser };
        }

        case AUTH_ACTION.FetchConsumerRecord: {
            switch (status) {
                case STATUS.Succeeded:
                    return {
                        ...state,
                        consumerRecord: action.consumerRecord,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        errors: action.errors,
                    };
            }
        }

        case AUTH_ACTION.SendAppDownloadLink: {
            return {
                ...state,
                appLinkSent: true,
            };
        }

        case AUTH_ACTION.ResetAppLinkSent: {
            return {
                ...state,
                appLinkSent: false,
            };
        }

        case authActions.SET_PASSWORD_RESET_ACTIVE: {
            const { passwordResetActive } = action;
            return {
                ...state,
                passwordResetActive,
            };
        }

        case CLEAR_ERRORS: {
            return { ...state, errors: [] };
        }
        case AUTH_ACTION.ClearVerification: {
            return {
                ...state,
                newUserVerified: false,
            };
        }

        default:
            return state;
    }
}
