import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import {
    AGENTS_SOCIAL_CONNECTIONS,
    SOCIAL_FEED,
    STATUS,
    AGENT_LISTING_STATS,
    AGENTS,
    BROKERAGES,
} from '../../../store/api/constants';
import * as SocialActions from './actions';
import { generateSignature, parseStitchServiceError } from '../../../utils/common';
import { callStitchFunction, findRecord } from '../../../store/api/sagas';
import { getStitchUserId } from '../../auth/selectors';
import { getProfileData } from '../Profile/selectors';
import { getSocialFeed, getSocialFeedPageNumber } from './selectors';
import { BSON } from 'realm-web';
import { BadgeTypes } from '../../../utils/common/BadgesTypes';
import { useForkRef } from '@material-ui/core';
import { BrokerageAndMarketData } from '../../../utils/constants';
import { getSelectedBrokerage } from '../Brokerage/selectors';

export function* fetchFeedItems({
    isReset = false,
    agentId,
    isOnlyAgent = false,
}: ReturnType<typeof SocialActions.fetchFeedRequested>): Generator<any, any, any> {
    try {
        const PER_PAGE = 50;

        var agentRecord = yield select(getProfileData);
        let agentIdToQuery = agentId ? agentId : agentRecord._id;

        const pageNumber = isReset ? 0 : yield select(getSocialFeedPageNumber);
        const currentFeed = isReset ? [] : yield select(getSocialFeed);
        var newFeed = [...currentFeed];
        var agentSocialRecord = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: AGENTS_SOCIAL_CONNECTIONS,
            query: {
                agentObjectId: agentIdToQuery,
            },
            projection: {
                _id: 1,
                agentObjectId: 1,
                following: 1,
            },
            signature: generateSignature(),
        });

        if (agentSocialRecord?.length) {
            agentSocialRecord = agentSocialRecord[0];
        }
        // // get from follower collection
        const { following = [] } = agentSocialRecord;

        var followedIds: any = [];
        following?.map((agent: any) => {
            followedIds.push(agent.agentObjectId);
        });

        const socialFeed = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SOCIAL_FEED,
            query: {
                $or: [
                    // documents from agent's following
                    {
                        agentObjectId: {
                            $in: isOnlyAgent ? [agentIdToQuery] : followedIds,
                        },
                    },
                    // personal posts
                    {
                        agentObjectId: agentIdToQuery,
                        type: 'post',
                    },
                ],
            },
            // used for testing
            // agentObjectId: { $ne: null },
            sort: {
                activityDate: -1,
            },
            otherStages: [
                {
                    $skip: pageNumber > 0 ? (pageNumber - 1) * PER_PAGE : 0,
                },
                {
                    $limit: PER_PAGE,
                },
            ],
            signature: generateSignature(),
        });

        // ==========================================

        if (socialFeed?.length) {
            newFeed = [...newFeed, ...socialFeed];

            // if we passed in an optionalId, we need to be sure we store it in the correct reducer
            yield put(SocialActions.fetchFeedSucceeded(newFeed, agentIdToQuery));

            yield put(SocialActions.updateSocialFeedPaginationRequested(pageNumber, false));
        } else {
            yield put(SocialActions.fetchFeedSucceeded([], agentIdToQuery)); // <- only "succeed" to clear out social and start with a clean slate
            yield put(
                SocialActions.fetchFeedFailed([
                    `No feed items found for agentId ${agentIdToQuery}.`,
                ]),
            );
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(SocialActions.fetchFeedFailed([message]));
    }
}

export function* updateFeedItem({
    feedData,
}: ReturnType<typeof SocialActions.updateFeedItemRequested>): Generator<any, any, any> {
    const { eventId, agentId, type, comment } = feedData;

    try {
        const response = yield call(
            callStitchFunction,
            'interactWithSocialFeedEvent',
            eventId,
            agentId,
            type,
            comment,
        );

        if (response) {
            yield put(SocialActions.updateFeedItemSucceeded(response));
        } else {
            yield put(
                SocialActions.updateFeedItemFailed([
                    'There was an issue updating this feed item. Code: 0147-01.',
                ]),
            );
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(SocialActions.updateFeedItemFailed([message, 'Code: 0147-02.']));
    }
}

// convert image to base64, thanks stack overflow
export const imageToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export function* createNewSocialPost({
    postData,
}: ReturnType<typeof SocialActions.createSocialFeedPostRequested>): Generator<any, any, any> {
    const { agentObjectId, post } = postData;

    try {
        try {
            let base64Image;
            if (post.imageRAW) {
                try {
                    base64Image = yield imageToBase64(post.imageRAW);
                } catch (err) {
                    return yield put(
                        SocialActions.updateFeedItemFailed([
                            'Error uploading image file',
                            'Code: 0148-04',
                        ]),
                    );
                }
            }

            // createSocialFeedPost was built to handle mobile image uploads.
            // This block adds the necessary data to match the behavior of mobile image uploads
            const finalData = {
                ...post,
                imageRAW: post.imageRAW
                    ? {
                          base64: 'base64Image',
                          data: base64Image.split(',')[1], // removes uneeded data type that is created in front of the base64 string },
                          mime: post.imageRAW.type,
                      }
                    : undefined,
            };

            const response = yield call(
                callStitchFunction,
                'createSocialFeedPost',
                agentObjectId,
                finalData,
            );

            if (response) {
                yield put(SocialActions.createSocialFeedPostSucceeded(response));
            } else {
                yield put(
                    SocialActions.updateFeedItemFailed([
                        'There was an issue updating this feed item. Code: 0148-03.',
                    ]),
                );
            }
        } catch (error) {
            const message = parseStitchServiceError(error);
            yield put(
                SocialActions.createSocialFeedPostFailed([
                    message,
                    'error uploading image',
                    'Code: 0148-02.',
                ]),
            );
        }
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(SocialActions.createSocialFeedPostFailed([message, 'Code: 0148-01.']));
    }
}

export function* fetchAgentMetrics({
    agentId,
    mlsName,
}: ReturnType<typeof SocialActions.fetchAgentMetricsRequested>): Generator<any, any, any> {
    try {
        const metricsDataBuyer = yield call(
            callStitchFunction,
            'metricsProdChart',
            agentId,
            mlsName,
            'buyer',
            'agent',
        );

        const metricsDataSeller = yield call(
            callStitchFunction,

            'metricsProdChart',
            agentId,
            mlsName,
            'seller',
            'agent',
        );

        if (metricsDataBuyer?.length && metricsDataSeller?.length) {
            yield put(
                SocialActions.fetchAgentMetricsSucceeded(metricsDataBuyer, metricsDataSeller),
            );
        } else {
            yield put(SocialActions.fetchAgentMetricsFailed(['No Metrics found']));
        }
    } catch (err) {
        const message = parseStitchServiceError(err);
        yield put(SocialActions.fetchAgentMetricsFailed([message]));
    }
}

function* fetchAgentStats({
    agentObjectId,
}: ReturnType<typeof SocialActions.fetchAgentStatsRequested>): Generator<any, any, any> {
    try {
        const agentStats = yield call(
            callStitchFunction,
            'metricsGetStats',
            agentObjectId,
            'agent',
            true,
        );
        var agentStatsMap: any = {};
        agentStats.map((stats: any) => {
            agentStatsMap[`${stats.displayMlsName}`] = stats;
        });

        const agentMarkets = Object.keys(agentStatsMap);

        if (agentStatsMap) {
            yield put(SocialActions.fetchAgentStatsSucceeded(agentStatsMap, agentMarkets));
        } else {
            yield put(
                SocialActions.fetchAgentStatsFailed([
                    'Unable to get Agent stats. Error code: 0112-1',
                ]),
            );
        }
    } catch (error) {
        yield put(
            SocialActions.fetchAgentStatsFailed([
                'Agent stats fetch failed. Please try again. Error code: 0112-2',
            ]),
        );
    }
}

function* fetchAgentSocialDocument({
    agentId,
}: ReturnType<typeof SocialActions.fetchAgentSocialDocumentRequested>): Generator<any, any, any> {
    try {
        const agentDocument = yield call(findRecord, AGENTS_SOCIAL_CONNECTIONS, {
            agentObjectId: agentId,
        });
        const agentsRecord = yield call(findRecord, AGENTS, { _id: agentId });
        const badges = agentsRecord?.badges;

        // also fetch the brokerage and market data
        let agentData = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: AGENTS,
            query: {
                _id: agentId,
            },
            projection: {
                markets: 1,
            },
            signature: generateSignature(),
        });

        if (agentData.length) {
            agentData = agentData[0];
        } else {
            yield put(
                SocialActions.fetchAgentSocialDocumentFailed([
                    'Unable to fetch Brokerage and Market Data',
                    'Code: 0164-01',
                ]),
            );
        }

        const { markets } = agentData;
        const market = markets.length ? markets[0] : null; //markets 0 is preferred for now

        let data: BrokerageAndMarketData = {
            agentId,
        };
        const match = agentData.markets?.map((market: any) => {
            return {
                markets: {
                    $elemMatch: {
                        mlsName: market?.mlsName,
                        brokerageId: market?.brokerageId,
                    },
                },
            };
        });

        let brokerage;

        const brokerages = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: BROKERAGES,
            query: {
                $or: match,
            },
            signature: generateSignature(),
        });

        if (brokerages?.length) {
            brokerage = brokerages[0];
        }

        if (brokerage) {
            // we need to know how many agents are in the brokerage so lets get that too
            const brokerageData = yield call(callStitchFunction, 'runGeneralQuery', {
                collectionName: BROKERAGES,
                query: {
                    name: brokerage.name,
                },
                projection: {
                    agentCount: 1,
                },
                signature: generateSignature(),
            });

            const agentCount = brokerageData?.length ? brokerageData[0].agentCount : 0;
            data['brokerage'] = {
                name: brokerage.name || '',
                market: brokerage.market || '',
                image: brokerage.image || '',
                agentCount,
            };

            if (agentData.length) {
                agentData = agentData[0];
            } else {
                yield put(
                    SocialActions.fetchAgentSocialDocumentFailed([
                        'Unable to fetch Brokerage and Market Data',
                        'Code: 0164-01',
                    ]),
                );
            }
        }

        if (market) {
            data['market'] = {
                marketObjectId: market.marketObjectId,
                marketName: market.marketName,
                mlsName: market.mlsName,
                agentMlsId: market.agentMlsId,
            };
        }

        if (agentDocument) {
            yield put(
                SocialActions.fetchAgentSocialDocumentSucceeded({
                    ...agentDocument,
                    badges,
                    brokerageAndMarketData: data,
                }),
            );
        } else {
            yield put(
                SocialActions.fetchAgentSocialDocumentFailed([
                    `Failed to fetch social document for user ${agentId}. Code: 0156-02.`,
                ]),
            );
        }
    } catch (err) {
        const message = parseStitchServiceError(err);
        yield put(
            SocialActions.fetchAgentSocialDocumentFailed([
                message,
                `Failed to fetch social document for user ${agentId}. Code: 0156-01.`,
            ]),
        );
    }
}

export function* followAndUnfollowAgents({
    interactionType,
    agent,
    isPrivateAccount,
}: ReturnType<typeof SocialActions.followAgentRequested>): Generator<any, any, any> {
    try {
        const agentRecord = yield select(getProfileData);
        const connectionUpdate = yield call(
            callStitchFunction,
            'createOrUpdateAnAgentSocialConnection',
            interactionType,
            agentRecord._id,
            agent.agentId,
            isPrivateAccount,
        );

        if (!connectionUpdate) {
            yield put(SocialActions.followAgentFailed(['Failed to follow agent']));
        } else {
            if (interactionType === 'follow' && !isPrivateAccount) {
                // check if you're following Andrew, Jon, Luke, Chris, or Jake Jabs
                if (agent.agentId === new BSON.ObjectID('5efd1c30bf4173589827ae07')) {
                    yield put(
                        SocialActions.checkActionBadgeStatusRequested(
                            BadgeTypes.action.iSeeYou,
                            null,
                        ),
                    );
                } else if (agent.agentId === new BSON.ObjectID('5ef4e240401ae2a3ec985a36')) {
                    yield put(
                        SocialActions.checkActionBadgeStatusRequested(
                            BadgeTypes.action.teachersPet,
                            null,
                        ),
                    );
                } else if (
                    agent.agentId === new BSON.ObjectID('5f591d4779482edb9c5c092a') || // these need to be converted to the object id
                    agent.agentId === new BSON.ObjectID('5f6118930f29661f6e8e5c67') ||
                    agent.agentId === new BSON.ObjectID('5f060d7e87366d5e226cc0a9')
                ) {
                    yield put(
                        SocialActions.checkActionBadgeStatusRequested(
                            BadgeTypes.action.stalker,
                            null,
                        ),
                    );
                }

                yield put(
                    SocialActions.followAgentSucceeded(
                        {
                            agentId: agentRecord?._id,
                            firstName: agentRecord?.firstName,
                            lastName: agentRecord?.lastName,
                            profilePhoto: agentRecord?.profilePhotoUpload.uri,
                        },
                        {
                            agentId: agent?.agentId,
                            firstName: agent?.firstName,
                            lastName: agent?.lastName,
                            profilePhoto: agent.profilePhoto,
                        },
                    ),
                );
            } else if (interactionType === 'unfollow') {
                yield put(
                    SocialActions.unfollowAgentSucceeded(
                        {
                            agentId: agentRecord?._id,
                            firstName: agentRecord?.firstName,
                            lastName: agentRecord?.lastName,
                            profilePhoto: agentRecord?.profilePhotoUpload.uri,
                        },
                        {
                            agentId: agent?.agentId,
                            firstName: agent?.firstName,
                            lastName: agent?.lastName,
                            profilePhoto: agent.profilePhoto,
                        },
                    ),
                );
            } else {
                yield put(SocialActions.fetchMySocialProfileFullRequested(agentRecord?._id));
                yield put(SocialActions.fetchAgentFullRequested(agent?.agentId, agentRecord?._id));
            }
        }
    } catch (error) {
        const errorMsg: string = parseStitchServiceError(error);
        yield put(SocialActions.followAgentFailed([errorMsg]));
    }
}

export function* checkActionBadgeStatus({
    badgeType,
    agentId,
}: ReturnType<typeof SocialActions.checkActionBadgeStatusRequested>): Generator<any, any, any> {
    try {
        const userId = yield select(getStitchUserId);
        const badgeUpdate = yield call(
            callStitchFunction,
            'giveAgentBadges',
            badgeType,
            agentId ?? userId,
        );
        if (badgeUpdate) {
            yield put(SocialActions.checkActionBadgeStatusSucceeded());
        }
    } catch (error) {
        // don't alert the user
        yield put(SocialActions.checkActionBadgeStatusFailed(error));
    }
}

export function* onFetchAgentFullDocument({
    agentId,
    viewingAgentId,
}: ReturnType<typeof SocialActions.fetchAgentFullRequested>): Generator<any, any, any> {
    try {
        const agentFullRecord = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: AGENTS,
            query: {
                _id: agentId,
            },
            signature: generateSignature(),
        });
        if (agentFullRecord) {
            const agentBeingViewed = yield call(callStitchFunction, 'runGeneralQuery', {
                collectionName: AGENTS_SOCIAL_CONNECTIONS,
                query: {
                    agentObjectId: agentId,
                },
                signature: generateSignature(),
            });
            const currentAgentSocialConnections = yield call(
                callStitchFunction,
                'runGeneralQuery',
                {
                    collectionName: AGENTS_SOCIAL_CONNECTIONS,
                    query: {
                        agentObjectId: viewingAgentId,
                    },
                    signature: generateSignature(),
                },
            );
            const agentBeingViewedPendingFollowers = agentBeingViewed[0]?.pendingFollowers;
            const currentAgentPendingFollowers = currentAgentSocialConnections[0]?.pendingFollowers;
            let index;
            let indexForCurrentAgent;

            // To check if the current agent has a pending request on the agent being currently viewed
            if (agentBeingViewedPendingFollowers) {
                index = agentBeingViewedPendingFollowers.findIndex((pendingFollower: any) => {
                    if (pendingFollower?.agentObjectId?.toString() === viewingAgentId?.toString()) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            if (currentAgentPendingFollowers) {
                indexForCurrentAgent = currentAgentPendingFollowers.findIndex(
                    (pendingFollower: any) => {
                        if (pendingFollower?.agentObjectId?.toString() === agentId?.toString()) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                );
            }

            if (index > -1) {
                // Current agent is in the pending list of the agent being viewed
                yield put(SocialActions.isAgentPending(true));
            } else {
                yield put(SocialActions.isAgentPending(false));
            }
            if (indexForCurrentAgent > -1) {
                // The agent being viewed is in the pending list for the current agent
                yield put(SocialActions.isMyAgentPending(true));
            } else {
                yield put(SocialActions.isMyAgentPending(false));
            }
            yield put(SocialActions.fetchAgentFullSucceeded(agentFullRecord[0]));
        }
    } catch (error) {
        yield put(SocialActions.fetchAgentFullFailed(error));
    }
}

export function* onFetchMyAgentFullDocument({
    agentId,
}: ReturnType<typeof SocialActions.fetchMySocialProfileFullRequested>): Generator<any, any, any> {
    try {
        const agentFullSocialRecord = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: AGENTS_SOCIAL_CONNECTIONS,
            query: {
                agentObjectId: agentId,
            },
            signature: generateSignature(),
        });
        if (agentFullSocialRecord) {
            yield put(SocialActions.fetchMySocialProfileFullSucceeded(agentFullSocialRecord[0]));
        }
    } catch (error) {
        yield put(SocialActions.fetchSocialMyProfileFullFailed(error));
    }
}

export function* fetchSearchedAgents({ searchString }: any): Generator<any, any, any> {
    try {
        let agents = [];

        agents = yield call(callStitchFunction, 'textSearchAgentsSocialConnections', searchString);
        if (agents) {
            yield put(SocialActions.fetchSearchAgentsSucceeded(agents));
        }
    } catch (error) {
        const errorMsg = parseStitchServiceError(error);
        yield put(SocialActions.fetchSearchAgentsFailed(errorMsg));
    }
}

export default function* () {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchFeed &&
                action.status === STATUS.Requested,
            fetchFeedItems,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.UpdateFeedItem &&
                action.status === STATUS.Requested,
            updateFeedItem,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.CreateFeedItem &&
                action.status === STATUS.Requested,
            createNewSocialPost,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchAgentMetrics &&
                action.status === STATUS.Requested,
            fetchAgentMetrics,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchAgentStats &&
                action.status === STATUS.Requested,
            fetchAgentStats,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchAgentSocialDocument &&
                action.status === STATUS.Requested,
            fetchAgentSocialDocument,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FollowAndUnfollowAgents &&
                action.status === STATUS.Requested,
            followAndUnfollowAgents,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.Badges &&
                action.status === STATUS.Requested,
            checkActionBadgeStatus,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchAgentFull &&
                action.status === STATUS.Requested,
            onFetchAgentFullDocument,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchMyAgentFull &&
                action.status === STATUS.Requested,
            onFetchMyAgentFullDocument,
        ),
        takeLatest(
            (action: any) =>
                action.type === SocialActions.SOCIAL_ACTION.FetchSearchAgents &&
                action.status === STATUS.Requested,
            fetchSearchedAgents,
        ),
    ]);
}
