import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    callStitchFunction,
    createShowingFeedback,
    updateShowingRequest,
} from '../../../store/api/sagas';
import { getAgentObject } from '../../auth/selectors';
import * as Actions from './actions';
import { PAGINATION_LIMIT } from './constants';
import { getShowings } from './selectors';

export function* fetchShowings({
    payload: filters,
}: ReturnType<typeof Actions.fetchShowingsRequested>): Generator<any, any, any> {
    const { option = 'Buy Side', filterOption = 'All', page = 0, filterId = null } = filters as any;
    try {
        const existing = yield select(getShowings);
        const agent = yield select(getAgentObject);
        const paginationIndex = page * PAGINATION_LIMIT;
        const showings = yield call(callStitchFunction, 'fetchAgentShowings', {
            side: option,
            filter: filterOption,
            paginationIndex,
            paginationLimit: PAGINATION_LIMIT,
            filterId,
        });
        const camelSide = option === 'Buy Side' ? 'buySide' : 'listSide';
        const groupedShowings: { date: Date; showingRequests: any[] }[] = [];
        let currentGroupDate = null;
        let currentIndex = -1;
        if (filterId) {
            if (showings[camelSide][filterId]) {
                showings[camelSide][filterId].sort((a: any, b: any) => b.start - a.start);
                for (const showing of showings[camelSide][filterId]) {
                    const copyDate = new Date(showing.start);
                    copyDate.setHours(0, 0, 0);
                    if (copyDate.toString() === currentGroupDate?.toString()) {
                        groupedShowings[currentIndex].showingRequests.push(showing);
                    } else {
                        currentGroupDate = copyDate;
                        currentIndex++;
                        groupedShowings.push({ date: copyDate, showingRequests: [showing] });
                    }
                }
                showings[camelSide][filterId] = groupedShowings;
                yield put(Actions.fetchShowingsSucceeded(showings));
            }
        } else {
            showings[camelSide][filterOption].sort((a: any, b: any) =>
                filterOption !== 'Upcoming' ? b.start - a.start : a.start - b.start,
            );
            if (showings[camelSide][filterOption]) {
                for (const showing of showings[camelSide][filterOption]) {
                    const copyDate = new Date(showing.start);
                    copyDate.setHours(0, 0, 0);
                    if (copyDate.toString() === currentGroupDate?.toString()) {
                        groupedShowings[currentIndex].showingRequests.push(showing);
                    } else {
                        currentGroupDate = copyDate;
                        currentIndex++;
                        groupedShowings.push({ date: copyDate, showingRequests: [showing] });
                    }
                }
                const existingPage = existing[camelSide][filterOption]?.findIndex(
                    (d: any) => d.page === page,
                );

                showings[camelSide][filterOption] = existing[camelSide][filterOption];
                if (existingPage === undefined) {
                    showings[camelSide][filterOption] = [{ page: page, showings: groupedShowings }];
                } else if (existingPage === -1) {
                    showings[camelSide][filterOption].push({
                        page: page,
                        showings: groupedShowings,
                    });
                } else {
                    showings[camelSide][filterOption][existingPage] = {
                        page: page,
                        showings: groupedShowings,
                    };
                }
                showings[camelSide][filterOption].sort((a: any, b: any) => a.page - b.page);
                yield put(Actions.fetchShowingsSucceeded(showings));
            }
        }
    } catch (err) {
        yield put(Actions.fetchShowingsFailed(err));
    }
}

export function* changeShowingStatus({
    payload: showingRequestData,
}: ReturnType<typeof Actions.changeShowingStatusRequested>): Generator<any, any, any> {
    try {
        const {
            id,
            status,
            denialReason = null,
            isUnverified = false,
            sendLockInfo = false,
            callback = () => {},
        } = showingRequestData;
        const { upsertedId, modifiedCount } = yield call(
            updateShowingRequest,
            {
                id,
                status,
                ...(denialReason ? { denialReason } : null),
            },
            {
                isUnverifiedApproval: isUnverified,
                sendLockInfo: sendLockInfo,
            },
        );
        if (upsertedId || modifiedCount) {
            callback();
            yield put(Actions.changeShowingStatusSucceeded());
        } else {
            yield put(Actions.changeShowingStatusFailed('Could not update showing'));
        }
    } catch (err) {
        yield put(Actions.changeShowingStatusFailed(err));
    }
}

export function* submitFeedback({
    payload: feedbackData,
}: ReturnType<typeof Actions.submitFeedbackRequested>): Generator<any, any, any> {
    try {
        const { priceAccuracyRating, locationRating, interestLevel, reviewText, showingId } =
            feedbackData;
        const feedbackToSubmit = {
            ratings: {
                priceAccuracy: priceAccuracyRating,
                location: locationRating,
                interestLevel: interestLevel,
            },
            review: reviewText,
        };
        const { upsertedId, modifiedCount } = yield call(
            createShowingFeedback,
            showingId,
            feedbackToSubmit,
        );
        if (upsertedId || modifiedCount) {
            yield put(Actions.submitFeedbackSucceeded());
            yield put(
                Actions.fetchShowingsRequested({
                    filterOption: 'Needs Action',
                    option: 'Buy Side',
                }),
            );
        } else {
            yield put(Actions.submitFeedbackFailed('Could not update showing'));
        }
    } catch (err: any) {
        yield put(Actions.submitFeedbackFailed(err));
    }
}
export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) => action.type === Actions.FETCH_SHOWINGS_REQUESTED,
            fetchShowings,
        ),
        takeLatest(
            (action: any) => action.type === Actions.CHANGE_SHOWING_STATUS_REQUESTED,
            changeShowingStatus,
        ),
        takeLatest(
            (action: any) => action.type === Actions.SUBMIT_FEEDBACK_REQUESTED,
            submitFeedback,
        ),
    ]);
}
