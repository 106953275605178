import React, { ReactElement, useEffect, useState, useRef } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import AnimatedModal from '../../../../../components/AnimatedModal';
import Typography from '../../../../../components/Typography';
import indexTheme from '../../../../../theme';
import xIcon from '../../../../../images/xBlack.svg';
import { ClickableWithFeedback } from '../../../../../components';
import ButtonComponent from '../../../../../components/ButtonComponent';
import RadioRow from '../common/RadioRow';
import RadioButtons from '../common/RadioButtons';
import DropDownCal from '../../../../../components/DropDownCal';
import { getRestrictionTimes } from '../utils';

interface Props {
    modalVisible: boolean;
    set: Function;
    onPressAdd: Function;
    passedRestriction?: any;
}

type ShowingRestriction = {
    endTime: Date;
    recurring: boolean;
    recurringDetail: {
        days: number[];
        endDate: Date;
        startDate: Date;
    };
    startTime: Date;
};
const AddRestriction = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const animatedModalRef = useRef<any>(null);

    const [restriction, setRestriction] = useState<ShowingRestriction>(
        props.passedRestriction ?? {
            endTime: new Date(new Date().setHours(21, 0, 0)),
            recurring: true,
            recurringDetail: {
                days: [],
                // Default value for 'Never' being selected
                endDate: new Date('January 1, 2400'),
                startDate: new Date(),
            },
            startTime: new Date(new Date().setHours(7, 0, 0)),
        },
    );

    const restrictionStart = getRestrictionTimes('start');
    const restrictionEnd = getRestrictionTimes('end');
    // just switched the end time to 23

    const [blockFull, setBlockFull] = useState(
        restriction.startTime.getHours() === 7 && restriction.endTime.getHours() === 23,
    );
    const [customStartDate, setCustomStartDate] = useState(
        restriction.recurringDetail.startDate.getDate() !== new Date().getDate(),
    );
    const [customEndDate, setCustomEndDate] = useState(
        restriction.recurringDetail.endDate.toISOString() !== '2400-01-01T07:00:00.000Z',
    );

    useEffect(() => {
        if (blockFull) {
            setRestriction({
                ...restriction,
                startTime: new Date(new Date().setHours(7, 0, 0)),
                endTime: new Date(new Date().setHours(23, 0, 0)),
            });
        }
    }, [blockFull]);

    const changeRecurringDetail = (changedValues: any) => {
        return setRestriction({
            ...restriction,
            recurringDetail: {
                ...restriction.recurringDetail,
                ...changedValues,
            },
        });
    };

    // These hooks are here so the dropdown updates properly.
    const [selectedStartTime, setSelectedStartTime] = useState('7, 0, 7:30am');
    const [selectedEndTime, setSelectedEndTime] = useState('23, 0, 11:00pm');

    const setNewDate = (type: 'startTime' | 'endTime', hours: string, minutes: string) => {
        const newStart = new Date(`${restriction[type]}`);

        newStart.setHours(Number(hours), Number(minutes), 0);
        setRestriction({ ...restriction, [type]: newStart });
    };

    const changeTimes = (start: string[], end: string[], newDate: Date) => {
        const startDate = new Date(`${newDate}`);
        const endDate = new Date(`${newDate}`);
        startDate.setHours(Number(start[0]), Number(start[1]), 0);

        endDate.setHours(Number(end[0]), Number(end[1]), 0);

        setRestriction({
            ...restriction,
            startTime: startDate,
            endTime: endDate,
        });
    };

    const tomorrow = new Date();
    tomorrow.setDate(restriction.recurringDetail.startDate.getDate() + 1);

    useEffect(() => {
        if (!customStartDate) {
            changeRecurringDetail({ startDate: new Date() });
        }
    }, [customStartDate]);

    useEffect(() => {
        if (!customEndDate) {
            changeRecurringDetail({ endDate: new Date('January 1, 2400') });
        } else {
            changeRecurringDetail({ endDate: tomorrow });
        }
    }, [customEndDate]);

    // Use this to see if the user is inputting invalid times for the restriction block. If don't let them continue
    const invalidTimes =
        restriction.startTime >= restriction.endTime ||
        restriction.startTime.toISOString() === restriction.endTime.toISOString();

    const getAddDisabled = () => {
        if (invalidTimes) {
            return true;
        } else if (restriction.recurring && !restriction.recurringDetail.days.length) {
            return true;
        } else return false;
    };

    const RestrictionModule = ({
        title,
        row = false,
        children,
        isLast = false,
    }: {
        title: string;
        row?: boolean;
        children: ReactElement;
        isLast?: boolean;
    }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: row ? 'row' : 'column',
                    borderBottomWidth: isLast ? 0 : 1,
                    borderBottomColor: indexTheme.palette.separatorGrey,
                    borderBottomStyle: 'solid',
                    ...indexTheme.paddingVertical(10),
                }}
            >
                <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                    {title}
                </Typography>
                <div style={{ marginLeft: row ? 20 : 0 }}>{children}</div>
            </div>
        );
    };

    return (
        <AnimatedModal style={{ top: null }} ref={animatedModalRef} {...props}>
            <div className={styleSheet.header}>
                <Typography textStyle={'p1'} color={indexTheme.palette.mediumGrey}>
                    Add Restriction
                </Typography>
                <ClickableWithFeedback onClick={() => animatedModalRef.current.closeModal()}>
                    <img src={xIcon} />
                </ClickableWithFeedback>
            </div>
            <div className={styleSheet.restrictionContainer}>
                <RestrictionModule row title={'Recurring Restriction?'}>
                    <RadioRow
                        data={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                        ]}
                        set={(value: any) => setRestriction({ ...restriction, recurring: value })}
                        selected={restriction.recurring}
                    />
                </RestrictionModule>
                {restriction.recurring && (
                    <RestrictionModule
                        title={'Select the days of the week you would like for this to repeat on.'}
                    >
                        <div className={styleSheet.buttonContainer}>
                            {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(
                                (day: string, index: number) => {
                                    const selectedDay = restriction.recurringDetail.days.includes(
                                        index,
                                    );
                                    return (
                                        <ButtonComponent
                                            onClick={() => {
                                                let newDays = restriction.recurringDetail.days;
                                                if (selectedDay) {
                                                    newDays.splice(newDays.indexOf(index), 1);
                                                } else {
                                                    newDays.push(index);
                                                }
                                                changeRecurringDetail({ days: newDays });
                                            }}
                                            color={indexTheme.palette.blue}
                                            width={42}
                                            fill={selectedDay}
                                        >
                                            {day}
                                        </ButtonComponent>
                                    );
                                },
                            )}
                        </div>
                    </RestrictionModule>
                )}
                <RestrictionModule row title={'Block Entire Day(s)?'}>
                    <RadioRow
                        data={[
                            {
                                label: 'Yes',
                                value: true,
                            },
                            {
                                label: 'No',
                                value: false,
                            },
                        ]}
                        set={(value: boolean) => setBlockFull(value)}
                        selected={blockFull}
                    />
                </RestrictionModule>
                {!restriction.recurring && (
                    <RestrictionModule row title={'Restriction Date: '}>
                        <DropDownCal
                            changeDate={(d: any) => {
                                const start = selectedStartTime.split(',');
                                const end = selectedEndTime.split(',');
                                // setNewDate('endTime', end[0], end[1], new Date(d.ts));
                                // setNewDate('startTime', start[0], start[1], new Date(d.ts));
                                changeTimes(start, end, new Date(d.ts));
                            }}
                            disabled={false}
                            selectedDate={restriction.startTime}
                        />
                    </RestrictionModule>
                )}
                {!blockFull && (
                    <RestrictionModule isLast={!restriction.recurring} row title={'From'}>
                        <div style={{ display: 'flex' }}>
                            <select
                                value={selectedStartTime}
                                onChange={(e) => {
                                    const splitValues = e.target.value.split(',');
                                    setNewDate('startTime', splitValues[0], splitValues[1]);
                                    setSelectedStartTime(e.target.value);
                                }}
                                className={styleSheet.restrictionSelect}
                            >
                                {restrictionStart.map((restrictionTime: any) => {
                                    return (
                                        <option
                                            value={[
                                                restrictionTime.hours,
                                                restrictionTime.minutes,
                                                restrictionTime.formattedTime,
                                            ]}
                                        >
                                            {restrictionTime.formattedTime}
                                        </option>
                                    );
                                })}
                            </select>
                            <Typography
                                textStyle={'b1'}
                                style={{ ...indexTheme.marginHorizontal(15) }}
                                color={indexTheme.palette.mediumGrey}
                            >
                                to
                            </Typography>
                            <select
                                value={selectedEndTime}
                                onChange={(e) => {
                                    const splitValues = e.target.value.split(',');
                                    setNewDate('endTime', splitValues[0], splitValues[1]);
                                    setSelectedEndTime(e.target.value);
                                }}
                                className={
                                    invalidTimes
                                        ? styleSheet.invalidTimes
                                        : styleSheet.restrictionSelect
                                }
                            >
                                {restrictionEnd.map((restrictionTime: any) => {
                                    return (
                                        <option
                                            value={[
                                                restrictionTime.hours,
                                                restrictionTime.minutes,
                                                restrictionTime.formattedTime,
                                            ]}
                                        >
                                            {restrictionTime.formattedTime}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </RestrictionModule>
                )}
                {restriction.recurring && (
                    <>
                        <RestrictionModule title={'Starts'}>
                            <div style={{ ...indexTheme.paddingHorizontal(48) }}>
                                <RadioButtons
                                    selectedValue={customStartDate}
                                    data={[
                                        {
                                            label: 'Now',
                                            value: false,
                                        },
                                        {
                                            label: 'On',
                                            value: true,
                                            extraLabelContent: (
                                                <DropDownCal
                                                    changeDate={(d: any) => {
                                                        const newDate = new Date(d.ts);
                                                        newDate.setHours(7, 0, 0);
                                                        if (
                                                            newDate >
                                                            restriction.recurringDetail.endDate
                                                        ) {
                                                            const dateToSet = new Date();
                                                            dateToSet.setDate(
                                                                newDate.getDate() + 1,
                                                            );
                                                            changeRecurringDetail({
                                                                startDate: newDate,
                                                                endDate: dateToSet,
                                                            });
                                                        } else {
                                                            changeRecurringDetail({
                                                                startDate: newDate,
                                                            });
                                                        }
                                                    }}
                                                    selectedDate={
                                                        restriction.recurringDetail.startDate
                                                    }
                                                    onInitialPress={() => {
                                                        if (!customStartDate) {
                                                            setCustomStartDate(true);
                                                        }
                                                    }}
                                                    disabled={!customStartDate}
                                                />
                                            ),
                                        },
                                    ]}
                                    onChangeValue={(value: boolean) => setCustomStartDate(value)}
                                />
                            </div>
                        </RestrictionModule>
                        <RestrictionModule isLast title={'Ends'}>
                            <div style={{ ...indexTheme.paddingHorizontal(48) }}>
                                <RadioButtons
                                    selectedValue={customEndDate}
                                    data={[
                                        { label: 'Never', value: false },
                                        {
                                            label: 'On',
                                            value: true,
                                            extraLabelContent: (
                                                <DropDownCal
                                                    changeDate={(d: any) => {
                                                        const newDate = new Date(d.ts);
                                                        newDate.setHours(7, 0, 0);
                                                        if (
                                                            newDate >
                                                            restriction.recurringDetail.startDate
                                                        ) {
                                                            changeRecurringDetail({
                                                                endDate: newDate,
                                                            });
                                                        } else {
                                                            const dateToSet = new Date();
                                                            dateToSet.setDate(
                                                                restriction.recurringDetail.startDate.getDate() +
                                                                    1,
                                                            );
                                                            changeRecurringDetail({
                                                                endDate: dateToSet,
                                                            });
                                                        }
                                                    }}
                                                    disabled={!customEndDate}
                                                    selectedDate={
                                                        restriction.recurringDetail.endDate
                                                    }
                                                    onInitialPress={() => {
                                                        if (!customEndDate) {
                                                            setCustomEndDate(true);
                                                        }
                                                    }}
                                                />
                                            ),
                                        },
                                    ]}
                                    onChangeValue={(value: boolean) => {
                                        setCustomEndDate(value);
                                    }}
                                />
                            </div>
                        </RestrictionModule>
                    </>
                )}
            </div>
            <div className={styleSheet.footer}>
                <ButtonComponent
                    color={indexTheme.palette.blue}
                    width={257}
                    onClick={() => {
                        props.onPressAdd(restriction);
                        props.set(false);
                    }}
                    fill
                    disabled={getAddDisabled()}
                >
                    Add Restriction
                </ButtonComponent>
            </div>
        </AnimatedModal>
    );
};
export default AddRestriction;
