export default (theme: any) => {
    return {
        root: {
            position: 'relative',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            height: 60,
            backgroundColor: theme.palette.blue,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        img: {
            height: 18,
            width: 18,
            marginLeft: 10,
            marginBottom: -3,
        },
        divider: {
            "border-right": '1px solid '+theme.palette.white,
            marginRight: 20,
            height:"50%",
            
            ...theme.paddingHorizontal(10),
            ...theme.paddingVertical(5),
          },
          button: {
            backgroundColor: theme.palette.green + '!important',
            height: '35px !important',
            fontSize: '14px !important',
            fontWeight: '600 !important',
            width: '210px !important',
            marginRight: '15px !important',
            marginLeft: '15px !important',

        },
    };
};
