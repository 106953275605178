import { STATUS } from '../../../utils/constants';
import { MAP_ACTIONS } from './actions';
import { MapState } from './types';
const initialState: MapState = {
    loading: false,
    listings: [],
    errors: [],
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case MAP_ACTIONS.FetchMapListings: {
            switch (action.status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { listings } = action;
                    return {
                        ...state,
                        listings,
                        loading: false,
                    };
                case STATUS.Failed:
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
            }
        }
        default:
            return state;
    }
}
