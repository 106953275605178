import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

const PastMessages = ({ data, agentData, recipients }: any) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    const fixOverflow = useCallback((el) => {
        if (!el) return;
        var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        let count = 0;
        let lastChild = null;
        while (isOverflowing) {
            lastChild = el.lastElementChild;
            el.removeChild(lastChild);
            isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
            count++;
        }
        if (count) {
            let innerChild = lastChild.lastElementChild;
            innerChild.textContent = '+' + count;
            el.appendChild(lastChild);
            el.style.overflow = 'visible';
        }
    }, []);
    return (
        <div className={styleSheet.pastMessageRoot}>
            <span className={styleSheet.dateTime}>
                {DateTime.fromJSDate(new Date(data.date)).toLocaleString(DateTime.DATE_MED)}
            </span>
            <br />
            <div
                style={{
                    display: 'flex',
                    lineHeight: '26px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '5px',
                    marginBottom: '10px',
                    overflow: 'hidden',
                }}
                ref={fixOverflow}
            >
                <span className={styleSheet.recipientDisplayText} style={{ marginRight: '10px' }}>
                    Sent To:{' '}
                </span>
                {data.privateRecipients?.length ? (
                    [
                        ...new Map(
                            data.privateRecipients.map((d: any) => [d.toString(), d]),
                        ).values(),
                    ].map((data: any) => {
                        let agent = data.firstName
                            ? data
                            : agentData?.find(
                                  (recipient: any) => recipient._id.toString() === data.toString(),
                              );
                        if (!agent) {
                            agent = recipients?.find(
                                (recipient: any) =>
                                    recipient.agent._id.toString() === data.toString(),
                            ).agent;
                        }
                        return (
                            <span className={styleSheet.recipientDisplay}>
                                <span className={styleSheet.recipientDisplayText}>
                                    {agent?.firstName + ' ' + agent?.lastName}
                                </span>
                            </span>
                        );
                    })
                ) : (
                    <span className={styleSheet.recipientDisplay}>
                        <span className={styleSheet.recipientDisplayText}>Everyone</span>
                    </span>
                )}
            </div>
            <span className={styleSheet.message}>{data?.message}</span>
            <br />
        </div>
    );
};
export default PastMessages;
