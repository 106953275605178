import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { getAgentUserProfileData } from '../../domains/main/Brokerage/selectors';
import { callStitchFunction } from '../../store/api/sagas';
import { fetchNewsFailed, fetchNewsSucceeded, FETCH_NEWS_REQUESTED } from './actions';

function* fetchAgentContent(): Generator<any, any, any> {
    try {
        const agent = yield select(getAgentUserProfileData);
        const market = agent.markets[0].marketName;
        const agentContent = yield call(
            callStitchFunction,
            'runAggregationContentCollections',
            'agentContent',
            {
                market,
                contentType: '',
            },
        );

        if (agentContent) {
            yield put(fetchNewsSucceeded(agentContent));
        }
    } catch (error) {
        yield put(fetchNewsFailed(error));
    }
}

export default function* () {
    yield all([takeLatest(FETCH_NEWS_REQUESTED, fetchAgentContent)]);
}
