import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import CheckMarkComponent from '../../../../../components/CheckmarkComponent';
import sortCheckBox from '../../sortCheckBox.svg';
import selectedSortCheckBox from '../../selectedSortCheckBox.svg';

export interface HeaderProps {
    allChecked: boolean;
    setAllChecked: Function;
    currentFilter: number;
    setCurrentFilter: Function;
    ascending: boolean;
    setAscending: Function;
    labels: Array<string>;
    customStyle?: Object;
}
const FilterBar = (props: HeaderProps) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const {
        allChecked,
        setAllChecked,
        currentFilter,
        setCurrentFilter,
        ascending,
        setAscending,
        labels,
        customStyle,
    } = props;

    const titleComponent = (title: string, index: number) => {
        return (
            <div
                className={
                    title === 'Email'
                        ? styleSheet.emailTitle
                        : title === 'Phone'
                        ? styleSheet.phoneTitle
                        : styleSheet.titles
                }
                key={index}
            >
                <div>{title}</div>
                {!!title.length && (
                    <img
                        className={
                            ascending && currentFilter === index
                                ? styleSheet.ascending
                                : styleSheet.descending
                        }
                        src={currentFilter === index ? selectedSortCheckBox : sortCheckBox}
                        onClick={() => {
                            currentFilter === index
                                ? setAscending(!ascending)
                                : setCurrentFilter(index);
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={styleSheet.root} style={customStyle}>
            <div className={styleSheet.checkBox}>
                <CheckMarkComponent
                    disabled={false}
                    checked={allChecked}
                    setChecked={setAllChecked}
                />
            </div>
            <div className={styleSheet.labels}>
                {labels.map((label, index) => {
                    return titleComponent(label, index);
                })}
            </div>
        </div>
    );
};

export default FilterBar;
