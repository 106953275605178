import React, { useState } from 'react';

import { AgentSimple, FeedItem } from '../../../../../../utils/constants';

import styles from './index.module.css';
import LeftArrow from '../../../../../../images/backArrow.svg';
import DefaultEventImage from '../../../../../../images/feed/defaultEventImage.svg';
import { SlideOutDrawer } from 'web-lib';

interface SingleEventViewProps {
    dateString: string;
    onExit: Function;
    selectedEvent: FeedItem;
}

const SingleEventView = ({ dateString, onExit, selectedEvent }: SingleEventViewProps) => {
    const [goingOrNotGoing, setGoingOrNotGoing] = useState<'going' | 'notGoing'>('going');

    const mainContent = (
        <div className={styles.singleEventViewRoot}>
            <div className={styles.centerContainer}>
                <img
                    src={selectedEvent.image || DefaultEventImage}
                    className={styles.selectedEventImage}
                    alt={'eventImage'}
                />
                <div className={styles.centerContainerText}>
                    <span className={styles.centerContainerTextTitle}>{selectedEvent.title}</span>
                    <span className={styles.centerContainerTextDate}>{dateString}</span>
                    <span className={styles.centerContainerTextDescription}>
                        {selectedEvent.description}
                    </span>
                </div>
            </div>

            <div className={styles.goingOrNotGoingContainer}>
                <div className={styles.goingOrNotGoingHeader}>
                    <span
                        className={styles.goingOrNotGoingHeaderItem}
                        onClick={() => setGoingOrNotGoing('going')}
                        style={{ color: goingOrNotGoing === 'going' ? '#000000' : '#6a6a6a' }}
                    >
                        Going ({selectedEvent.going.length})
                    </span>
                    {goingOrNotGoing === 'going' && <div className={styles.goingSelectedFooter} />}
                    <span
                        className={styles.goingOrNotGoingHeaderItem}
                        onClick={() => setGoingOrNotGoing('notGoing')}
                        style={{ color: goingOrNotGoing === 'notGoing' ? '#000000' : '#6a6a6a' }}
                    >
                        Not Going ({selectedEvent.notGoing.length})
                    </span>
                    {goingOrNotGoing === 'notGoing' && (
                        <div className={styles.notGoingSelectedFooter} />
                    )}
                </div>

                <div className={styles.goingOrNotGoingNames}>
                    {selectedEvent[goingOrNotGoing].map((agent: AgentSimple) => (
                        <div
                            key={agent.agentId?.toString() || Math.random()}
                            className={styles.goingOrNotGoingSingleName}
                        >{`${agent.firstName} ${agent.lastName}`}</div>
                    ))}
                </div>
            </div>
        </div>
    );

    return <SlideOutDrawer children={mainContent} onCloseModal={() => onExit()} />;
};

export default SingleEventView;
