export default (theme: any) => {
    const mainBorder = {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: theme.palette.separatorGrey,
        borderStyle: 'solid',
    };

    return {
        root: {},
        selectionRow: {
            display: 'flex',
            height: 86,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: {
                left: 48,
                right: 48,
            },
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: '#d3d3d3',
            borderStyle: 'solid',
        },
        uselessGreyBar: {
            dipslay: 'flex',
            height: 100,
            backgroundColor: theme.palette.background,
        },

        userContainer: {
            height: 198,
            ...mainBorder,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
        },
        userInfoText: {
            display: 'flex',
            flexDirection: 'column',
            '& span': {
                marginTop: 16,
                height: 16,
            },
        },
        editProfileButton: {
            display: 'flex',
            margin: {
                top: 16,
                right: 48,
            },
        },
        userPhotoContainer: {
            height: 80,
            position: 'absolute',
            top: -40,
            borderRadius: 40,
            overflow: 'hidden',
        },

        textButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 10,
        },
        textButtonInnerContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        blueArrow: {
            width: 18,
            height: 18,
            marginLeft: 10,
        },

        aboutContainer: {
            ...mainBorder,
            padding: { top: 16, bottom: 16 },
            display: 'flex',
            flexDirection: 'column',
        },

        innerRoot: {
            padding: {
                left: 48,
                right: 48,
                bottom: 100,
            },
        },

        paymentMethodRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        editOptions: {
            display: 'flex',
            alignItems: 'center',
        },
    };
};
