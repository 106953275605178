import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'web-lib';
import { useHistory } from 'react-router-dom';
import DefaultProfilePic from '../../../../../images/defaultPhoto.svg';
import { Agent } from '../../../../../utils/constants';

import styles from './index.module.css';

type BrokerageRosterProps = {
    brokerageAgents: any;
    loadingAgents: boolean;
    currentPagination: number;
    setCurrentPagination: Function;
    setSelectedAgent: Function;
    setViewingRosterProfile: Function;
};

const BrokerageRoster = ({
    brokerageAgents,
    loadingAgents,
    currentPagination,
    setCurrentPagination,
    setSelectedAgent,
    setViewingRosterProfile,
}: BrokerageRosterProps) => {
    const history = useHistory();
    const [toMapAgents, setToMapAgents] = useState<any[]>([]);
    const [didAppend, setDidAppend] = useState<boolean>(false);
    const [maxScrollEnd, setMaxScrollEnd] = useState<number>(1000000); // Set scroll end to arbitrarily large value until we calculate the approximate height for re-fetch
    const scrollContainer = useRef(null);

    useEffect(() => {
        setToMapAgents(brokerageAgents);
        if (brokerageAgents.length > 0 && brokerageAgents) {
            //Lets set the scroll end to be height the is a function of the total number of agents, minus a few, times the container element size
            //This way once most of the elements have been scrolled through the page will make another fetch
            setMaxScrollEnd(75 * (brokerageAgents.length - 10));

            if (didAppend) setDidAppend(false);
        }
    }, [brokerageAgents]);

    const handleScrollBottom = (ref: any) => {
        const currentScrollLocation = ref.current.scrollTop;

        if (currentScrollLocation >= maxScrollEnd && !didAppend) {
            setCurrentPagination(currentPagination + 50);
            setDidAppend(true);
        }
    };

    type AgentCardProps = {
        agentInfo: any;
        onClick: Function;
    };

    const AgentsCard = ({ agentInfo, onClick }: AgentCardProps) => {
        const [ppDidBreak, setPPDidBreak] = useState<boolean>(false);

        return (
            <div onClick={() => onClick(agentInfo)} className={styles.agentContainer}>
                <div className={styles.agentPicAndName}>
                    {agentInfo.profilePhotoUpload ? (
                        <img
                            className={styles.agentProfilePic}
                            alt={'Agent Profile Pic'}
                            src={!ppDidBreak ? agentInfo.profilePhotoUpload.uri : DefaultProfilePic}
                            onError={() => setPPDidBreak(true)}
                        />
                    ) : (
                        <img
                            className={styles.agentProfilePic}
                            alt={'Default Profile Pic'}
                            src={DefaultProfilePic}
                        />
                    )}
                    <p className={styles.agentName}>
                        {agentInfo.firstName + ' ' + agentInfo.lastName}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div
                className={styles.agentsContainer}
                onScroll={() => handleScrollBottom(scrollContainer)}
                ref={scrollContainer}
                id={'ListingContainer'}
            >
                {toMapAgents && toMapAgents.length > 0 ? (
                    toMapAgents.map((agent: Agent, key: number) => (
                        <div key={key}>
                            <AgentsCard
                                onClick={(info: any) => {
                                    const newSelectedAgent = {
                                        agentId: info._id,
                                        name: `${info.firstName} ${info.lastName}`,
                                        firstName: info.firstName,
                                        lastName: info.lastName,
                                        photoUri: info?.profilePhotoUpload?.uri || '',
                                    };
                                    setSelectedAgent(newSelectedAgent);
                                    setViewingRosterProfile();
                                    history.push('/social/agentProfile');
                                }}
                                agentInfo={agent}
                            />
                            <div className={styles.cardDivider}></div>
                        </div>
                    ))
                ) : (
                    <>
                        {!loadingAgents && (
                            <p className={styles.noAgentsFound}>
                                No agents exist in this brokerage.
                            </p>
                        )}
                    </>
                )}
                {loadingAgents && (
                    <div className={styles.pageLoaderContainer}>
                        <div className={styles.pageLoader}>
                            <Loader
                                icon={
                                    'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrokerageRoster;
