import React from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import stylesCSS from '../../utils/commonStyles.module.css';
import agentWordmark from '../../agentWordmark.svg';
import HelpIcon from '../../images/helpCenterIcon.svg';

interface Props {
    rightContent?: any;
}

const HeaderBar = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div className={stylesCSS.header}>
            <img src={agentWordmark} className={stylesCSS.wordmark} />
            <div className={stylesCSS.profileIcons}>
                {props.rightContent ? (
                    props.rightContent
                ) : (
                    <img
                        src={HelpIcon}
                        className={stylesCSS.headerIcon}
                        onClick={() => window.open('https://www.support.showingly.com/knowledge')}
                    />
                )}
            </div>
        </div>
    );
};
export default HeaderBar;
